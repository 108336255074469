import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles(() => ({
  contactHeading: {
    fontSize: "50px",
    fontWeight: "300",
    paddingTop: "4rem",
    margin: "0",
  },
  email: {
    fontSize: "18px",
    fontWeight: "300",
    margin: "0",
    fontFamily: "Inter",
  },
  message: {
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "6rem",
    fontFamily: "Inter",
  },
  messageInput: {
    fontFamily: "Inter",
    // border: '1px solid #ccc',
    // padding: '40px',
    marginBottom: "10px",
    width: "100%",
    resize: "none" /* Optional: Disable textarea resizing by the user */,
    overflowY: "hidden" /* Optional: Hide vertical scrollbar */,
  },
  submitBTN: {
    width: "100%",
  },
  // messageInput: {
  //     height: '300px'
  // },

  text3: {
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: rem(22),
    color: "#979797",
    marginTop: rem(26),
    marginBottom: rem(4),
    "@media (max-width: 1440px)": {
      fontSize: rem(18),
    },
  },
  footer_wrapper: {
    display: "flex",
    padding: "50px 100px",
    flexDirection: "row",
    gap: "100px",
    flexWrap: "wrap",
    "@media (max-width: 1440px)": {
      gap: "50px",
    },
    "@media (max-width: 1024px)": {
      gap: "50px",
      padding: "50px 40px",
    },
    "@media (max-width: 800px)": {
      padding: "30px",
    },
    "@media (max-width: 500px)": {
      padding: "50px",
    },
    "@media (max-width: 425px)": {
      padding: "30px",
    },
    "@media (max-width: 325px)": {
      padding: "20px",
    },
  },
  footer_left: {
    flex: 1,
  },
  footer_text: {
    fontFamily: "Inter",
    fontSize: rem(20),
    fontWeight: 400,
    color: "#292828",
    marginTop: 23,
    "@media (max-width: 1440px)": {
      fontSize: rem(16),
    },
  },
  footer_center: {
    width: "250px !important",
    marginTop: "20px",
    "& .mantine-Text-root": {
      margin: "10px",
    },
  },
  footer_right: {
    width: "400px !important",
    marginTop: "20px",
  },

  drop: {
    // display: "none",
    width: "100%",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },

  drop_inner_container: {
    padding: "20px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
}));
export default useStyles;
