import { https } from "../http";
import store from "../store";

import {
  setCreateFolderLoading,
  setFolderLoading,
  setFolders,
  setFileLoading,
  setFiles,
  setUploadFileLoading,
  setRenameFileLoading,
} from "../store/reducers/document";
import { showError, showInfo } from "../utils/index";

const getFolders = async (client) => {
  try {
    store.dispatch(setFolderLoading(true));
    let response = await https().get(`/document/folder/get?client=${client}`);
    if (response) {
      store.dispatch(setFolders(response.data));
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setFolderLoading(false));
    return false;
  }
};

const getFiles = async (client, folderId) => {
  try {
    store.dispatch(setFileLoading(true));
    let response = await https().get(
      `/document/file/get?client=${client}&folder=${folderId}`
    );
    if (response) {
      store.dispatch(setFiles(response.data));
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setFileLoading(false));
    return false;
  }
};

const createFolder = async (data) => {
  try {
    store.dispatch(setCreateFolderLoading(true));
    let response = await https().post(`/document/folder/create`, data);
    if (response) {
      store.dispatch(setCreateFolderLoading(false));
      getFolders(data?.client);
      // showInfo(response.data.message);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setCreateFolderLoading(false));
    return false;
  }
};

const uploadFiles = async (data, folderId, client) => {
  try {
    store.dispatch(setUploadFileLoading(true));
    let response = await https().post(`/document/file/create`, data);
    if (response) {
      store.dispatch(setUploadFileLoading(false));
      getFiles(client, folderId);
      // showInfo(response.data.message);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setUploadFileLoading(false));
    return false;
  }
};

const updateFolder = async (data) => {
  try {
    store.dispatch(setCreateFolderLoading(true));
    let response = await https().post(`/document/folder/update`, data);
    if (response) {
      store.dispatch(setCreateFolderLoading(false));
      getFolders(data?.client);
      // showInfo(response.data.message);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setCreateFolderLoading(false));
    return false;
  }
};

const deleteFolder = async (folderId, client) => {
  try {
    store.dispatch(setCreateFolderLoading(true));
    let response = await https().delete(
      `/document/folder/delete?folder=${folderId}`
    );
    if (response) {
      store.dispatch(setCreateFolderLoading(false));
      getFolders(client);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setCreateFolderLoading(false));
    return false;
  }
};

const renameFile = async (data, folder,client) => {
  try {
    store.dispatch(setRenameFileLoading(true));
    let response = await https().post(`/document/file/rename`, data);
    if (response) {
      store.dispatch(setRenameFileLoading(false));
      getFiles(client,folder);
      // showInfo(response.data.message);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setRenameFileLoading(false));
    return false;
  }
};

const deleteFile = async (fileId, folder, client) => {
  try {
    store.dispatch(setRenameFileLoading(true));
    let response = await https().delete(`/document/file/delete?file=${fileId}`);
    if (response) {
      store.dispatch(setRenameFileLoading(false));
      getFiles(client, folder);
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    // console.log("🚀 ~ deleteFile ~ _error:", _error);

    // showError(_error, error?.response?.status);
    store.dispatch(setRenameFileLoading(false));
    return false;
  }
};

export {
  createFolder,
  getFolders,
  updateFolder,
  uploadFiles,
  getFiles,
  deleteFolder,
  deleteFile,
  renameFile,
};
