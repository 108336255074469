import {
  Anchor,
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { toggleTheme } from "../../store/reducers/theme";
import mixpanel from "mixpanel-browser";
import { PopupButton } from "react-calendly";

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: "6rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    borderBottom:
      "border-bottom: 1px solid linear-gradient(90deg, rgba(0, 0, 0, 0) 1.67%, #000000 51.29%, rgba(0, 0, 0, 0) 98.07%)",
    height: rem(80),
    "@media (max-width: 1190px)": {
      paddingInline: "3rem",
    },
    "@media (max-width: 768px)": {
      paddingInline: "1rem",
    },
  },
  leftSide: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    gap: "100px",
    "@media (max-width: 870px)": {
      justifyContent: "start",
      paddingLeft: "1rem",
    },
  },
  rightSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "@media (max-width: 1100px)": {
      gap: "10px",
    },
  },
  loginsignup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  link: {
    marginTop: rem(38),
  },
  linkgroup: {
    display: "flex",
    gap: "20px",
    border: "1px solid gray",
    padding: "10px 20px",
    borderRadius: "10px",
    FontFamily: "Inter",
    // gap: 75,
    "@media (max-width: 1440px)": {
      gap: 45,
    },
    "@media (max-width: 1250px)": {
      gap: 25,
    },
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  navbar_link: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    cursor: "pointer",
    fontWeight: 500,
    lineHeight: "normal",
    "@media (max-width: 1440px)": {
      fontSize: "16px",
    },
    "@media (max-width: 1100px)": {
      fontSize: "13px",
    },
    "&:hover": {
      borderBottom: "2px solid #000",
    },
  },
  login: {
    fontFamily: "Inter",
    padding: "15px 25px",
    height: "auto",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "10px",
    background: "#000",
    cursor: "pointer",
    "&:hover": {
      background: "#000",
      opacity: 0.8,
    },
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  loginMobile: {
    fontFamily: "Inter",
    // marginLeft: 30,
  },
  logo: {
    cursor: "pointer",
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important",
    },
  },
  menu: {
    width: 35,
    height: 35,
    objectFit: "contain",
    marginRight: 30,
    display: "none",
    "@media (max-width:1000px)": {
      display: "block",
    },
    "@media (max-width:330px)": {
      marginRight: 10,
    },
  },

  customDrawer: {
    width: 0,
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#f2f2f2",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 1,
  },

  customDrawerOpen: {
    height: "100%",
    width: "250px !important",
    position: "fixed",
    top: 0,
    left: 0,
    // backgroundColor: "#FFBA45",
    backgroundColor: "#FFF",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 999,
  },

  drawerContent: {
    padding: "20px",
    width: "100%",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
    margin: "20px 10px",
  },
  textColor: {
    color: theme.primaryColor,
  },
  secondaryColor: {
    color: theme.other.successColor,
  },
  drawerBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "10px",
  },
  activeLink: {
    cursor: "pointer",
    color: theme.colors.yellow[6],
    "&:hover": {
      borderBottomWidth: "2px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.colors.yellow[6],
    },
  },
}));

const HeaderMain = ({ onClicks, contactFeature, contactPlane }) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    navigate("/");
  };

  const handleNavigateToFeatures = async () => {
    await mixpanel.track("scroll to features section")
    navigate("/", { state: { scrollToFeatures: true } });
  };

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const isActiveLink = (path) => location.pathname === path;

  return (
    <Box className={classes.container}>
      <Image
        src={
          theme.colorScheme === "dark"
            ? "/assets/images/logo2-white-version-2.png"
            : "/assets/images/logo2.png"
        }
        // mr={"auto"}
        fit="fill"
        className={classes.logo}
        onClick={handleClick}
      />
      <Box className={classes.leftSide}>
        <SimpleGrid className={classes.linkgroup}>
          <Text
            className={cx(classes.navbar_link, {
              [classes.activeLink]: isActiveLink("/"),
            })}
            onClick={() => {
              navigate("/");
            }}
          >
            <Center>Home</Center>
          </Text>
          <Text
            className={cx(classes.navbar_link, {
              [classes.activeLink]: isActiveLink("/whyogiso"),
            })}
            onClick={async () => {
              await mixpanel.track("navigate to why ogiso page")
              navigate("/whyogiso");
            }}
          >
            <Center>Why Ogiso</Center>
          </Text>
          <Text
            className={classes.navbar_link}
            onClick={handleNavigateToFeatures}
          >
            <Center>Features</Center>
          </Text>
          <Text
            className={cx(classes.navbar_link, {
              [classes.activeLink]: isActiveLink("/pricing"),
            })}
            onClick={async () => {
              await mixpanel.track("navigate to pricing page")
              navigate("/pricing");
            }}
          >
            <Center>Pricing</Center>
          </Text>
          <Text
            className={cx(classes.navbar_link, {
              [classes.activeLink]: isActiveLink("/contact-us"),
            })}
            onClick={async () => {
              await mixpanel.track("navigate to contact page")
              navigate("/contact-us");
            }}
          >
            <Center>Contact Us</Center>
          </Text>
        </SimpleGrid>
      </Box>
      <Box className={classes.rightSide}>
        <Box className={classes.loginsignup}>
          <Text
            className={classes.navbar_link}
            onClick={() => navigate("/login")}
          >
            <Center>Login</Center>
          </Text>
          <Text
            className={classes.navbar_link}
            onClick={() => navigate("/signup")}
          >
            <Center>Signup</Center>
          </Text>
        </Box>
        {/* <Link
          target="_blank"
          style={{ textDecoration: "none", color: "#fff" }}
          to="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
          onClick={() => mixpanel.track("demo booked")}
        >
          <Button color="#000" ml={"auto"} size="xl" className={classes.login}>
            Book a Demo
          </Button>
        </Link> */}
        <div
          onClick={() => mixpanel.track("demo booked")}
        >
          <PopupButton
            className={classes.login}
            url="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
            rootElement={document.getElementById("root")}
            text="Book a Demo"
          />
        </div>
      </Box>

      {/* <Button onClick={() => dispatch(toggleTheme())}>
          {theme.colorScheme === "light" ? "Dark" : "Light"} Mode
        </Button> */}

      <img
        src="/assets/images/menu.png"
        className={classes?.menu}
        onClick={toggleDrawer}
        alt="menu"
      />

      {isOpen ? (
        <Box className={classes?.customDrawerOpen}>
          <Box className={classes.drawerContent}>
            <span onClick={toggleNavbar}>
              <img width={25} src="/assets/images/closeIcon.png" alt="close" />
            </span>
            <Image
              src="/assets/images/logo3.png"
              mr={"auto"}
              fit="fill"
              ml={99}
              className={classes.logo}
            />
            <Box className={classes.links}>
              <Anchor
                className={classes.navbar_link}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Center>Home</Center>
              </Anchor>
              <Anchor
                className={classes.navbar_link}
                onClick={async () => {
                  await mixpanel.track("navigate to why ogiso page")
                  navigate("/whyogiso");
                }}
              >
                <Center>Why Ogiso</Center>
              </Anchor>
              <Anchor
                className={classes.navbar_link}
                onClick={handleNavigateToFeatures}
              >
                <Center>Features</Center>
              </Anchor>
              <Anchor
                className={classes.navbar_link}
                onClick={async () => {
                  await mixpanel.track("navigate to pricing page")
                  navigate("/pricing");
                }}
              >
                <Center>Pricing</Center>
              </Anchor>
              <Anchor
                className={classes.navbar_link}
                onClick={async () => {
                  await mixpanel.track("navigate to contact page")
                  navigate("/contact-us");
                }}
              >
                <Center>Contact Us</Center>
              </Anchor>
            </Box>
            <Box className={classes.drawerBtn}>
              <Button
                size="xs"
                c={"#000"}
                color="yellow"
                variant="white"
                className={classes.loginMobile}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              <Button
                size="xs"
                c={"#000"}
                color="gray"
                className={classes.loginMobile}
                onClick={() => navigate("/signup")}
              >
                Get Started
              </Button>
              <Button
                size="sm"
                c={"#000"}
                color="yellow"
                className={classes.loginMobile}
              >
                <Link
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                  to="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
                >
                  Book a Demo
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.customDrawer}>
          <Box className={classes.drawerContent}></Box>
        </Box>
      )}
    </Box>
  );
};

export default HeaderMain;
