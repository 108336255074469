import { ActionIcon, Flex, Group, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import swal from "sweetalert";
import FacilityTypeModal from "../../containers/FacilityTypeModal";
import { removeFacilityType } from "../../services/facility";

const useStyles = createStyles((theme) => ({
  border: {
    borderLeft: "1px solid #F2F2F2"
  }
}));
const FacilityTableRow = ({ row }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  // functions
  const remove = async (canDelete) => {
    if (canDelete) {
      const willDelete = await swal({
        title: `Are you sure?`,
        text: "This action cannot be undone. Facility Types are further associated to Facilities, Deleting facility type can cause problems.",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Delete"]
      });
      if (willDelete) removeFacilityType(row?.id);
    } else {
      swal({
        title: `Not Allowed`,
        text: "You are not allowed to delete this facility.",
        icon: "warning",
        dangerMode: true
      });
    }
  };
  return (
    <tr>
      <td className={classes.border}>
        <Flex
          gap="xs"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {row.type || "-"}
          <Group>
            <FacilityTypeModal
              opened={opened}
              close={close}
              title={"Facility Type"}
              data={row}
            />
            {!row?.general && (
              <ActionIcon color="green" variant="light" onClick={open}>
                <IconEdit />
              </ActionIcon>
            )}
            {!row?.general && (
              <ActionIcon
                color="red"
                variant="light"
                onClick={() => remove(row?.canDelete)}
              >
                <IconTrash />
              </ActionIcon>
            )}
          </Group>
        </Flex>
      </td>

    </tr>
  );
};

export default FacilityTableRow;
