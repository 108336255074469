import { createSlice } from "@reduxjs/toolkit";
import { decrypt, formatTime } from "../../utils";
import moment from "moment";
import { isLate } from "../../pages/Home/Medication/MarLogTab/MarLogTable";

const initialState = {
  selectedMarType: "",
  loading: false,
  addMarLoading: false,
  loadingMar_Types: false,

  medicationLogs: [],
  medicationLogCount : 0,
  medicationLogsAllData : [],

  mar: [],
  marTypes: [],
  allmarTypes: [],
  marType: [],
  marAgainstTypes: [],
  filterDates: [undefined, undefined],
  logType: "all",
  logSearch: "",
  allData: [],

  selectedClient: JSON.parse(localStorage.getItem("selectedClient")) || null,
};

var selectedClientCopy = null;

export const marState = createSlice({
  name: "mar",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload,
      };
    },
    setLoadingMAR_Types(state, action) {
      return {
        ...state,
        loadingMAR_Types: action?.payload,
      };
    },
    setAddMarLoading(state, action) {
      return {
        ...state,
        addMarLoading: action?.payload,
      };
    },

    setMar(state, action) {
      let _mar = [];
      let _allData = [];
      if (action?.payload?.newMar?.length > 0) {
        action?.payload?.newMar?.forEach((v, i) => {
          const _decrypt = decrypt(v?.client?.form3);
          const filtered =
            _decrypt?.find((x) => Number(x?.id) === Number(v?.medicationId));
            if(filtered){
              _mar.push({
                ...v,
                form3: filtered ? filtered : null,
              });
            }
        });
      }
      if (action?.payload?.allData?.length > 0) {
        action?.payload?.allData?.forEach((v, i) => {
          const _isLate = isLate(
            v?.date,
            v?.timeSlot,
            v?.createdAt,
            v?.medicationType
          );

          const _decrypt = decrypt(v?.client?.form3);
          const filtered =
            _decrypt?.find((x) => Number(x?.id) === Number(v?.medicationId));
            // _decrypt?.find((x) => x?.id === v?.medicationId) || _decrypt?.[0];
            if(filtered){
              _allData.push({
                date: moment(v?.date).format("DD-MM-YYYY") || "-",
                clientName:
                  decrypt(v?.client?.name, false) || v?.client?.name || "-",
                medicationName: filtered?.medicationName || "-",
                dosage: v?.dosage || "-",
                ingestionMode: filtered?.ingestionMode || "-",
                isNarcotic: filtered?.isNarcotic || "No",
                medicineRemaining: v?.medicineRemaining || "-",
                frequency: v?.form3?.frequency || "-",
                timeSlot: v?.timeSlot || "-",
                staff: v?.staff ? formatTime(v?.createdAt) : "-" || "-",
                staffName: v?.staff?.name || "-",
                medicationType:
                  v?.medicationType === "upcoming"
                    ? !moment(v.date).isBefore(moment().format("MM-DD-YYYY"))
                      ? "Upcoming"
                      : "Missed"
                    : v?.medicationType,
                late: _isLate,
              });
            }
        });
      }
      return {
        ...state,
        mar: _mar,
        count: action?.payload?.count,
        loading: false,
        allData: _allData,
      };
    },

    setMedicationLogs(state, action) {
      let _mar = [];
      let _allData = [];
      if (action?.payload?.newMar?.length > 0) {
        action?.payload?.newMar?.forEach((v, i) => {
          const _decrypt = decrypt(v?.client?.form3);
          const filtered =
            _decrypt?.find((x) => x?.id === v?.medicationId);
          _mar.push({
            ...v,
            form3: filtered ? filtered : null,
          });
        });
      }
      if (action?.payload?.allData?.length > 0) {
        action?.payload?.allData?.forEach((v, i) => {

          const _decrypt = decrypt(v?.client?.form3);
          const filtered =
            _decrypt?.find((x) => x?.id === v?.medicationId);
          _allData.push({
            date: moment(v?.createdAt).format("DD-MM-YYYY") || "-",
            clientName:
              decrypt(v?.client?.name, false) || v?.client?.name || "-",
            medicationName: filtered?.medicationName || v?.medication?.medicationName|| "-",
            refillCount: v?.refillCount || "-",
            newMedicineCount : v?.newMedicineCount || "-" 
          });
        });
      }
      return {
        ...state,
        medicationLogs: _mar,
        medicationLogCount: action?.payload?.count,
        loading: false,
        medicationLogsAllData: _allData,
      };
    },

    setSelectedClient(state, action) {
      selectedClientCopy = action?.payload;
      return {
        ...state,
        selectedClient: action?.payload,
      };
    },
    searchClient(state, action) {
      let searchedMedicines = selectedClientCopy?.form3?.filter((item) =>
        item.medicationName?.toLowerCase()?.includes(action.payload)
      );
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          form3: searchedMedicines,
        },
      };
    },
    serchMedicinesByStartDate(state, action) {
      let searchedMedicines = selectedClientCopy?.form3?.filter(
        (item) =>
          moment(item.medicationDate).format("MM-DD-YYYY") ===
          moment(action?.payload).format("MM-DD-YYYY")
      );
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          form3: searchedMedicines,
        },
      };
    },
    setFilterDates(state, action) {
      return {
        ...state,
        filterDates: action?.payload,
      };
    },

    setLogType(state, action) {
      return {
        ...state,
        logType: action?.payload,
      };
    },
    setLogSearch(state, action) {
      return {
        ...state,
        logSearch: action?.payload,
      };
    },
    resetSelectedClient(state, action) {
      return {
        ...state,
        selectedClient: selectedClientCopy,
      };
    },

    // Reset State
    reset() {
      return initialState;
    },
  },
});

export const {
  setLoading,
  setLoadingMAR_Type,
  setAddMarLoading,
  setMar,
  setSelectedClient,
  searchClient,
  setFilterDates,
  setLogType,
  serchMedicinesByStartDate,
  resetSelectedClient,
  setLogSearch,setMedicationLogs
} = marState.actions;
export default marState.reducer;
