import React, { useCallback } from "react";
import { Input, Select, createStyles } from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { useState,useEffect,useRef } from "react";
import { resetSelectedClient, searchClient, serchMedicinesByStartDate, setFilterDates, setLogSearch } from "../../../../store/reducers/mar";
import store from "../../../../store";
import { DatePickerInput } from "@mantine/dates";
import { getMar, getMedicationLogs } from "../../../../services";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(0, 0, 0, 1)",
    padding: "10px 20px",
    // marginBottom: "10px",
    height: "65px",
  },

  filterRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    borderRadius: "10px",
  },
  searchBox: {
    width: "600px",
  },
  searchBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    "& input": {
      height: "50px",
    },
  },
  filterBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "20px",
  },
  dateBar: {
    height: "50px",
    width: "250px",
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "center",
    // "& button": {
    //   width: "250px",
    //   height: "50px",
    // },

    "& .mantine-DatePickerInput-input": {
      height: "50px",
    },
  },
  filter: {
    height: "50px",
    // height: "78.77px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& input": {
      height: "50px",
    },
    "& .mantine-Input-icon": {
      paddingLeft: "20px",
    },
    "& .mantine-Input-input": {
      paddingLeft: "3rem",
    },
    "& img": {
      width: "30px",
      height: "auto",
    },
  },
}));

const FilterBar = ({ activeTab }) => {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const { filterDates, logType,logSearch } = useSelector(
    (state) => state.mar
  );
  // const [value, setValue] = useState("");
  const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState("");
    const timerRef = useRef();
    
    useEffect(() => {
      timerRef.current = setTimeout(() => setDebouncedValue(search), delay);
  
      return () => {
        clearTimeout(timerRef.current);
      };
    }, [search, delay]);
  
    return debouncedValue;
  };
  const debouncedValue = useDebounce(value, 500);
  

  const getData = useCallback(async () => {
    // console.log("🚀 ~ FilterBar ~ debouncedValue:", debouncedValue)
      if (activeTab === "medicationList") {
            store.dispatch(searchClient(debouncedValue));
          }else if (activeTab === "marLog"){
            await getMar(1, null, true,filterDates, logType,debouncedValue);
          }
          else if (activeTab === "medicationLog"){
            await getMedicationLogs(1, null, true,debouncedValue);
          }
  }, [debouncedValue]);

  useEffect(() => {
    getData();
  }, [debouncedValue, getData]);

  return (
    <div className={classes.row}>
      <Input
        id="search-medicine"
        icon={<IconSearch />}
        className={classes.searchBar}
        placeholder="Search..."
        value={search}
        onChange={async (e) => {
          setSearch(e.target.value);
          store.dispatch(setLogSearch(e.target.value))
        }}
      />
      <div className={classes.filterBar}>

        {activeTab === "medicationList" && (
          <div>
            <DatePickerInput
              id="filter-by-start-date"
              placeholder="Filter by Start Date"
              value={startDate}
              clearable
              onChange={async (date) => {
                setStartDate(date);
                if(date){
                  let start = moment(date)?.toISOString();
                  store.dispatch(serchMedicinesByStartDate(start));
                }else{
                  store.dispatch(resetSelectedClient());
                }
              }}
              className={classes.dateBar}
            />
          </div>
        )}
        {activeTab === "marLog" && (
          <div>
            <DatePickerInput
              id="select-date-range"
              type="range"
              placeholder="Pick dates range"
              value={value}
              clearable
              onChange={async (date) => {
                setValue(date);
                if (date?.[1] !== null) {
                  // console.log("in if");
                  let start = moment(date?.[0])?.toISOString();
                  let end = moment(date?.[1])?.toISOString();
                  store.dispatch(setFilterDates([start, end]));
                  await getMar(1, null, true, [start, end], logType,logSearch);
                } else if (date?.[0] === null && date?.[1] === null) {
                  store.dispatch(setFilterDates([undefined, undefined]));
                  await getMar(1, null, true , [undefined, undefined] , logType, logSearch);
                }
              }}
              className={classes.dateBar}
            />
          </div>
        )}

        {/* <Select
          className={classes.filter}
          icon={<img src={"/assets/images/sort-icon.png"} />}
          data={[
            { value: "month", label: "Month" },
            { value: "week", label: "Week" },
            { value: "day", label: "Day" },
          ]}
          rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: "none" } }}
        /> */}
      </div>
    </div>
  );
};

export default FilterBar;
