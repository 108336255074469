import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    minHeight: "100vh",
    margin: "0",
    padding: "0",
    "@media (max-width :40em)": {
      maxWidth: "100%",
      display: "block",
    },
    // background: "#000",
  },
  left_pane: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "0px",
    justifyContent: "space-between",

    // height: "100%",
    "@media (max-width :40em)": {
      display: "none",
    },
    "& .mantine-9nd06w": {
      padding: "0px",
    },
  },
  leftSideTitle: {
    " p": {
      fontFamily: "Inter",
      fontSize: "40px",
      fontWeight: "500",
      lineHeight: "66px",
      textAlign: "left",
      color: "#000",
      "@media (max-width : 800px)": {
        fontSize: "30px",
      },
    },
  },

  right_pane: {
    // background: "#fff",
    borderTopLeftRadius: "40px",
    borderBottomLeftRadius: "40px",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width : 800px)": {
      padding: "1rem",
    },
    "@media (max-width :40em)": {
      maxWidth: "100%",
      borderTopLeftRadius: "unset",
      borderBottomLeftRadius: "unset",
      padding: "3rem 1rem",
    },
    padding: "1rem",
  },
  welcome: {
    // position: "relative",
    // top: "5.5%",
    // left: "5.5%",
    maxWidth: "80%",
    marginLeft: "2rem",
    marginTop: "2rem",
  },
  authImg: {
    maxWidth: "100%",
    height: "auto",
  },
  logo: {
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important",
    },
    "@media (max-width:768px)": {
      marginLeft: 30,
    },
    "@media (max-width:330px)": {
      marginLeft: 10,
    },
  },

  logo_img2: {
    "@media (max-width :80em)": {
      width: "30%",
      height: "30%",
    },
    "@media (max-width :40em)": {
      display: "inline",
    },
    display: "none",
  },
  glass_man: {
    marginTop: rem(286),
    marginLeft: "8.95%",
    width: "59.2%",
    aspectRatio: 1.298,
  },
  credential: {
    "@media (max-width :80em)": {
      position: "absolute",
      top: "50%",
    },
    position: "absolute",
    top: "30%",
    left: "26.09%",
    width: "19%",
    aspectRatio: "0.8693",
  },
  myform: {
    maxWidth: "80%",
    margin: "auto",
    padding: "2rem 0",
    // position: "relative",
    // top: "10%",
  },
  rememberbox: {
    marginTop: rem(15),
    marginBottom: rem(15),
  },
  forgot: {
    marginLeft: "auto",
    color: "#FFBA45",
    textDecoration: "underline",
    fontSize: rem(16),
  },
  signup: {
    color: "#00000",
    textDecoration: "none",
  },
  toptitle: {
    "@media (max-width :40em)": {
      textAlign: "center",
      paddingTop: "1rem",
    },
    // position: "relative",
    // top: "5.5%",
    // right: "5.5%",
    marginTop: "1rem",
    textAlign: "center",
    color: "#000000",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  stepTitle: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(24),
    fontWeight: 700,
    fontFamily: "Inter",
    "@media (max-width :600px)": {
      fontSize: rem(24),
    },
  },
  stepSpan: {
    color: theme.other.grayDarkColor,
  },
  title: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(34),
    fontWeight: 700,
    fontFamily: "Inter",
    "@media (max-width :600px)": {
      fontSize: rem(24),
    },
  },
  hepl_btn: {
    "@media (max-width :40em)": {
      width: "100%",
    },
    "@media (max-width :800px)": {
      width: "100%",
    },
    marginTop: "2rem",
    width: "100%",
    height: rem(50),
    fontFamily: "Inter",
    fontSize: "22px",
    borderRadius: "4px",
    background: theme.other.grayDarkColor,
    color: "var(--White-Color, #FFF)",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      background: theme.other.grayDarkColor,
      opacity: 0.8,
    },
    "@media (max-width : 800px)": {
      fontSize: "18px",
    },
  },
  links: {
    textDecoration: "none",
    color: "#000",
    // fontWeight: '500'
  },
  login_btn: {
    "@media (max-width : 800px)": {
      fontSize: "18px",
    },
    "@media (max-width :40em)": {
      width: "100%",
    },
    "@media (max-width :800px)": {
      width: "100%",
    },
    marginTop: "2rem",
    width: "100%",
    height: rem(50),
    fontFamily: "Inter",
    fontSize: "22px",
    borderRadius: "4px",
    background: "#000",
    color: "var(--White-Color, #FFF)",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      background: "#000",
      opacity: 0.8,
    },
  },
  login_btn_google: {
    "@media (max-width :80em)": {
      width: "100%",
      fontSize: "1rem",
    },
    width: rem(296),
    height: rem(50),
    marginTop: rem(20),
    color: "#979797",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.09)",
  },
  learn_btn: {
    color: "#FFBA45",
    marginLeft: "1rem",
  },
  myfont: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  learnSignupBTN: {
    "@media (max-width :1280px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: rem(20),
    gap: "1rem",
    marginLeft: rem(20),
  },
  iconBorder: {
    border: "1px solid #232323",
    borderRadius: "3px",
    padding: ".5rem 2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
    cursor: "pointer",
    ":hover": {
      background: "#cccccc78",
    },
  },
}));

export default useStyles;
