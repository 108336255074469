import React from "react";

export const YellowMarkSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="8.24961"
        height="24.7488"
        rx="4.1248"
        transform="matrix(0.707138 -0.707076 0.707138 0.707076 26.668 50.2842)"
        fill="#FFBA45"
      />
      <rect
        x="38.335"
        y="61.833"
        width="41.248"
        height="8.24961"
        rx="4.1248"
        transform="rotate(-45 38.335 61.833)"
        fill="#FFBA45"
      />
      <ellipse
        cx="50.0003"
        cy="50"
        rx="50.0003"
        ry="50"
        fill="#FFBA45"
        fill-opacity="0.25"
      />
    </svg>
  );
};
