import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  LoadingAddTicket: false,
  tickets: [],
  count: 0,
  unreadedCount: 0,
};

export const ticketState = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload,
      };
    },
    setLoadingAddTicket(state, action) {
      return {
        ...state,
        LoadingAddTicket: action?.payload,
      };
    },
    setTickets(state, action) {
      return {
        ...state,
        loading: false,
        tickets: action?.payload?.tickets,
        count: action?.payload?.count,
        unreadedCount: action?.payload?.unreadedCount,
      };
    },

    setTicketUnreadCount(state, action) {
      return {
        ...state,
        unreadedCount: action?.payload?.count,
      };
    },
    // Reset State
    reset() {
      return initialState;
    },
  },
});

export const {
  setLoading,
  setLoadingAddTicket,
  setTickets,
  setTicketUnreadCount,
} = ticketState.actions;
export default ticketState.reducer;
