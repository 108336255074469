import { createSlice } from '@reduxjs/toolkit';
import { darkTheme, lightTheme } from '../../config/themeConfig';

const getInitialTheme = () => {
  const savedTheme = localStorage.getItem('theme');
  return savedTheme === 'dark' ? darkTheme : lightTheme;
};

const themeSlice = createSlice({
  name: 'theme',
  initialState: getInitialTheme(),
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem('theme', action.payload.colorScheme);
      return action.payload;
    },
    toggleTheme: (state) => {
      const newTheme = state.colorScheme === 'light' ? darkTheme : lightTheme;
      localStorage.setItem('theme', newTheme.colorScheme);
      return newTheme;
    },
  },
});

export const { setTheme, toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
