import React from "react";

export const CloseIconSvg = ({ className, onClick }) => {
  return (
    <svg
      width="43"
      height="43"
      className={className}
      onClick={onClick}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="24" r="14.5" stroke="#A8A8A8" />
      <path
        d="M25.8817 32.6528L12.6235 19.3945L14.8733 17.1446L28.1316 30.4029L25.8817 32.6528ZM14.8733 32.6528L12.6235 30.4029L25.8817 17.1446L28.1316 19.3945L14.8733 32.6528Z"
        fill="#A8A8A8"
      />
    </svg>
  );
};
