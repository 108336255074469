import React from "react";
import { Col, Grid, Modal } from "@mantine/core";
import useStyles from "./style";
import { IconXboxX } from "@tabler/icons-react";
import insuranceImage from "../../images/insurance.png";
import collapseImage from "../../images/collapse.png";
import InsuranceCard from "../InsuranceCard/InsuranceCard";
import { FaRegListAlt } from "react-icons/fa";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Index = ({ opened, onClose, data }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  // console.log("data =>", data);
  return (
    <div>
      <Modal.Root
        className={classes.clientInsuranceModal}
        opened={opened}
        onClose={() => onClose()}
        size={650}
        shadow="md"
        overlayProps={{ blur: 3 }}
        radius={"lg"}
        zIndex={1000}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title className={classes.flexParent}>
              <div className={classes.headerFlex}>
                <div>
                  <img
                    className={classes.headerFlexImage}
                    src={insuranceImage}
                    alt="Insurance image"
                  />
                </div>
                <div>
                  <h3 className={classes.headerFlexTitle}>Insurance</h3>
                </div>
              </div>
              <div className={classes.headerFlex}>
                <div>
                  <button
                    className={classes.headerFlexEndButton}
                    onClick={() => {
                      navigate("/home/patient", {
                        state: {
                          clientDetails: data,
                          formName: "insuranceInfo",
                          edit: true,
                          disable: true,
                        },
                      });
                    }}
                  >
                    Edit{" "}
                  </button>
                </div>
                <div>
                  <button
                    className={classes.headerCollapseImageButton}
                    onClick={() => onClose()}
                  >
                    <img
                      className={classes.headerCollapseImage}
                      src={collapseImage}
                      alt="Collapse image"
                    />
                  </button>
                </div>
              </div>
            </Modal.Title>

            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body className={classes.body}>
            {data?.form2?.insurance?.length > 0 ? (
              <div className={classes.bodyFlex}>
                <Grid justify="Space-between">
                  {data?.form2?.insurance?.map((cardDetails, index) => (
                    <Col key={index} span={12} sm={5}>
                      <InsuranceCard
                        type={cardDetails?.type}
                        effectiveDate={
                          moment(cardDetails?.effectiveDate).format(
                            "MM/DD/YYYY"
                          ) ||
                          moment(data?.form2?.effectiveDate).format(
                            "MM/DD/YYYY"
                          )
                        }
                        groupNumber={cardDetails?.groupNumber}
                        subscriberName={cardDetails?.subscriberName}
                        insuranceCompanyName={cardDetails?.providerName}
                        identificationNumber={cardDetails?.identificationNumber}
                        anotherType={cardDetails?.insuranceType}
                      />
                    </Col>
                  ))}
                </Grid>
              </div>
            ) : (
              <div className={classes.bodyFlex}>
                <Grid justify="Space-between">
                  <Col span={12} sm={5}>
                    <InsuranceCard
                      type={"Medical"}
                      effectiveDate={moment(data?.form2?.effectiveDate).format(
                        "MM/DD/YYYY"
                      )}
                      groupNumber={data?.form2?.groupNumber}
                      subscriberName={data?.form2?.subscriberName}
                      insuranceCompanyName={data?.form2?.insuranceCompanyName}
                      identificationNumber={""}
                      anotherType={""}
                    />
                  </Col>
                </Grid>
              </div>
            )}
            {data?.form2?.insurance?.length === 0 && (
              <h2 className={classes.noDataFound}>
                No Data Found <FaRegListAlt className={classes.noDataIcon} />
              </h2>
            )}
          </Modal.Body>
        </Modal.Content>
        {/* <h1>Hello World</h1> */}
      </Modal.Root>
    </div>
  );
};

export default Index;
