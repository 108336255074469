import React from "react";
import {
    ActionIcon,
    Box,
    Button,
    Checkbox,
    Divider,
    Group,
    Loader,
    Modal,
    PasswordInput,
    Radio,
    Select,
    SimpleGrid,
    Text,
    TextInput,
    Textarea,
    createStyles,
} from "@mantine/core";

import { useForm, setForm } from "@mantine/form";

import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { DateInput, TimeInput } from "@mantine/dates";
import { useLocation } from "react-router-dom";
import { EditclientProfile, getSinglePatients } from "../../services/patient";


const useStyles = createStyles((theme) => ({
    wrapper: {
        display: "flex",
    },
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    btn: {
        marginLeft: "auto",
        marginTop: 50,
    },
    mymodal: {
        width: 818,
        overflow: "visible",
        // height: 500,
        // "& .mantine-Modal-content": {
        //   height: "300px !important",
        // },
        // "& .mantine-Modal-inner": {
        //   padding: "0px !important",
        // },
        "& .mantine-Paper-root": {
            overflow: "initial !important",
        },
        "& .mantine-Modal-inner": {
            padding: "0px !important",
        },

        "@media (max-width:400px)": {
            width: "auto",
        },
    },
    mwd: {
        width: "100%",
    },
}));



const Editinsurance= ({ opened, onclose, title, form2,setClientDetails }) => {
    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const { classes } = useStyles();

    // console.log('my form2', form2);
  
   
    const location = useLocation();
    const id = location.state


    const form = useForm({
        initialValues: {
          effectiveDate: new Date(),
          groupNumber: "",
          prescriptionGroup: "",
          subscriberNumber: "",
          subscriberName: "",
          insuranceCompanyName: "",
          isForm1:"false",
        },
        validate: {
          
        }
      });

    useEffect(() => {

        if (form2) {

            form.setValues({

                //...form1,

                effectiveDate: new Date(form2?.effectiveDate),
                groupNumber: form2?.groupNumber,
                prescriptionGroup:form2?.prescriptionGroup,
                subscriberNumber: form2?.subscriberNumber,
                subscriberName: form2?.subscriberName,
                insuranceCompanyName: form2?.insuranceCompanyName
                
                
            

            });


        }
    }, [form2]);

    const prevoiusdata = {
       

        emergencyContactName: form2?.emergencyContactName,
        emergencyContactPhoneNumber: form2?.emergencyContactPhoneNumber,
        emergencyContactEmail: form2?.emergencyContactEmail,
        noCaseManager: form2?.noCaseManager,
        caseManagerName: form2?.caseManagerName,
        caseManagerPhoneNumber: form2?.caseManagerPhoneNumber,
        caseManagerEmail: form2?.caseManagerEmail,
        noGuardian: form2?.noGuardian,
        additionalProvider: form2?.additionalProvider,
        noAdditionalProvider:form2?.noAdditionalProvider,
    pcpEmail:form2?.pcpEmail,
    pcpFaxNumber:form2?.pcpFaxNumber,
    pcpName:form2?.pcpName,
    pcpPhoneNumber:form2?.pcpPhoneNumber,
        
      };

    
     

    const onSave = async(values) => {
      

        // console.log('values',values);
        //setLoading(true);
        try {
            let payload = {
                ...values,...prevoiusdata,id,isForm1 : false
            }
            await EditclientProfile(payload);
        //   const payload = { ...values,...id,...prevoiusdata };
          //await EditclientProfile({form2:payload,clientId:id})
          //await EditclientProfile({payload})
        //   console.log('payload', payload);
        
        //   console.log("Successfully updated profile");
          const data = await getSinglePatients(id)
          setClientDetails(data)
          //resetForm();
          onclose();
    
        } catch (error) {
          console.error("Error updated profille:", error.message);
    
        }
        finally {
          //getMar()
          //setLoading(false);
    
          //window.location.reload();
        }

    }


    const resetForm = () => {
        form?.reset();
        onclose();
    };








    return (
        <>
            <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "600px" }}>
                    {false ? (
                        // <TableLoader/>
                        <Box
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: 'absolute',
                                bottom: '11%',
                                left: '7%'
                                //padding: "100px 0 0 0",
                            }}
                        >
                            <Loader color="yellow" size={"md"} />
                        </Box>
                    ) : (

                        <Modal
                            className={classes.mymodal}
                            opened={opened}
                            size={"xl"}
                            onClose={() => {
                                //form?.reset();
                                onclose();
                            }}
                            shadow="md"
                            overlayProps={{ blur: 3 }}
                            radius={"lg"}
                            zIndex={1000}
                            title={
                                <Group>
                                    <img src="/assets/images/addfac.png" alt="" />
                                    <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
                                        {title}
                                    </Text>
                                </Group>
                            }
                            centered
                        >
                            <form onSubmit={form?.onSubmit((values) => onSave(values))}>
                            <SimpleGrid cols={isMobileScreen ? 1 : 2}>
          <TextInput
            className={classes.myfont}
            label="Insurance Company Name"
            size="sm"
            icon={<img src="/assets/images/sms.png" alt="company" />}
            placeholder="Enter company name"
            sx={{ marginTop: 20 }}
            {...form.getInputProps("insuranceCompanyName")}
            //disabled={view}
          />
          <TextInput
            className={classes.myfont}
            label="Subscriber name"
            size="sm"
            icon={<img src="/assets/images/sms.png" alt="company" />}
            placeholder="Enter subscriber name"
            sx={{ marginTop: 20 }}
            {...form.getInputProps("subscriberName")}
            //disabled={view}
          />
          <TextInput
            className={classes.myfont}
            label="Subscriber Number"
            size="sm"
            min={0}
            icon={<img src="/assets/images/sms.png" alt="company" />}
            placeholder="Enter subscriber number"
            {...form.getInputProps("subscriberNumber")}
            //disabled={view}
          />
          <DateInput
            className={classes.myfont}
            label="Effective Date"
            placeholder="Effective date"
            clearable
            size="sm"
            icon={<img src="/assets/images/sms.png" alt="company" />}
            {...form.getInputProps("effectiveDate")}
            //disabled={view}
          />
          <TextInput
            className={classes.myfont}
            label="Group Number"
            size="sm"
            min={0}
            icon={<img src="/assets/images/sms.png" alt="company" />}
            placeholder="Enter group number"
            {...form.getInputProps("groupNumber")}
            //disabled={view}
          />
          <TextInput
          style={{marginBottom: '30px',}}
            className={classes.myfont}
            label="Prescription Group"
            size="sm"
            icon={<img src="/assets/images/sms.png" alt="company" />}
            placeholder="Enter prescription group number"
            min={0}
            {...form.getInputProps("prescriptionGroup")}
            //disabled={view}
          />
        </SimpleGrid>
                                 
                                    <Button
                                       
                                    color="yellow"
                                    sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                                    type="submit"
                                >
                                    Submit
                                        </Button>
                                       
                            </form>
                        </Modal>
                    )}
                </div>
            </div>
        </>
    );
};

export default Editinsurance;

