import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // overflowX: "auto",
  },
  mainHeading: {
    fontSize: "26px",
    fontWeight: 500,
  },

  tabs: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "& p": {
      // fontSize: "22px",
      fontSize: "14px",
      margin: "0",
      cursor: "pointer",
    },
  },
  active: {
    fontWeight: 500,
    borderBottom: "3px solid #fab005",
  },

  bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0px",
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  right: {
    display: "flex",
    alignItems: "center",
    gap: "10px",

    "& button": {
      // width: '413px',
      // height: "50px",
      padding: "5px 10px", 
      "& img": {
        width: "24px",
      },
    },
  },

  iconBar: {
    // padding: '15px',
    height: "px",
    // padding: "0 15px",
    padding: "5px 10px",
    background: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& img": {
      // width: "35px",
      // height: "35px",
      width: "24px",
      height: "24px"
    },
  },
  iconBarActive: {
    background: "#fae7be",
  },

  filterRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    borderRadius: "10px",
  },
  searchBox: {
    width: "600px",
  },
  searchBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& input": {
      height: "50px",
    },
  },
  filterBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "20px",
  },
  filter: {
    height: "78.77px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& input": {
      height: "50px",
    },
    "& .mantine-Input-icon": {
      paddingLeft: "20px",
    },
    "& .mantine-Input-input": {
      paddingLeft: "3rem",
    },
    "& img": {
      width: "30px",
      height: "auto",
    },
  },
}));

export default useStyles;
