import React, { useEffect } from "react";
import { Box, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import StartedBanner from "../LandingPage/StartedBanner";
import Footer from "../LandingPage/Footer";
import { TrackPageView } from "../../config/mixpanelConfig";

const useStyles = createStyles(() => ({
  header: {
    marginTop: "2rem",
  },
  TCandPP: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
    padding: "2rem 4rem",
  },
  mainHeading: {
    textDecoration: "uppercase",
    fontWeight: "normal",
    textAlign: "left",
  },
  subHeading: {
    textCombineUpright: "uppercase",
  },
  text: {
    textAlign: "justify",
    color: "#737373",
  },
  linkStyle: {
    textDecoration: "none",
  },
  logo_img2: {
    cursor: "pointer",
    width: "10%",
    "@media (max-width :600px)": {
      width: "30%",
    },
  },
}));

const PrivacyPolicy = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    TrackPageView("Privacy Policy")
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <Header />
      </Box>

      <div className={classes.TCandPP}>
        <h2 id="PRIVACY-POLICY" className={classes.mainHeading}>
          PRIVACY POLICY
        </h2>
        <p className={classes.text}>
          We care about data privacy and security. Please review our Privacy
          Policy:{" "}
          {/* <Link
          className={classes.linkStyle}
          target="_blank"
          to={"http://ogiso.io/term-conditions"}
        >
          http://www.ogiso.io/privacy-policy{" "}
        </Link> */}
          <a
            style={{ textDecoration: "none" }}
            href="#PRIVACY-POLICY"
            onClick={() => {
              navigate("/privacy-policy");
              // Scroll to the top of the page
              window.scrollTo(0, 0);
            }}
          >
            https://www.ogiso.io/privacy-policy{" "}
          </a>
          . By using the Services, you agree to be bound by our Privacy Policy,
          which is incorporated into these Legal Terms. Please be advised the
          Services are hosted in the United States. If you access the Services
          from any other region of the world with laws or other requirements
          governing personal data collection, use, or disclosure that differ
          from applicable laws in the United States, then through your continued
          use of the Services, you are transferring your data to the United
          States, and you expressly consent to have your data transferred to and
          processed in the United States.
        </p>
        <h2 id="DMCA" className={classes.mainHeading}>
          1. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
        </h2>
        <span>Notifications</span>
        <p className={classes.text}>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately notify our
          Designated Copyright Agent using the contact information provided
          below (a "Notification"). A copy of your Notification will be sent to
          the person who posted or stored the material addressed in the
          Notification. Please be advised that pursuant to federal law you may
          be held liable for damages if you make material misrepresentations in
          a Notification. Thus, if you are not sure that material located on or
          linked to by the Services infringes your copyright, you should
          consider first contacting an attorney.
          <br />
          <br />
          All Notifications should meet the requirements of DMCA 17 U.S.C. §
          512(c)(3) and include the following information: (1) A physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (2)
          identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works on the Services are covered by the
          Notification, a representative list of such works on the Services; (3)
          identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material; (4) information
          reasonably sufficient to permit us to contact the complaining party,
          such as an address, telephone number, and, if available, an email
          address at which the complaining party may be contacted; (5) a
          statement that the complaining party has a good faith belief that use
          of the material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law; and (6) a statement that the
          information in the notification is accurate, and under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed upon.
        </p>
        <span>Counter Notification</span>
        <p className={classes.text}>
          If you believe your own copyrighted material has been removed from the
          Services as a result of a mistake or misidentification, you may submit
          a written counter notification to [us/our Designated Copyright Agent]
          using the contact information provided below (a "Counter
          Notification"). To be an effective Counter Notification under the
          DMCA, your Counter Notification must include substantially the
          following: (1) identification of the material that has been removed or
          disabled and the location at which the material appeared before it was
          removed or disabled; (2) a statement that you consent to the
          jurisdiction of the Federal District Court in which your address is
          located, or if your address is outside the United States, for any
          judicial district in which we are located; (3) a statement that you
          will accept service of process from the party that filed the
          Notification or the party's agent; (4) your name, address, and
          telephone number; (5) a statement under penalty of perjury that you
          have a good faith belief that the material in question was removed or
          disabled as a result of a mistake or misidentification of the material
          to be removed or disabled; and (6) your physical or electronic
          signature.
          <br />
          <br />
          If you send us a valid, written Counter Notification meeting the
          requirements described above, we will restore your removed or disabled
          material, unless we first receive notice from the party filing the
          Notification informing us that such party has filed a court action to
          restrain you from engaging in infringing activity related to the
          material in question. Please note that if you materially misrepresent
          that the disabled or removed content was removed by mistake or
          misidentification, you may be liable for damages, including costs and
          attorney's fees. Filing a false Counter Notification constitutes
          perjury.
          <br />
          <br />
          Designated Copyright Agent
          <span
            style={{ textAlign: "left", display: "flex", alignItems: "start" }}
          >
            Frank Aikhu <br />
            Attn: Copyright Agent <br />
            2626 E Havard St <br />
            Phoenix, AZ 85008 <br />
            United States <br />
            support@ogiso.io
          </span>
        </p>
        <h2 id="TERM-AND-TERMINATION" className={classes.mainHeading}>
          2. TERM AND TERMINATION
        </h2>
        <p className={classes.text}>
          These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
          OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
          SOLE DISCRETION.
          <br />
          <br />
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress
        </p>
        <h2
          id="MODIFICATIONS-AND-INTERRUPTIONS"
          className={classes.mainHeading}
        >
          3. MODIFICATIONS AND INTERRUPTIONS
        </h2>
        <p className={classes.text}>
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.
          <br />
          <br />
          We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to the Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate us to maintain and support the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
        </p>
        <h2 id="GOVERNING-LAW" className={classes.mainHeading}>
          4. GOVERNING LAW
        </h2>
        <p className={classes.text}>
          These Legal Terms and your use of the Services are governed by and
          construed in accordance with the laws of the State of Arizona
          applicable to agreements made and to be entirely performed within the
          State of Arizona, without regard to its conflict of law principles.
        </p>
        <h2 id="DISPUTE-RESOLUTION" className={classes.mainHeading}>
          5. DISPUTE RESOLUTION
        </h2>
        <span>Informal Negotiations</span>
        <p className={classes.text}>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Legal Terms (each a "Dispute"
          and collectively, the "Disputes") brought by either you or us
          (individually, a "Party" and collectively, the "Parties"), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration. Such informal negotiations commence
          upon written notice from one Party to the other Party.
        </p>
        <sapn>Binding Arbitration</sapn>
        <p className={classes.text}>
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be finally and exclusively resolved by binding
          arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
          HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration
          shall be commenced and conducted under the Commercial Arbitration
          Rules of the American Arbitration Association ("AAA") and, where
          appropriate, the AAA’s Supplementary Procedures for Consumer Related
          Disputes ("AAA Consumer Rules"), both of which are available at the{" "}
          <Link
            target="_blank"
            className={classes.linkStyle}
            to={"http://www.adr.org/"}
          >
            {" "}
            American Arbitration Association (AAA)
          </Link>{" "}
          website. Your arbitration fees and your share of arbitrator
          compensation shall be governed by the AAA Consumer Rules and, where
          appropriate, limited by the AAA Consumer Rules. The arbitration may be
          conducted in person, through the submission of documents, by phone, or
          online. The arbitrator will make a decision in writing, but need not
          provide a statement of reasons unless requested by either Party. The
          arbitrator must follow applicable law, and any award may be challenged
          if the arbitrator fails to do so. Except where otherwise required by
          the applicable AAA rules or applicable law, the arbitration will take
          place in Maricopa, Arizona. Except as otherwise provided herein, the
          Parties may litigate in court to compel arbitration, stay proceedings
          pending arbitration, or to confirm, modify, vacate, or enter judgment
          on the award entered by the arbitrator.
          <br />
          <br />
          If for any reason, a Dispute proceeds in court rather than
          arbitration, the Dispute shall be commenced or prosecuted in the state
          and federal courts located in Maricopa, Arizona, and the Parties
          hereby consent to, and waive all defenses of lack of personal
          jurisdiction, and forum non conveniens with respect to venue and
          jurisdiction in such state and federal courts. Application of the
          United Nations Convention on Contracts for the International Sale of
          Goods and the Uniform Computer Information Transaction Act (UCITA) are
          excluded from these Legal Terms.
          <br />
          <br />
          In no event shall any Dispute brought by either Party related in any
          way to the Services be commenced more than one (1) years after the
          cause of action arose. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <span>Restrictions</span>
        <p className={classes.text}>
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </p>
        <span>Exceptions to Informal Negotiations and Arbitration</span>
        <p className={classes.text}>
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or unauthorized use; and (c) any claim for
          injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <h2 id="CORRECTIONS" className={classes.mainHeading}>
          6. CORRECTIONS
        </h2>
        <p className={classes.text}>
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </p>
        <h2 id="DISCLAIMER" className={classes.mainHeading}>
          7. DISCLAIMER
        </h2>
        <p className={classes.text}>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
          ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
          FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
          WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
          OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
          NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
          EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
        <h2 id="LIMITATIONS-OF-LIABILITY" className={classes.mainHeading}>
          8. LIMITATIONS OF LIABILITY
        </h2>
        <p className={classes.text}>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
          PAID, IF ANY, BY YOU TO US DURING THE TWO (2) MONTH PERIOD PRIOR TO
          ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL
          LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
          OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
          ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
          YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <h2 id="INDEMNIFICATION" className={classes.mainHeading}>
          9. INDEMNIFICATION
        </h2>
        <p className={classes.text}>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Services; (3) breach of these Legal
          Terms; (4) any breach of your representations and warranties set forth
          in these Legal Terms; (5) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or
          (6) any overt harmful act toward any other user of the Services with
          whom you connected via the Services. Notwithstanding the foregoing, we
          reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </p>
        <h2 id="USER-DATA" className={classes.mainHeading}>
          10. USER DATA
        </h2>
        <p className={classes.text}>
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
        </p>
        <h2 id="ECTS" className={classes.mainHeading}>
          11. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </h2>
        <p className={classes.text}>
          Visiting the Services, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <h2 id="CALIFORNIA-USERS-AND-RESIDENTS" className={classes.mainHeading}>
          12. CALIFORNIA USERS AND RESIDENTS
        </h2>
        <p className={classes.text}>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
        <h2 id="MISCELLANEOUS" className={classes.mainHeading}>
          13. MISCELLANEOUS
        </h2>
        <p className={classes.text}>
          These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Legal Terms shall
          not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Legal Terms and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Legal
          Terms or use of the Services. You agree that these Legal Terms will
          not be construed against us by virtue of having drafted them. You
          hereby waive any and all defenses you may have based on the electronic
          form of these Legal Terms and the lack of signing by the parties
          hereto to execute these Legal Terms.
        </p>
        <h2 id="CONTACT-US" className={classes.mainHeading}>
          14. CONTACT US
        </h2>
        <p className={classes.text}>
          In order to resolve a complaint regarding the Services or to receive
          further information regarding use of the Services, please contact us
          at:
          <br />
          <br />
          <span
            style={{ textAlign: "left", display: "flex", alignItems: "start" }}
          >
            Lumen Christi of Arizona LLC <br /> 2626 E Havard St <br /> Phoenix,
            AZ 85008 <br /> United States <br /> Phone: 7573382712 <br />{" "}
            support@ogiso.io
          </span>
        </p>
      </div>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
