import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: "center",
  },
  yellowButton: {
    background: "rgba(255, 186, 69, 0.85)",
  },
  iconButton: {
    // background: "rgba(255, 186, 69, 0.85)", // yellow
    padding: "6px 15px",
    borderRadius: "5px",
    marginInline: 5,
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
