import {
  Box,
  Button,
  Container,
  Loader,
  Select,
  SimpleGrid,
  Textarea,
  createStyles,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import AccordionCheck from "../../../components/AccordionCheck";
import TopBar from "../../../components/Topbar";
import { getAllFacilities } from "../../../services";
import { getAllPatientsModal } from "../../../services/patient";
import { getAllStaff } from "../../../services/staff";
import { addTask, getClientTask } from "../../../services/task";
import { decrypt } from "../../../utils";
import PreviousNotesComp from "../../../components/TaskTable/PreviousNotesComp";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  container: {
    border: "1px solid #dee2e6",
    borderRadius: "15px",
  },
  mainContainer: {
    borderBottom: "1px solid #dee2e6",
    padding: "20px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  mainHeading: {
    margin: "0",
  },
  draft: {
    p: {
      margin: "0",
      border: "1px solid #fab005",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "#fab005",
    },
  },
  completed: {
    p: {
      margin: "0",
      border: "1px solid green",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "green",
    },
  },
  expired: {
    p: {
      margin: "0",
      border: "1px solid red",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "red",
    },
  },
  detail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detail: {
    span: {
      color: "#A8A8A8",
    },
  },
  detail: {
    p: {
      // fontWeight: '600',
      margin: "0",
      // textAlign: "center",
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  info: {
    p: {
      margin: "0",
      textAlign: "center",
    },
  },
  btnFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
    gap: "20px",
  },
  divGap: {
    margin: "20px 0",
    alignItems: "center",
  },
}));
const AddTask = () => {
  const { classes } = useStyles();
  const isMoobileScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery("(max-width: 1030px)");

  const navigate = useNavigate();
  const [state, setState] = useState(null);
  const [taskTypeDetails, setTypeDetails] = useState(null);
  const [notes, setNotes] = useState("");
  const [checks, setChecks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingClientTasks, setLoadingClientTasks] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const { allPatients } = useSelector((state) => state?.patient);
  const { allFacilities } = useSelector((state) => state?.facility);
  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;
  let taskType = localStorage.getItem("taskType");
  let taskTypeName = localStorage.getItem("taskTypeName");

  useEffect(() => {
    getAllPatientsModal();
    getAllStaff("all");
    getAllFacilities("all");
  }, []);

  const form = useForm({
    initialValues: {
      client: state?.client ? state?.client?.id : "",
      facility: state?.facility ? state?.facility?.id : "",
      shift: "",
    },
    validate: {
      client: (value) => (value ? null : "Client is Required"),
      facility: (value) => (value ? null : "Facility is Required"),
      shift: (value) =>
        value
          ? // value || state?.shift?.find((item) => item.staff === profile?.user?.id)
            null
          : "Shift is Required",
    },
  });

  const resetForm = () => {
    form?.reset();
  };

  const onSave = async (values) => {
    setLoading(true);
    let payload;
    if (state?.startedBy) {
      let emptyShifts = checks?.filter((obj) => !obj.shift);
      let withShifts = checks?.filter((obj) => obj.shift);

      let filledShifts = emptyShifts?.map((item) => {
        return { ...item, shift: values.shift };
      });
      //update
      payload = {
        ...values,
        isStarted: false,
        notes,
        fieldsDone: [...withShifts, ...filledShifts],
        taskId: state?._id,
      }; //update wala payload
    } else {
      //add
      payload = {
        ...values,
        isStarted: true,
        notes,
        fieldsDone: checks,
        taskType,
      };
    }
    await addTask(payload, resetForm, navigate, setLoading);
  };

  const AuctionTimer = () => {
    const [remainingTime, setRemainingTime] = useState("");

    useEffect(() => {
      let interval;
      interval = setInterval(() => {
        const now = moment();
        const end = moment(state?.taskEndAt);
        const duration = moment.duration(end.diff(now));
        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setRemainingTime("Task Ended!");
          setHasEnded(true);
        } else {
          const days = Math.floor(duration.asDays());
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          setRemainingTime(
            days === 0
              ? `${hours}h ${minutes}m ${seconds}s`
              : `${days}d ${hours}h ${minutes}m ${seconds}s`
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [state?.taskEndAt]);

    return (
      <>
        <div style={{ minWidth: "130px" }}>
          <p
            style={{
              textTransform:
                remainingTime === "Task Ended!" ? "initial" : "lowercase",
              fontSize: remainingTime === "Task Ended!" ? "16px" : "18px",
            }}
          >
            {remainingTime || "-"}
          </p>
        </div>
      </>
    );
  };

  return (
    <Container fluid className={classes.content} px={20}>
      <TopBar title={"Add Task"} />
      <Box className={classes.container}>
        <form onSubmit={form?.onSubmit((values) => onSave(values))}>
          <Box className={classes.mainContainer}>
            <div className={classes.flex}>
              <h2 className={classes.mainHeading}>{taskTypeName || ""}</h2>
              {state?.status === "draft" && !hasEnded && (
                <div className={classes.draft}>
                  <p>Draft</p>
                </div>
              )}
              {hasEnded && (
                <div className={classes.completed}>
                  <p>Completed</p>
                </div>
              )}
              {/* {state?.status === "expired" && (
              <div className={classes.expired}>
                <p>Expired</p>
              </div>
            )} */}
            </div>
            <SimpleGrid
              cols={isMoobileScreen ? 1 : isTabletScreen ? 3 : 4}
              className={classes.divGap}
            >
              <div className={classes.detail}>
                {/* <p>
                  <strong>Started By Staff: </strong> {state?.startedBy?.name}
                </p> */}
                <Select
                  label="Started By Staff"
                  placeholder={
                    state?.startedBy
                      ? state?.startedBy?.name
                      : profile?.user?.name
                  }
                  disabled={true}
                  data={[]}
                />
              </div>
              <div className={classes.info}>
                <Select
                  id="add-task-client-name"
                  label="Client Name"
                  value={state?.client?.id}
                  placeholder={
                    state?.client
                      ? decrypt(state?.client?.name, false)
                      : "Client"
                  }
                  // disabled={state?.client}
                  onChange={async (value) => {
                    setLoadingClientTasks(true);
                    form.setFieldValue("client", value);
                    let facility = allPatients?.find(
                      (item) => item.id === value
                    )?.facilityId?.id;
                    form.setFieldValue("facility", facility);
                    let data = await getClientTask({
                      client: value,
                      type: taskType,
                    });
                    if (data?.task) {
                      setState({ ...data?.task });
                      setTypeDetails(data?.taskType);
                      let fieldIds = data?.task?.fieldsDone?.map((item) => {
                        return {
                          field: item?.field?._id,
                          staff: item?.staff?._id,
                          shift: item?.shift,
                        };
                      });
                      // console.log("fieldIds", fieldIds);
                      setChecks(fieldIds);
                      // setNotes(data?.task?.notes);
                      setLoadingClientTasks(false);
                    } else {
                      setState(null);
                      setTypeDetails(data?.taskType);
                      setLoadingClientTasks(false);
                      setChecks([]);
                      // setNotes("");
                    }
                    // console.log("data", data);
                  }}
                  data={
                    allPatients?.map((data) => {
                      return {
                        value: data?.id,
                        label: data?.name,
                      };
                    }) || []
                  }
                  // {...form.getInputProps("client")}
                />

                {/* <p>
                {decrypt(state?.client?.name, false) || state?.client?.name}
              </p> */}
              </div>
              <div className={classes.info}>
                <Select
                  label="Facitlity"
                  placeholder={
                    state?.client ? state?.facility?.name : "Facility"
                  }
                  // disabled={true}
                  // disabled={state?.facility}
                  value={state?.facility?.id}
                  data={
                    allFacilities?.facilities?.map((data) => {
                      return {
                        value: data?.id,
                        label: data?.name,
                      };
                    }) || []
                  }
                  {...form.getInputProps("facility")}
                />

                {/* <p>Facility Name</p> */}
                {/* <p>{state?.facility?.name}</p> */}
              </div>
              <div className={classes.info}>
                {state?.shift?.find(
                  (item) => item.staff === profile?.user?.id
                ) ? (
                  <Select
                    label="Shift"
                    placeholder={
                      state?.shift?.find(
                        (item) => item.staff === profile?.user?.id
                      )?.name
                    }
                    disabled={true}
                    // value={
                    //   state?.shift?.find(
                    //     (item) => item.staff === profile?.user?.id
                    //   )?.name
                    // }
                    data={["Morning", "Evening", "Night"]}
                    // {...form.getInputProps("shift")}
                  />
                ) : (
                  <Select
                    id="add-task-staff"
                    label="Shift"
                    placeholder={"Select Shift"}
                    data={["Morning", "Evening", "Night"]}
                    {...form.getInputProps("shift")}
                  />
                )}
                {/* <p>Shift</p>
              <p>{state?.shift || "-"}</p> */}
              </div>
              <div className={classes.detail}>
                <p>
                  <strong>Date Created: </strong>{" "}
                  {state?.dateTime &&
                    moment(
                      state?.dateTime ? state?.dateTime : state?.createdAt
                    ).format("MM-DD-YYYY")}{" "}
                  at{" "}
                  {state?.dateTime
                    ? moment(state?.dateTime).format("hh:mm a")
                    : "-"}
                </p>
              </div>
              <div className={classes.detail}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <strong>Time Remaining: </strong>{" "}
                  {state?.dateTime && state?.taskEndAt ? <AuctionTimer /> : "-"}
                </p>
              </div>

              {/* <div className={classes.info}>
                <p>
                  <strong>Time: </strong>
                  
                </p>
              </div> */}
            </SimpleGrid>
            {/* <SimpleGrid cols={5}>
            <div className={classes.info}>
              <p>Client Name</p>
              <p>
                {decrypt(state?.client?.name, false) || state?.client?.name}
              </p>
            </div>
            <div className={classes.info}>
              <p>Facility Name</p>
              <p>{state?.facility?.name}</p>
            </div>
            <div className={classes.info}>
              <p>Time</p>
              <p> {moment(state?.dateTime).format("hh:mm A")}</p>
            </div>
            <div className={classes.info}>
              <p>Shift</p>
              <p>{state?.shift || "-"}</p>
            </div>
          </SimpleGrid> */}
          </Box>

          <Box className={classes.mainContainer}>
            <div>
              <h3>Task List</h3>
            </div>
            {loadingClientTasks ? (
              <div
                style={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader size={40} color="yellow" />
              </div>
            ) : (
              <SimpleGrid cols={isMoobileScreen ? 1 : isTabletScreen ? 3 : 4}>
                {taskTypeDetails?.subTasks?.map((tasks) => (
                  <div>
                    <AccordionCheck
                      data-testid="task-grid"
                      Data={tasks?.fields}
                      checksDone={state?.fieldsDone || []}
                      Heading={tasks?.task?.name}
                      setChecks={setChecks}
                      checks={checks}
                      userType={userType}
                      userId={profile?.user?.id}
                      shift={form.getInputProps("shift")?.value}
                    />
                  </div>
                ))}
              </SimpleGrid>
            )}
          </Box>
          <Box className={classes.mainContainer}>
            <div>
              <h3>Additional Notes</h3>
              {/* <div className={classes.flex}>
              <Radio label="Yes" />
              <Radio label="No" />
            </div> */}
              <div className={classes.divGap}>
                <Textarea
                  id="additional-note"
                  defaultValue={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  disabled={hasEnded}
                  minRows={4}
                  style={{ height: "auto" }}
                />
              </div>
              {!hasEnded && (
                <div className={classes.btnFlex}>
                  <Button
                    color="yellow"
                    onClick={() => {
                      navigate("/home/tasks");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="ad-task-start"
                    color="yellow"
                    type="submit"
                    loading={loading}
                  >
                    {state?.startedBy ? "Save" : "Start"}
                  </Button>
                </div>
              )}
            </div>
          </Box>
          <Box className={classes.mainContainer}>
            <h3>Previous Notes</h3>
            <div className={classes.divGap}>
              <PreviousNotesComp notes={state?.notes} />
            </div>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default AddTask;
