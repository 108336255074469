import { https } from "../http";
import store from "../store";
import { showError, showInfo } from "../utils/index";
import { setLoading } from "../store/reducers/auth";
import { getProfile } from "./profile";

// POST
const selectPlan = async (data) => {
  try {
    //   store.dispatch(setAddStaffLoading(true));
    let response = await https().post("/subscription/create", data);

    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);

    return error?.response?.data?.message;
  }
};

const selectTrial = async (data) => {
  try {
    let response = await https().post("/subscription/create-trial", data);

    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);

    return error?.response?.data?.message;
  }
};
const updatePlan = async (data) => {
  try {
    let response = await https().post("/payment/upgrade-plan", data);

    if (response?.status === 200) {
      getProfile();
      return true;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
    return error?.response?.data?.message;
  }
};

// POST
const createSubscription = async (data, navigate) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/payment/paynow", data);

    if (response?.status === 200) {
      store.dispatch(setLoading(false));
      return response;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    if (
      error?.response?.data?.message?.toLowerCase() ===
      "stripe customer not found"
    )
      return navigate("/payInfo", {
        state: {
          planId: data?.packageId,
          stripePlan: data?.stripePlanId
        }
      });
    showError(error?.response?.data?.message, 500);
  }
};
const cancelSubscription = async (data) => {
  try {
    let response = await https().post("/payment/cancel-subscription", data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, 500);
    return error;
  }
};

const forcePayment = async () => {
  try {
    let response = await https().post("/payment/force-payment");

    if (response?.status === 200) {
      getProfile();
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, 500);
  }
};

const getSubscription = async (callback) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().get("/payment/getSubscription");
    if (response.status === 200) callback(null, response?.data);
  } catch (error) {
    callback(error);
  }
};
const getUserSubscription = async (callback) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().get("/subscription");
    if (response.status === 200) callback(null, response?.data);
  } catch (error) {
    callback(error);
  }
};

const updateSubscriptionCustomer = async (data) => {
  try {
    let response = await https().put(
      "/payment/update-subscription-customer",
      data
    );
    if (response.status === 200) return response;
  } catch (error) {
    showError(error?.response?.data?.message, 500);
    return error;
  }
};

export {
  getUserSubscription,
  createSubscription,
  selectPlan,
  selectTrial,
  updatePlan,
  getSubscription,
  forcePayment,
  cancelSubscription,
  updateSubscriptionCustomer
};
