import React from "react";
import useStyles from "./stylesComponents";
import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
function SearchBar() {
  const { classes } = useStyles();
  return (
    <div
      style={{ background: "rgba(0, 0, 0, 1)", padding: 1, margin: "10px 0px" }}
    >
      <div className={classes.row}>
        <Input
          icon={<IconSearch />}
          placeholder="Search Documents..."
          style={{ width: 500, marginLeft: 15 }}
        />
      </div>
    </div>
  );
}

export default SearchBar;
