import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  marDetailModal: {
    "& .mantine-19pz3dh": {
      display: "none",
    },
    "& .mantine-Modal-body": {
      padding: 0,
    },
    "& .mantine-Paper-root": {
      overflow: "initial",
    },
    "& .mantine-Modal-content": {
      borderRadius: 0,
    },
  },
  moreRow: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      color: "#5C5C5C",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      cursor: "pointer",
    },
  },
  header: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    borderBottom: "1px solid #D9D9D9",
    paddingInline: "30px",
    marginTop: "20px",
    "& h2": {
      fontSize: "36px",
      fontWeight: 500,
    },
    "& p": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#5C5C5C",
      textTransform: "uppercase",
    },
  },
  cardWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  modalBody: {
    margin: "20px",
    position: "sticky",
  },
  medBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxHeight: "60vh",
    overflowY: "auto",
    padding: "20px",
    borderBottom: "1px solid #D9D9D9",
  },

  // CARD
  cardContainer: {
    width: "100%",
    height: "70px",
    borderRadius: "5px",
    display: "flex",
    paddingInline: "20px",
    paddingBlock: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "& p": {
      margin: 0,
    },
    "& h2": {
      margin: 0,
    },
    "& h3": {
      margin: 0,
    },
  },
  redText: {
    color: "#FF0000",
  },

  saveButton: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "10px",
    "& button": {
      borderRadius: "5px",
      background: "rgba(255, 186, 69, 0.85)",
      width: "145px",
      height: "50px",
      color: "#000",

      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",

      "&:hover": {
        background: "rgba(255, 186, 69, 0.85)",
      },
    },
  },
  box1: {
    width: "130px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& > div": {
      "& h2": {
        fontSize: "16px",
        fontWeight: 600,
        // lineHeight: 1,
      },
      "& p": {
        fontSize: "8px",
        fontWeight: 300,
        // lineHeight: 1,
      },
    },
    "& > img": {
      width: "18px",
      height: "18px",
    },
  },
  box2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& > p": {
      fontSize: "12px",
      fontWeight: 400,
      marginLeft: "5px",
      // lineHeight: 1,
    },
    "& > div": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      "& h2": {
        fontSize: "20px",
        fontWeight: 500,
        // lineHeight: 1,
      },
      "& p": {
        fontSize: "12px",
        fontWeight: 400,
        // lineHeight: 1,
      },
    },
  },
  box3: {
    width: "150px",
    "& > p:nth-of-type(1)": {
      fontSize: "8px",
      fontWeight: 300,
      textAlign: "center",
      // lineHeight: 1,
    },
    "& > p:nth-of-type(2)": {
      fontSize: "12px",
      fontWeight: 300,
      textAlign: "center",
      // lineHeight: 1,
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      "& > p": {
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
        // lineHeight: 1,
      },
    },
  },
  box4: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "38px",
    height: "38px",
    // border: "1px solid black",
    "& h3": {
      margin: "0",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    "& p": {
      margin: "0",
      textAlign: "center",
      fontSize: "6px",
      color: "#5C5C5C",
      lineHeight: "1",
    },
  },
  outOfmedication: {
    "& p": {
      color: "#fff !important",
    },
    "& h2": {
      color: "#fff !important",
    },
    "& h3": {
      color: "#fff !important",
    },
  },
  outcome: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  quantityInput: {
    "& input": {
      borderRadius: "5px",
      width: "160px",
      height: "100px",
      border: "1px solid #979797",
      fontSize: "60px",
      fontWeight: "bold",
      textAlign: "center",
    },
  },
  dispensed: {
    display: "flex",
    // justifyContent: 'center',
    alignItems: "center",
    flexDirection: "column",
    "& h3": {
      fontWeight: "400",
      fontSize: "36px",
      margin: "0",
      marginBottom: "10px",
    },
  },
  radioContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "& .mantine-1svz3tp": {
      color: "#232323",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "800",
      lineHeight: "20px",
    },
    "& .mantine-1cu6yj8:checked": {
      background: "#FFBA45",
      borderColor: "#FFBA45",
    },
  },
  comments: {
    "& .mantine-1ix1d88": {
      borderRadius: "15px",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
    flexDirection: "column",
    width: "420px",
    margin: "1rem auto",
  },
  topButtons: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
  },
}));

export default useStyles;
