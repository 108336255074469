import {
  Anchor,
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  createStyles,
  rem,
} from "@mantine/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { toggleTheme } from "../../store/reducers/theme";

const useStyles = createStyles((theme) => ({
  container: {
    // display: console.log("flex",theme),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px dashed rgba(57, 57, 57, 0.20)",
    height: rem(80),
    "@media (max-width:768px)": {
      justifyContent: "space-between",
    },
  },
  link: {
    marginTop: rem(38),
  },
  linkgroup: {
    FontFamily: "Inter",
    gap: 75,
    "@media (max-width: 1440px)": {
      gap: 45,
    },
    "@media (max-width: 650px)": {
      display: "none",
    },
  },
  navbar_link: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    "@media (max-width: 1440px)": {
      fontSize: "16px",
    },
  },
  login: {
    fontFamily: "Inter",
    marginRight: rem(100),
    "@media (max-width: 768px)": {
      marginRight: 30,
    },
    "@media (max-width: 650px)": {
      display: "none",
    },
    display: "flex",
    width: "120px",
    height: "50px",
    // padding: "12px 20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    color: "#FFF",
    textAlign: "center",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  loginMobile: {
    fontFamily: "Inter",
    marginLeft: 30,
  },
  logo: {
    cursor: "pointer",
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important",
    },
    "@media (max-width:768px)": {
      marginLeft: 30,
    },
    "@media (max-width:330px)": {
      marginLeft: 10,
    },
  },
  menu: {
    width: 35,
    height: 35,
    objectFit: "contain",

    marginRight: 30,
    display: "none",
    "@media (max-width:650px)": {
      display: "block",
    },
    "@media (max-width:330px)": {
      marginRight: 10,
    },
  },

  customDrawer: {
    width: 0,
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#f2f2f2",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 1,
  },

  customDrawerOpen: {
    height: "100%",
    width: "250px !important",
    position: "fixed",
    top: 0,
    left: 0,
    // backgroundColor: "#FFBA45",
    backgroundColor: "#FFF",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 999,
  },

  drawerContent: {
    padding: "20px",
    width: "100%",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
    margin: "20px 10px",
  },
  textColor: {
    color: theme.primaryColor,
  },
  secondaryColor: {
    color: theme.other.successColor, 
  },

  /* Add more styles as needed */
}));

const Header = ({ onClicks, contactFeature, contactPlane }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  // Function to toggle the navbar visibility
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    navigate("/");
  };

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  return (
    <Box className={classes.container}>
      <Image
        src="/assets/images/logo2.png"
        mr={"auto"}
        fit="fill"
        ml={99}
        className={classes.logo}
        onClick={handleClick}
      />
      <div>
        <Button onClick={() => dispatch(toggleTheme())}>
          Switch to {theme.colorScheme === "light" ? "Dark" : "Light"} Mode
        </Button>
        <div className={classes.textColor}>color</div>
          <div className={classes.secondaryColor}>secondaryColor</div>
      </div>
      <SimpleGrid cols={3} mx={"auto"} className={classes.linkgroup}>
        <Anchor
          className={classes.navbar_link}
          onClick={() => {
            if (contactFeature) {
              localStorage.setItem("contactFeature", true);
              navigate("/", { state: { scrollFeature: true } });
            } else {
              onClicks?.onPressFeature();
            }
          }}
        >
          
          <Center>Feature</Center>
        </Anchor>
        <Anchor
          className={classes.navbar_link}
          onClick={() => {
            if (contactPlane) {
              localStorage.setItem("contactPlane", true);
              navigate("/", { state: { scrollPlane: true } });
            } else {
              onClicks?.onPressPlan();
            }
          }}
        >
          <Center>Plan</Center>
        </Anchor>
        <Anchor
          className={classes.navbar_link}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          <Center>Contact Us</Center>
        </Anchor>
      </SimpleGrid>
      <Button
        color="yellow"
        ml={"auto"}
        className={classes.login}
        onClick={() => navigate("/login")}
      >
        Login
      </Button>
      <img
        src="/assets/images/menu.png"
        className={classes?.menu}
        onClick={toggleDrawer}
        alt="menu"
      />

      {isOpen ? (
        <div className={classes?.customDrawerOpen}>
          <div className={classes.drawerContent}>
            <span onClick={toggleNavbar}>
              <img width={25} src="/assets/images/closeIcon.png" alt="close" />
            </span>
            <Image
              src="/assets/images/logo3.png"
              mr={"auto"}
              fit="fill"
              ml={99}
              className={classes.logo}
            />
            <div className={classes.links}>
              <Anchor className={classes.navbar_link}>
                <Center>Feature</Center>
              </Anchor>
              <Anchor className={classes.navbar_link}>
                <Center>Plan</Center>
              </Anchor>
              <Anchor className={classes.navbar_link}>
                <Center>Contact Us</Center>
              </Anchor>
            </div>
            <Button
              color="yellow"
              ml={"auto"}
              className={classes.loginMobile}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.customDrawer}>
          <div className={classes.drawerContent}></div>
        </div>
      )}
    </Box>
  );
};

export default Header;
