import React from "react";

import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { addFacility, editFacility, getFacilityTypes } from "../../services";
import { useForm } from "@mantine/form";
import { states } from "../../utils";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 400,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const FacilityModal = ({ opened, close, title, view, setView, ...data }) => {
  console.table({
    opened: opened,
    close: close,
    title: title,
    view: view,
    setView: setView,
    data: { ...data },
  });
  const { allFacilityTypes, loadingAddFacility } = useSelector(
    (state) => state.facility
  );
  const { classes } = useStyles();
  const edit = data.data ? true : false;

  const form = useForm({
    initialValues: {
      name: edit ? data?.data?.name : "",
      type: edit ? data?.data?.type?.id : null,
      location: edit ? data?.data?.addressLineTwo : "",
      address: edit ? data?.data?.address : "",
      zip: edit ? data?.data?.zipcode : "",
      state: edit ? data?.data?.state : null,
    },

    validate: {
      name: (value) => (value ? null : "Required"),
      type: (value) => (value ? null : "Required"),
      // location: (value) => (value ? null : "Required"),
      address: (value) => (value ? null : "Required"),
      zip: (value) => null,
      state: (value) => null,
    },
  });

  // functions
  const onSave = (values) => {
    if (edit) {
      editFacility({
        ...values,
        zipcode: values?.zip,
        id: data?.data?.id,
      });
      close();
      form?.reset();
    } else {
      addFacility({ ...values, zipcode: values?.zip });
      close();
      form?.reset();
    }
    // close();
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={"xl"}
      onClose={() => {
        // console.log("clicked cancel");
        close();
        view && setView(false);
      }}
      shadow="md"
      overlayProps={{ blur: 3 }}
      zIndex={1000}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {view ? "View Facility" : title}
          </Text>
        </Group>
      }
      centered
    >
      <form onSubmit={form?.onSubmit((values) => onSave(values))}>
        <SimpleGrid
          cols={2}
          className={classes.mwd}
          sx={{
            paddingTop: 20,
            borderTopWidth: 1,
            borderTopColor: "#CBCBCB66",
            borderTopStyle: "solid",
          }}
        >
          {/* facility name */}
          <TextInput
            id="facility-name"
            label="Facility Name*"
            size="lg"
            icon={<img src="/assets/images/home2.png" alt="home" />}
            placeholder="Ex: Valerie Liberty"
            {...form.getInputProps("name")}
            disabled={view}
          />
          {/* facilit type */}
          <Select
            id="facility-type"
            searchable
            label="Facility Type*"
            size="lg"
            data={
              allFacilityTypes?.ftypes?.map((val) => ({
                value: val?.id,
                label: val?.name,
              })) || []
            }
            icon={<img src="/assets/images/building.png" alt="home" />}
            placeholder="Select Facility Type"
            {...form.getInputProps("type")}
            disabled={view}
          />
        </SimpleGrid>
        {/* address */}
        <TextInput
          id="address"
          label="Address"
          size="lg"
          icon={<img src="/assets/images/location.png" alt="address" />}
          placeholder="Ex: Flate No/House No/Apt No"
          sx={{ marginTop: 20 }}
          {...form.getInputProps("address")}
          disabled={view}
        />
        {/* address two */}
        <TextInput
          id="address-two"
          label="Address Line 2"
          size="lg"
          icon={<img src="/assets/images/location.png" alt="address" />}
          sx={{ marginTop: 20 }}
          placeholder="Place/Area etc."
          {...form.getInputProps("location")}
          disabled={view}
        />
        {/* zipcode */}
        <SimpleGrid cols={2} sx={{ marginTop: 20 }}>
          <TextInput
            id="zipcode"
            label="Zipcode"
            size="lg"
            icon={<img src="/assets/images/location2.png" alt="address" />}
            placeholder="Ex: 00001"
            type="number"
            {...form.getInputProps("zip")}
            disabled={view}
          />
          {/* state */}
          <Select
            id="state"
            searchable
            label="State"
            data={states}
            size="lg"
            placeholder="Choose One"
            {...form.getInputProps("state")}
            icon={<img src="/assets/images/map.png" alt="address" />}
            disabled={view}
          />
        </SimpleGrid>
        {view ? (
          <div style={{ height: "30px" }} />
        ) : (
          <SimpleGrid
            cols={2}
            sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
          >
            <Button
              color="yellow"
              sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              id="facility-save"
              loading={loadingAddFacility}
              color="yellow"
              sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
              type="submit"
            >
              Save
            </Button>
          </SimpleGrid>
        )}
      </form>
    </Modal>
  );
};

export default FacilityModal;
