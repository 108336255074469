import { https } from "../http";
import store from "../store";

import {
  setTickets,
  setLoading,
  setLoadingAddTicket,
  setTicketUnreadCount,
} from "../store/reducers/ticket";
import { showError, showInfo } from "../utils/index";

// POST
const createTicket = async (data) => {
  try {
    store.dispatch(setLoadingAddTicket(true));
    let response = await https().post("/ticket", data);

    if (response?.status === 200) {
      getTickets(false);
      showInfo("Ticket Created successfully");
      // callback?.();
    }
  } catch (error) {
    console.log("error", error);
    store.dispatch(setLoadingAddTicket(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// GET
const getTickets = async (page, showLoading = true, id) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let string = "";
    if (id) string = `/ticket/?id=${id}`;
    else string = `/ticket/?page=${page}&perPage=10`;

    let response = await https().get(string);

    if (response?.status === 200) {
      store.dispatch(setLoading(false));
      if (!id) store.dispatch(setTickets(response?.data));
      else return response.data;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const readTickets = async () => {
  try {
    let string = `/ticket/read-tickets`;
    let response = await https().get(string);
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getUnreadCount = async () => {
  try {
    let string = `/ticket/get-unread-count`;
    let response = await https().get(string);
    store.dispatch(setTicketUnreadCount(response?.data));
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// PUT
const editTicket = async (data, showLoading = true, callback = null) => {
  try {
    store.dispatch(setLoadingAddTicket(true));
    let response = await https().put(`/ticket`, data);

    if (response?.status === 200) {
      getTickets(showLoading);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddTicket(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { createTicket, editTicket, getTickets, readTickets, getUnreadCount };
