import React, { useEffect } from "react";
import {
  Text,
  Modal,
  createStyles,
  Group,
  Select,
  Button,
  TextInput,
  Box,
  rem,
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import MarViewReportModal from "./MarViewModal";
import { DropDownArrowSvg } from "../../../components/Svgs/DropDownArrowSvg";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    "& .mantine-Modal-content": {
      height: "600px !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  reportInputFields: {
    margin: "auto",
    marginBottom: 10,
    borderBottomColor: "#FFBA4599",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    paddingBottom: 20,
    display: "flex",
    flexDirection: "row",
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}));

const AddMarReportModal = ({ opened, onclose, title, userId }) => {
  const { classes } = useStyles();
  const { patients } = useSelector((state) => state.patient);
  const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;
  const [marViewModalOpened, setMarViewModalOpened] = React.useState(false);

  const form = useForm({
    initialValues: {
      // reportName: "",
      monthValue: [],
      selectedClient: null,
    },
    validate: {
      // reportName: (value) => (value ? null : "Please enter a report name."),
      monthValue: (value) =>
        value.length > 0 ? null : "Please select at least one month.",
      selectedClient: (value) => (value ? null : "Please select a client."),
    },
  });

  const handleReportClick = () => {
    if (form.validate().hasErrors) return;
    setMarViewModalOpened(true);
  };

  const handleMarViewModalClose = () => {
    setMarViewModalOpened(false);
  };

  const handleClose = () => {
    form.reset();
    onclose();
  };

  useEffect(() => {
    if (userId && opened) { 
      form.setFieldValue("selectedClient", userId);
    }
  }, [userId, opened]);

  return (
    <>
      <Modal
        centered
        size={600}
        shadow="md"
        radius={"lg"}
        zIndex={1000}
        opened={opened}
        onClose={handleClose}
        overlayProps={{ blur: 3 }}
        className={classes.mymodal}
        title={
          <Group style={{ zIndex: -1 }}>
            <img src="/assets/images/addfac.png" alt="" />
            <Text ff={"Inter"} fw={600} fz={22}>
              {title}
            </Text>
          </Group>
        }
      >
        <form onSubmit={form.onSubmit(handleReportClick)}>
          {/* <TextInput
            size="md"
            label="Report Name"
            placeholder="Ex: Newman Audit"
            {...form.getInputProps("reportName")}
          /> */}
          <MonthPickerInput
            mt={10}
            size="md"
            clearable
            type="range"
            allowSingleDateInRange
            label="Select Months"
            placeholder="Select Months"
            {...form.getInputProps("monthValue")}
          />
          <Select
            mt={10}
            size="md"
            label="Client"
            disabled={userId}
            rightSection={icon}
            placeholder="Select Client"
            data={patients?.patients?.map((patient) => ({
              value: patient.id,
              label: patient.name,
            }))}
            onChange={(_value) => console.log("onchange value e", _value)}
            {...form.getInputProps("selectedClient")}
          />
          <Box className={classes.viewBtn}>
            <Button color="yellow" c="#000" mt={20} size="md" type="submit">
              View Report
            </Button>
          </Box>
        </form>
      </Modal>
      <MarViewReportModal
        // reportName={form.values.reportName}
        monthValue={form.values.monthValue}
        selectedClient={patients?.patients?.find(
          (patient) => patient.id === form.values.selectedClient
        )}
        opened={marViewModalOpened}
        onClose={handleMarViewModalClose}
      />
    </>
  );
};

export default AddMarReportModal;
