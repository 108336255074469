import React from "react";
import { Box, Container, createStyles, getStylesRef } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../components/AddButton";
import FacilityTable from "../../../components/FacilityTable";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import Topbar from "../../../components/Topbar";
import FacilityModal from "../../../containers/FacilityModal";
import { getAllFacilities, getAllFacilityTypes } from "../../../services";
import { IconSettingsPlus } from "@tabler/icons-react";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },

  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 10,

    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  btnIcon: {
    ref: getStylesRef("btnIcon"),
    background: "#FFBA45",
    borderRadius: 5,
    cursor: "pointer",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
}));

const Facility = (props) => {
  const { facilities } = useSelector((state) => state.facility);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAllFacilityTypes();
    TrackPageView("Facility");
  }, []);

  useEffect(() => {
    getAllFacilities(currentPage);
  }, [currentPage]);

  useEffect(() => {}, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  return (
    <Container fluid className={classes.content} px={20}>
      <FacilityModal opened={opened} close={close} title={"Add New Facility"} />
      <Topbar title={"Facility Management"} />

      <Box className={classes.btn}>
        <AddButton title={"Add New Facility"} onClick={open} />

        <IconSettingsPlus
          size={40}
          stroke={1}
          color="black"
          className={classes.btnIcon}
          onClick={() => navigate("/home/facilitytype")}
        />
      </Box>
      <Box className={classes.table}>
        <FacilityTable facilities={facilities?.facilities} />
        <CustomPagination
          itemsPerPage={10}
          data={facilities?.count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default Facility;
