import { Button, Tabs, Text } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { FileFolderRadio, RowGridRadio } from "./radio";

import { useSelector } from "react-redux";
import { IconFolderPlus, IconArrowLeft } from "@tabler/icons-react";
import { FileItem, FileTable } from "./file";
import { FolderItem, FolderTable } from "./folder";
import useStyles from "./styles";
import UploadDocument from "./uploadDocument";
import SearchBar from "./searchBar";
import TableLoader from "../TableLoader";
import { useDisclosure } from "@mantine/hooks";
import CreateFolderModal from "./CreateFolderModal";
import { getFiles, getFolders } from "../../services/Document";
import RecordNotFound from "../RecordNotFound";

function Document({ client }) {
  const { classes } = useStyles();
  const [type, setType] = useState("folder");
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [view, setView] = useState("grid");
  const [expiredTabLoading, setExpiredTabLoading] = useState(false);
  const [tabState, setTabState] = useState('active')
  const [opened, { open, close }] = useDisclosure(false);
  const { getFolderLoading, folders, files, getFileLoading } = useSelector(
    (state) => state.document
  );

  const getFoldersData = async () => {
    await getFolders(client);
  };

  const getFilesData = async () => {
    await getFiles(client, selectedFolder?._id);
  };
  useEffect(() => {
    if (type === "folder") {
      getFoldersData();
    } else {
      getFilesData();
    }
  }, [type, selectedFolder]);

  useEffect(() => {
    if (tabState == "expired") {
      setExpiredTabLoading(true);
      setTimeout(() => {
        setExpiredTabLoading(false);
      }, 2000);
    }
  }, [tabState])

  const GridView = () => {
    return (
      <div>
        {type === "file" ? (
          <>
            <Text
              my={5}
              ta={"center"}
              style={{ fontSize: "20px", fontWeight: 500 }}
            >
              {selectedFolder ? selectedFolder?.name : "All Documents"}
            </Text>
            <div
              style={{ justifyContent: files ? "start" : "center" }}
              className={classes.row}
            >
              {getFileLoading ? (
                <TableLoader />
              ) :  (
                <>
                  <Tabs
                    value={tabState}
                    style={{ width: "100%" }}
                    onTabChange={(e) => {
                      setTabState(e)
                    }}
                  >
                    <Tabs.List style={{ gap: "39px" }}>
                      <Tabs.Tab value="active">Active</Tabs.Tab>
                      <Tabs.Tab value="expired">Expired</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="active">
                      <div
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                          display: "flex",
                          alignItems: "start",
                          columnGap: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {files?.activeFiles?.map((file) => (
                          <FileItem client={client} data={file} />
                        ))}
                      </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="expired" onChange={(e) => console.log('expired tab click', e)}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                          display: "flex",
                          alignItems: "start",
                          columnGap: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {expiredTabLoading ? <TableLoader color="yellow" size={"md"} /> : files?.expiredFiles?.length > 0 ? files?.expiredFiles?.map((file) => (
                          <FileItem client={client} data={file} />
                        )) :
                          (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "60vh",
                                gap: "8rem",
                              }}
                            >
                              <RecordNotFound />
                              <p className={classes.notFoundText}>
                                You don&apos;t have any files uploaded to this folder, click
                                on “upload documents” to add.
                              </p>
                            </div>
                          )
                        }
                      </div>
                    </Tabs.Panel>
                  </Tabs>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <Text my={5} ta={"center"}>
              All Folders
            </Text>
            <div
              className={classes.row}
              style={{
                justifyContent: folders?.length > 0 ? "start" : "center",
              }}
            >
              {getFolderLoading ? (
                <TableLoader />
              ) : folders?.length > 0 ? (
                folders?.map((folder) => (
                  <FolderItem
                    data={folder}
                    setSelectedFolder={setSelectedFolder}
                    setType={setType}
                    client={client}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    height: "60vh",
                    gap: "8rem",
                  }}
                >
                  <RecordNotFound />
                  <p className={classes.notFoundText}>
                    You don&apos;t have any folders, click on the folder icon
                    above to add.
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };
  const TableView = () => {
    return (
      <div>
        {type === "file" ? (
          <>
            <Text my={5} ta={"center"}>
              {selectedFolder ? selectedFolder?.name : "All Documents"}
            </Text>

            {getFileLoading ? (
              <TableLoader />
            ) : (

              <Tabs
                value={tabState}
                style={{ width: "100%" }}
                onTabChange={(e) => {
                  setTabState(e)
                }}
              >
                <Tabs.List style={{ gap: "39px" }}>
                  <Tabs.Tab value="active">Active</Tabs.Tab>
                  <Tabs.Tab value="expired">Expired</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="active">
                  <FileTable client={client} data={files?.activeFiles} />
                </Tabs.Panel>
                <Tabs.Panel value="expired" onChange={(e) => console.log('expired tab click', e)}>
                  <FileTable client={client} data={files?.expiredFiles} />
                </Tabs.Panel>
              </Tabs>

            )}
          </>
        ) : (
          <>
            <Text my={5} ta={"center"}>
              All Folders
            </Text>
            {getFolderLoading ? (
              <TableLoader />
            ) : (
              <FolderTable
                setType={setType}
                setSelectedFolder={setSelectedFolder}
                folders={folders}
                client={client}
              />
            )}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div
        className={classes.row}
        style={{
          justifyContent: "space-between",
          marginTop: 10,
          marginBottom: 10,
          flexWrap: "wrap",
        }}
      >
        <RowGridRadio onChange={(val) => setView(val)} />

        <div className={classes.row}>
          <UploadDocument client={client} selectedFolder={selectedFolder} />
          <Button
            // leftIcon={<IconFolderPlus size={20} />}
            color="yellow"
            onClick={() => {
              open();
            }}
            className={classes.iconButton}
            style={{ color: "black" }}
          >
            <IconFolderPlus size={20} />
          </Button>
          {/* <UnstyledButton
            color="yellow"
            onClick={() => {
              open();
            }}
            className={classes.iconButton}
          >
          </UnstyledButton> */}
        </div>
      </div>
      <SearchBar client={client} />
      {selectedFolder ? (
        <Button
          onClick={() => {
            setSelectedFolder(null);
            setType("folder");
          }}
          color="yellow"
          className={classes.iconButton}
        >
          <IconArrowLeft /> Back to Folders
        </Button>
      ) : (
        // <p style={{ display: "flex", alignItems: "center", color: "yellow" }}>
        //
        // </p>
        <FileFolderRadio onChange={(val) => setType(val)} />
      )}
      {view === "grid" ? <GridView /> : <TableView />}
      <CreateFolderModal
        opened={opened}
        client={client}
        onclose={() => {
          close();
        }}
        title={"Create New Folder"}
      />
    </>
  );
}

export default Document;
