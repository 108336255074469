import React from "react";
import { Col, Grid, Modal } from "@mantine/core";
import useStyles from "./style";
import { IconXboxX } from "@tabler/icons-react";
import insuranceImage from "../../images/insurance.png";
import careSupportImage from "../../images/contact.png";
import collapseImage from "../../images/collapse.png";
import phone from "../../images/phone.png";
import { FaEnvelope } from "react-icons/fa";
import { FaRegListAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Index = ({ opened, onClose, clientDetails }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  // console.log("Modal client details =>", clientDetails);
  return (
    <div>
      <Modal.Root
        className={classes.clientInsuranceModal}
        opened={opened}
        onClose={() => onClose()}
        size={650}
        shadow="md"
        overlayProps={{ blur: 3 }}
        radius={"lg"}
        zIndex={1000}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title className={classes.flexParent}>
              <div className={classes.headerFlex}>
                <div>
                  <img
                    className={classes.headerFlexImage}
                    src={careSupportImage}
                    alt="Care & support image"
                  />
                </div>
                <div>
                  <h3 className={classes.headerFlexTitle}>Care & Support</h3>
                </div>
              </div>
              <div className={classes.headerFlex}>
                <div>
                  <button
                    className={classes.headerFlexEndButton}
                    onClick={() => {
                      navigate("/home/patient", {
                        state: {
                          clientDetails: clientDetails,
                          formName: "careSupportInfo",
                          edit: true,
                          disable: true,
                        },
                      });
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div>
                  <button
                    className={classes.headerCollapseImageButton}
                    onClick={() => onClose()}
                  >
                    <img
                      className={classes.headerCollapseImage}
                      src={collapseImage}
                      alt="Collapse image"
                    />
                  </button>
                </div>
              </div>
            </Modal.Title>

            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body className={classes.body}>
            {clientDetails?.form2 ? (
              <div className={classes.gridParent}>
                <Grid>
                  {/* Guardian */}
                  {clientDetails?.form2?.emergencyContactName ||
                  clientDetails?.form2?.emergencyContactPhoneNumber ||
                  clientDetails?.form2?.emergencyContactEmail ? (
                    <Col span={12} className={classes.box}>
                      <p className={classes.title}>Guardian</p>
                      <div className={classes.dataFlex}>
                        <div className={classes.dataName}>
                          {clientDetails?.form2?.emergencyContactName}
                        </div>
                        <div className={classes.contact}>
                          {clientDetails?.form2?.emergencyContactPhoneNumber ? (
                            <div className={classes.phoneNo}>
                              <img
                                className={classes.phoneImg}
                                src={phone}
                                alt="phone"
                              />
                              <small className={classes.number}>
                                {
                                  clientDetails?.form2
                                    ?.emergencyContactPhoneNumber
                                }
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                          {clientDetails?.form2?.emergencyContactEmail ? (
                            <div className={classes.emailParent}>
                              <FaEnvelope className={classes.envelope} />
                              <small className={classes.number}>
                                {clientDetails?.form2?.emergencyContactEmail}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {/* Case Manager */}
                  {clientDetails?.form2?.caseManagerName ||
                  clientDetails?.form2?.caseManagerPhoneNumber ||
                  clientDetails?.form2?.caseManagerEmail ? (
                    <Col span={12} className={classes.box}>
                      <p className={classes.title}>Case Manager</p>
                      <div className={classes.dataFlex}>
                        <div className={classes.dataName}>
                          {clientDetails?.form2?.caseManagerName}
                        </div>
                        <div className={classes.contact}>
                          {clientDetails?.form2?.caseManagerPhoneNumber ? (
                            <div className={classes.phoneNo}>
                              <img
                                className={classes.phoneImg}
                                src={phone}
                                alt="phone"
                              />
                              <small className={classes.number}>
                                {clientDetails?.form2?.caseManagerPhoneNumber}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                          {clientDetails?.form2?.caseManagerEmail ? (
                            <div className={classes.emailParent}>
                              <FaEnvelope className={classes.envelope} />
                              <small className={classes.number}>
                                {clientDetails?.form2?.caseManagerEmail}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {/* Primary Care Physician */}
                  {clientDetails?.form2?.pcpName ||
                  clientDetails?.form2?.pcpPhoneNumber ||
                  clientDetails?.form2?.pcpEmail ? (
                    <Col span={12} className={classes.box}>
                      <p className={classes.title}>Primary Care Physician</p>
                      <div className={classes.dataFlex}>
                        <div className={classes.dataName}>
                          {clientDetails?.form2?.pcpName}
                        </div>
                        <div className={classes.contact}>
                          {clientDetails?.form2?.pcpPhoneNumber ? (
                            <div className={classes.phoneNo}>
                              <img
                                className={classes.phoneImg}
                                src={phone}
                                alt="phone"
                              />
                              <small className={classes.number}>
                                {clientDetails?.form2?.pcpPhoneNumber}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                          {clientDetails?.form2?.pcpEmail ? (
                            <div className={classes.emailParent}>
                              <FaEnvelope className={classes.envelope} />
                              <small className={classes.number}>
                                {clientDetails?.form2?.pcpEmail}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {/* Pharmacy */}

                  {clientDetails?.form2?.pharmacyName ||
                  clientDetails?.form2?.pharmacyPhoneNumber ||
                  clientDetails?.form2?.pharmacyEmail ? (
                    <Col span={12} className={classes.box}>
                      <p className={classes.title}>Pharmacy</p>
                      <div className={classes.dataFlex}>
                        <div className={classes.dataName}>
                          {clientDetails?.form2?.pharmacyName}
                        </div>
                        <div className={classes.contact}>
                          {clientDetails?.form2?.pharmacyPhoneNumber ? (
                            <div className={classes.phoneNo}>
                              <img
                                className={classes.phoneImg}
                                src={phone}
                                alt="phone"
                              />
                              <small className={classes.number}>
                                {clientDetails?.form2?.pharmacyPhoneNumber}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                          {clientDetails?.form2?.pharmacyEmail ? (
                            <div className={classes.emailParent}>
                              <FaEnvelope className={classes.envelope} />
                              <small className={classes.number}>
                                {clientDetails?.form2?.pharmacyEmail}
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {/* Additional Provider */}

                  {clientDetails?.form2?.additionalProvider
                    ? clientDetails?.form2?.additionalProvider?.map(
                        (data, index) => (
                          <Col key={index} span={12} className={classes.box}>
                            <p className={classes.title}>
                              {data?.providerType}
                            </p>
                            <div className={classes.dataFlex}>
                              <div className={classes.dataName}>
                                {data?.providerName}
                              </div>
                              <div className={classes.contact}>
                                {data?.providerPhone ? (
                                  <div className={classes.phoneNo}>
                                    <img
                                      className={classes.phoneImg}
                                      src={phone}
                                      alt="phone"
                                    />
                                    <small className={classes.number}>
                                      {data?.providerPhone}
                                    </small>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data?.providerEmail ? (
                                  <div className={classes.emailParent}>
                                    <FaEnvelope className={classes.envelope} />
                                    <small className={classes.number}>
                                      {data?.providerEmail}
                                    </small>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    : ""}
                </Grid>
              </div>
            ) : (
              <h2 className={classes.noDataFound}>
                No Data Found <FaRegListAlt className={classes.noDataIcon} />
              </h2>
            )}
          </Modal.Body>
        </Modal.Content>
        {/* <h1>Hello World</h1> */}
      </Modal.Root>
    </div>
  );
};

export default Index;
