import {
    ActionIcon,
    Button,
    Divider,
    Group,
    PasswordInput,
    Radio,
    Select,
    SimpleGrid,
    TextInput,
    Textarea
  } from "@mantine/core";
  import { DateInput } from "@mantine/dates";
  import { useForm } from "@mantine/form";
  import { useMediaQuery } from "@mantine/hooks";
  import { IconPlus, IconTrash } from "@tabler/icons-react";
  import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
  } from "react";
  import { states } from "../../utils";
  
  const Form1 = forwardRef(
    (
      {
        edit,
        functions,
        classes,
        view,
        options,
        setState,
        setActive,
        state,
        active
      },
      ref
    ) => {
      useEffect(() => {
        if (active === 0 && state?.form1) {
          setPatientWounds(state?.form1?.patientWounds === "yes");
          setIsKnownIllness(state?.form1?.isKnownIllness === "yes");
          form.setValues({
            ...state?.form1,
            dob: new Date(state?.form1.dob),
            admissionDate: state?.form1.admissionDate
              ? new Date(state?.form1.admissionDate)
              : "",
            socialSecurityNumber:
              view || edit
                ? state?.form1?.lastFourDigits
                : state?.form1.socialSecurityNumber
          });
  
          let knownAl =
            typeof state?.form1?.knownAllergies === "string"
              ? [""]
              : state?.form1?.knownAllergies || [""];
          let knownIll =
            typeof state?.form1?.knownIllness === "string"
              ? [{ illness: "", contagious: "no" }]
              : state?.form1?.knownIllness || [{ illness: "", contagious: "no" }];
          let diet =
            typeof state?.form1?.dietaries === "string"
              ? [""]
              : state?.form1?.dietaries || [""];
  
          setKnownAllergies(knownAl);
          setKnownIllness(knownIll);
          setDietaries(diet);
        }
      }, [active]);
  
      const genders = ["Male", "Female", "Other"];
      const form = useForm({
        initialValues: {
          name: "",
          gender: "",
          dob: new Date(),
          facility: null,
          previousAddress: "",
          socialSecurityNumber: "",
          lastFourDigits: "",
          dietaryRestrictions: "",
          contagious: "no",
          patientWounds: "no",
          isKnownIllness: "no",
          zipCode: "",
          state: "",
          admissionDate: new Date()
        },
        validate: {
          name: (value) => (value ? null : "Required"),
          dob: (value) => (value ? null : "Required"),
          previousAddress: (value) => (value ? null : "Required"),
          facility: (value) => (value ? null : "Required")
          //   knownAllergies: (value) => (value ? null : "Required")
          //   dietaryRestrictions: (value) => (value ? null : "Required")
        }
      });
  
      useImperativeHandle(ref, () => ({
        confirm: () => {
          // console.log("Called");
          let values = form.values;
          let valid = form?.validate();
          if (Object.keys(valid?.errors).length === 0) {
            let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
            let obj = {
              ...state,
              form1: {
                ...values,
                knownAllergies,
                dietaries,
                knownIllness,
                lastFourDigits: `${"*".repeat(
                  lengthToMask
                )}${values?.socialSecurityNumber?.slice(-4)}`
              }
            };
  
            setState(obj);
            setActive(1);
          }
        }
      }));
  
      const [knownAllergies, setKnownAllergies] = useState([""]);
      const [knownIllness, setKnownIllness] = useState([
        {
          illness: "",
          contagious: "no"
        }
      ]);
      const [dietaries, setDietaries] = useState([""]);
      const [isknownIllness, setIsKnownIllness] = useState(false);
      const [patientWounds, setPatientWounds] = useState(false);
      const isMobileScreen = useMediaQuery("(max-width: 600px)");
      const { close } = functions;
  
      const onSave = (values) => {
        let valid = form?.validate();
        if (Object.keys(valid?.errors).length === 0) {
          let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
          let obj = {
            ...state,
            form1: {
              ...values,
              knownAllergies,
              dietaries,
              knownIllness: values.isKnownIllness === "yes" ? knownIllness : [],
              lastFourDigits: `${"*".repeat(
                lengthToMask
              )}${values?.socialSecurityNumber?.slice(-4)}`
            }
          };
  
          setState(obj);
          setActive(1);
        }
      };
  
      const handleChangeKnownAllergies = (index, value) => {
        let temp = [...knownAllergies];
        temp[index] = value;
        setKnownAllergies(temp);
      };
  
      const addEmptyAllergy = () => {
        let temp = [...knownAllergies];
        temp.push("");
        setKnownAllergies(temp);
      };
      const removeAllergy = (index) => {
        if (knownAllergies?.length > 1) {
          let temp = knownAllergies?.filter((v, i) => i !== index);
          setKnownAllergies(temp);
        }
      };
  
      const handleChangeDietary = (index, value) => {
        let temp = [...dietaries];
        temp[index] = value;
        setDietaries(temp);
      };
  
      const addEmptyDietary = () => {
        let temp = [...dietaries];
        temp.push("");
        setDietaries(temp);
      };
      const removeDietary = (index) => {
        if (dietaries?.length > 1) {
          let temp = dietaries?.filter((v, i) => i !== index);
          setDietaries(temp);
        }
      };
  
      const handleChangeKnownIllness = (index, value, type) => {
        // let temp = [...knownIllness];
        // temp[index][type] = value;
        setKnownIllness((p) =>
          p?.map((v, i) => (index === i ? { ...v, [type]: value } : v))
        );
      };
  
      const addEmptyKnownIllness = () => {
        let temp = [...knownIllness];
        temp.push({
          illness: "",
          contagious: "no"
        });
        setKnownIllness(temp);
      };
      const removeKnownIllness = (index) => {
        if (knownIllness?.length > 1) {
          let temp = knownIllness?.filter((v, i) => i !== index);
          setKnownIllness(temp);
        }
      };
  
      return (
        <form onSubmit={form?.onSubmit((values) => onSave(values))}>
          <SimpleGrid
            cols={isMobileScreen ? 1 : 2}
            className={classes.mwd}
            sx={{
              marginTop: 50,
              paddingBottom: 30
            }}
          >
            <TextInput
              className={classes.myfont}
              label="Full Name *"
              size="md"
              icon={<img src="/assets/images/profile.png" alt="home" />}
              placeholder="Ex: Valerie Liberty"
              {...form.getInputProps("name")}
              disabled={view}
            />
            <DateInput
              maxDate={new Date()}
              className={classes.myfont}
              label="Date of birth *"
              valueFormat="MM/DD/YYYY"
              size="md"
              icon={<img src="/assets/images/home2.png" alt="home" />}
              placeholder="Ex: 04/02/1993"
              {...form.getInputProps("dob")}
              disabled={view}
            />
  
            <Select
              className={classes.myfont}
              label="Facility Name *"
              size="md"
              data={options}
              autoComplete="off"
              icon={<img src="/assets/images/building.png" alt="home" />}
              placeholder="Select Facility Name"
              {...form.getInputProps("facility")}
              searchable
              disabled={view}
            />
  
            <PasswordInput
              className={classes.myfont}
              label="Social Security Number"
              size="md"
              autoComplete="new-password"
              icon={<img src="/assets/images/lock.png" alt="address" />}
              placeholder="Ex: AAA-GG-SSSS"
              {...form.getInputProps("socialSecurityNumber")}
            />
            <TextInput
              className={classes.myfont}
              label="Previous Address"
              size="md"
              icon={<img src="/assets/images/location.png" alt="address" />}
              placeholder="Ex: A-34 Carry St."
              {...form.getInputProps("previousAddress")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="Zip Code"
              size="md"
              icon={<img src="/assets/images/location.png" alt="address" />}
              placeholder="Ex: A-34 Carry St."
              {...form.getInputProps("zipCode")}
              disabled={view}
            />
            <Select
              searchable
              label="State"
              data={states}
              size="md"
              placeholder="Choose One"
              {...form.getInputProps("state")}
              icon={<img src="/assets/images/map.png" alt="address" />}
              disabled={view}
            />
            <DateInput
              maxDate={new Date()}
              className={classes.myfont}
              label="Client Admission Date"
              valueFormat="MM/DD/YYYY"
              size="md"
              icon={<img src="/assets/images/home2.png" alt="home" />}
              placeholder="Ex: 04/02/1993"
              {...form.getInputProps("admissionDate")}
              disabled={view}
            />
            <Select
              searchable
              label="Gender"
              data={genders}
              size="md"
              placeholder="Choose Gender"
              {...form.getInputProps("gender")}
              disabled={view}
            />
          </SimpleGrid>
          <SimpleGrid cols={1}>
            <Divider variant="dashed" />
            {knownAllergies?.map((value, index) => (
              <Group align="center" grow>
                <Group>
                  <span style={{ marginTop: "20px" }}>{index + 1} .</span>
                  <TextInput
                    className={classes.myfont}
                    label="Known Allergies"
                    size="md"
                    icon={<img src="/assets/images/building.png" alt="address" />}
                    placeholder="Please enter known allergies"
                    value={value}
                    onChange={(e) =>
                      handleChangeKnownAllergies(index, e?.target?.value)
                    }
                    disabled={view}
                  />
                </Group>
                <Group>
                  <ActionIcon
                    color="red"
                    variant="light"
                    mt={23}
                    size={25}
                    opacity={0.9}
                    onClick={() => removeAllergy(index)}
                  >
                    <IconTrash size={"md"} />
                  </ActionIcon>
                  {index === knownAllergies?.length - 1 && (
                    <ActionIcon
                      color="yellow"
                      variant="light"
                      mt={23}
                      size={30}
                      onClick={addEmptyAllergy}
                    >
                      <IconPlus />
                    </ActionIcon>
                  )}
                </Group>
              </Group>
            ))}
  
            <Divider variant="dashed" />
  
            {dietaries?.map((value, index) => (
              <Group align="center" grow>
                <Group>
                  <span style={{ marginTop: "20px" }}>{index + 1} .</span>
                  <TextInput
                    className={classes.myfont}
                    label="Dietary Restrictions"
                    size="md"
                    icon={<img src="/assets/images/building.png" alt="address" />}
                    placeholder="Dietary Restrictions"
                    value={value}
                    onChange={(e) => handleChangeDietary(index, e?.target?.value)}
                    disabled={view}
                  />
                </Group>
                <Group>
                  <ActionIcon
                    color="red"
                    variant="light"
                    mt={23}
                    size={25}
                    opacity={0.9}
                    onClick={() => removeDietary(index)}
                  >
                    <IconTrash size={"md"} />
                  </ActionIcon>
                  {index === dietaries?.length - 1 && (
                    <ActionIcon
                      color="yellow"
                      variant="light"
                      mt={23}
                      size={30}
                      // ref={addFieldRef}
                      onClick={addEmptyDietary}
                    >
                      <IconPlus />
                    </ActionIcon>
                  )}
                </Group>
              </Group>
            ))}
            <Divider variant="dashed" />
  
            <Radio.Group
              name="isKnownIllness"
              label="Are there any known Illness?"
              {...form.getInputProps("isKnownIllness")}
              description="If 'Yes' Please tell Known Illness"
              onChange={(value) => {
                form?.setFieldValue("isKnownIllness", value);
                setIsKnownIllness(value === "yes");
              }}
            >
              <Group mt="xs">
                <Radio color="yellow" disabled={view} value="yes" label="Yes" />
                <Radio color="yellow" disabled={view} value="no" label="No" />
              </Group>
            </Radio.Group>
            {isknownIllness &&
              knownIllness?.map((value, index) => (
                // <Group grow>
                <Group align="center" grow>
                  <span
                    style={{
                      marginTop: "20px",
                      flexGrow: 0.5
                    }}
                  >
                    {index + 1} .
                  </span>
  
                  <TextInput
                    className={classes.myfont}
                    label="Known Illness"
                    size="md"
                    icon={<img src="/assets/images/building.png" alt="address" />}
                    placeholder="Known Illness"
                    value={value?.illness}
                    onChange={(e) =>
                      handleChangeKnownIllness(index, e?.target?.value, "illness")
                    }
                    disabled={view}
                  />
  
                  <Radio.Group
                    // name={`contagious${index}`}
                    label="Is it Contagious?"
                    value={value?.contagious}
                    onChange={(value) => {
                      // console.log("index,value", index, value);
                      handleChangeKnownIllness(index, value, "contagious");
                    }}
                  >
                    <Group mt="xs">
                      <Radio
                        color="yellow"
                        disabled={view}
                        value="yes"
                        label="Yes"
                      />
                      <Radio
                        color="yellow"
                        disabled={view}
                        value="no"
                        label="No"
                      />
                    </Group>
                  </Radio.Group>
                  <Group>
                    <ActionIcon
                      color="red"
                      variant="light"
                      // mt={23}
                      size={25}
                      opacity={0.9}
                      onClick={() => removeKnownIllness(index)}
                    >
                      <IconTrash size={"md"} />
                    </ActionIcon>
                    {index === knownIllness?.length - 1 && (
                      <ActionIcon
                        color="yellow"
                        variant="light"
                        // mt={23}
                        size={30}
                        onClick={addEmptyKnownIllness}
                      >
                        <IconPlus />
                      </ActionIcon>
                    )}
                  </Group>
                </Group>
              ))}
            {isknownIllness && <Divider variant="dashed" />}
  
            <Radio.Group
              name="patientWounds"
              {...form.getInputProps("patientWounds")}
              label="Does the Client have any wounds?"
              onChange={(value) => {
                form.setFieldValue("patientWounds", value);
                setPatientWounds(value === "yes");
              }}
            >
              <Group mt="xs">
                <Radio color="yellow" disabled={view} value="yes" label="Yes" />
                <Radio color="yellow" disabled={view} value="no" label="No" />
              </Group>
            </Radio.Group>
            {patientWounds && (
              <Textarea
                autosize={view}
                className={classes.textArea}
                label="Wounds Information"
                size="md"
                icon={
                  <img
                    src="/assets/images/linear-note.png"
                    alt="wounds"
                    className={classes.descriptionIcon}
                  />
                }
                placeholder="Provide information about the client's wounds..."
                {...form.getInputProps("woundsInformation")}
                disabled={view}
              />
            )}
          </SimpleGrid>
          {view ? (
            <div style={{ height: "30px" }} />
          ) : (
            <SimpleGrid
              cols={2}
              sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
            >
              <Button
                color="yellow"
                sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                color="yellow"
                sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                type="submit"
              >
                Next
              </Button>
            </SimpleGrid>
          )}
        </form>
      );
    }
  );
  
  Form1.displayName = "Form1";
  export default Form1;
  