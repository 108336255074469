import React from "react";
import { Box, Container, Grid, Tabs, Text } from "@mantine/core";
import {
  IconBell,
  IconSettings,
  IconSquareRoundedChevronLeft,
} from "@tabler/icons-react";
import useStyles from "./style";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaArrowLeftLong } from "react-icons/fa6";

const Topbar = (props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { notiCount } = useSelector((state) => state.notification);
  const {
    title,
    back,
    populateName,
    initialValues,
    backAllClients,
    populateFacility,
    onEditFacilityClick,
  } = props;
  return (
    <Container className={classes.wrapper} fluid p={0}>
      <Box style={{ width: "100%" }}>
        {backAllClients && (
          <Box className={classes.backAllClients} onClick={backAllClients}>
            <FaArrowLeftLong color="#FBAA0D" size={20} />
            <Text color="#5C5C5C">All Clients</Text>
          </Box>
        )}

        <Box className={classes.leftSide}>
          <Text className={classes.myfont} data-testid="dashboard-title">
            {back && (
              <IconSquareRoundedChevronLeft
                size={30}
                onClick={() => navigate(back)}
              />
            )}
            {title}
          </Text>
        </Box>
        <Grid >
          <Grid.Col span={2}>
            <Text fz={26} c="#979797">
              {populateName ? populateName : initialValues?.name}
            </Text>
          </Grid.Col>
          {(populateFacility || initialValues) && (
            <Grid.Col
              span={7}
              className={classes.facilityBox}
            >
              <Box className={classes.facilityContainer}>
                <Text fz={24} c="#979797">
                  Facility:
                </Text>
                {initialValues?.facilityId
                  ? initialValues?.facilityId?.map((facility, index) => (
                      <Text
                        fz={16}
                        c="#000"
                        key={index}
                        className={classes.facility}
                      >
                        {facility?.name || "No facility"}
                      </Text>
                    ))
                  : populateFacility?.map((facility, index) => (
                      <Text
                        fz={16}
                        c="#000"
                        key={index}
                        className={classes.facility}
                      >
                        {facility?.name || "No facility"}
                      </Text>
                    ))}
              </Box>
              <Text
                fz={16}
                c="#000"
                style={{ cursor: "pointer" }}
                onClick={onEditFacilityClick}
              >
                Edit
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </Box>
      <Box className={classes.rightIcons}>
        <NavLink to={"/home/settings"}>
          <span
            className={`${classes.float_right} ${classes.settingBGcolor}`}
            style={{
              background: window.location.pathname.includes("settings")
                ? "#000"
                : "rgba(35, 35, 35, 0.06)",
            }}
          >
            <IconSettings
              size={30}
              className={classes.settingIcon}
              stroke={1}
              style={{
                color: window.location.pathname.includes("settings")
                  ? "#fff"
                  : "#232323",
              }}
            />
          </span>
        </NavLink>
        <NavLink to={"/home/notifications"}>
          <span
            className={`${classes.float_right} ${classes.settingBGcolor}`}
            style={{
              position: "relative",
              background: window.location.pathname.includes("notifications")
                ? "#000"
                : "rgba(35, 35, 35, 0.06)",
            }}
          >
            <IconBell
              size={30}
              className={classes.settingIcon}
              stroke={1}
              style={{
                color: window.location.pathname.includes("notifications")
                  ? "#fff"
                  : "#232323",
              }}
            />
            {notiCount !== 0 && (
              <span className={classes.badge}>{notiCount}</span>
            )}
          </span>
        </NavLink>
      </Box>

      {/* <ActionIcon
          variant="light"
          color="dark"
          size={52}
          className={`${classes.float_right} ${classes.smallscreen} `}
          mx={5}
        >
          <IconSettings size={30} color="#232323" stroke={1} />
        </ActionIcon> */}
      {/* <span className={`${classes.float_right} ${classes.notificationBGcolor}`}>
        <IconBell size={30} className={classes.notificationIcon} stroke={1} />
      </span> */}
      {/* <ActionIcon
        variant="light"
        size={52}
        className={`${classes.float_right} ${classes.smallscreen}`}
        mx={5}
      >
        <IconBell size={30} color="#232323" stroke={1} />
      </ActionIcon> */}
      {/* <TextInput
        placeholder="Feature Coming Soon..."
        sx={{ fontFamily: "Inter", width: "30%", marginRight: ".5rem" }}
        icon={<IconSearch color="#232323" />}
        size="lg"
        radius={12}
        className={`${classes.float_right} ${classes.mobileScreen}`}
      /> */}
    </Container>
  );
};

export default Topbar;
