import {
    Button,
    Checkbox,
    Divider,
    Select,
    SimpleGrid,
    Text,
    TextInput
  } from "@mantine/core";
  import { DateInput } from "@mantine/dates";
  import { useForm } from "@mantine/form";
  import { useMediaQuery } from "@mantine/hooks";
  import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
  } from "react";
  import { PhoneInput } from "react-international-phone";
  
  const Form2 = forwardRef(
    ({ classes, view, state, active, setState, setActive }, ref) => {
      const isMobileScreen = useMediaQuery("(max-width: 600px)");
      const [show, setShow] = useState(false);
      const [showAdditional, setShowAdditional] = useState(false);
      const [showGuadrian, setShowGuardian] = useState(false);
  
      const initialProvider = {
        providerEmail: "",
        providerName: "",
        providerType: "",
        providerPhone: ""
      };
      const providerTypes = [
        "Allergist/Immunologist",
        "Anesthesiologist",
        "Cardiologist",
        "Dermatologist",
        "Emergency Medicine Physician",
        "Endocrinologist",
        "Gastroenterologist",
        "Geriatrician",
        "General Practitioner (GP)",
        "Hematologist",
        "Infectious Disease Specialist",
        "Nephrologist",
        "Neonatologist",
        "Neurologist",
        "Obstetrician/Gynecologist (OB/GYN)",
        "Oncologist",
        "Ophthalmologist",
        "Orthopedic Surgeon",
        "Otolaryngologist (ENT Specialist)",
        "Pathologist",
        "Pediatrician",
        "Pharmacist",
        "Physical Medicine and Rehabilitation Specialist",
        "Plastic Surgeon",
        "Podiatrist",
        "Psychiatrist",
        "Pulmonologist",
        "Radiologist",
        "Rheumatologist",
        "Sports Medicine Specialist",
        "Urologist"
      ];
  
      const [additionalProvider, setAdditionalProvider] = useState([
        { id: 1, ...initialProvider }
      ]);
      const topRef = useRef();
      const inputRef = useRef();
      const noGuardianRef = useRef();
      const noCaseRef = useRef();
      const noAdditionalRef = useRef();
      const form = useForm({
        initialValues: {
          emergencyContactName: "",
          emergencyContactPhoneNumber: "",
          emergencyContactEmail: "",
          noCaseManager: false,
          noAdditionalProvider: false,
          caseManagerName: "",
          caseManagerPhoneNumber: "",
          caseManagerEmail: "",
          pcpName: "",
          pcpPhoneNumber: "",
          pcpFaxNumber: "",
          pcpEmail: "",
          noGuardian: false,
          effectiveDate: new Date(),
          groupNumber: "",
          prescriptionGroup: "",
          subscriberNumber: "",
          subscriberName: "",
          insuranceCompanyName: ""
        },
        validate: {
          // emergencyContactName: (value) => (value ? null : "Required"),
          // emergencyContactPhoneNumber: (value) => (value ? null : "Required"),
          // emergencyContactEmail: (value) =>
          // /^\S+@\S+$/.test(value) ? null : "Invalid email",
          // caseManagerName: (value) => (!show && !value ? "Required" : null),
          // caseManagerPhoneNumber: (value) => (!show && !value ? "Required" : null),
          // caseManagerEmail: (value) => /^\S+@\S+$/.test(value) ? null : "Invalid email",
          // pcpPhoneNumber: (value) => (value ? null : "Required"),
          // pcpFaxNumber: (value) => (value ? null : "Required"),
          // pcpEmail: (value) => /^\S+@\S+$/.test(value) ? null : "Invalid email"
        }
      });
  
      useImperativeHandle(ref, () => ({
        confirm: () => {
          let values = form.values;
          if (
            !values?.caseManagerEmail &&
            values?.caseManagerPhoneNumber == "+1" &&
            !values?.caseManagerName
          ) {
            values.noCaseManager = true;
          } else {
            values.noCaseManager = false;
          }
          if (
            !values?.emergencyContactName &&
            !values?.emergencyContactEmail &&
            values?.emergencyContactPhoneNumber == "+1"
          ) {
            values.noGuardian = true;
          } else {
            values.noGuardian = false;
          }
  
          if (additionalProvider?.length === 0)
            values.noAdditionalProvider = true;
          let obj = {
            ...values,
            additionalProvider
          };
          setState((p) => ({ ...p, form2: obj }));
          setActive(2);
        }
      }));
      const onSave = (values) => {
        if (
          !values?.caseManagerEmail &&
          values?.caseManagerPhoneNumber == "+1" &&
          !values?.caseManagerName
        ) {
          values.noCaseManager = true;
        } else {
          values.noCaseManager = false;
        }
        if (
          !values?.emergencyContactName &&
          !values?.emergencyContactEmail &&
          values?.emergencyContactPhoneNumber == "+1"
        ) {
          values.noGuardian = true;
        } else {
          values.noGuardian = false;
        }
  
        if (additionalProvider?.length === 0) values.noAdditionalProvider = true;
        let obj = {
          ...values,
          additionalProvider
        };
        setState((p) => ({ ...p, form2: obj }));
        setActive(2);
      };
      const handleChangeProvider = (index, key, value) => {
        let temp = additionalProvider?.map((v, i) => {
          return i === index
            ? {
                ...v,
                [key]: value
              }
            : v;
        });
        setAdditionalProvider(temp);
      };
  
      const addProvider = () => {
        setAdditionalProvider([
          ...additionalProvider,
          { id: Date.now(), ...initialProvider }
        ]);
      };
      const removeProvider = (provId) => {
        setAdditionalProvider(additionalProvider.filter((p) => p.id !== provId));
      };
  
      useEffect(() => {
        if (active === 1) topRef?.current?.scrollIntoView(); // { behavior: "smooth" }
        if (active === 1 && state?.form2) {
          if (
            state?.form2?.additionalProvider &&
            Array.isArray(state?.form2?.additionalProvider)
          )
            setAdditionalProvider(state?.form2?.additionalProvider);
          else setAdditionalProvider([]);
          form.setValues({
            ...(state?.form2
              ? {
                  ...state?.form2,
                  effectiveDate: state?.form2?.effectiveDate
                    ? new Date(state?.form2?.effectiveDate)
                    : new Date()
                }
              : {})
          });
          setShow(state?.form2?.noCaseManager);
          setShowGuardian(state?.form2?.noGuardian);
          setShowAdditional(state?.form2?.noAdditionalProvider);
          if (state?.form2?.noCaseManager) noCaseRef.current.checked = true;
          if (state?.form2?.noAdditionalProvider)
            noAdditionalRef.current.checked = true;
          if (state?.form2?.noGuardian) noGuardianRef.current.checked = true;
        }
      }, [active]);
  
      return (
        <form onSubmit={form?.onSubmit((values) => onSave(values))} ref={topRef}>
          <Text variant="text" style={{ fontWeight: "600" }} ref={inputRef}>
            Guardian Info
          </Text>
          <Checkbox
            label="No Guardian"
            ref={noGuardianRef}
            {...form.getInputProps("noGuardian")}
            sx={{ marginTop: 20 }}
            onChange={(v) => setShowGuardian(v?.target?.checked)}
            disabled={view}
            color="yellow"
          />
          {!showGuadrian && (
            <SimpleGrid
              cols={isMobileScreen ? 1 : 2}
              className={classes.mwd}
              sx={{
                paddingBottom: 30
              }}
            >
              <TextInput
                className={classes.myfont}
                label="Guardian/Emergency Contact Name"
                size="sm"
                icon={<img src="/assets/images/profile.png" alt="contact" />}
                placeholder="Ex: John Doe"
                sx={{ marginTop: 20 }}
                {...form.getInputProps("emergencyContactName")}
                disabled={view}
              />
  
              <div className={classes.phoneInputBox}>
                <p className={classes.label}>
                  Guardian/Emergency contact Phone Number
                </p>
                <PhoneInput
                  defaultCountry="us"
                  {...form.getInputProps("emergencyContactPhoneNumber")}
                  className={classes.phoneInput}
                />
              </div>
  
              <TextInput
                className={classes.myfont}
                label="Guardian/Emergency Contact Email"
                size="sm"
                icon={<img src="/assets/images/sms.png" alt="email" />}
                placeholder="Enter email"
                {...form.getInputProps("emergencyContactEmail")}
                disabled={view}
              />
            </SimpleGrid>
          )}
  
          <Divider my="sm" variant="dashed" />
          <Text variant="text" style={{ fontWeight: "600" }}>
            Case Manager Info
          </Text>
          <Checkbox
            label="No Case Manager"
            {...form.getInputProps("noCaseManager")}
            sx={{ marginTop: 20 }}
            ref={noCaseRef}
            onChange={(v) => setShow(v?.target?.checked)}
            disabled={view}
            color="yellow"
          />
          <SimpleGrid
            cols={isMobileScreen ? 1 : 2}
            className={classes.mwd}
            sx={{
              paddingBottom: 30
            }}
          >
            {!show && (
              <>
                <TextInput
                  className={classes.myfont}
                  label="Case Manager Name"
                  size="sm"
                  icon={<img src="/assets/images/profile.png" alt="user" />}
                  placeholder="Enter name"
                  sx={{ marginTop: 20 }}
                  {...form.getInputProps("caseManagerName")}
                  disabled={view}
                />
  
                <div className={classes.phoneInputBox}>
                  <p className={classes.label}>Case Manager Phone Number</p>
                  <PhoneInput
                    required
                    defaultCountry="us"
                    {...form.getInputProps("caseManagerPhoneNumber")}
                    className={classes.phoneInput}
                  />
                </div>
  
                <TextInput
                  className={classes.myfont}
                  label="Case Manager Email"
                  size="sm"
                  icon={<img src="/assets/images/sms.png" alt="email" />}
                  placeholder="Enter email"
                  sx={{ marginTop: 20 }}
                  type="email"
                  {...form.getInputProps("caseManagerEmail")}
                  disabled={view}
                />
              </>
            )}
          </SimpleGrid>
          <Divider my="sm" variant="dashed" />
          <Text variant="text" style={{ fontWeight: "600" }}>
            Primary Care Physician
          </Text>
          <SimpleGrid cols={isMobileScreen ? 1 : 2}>
            <TextInput
              className={classes.myfont}
              label="PCP Name"
              size="sm"
              icon={<img src="/assets/images/profile.png" alt="profile" />}
              placeholder="Enter Name"
              sx={{ marginTop: 20 }}
              {...form.getInputProps("pcpName")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="PCP Email"
              size="sm"
              icon={<img src="/assets/images/sms.png" alt="email" />}
              placeholder="Enter email"
              type="email"
              sx={{ marginTop: 20 }}
              {...form.getInputProps("pcpEmail")}
              disabled={view}
            />
            <div className={classes.phoneInputBox}>
              <p className={classes.label}>PCP Phone Number</p>
              <PhoneInput
                required
                defaultCountry="us"
                {...form.getInputProps("pcpPhoneNumber")}
                className={classes.phoneInput}
              />
            </div>
  
            <div className={classes.phoneInputBox}>
              <p className={classes.label}>PCP Fax Number</p>
              <PhoneInput
                required
                defaultCountry="us"
                {...form.getInputProps("pcpFaxNumber")}
                className={classes.phoneInput}
              />
            </div>
          </SimpleGrid>
  
          <Divider my="sm" variant="dashed" size={"md"} />
          <Text variant="text" style={{ fontWeight: "600" }}>
            Additional Provider Info
          </Text>
          <Checkbox
            label="No Additional Provider"
            {...form.getInputProps("noAdditionalProvider")}
            sx={{ marginTop: 20 }}
            ref={noAdditionalRef}
            onChange={(v) => {
              setShowAdditional(v?.target?.checked);
              form.setFieldValue("noAdditionalProvider", v?.target?.checked);
            }}
            disabled={view}
            color="yellow"
          />
  
          {!showAdditional && (
            <div>
              {additionalProvider?.map((value, index) => (
                <div>
                  {/* <span style={{ marginTop: "30px" }}>{index + 1} .</span> */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {!view && (
                      <span
                        onClick={() => removeProvider(value.id)}
                        style={{
                          color: "#fab005",
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                      >
                        Delete
                      </span>
                    )}
                  </div>
  
                  <SimpleGrid
                    cols={isMobileScreen ? 1 : 2}
                    className={classes.mwd}
                    sx={{
                      paddingBottom: 30
                    }}
                  >
                    <TextInput
                      className={classes.myfont}
                      label="Provider Name"
                      size="md"
                      icon={
                        <img src="/assets/images/building.png" alt="address" />
                      }
                      placeholder="Enter Provider Name"
                      value={value?.providerName}
                      onChange={(e) =>
                        handleChangeProvider(
                          index,
                          "providerName",
                          e?.target?.value
                        )
                      }
                      disabled={view}
                    />
                    <TextInput
                      className={classes.myfont}
                      label="Provider Email"
                      size="md"
                      icon={<img src="/assets/images/sms.png" alt="address" />}
                      placeholder="Enter Provider Email"
                      value={value?.providerEmail}
                      onChange={(e) =>
                        handleChangeProvider(
                          index,
                          "providerEmail",
                          e?.target?.value
                        )
                      }
                      disabled={view}
                    />
                    <div>
                      <p className={classes.providerLabel}>Provider Contact</p>
                      <PhoneInput
                        required
                        defaultCountry="us"
                        onChange={(val) => {
                          handleChangeProvider(index, "providerPhone", val);
                        }}
                        value={value?.providerPhone}
                        className={classes.providerPhone}
                      />
                    </div>
  
                    <Select
                      className={classes.myfont}
                      label="Provider Type"
                      size="md"
                      data={providerTypes}
                      placeholder="Select Type"
                      value={value?.providerType}
                      onChange={(value) => {
                        handleChangeProvider(index, "providerType", value);
                      }}
                      disabled={view}
                    />
                  </SimpleGrid>
                  <Divider my="sm" variant="dashed" />
                </div>
              ))}
              {/* Button to Add Medication */}
              {!view && (
                <Button
                  size="sm"
                  color="yellow"
                  onClick={addProvider}
                  style={{ marginLeft: "auto", display: "block" }}
                >
                  Add Provider
                </Button>
              )}
            </div>
          )}
          <Divider my="sm" variant="dashed" size={"md"} />
  
          <Text variant="text" style={{ fontWeight: "600" }}>
            Insurance
          </Text>
          <SimpleGrid cols={isMobileScreen ? 1 : 2}>
            <TextInput
              className={classes.myfont}
              label="Insurance Company Name"
              size="sm"
              icon={<img src="/assets/images/sms.png" alt="company" />}
              placeholder="Enter company name"
              sx={{ marginTop: 20 }}
              {...form.getInputProps("insuranceCompanyName")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="Subscriber name"
              size="sm"
              icon={<img src="/assets/images/sms.png" alt="company" />}
              placeholder="Enter subscriber name"
              sx={{ marginTop: 20 }}
              {...form.getInputProps("subscriberName")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="Subscriber Number"
              size="sm"
              min={0}
              icon={<img src="/assets/images/sms.png" alt="company" />}
              placeholder="Enter subscriber number"
              {...form.getInputProps("subscriberNumber")}
              disabled={view}
            />
            <DateInput
              className={classes.myfont}
              label="Effective Date"
              placeholder="Effective date"
              clearable
              size="sm"
              icon={<img src="/assets/images/sms.png" alt="company" />}
              {...form.getInputProps("effectiveDate")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="Group Number"
              size="sm"
              min={0}
              icon={<img src="/assets/images/sms.png" alt="company" />}
              placeholder="Enter group number"
              {...form.getInputProps("groupNumber")}
              disabled={view}
            />
            <TextInput
              className={classes.myfont}
              label="Prescription Group"
              size="sm"
              icon={<img src="/assets/images/sms.png" alt="company" />}
              placeholder="Enter prescription group number"
              min={0}
              {...form.getInputProps("prescriptionGroup")}
              disabled={view}
            />
          </SimpleGrid>
  
          {view ? (
            <div style={{ height: "30px" }} />
          ) : (
            <SimpleGrid
              cols={isMobileScreen ? 1 : 2}
              sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
            >
              <Button
                color="yellow"
                sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
                onClick={() => setActive(0)}
              >
                Back
              </Button>
              <Button
                color="yellow"
                sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                type="submit"
              >
                Next
              </Button>
            </SimpleGrid>
          )}
        </form>
      );
    }
  );
  
  Form2.displayName = "Form2";
  
  export default Form2;
  