import React from "react";
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  hideClientBox: {
    width: "100%",
    paddingBlock: "20px",
    "& img": {
      height: "25px",
      cursor: "pointer",
    },
  },
  showClient: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& p": {
      margin: 0,
      fontSize: "20px",
      fontWeight: "bold",
      marginLeft: "80px",
    },
    "& img": {
      marginLeft: "40px",
    },
  },
  hideClient: {},
}));

const HideClient = ({ show, setShow, isHeading }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.hideClientBox}>
      {show ? (
        <div className={classes.showClient}>
          <p>Clients</p>
          <img
            onClick={() => setShow(false)}
            src={"/assets/images/arrow-left.png"}
          />
          {/* {isHeading && (
            <div style={{ flex: 1 }}>
              <h2 style={{ margin: 0, textAlign: "center" }}>Mar Log</h2>
            </div>
          )} */}
        </div>
      ) : (
        <div className={classes.hideClient}>
          <img
            onClick={() => setShow(true)}
            src={"/assets/images/arrow-right.png"}
          />
          {/* {isHeading && (
            <div style={{ flex: 1 }}>
              <h2 style={{ margin: 0, textAlign: "center" }}>Mar Log</h2>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default HideClient;
