import { Button, Text, Box, Badge } from "@mantine/core";
import React from "react";
import useStyles from "./style";

const HeroSection = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.heroSection}>
      <Badge className={classes.badge}>Why Ogiso?</Badge>
      <Text className={classes.primaryHeading} ta={"center"}>
        We are simple, fast and free.
      </Text>
      <Text className={classes.text}>
        At Ogiso, we set out to build the best solutions for managing your HCBS
        (Home and Community based Service) facility. Our platform is
        specifically designed to streamline the creation and management of IDD
        service plans, medication administration, task management and much more.
      </Text>
      <img
        src={"/assets/images/dashboardShadow.png"}
        alt="heroSectionImg"
        className={classes.img}
      />
    </Box>
  );
};

export default HeroSection;
