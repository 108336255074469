import React from "react";
import {
  Group,
  Modal,
  Radio,
  Select,
  SimpleGrid,
  Text,
  createStyles
} from "@mantine/core";

import { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { addPlan, editPlan } from "../../services/supportplan";
import Form4 from "./Form4";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: "100%"
  },
  mwd: {
    width: "100%"
  },
  myfont: {
    fontFamily: "Inter",
    overflow: "visible"
  },
  phoneInputBox: {},
  label: {
    display: "inline-block",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
    marginBottom: "4px",
    marginTop: "22px"
  },
  phoneInput: {
    "& button": {
      height: "2.3rem",
      borderColor: "#ced4da"
    },
    "& .react-international-phone-input": {
      height: "2.3rem !important",
      borderColor: "#ced4da",
      width: "100%"
    }
  },
  mwd: {
    width: "100%",
    "& .mantine-Modal-header": {
      zIndex: 100
    }
  },
  textArea: {
    fontFamily: "Inter",
    "& .mantine-Textarea-input": {
      height: "200px"
    }
  }
}));
const SupportModal = ({
  opened,
  close,
  title,
  view,
  setView,
  client,
  activeTab,
  patient,
  carePlanType,
  facility,
  ...data
}) => {
  const { allPatients } = useSelector((state) => state?.patient);
  const { allFacilities } = useSelector((state) => state?.facility);

  const { addPlanLoading, allPlans } = useSelector(
    (state) => state?.supportPlan
  );
  let previouslyExists = allPlans?.map((v) => v?.client?._id);

  const isMobile = useMediaQuery("(max-width: 600px)");
  const { classes } = useStyles();

  const edit = data?.data ? true : false;
  const [state, setState] = useState(data?.data ? data?.data : {});
  const patientOptions =
    !edit && !view
      ? allPatients
          ?.filter((v) => !previouslyExists?.includes(v?.id))
          ?.map((v) => ({ label: v?.name, value: v?.id }))
      : allPatients?.map((v) => ({ label: v?.name, value: v?.id }));

  const form = useForm({
    initialValues: {
      client: client || "",
      //client: "My Client",
      facility: "",
      skillBuilding: "no",
      isActive: "yes"
    },
    validate: {
      client: (v) => (v ? null : "Client is required"),
      facility: (v) => (v ? null : "Facility is required")
    }
  });

  useEffect(() => {
    if (opened && !edit) {
      if (localStorage.getItem("performActionFlag") != "true") {
        form.setFieldValue("client", patient);
        form.setFieldValue("facility", facility);
        localStorage.setItem("performActionFlag", "true");
      }

      form.setFieldValue("client", client);

      let patient2 = allPatients?.find((V) => V?.id === client);
      form.setFieldValue("facility", patient2?.facilityId?.id);
    }
    if (!opened) {
      form.setValues({
        client: "",
        facility: "",
        isActive: "yes",
        skillBuilding: "no"
      });
    }

    if (edit && opened) {
      form.setFieldValue("client", data?.data?.client?._id);
      let patient = allPatients?.find((V) => V?.id === data?.data?.client?._id);
      form.setFieldValue("facility", patient?.facilityId?.id);
      setState({
        outcomes: data?.data?.outcomes
      });
    }
  }, [opened, data?.data]);

  const onSave = async (form4) => {
    if (edit) {
      let obj = {
        id: data?.data?._id,
        client: form.values.client,
        isActive: form.values.isActive,
        facility: form.values?.facility,
        outcomes: form4
      };

      editPlan(obj);
      close();
    } else {
      form.validate();
      if (form.isValid()) {
        let obj = {
          planType: carePlanType,
          client: form.values?.client,
          isActive: form.values.isActive,
          facility: form.values?.facility,
          outcomes: form4?.map((v, i) => ({ ...v, outcomeNumber: i + 1 }))
        };


        addPlan(obj);

        close();
      }
    }
  };
  const onClientChange = (value) => {
    form.setFieldValue("client", value);
    let patient = allPatients?.find((V) => V?.id === value);
    form.setFieldValue("facility", patient?.facilityId?.id);
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={810}
      onClose={() => {
        close();
        view && setView?.(false);
      }}
      shadow="md"
      radius={15}
      overlayProps={{ blur: 3 }}
      zIndex={1000}
      lockScroll={true}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {view ? "View Plan" : title ? title : "Edit Plan"}
          </Text>
        </Group>
      }
      centered
    >
      <form>
        <SimpleGrid cols={isMobile ? 1 : 3}>
          <Select
            id="client-name"
            size="md"
            label={"Client Name"}
            data={patientOptions}
            icon={<img src="/assets/images/home2.png" alt="home" />}
            placeholder={`Select Client Name`}
            searchable
            disabled={client ? true : view}
            {...form.getInputProps("client")}
            onChange={onClientChange}
            style={{ margin: "20px 0px" }}
            className={classes.myfont}
          />
          <Select
            size="md"
            label={"Facility Name"}
            data={allFacilities?.facilities?.map((v) => ({
              label: v?.name,
              value: v?.id
            }))}
            icon={<img src="/assets/images/home2.png" alt="home" />}
            placeholder={`Select Facility`}
            searchable
            disabled
            {...form.getInputProps("facility")}
            style={{ margin: "20px 0px" }}
            className={classes.myfont}
          />
          <Radio.Group
            style={{ margin: "20px 0px" }}
            label="Plan is active"
            size="md"
            {...form.getInputProps("isActive")}
          >
            <Group>
              <Radio
                color={"yellow"}
                label="Yes"
                value={"yes"}
                disabled={view}
              />
              <Radio color={"yellow"} label="No" value={"no"} disabled={view} />
            </Group>
          </Radio.Group>
        </SimpleGrid>
      </form>
      <Form4
        view={view}
        close={close}
        state={state}
        edit={edit}
        classes={classes}
        setState={setState}
        onSubmit={onSave}
        addPlanLoading={addPlanLoading}
      />
    </Modal>
  );
};

export default SupportModal;
