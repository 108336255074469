import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addPlanLoading: false,
  updatePlanLoading: false,
  plans: [],
  allPlans: [],
  count: 0,
};

export const patientState = createSlice({
  name: "supportplan",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload,
      };
    },
    setAddPlanLoading(state, action) {
      return {
        ...state,
        addPlanLoading: action?.payload,
      };
    },
    setUpdatePlanLoading(state, action) {
      return {
        ...state,
        updatePlanLoading: action?.payload,
      };
    },
    setPlans(state, action) {
      return {
        ...state,
        loading: false,
        plans: action?.payload?.plans,
        count: action?.payload?.count,
      };
    },
    setAllPlans(state, action) {
      return {
        ...state,
        loading: false,
        allPlans: action?.payload.plans,
        count: action?.payload.count,
      };
    },

    // Reset State
    reset() {
      return initialState;
    },
  },
});

export const { setLoading, setAddPlanLoading, setPlans, setAllPlans, setUpdatePlanLoading } =
  patientState.actions;
export default patientState.reducer;
