import mixpanel from "mixpanel-browser";

export function userInfoAnalytics(profile) {
  mixpanel.identify(profile?.user?.user?.id);
  mixpanel.people.set({
    $name: profile?.user?.user?.name,
    $email: profile?.user?.user?.email,
    $avatar: profile?.user?.user?.photo,
  });
  return true;
}

export function pageInfoAnalytics(pageName, reference) {
  mixpanel.track(pageName, { 
    pageName: reference
  })
  return true;
}

export function TrackPageView(pageName){
  mixpanel.track_pageview({ 
    "page": pageName,
    "Page Name": pageName
  })
}

export function TrackPageExit(pageName) {
  window.addEventListener('beforeunload', function () {
    mixpanel.track(pageName + " Page Exit", {
      page: pageName,
      "Page Name": pageName,
    });
  });
  return true;
}