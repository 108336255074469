import { https } from "../http";
import store from "../store";
import {
  setAddNoteLoading,
  setAllNotesTypes,
  setFieldTypes,
  setLoading,
  setLoadingNote_Types,
  setNoteTypes,
  setNotes,
  setNotesAgainstType,
} from "../store/reducers/note";
import { showError } from "../utils/index";
import swal from "sweetalert";

// GET
const getFieldTypes = async (showLoading = true) => {
  try {
    let response = await https().get("/field-type");

    if (response?.status === 200) {
      store.dispatch(setFieldTypes(response?.data));
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const getNoteTypes = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/ntype/${page}`);

    if (response?.status === 200) {
      store.dispatch(setLoading(false));
      store.dispatch(setNoteTypes(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getAllNoteTypes = async (showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/ntype/all`);

    if (response?.status === 200) {
      store.dispatch(setLoading(false));
      store.dispatch(setAllNotesTypes(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getNotes = async (page, userId, showLoading = true, dateRange) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    ///mar/get?client=${userId}&page=${page}
    let queryStr = `?page=${page}&client=${userId ? userId : undefined}`;
    if (dateRange) {
      queryStr = queryStr + `&dateRange=${dateRange}`;
    }
    let response = await https().get(`/note${queryStr}`);
    if (response?.status === 200) {
      store.dispatch(setNotes(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getNotesByReport = async (id) => {
  try {
    store.dispatch(setLoadingNote_Types(true));
    let response = await https().post(`/note/getNotesByReport`, {
      reportId: id,
    });

    if (response?.status === 200) {
      store.dispatch(setNotesAgainstType(response?.data));
      store.dispatch(setLoadingNote_Types(false));
    }
  } catch (error) {
    store.dispatch(setLoadingNote_Types(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// POST

const createNoteType = async (data) => {
  try {
    store.dispatch(setAddNoteLoading(true));
    let response = await https().post("/ntype", data);

    if (response?.status === 200) {
      getNoteTypes();
    }
  } catch (error) {
    store.dispatch(setAddNoteLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const createNote = async (data, callback) => {
  try {
    store.dispatch(setAddNoteLoading(true));
    let response = await https().post("/note", data);

    if (response?.status === 200) {
      data?.userId ? getNotes(1, data?.userId, false) : getNotes(false);
      await swal({
        title: `Note Added`,
        text: "Note has been added successfully",
        icon: "success",
        dangerMode: false,
        buttons: ["Cancel", "OK"],
      });
      callback?.();
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("This name is already taken");
    else showError(_error, error?.response?.status);
    store.dispatch(setAddNoteLoading(false));
    // showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

// PUT
const editNoteType = async (data, callback) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().put("/ntype", data);

    if (response?.status === 200) {
      getNoteTypes(1);

      callback?.();
    }
  } catch (error) {
    let _error = error?.response?.data?.message;

    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("Note Name already exists");
    else showError(_error, error?.response?.status);
    store.dispatch(setLoading(false));
    return false;
    // showError(error?.response?.data?.message, error?.response?.status)
  }
};

const editNote = async (data, showLoading = true, callback = null) => {
  try {
    store.dispatch(setAddNoteLoading(true));
    let response = await https().put(`/note`, data);

    if (response?.status === 200) {
      data?.userId ? getNotes(1, data?.userId, false) : getNotes(false);
      // getNotes();
      await swal({
        title: `Note Edited`,
        text: "Note has been edited successfully",
        icon: "success",
        dangerMode: false,
        buttons: ["Cancel", "OK"],
      });
      callback?.();
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("This name is already taken");
    else showError(_error, error?.response?.status);
    store.dispatch(setAddNoteLoading(false));
    // showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

// DELETE
const remoteNoteType = async (id, callback) => {
  try {
    store.dispatch(setAddNoteLoading(true));
    let response = await https().delete(`/ntype/?id=${id}`);

    if (response?.status === 200) {
      getNoteTypes(false);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setAddNoteLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const removeNote = async (data, userId, callback) => {
  console.log("🚀 ~ removeNote ~ data:", data, userId);
  try {
    store.dispatch(setAddNoteLoading(true));

    let response = await https().delete(`/note/${data?.id}`);

    if (response?.status === 200) {
      userId ? getNotes(1, userId, false) : getNotes(false);
      // getNotes();
      callback?.();
    }
  } catch (error) {
    store.dispatch(setAddNoteLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export {
  createNote,
  createNoteType,
  editNote,
  editNoteType,
  getAllNoteTypes,
  getFieldTypes,
  getNoteTypes,
  getNotes,
  getNotesByReport,
  remoteNoteType,
  removeNote,
};
