import { https } from "../http";
import store from "../store";
import {
  setFacilities,
  setFacilityTypes,
  setLoading,
  setLoadingAddFacility,
  setAllFacilities,
  setAllFacilityTypes
} from "../store/reducers/facility";
import { setCookie, showError, showInfo } from "../utils/index";

// GET
const getAllFacilities = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/facility/${page}`);

    if (response?.status === 200) {
      store.dispatch(setFacilities(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

const getAllFacilitiesModal = async ( showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/facility/all`);

    if (response?.status === 200) {
      store.dispatch(setAllFacilities(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

const getFacilityTypes = async (page) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().get(`/ftype/${page}`);

    if (response?.status === 200) {
      store.dispatch(setFacilityTypes(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

const getAllFacilityTypes = async (page) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().get(`/ftype/all`);

    if (response?.status === 200) {
      store.dispatch(setAllFacilityTypes(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

// POST
const addFacilityType = async (data, callback) => {
  try {
    store.dispatch(setLoadingAddFacility(true));
    let response = await https().post("/ftype", data);

    if (response?.status === 200) {
      getFacilityTypes(1);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddFacility(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};
const addFacility = async (data) => {
  try {
    store.dispatch(setLoadingAddFacility(true));
    let response = await https().post("/facility", data);

    if (response?.status === 200) {
      getAllFacilities();
    }
  } catch (error) {
    let string = error?.response?.data?.message;
    if (string?.includes("duplicate") && string.includes("name"))
      showError("Facility with this name already exists");
    else showError(error?.response?.data?.message, error?.response?.status)
    store.dispatch(setLoadingAddFacility(false));
  }
};

// PUT
const editFacilityType = async (data, callback) => {
  try {
    store.dispatch(setLoadingAddFacility(true));
    let response = await https().put("/ftype", data);

    if (response?.status === 200) {
      getFacilityTypes();
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddFacility(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

const editFacility = async (data, showLoading = true) => {
  try {
    store.dispatch(setLoadingAddFacility(true));
    let response = await https().put(`/facility/${data?.id}`, data);

    if (response?.status === 200) {
      getAllFacilities(showLoading);
    }
  } catch (error) {
    store.dispatch(setLoadingAddFacility(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

// DELETE
const removeFacilityType = async (id, callback) => {
  try {
    store.dispatch(setLoadingAddFacility(true));
    let response = await https().delete(`/ftype/${id}`);

    if (response?.status === 200) {
      getFacilityTypes();
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddFacility(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

const removeFacility = async (data, callback) => {
  try {
    store.dispatch(setLoadingAddFacility(true));

    let response = await https().delete(`/facility/${data?.id}`);

    if (response?.status === 200) {
      getAllFacilities();
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddFacility(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

export {
  getAllFacilities,
  getFacilityTypes,
  addFacilityType,
  addFacility,
  removeFacility,
  removeFacilityType,
  editFacilityType,
  editFacility,
  getAllFacilitiesModal,
  getAllFacilityTypes
};
