import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  medicationModal: {
    width: 1450,
    overflowY: "auto",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#979797",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "118px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "40px",
    },
  },

  mainContentWarpper: {
    width: "100%",
    height: "70vh",
    maxHeight: "900px",
    overflowY: "auto",
  },
  mainContent: {
    width: "100%",
    // height: "100%",
    // padding: "3rem 4rem 5rem 8rem",
    padding: "3rem 5rem 5rem 5rem",
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center',
  },

  details: {
    width: "100%",
  },

  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    marginBottom: "30px",
    "& p": {
      margin: "0",
      fontSize: "32px",
    },
  },
  headLeft: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100px",
    height: "100px",
    marginRight: "50px",
    // background: "#fdf5e3",
    "& h3": {
      margin: "0",
      fontSize: "36px",
    },
    "& p": {
      margin: "0",
      textAlign: "center",
      fontSize: "16px !important",
      color: "#5C5C5C",
      fontWeight: 400,
    },
  },

  detailTitle: {
    width: "100%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "36px",
      color: "#5C5C5C",
      fontWeight: 700,
    },
  },

  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: "#74D588", // rgba(128, 0, 128, 0.50)
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "118px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "30px",
    },
  },

  span1: {
    fontWeight: "600",
    fontSize: "36px",
    margin: "0",
    color: "#979797",
  },
  span2: {
    fontWeight: 400,
    fontSize: "32px",
    color: "#979797",
    margin: "0",
  },
  span3: {
    color: "#979797",
    margin: "0",
    fontWeight: 500,
    fontSize: "36px",
  },

  orderInfo: {
    width: "100%",
    "& p": {
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "25px",
      // marginBlock: "50px",
    },
  },
  orderInfoWarning: {
    width: "100%",
    "& p": {
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "23px",
      color : "red",
      fontStyle: "normal",
      fontWeight: 700,
      // textDecoration : "underline",
      lineHeight: "normal",
      marginBlock: "30px",
    },
  },

  saveButton: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "& button": {
      borderRadius: "5px",
      background: "rgba(255, 186, 69, 0.85)",
      width: "145px",
      height: "60px",
      color: "#000",

      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",

      "&:hover": {
        background: "rgba(255, 186, 69, 0.85)",
      },
    },
  },

  personInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "column",
    width: "25%",
    "& img": {
      width: "170px",
      height: "160px",
      objectFit: "cover",
      borderRadius: "10px",
    },
    "& h3": {
      margin: "0",
      marginTop: "10px",
      textAlign: "center",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "25px",
    },
    "& p": {
      margin: "0",
      marginTop: "5px",
      textAlign: "center",
      color: "#979797",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "25px",
    },
  },

  uploadAndDateBox: {
    width: "100%",
    display: "flex",
    gap: "80px",
    alignItems: "center",
    justifyContent: "center",
  },

  dropzone: {
    "& .mantine-Dropzone-root": {
      background: "#F2F2F2",
      border: "none",
    },
    "& .mantine-Group-root": {
      gap: "0px",
      // height: "60px",
      minHeight: "initial !important",
    },
    width: "350px",
    height: "90px",
  },
  column: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    paddingLeft: "20px",
  },
  dragImage: {
    alignSelf: "center",
    width: "55px",
    height: "55px",
  },
  uploadText: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "25px",
    "& span": {
      color: "#FFBA45",
    },
  },

  datePicker: {
    width: "250px",
    "& .mantine-DatePickerInput-input": {
      border: "none",
      borderRadius: "0px !important",
      borderBottom: "2px solid #A8A8A8 !important",
    },
  },

  fileNameBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    "& p": {
      fontSize: "14px",
      color: "#5C5C5C",
      fontWeight: 600,
    },
  },
}));

export default useStyles;
