import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    marginBottom: "50px",
    flexDirection: "column",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  mt_30: {
    margin: "30px 0",
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "& p": {
      fontSize: "22px",
      margin: "0",
    },
  },
  active: {
    fontWeight: 500,
    borderBottom: "3px solid #fab005",
  },
  clientButtonBar:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "1rem"
  },
  rightBtns:{
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  detailBar: {
    display: "flex",
    marginTop: "10px",
    flexDirection: "column",
  },
  tabContainer: {
    display: "flex",
    minWidth: "260px",
    height: "40px",
  },

  userDetail: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .detail_box": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& .image_box": {
        width: "80px",
        height: "80px",
        border: "1px solid rgb(230,230,230)",
        borderRadius: "10px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& h3": {
        margin: "0",
        fontSize: "30px",
        textAlign: "center",
      },
      "& p": {
        margin: "0",
        color: "#979797",
        textAlign: "center",
      },
    },
  },

  monthlyBtn: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px 0 0 50px",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      width: "24px",
      marginRight: "5px",
    },
  },
  weeklyBtn: {
    display: "flex",
    alignItems: "center",
    // borderRadius: "50px 0 0 50px",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      width: "24px",
      marginRight: "5px",
    },
  },
  todayBtn: {
    display: "flex",
    alignItems: "center",
    borderRadius: "0 50px 50px 0",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      // width: "40px",
      // marginRight: "20px",
      width: "24px",
      marginRight: "5px",
    },
  },
  users: {
    padding: "20px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  user: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    "& img": {
      width: "100%",
    },
  },
  userBox: {
    width: "82.69px",
    height: "82.69px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F2F2F2",
    borderRadius: "100%",
  },
  list: {
    border: "1px solid rgba(90,90,90,0.1)",
    maxHeight: "600px",
    overflowY: "auto",
  },
  item: {
    padding: "20px 30px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // width:"248px",
    // "&:hover": {
    //   backgroundColor: "#fdf8ec",
    // },
    "& p": {
      margin: "0",
    },
  },
  itemBg: {
    backgroundColor: "#F2F2F2",
  },
  itemActive: {
    backgroundColor: "#fdf8ec",
    borderRight: "5px solid #FFBA45",
    "& p": {
      fontWeight: "bold",
    },
  },

  scheduleDetail: {
    width: "100%",
    display: "flex",
    gap: "20px",
  },
  scheduleDetailLeft: {
    width: "250px",
  },
  scheduleDetailRight: {
    width: "calc(100% - 270px)",
  },
  scheduleDetailRight100: {
    width: "calc(100% - 70px)",
  },

  wrapper: {
    width: "100%",
    overflowX: "auto",
  },
  mainContainer: {
    background: "#fdf8ec",
    // border: "1px solid rgba(90,90,90,0.1)",
    // width: "2000px",
    // width: "100%",
    "& ._monthlyDateCarousel": {
      marginRight: "25px",
    },
    "& ._monthlySlider": {
      paddingInline: "30px",
    },
    "& ._weeklySlider": {
      paddingLeft: "32px",
    },
  },
  date: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      marginTop: "10px",
      marginBottom: "20px",
      fontSize: "30px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  dateSlider: {
    marginLeft: "50px",
  },
  dateSlider2: {
    marginLeft: "25px",
  },
  slider: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0",
    // padding: "0 32px",
  },
  scheduleBox: {
    // marginTop: "30px",
    width: "100%",
    minHeight: "200px",
    paddingInline: "30px",
    display: "flex",
    flexDirection: "row",
    "& > div:nth-of-type(2)": {
      borderLeft: "1px solid rgba(90,90,90,0.1)",
    },
  },
  scheduleBoxForMonth: {
    marginTop: "30px",
    width: "100%",
    minHeight: "200px",
    paddingInline: "50px",
    // paddingInlineStart : "50px",
    // paddingInlineEnd: "30px",

    display: "flex",
    flexDirection: "row",
    "& > div": {
      borderRight: "1px solid rgba(90,90,90,0.1)",
      borderTop: "1px solid rgba(90,90,90,0.1)",
    },
    "& > div:nth-of-type(1)": {
      borderLeft: "1px solid rgba(90,90,90,0.1)",
    },
  },
  column1: {
    width: "45px",
    backgroundColor: "#FFBA45",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    "& p": {
      fontSize: "24px",
      fontWeight: 700,
      transform: "rotate(-90deg)",
    },
  },
  column1Empty: {
    width: "45px",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  column: {
    width: "calc((100% - 55px) / 7)",
    backgroundColor: "#fff",
    borderTop: "1px solid rgba(90,90,90,0.1)",
    borderBottom: "1px solid rgba(90,90,90,0.1)",
    borderRight: "1px solid rgba(90,90,90,0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "30px 10px",
  },
  columnMonth: {
    width: "calc(100% / 7)",
    backgroundColor: "#fff",
    // borderTop: "1px solid rgba(90,90,90,0.1)",
    // borderBottom: "1px solid rgba(90,90,90,0.1)",
    // borderRight: "1px solid rgba(90,90,90,0.1)",
    display: "flex",
    flexDirection: "column",
  },
  administerPrnBtnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "10px",
  },
  prnButton: {
    backgroundColor: "#FFBA45",
    color: "#000",
    borderRadiuis: "15px",
    height: "50px",
    "&:hover": {
      backgroundColor: "#FFBA45",
    },
    "& .imgDiv": {
      borderLeft: "1px solid black",
      marginLeft: "5px",
      paddingLeft: "5px",
    },
  },
  activeWeekToday: {
    background: "#fae7be !important",
  },
  carouselButtonGroup: {
    position: "absolute",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& img": {
      cursor: "pointer",
    },
  },

  mainContainerDaily: {
    background: "#fdf8ec",
    border: "1px solid rgba(90,90,90,0.1)",
  },

  sliderDaily: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0px 32px 0px 37px",
    // padding: "0 20px 0px 20px",
  },
  divider: {
    width: "1px",
    height: "50px",
    backgroundColor: "#D9D9D9",
    marginInline: "2px",
  },
  loadingBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "100px 0 0 0",
  },
  cuationsBox: {
    display: "flex",
    justifyContent: "center",
    padding: 15,
  },
}));

export default useStyles;
