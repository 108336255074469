import React, { useState } from "react";
import { ActionIcon, Button, Modal, TextInput } from "@mantine/core";

import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import { MdFormatColorFill } from "react-icons/md";
import { useDisclosure } from "@mantine/hooks";
import { updateFillMedication } from "../../services";
import { useSelector } from "react-redux";

const FillMedicationModal = ({ data, clientId }) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(null);
  const [newCcount, setNewCount] = useState(null);
  const { profile } = useSelector((state) => state?.profile);
  const [total, setTotal] = useState(null);

  const handleOpen = () => {
    open();
    data?.medicineRemaining && setCount(Number(data?.medicineRemaining));
    data?.totalMedicine && setTotal(Number(data?.totalMedicine));
  };

  const handleClose = () => {
    close();
    setCount(null);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const payload = {
      medicationId: data?.id,
      medicationName: data?.medicationName,
      clientId,
      count: Number(newCcount),
    };
    if (newCcount) {
      const resp = await updateFillMedication(payload);
      if (resp) {
        handleClose();
      }
    }
    setLoading(false);
  };

  return (
    <>
      <ActionIcon
        color=""
        id="fill"
        title="Fill"
        variant="light"
        onClick={handleOpen}
        // disabled={profile?.user?.type == "staff" ? true : false}
      >
        <MdFormatColorFill size={20} />
      </ActionIcon>
      <Modal
        centered
        size={"lg"}
        shadow="md"
        radius={"lg"}
        zIndex={1000}
        opened={opened}
        onClose={handleClose}
        overlayProps={{ blur: 3 }}
        className={classes.fillMedicationModal}
      >
        <CloseCircle onclose={handleClose} />
        <div className={classes.wrapper}>
          <h2>Update Medication Count</h2>

          <div className={classes.formRow}>
            <div className={classes.textInputBox}>
              <TextInput
                size="sm"
                disabled
                type="number"
                value={count}
                label={"Current Medication Count"}
                // placeholder={"Enter Current Count"}
                // onChange={(e) => setCount(e.target.value)}
              />
            </div>

            {/* <div className={classes.textInputBox}>
              <TextInput
                label={"Total Medication"}
                size="sm"
                // placeholder={"Enter Current Count"}
                type="number"
                disabled
                value={total}
                // onChange={(e) => setCount(e.target.value)}
              />
            </div> */}
          </div>

          {/* <div className={classes.textInputBox}>
            <TextInput
              label={"Total Medication"}
              size="sm"
              type="number"
              disabled
              value={total}
            />
          </div> */}

          {/* </div> */}

          <div className={classes.textInputBox}>
            <TextInput
              size="sm"
              type="number"
              id="refill-count"
              value={newCcount}
              label={"Increase Medication Count"}
              placeholder={"Enter Refill Count"}
              onChange={(e) => {
                let value = Number(e.target.value);
                if (value) {
                  setNewCount(value);
                  setCount(Number(data?.medicineRemaining) + value);
                  setTotal(Number(data?.totalMedicine) + value);
                } else {
                  setCount(Number(data?.medicineRemaining));
                  setTotal(Number(data?.totalMedicine));
                  setNewCount(null);
                }
              }}
            />
          </div>

          <Button
            loading={loading}
            id="update-refill"
            onClick={handleUpdate}
            className={classes.saveButton}
            disabled={Number(data?.medicineRemaining) === Number(count)}
          >
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FillMedicationModal;
