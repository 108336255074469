import React from "react";

export const ThreeIconSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.032 70.896C47.8347 70.896 45.712 70.5973 43.664 70C41.616 69.4027 39.7813 68.5173 38.16 67.344C36.5387 66.1707 35.248 64.72 34.288 62.992C33.3493 61.2427 32.88 59.2267 32.88 56.944H40.816C40.9013 58.3947 41.3387 59.6533 42.128 60.72C42.9173 61.7653 43.984 62.576 45.328 63.152C46.6933 63.7067 48.2613 63.984 50.032 63.984C52.6347 63.984 54.672 63.3867 56.144 62.192C57.616 60.9973 58.352 59.4827 58.352 57.648C58.352 56.4533 58.0533 55.4293 57.456 54.576C56.8587 53.7013 55.9413 53.04 54.704 52.592C53.4667 52.1227 51.888 51.888 49.968 51.888H44.464V45.936H49.136C51.568 45.936 53.4773 45.4133 54.864 44.368C56.2507 43.3227 56.944 41.904 56.944 40.112C56.944 38.512 56.3467 37.1787 55.152 36.112C53.9573 35.024 52.1653 34.48 49.776 34.48C47.8347 34.48 46.096 34.8747 44.56 35.664C43.0453 36.4533 42.0747 37.8293 41.648 39.792H33.712C34.0107 36.9547 34.864 34.64 36.272 32.848C37.7013 31.056 39.5467 29.744 41.808 28.912C44.0693 28.0587 46.6187 27.632 49.456 27.632C52.464 27.632 55.1413 28.1227 57.488 29.104C59.8347 30.0853 61.68 31.4507 63.024 33.2C64.3893 34.928 65.072 36.9333 65.072 39.216C65.072 41.328 64.496 43.1733 63.344 44.752C62.192 46.3093 60.4853 47.472 58.224 48.24C60.7413 49.0933 62.7573 50.3627 64.272 52.048C65.7867 53.7333 66.544 55.8347 66.544 58.352C66.544 60.8053 65.8507 62.9813 64.464 64.88C63.0987 66.7573 61.1787 68.2293 58.704 69.296C56.2293 70.3627 53.3387 70.896 50.032 70.896Z"
        fill="#FFBA45"
      />
      <rect x="0.5" y="0.5" width="99" height="99" rx="49.5" stroke="#FFBA45" />
    </svg>
  );
};
