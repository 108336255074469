import React, { useEffect, useRef, useState } from "react";
import Footer from "../LandingPage/Footer";
import { Box, Button, Card, Grid, Skeleton, Text } from "@mantine/core";
import { useStyles } from "./style";
import PricingCards from "./PricingCards";
import Faqcomp from "./Faq";
import StartedBanner from "../LandingPage/StartedBanner";
import Header from "../../components/Header/Header";
import { getPackages } from "../../services/package";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderMain from "../../components/Header/HeaderMain";
import { useMediaQuery } from "@mantine/hooks";
import { pageInfoAnalytics, TrackPageExit } from "../../config/mixpanelConfig";

const PricingComp = () => {
  const { classes } = useStyles();
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const [showHeaderMain, setShowHeaderMain] = useState(true);
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isLaptopScreen = useMediaQuery("(max-width: 900px)");
  const navigate = useNavigate();
  const location = useLocation();
  const featuresRef = useRef(null);

  useEffect(() => {
    if (location.state?.scrollToFeatures && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowHeaderMain(false);
      } else {
        setShowHeaderMain(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, []);

  const getAllPackages = async () => {
    const data = await getPackages();
    if (data) {
      setLoadingPackages(false);
      setPackages(data);
    }
  };

  useEffect(() => {
    getAllPackages();
    window.scrollTo(0, 0);
    pageInfoAnalytics("Pricing Page Viewed", "Pricing Page");
    TrackPageExit("Pricing");
  }, []);

  const handleClick = () => {
    navigate("/signup");
  };

  const renderSkeleton = () => {
    return (
      <Grid.Col span={isMobileScreen ? 12 : isLaptopScreen ? 6 : 3}>
        <Skeleton loading={true} mt={30}>
          <div style={{ height: "630px", width: "300px" }}></div>
        </Skeleton>
      </Grid.Col>
    );
  };

  const renderPackageCard = (item) => {
    return (
      <Grid.Col span={isMobileScreen ? 12 : isLaptopScreen ? 6 : 3}>
        <PricingCards
          item={item}
          onClick={handleClick}
          isTrial={item?.name === "Trial"}
          isPriceCard={item?.name === "Silver"}
          theme={item?.name === "Silver" && "black"}
        />
      </Grid.Col>
    );
  };

  const renderEnterpriseCard = () => {
    return (
      <Grid.Col span={isMobileScreen ? 12 : isLaptopScreen ? 6 : 3}>
        <Card shadow="sm" mt={"30px"} withBorder className={classes.Card}>
          <Text className={classes.cardStatus}> Enterprise</Text>
          <Box
            className={classes.cardColor}
            style={{ backgroundColor: "#772598" }}
          ></Box>
          <Box className={classes.cardContent}>
            <Text className={classes.cardAmount}>
              <h2 className={classes.cast}>Contact Us</h2>
            </Text>
            <ul className={classes.cardList}>
              <li>Reach out to sales representative</li>
            </ul>
            <Button
              className={classes.cardButton}
              onClick={() => navigate("/contact-us")}
            >
              Schedule a Call
            </Button>
          </Box>
        </Card>
      </Grid.Col>
    );
  };

  return (
    <>
      <Box
        style={{
          opacity: showHeaderMain ? 1 : 0,
          visibility: showHeaderMain ? "visible" : "hidden",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <HeaderMain />
      </Box>
      <Box
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999,
          opacity: showHeaderMain ? 0 : 1,
          visibility: showHeaderMain ? "hidden" : "visible",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <Header />
      </Box>
      <Box className={classes.InlinePadding}>
        <Box className={classes.container}>
          <Text className={classes.primaryHeading}>
            Find the plan that <br /> works for you
          </Text>
          <Grid gutter={0}>
            {loadingPackages
              ? [1, 2, 3]?.map(() => renderSkeleton())
              : packages?.packages?.map((item) => renderPackageCard(item))}
            {loadingPackages ? renderSkeleton() : renderEnterpriseCard()}
          </Grid>
          <Box>
            <Faqcomp />
          </Box>
        </Box>
      </Box>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default PricingComp;
