import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  ContactUs: {
    padding: "6rem",
    "@media (max-width: 765px)": {
      padding: "2rem",
    },
    "@media (max-width: 500px)": {
      padding: "1rem",
    },
  },
  contactContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  contactHeading: {
    fontSize: "50px",
    fontWeight: "600",
    // paddingTop: '4rem',
    margin: "0",
  },
  formtHeading: {
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "30px",
    "@media (max-width: 600px)": {
      fontSize: "27px",
    },
  },
  formTitle: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0",
    fontFamily: "Inter",
    lineHeight: "30px",
    marginTop: "10px",
  },
  link:{
    textDecoration: "none",
    color: "#000"
  },
  hilightedtext: {
    color: theme.other.black,
    fontWeight: "500",
    cursor: "pointer"
  },
  termsCondition: {
    fontSize: "15px",
    fontWeight: "400",
    color: theme.other.lightGray,
    textAlign: "center"
  },
  contectText: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    margin: "0",
    fontFamily: "Inter",
    width: "60%",
    "@media (max-width: 1220px)": {
      width: "90%",
    },
    "@media (max-width: 760px   )": {
      width: "100%",
    },
  },
  message: {
    // textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "2rem",
    fontFamily: "Inter",
  },
  formContent: {
    padding: "25px",
    borderRadius: "30px",
    minWidth: "400px",
    maxWidth: "400px",
    width: "100%",
    boxShadow: theme.other.boxShadow,
    "@media (max-width: 1000px)": {
      maxWidth: "600px",
    },
    "@media (max-width: 760px)": {
      width: "90%",
      minWidth: "300px",
    },
  },
  contentSection: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginTop: "40px",
    alignItems: "stretch",
  },
  sectionBox: {
    width: " 32%",
    maxWidth: "32%",
    minWidth: "200px",

    "@media (max-width: 1000px)": {
      minWidth: "100%",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  sectionHeading: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "500",
    color: theme.other.dark,
  },
  sectionText: {
    fontSize: "15    px",
    color: theme.other.lightGray,
    fontWeight: "400",
  },
  messageInput: {
    fontFamily: "Inter",
    marginBottom: "10px",
    width: "100%",
    resize: "none",
    overflowY: "hidden",
  },
  submitBTN: {
    width: "100%",
  },

  text3: {
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: rem(22),
    color: "#979797",
    marginTop: rem(26),
    marginBottom: rem(4),
    "@media (max-width: 1440px)": {
      fontSize: rem(18),
    },
  },

  AccordionHeading: {
    fontSize: "45px",
    lineHeight: "66px",
    marginTop: "60px",
    fontWeight: "400",
    color: theme.other.black,
    "@media (max-width: 760px)": {
      fontSize: "35px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "25px",
      lineHeight: "23px",
    },
  },

  question: {
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "36px",
    marginTop: "30px",
    color: theme.other.black,
    "@media (max-width: 760px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "16px",
      lineHeight: "23px",
    },
  },

  answer: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: theme.other.lightGray,
    width: "90%",
    "@media (max-width: 760px)": {
      fontSize: "16px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "14",
      lineHeight: "23px",
      width: "98%",
      fontWeight: "300",
    },
  },

  chevron: {
    transition: "transform 0.2s ease", // O
    "&[data-rotate]": {
      transform: "rotate(45deg)",
    },
  },

  icon: {
    width: "16px",
    height: "16px",
  },
  faqContainer:{
    margin: "20px 0"
  }
}));
export default useStyles;
