import { Box, Grid, Tabs, Text } from "@mantine/core";
import React, { useState } from "react";
import Form1 from "../../../components/PatientModal/PersonalForm";
import useStyles from "./style";
import FormInfo from "./FormInfo";
import InsuranceForm from "../../../components/PatientModal/InsuranceForm";
import PersonalForm from "../../../components/PatientModal/PersonalForm";
import HealthForm from "../../../components/PatientModal/HealthForm";
import CareSupport from "../../../components/PatientModal/CareSupport";
import { OneIconSvg } from "../../../components/Svgs/OneIconSvg";
import { TwoIconSvg } from "../../../components/Svgs/TwoIconSvg";
import { ThreeIconSvg } from "../../../components/Svgs/ThreeIconSvg";
import { FourIconSvg } from "../../../components/Svgs/FourIconSvg";
import { YellowMarkSvg } from "../../../components/Svgs/YellowMarkSvg";
import { addPatient, editPatient } from "../../../services/patient";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import SelectCarePlanTypeModal from "../../../components/SelectCarePlanTypeModal";
import CarePlaneDetails from "../../../components/MAR/CarePlaneDetails";
import MedicationAskModal from "./MedicationAskModal";
import AddNewMedicationModal from "../../../components/AddNewMedicationModal";
import { decrypt } from "../../../utils";

const ClientForm = ({
  view,
  page,
  title,
  close,
  opened,
  setView,
  facility,
  activeTab,
  selectedTab,
  currentPage,
  buttonLabel,
  initialValues,
  getAllPatients,
  backAllClients,
  setPopulateName,
  setShowClientForm,
  setPopulateFacility,
  ...data
}) => {
  const { classes } = useStyles();
  const [active, setActive] = useState(0);
  const [completedForms, setCompletedForms] = useState({
    personalInfo: false,
    healthInfo: false,
    careSupportInfo: false,
    insuranceInfo: false,
  });

  const [activeTabValue, setActiveTabValue] = useState(
    selectedTab?.formName || "personalInfo"
  );

  // const edit = data?.data ? true : false;
  const edit = !!initialValues;
  const [state, setState] = useState(
    initialValues
      ? {
          form1: initialValues?.form1,
          form2: initialValues?.form2,
          form3: initialValues?.form3 || [],
          imageurl: initialValues?.imageurl,
        }
      : {
          form1: null,
          form2: null,
          form3: [],
        }
  );

  console.log("🚀 ~ state ======================>:", state);
  setPopulateName(state?.form1?.name);
  const isMobileScreen = useMediaQuery("(max-width: 1025px)");

  const [showCarePlanDetails, setShowCarePlanDetails] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPlanType, setSelectedPlanType] = useState(null);

  const carePlanSelected = (type) => {
    setSelectedPlanType(type);
    setShowCarePlanDetails(true);
    closeModal();
  };

  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const [
    openedAskMedicalModal,
    { open: openAddMedicalModal, close: closeAskMedicalModal },
  ] = useDisclosure(false);

  const [
    openedMedicalModal,
    { open: openMedicalModal, close: closeMedicalModal },
  ] = useDisclosure(false);

  // submitHandle
  const submitHandle = async (tempform2) => {
    // form1: tempfrom1 ? tempfrom1 : {...state?.form1, socialSecurityNumber : decrypt(state?.form1?.socialSecurityNumber, false)},
    let obj = {
      form1: {...state?.form1, socialSecurityNumber : decrypt(state?.form1?.socialSecurityNumber, false)},
      form2: tempform2,
      form3: state?.form3,
      name: state?.form1?.name,
      imageurl: state?.imageurl,
      // form2: state?.form2,
      // facilityId: facility,
    };
    console.log("🚀 ~ submitHandle state ~ obj:", obj)
    if (edit) {
      obj.id = initialValues?.id;
      try {
        let response = await editPatient(obj, false, page);
        if (response.status === 200) {
          backAllClients(true);
        }
      } catch (error) {
        console.error("Error editing patient:", error);
      }
    } else {
      let obj = {
        form2: tempform2,
        form3: state?.form3,
        form1: state?.form1,
        facilityId: facility,
        name: state?.form1?.name,
        imageurl: state?.imageurl,
        // facilityId: facility?.map((facility) => facility?.value),
      };
      // delete obj.active;

      let response = await addPatient(obj);
      if (response.status === 200) {
        setSelectedClient(response?.data?.patient?.id);
        openModal();
        // backAllClients(true);
      }
      // if (response.status === 200) {
      //   alert(response?.data?.message);
      //   const create = await swal({
      //     title: `Support Plan`,
      //     text: "Do you want to create support plan?",
      //     icon: "info",
      //     buttons: ["No", "Yes"],
      //   });
      //   if (create) {
      //     getAllPatientsModal();
      //     localStorage.setItem("performActionFlag", "false");
      //     navigate("/home/supportPlan", {
      //       state: {
      //         newPlan: true,
      //         patient: response?.data?.patient?.id,
      //         facility: response?.data?.patient?.facilityId,
      //       },
      //     });

      //     close();
      //   } else {
      //     close();
      //   }
      // } else {
      //   await addPatient(obj);
      //   close();
      // }
    }
  };

  const saveHandle = async (tempfrom1, tempform2, tempform3) => {
    let obj = {
      form1: tempfrom1 ? tempfrom1 : {...state?.form1, socialSecurityNumber : decrypt(state?.form1?.socialSecurityNumber, false)},
      form2: tempform2 ? tempform2 : state?.form2,
      form3: tempform3 ? tempform3 : state?.form3,
      name: tempfrom1 ? tempfrom1?.name : state?.form1?.name,
      imageurl: state?.imageurl,
    };
    console.log("🚀 ~ submitHandle state ~ obj of edit:", obj);
    if (edit) {
      obj.id = initialValues?.id;
      try {
        let response = await editPatient(obj, false, page);
        if (response.status === 200) {
          backAllClients(true);
          // window.location.reload();
        }
      } catch (error) {
        console.error("Error editing patient:", error);
      }
    }
  };

  const handleFormSubmit = (formKey, nextTab) => {
    setCompletedForms((prev) => ({
      ...prev,
      [formKey]: true,
    }));
    setActiveTabValue(nextTab);
  };
  // const {  addPlanLoading } = useSelector((state) => state?.supportPlan);
  // const [rowData, setRowData] = useState(null);
  // const handleTableBoxClick = () => {
  //   setShowCarePlanDetails(false);
  //   setRowData(null);
  // };

  return (
    <>
      {!showCarePlanDetails && (
        <Box className={classes.ClientForm}>
          <Tabs
            color="yellow"
            variant="pills"
            radius="xs"
            defaultValue="personalInfo"
            orientation={isMobileScreen ? "horizontal" : "vertical"}
            className={classes.tabs}
            value={activeTabValue}
            onTabChange={setActiveTabValue}
          >
            <Tabs.List className={classes.tabList}>
              <Tabs.Tab value="personalInfo" className={classes.tab}>
                <Box className={classes.tabTitle}>
                  {completedForms?.personalInfo ? (
                    <YellowMarkSvg />
                  ) : (
                    <OneIconSvg />
                  )}

                  <Text variant="text" color="#000">
                    Personal Information
                  </Text>
                </Box>
              </Tabs.Tab>
              <Tabs.Tab
                value="healthInfo"
                className={classes.tab}
                // disabled={!completedForms.healthInfo}
              >
                <Box className={classes.tabTitle}>
                  {completedForms?.healthInfo ? (
                    <YellowMarkSvg />
                  ) : (
                    <TwoIconSvg />
                  )}
                  <Text variant="text" color="#000">
                    Health Information
                  </Text>
                </Box>
              </Tabs.Tab>
              <Tabs.Tab
                value="careSupportInfo"
                className={classes.tab}
                // disabled={!completedForms.careSupportInfo}
              >
                <Box className={classes.tabTitle}>
                  {completedForms?.careSupportInfo ? (
                    <YellowMarkSvg />
                  ) : (
                    <ThreeIconSvg />
                  )}
                  <Text variant="text" color="#000">
                    Care & Support Information
                  </Text>
                </Box>
              </Tabs.Tab>
              <Tabs.Tab value="insuranceInfo" className={classes.tab}>
                <Box className={classes.tabTitle}>
                  {completedForms?.insuranceInfo ? (
                    <YellowMarkSvg />
                  ) : (
                    <FourIconSvg />
                  )}
                  <Text variant="text" color="#000">
                    Insurance Information
                  </Text>
                </Box>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="personalInfo">
              <Grid cols={2} className={classes.formContent}>
                <Grid.Col span="auto" className={classes.form}>
                  <PersonalForm
                    title="Personal Information"
                    view={view}
                    edit={edit}
                    active={active}
                    state={state}
                    functions={{ close }}
                    setState={setState}
                    setActive={setActive}
                    activeTabValue={activeTabValue}
                    handleFormSubmit={handleFormSubmit}
                    classes={classes}
                    facility={facility}
                    selectedTab={selectedTab}
                    saveHandle={saveHandle}
                    //   ref={form1Ref}
                    //   options={options}
                  />
                </Grid.Col>
                <Grid.Col span={4} className={classes.formInfo}>
                  <FormInfo activeTabValue={activeTabValue} />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel value="healthInfo">
              <Grid cols={2} className={classes.formContent}>
                <Grid.Col span="auto" className={classes.form}>
                  <HealthForm
                    title="Health Information"
                    view={view}
                    edit={edit}
                    active={active}
                    state={state}
                    functions={{ close }}
                    setState={setState}
                    setActive={setActive}
                    handleFormSubmit={handleFormSubmit}
                    classes={classes}
                    saveHandle={saveHandle}
                    selectedTab={selectedTab}
                    //   ref={form1Ref}
                    //   options={options}
                  />
                </Grid.Col>
                <Grid.Col
                  span={4}
                  className={classes.formInfo}
                  style={{ overflowY: "auto" }}
                >
                  <FormInfo activeTabValue={activeTabValue} />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel value="careSupportInfo">
              <Grid cols={2} className={classes.formContent}>
                <Grid.Col span="auto" className={classes.form}>
                  <CareSupport
                    title="Care & Support Information"
                    view={view}
                    edit={edit}
                    active={active}
                    state={state}
                    functions={{ close }}
                    setState={setState}
                    setActive={setActive}
                    handleFormSubmit={handleFormSubmit}
                    classes={classes}
                    saveHandle={saveHandle}
                    selectedTab={selectedTab}
                    //   ref={form1Ref}
                    //   options={options}
                  />
                </Grid.Col>
                <Grid.Col span={4} className={classes.formInfo}>
                  <FormInfo activeTabValue={activeTabValue} />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel value="insuranceInfo">
              <Grid cols={2} className={classes.formContent}>
                <Grid.Col span="auto" className={classes.form}>
                  <InsuranceForm
                    title="Insurance Information"
                    view={view}
                    edit={edit}
                    active={active}
                    state={state}
                    buttonLabel={buttonLabel}
                    functions={{ close }}
                    setState={setState}
                    setActive={setActive}
                    handleFormSubmit={handleFormSubmit}
                    backAllClients={backAllClients}
                    classes={classes}
                    onSubmit={submitHandle}
                    //   ref={form1Ref}
                    //   options={options}
                  />
                </Grid.Col>
                <Grid.Col span={4} className={classes.formInfo}>
                  <FormInfo activeTabValue={activeTabValue} />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        </Box>
      )}

      {selectedClient && selectedPlanType && (
        <CarePlaneDetails
          // rowData={rowData}
          // onSelect={carePlanSelected}
          segment={selectedTab}
          client={selectedClient}
          carePlanType={selectedPlanType}
          openAddMedicalModal={openAddMedicalModal}
          // setRowData={setRowData}
          // addPlanLoading={addPlanLoading}
          // setShowCarePlanDetails={setShowCarePlanDetails}
          // handleTableBoxClick={handleTableBoxClick}
        />
      )}
      <SelectCarePlanTypeModal
        // open={opened2}
        // close={close2}
        close={closeModal}
        open={openedModal}
        onSelect={carePlanSelected}
        openAddMedicalModal={openAddMedicalModal}
      />

      <MedicationAskModal
        open={openedAskMedicalModal}
        close={closeAskMedicalModal}
        setShowClientForm={setShowClientForm}
        currentPage={currentPage}
        getAllPatients={getAllPatients}
        openMedicalModal={openMedicalModal}
        onOpened={() => {
          closeAskMedicalModal();
          openMedicalModal();
        }}
        // setSelectedPlanType={setSelectedPlanType}
      />

      <AddNewMedicationModal
        opened={openedMedicalModal}
        onclose={() => {
          closeMedicalModal()
        }}
        selectedClient={selectedClient}
        currentPage={currentPage}
        getAllPatients={getAllPatients}
        setShowClientForm={setShowClientForm}
        // data={medicine}
        // isPreview={isPreview}
      />
    </>
  );
};

export default ClientForm;
