import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  medicationModal: {
    width: "100%",
    overflow: "auto",
    // height: 600,
    borderRadius: "1rem 1rem 1rem 1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",

    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4ead8",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "80px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
    },
  },

  mainContentWarpper: {
    width: "100%",
    maxHeight: "auto",
    overflowY: "auto",
  },
  mainContent: {
    width: "100%",
    padding: "10px",
  },
  select: {
    background: "#f1f3f5",
    padding: "2px 10px",
    width: "280px",
  },
  reasonBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "@media (max-width: 600px)": {
      width: "100%",
      flexDirection: "column",
    },
  },
  otherReason: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  selectBoxDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem",
    width: "100%",
    flexWrap: "wrap",
  },
 
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    marginTop: "20px",
    marginBottom: "20px",
  },
  datePicker: {
    width: "250px",
    "& .mantine-DatePickerInput-input": {
      border: "none",
      borderRadius: "0px !important",
      borderBottom: "2px solid #A8A8A8 !important",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  viewModalContent: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    background: "#f1f3f5",
    overflowY: "auto",
    height: "500px",
  },
  headerContent: {
    background: "white",
    borderRadius: "5px",
    padding: "1rem",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%",
  },
  userImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    width: "100%",
  },
  body: {
    background: "white",
    borderRadius: "5px",
    padding: "20px",
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  type: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    background: "#f1f3f5",
    borderRadius: "5px",
    width: "max-content",
    padding: "5px",
    "& p": {},
  },
  date: {
    background: "#ffba45",
    padding: "5px",
    borderRadius: "20px",
  },
  accordionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    borderRadius: "5px",
    width: "max-content",
    padding: "5px",
    "@media (max-width:800px)": {
      width: "auto",
      gap: "0",
    },
  },
  accordionTitle: {
    background: "#f1f3f5",
    padding: "5px 10px",
    width: "200px",
  },
  howOften: {
    border: "1px solid #f1f3f5",
    borderRadius: "5px",
    padding: "10px",
    width: "250px",
    margin: "0",
    "& p": {
      marginBottom: "0",
    },
    "& a": {
      color: "#979797",
    },
    "& .mantine-xloy54": {
      gap: "0 0 !important",
    },
  },
  supportBorder: {
    // border: "1px solid #f1f3f5",
    // borderRadius: "5px",
    overflowY: "auto",
    padding: "10px",
    width: "350px",
    textAlign: "justify",
    height: "150px",
    marginTop: "2px",
    "& p": {
      marginBottom: "0",
      textAlign: "justify"

    },
    "& a": {
      color: "#979797",
    },
  },
  supportDetailsContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    marginTop: "20px",
    height: "100%",
    overflow: "scroll",
  },
  accordionPanel: {
    // overflow: "auto",
    height: "100%",
    zIndex: 1,
    position: "relative",
    background: "#fff",
    // overflow: "auto",
  },
  activityNumber: {
    width: "10%",
  },
  supportActivityDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    width: "100%",
    marginTop: "20px",
  },
  supportActivityText: {
    color: "#979797",
  },
  supportDetailRightButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    width: "100%",
    flexDirection: "column",
  },
  textInputBox: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "5px 15px",
    borderRadius: "15px",
    position: "relative",
    "& textarea[disabled]": {
      backgroundColor: "#fff !important",
    },
    "& .mantine-Textarea-input": {
      border: "none",
      paddingInline: "0",
    },
    "& .label": {
      position: "absolute",
      top: -10,
      left: 15,
      zIndex: 100,
      color: "#000",
      margin: 0,
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
  },
  supportDetailButtons:{
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  }
}));

export default useStyles;


