import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  medications: [],
  count:0,
};

export const medicationState = createSlice({
  name: "medications",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },

    setMedications(state, action) {
     
      return {
        ...state,
        loading: false,
        medications: action?.payload?.medications,
        
        count: action?.payload?.count,
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const { setLoading, setMedications } = medicationState.actions;
export default medicationState.reducer;
