import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addReportLoading: false,
  reports: []
};

export const facilityState = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setLoadingAddReport(state, action) {
      return {
        ...state,
        addReportLoading: action?.payload
      };
    },
    setReports(state, action) {
      return {
        ...state,
        loading: false,
        reports: action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const { setLoading, setLoadingAddReport, setReports } =
  facilityState.actions;
export default facilityState.reducer;
