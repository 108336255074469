import {
  ActionIcon,
  Box,
  Group,
  Select,
  SimpleGrid,
  Text,
  TextInput
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { noteType_Client_Options } from "../../utils";

const CustomRow = ({ data, classes, view, edit, functions }) => {
  let { handleFieldsEdit, removeItem } = functions;
  const { fieldType } = useSelector((state) => state.note);

  return data?.map((element, index) => {
    return (
      <Group key={index}>
        <Box sx={{ width: "90%" }} className={classes.bordered}>
          <Text style={{ fontWeight: 600 }}>
            {edit ? "Field" : "Custom Field"}
          </Text>
          <SimpleGrid
            cols={3}
            className={classes.mwd}
            sx={{
              paddingTop: 20
            }}
          >
            <TextInput
              size="md"
              label={view ? "Field Name" : null}
              icon={<img src="/assets/images/home2.png" alt="home" />}
              placeholder="Field Name"
              className={classes.myfont}
              value={element?.field}
              onChange={(e) => {
                handleFieldsEdit(e?.target?.value, "field", element);
              }}
              disabled={view || edit}
            />

            <Select
              data={fieldType?.map((val) => ({
                value: val?.id,
                label: val?.name?.toUpperCase()
              }))}
              size="md"
              label={view ? "Field Type" : null}
              icon={<img src="/assets/images/building.png" alt="home" />}
              placeholder="Field Type"
              className={classes.myfont}
              value={element?.fieldType}
              onChange={(value) => {
                handleFieldsEdit(value, "fieldType", element);
              }}
              disabled={view || edit}
              // disabled
            />

            {"options" in element &&
              element?.options != noteType_Client_Options &&
              element?.options != "Facilities" &&
              element?.options != "Staff" && (
                <TextInput
                  size="md"
                  label={view ? "Other Options" : null}
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="Other Options"
                  className={classes.myfont}
                  value={element?.others}
                  onChange={(e) => {
                    handleFieldsEdit(e?.target?.value, "others", element);
                  }}
                  disabled={view || edit}
                  // disabled
                />
              )}

            <Select
              data={["Yes", "No"]}
              size="md"
              icon={<img src="/assets/images/home2.png" alt="home" />}
              placeholder="Field Priority"
              label={view ? "Priority" : null}
              className={classes.myfont}
              value={element?.priority ? "Yes" : "No"}
              onChange={(value) => {
                handleFieldsEdit(
                  value === "Yes" ? true : false,
                  "priority",
                  element
                );
              }}
              disabled={view || edit}
              // disabled
            />

            <Select
              data={["Yes", "No"]}
              size="md"
              label={view ? "View Only" : null}
              icon={<img src="/assets/images/home2.png" alt="home" />}
              placeholder="View Only"
              className={classes.myfont}
              value={element?.viewOnly ? "Yes" : "No"}
              onChange={(value) => {
                handleFieldsEdit(
                  value === "Yes" ? true : false,
                  "viewOnly",
                  element
                );
              }}
              disabled={view || edit}
              // disabled
            />
          </SimpleGrid>
        </Box>
        {!view && (
          <Box>
            <ActionIcon
              color="red"
              variant="light"
              size={44}
              mt={5}
              sx={{ padding: 10 }}
              onClick={() => removeItem(element)}
            >
              <IconTrash size={"md"} />
            </ActionIcon>
          </Box>
        )}
      </Group>
    );
  });
};
export default CustomRow;
