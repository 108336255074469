import React, { useState } from "react";
import { Button, Modal, PasswordInput } from "@mantine/core";

import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import { verifyPasswordAndUpdateEmail } from "../../services/profile";
import { useNavigate } from "react-router-dom";

const VerifyPassword = ({ opened, onclose, objForUpdate }) => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleClose = () => {
    onclose();
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (password?.trim() !== "" && !!objForUpdate?.oldEmail) {
      await verifyPasswordAndUpdateEmail(
        {
          newEmail: objForUpdate?.newEmail,
          email: objForUpdate?.oldEmail,
          password: password,
        },
        navigate
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.fillMedicationModal}
        opened={opened}
        onClose={handleClose}
        shadow="md"
        overlayProps={{ blur: 3 }}
        radius={"lg"}
        zIndex={1000}
        centered
        size={"lg"}
      >
        <CloseCircle onclose={handleClose} />
        <div className={classes.wrapper}>
          <h2>Verify Password</h2>
          <h4>
            Changing Email requires confirmation, please enter your current
            password to verify.
          </h4>

          <div className={classes.textInputBox}>
            <PasswordInput
              mt="xl"
              label="Password"
              variant="filled"
              placeholder="Eg.A*****"
              size={"lg"}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.passwordInput}
            />
          </div>

          <Button
            loading={loading}
            onClick={handleUpdate}
            className={classes.saveButton}
          >
            Verify
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VerifyPassword;
