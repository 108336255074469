import { Box, Grid, Text } from "@mantine/core";
import React from "react";
import useStyles from "./style";
import { useMediaQuery } from "@mantine/hooks";
import schedulerImg from "./../../images/schedulerImg.png";
import formsImg from "./../../images/formsImg.png";
import skyAiImg from "./../../images/skyAiImg.png";
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";
import { DemoBookButton } from "../../components/BookADemoButton/DemoBookButton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const UpcomingFeatures = () => {
  const { classes } = useStyles();
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isLabptopScreen = useMediaQuery("(max-width: 1024px)");
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    const { classes } = useStyles();
    return (
      <button
        className={`${classes.carouselArrow} ${classes.leftArrow}`}
        onClick={onClick}
      >
        <PiCaretLeft size={35} />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    const { classes } = useStyles();
    return (
      <button
        className={`${classes.carouselArrow} ${classes.rightArrow}`}
        onClick={onClick}
      >
        <PiCaretRight size={35} />
      </button>
    );
  };

  
  return (
    <Box>
      <Box className={classes.features}>
        <Box className={classes.featuresBtn}>Upcoming Features</Box>
      </Box>
      <Carousel
        ssr={true}
        infinite={true}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        slidesToSlide={1}
        pauseOnHover={false}
        autoPlaySpeed={12000}
        keyBoardControl={true}
        responsive={responsive}
        transitionDuration={1000}
        containerClass="carousel-container"
        className={classes.CarouselContainer}
        itemClass="carousel-item-padding-40-px"
        dotListClass={classes.customDotListStyle}
        showDots={isLabptopScreen ? true : false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        customTransition="transform 500ms ease-in-out 0s"
        customRightArrow={
          <CustomRightArrow onClick={() => this.carouselRef.next()} />
        }
        customLeftArrow={
          <CustomLeftArrow onClick={() => this.carouselRef.previous()} />
        }
      >
        {/* Scheduler (Coming Soon) */}
        <Grid
          gutter="lg"
          py={isMobileScreen ? "0px" : "5rem"}
          mx={isLabptopScreen ? "1rem" : "8rem"}
          className={classes.MobileFlexDirection}
        >
          <Grid.Col
            span={isMobileScreen ? 12 : 6}
            className={classes.carouselImgFlex}
            maw={"100%"}
          >
            <img
              src={schedulerImg}
              alt="schedulerImg"
              className={classes.img}
            />
          </Grid.Col>
          <Grid.Col
            span={isMobileScreen ? 12 : 6}
            maw={"100%"}
            ta={isMobileScreen ? "center" : "left"}
          >
            <Text className={classes.primaryHeading}>
              Scheduler (Coming Soon)
            </Text>
            <Text className={classes.primarySubHeading}>
              Create, Track and Maintain Appointments with Our New Scheduler
            </Text>
            <Text className={classes.text}>
              Organize appointments and track events effortlessly. Customize
              your schedule by appointment type and other criteria to better
              manage your time and resources.
            </Text>
            <DemoBookButton />
          </Grid.Col>
        </Grid>
        {/* Forms (Coming Soon) */}
        <Grid
          gutter="lg"
          py={isMobileScreen ? "0px" : "5rem"}
          mx={isLabptopScreen ? "1rem" : "8rem"}
          className={classes.MobileFlexDirection}
        >
          <Grid.Col
            span={isMobileScreen ? 12 : 6}
            maw={"100%"}
            ta={isMobileScreen ? "center" : "left"}
          >
            <Text className={classes.primaryHeading}>Forms (Coming Soon)</Text>
            <Text className={classes.primarySubHeading}>
              Custom Forms Management
            </Text>
            <Text className={classes.text}>
              Create and manage custom forms to track essential business
              information and maintain compliance. Tailor forms to your specific
              needs for efficient data collection.
            </Text>
            <DemoBookButton />
          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 12 : 6} maw={"100%"}>
            <img src={formsImg} alt="formsImg" className={classes.img} />
          </Grid.Col>
        </Grid>
        {/* Sky AI (Coming Soon) */}
        <Grid
          gutter="lg"
          py={isMobileScreen ? "0px" : "5rem"}
          mx={isLabptopScreen ? "1rem" : "8rem"}
        >
          <Grid.Col
            span={isMobileScreen ? 12 : 6}
            className={classes.carouselImgFlex}
            maw={"100%"}
          >
            <img src={skyAiImg} alt="skyAiImg" className={classes.img} />
          </Grid.Col>
          <Grid.Col
            span={isMobileScreen ? 12 : 6}
            maw={"100%"}
            ta={isMobileScreen ? "center" : "left"}
          >
            <Text className={classes.primaryHeading}>Sky AI (Coming Soon)</Text>
            <Text className={classes.primarySubHeading}>
              Revolutionize your operations with our upcoming AI assistant{" "}
            </Text>
            <Text className={classes.text}>
              Enhance productivity and streamline workflows with advanced AI
              capabilities. Sky Sky AI will be your dedicated business
              assistant, ensuring you remain compliant at every stage.
            </Text>
            <DemoBookButton />
          </Grid.Col>
        </Grid>
      </Carousel>
    </Box>
  );
};

export default UpcomingFeatures;
