import { createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  loader: {
    height: "30vh",
    width: "100%",
    paddingTop: 50
  }
}));
function RecordNotFound() {
  const { classes } = useStyles();
  return (
    <div className={classes?.loader}>
      <center>
        <img src={"/assets/images/notfound.png"} alt="record not found" />
      </center>
    </div>
  );
}

export default RecordNotFound;
