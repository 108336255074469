import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingTypes: false,
  modalLoading: false,
  loadingTasks: false,
  taskTypes: [],
  taskTypesCount: 0,
  tasks: [],
  tasksCount: 0,
  subTask:[],
  fields: []
};

export const taskState = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTypesLoading(state, action) {
      // console.log(action.payload)
      return {
        ...state,
        loadingTypes: action?.payload,
      };
    },
    setModalLoading(state, action) {
      return {
        ...state,
        modalLoading: action?.payload,
      };
    },
    setLoadingTasks(state, action) {
      return {
        ...state,
        loadingTasks: action?.payload,
      };
    },
    setTaskTypes(state, action) {
      return {
        ...state,
        loadingTypes: false,
        taskTypes: action?.payload?.data,
        taskTypesCount: action?.payload?.count,
      };
    },
    setTasks(state, action) {
      return {
        ...state,
        loadingTasks: false,
        tasks: action?.payload?.data,
        tasksCount: action?.payload?.count,
      };
    },
    setSubTask(state, action) {
      return {
        ...state,
        subTask : action?.payload
      };
    },
    setFields(state, action) {
      return {
        ...state,
        fields : action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    },
  },
});

export const {
  setTypesLoading,
  setLoadingTasks,
  setTaskTypes,
  setModalLoading,
  setTasks,
  setSubTask,
  setFields
} = taskState.actions;
export default taskState.reducer;
