import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  forgotLoading: false,
  resetPasswordLoading: false,
  isLoggedIn: localStorage.getItem("token") ? true : false,
  emailVerifyLoading: false,
  token: localStorage.getItem("token"),
  role: {
    roleId: localStorage.getItem("roleId"),
    name: "",
    permissions: {
      users: 0,
    },
  },
  
  roleLoading: false,
  permissionsLoading: false,
  roles: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload,
      };
    },
    setForgotLoading(state, action) {
      return {
        ...state,
        forgotLoading: action?.payload,
      };
    },
    setResetLoading(state, action) {
      return {
        ...state,
        resetPasswordLoading: action?.payload,
      };
    },

    _login(state, action) {
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: action.payload.token,
      };
    },

    setRolesLoading(state, action) {
      return {
        ...state,
        roleLoading: action?.payload,
      };
    },
    setEmailVerifyLoading(state, action) {
      return {
        ...state,
        emailVerifyLoading: action?.payload,
      };
    },

    setRoles(state, action) {
      return {
        ...state,

        roles: action?.payload,
      };
    },
    setContactUs(state, action) {
      return {
        ...state,
        ContactUs: action?.payload,
      };
    },
    setRolePermissions(state, action) {
      return {
        ...state,
        role: {
          ...state.role,
          permissions: action.payload,
        },
        permissionsLoading: false,
      };
    },
    setRoleName(state, action) {
      return {
        ...state,
        role: {
          ...state.role,
          name: action.payload,
        },
      };
    },
    setPermissionLoading(state, action) {
      return {
        ...state,
        permissionsLoading: action?.payload,
      };
    },

    // Reset State
    reset() {
      return initialState;
    },
  },
});

export const {
  _login,
  reset,
  setLoading,
  setRolesLoading,
  setRoles,
  setRolePermissions,
  setRoleName,
  setPermissionLoading,
  setEmailVerifyLoading,
  setForgotLoading,
  setResetLoading,
  setContactUs,
} = authSlice.actions;
export default authSlice.reducer;
