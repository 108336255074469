import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  row: {
    margin: 5,
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "20px",
    // justifyContent: "space-between",
  },

  radioItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 30,
    background: "rgba(242, 242, 242, 1)",
  },
  radioItemSelected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 30,
    background: "rgba(255, 186, 69, 0.35)",
  },
  radioImage: {
    height: 18,
  },

  // radio button group
  radioStartButton: {
    border: "none",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: 20,
    background: "rgba(242, 242, 242, 1)",
  },
  radioEndButton: {
    border: "none",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    background: "rgba(242, 242, 242, 1)",
  },
  radioEndButtonSelected: {
    border: "none",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    background: "rgba(255, 186, 69, 0.35)",
  },
  radioStartButtonSelected: {
    border: "none",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: 20,
    background: "rgba(255, 186, 69, 0.35)",
  },

  // folder item
  folderItem: {
    width: 200,
    margin: 10,
    padding: 10,
    borderRadius: 7,
    position: "relative",
    background: "rgba(242, 242, 242, 1)",

    // minHeight: 210,
  },



  disabledItem: {
    position: "relative",
    "&::after": {
      //content: '" "',
      position: "absolute",
      //cursor:'none',
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      //background: "rgba(0, 0, 0, 0.1)",
    },
  },

  verticalIconContainer: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  folderDateText: {
    color: "rgba(151, 151, 151, 1)",
    marginTop: "3px",
  },
  expiryDateText: {
    color: "red",
  },
  folderMenuIcon: {
    width: 20,
  },
  folderBig: {
    width: 100,
  },
  fileImageRow: { width: 20, margin: 5 },
  actionsImageRow: { width: 20, cursor: "pointer" },

  filePreview: {
    marginTop: 5,
    background: "#ffffff",
    padding: 10,
    borderRadius: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  // upload documents modal
  uploadModal: {
    "& .mantine-Modal-body": {
      padding: 0,
    },
  },
  uploadHeader: {
    background: "rgba(255, 186, 69, 0.85)",
    padding: 10,
  },
  dropzone: {
    "& .mantine-Dropzone-root": {
      background: "rgba(255, 186, 69, 0.1)",
    },
    width: "80%",
    marginBottom: 20,
  },
  modalHeaderImage: { width: 25 },
  dragImage: {
    alignSelf: "center",
    width: "35%",
  },
  browseText: {
    color: "rgba(107, 156, 255, 1)",
  },
  fileItem: {
    width: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 2,
    background: "rgba(255, 186, 69, 0.25)",
    padding: "13px 5px",
    margin: "5px",
  },
  datePicker: {
    minWidth: 200,
    "& .mantine-InputWrapper-label": {
      marginLeft: 10,
    },
    "& .mantine-DatePickerInput-input": {
      borderRadius: 0,
      border: "1px solid rgba(242, 242, 242, 1)",
      borderBottom: "2px solid rgba(255, 186, 69, 1)",
      background: "rgba(242, 242, 242, 1)",
    },
  },

  // search bar
}));

export default useStyles;
