import { ActionIcon, Group, Switch, createStyles } from "@mantine/core";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import swal from "sweetalert";
import FacilityModal from "../../containers/FacilityModal";
import { editFacility, removeFacility } from "../../services";

const FacilityTableRow = ({ row }) => {
  const [active, setActive] = useState(row.active);
  const [opened, { open, close }] = useDisclosure(false);
  const [view, setView] = useState(false);

  // functions
  const remove = async (event) => {
    event.stopPropagation();
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone. Facility are further associated to Client, Deleting facility can cause problems.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });
    if (willDelete) removeFacility(row);
  };
  const updateStatus = async (event) => {
    event.stopPropagation();
    setActive(event.target.checked);
    editFacility({ id: row?.id, active: event?.target?.checked }, false);
  };
  function handleRowClick(event) {
    setView(true);
    open();
  }

  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={handleRowClick}>{row?.name || "-"}</td>
      <td onClick={handleRowClick}>{row.type?.name || "-"}</td>
      <td onClick={handleRowClick}>{row?.address || "-"}</td>
      <td className="exclude-modal-trigger">
        <Switch
          size="md"
          color="yellow"
          checked={active}
          onChange={updateStatus}
         
        />
      </td>
      <td className="exclude-modal-trigger">
        <Group>
          <FacilityModal
            opened={opened}
            close={close}
            title={"Edit Facility"}
            data={row}
            view={view}
            setView={setView}
          />
          <ActionIcon
            color="yellow"
            variant="light"
            onClick={(event) => {
              event.stopPropagation();
              setView(true);
              open();
            }}
          >
            <IconEye />
          </ActionIcon>
          <ActionIcon
            color="green"
            variant="light"
            onClick={(event) => {
              event.stopPropagation();
              open(event);
            }}
          >
            <IconEdit />
          </ActionIcon>
          <ActionIcon color="red" variant="light" onClick={remove}>
            <IconTrash />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  );
};

export default FacilityTableRow;
