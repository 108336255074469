import React from "react";

export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path fill="url(#pattern0_1_650)" d="M0 0H48V48H0z"></path>
      <defs>
        <pattern
          id="pattern0_1_650"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02083)" xlinkHref="#image0_1_650"></use>
        </pattern>
        <image
          id="image0_1_650"
          width="48"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVR4nO2Zu0rEQBSGPwNeKi9YiXYWi/oCFsI+g2svVuJuZWe7PoKVVrZ2WmijhSIuaLuCYC1qYeGlWi00EjgDQbwlmcxlmQ+mSWbOnH9OMvlnFwKBQFdyAsSOteMsAi4dSDj+0tpZBMwC78AbMI09KsCr5DKXdfCWKD8HIswTAWeSw2aeAIPArQRYwTwNmfseGM4bpCZBnoFxzHIjcyc5FGJPAu1iliVgVUegMeBJRMzjKQ0RcAcMpa6rl0x32yljR2h9syOUJeAI1/bkjN+eGUpiXVboGhjQGLcPuJLYTY1xjU3ULGlhjNiMCtAp8dEs1WZERe1CXnTZjLoOu5CXBZn8BZgo+IGsYQllM/ZzjE2sSTL2AIvktRm1gtWzanvT708dB8i6k6gd7MLSQamQzTBiF4rajN8O323pk/R1jv6Um/wJdT/p6yT/FeAscRBgmVAB24QK2CZUwDZdU4HqH/edpSUJfgDbwKhvAnqBNfmZJEn0EVgGenwRoJgEDlMJnwJTPglAVn0ReJCkO74JUIwAG3IS81KAoionskz/9wZwnE8zvB2Njrs3AQAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
  );
};
