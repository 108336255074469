import { Box, Button, createStyles } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomPagination from "../../../../components/Pagination/CustomPagination";

// import "react-multi-carousel/lib/styles.css";

import { getMar } from "../../../../services/mar";
import MarLogTable from "./MarLogTable";
import UserDetail from "../MarTab/UserDetail";
import HideClient from "../ClientHide";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ClientColumn from "../ClientColumn";
import { decrypt } from "../../../../utils";

import { CSVLink, CSVDownload } from "react-csv";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // overflowX: "auto",
    marginBottom: "50px",
    height: "100%",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "& button": {
      border: "none",
      padding: "5px 30px",
      height: "50px",
      color: "black",
    },
    "& img": {
      width: "30px",
    },
  },
  mainHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBody: {
    width: "100%",
    display: "flex",
    gap: "20px",
    // marginTop: "30px",
  },
  scheduleDetailRight: {
    width: "calc(100% - 270px)",
  },
  scheduleDetailRight100: {
    width: "calc(100% - 70px)",
  },
  userDetail: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "40px",
    "& .detail_box": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& .image_box": {
        width: "80px",
        height: "80px",
        border: "1px solid rgb(230,230,230)",
        borderRadius: "10px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& h3": {
        margin: "0",
        fontSize: "30px",
        textAlign: "center",
      },
      "& p": {
        margin: "0",
        color: "#979797",
        textAlign: "center",
      },
    },
  },
}));

const MarLogTab = ({ refresh, userId }) => {
  const { classes } = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const { count } = useSelector((state) => state.mar);
  const [show, setShow] = useState(true);
  const { selectedClient, filterDates, allData, logType } = useSelector(
    (state) => state.mar
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // if (refresh) getMarLog();
    getMarLog();
  }, [currentPage, refresh, selectedClient, logType]);

  const getMarLog = async () => {
    await getMar(currentPage, null, true, filterDates, logType);
  };

  // console.log("userId =>", userId);

  return (
    <div className={classes.content}>
      {/* <div>
        <div style={{ height: "50px" }} />
        <div className={classes.userDetail}>
          <div className="detail_box">
            <div
              className="image_box"
              style={{
                backgroundImage: `url(${decrypt(selectedClient?.imageurl)})`,
              }}
            />
            <div>
              <h3>{selectedClient?.name}</h3>
              <p>{selectedClient?.id?.slice(0, 6)?.toUpperCase()}</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <HideClient show={show} setShow={setShow} isHeading={true} /> */}
      {!userId ? (
        <HideClient show={show} setShow={setShow} />
      ) : (
        <Box m={20}></Box>
      )}

      <div className={classes.contentBody}>
        {show ? (
          <>
            {!userId && <UserDetail isClient={false} />}
            {/* {userId && <UserDetail isClient={true} />} */}
          </>
        ) : (
          <ClientColumn />
        )}
        <div
          style={{ width: userId ? "100%" : "calc(100% - 270px)" }}

          // className={
          //   (userId && !userId) || !show
          //     ? classes.scheduleDetailRight100
          //     : classes.scheduleDetailRight
          // }
        >
          <MarLogTable />
          <CustomPagination
            data={count}
            itemsPerPage={10}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
      <div className={classes.btnContainer}>
        <CSVLink data={allData} filename={"Medication MarLog.csv"}>
          <Button
            leftIcon={<img src={"/assets/images/downloadFolder.png"} />}
            color="yellow"
          >
            Download MAR Log
          </Button>
        </CSVLink>
      </div>
    </div>
  );
};

export default MarLogTab;
