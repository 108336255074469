import React from "react";

export const OneIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="#FFBA45"
        d="M51.136 28.208h5.696V70H49.28V37.232c-1.13.512-2.24.907-3.328 1.184a13.17 13.17 0 01-3.264.416V31.6c1.45-.15 2.859-.48 4.224-.992 1.365-.533 2.773-1.333 4.224-2.4z"
      ></path>
      <rect
        width="99"
        height="99"
        x="0.5"
        y="0.5"
        stroke="#FFBA45"
        rx="49.5"
      ></rect>
    </svg>
  );
};
