import React from "react";
import {
  Modal,
  Button,
  Select,
  Box,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { useEffect, useState } from "react";
import CloseCircle from "../CloseCircle";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { getPlanTypes } from "../../services/supportplan";
import { SlCheck } from "react-icons/sl";

const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;

const useStyles = createStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4ead8",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "80px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
    },
  },
  modal: {
    width: "100%",
    overflow: "auto",
    borderRadius: "1rem 1rem 1rem 1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  text: {
    fontFmily: "Inter",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    fontWeight: "400",
    lineHeight: "25px",
  },
  viewModalContent: {
    margin: "1rem",
    padding: "1rem",
  },
  facilitiesSelect: {
    border: "1px solid #D9D9D9",
    padding: "0 .5rem",
    cursor: "pointer",
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    margin: "0 auto",
    marginTop: 20,
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "1rem",
    textAlign: "center",
  },
}));

const SelectCarePlanTypeModal = ({
  open,
  close,
  onSelect,
  openAddMedicalModal,
}) => {
  const { classes } = useStyles();
  const [selected, setSelected] = useState("");
  const [types, setTypes] = useState([
    // {
    //   _id: "661510986a6068af0f4e06e6",
    //   name: "Individual Support Plan (ISP)"
    // }
  ]);
  const [error, setError] = useState("");

  const getData = async () => {
    let data = await getPlanTypes();
    setTypes(data);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSelect = () => {
    if (!selected) {
      setError("Please select a care plan type.");
    } else {
      setError("");
      onSelect(selected);
    }
  };

  // const handleClose = () => {
  //   close()
  //   setError("");
  //   openAddMedicalModal();
  //   setSelected("");
  // };

  const handleClose = () => {
    close();
    setError((prevError) => (prevError !== "" ? "" : prevError));
    setSelected((prevSelected) =>
      prevSelected !== null ? null : prevSelected
    );
    openAddMedicalModal();
  };

  return (
    <>
      <Box style={{ overflowX: "auto" }}>
        <Box style={{ minWidth: "1000px" }}>
          <Modal
            centered
            size="lg"
            shadow="md"
            radius="lg"
            opened={open}
            zIndex={1000}
            onClose={handleClose}
            className={classes.modal}
            overlayProps={{ blur: 3 }}
          >
            <CloseCircle onclose={handleClose} />
            <Box className={classes.header}>
              <Text
                fw={600}
                size={24}
                ff={"Inter"}
                c={"#167207"}
                variant="text"
              >
                Client Saved
              </Text>
              <SlCheck color="#167207" width={20} height={20} />
            </Box>
            <Text size={"md"} c={"#263238"} className={classes.text}>
              Do you want to add care plan?
            </Text>
            <Box className={classes.viewModalContent}>
              <Select
                size="md"
                searchable
                value={selected}
                autoComplete="off"
                variant="unstyled"
                rightSection={icon}
                placeholder="Select"
                label="Care Plan Type"
                id="individual-facility-name"
                rightSectionPointerEvents="none"
                className={classes.facilitiesSelect}
                onChange={(t) => {
                  setSelected(t);
                  setError("");
                }}
                data={types?.map((type) => ({
                  value: type?.id,
                  label: type?.name,
                }))}
              />
              {error && <Text className={classes.errorText}>{error}</Text>}
              <Box className={classes.buttonBox}>
                <Button
                  id="add-later-plan"
                  radius="md"
                  onClick={handleClose}
                  variant="outline"
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                      borderColor: "#FFBA45",
                    },
                  })}
                >
                  No, Add Later
                </Button>
                <Button
                  radius="md"
                  color="yellow"
                  onClick={handleSelect}
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                    },
                  })}
                >
                  Yes, Add Care Plan
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default SelectCarePlanTypeModal;
