import React from "react";
import { Box, ScrollArea, Table, Text, createStyles } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import PlanTableRow from "../PlanTableRow";
import RecordNotFound from "../RecordNotFound";
import TableLoader from "../TableLoader";
import { useDisclosure } from "@mantine/hooks";
import ViewCarePlanModal from "../SupportModal/ViewCarePlanModal";
import SupportModal from "../SupportModal";
import CarePlanUpdate from "../MAR/CarePlanUpdate";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFound: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "75vh",
    gap: "8rem",
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const PlanTable = ({
  setRowData,
  setShowCarePlanDetails,
  segment,
  client,
  currentPage
}) => {
  // console.log("🚀 ~ segment inside the plantable:", segment)
  const { classes, cx } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const { plans, loading } = useSelector((state) => state.supportPlan);
  const [scrolled, setScrolled] = useState(false);
  const [row, setRow] = useState(null);
  const [view, setView] = useState(false);
  // console.log("🚀 ~ view:", view)

  const rows = plans?.map((row, i) => (
    <PlanTableRow
      segment={segment}
      client={client}
      currentPage={currentPage}
      row={row}
      open={(data) => {
        console.log("🚀 ~ data:", data)
        setRow(data);
        setRowData(data);
        setShowCarePlanDetails(true);
        // open();
      }}
      openView={(data) => {
        setRow(data);
        setRowData(data);
        open();
      }}
      key={i}
      setView={setView}
    />
  ));
  return (
    <>
      <ScrollArea
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        sx={{ marginTop: "30px" }}
      >
        {/* <SupportModal
          opened={opened}
          close={close}
          title={"Edit Support Plan"}
          data={row}
          view={view}
        /> */}
        <ViewCarePlanModal
          data={row}
          view={open}
          onclose={close}
          opened={opened}
          setRowData={setRowData}
          setShowCarePlanDetails={setShowCarePlanDetails}
        />
        <Table
          withBorder
          fontSize={"sm"}
          highlightOnHover
          withColumnBorders
          enableStickyHeader
          verticalSpacing={"md"}
          sx={{ fontFamily: "Inter" }}
        >
          <thead
            // style={
            //   segment === "Active"
            //     ? { background: "#000", color: "#fff !important" }
            //     : { background: "#F2F2F2", color: "#000 !important" }
            // }
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            {segment === "Active" || segment === "active" || segment === "draft" ? (
              <tr>
                <th style={{ minWidth: "200px" }}>Plan Type</th>
                <th style={{ minWidth: "200px" }}>Client Name</th>
                <th style={{ minWidth: "140px" }}>Facility Name</th>
                <th style={{ minWidth: "140px" }}>Outcomes</th>
                <th style={{ minWidth: "140px" }}>Action</th>
              </tr>
            ) : (
              <tr>
                <th style={{ minWidth: "200px" }}>Plan Type</th>
                <th style={{ minWidth: "200px" }}>Plan Start Date</th>
                <th style={{ minWidth: "140px" }}>Discontinue Date</th>
                <th style={{ minWidth: "140px" }}>Discontinue Reason</th>
                <th style={{ minWidth: "140px" }}>Action</th>
              </tr>
            )}
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={1000}>
                  <TableLoader />
                </td>
              </tr>
            ) : rows?.length ? (
              <>{rows}</>
            ) : (
              <tr>
                <td colSpan={1000}>
                  <Box className={classes.notFound}>
                    <RecordNotFound />
                    <Text className={classes.notFoundText}>
                      You don&apos;t have any record, click on “Add Care Plan”
                    </Text>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default PlanTable;
