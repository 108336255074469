import {
  Button,
  Card,
  Container,
  Select,
  SimpleGrid,
  TextInput,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSelector } from "react-redux";
import { updateBusinessProfile } from "../../../../services/profile";
import { PhoneInput } from "react-international-phone";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 40,
    marginBottom: 40,
    borderWidth: 1,
    borderRadius: 15,
    borderStyle: "solid",
    borderColor: "#FFDEABEE",
    display: "flex",
    flexDirection: "row",
    overflow: "visible",
  },
  camera: {
    position: "absolute",
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  box: {
    width: 280,
    paddingTop: 47,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "#F2F2F2",
    textAlign: "center",
  },
  inputIcon: {
    borderRightWidth: 1,
    borderRigthColor: "#CBCBCB",
    borderRightStyle: "solid",
    paddingRight: 5,
  },
  changepwd: {
    position: "absolute",
    color: "#FFBA45",
    fontFamily: "Inter",
    fontSize: rem(14),
    marginLeft: "auto",
    textDecoration: "underline",
    paddingBottom: 10,
    marginTop: 9,
  },
  btn: {
    width: 122,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
  },
  inputContainer: {
    "@media (max-width :40em)": {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "4rem",
    },
  },
  label:{
    margin: '4px',
    width: '100%',
  },
  phoneInput: {
    "& button": {
      height: "2.625rem",
      borderColor: "f1f3f5 !important",
    },
    "& .react-international-phone-input": {
      height: "2.625rem !important",
      borderColor: "f1f3f5 !important",
      width: "100%",
      },
  },
}));
const BusinessProfile = () => {
  const { profile, saveProfileLoading } = useSelector(
    (state) => state?.profile
  );
  const { classes } = useStyles();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      businessEmail: profile?.business?.businessEmail,
      companyName: profile?.business?.companyName,
      contactPhone: profile?.business?.contactPhone,
      industry: profile?.business?.industry,
    },
    validate: {
      companyName: (value) => (value ? null : "Required"),
      businessEmail: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      contactPhone: (value) => (value ? null : "Required"),
      industry: (value) => (value ? null : "Required"),
    },
  });
  const onFinish = (values) => {
    updateBusinessProfile(values);
  };

  return (
    <>
      <form onSubmit={form?.onSubmit((values) => onFinish(values))}>
        <Card className={classes.wrapper} pb={30}>
          <Container fluid sx={{ width: "100%" }}>
            <SimpleGrid cols={3} className={classes.inputContainer}>
              <TextInput
                label={"Company Name*"}
                size="md"
                icon={
                  <img
                    src={"/assets/images/profile.png"}
                    alt=""
                    className={classes.inputIcon}
                  />
                }
                {...form.getInputProps("companyName")}
              />
              <TextInput
                label={"Email"}
                size="md"
                icon={
                  <img
                    src={"/assets/images/sms.png"}
                    alt=""
                    className={classes.inputIcon}
                  />
                }
                {...form.getInputProps("businessEmail")}
                disabled
              />
              {/* <TextInput
                label={"Phone number"}
                size="md"
                type="number"
                icon={
                  <img
                    src={"/assets/images/sms.png"}
                    alt=""
                    className={classes.inputIcon}
                  />
                }
                {...form.getInputProps("contactPhone")}
              /> */}
              <div className={classes.phoneInputBox}>
                <p className={classes.label}>Phone number</p>
                <PhoneInput
                  defaultCountry="us"
                  // value={phone}
                  // onChange={(phone) => setPhone(phone)}
                  {...form.getInputProps("contactPhone")}
                  className={classes.phoneInput}
                 
                  // disabled={view}
                />
              </div>
              <Select
                label={"Industry"}
                size="md"
                searchable
                icon={
                  <img
                    src={"/assets/images/lock.png"}
                    alt=""
                    className={classes.inputIcon}
                  />
                }
                {...form.getInputProps("industry", { type: "select" })}
                data={[
                  {
                    value: "health care",
                    label: "Health Care",
                  },
                  { value: "transportation", label: "Transportation" },
                ]}
              />
            </SimpleGrid>
          </Container>
        </Card>
        <SimpleGrid
          cols={2}
          sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
        >
          <Button
            ml={"auto"}
            color="yellow"
            variant="light"
            className={classes.btn}
            onClick={() => navigate("/home/dashboard")}
          >
            Cancel
          </Button>
          <Button
            mr={"auto"}
            type="submit"
            color="yellow"
            className={classes.btn}
            loading={saveProfileLoading}
          >
            Save
          </Button>
        </SimpleGrid>
      </form>
    </>
  );
};

export default BusinessProfile;
