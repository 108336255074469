import React, { useState } from "react";
import { Button, Menu, MultiSelect, NumberInput, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import useStyles from "./style";
import { DateInput } from "@mantine/dates";
import moment from "moment";

const ScheduleMenu = ({ form, isPreview, data }) => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    form.getInputProps("repeatOn")?.value || [moment().format("dddd")]
  );
  // console.log("🚀 ~ ScheduleMenu ~ selectedDays:", selectedDays);

  const reset = () => {
    setOpened(false);
    form.setFieldValue("scheduleType", "daily");
    form.setFieldValue("timeSlots", undefined);
    form.setFieldValue("repeatType", "day");
    setSelectedDays([]);
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const toggleDay = (day) => {
    const index = selectedDays.indexOf(day);
    if (index === -1) {
      setSelectedDays([...selectedDays, day]);
      form.setFieldValue("repeatOn", [...selectedDays, day]);
    } else {
      if (selectedDays?.length > 1) {
        const updatedDays = [...selectedDays];
        updatedDays.splice(index, 1);
        setSelectedDays(updatedDays);
        form.setFieldValue("repeatOn", updatedDays);
      }
    }
  };

  return (
    <Menu
      shadow="md"
      width={320}
      withArrow
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        {
          data ? (
            <p
              style={{
                marginTop: "4px",
                fontSize: "14px",
                color: "#979797",
                cursor: "pointer",
              }}
            >
              {isPreview ? "View" : "Edit"}
            </p>
          ) : (
            <p
              className="add-time"
              style={{
                marginTop: "4px",
                fontSize: "14px",
                color: "#979797",
                cursor: "pointer",
              }}
            >
              Add
            </p>
          )
          // <p className={classes.scheduleMenuBtnText}>
          //     Click to Set Schedule
          // </p>
        }
      </Menu.Target>

      <Menu.Dropdown>
        <div className={classes.scheduleMenuBox}>
          <h2>Set Administration Schedule</h2>
          {/* SCHEDULE TYPE */}
          <Select
            data={[
              { value: "daily", label: "Daily" },
              { value: "custom", label: "Custom" },
            ]}
            defaultValue={"daily"}
            size="sm"
            className={classes.forLabel}
            placeholder="Select Schedule Type"
            rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            disabled={isPreview}
            style={{ width: "200px" }}
            {...form.getInputProps("scheduleType")}
            onChange={(value) => {
              form.setFieldValue("scheduleType", value);
              if (value === "daily") {
                form.setFieldValue("repeatEvery", 1);
                form.setFieldValue("repeatType", "day");
              }
            }}
          />

          {form.getInputProps("scheduleType")?.value === "custom" && (
            <>
              {/* REPEAT EVERY */}
              <div className={classes.repeatEveryBox}>
                <p>Repeat every</p>
                <NumberInput
                  variant="filled"
                  radius="xs"
                  min={1}
                  defaultValue={1}
                  {...form.getInputProps("repeatEvery")}
                  style={{ width: "65px" }}
                />
                <Select
                  data={[
                    { value: "day", label: "Day" },
                    { value: "week", label: "Week" },
                    { value: "month", label: "Month" },
                  ]}
                  defaultValue={"day"}
                  size="sm"
                  className={classes.forLabel}
                  placeholder="Day(s)"
                  rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  disabled={isPreview}
                  style={{ width: "100px" }}
                  {...form.getInputProps("repeatType")}
                  onChange={(value) => {
                    form.setFieldValue("repeatType", value);
                    if (value !== "week") {
                      form.setFieldValue("repeatOn", undefined);
                      setSelectedDays([]);
                    }
                  }}
                />
              </div>

              {/* REPEAT ON FOR WEEK */}
              {form.getInputProps("repeatType")?.value === "week" && (
                <div className={classes.repeatOn}>
                  <p>Repeat on</p>
                  <div className="dayCircleBox">
                    {daysOfWeek.map((day) => (
                      <div
                        key={day}
                        className={`dayCircle ${
                          selectedDays.includes(day)
                            ? "dayCircleActive"
                            : "dayCircleInactive"
                        }`}
                        onClick={() => (!isPreview ? toggleDay(day) : () => {})}
                      >
                        <p>{day.charAt(0).toUpperCase()}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* REPEAT ON FOR MONTH */}
              {form.getInputProps("repeatType")?.value === "month" && (
                <div className={classes.repeatOn}>
                  <p>Repeat on</p>
                  <Select
                    data={[]}
                    size="sm"
                    className={classes.forLabel}
                    placeholder={`Monthly on the ${
                      form.getInputProps("medicationDate")?.value?.getDate() ||
                      new Date()?.getDate()
                    }`}
                    rightSection={
                      <IconChevronDown color={"#FBAA0D"} size={25} />
                    }
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    disabled={true}
                    style={{ width: "200px" }}
                  />
                </div>
              )}
            </>
          )}

          {/* TIME SLOTS */}
          <MultiSelect
            id="administering-time"
            disabled={isPreview}
            withAsterisk
            label="Administering Time(s)"
            size="sm"
            data={[
              "12:00 AM",
              "12:30 AM",
              "01:00 AM",
              "01:30 AM",
              "02:00 AM",
              "02:30 AM",
              "03:00 AM",
              "03:30 AM",
              "04:00 AM",
              "04:30 AM",
              "05:00 AM",
              "05:30 AM",
              "06:00 AM",
              "06:30 AM",
              "07:00 AM",
              "07:30 AM",
              "08:00 AM",
              "08:30 AM",
              "09:00 AM",
              "09:30 AM",
              "10:00 AM",
              "10:30 AM",
              "11:00 AM",
              "11:30 AM",
              "12:00 PM",
              "12:30 PM",
              "01:00 PM",
              "01:30 PM",
              "02:00 PM",
              "02:30 PM",
              "03:00 PM",
              "03:30 PM",
              "04:00 PM",
              "04:30 PM",
              "05:00 PM",
              "05:30 PM",
              "06:00 PM",
              "06:30 PM",
              "07:00 PM",
              "07:30 PM",
              "08:00 PM",
              "08:30 PM",
              "09:00 PM",
              "09:30 PM",
              "10:00 PM",
              "10:30 PM",
              "11:00 PM",
              "11:30 PM",
            ]}
            rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
            rightSectionWidth={30}
            style={{ cursor: "pointer" }}
            styles={{
              rightSection: {
                pointerEvents: "none",
              },
            }}
            placeholder="Select Time(s)"
            searchable
            {...form.getInputProps("timeSlots")}
          />

          {/* MEDICATION START DATE */}
          <DateInput
            size="sm"
            placeholder={"mm/dd/yyyy"}
            label="Medication Start Date"
            rightSection={
              <img
                style={{ width: "30px" }}
                src={"/assets/images/calendar-icon.png"}
                alt="Icon"
              />
            }
            {...form.getInputProps("medicationDate")}
            withAsterisk
            style={{ cursor: "pointer" }}
            disabled={isPreview || data}
            styles={{ rightSection: { pointerEvents: "none" } }}
          />

          {/* FOOTER ACTIONS */}
          <div className="footer">
            <Button onClick={() => (!isPreview ? reset() : setOpened(false))}>
              Reset
            </Button>
            {/* {!isPreview && (
              <Button onClick={() => setOpened(false)}>Done</Button>
            )} */}
          </div>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ScheduleMenu;
