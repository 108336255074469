import {
  Button,
  Divider,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

function Form4({
  classes,
  view,
  state,
  setState,
  onSubmit,
  active,
  setActive,
  addPatientLoading
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const initialOutcome = {
    outcomeCategory: "",
    outcomeTitle: "",
    customOutcome: "",
    outcomeNumber: "",
    outcomeDescription: "",
    additionalInformation: ""
  };
  const [outcome, setOutcome] = useState([{ id: 1, ...initialOutcome }]);
  const [showCustomOutcome, setShowCustomOutcome] = useState({});

  // components
  const NoRecord = () => {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img
          src="/assets/images/building.png"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "contain"
          }}
        />
        <Text style={{ opacity: 0.3 }} mt={10}>
          No Outcomes Found
        </Text>
      </div>
    );
  };

  // functions
  const onSave = () => {
    setState((p) => ({ ...p, form4: outcome }));
    onSubmit(outcome);
  };

  const handleOutcomeChange = (outcomeId, field, value) => {
    setOutcome((prevOutcome) =>
      prevOutcome.map((outcome) =>
        outcome.id === outcomeId ? { ...outcome, [field]: value } : outcome
      )
    );
  };
  const addOutcome = () => {
    setOutcome([...outcome, { id: Date.now(), ...initialOutcome }]);
  };
  const removeOutcome = (id) => {
    setOutcome((p) => p?.filter((v) => v?.id != id));
  };

  useEffect(() => {
    if (active === 3) {
      let object = {};
      if (state?.form4) {
        let form = Array.isArray(state?.form4) ? state?.form4 : [];
        setOutcome(form);
        form?.forEach((value) => {
          object[value?.id] = value?.outcomeCategory === "Other";
        });
      }
    }
  }, [active]);

  return (
    <form>
      <Text variant="text" style={{ fontWeight: "600" }}>
        Support Plan
      </Text>

      {/* <Divider my="sm" variant="dashed" /> */}
     
      {outcome?.length > 0 ? (
        outcome?.map((out, index) => {
          return (
            <div key={out.id}>
              {!view && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px"
                  }}
                >
                  <span
                    onClick={() => removeOutcome(out?.id)}
                    style={{
                      color: "#fab005",
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                  >
                    Delete
                  </span>
                </div>
              )}
              <TextInput
                className={classes.myfont}
                style={{ marginTop: "15px" }}
                label="Outcome Number"
                size="sm"
                disabled
                value={index + 1}
              />
              <SimpleGrid
                cols={isMobileScreen ? 1 : 2}
                className={classes.mwd}
                sx={{
                  paddingBottom: 30
                }}
              >
                <TextInput
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Outcome Title"
                  size="sm"
                  placeholder="Enter outcome title"
                  disabled={view}
                  value={out?.outcomeTitle}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "outcomeTitle",
                      e?.target?.value
                    );
                  }}
                />
                <Select
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Outcome Category"
                  size="sm"
                  data={[
                    "Nutrition",
                    "Physical Health",
                    "Hygiene",
                    "Character Development",
                    "Independence in daily living",
                    "Socialize",
                    "Communication",
                    "Diet",
                    "Other"
                  ]}
                  placeholder="Select outcome category"
                  value={out?.outcomeCategory}
                  onChange={(value) => {
                    handleOutcomeChange(out?.id, "outcomeCategory", value);
                    setShowCustomOutcome((p) => ({
                      ...p,
                      [out?.id]: value === "Other"
                    }));
                  }}
                  disabled={view}
                />

                {showCustomOutcome[out?.id] && (
                  <Textarea
                    autosize={view}
                    className={classes.myfont}
                    style={{ marginTop: "15px" }}
                    label="Add Custom Outcome"
                    size="sm"
                    placeholder="Enter outcome"
                    disabled={view}
                    value={out?.customOutcome}
                    onChange={(e) => {
                      handleOutcomeChange(
                        out?.id,
                        "customOutcome",
                        e?.target?.value
                      );
                    }}
                  />
                )}

                <Textarea
                  autosize={view}
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Outcome Description"
                  size="sm"
                  placeholder="Enter outcome description"
                  disabled={view}
                  value={out?.outcomeDescription}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "outcomeDescription",
                      e?.target?.value
                    );
                  }}
                />

                <Textarea
                  autosize={view}
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Additional Note"
                  size="sm"
                  placeholder="Enter Additional Note"
                  disabled={view}
                  value={out?.additionalInformation}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "additionalInformation",
                      e?.target?.value
                    );
                  }}
                />
              </SimpleGrid>
              <Divider my="sm" variant="dashed" />
            </div>
          );
        })
      ) : (
        <NoRecord />
      )}
       {!view && (
        <Button
          size="sm"
          color="yellow"
          onClick={addOutcome}
          style={{ marginLeft: "auto", display: "block" }}
        >
          Add New Outcome
        </Button>
      )}

      {!view && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <Button
            size="sm"
            variant="outline"
            color="yellow"
            onClick={() => setActive(2)}
            disabled={view}
            style={{ marginTop: 20 }}
          >
            Back
          </Button>
          <Button
            loading={addPatientLoading}
            size="sm"
            color="yellow"
            onClick={onSave}
            disabled={view}
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </div>
      )}
    </form>
  );
}

export default Form4;
