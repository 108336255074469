import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import TicketTable from "../../../components/TicketTable";
import { Box, Container } from "@mantine/core";
import AddButton from "../../../components/AddButton";
import { useEffect } from "react";
import { pageInfoAnalytics } from "../../../config/mixpanelConfig";

const MyTicket = ({ activeTab }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    pageInfoAnalytics("My Ticket Page Viewed", "My Ticket Page");
  }, []);
  return (
    <Container fluid className={classes.content} style={{ width: "100%" }}>
      {/* <Topbar title={"Tickets"} /> */}
      <Box style={{ padding: "30px 0 0 0" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div className={classes.btn}>
            <AddButton
              title={"Create Ticket"}
              onClick={() => navigate("/home/create-ticket")}
            />
          </div>
        </Box>
        <TicketTable activeTab={activeTab} />
      </Box>

      {/* <div className={classes.ContactUs} style={{ width: "100%" }}>
        <Box >
          <h2 className={classes.contactHeading}>My Tickets</h2>
        </Box>
        <Box >
          <TicketTable />
        </Box>

        <Container fluid sx={{ backgroundColor: "#FAFAFA" }}>
          <Box className={classes.footer_wrapper}>
            <Box className={classes.footer_left}>
              <Box>
                <img
                  onClick={handleClick}
                  width={"20%"}
                  src="/assets/images/logo2.png"
                  alt=""
                />
              </Box>
              <Text className={classes.footer_text} style={{ width: "90%" }}>
                Join the ranks of leading healthcare facilities that have
                already embraced the future of medical note-taking. Start your
                journey towards effortless documentation, precise billing, and
                superior service maintenance. Your patients and staff deserve
                nothing less.
              </Text>
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: "30px" }}
                mt={25}
              >
                <ActionIcon>
                  <img src="/assets/images/twitter2.png" alt="" />
                </ActionIcon>
                <ActionIcon>
                  <img src="/assets/images/facebook2.png" alt="" />
                </ActionIcon>
                <ActionIcon>
                  <img src="/assets/images/instagram2.png" alt="" />
                </ActionIcon>
              </Box>
            </Box>

            <Box className={classes.footer_center} style={{ width: "250px" }}>
              <Text ff={"Inter"} fw={500} fz={26}>
                Pages
              </Text>

              <Text
                className={classes.text3}
                onClick={() => {
                  navigate("/contact-us");
                  // Scroll to the top of the page
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </Text>
              <Text
                className={classes.text3}
                onClick={() => {
                  navigate("/term-conditions");
                  // Scroll to the top of the page
                  window.scrollTo(0, 0);
                }}
              >
                Terms and Conditions
              </Text>
              <Text
                className={classes.text3}
                onClick={() => {
                  navigate("/privacy-policy");
                  // Scroll to the top of the page
                  window.scrollTo(0, 0);
                }}
              >
                Privacy Policy
              </Text>
              <Text
                className={classes.text3}
                onClick={() => {
                  navigate("/cookies-policy");
                  // Scroll to the top of the page
                  window.scrollTo(0, 0);
                }}
              >
                Cookie Policy
              </Text>
            </Box>

          </Box>
        </Container>

        <Footer />
      </div> */}
    </Container>
  );
};

export default MyTicket;
