import {
  Container,
  Group,
  Loader,
  SimpleGrid,
  Text,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPackages } from "../../../../services/package";

import PlanCard from "../../../../components/PlanCard";
import { dateFormat2 } from "../../../../utils";
const useStyles = createStyles((theme) => ({
  "& .mantine-usvkjp": {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  wrapperContainer: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "@media (max-width :80em)": {
      margin: "1rem 0!important",
    },
  },
  inputContainer: {
    "@media (max-width :80em)": {
      display: "flex !important",
      flexDirection: "column !important",
      paddingBottom: "4rem !important",
    },
  },
}));
const Upgrade = () => {
  const { classes } = useStyles();
  const { profile } = useSelector((state) => state.profile);
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);

  const packageFn = async () => {
    setLoadingPackages(true);
    const resp = await getPackages(profile?.user?.id);
    if (resp) {
      let tempPackages = resp?.packages;

      const sortedByCost = tempPackages.sort((a, b) => {
        return Number(a.cost) - Number(b.cost);
      });

      setLoadingPackages(false);
      setPackages(sortedByCost);
    } else {
      loadingPackages(false);
    }
  };

  useEffect(() => {
    packageFn();
  }, []);

  useEffect(() => {
    // console.log("Profile changed");
  }, [profile]);

  return (
    <Container
      fluid
      sx={{ width: "100%" }}
      mt={50}
      className={classes.wrapperContainer}
    >
      <SimpleGrid cols={3} spacing={30} className={classes.inputContainer}>
        {loadingPackages ? (
          <Group cols={2}>
            <Loader color="yellow" size={"md"} />
            <Text>Loading, Please Wait...</Text>
          </Group>
        ) : (
          packages?.map((item) => {
            return Number(item?.cost) === 0 ? (
              // profile?.isEligibleForTrial ? (
              <PlanCard
                activeId={profile?.subscription?.packageId}
                packageId={item?.id}
                title={item?.name}
                plan={item}
                price={item?.cost}
                level={item?.level}
                duration={item?.duration}
                stripePlanId={item?.stripeId}
                desc={`${item?.staff} Staff Members, ${item?.facility} Facility, ${item?.patient} Patients & ${item?.dailyUpdates} Daily Updates`}
                btn={
                  Number(item?.level) >
                  Number(profile?.subscription?.packageId?.level)
                    ? "Upgrade Plan"
                    : "Select Plan"
                }
                billing={`${dateFormat2(profile?.subscription?.startedAt)} -
            ${dateFormat2(profile?.subscription?.expiredAt)}`}
              />
            ) : (
              // ) : null
              <PlanCard
                // allPackages={packages?.packages}
                allPackages={packages}
                activeId={profile?.subscription?.packageId}
                packageId={item?.id}
                plan={item}
                title={item?.name}
                price={item?.cost}
                duration={item?.duration}
                level={item?.level}
                stripePlanId={item?.stripeId}
                btn={
                  Number(item?.level) >
                  Number(profile?.subscription?.packageId?.level)
                    ? "Upgrade Plan"
                    : "Select Plan"
                }
                billing={`${dateFormat2(profile?.subscription?.startedAt)} -
${dateFormat2(profile?.subscription?.expiredAt)}`}
              />
            );
          })
        )}
      </SimpleGrid>
    </Container>
  );
};

export default Upgrade;
