import React from "react";

export const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#FFBA45"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M15.582 18.79H6.415c-2.75 0-4.583-1.375-4.583-4.583V7.79c0-3.208 1.833-4.583 4.583-4.583h9.167c2.75 0 4.583 1.375 4.583 4.583v6.417c0 3.208-1.833 4.583-4.583 4.583z"
      ></path>
      <path
        stroke="#FFBA45"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M15.585 8.25l-2.87 2.292c-.944.751-2.493.751-3.437 0L6.418 8.25"
      ></path>
    </svg>
  );
};
