import React from "react";
import { Box, Collapse, Group, rem } from "@mantine/core";
import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight
} from "@tabler/icons-react";

import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscriptionExpired } from "../../utils";
import useStyles from "./style";

export function LinksGroup({
  label,
  initiallyOpened,
  links,
  to,
  imgsrc,
  onClick,
  setIsOpen
}) {
  const { classes, theme, cx } = useStyles();
  const hasLinks = Array.isArray(links);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  const items = (hasLinks ? links : []).map((link, i) => (
    <NavLink
      className={({ isActive, isPending }) =>
        isActive ? cx(classes.link, classes.linkActive) : classes.link
      }
      attr={link.attr && link.attr}
      to={link.to}
      key={i}
      onClick={(event) => {
        event.preventDefault();
        navigate(link.to);
      }}
    >
      {link.label}
    </NavLink>
  ));

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          isActive ? cx(classes.link, classes.linkActive) : classes.link
        }
        to={to}
        key={label}
        onClick={(e) => {
          e.preventDefault();
          if (!isSubscriptionExpired()) {
            navigate(to);
            setOpened((o) => !o);
            setIsOpen(false);
            if (hasLinks) onClick?.();
          }
        }}
        style={{
          background: isSubscriptionExpired() ? "#ebebeb" : ""
        }}
      >
        <Group spacing={"sm"} sx={{ width: "100%" }}>
          <img width={30} src={imgsrc} className={classes.linkIcon} alt="" />
          <div className={classes.row}>
            <span style={{ width: "max-content" }}>{label}</span>
            {hasLinks && (
              <ChevronIcon
                className={classes.chevron}
                size="1.5rem"
                stroke={1.5}
                style={{
                  transform: opened
                    ? `rotate(${theme.dir === "rtl" ? 90 : -90}deg)`
                    : "none"
                }}
              />
            )}
          </div>
        </Group>
      </NavLink>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

const mockdata = {
  label: "Releases",
  icon: IconCalendarStats,
  links: [
    { label: "Upcoming releases", link: "/" },
    { label: "Previous releases", link: "/" },
    { label: "Releases schedule", link: "/" }
  ]
};

export function NavbarLinksGroup() {
  return (
    <Box
      sx={(theme) => ({
        minHeight: rem(220),
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white
      })}
    >
      <LinksGroup {...mockdata} />
    </Box>
  );
}
