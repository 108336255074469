import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
    TCandPP: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '2rem 4rem'
    },
    mainHeading: {
        textDecoration: 'uppercase',
        fontWeight: 'normal',
        textAlign: 'left',

    },
    subHeading: {
        textCombineUpright: 'uppercase'
    },
    text: {
        textAlign: 'left',
            color: '#737373'
    },
    linkStyle:{
        textDecoration: 'none',
    },
    logo_img2:{
        cursor: 'pointer',
        width: '10%',
        "@media (max-width :600px)": {
            width: '30%'
          },
    }
}));

export default useStyles;
