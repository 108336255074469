import {
  Checkbox,
  Divider,
  Input,
  Select,
  Box,
  Button,
  rem,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// import { PhoneInput } from "react-international-phone";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { useSelector } from "react-redux";

const InsuranceForm = forwardRef(
  (
    {
      view,
      state,
      title,
      active,
      onSubmit,
      setState,
      setActive,
      buttonLabel,
      handleFormSubmit,
      edit,
      backAllClients,
    },
    ref
  ) => {
    const { addPatientLoading } = useSelector((state) => state?.patient);
    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const isLabptopScreen = useMediaQuery("(max-width: 1024px)");

    // const [showAdditional, setShowAdditional] = useState(false);
    const { classes } = useStyles();
    const [show, setShow] = useState(false);
    const [showGuadrian, setShowGuardian] = useState(false);
    const [showPCP, setShowPCP] = useState(false);
    const [showPharmacy, setShowPharmacy] = useState(false);
    const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;

    const uniqueId = () => Math.floor(Math.random() * 1000000);

    const initialInsuranceBlank = {
      id: uniqueId(),
      type: "",
      subscriberName: "",
      providerName: "",
      identificationNumber: "",
      groupNumber: "",
      effectiveDate: "",
      showType: true,
    };
    const initialInsurance = [
      {
        id: uniqueId(),
        type: "medical",
        subscriberName: "",
        providerName: "",
        identificationNumber: "",
        groupNumber: "",
        effectiveDate: "",
        showType: false,
      },
      {
        id: uniqueId(),
        type: "vision",
        subscriberName: "",
        providerName: "",
        identificationNumber: "",
        groupNumber: "",
        effectiveDate: "",
        showType: false,
      },
      {
        id: uniqueId(),
        type: "prescription",
        subscriberName: "",
        providerName: "",
        identificationNumber: "",
        groupNumber: "",
        effectiveDate: "",
        showType: false,
      },
    ];
    const initialProvider = {
      additionalInsuranceName: "",
      additionalInsurancePhone: "",
      additionalInsuranceEmail: "",
      additionalInsuranceSubscriberName: "",
      additionalInsuranceEffectiveDateName: "",
    };
    const [insurance, setInsurance] = useState(initialInsurance);
    const [additionalInsurance, setAdditionalInsurance] = useState([
      { id: 1, ...initialProvider },
    ]);
    const topRef = useRef();
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      confirm: () => {
        let values = null;
        if (
          !values?.caseManagerEmail &&
          values?.caseManagerPhoneNumber == "+1" &&
          !values?.caseManagerName
        ) {
          values.noCaseManager = true;
        } else {
          values.noCaseManager = false;
        }
        if (
          !values?.emergencyContactName &&
          !values?.emergencyContactEmail &&
          values?.emergencyContactPhoneNumber == "+1"
        ) {
          values.noGuardian = true;
        } else {
          values.noGuardian = false;
        }

        if (additionalInsurance?.length === 0)
          values.noAdditionalInsurance = true;
        let obj = {
          ...values,
          additionalInsurance,
        };
        setState((p) => ({ ...p, form2: obj }));
        setActive(2);
      },
    }));

    // const onSave = (values) => {
    //   let valid = form?.validate();

    //   // if (
    //   //   !values?.caseManagerEmail &&
    //   //   values?.caseManagerPhoneNumber == "+1" &&
    //   //   !values?.caseManagerName
    //   // ) {
    //   //   values.noCaseManager = true;
    //   // } else {
    //   //   values.noCaseManager = false;
    //   // }
    //   // if (
    //   //   !values?.emergencyContactName &&
    //   //   !values?.emergencyContactEmail &&
    //   //   values?.emergencyContactPhoneNumber == "+1"
    //   // ) {
    //   //   values.noGuardian = true;
    //   // } else {
    //   //   values.noGuardian = false;
    //   // }

    //   // if (additionalInsurance?.length === 0) values.noAdditionalInsurance = true;
    //   // let obj = {
    //   //   ...values,
    //   //   additionalInsurance,
    //   // };
    //   if (Object.keys(valid?.errors).length === 0) {
    //     // let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
    //     let obj = {
    //       ...state,
    //       form2: {
    //         ...values,
    //       },
    //     };
    //     console.log("🚀 ~ onSave ~ obj insurance:", obj);
    //     onSubmit();
    //     setActive(2);
    //     setState(obj);
    //     // setState((p) => ({ ...p, form2: obj }));
    //   }
    //   // setActive(2);
    //   onSubmit();
    // };

    const onSave = (values) => {
      onSubmit({
        ...state.form2,
        insurance: insurance,
      });
    };

    const handleChangeInsurance = (id, key, value) => {
      let temp = insurance?.map((v, i) => {
        return v.id === id
          ? {
              ...v,
              [key]: value,
            }
          : v;
      });
      setInsurance(temp);
    };

    const addInsurance = () => {
      setInsurance([...insurance, initialInsuranceBlank]);
    };

    const removeProvider = (provId) => {
      setAdditionalInsurance(
        additionalInsurance.filter((p) => p.id !== provId)
      );
    };
    useEffect(() => {
      if (active === 0) topRef?.current?.scrollIntoView();
      if (active === 0 && state?.form2?.insurance) {
        setInsurance(state.form2.insurance);
      }
    }, [active, state]);
    return (
      <>
        {/* <form
        onSubmit={form?.onSubmit((values) => onSave(values, "insuranceInfo"))}
        ref={topRef}
      > */}
        <Text fz={24} weight={600}>
          {title}
        </Text>
        {insurance?.map((v, index) => {
          return (
            <>
              <Box className={classes.labelCheck}>
                <Text
                  variant="text"
                  tt="capitalize"
                  fw={500}
                  fz={18}
                  ref={inputRef}
                >
                  {v.type}
                </Text>
              </Box>
              <SimpleGrid
                cols={isMobileScreen ? 1 : 4 || isLabptopScreen ? 2 : 3}
                className={classes.mwd}
              >
                {v.showType === true && (
                  // <Box>
                  // {/* <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  //       {!view && (
                  //         <span
                  //           onClick={() => removeProvider(value.id)}
                  //           style={{
                  //             color: "#fab005",
                  //             textDecoration: "underline",
                  //             cursor: "pointer",
                  //           }}
                  //         >
                  //           Delete
                  //         </span>
                  //       )}
                  //     </Box> */}
                  // <SimpleGrid
                  //   cols={isMobileScreen ? 1 : 3}
                  //   className={classes.mwd}
                  //   mb={30}
                  // >
                  <TextInput
                    className={classes.myfont}
                    label="Insurance Type"
                    size="md"
                    sx={{ marginTop: 10 }}
                    placeholder="Enter Insurance Type"
                    value={v?.insuranceType}
                    onChange={(e) =>
                      handleChangeInsurance(
                        v.id,
                        "insuranceType",
                        e?.target?.value
                      )
                    }
                    disabled={view}
                  />

                  //   </SimpleGrid>
                  // </Box>
                )}
                <TextInput
                  id="provider-name"
                  size="sm"
                  disabled={view}
                  sx={{ marginTop: 10 }}
                  className={classes.myfont}
                  label="Provider Name"
                  value={v?.providerName}
                  placeholder="Enter Provider Name"
                  onChange={(e) =>
                    handleChangeInsurance(
                      v.id,
                      "providerName",
                      e?.target?.value
                    )
                  }
                />
                <TextInput
                  id="subscriber-name"
                  size="sm"
                  disabled={view}
                  sx={{ marginTop: 10 }}
                  className={classes.myfont}
                  label="Subscriber Name"
                  placeholder="Enter Subscriber Name"
                  value={v?.subscriberName}
                  onChange={(e) =>
                    handleChangeInsurance(
                      v.id,
                      "subscriberName",
                      e?.target?.value
                    )
                  }
                />

                <TextInput
                  id="identification-number"
                  size="sm"
                  disabled={view}
                  sx={{ marginTop: 10 }}
                  label={"Identification Number"}
                  className={classes.myfont}
                  value={v?.identificationNumber}
                  placeholder="Enter Identification Number"
                  onChange={(e) =>
                    handleChangeInsurance(
                      v.id,
                      "identificationNumber",
                      e?.target?.value
                    )
                  }
                />
                <TextInput
                  id="group-number"
                  size="sm"
                  disabled={view}
                  sx={{ marginTop: 10 }}
                  label={"Group Number"}
                  className={classes.myfont}
                  value={v?.groupNumber}
                  placeholder="Enter Group Number"
                  onChange={(e) =>
                    handleChangeInsurance(v.id, "groupNumber", e?.target?.value)
                  }
                />
                <DateInput
                  id="effective-date"
                  // maxDate={new Date()}
                  // className={classes.myfont}
                  label="Effective Date"
                  valueFormat="MM/DD/YYYY"
                  size="xs"
                  placeholder="mm/dd/yyyy"
                  disabled={view}
                  value={v.effectiveDate ? new Date(v.effectiveDate) : null}
                  variant="unstyled"
                  style={{ borderBottom: "2px solid #aaaaaa" }}
                  rightSection={
                    <img
                      style={{ width: "30px" }}
                      src={"/assets/images/calendar-icon.png"}
                      alt="Icon"
                    />
                  }
                  onChange={(date) =>
                    handleChangeInsurance(
                      v.id,
                      "effectiveDate",
                      date ? date.toISOString() : null
                    )
                  }
                />
              </SimpleGrid>
            </>
          );
        })}

        {/* {!showAdditional && ( */}
        <Box mt={20}>
          {!view && (
            <Button
              size="sm"
              color="yellow"
              variant="white"
              onClick={addInsurance}
              style={{ marginLeft: "auto", display: "block" }}
            >
              Add Additional Insurance
            </Button>
          )}
        </Box>
        {/* )} */}

        {view ? (
          <div style={{ height: "30px" }} />
        ) : (
          <SimpleGrid
            cols={isMobileScreen ? 1 : 3}
            // sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
            className={classes.btns}
          >
            <Button
              variant="white"
              color="yellow"
              size="sm"
              onClick={() => {
                handleFormSubmit("", "careSupportInfo");
              }}
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
                fontFamily: "inter",
              })}
            >
              Go Back
            </Button>
            <Box className={classes.centerBTN}>
              {/* <Button
                color="yellow"
                size="sm"
                variant="outline"
                radius="md"
                styles={(theme) => ({
                  root: {
                    color: "#000",
                    borderColor: "#FFBA45",
                  },
                  fontFamily: "inter",
                })}
                type="submit"
                onClick={backAllClients}
              >
                Add Later
              </Button> */}
            </Box>
            <Button
              id="individual-next"
              className="add-client-button"
              radius="md"
              size="sm"
              color="yellow"
              onClick={onSave}
              loading={addPatientLoading}
              disabled={addPatientLoading}
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
                fontFamily: "inter",
              })}
            >
              {buttonLabel}
              {/* Save Client */}
            </Button>
          </SimpleGrid>
        )}
        {/* </form> */}
      </>
    );
  }
);

export default InsuranceForm;
