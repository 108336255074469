import React from "react";
import { Button, Modal, Text } from "@mantine/core";

import useStyles from "./style";
import CloseCircle from "../CloseCircle";

import { RiErrorWarningLine } from "react-icons/ri";

const ConfirmationModal = ({ opened, close, loading, handleEdit }) => {
  const { classes } = useStyles();

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      className={classes.confirmationModal}
      opened={opened}
      onClose={handleClose}
      shadow="md"
      overlayProps={{ blur: 3 }}
      radius={"lg"}
      zIndex={1200}
      centered
    >
      <CloseCircle onclose={handleClose} />
      <div className={classes.wrapper}>
        <h2>
          <RiErrorWarningLine
            size={30}
            color={"orange"}
            style={{ marginBottom: "-7px" }}
          />{" "}
          Warning
        </h2>
        {/* <p style={{ fontSize: "20px" }}>
          You're changing the dependent fields (i-e : medication Type or Start
          Date or Expiration Date or Administering Times or Schedule Type or
          Repeat Every or Repeat Type). On clicking confirm, the schedules will
          be recreated with new details.
        </p> */}

        <Text fz={22} ff={"Inter"}>
          You're about to modify a primary field. This will remove all
          previously administered medications for this entry and generate a new
          medication schedule.
          <br/> 
          Please click Confirm to continue.
        </Text>

        <div className={classes.saveButton}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={loading} onClick={handleEdit}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
