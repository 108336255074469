import React from "react";
import { Card, Button, Text } from "@mantine/core";
import { useStyles } from "./style";
const PricingCards = (props) => {
  const { classes } = useStyles();
  const {
    item,
    src,
    onClick,
    disabled,
    isPriceCard,
    isTrial,
    bothNormal,
    ...properties
  } = props;
  let {
    patient,
    name,
    cost,
    staff,
    notes,
    reports,
    duration,
    facility,
    id: packageId,
    additionalStaff,
    additionalClient,
    additionalFacility,
  } = item;

  const facilityString =
    Number(facility) > 1
      ? `Up to ${facility} facilities`
      : `${facility} Facility`;

  const packageColor =
    name === "Trial"
      ? "#000000"
      : name === "Silver"
      ? "#e4e4e4"
      : name === "Sliver Plan Test"
      ? "#ffb435"
      : "#000000";

  return (
    <div className={classes.cardContainer}>
      <Card shadow="sm" withBorder className={classes.Card}>
        <Text className={classes.cardStatus}> {name}</Text>
        <div
          className={classes.cardColor}
          style={{ background: packageColor }}
        ></div>
        <div className={classes.cardContent}>
          <Text className={classes.cardAmount}>
            <h2 className={classes.cast}>
              {cost === "0.00" ? "Free" : `$${cost}`}
            </h2>
            {cost === "0.00" ? <p></p> : <p> /{duration}</p>}
          </Text>
          <ul className={classes.cardList}>
            <li>
              {facilityString}{" "}
              {name === "Free" ? (
                ""
              ) : (
                <span
                  style={{
                    fontSize: "13px",
                    color: "#666666",
                    fontWeight: 600,
                  }}
                >
                  (Additional Facility +${additionalFacility})
                </span>
              )}
            </li>
            <li>
              {staff} staff members{" "}
              {name === "Free" ? (
                ""
              ) : (
                <span
                  style={{
                    fontSize: "13px",
                    color: "#666666",
                    fontWeight: 600,
                  }}
                >
                  (Additional Staff +${additionalStaff})
                </span>
              )}
            </li>
            <li>
              {patient} Resident/Clients{" "}
              {name === "Free" ? (
                ""
              ) : (
                <span
                  style={{
                    fontSize: "13px",
                    color: "#666666",
                    fontWeight: 600,
                  }}
                >
                  (Additional Client +${additionalClient})
                </span>
              )}
            </li>
            <li>Medication Management/eMAR</li>
            <li>Care Plans</li>
            <li>Client Intake</li>
            {name === "Silver" && (
              <>
                <li>Custom Roles (coming soon)</li>
                <li>Document Management </li>
                <li>Staff Management (coming soon)</li>
                <li>Text to Speech</li>
                <li>{notes} Notes</li>
                <li>{reports} Reports</li>
              </>
            )}
            {name === "Sliver Plan Test" && (
              <>
                <li>Custom Roles (coming soon)</li>
                <li>Document Management </li>
                <li>Staff Management (coming soon)</li>
                <li>Task Management</li>
                <li>Text to Speech</li>
                <li>{notes} Notes</li>
                <li>{reports} Reports</li>
                <li>Forms (coming soon)</li>
                <li>Scheduler (coming soon)</li>
                <li>Sky Al (coming soon)</li>
              </>
            )}
            {name === "Free" && (
              <>
                <li>Custom Roles (coming soon)</li>
                <li> Staff Management (coming soon)</li>
                <li>{notes} Notes</li>
                <li>{reports} Reports</li>
              </>
            )}
          </ul>
        </div>
        <div
          className={classes.btnContainer}
          style={{ position: "absolute", bottom: "0px" }}
        >
          <Button
            className={classes.cardButton}
            disabled={disabled}
            onClick={() => onClick(packageId)}
          >
            {"Select Plan"}
          </Button>
        </div>
      </Card>

      {/* <Card shadow="sm" withBorder className={classes.Card}>
        <Text className={classes.cardStatus}> Silver</Text>
        <div
          className={classes.cardColor}
          style={{ backgroundColor: "#e4e4e4" }}
        ></div>
        <div className={classes.cardContent}>
          <Text className={classes.cardAmount}>
            <h2 style={{ padding: "0px" }}>$99.99</h2> <p> /Month</p>
          </Text>
          <ul className={classes.cardList}>
            <li>Up to 2 Facilities</li>
            <li>10 staff members</li>
            <li>10 Clients</li>
            <li>Medication Management/eMAR</li>
            <li>Care Plans</li>
            <li>Client Intake</li>
            <li>Custom Roles (coming soon)</li>
            <li> Staff Management (coming soon)</li>
            <li> Document Management </li>
            <li>Unlimited notes</li>
            <li>Unlimited Reports</li>
          </ul>
        </div>
        <div
          className={classes.btnContainer}
          style={{ position: "absolute", bottom: "0px" }}
        >
          <Button className={classes.cardButton}>Select Plan</Button>
        </div>
      </Card>
      <Card shadow="sm" withBorder className={classes.Card}>
        <Text className={classes.cardStatus}> Golden</Text>
        <div
          className={classes.cardColor}
          style={{ backgroundColor: "#ffb435" }}
        ></div>
        <div className={classes.cardContent}>
          <Text className={classes.cardAmount}>
            <h2 style={{ padding: "0px" }}>$399.99</h2> <p> /Month</p>
          </Text>
          <ul className={classes.cardList}>
            <li>Up to 10 Facilities </li>
            <li>40 Staff members</li>
            <li>50 Clients</li>
            <li>Medication Management/eMAR</li>
            <li>Care Plans</li>
            <li>Client Intake</li>
            <li>Custom Roles (coming soon)</li>
            <li>Staff Management (coming soon)</li>
            <li>Unlimited Notes</li>
            <li>Unlimited Reports</li>
            <li>Forms (coming soon)</li>
            <li>Scheduler (coming soon)</li>
            <li>Sky Al (coming soon)</li>
          </ul>
        </div>
        <div
          className={classes.btnContainer}
          style={{ position: "absolute", bottom: "0px" }}
        >
          <Button className={classes.cardButton}>Select Plan</Button>
        </div>
      </Card>
      <Card shadow="sm" withBorder className={classes.Card}>
        <Text className={classes.cardStatus}> Enterprise</Text>
        <div
          className={classes.cardColor}
          style={{ backgroundColor: "#772598" }}
        ></div>
        <div className={classes.cardContent}>
          <Text className={classes.cardAmount}>
            <h2 style={{ padding: "0px" }}>Contact Us</h2>
          </Text>
          <ul className={classes.cardList}>
            <li>Reach out to sales representative</li>
          </ul>
          <Button className={classes.cardButton}>Schedule a Call</Button>
        </div>
      </Card> */}
    </div>
  );
};

export default PricingCards;
