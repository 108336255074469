import React from "react";
import useStyles from "./style";

const InsuranceCard = ({
  type,
  effectiveDate,
  groupNumber,
  subscriberName,
  insuranceCompanyName,
  identificationNumber,
  anotherType,
}) => {
  const { classes } = useStyles();

 
  return (
    <div className={classes.insuranceCard}>
      <small className={classes.cardTitle}>{type ? type : anotherType}</small>
      <h4 className={classes.cardHeading}>{insuranceCompanyName}</h4>
      <div className={classes.cardInsideBox}>
        {/* Subsciber name and identification number */}
        <div className={classes.informationParent}>
          <div>
            <small className={classes.fieldNames}>Subscriber name</small>
            <small className={classes.upperFieldValues}>{subscriberName}</small>
          </div>
          <div>
            <small className={classes.fieldNames}>Identification number</small>
            <small className={classes.upperFieldValues}>
              {identificationNumber}
            </small>
          </div>
        </div>
        {/* Group number and effective date */}
        <div className={classes.informationParent}>
          <div>
            <small className={classes.fieldNames}>Group number</small>
            <small className={classes.lowerFieldValues}>{groupNumber}</small>
          </div>
          <div className={classes.effectiveDateParent}>
            <small className={classes.fieldNames}>Effective date</small>
            <small className={classes.lowerFieldValues}>{effectiveDate}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCard;
