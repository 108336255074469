import { createStyles } from "@mantine/core";
import RecordNotFound from "../../../../components/RecordNotFound";

const useStyles = createStyles((theme) => ({
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const NotFound = () => {
  const { classes } = useStyles();

  return (
    <tr>
      <td colSpan={1000}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "75vh",
            gap: "8rem",
          }}
        >
          <RecordNotFound />
          <p className={classes.notFoundText}>
            You don&apos;t have any record, Add Medications in Individual to see
            here.
          </p>
        </div>
      </td>
    </tr>
  );
};

export default NotFound;
