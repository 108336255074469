import { createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  narcoticBox: {
    height: "50px",
    width: "150px",
    color: "#FF0000",
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #FF0000",
    borderRadius: "25px",
    gap: "5px",
    "& p": {
      margin: 0,
      fontSize: "20px",
      fontWeight: 500,
    },
    "& img": {
      width: "30px",
      height: "30px",
    },
  },
}));
const NarcoticBox = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.narcoticBox}>
      <img src="/assets/images/narcotic.png" alt="" />
      <p>Narcotic</p>
    </div>
  );
};

export default NarcoticBox;
