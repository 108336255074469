import { Box, createStyles, rem } from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate, getOptions, noteType_Client } from "../../utils";
import TableLoader from "../TableLoader";
import moment from "moment";
import { decrypt } from "../../utils/index";

const useStyles = createStyles((theme) => ({
  header: {
    color: "white",
    fontFamily: "Inter",
    fontStyle: "medium",
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: "#232323",
    paddingTop: rem(15),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: rem(30),
    width: "100%",
    background: "purple",
  },
  cell: {
    border: "1px solid #EDEDED",
    fontFamily: "Inter",
    fontSize: 14,
    padding: "1rem",
    width: "max-content",
  },
  outcomeCell: {
    border: "1px solid #cbced7",
    fontFamily: "Inter",
    fontSize: 16,
    padding: "1rem",
    width: "max-content",
  },
  control: {
    color: "white",
    background: "green",
  },
  tableContainer: {
    width: "1150px",
    overflowX: "auto",

    "@media (min-width: 1441px)": {
      width: "1320px",
    },
    // "@media (max-width :1024px)": {
    //   width: "900px"
    // },
    // "@media (max-width :800px)": {
    //   width: "520px"
    // }
  },
  thClass: {
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "1rem",
    padding: "1rem",
  },
}));

const TaskReportViewTable = forwardRef(
  ({ data, tables, onFiltered, setFilteredTasks, setLength }, ref) => {
   
    const { classes } = useStyles();
    // const [headers, setHeaders] = useState(["Date", "Note Type", "Status", "Assigned To", "Due Date"]);
    const [headers, setHeaders] = useState([]);

    const { subTask } = useSelector((state) => state?.task);

    const { loadingNote_Types, notesAgainstTypes, allNotesTypes } = useSelector(
      (state) => state?.note
    );

    useImperativeHandle(ref, () => ({
      applyFilters: applyFilters,
    }));

    useEffect(() => {
      if (data && notesAgainstTypes?.tasks) {
        let tasks = notesAgainstTypes?.tasks || [];
        let updatedTasks = [];
        tasks?.map((item) => {
          item?.shift?.map((shiftObj) =>
            updatedTasks.push({ ...item, shiftObj })
          );
        });


        let tempTasks = [];
        // tasks?.forEach((task) => {
        updatedTasks?.forEach((task) => {
          let tempStaffs = [];
          task?.fieldsDone?.map((item) => {
            if (
              tempStaffs.findIndex(
                (tempStaff) => tempStaff?.id == item?.staff?.id
              ) == -1
            ) {
              tempStaffs.push(item?.staff);
            }
          });

          // new code
          let fieldsDoneAccordingToShift = task?.fieldsDone
            // ?.filter((field) => field?.staff?.id == staff?.id)
            ?.filter((field) => field?.shift == task?.shiftObj?.name);
          // .map((field) => field?.field)

          let notesDoneAccShift = task?.notes?.filter(
            (note) => note?.shift == task?.shiftObj?.name
          );

          let staffPerformedFields = [];

          // Array.from(
          //   new Set(
          //     task?.fieldsDone
          //       // ?.filter((field) => field?.staff?.id === staff?.id && field?.shift == filteredShift?.name)
          //       ?.filter((field) => field?.shift == filteredShift?.name)
          //       ?.map((filteredField) =>
          //         task?.taskType?.subTasks
          //           ?.filter((subItem) =>
          //             subItem?.fields?.includes(filteredField?.field?._id)
          //           )
          //           ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
          //       )
          //   )
          // )

          tempStaffs?.map((tempStaff) => {
            let staffFields = fieldsDoneAccordingToShift?.filter(
              (item) => item?.staff?.id == tempStaff?.id
            );
            let staffNotes = notesDoneAccShift?.filter(
              (item) => item?.staff?.id == tempStaff?.id
            );
            let completedTasks = Array.from(
              new Set(
                staffFields?.map(
                  (filteredField) =>
                    task?.taskType?.subTasks
                      ?.filter((subItem) =>
                        subItem?.fields?.includes(filteredField?.field?._id)
                      )
                      ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
                )
              )
            );
            let checkedTimes = staffFields?.map((item) => item?.checkedAt);
            if (staffFields?.length) {
              staffPerformedFields.push({
                staff: tempStaff,
                tasks: completedTasks,
                shift: task?.shiftObj?.name,
                activities: staffFields
                  ?.map((item) => item?.field?.name)
                  ?.join(", "),
                notes: staffNotes,
                time: checkedTimes,
              });
            }
          });

          let tempObj = {
            client: decrypt(task?.client?.name, false),
            facility: task?.facility?.name,
            date: moment(task?.dateTime).format("MM-DD-YYYY"),
            taskType: task?.taskType?.name,
            shift: task?.shiftObj?.name,
            // expandedRow: task?.shift?.filter(item => item?.name == task?.shiftObj?.name)?.map((filteredShift) => {
            // expandedRow: tempStaffs?.map((staff) => {
            expandedRow: staffPerformedFields?.map((staffPerformed) => {
              return {
                staff: staffPerformed?.staff,
                // staff: task?.fieldsDone
                // ?.filter((field) => field?.shift == filteredShift?.name)
                // .map((field) => field?.staff),
                tasks: staffPerformed?.tasks,
                // tasks: task?.fieldsDone
                //   ?.filter((field) => field?.staff?.id == staff?.id)
                //   ?.map(
                //     (filteredField) =>
                //       task?.taskType?.subTasks
                //         ?.filter((subItem) =>
                //           subItem?.fields?.includes(filteredField?.field?._id)
                //         )
                //         ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
                //   ),
                activities: staffPerformed?.activities,
                // task?.fieldsDone
                // ?.filter((field) => field?.staff?.id == staff?.id)
                // ?.filter((field) => field?.shift == filteredShift?.name)
                // .map((field) => field?.field?.name),
                // time: task?.fieldsDone
                //   ?.filter((field) => field?.staff?.id == staff?.id)
                //   .map((field) => field?.field)
                //   ?.map((val) => moment(val?.checkedAt).format("hh:mm a"))?.[0],
                // shift: task?.shiftObj?.name,
                // shift: task?.fieldsDone
                //   ?.filter((field) => field?.staff?.id == staff?.id)
                //   .map((field) => field?.shift)?.[0],
                // notes: task?.notes?.filter(
                //   (item) => item?.staff?.id == staff?.id
                // ),
                notes: staffPerformed?.notes,
                time: moment(
                  staffPerformed?.time?.[staffPerformed?.time?.length - 1]
                ).format("hh:mm a"),
              };
            }),
            fieldsDone: task?.fieldsDone?.map((item) => {
              return {
                name: item?.field?.name,
                time: moment(item?.field?.createdAt).format("hh:mm a"),
                shift: item?.shift,
                staff: item?.staff?.name,
                activity: task?.taskType?.subTasks
                  ?.filter((subItem) =>
                    subItem?.fields?.includes(item?.field?._id)
                  )
                  ?.map((filterSubTask) =>
                    subTask?.find((sTask) => sTask?._id == filterSubTask?.task)
                  ),
              };
            }),
          };

          // let tempObj = {
          //   client: decrypt(task?.client?.name, false),
          //   facility: task?.facility?.name,
          //   date: moment(task?.dateTime).format("MM-DD-YYYY"),
          //   taskType: task?.taskType?.name,
          //   expandedRow: tempStaffs?.map((staff) => {
          //     return {
          //       staff: staff,
          //       tasks: Array.from(
          //         new Set(
          //           task?.fieldsDone
          //             ?.filter((field) => field?.staff?.id === staff?.id)
          //             ?.map((filteredField) =>
          //               task?.taskType?.subTasks
          //                 ?.filter((subItem) =>
          //                   subItem?.fields?.includes(filteredField?.field?._id)
          //                 )
          //                 ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
          //             )
          //         )
          //       ),
          //       // tasks: task?.fieldsDone
          //       //   ?.filter((field) => field?.staff?.id == staff?.id)
          //       //   ?.map(
          //       //     (filteredField) =>
          //       //       task?.taskType?.subTasks
          //       //         ?.filter((subItem) =>
          //       //           subItem?.fields?.includes(filteredField?.field?._id)
          //       //         )
          //       //         ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
          //       //   ),
          //       activities: task?.fieldsDone
          //         ?.filter((field) => field?.staff?.id == staff?.id)
          //         .map((field) => field?.field?.name),
          //       time: task?.fieldsDone
          //         ?.filter((field) => field?.staff?.id == staff?.id)
          //         .map((field) => field?.field)
          //         ?.map((val) => moment(val?.checkedAt).format("hh:mm a"))?.[0],
          //       shift: task?.fieldsDone
          //         ?.filter((field) => field?.staff?.id == staff?.id)
          //         .map((field) => field?.shift)?.[0],
          //       notes: task?.notes?.filter(
          //         (item) => item?.staff?.id == staff?.id
          //       ),
          //     };
          //   }),
          //   fieldsDone: task?.fieldsDone?.map((item) => {
          //     return {
          //       name: item?.field?.name,
          //       time: moment(item?.field?.createdAt).format("hh:mm a"),
          //       shift: item?.shift,
          //       staff: item?.staff?.name,
          //       activity: task?.taskType?.subTasks
          //         ?.filter((subItem) =>
          //           subItem?.fields?.includes(item?.field?._id)
          //         )
          //         ?.map((filterSubTask) =>
          //           subTask?.find((sTask) => sTask?._id == filterSubTask?.task)
          //         ),
          //     };
          //   }),
          // };
          tempTasks.push(tempObj);
        });

        // Combine tasks and tasks
        let combinedData = [
          // ...sortedNotes,
          // ...withoutNotesDates,
          // ...sortedTasks,
          // ...withoutTasksDates,
        ];

        // Merge the default headers with customs headers
        let defaultHeaders = data?.fields;
        // let mergedHeaders = [...defaultHeaders, ...mergeArrays(customs)];
        let mergedHeaders = [...defaultHeaders];

        setHeaders({
          headers: mergedHeaders,
          // data: combinedData,
          data: tempTasks,
        });

        setLength(tempTasks?.length);

        // sendFiltersBack({
        //   headers: mergedHeaders,
        //   data: combinedData,
        // });
      }
    }, [data?.source?.id, notesAgainstTypes]);

    // useEffect(() => {
    //   if (data && notesAgainstTypes?.notes) {
    //     let notes = notesAgainstTypes?.notes;
    //     let customs = tables?.map((value) => {
    //       if (value?.source?.id === data?.source?.id) {
    //         if (data?.fields?.length > 0)
    //           return value?.source?.custom?.filter((val) =>
    //             data?.fields?.includes(val?._id)
    //           );
    //         else return value?.source?.custom;
    //       } else return value?.source?.custom;
    //     });

    //     let sortedData = notes
    //       ?.filter((item) => item.fields.Date)
    //       ?.sort(function (a, b) {
    //         return new Date(b?.fields?.Date) - new Date(a?.fields?.Date);
    //       });
    //     let withoutDates = notes?.filter((item) => !item.fields.Date);

    //       setHeaders({
    //         headers: mergeArrays(customs),
    //         data: [...sortedData, ...withoutDates],
    //       });
    //     sendFiltersBack({
    //       headers: data?.source?.custom,
    //       data: [...sortedData, ...withoutDates],
    //     });
    //   }
    // }, [data?.source?.id, notesAgainstTypes]);

    // const mergeArrays = (arrays) => {
    //   const uniqueFields = {};

    //   arrays.forEach((array) => {
    //     array.forEach((obj) => {
    //       const key = obj.field;
    //       if (!uniqueFields[key]) {
    //         uniqueFields[key] = obj;
    //       }
    //     });
    //   });
    //   return Object.values(uniqueFields);
    // };

    const applyFilters = (filters) => {
      if (data && notesAgainstTypes?.tasks) {
        let tasks = notesAgainstTypes?.tasks;
        let filteredData = [];

        if (Array.isArray(filters) && filters?.length) {
          const toFilter = {};

          let sources = filters?.map((filter) => filter?.source);
          filters?.forEach((v) => {
            let array = filters?.filter(
              (element) => element?.source == v?.source
            );


            let _filters = {};
            array?.forEach((element) => {
              // _filters[element?.field] = element?.filterOn;
              filteredData = [
                ...(filteredData?.length ? filteredData : tasks),
              ]?.filter((task) => {
                if (element?.fieldValue === "Facility Name") {
                  return (
                    element?.filterOn === task?.facility?.name &&
                    sources?.includes(task?.taskType?.id)
                  );
                } else if (element?.fieldValue === "Staff Name") {
                  return (
                    element?.filterOn === task?.staff?.[0]?.name &&
                    sources?.includes(task?.taskType?.id)
                  );
                } else if (element?.fieldValue === "Client Name") {
                  return (
                    element?.filterOn === decrypt(task?.client?.name, false) &&
                    sources?.includes(task?.taskType?.id)
                  );
                } else if (element?.fieldValue === "Date") {
                  return (
                    moment(element?.filterOn).format("MM-DD-YYYY") ===
                      moment(task?.dateTime).format("MM-DD-YYYY") &&
                    sources?.includes(task?.taskType?.id)
                  );
                }
                return false; // Return false for cases that don't match the conditions
              });
            });
          });
        }

        if (filters !== "clear") {
          setFilteredTasks(filteredData);
          let updatedTasks = [];
          filteredData?.map((item) => {
            item?.shift?.map((shiftObj) =>
              updatedTasks.push({ ...item, shiftObj })
            );
          });

          let tempTasks = [];
          updatedTasks?.forEach((task) => {
            let tempStaffs = [];
            task?.fieldsDone?.map((item) => {
              if (
                tempStaffs.findIndex(
                  (tempStaff) => tempStaff?.id == item?.staff?.id
                ) == -1
              ) {
                tempStaffs.push(item?.staff);
              }
            });

            let fieldsDoneAccordingToShift = task?.fieldsDone?.filter(
              (field) => field?.shift == task?.shiftObj?.name
            );

            let notesDoneAccShift = task?.notes?.filter(
              (note) => note?.shift == task?.shiftObj?.name
            );

            let staffPerformedFields = [];

            tempStaffs?.map((tempStaff) => {
              let staffFields = fieldsDoneAccordingToShift?.filter(
                (item) => item?.staff?.id == tempStaff?.id
              );
              let staffNotes = notesDoneAccShift?.filter(
                (item) => item?.staff?.id == tempStaff?.id
              );
              let completedTasks = Array.from(
                new Set(
                  staffFields?.map(
                    (filteredField) =>
                      task?.taskType?.subTasks
                        ?.filter((subItem) =>
                          subItem?.fields?.includes(filteredField?.field?._id)
                        )
                        ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
                  )
                )
              );
              let checkedTimes = staffFields?.map((item) => item?.checkedAt);
              if (staffFields?.length) {
                staffPerformedFields.push({
                  staff: tempStaff,
                  tasks: completedTasks,
                  shift: task?.shiftObj?.name,
                  activities: staffFields
                    ?.map((item) => item?.field?.name)
                    ?.join(", "),
                  notes: staffNotes,
                  time: checkedTimes,
                });
              }
            });

            let tempObj = {
              client: decrypt(task?.client?.name, false),
              facility: task?.facility?.name,
              date: moment(task?.dateTime).format("MM-DD-YYYY"),
              taskType: task?.taskType?.name,
              shift: task?.shiftObj?.name,
              // expandedRow: tempStaffs?.map((staff) => {
              //   return {
              //     staff: staff,
              //     tasks: Array.from(
              //       new Set(
              //         task?.fieldsDone
              //           ?.filter((field) => field?.staff?.id === staff?.id)
              //           ?.map((filteredField) =>
              //             task?.taskType?.subTasks
              //               ?.filter((subItem) =>
              //                 subItem?.fields?.includes(filteredField?.field?._id)
              //               )
              //               ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
              //           )
              //       )
              //     ),
              //     // tasks: task?.fieldsDone
              //     //   ?.filter((field) => field?.staff?.id == staff?.id)
              //     //   ?.map(
              //     //     (filteredField) =>
              //     //       task?.taskType?.subTasks
              //     //         ?.filter((subItem) =>
              //     //           subItem?.fields?.includes(filteredField?.field?._id)
              //     //         )
              //     //         ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
              //     //   ),
              //     activities: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.field?.name),
              //     time: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.field)
              //       ?.map((val) =>
              //         moment(val?.checkedAt).format("hh:mm a")
              //       )?.[0],
              //     shift: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.shift)?.[0],
              //     notes: task?.notes?.filter(
              //       (item) => item?.staff?.id == staff?.id
              //     ),
              //   };
              // }),
              expandedRow: staffPerformedFields?.map((staffPerformed) => {
                return {
                  staff: staffPerformed?.staff,
                  tasks: staffPerformed?.tasks,
                  activities: staffPerformed?.activities,
                  notes: staffPerformed?.notes,
                  time: moment(
                    staffPerformed?.time?.[staffPerformed?.time?.length - 1]
                  ).format("hh:mm a"),
                };
              }),
              fieldsDone: task?.fieldsDone?.map((item) => {
                return {
                  name: item?.field?.name,
                  time: moment(item?.field?.createdAt).format("hh:mm a"),
                  shift: item?.shift,
                  staff: item?.staff?.name,
                  activity: task?.taskType?.subTasks
                    ?.filter((subItem) =>
                      subItem?.fields?.includes(item?.field?._id)
                    )
                    ?.map((filterSubTask) =>
                      subTask?.find(
                        (sTask) => sTask?._id == filterSubTask?.task
                      )
                    ),
                };
              }),
            };
            tempTasks.push(tempObj);
          });

          setHeaders({
            headers: data?.fields,
            data: tempTasks,
          });

          setLength(tempTasks?.length);
        } else {
          setFilteredTasks(null);

          let updatedTasks = [];
          tasks?.map((item) => {
            item?.shift?.map((shiftObj) =>
              updatedTasks.push({ ...item, shiftObj })
            );
          });

          let tempTasks = [];
          updatedTasks?.forEach((task) => {
            let tempStaffs = [];
            task?.fieldsDone?.map((item) => {
              if (
                tempStaffs.findIndex(
                  (tempStaff) => tempStaff?.id == item?.staff?.id
                ) == -1
              ) {
                tempStaffs.push(item?.staff);
              }
            });

            let fieldsDoneAccordingToShift = task?.fieldsDone?.filter(
              (field) => field?.shift == task?.shiftObj?.name
            );

            let notesDoneAccShift = task?.notes?.filter(
              (note) => note?.shift == task?.shiftObj?.name
            );

            let staffPerformedFields = [];

            tempStaffs?.map((tempStaff) => {
              let staffFields = fieldsDoneAccordingToShift?.filter(
                (item) => item?.staff?.id == tempStaff?.id
              );
              let staffNotes = notesDoneAccShift?.filter(
                (item) => item?.staff?.id == tempStaff?.id
              );
              let completedTasks = Array.from(
                new Set(
                  staffFields?.map(
                    (filteredField) =>
                      task?.taskType?.subTasks
                        ?.filter((subItem) =>
                          subItem?.fields?.includes(filteredField?.field?._id)
                        )
                        ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
                  )
                )
              );
              let checkedTimes = staffFields?.map((item) => item?.checkedAt);
              if (staffFields?.length) {
                staffPerformedFields.push({
                  staff: tempStaff,
                  tasks: completedTasks,
                  shift: task?.shiftObj?.name,
                  activities: staffFields
                    ?.map((item) => item?.field?.name)
                    ?.join(", "),
                  notes: staffNotes,
                  time: checkedTimes,
                });
              }
            });

            let tempObj = {
              client: decrypt(task?.client?.name, false),
              facility: task?.facility?.name,
              date: moment(task?.dateTime).format("MM-DD-YYYY"),
              taskType: task?.taskType?.name,
              shift: task?.shiftObj?.name,
              // expandedRow: tempStaffs?.map((staff) => {
              //   return {
              //     staff: staff,
              //     tasks: Array.from(
              //       new Set(
              //         task?.fieldsDone
              //           ?.filter((field) => field?.staff?.id === staff?.id)
              //           ?.map((filteredField) =>
              //             task?.taskType?.subTasks
              //               ?.filter((subItem) =>
              //                 subItem?.fields?.includes(filteredField?.field?._id)
              //               )
              //               ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
              //           )
              //       )
              //     ),
              //     // tasks: task?.fieldsDone
              //     //   ?.filter((field) => field?.staff?.id == staff?.id)
              //     //   ?.map(
              //     //     (filteredField) =>
              //     //       task?.taskType?.subTasks
              //     //         ?.filter((subItem) =>
              //     //           subItem?.fields?.includes(filteredField?.field?._id)
              //     //         )
              //     //         ?.map((taskLabel) => taskLabel?.task?.name)?.[0]
              //     //   ),
              //     activities: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.field?.name),
              //     time: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.field)
              //       ?.map((val) =>
              //         moment(val?.checkedAt).format("hh:mm a")
              //       )?.[0],
              //     shift: task?.fieldsDone
              //       ?.filter((field) => field?.staff?.id == staff?.id)
              //       .map((field) => field?.shift)?.[0],
              //     notes: task?.notes?.filter(
              //       (item) => item?.staff?.id == staff?.id
              //     ),
              //   };
              // }),
              expandedRow: staffPerformedFields?.map((staffPerformed) => {
                return {
                  staff: staffPerformed?.staff,
                  tasks: staffPerformed?.tasks,
                  activities: staffPerformed?.activities,
                  notes: staffPerformed?.notes,
                  time: moment(
                    staffPerformed?.time?.[staffPerformed?.time?.length - 1]
                  ).format("hh:mm a"),
                };
              }),
              fieldsDone: task?.fieldsDone?.map((item) => {
                return {
                  name: item?.field?.name,
                  time: moment(item?.field?.createdAt).format("hh:mm a"),
                  shift: item?.shift,
                  staff: item?.staff?.name,
                  activity: task?.taskType?.subTasks
                    ?.filter((subItem) =>
                      subItem?.fields?.includes(item?.field?._id)
                    )
                    ?.map((filterSubTask) =>
                      subTask?.find(
                        (sTask) => sTask?._id == filterSubTask?.task
                      )
                    ),
                };
              }),
            };
            tempTasks.push(tempObj);
          });

          setHeaders({
            headers: data?.fields,
            data: tempTasks,
          });

          setLength(tempTasks?.length);
        }
      }
    };

    const sendFiltersBack = (headers) => {
      const heads = headers?.headers?.map((value) => value?.field);

      let csvData = headers?.data?.map((row, index) =>
        headers?.headers?.map((value) => {
          if (value?.options) {
            let options = getOptions(
              value?.options,
              allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)?.custom
            );
            return options?.find(
              (op) => op?.value === row?.fields[value?.field]
            )?.label;
          }
          return formatDate(row?.fields[value?.field]);
        })
      );
      if (csvData?.length && data?.source) {
        onFiltered(data?.source?.id, data?.source, [heads, ...csvData]);
      }
    };

    // const rows = headers?.data?.map((row, index) => {
    //   console.log("🚀 ~ rows ~ row:", row);
    //   let headersCount = 0;
    //   return (
    //     <>
    //       <tr key={index}>
    //         {headers?.headers?.map((value, i) => {
    //           let keyName =
    //             value === "Task Name"
    //               ? "taskType"
    //               : value === "Client Name"
    //               ? "client"
    //               : value === "Date"
    //               ? "date"
    //               : value === "Facility Name"
    //               ? "facility"
    //               : value === "Task Completed" ||
    //                 value === "Shift" ||
    //                 value === "Time" || value === "Activities Performed"
    //               ? "fieldsDone"
    //               : "";
    //           console.log("key value", keyName, value);
    //           if (i <= headersCount) {
    //             headersCount = headersCount + 1;
    //             return keyName === "fieldsDone" ? (
    //               <td
    //                 className={classes.cell}
    //                 style={{
    //                   width: "100%",
    //                   padding: "1rem 0",
    //                 }}
    //               >
    //                 <div
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   {row[keyName]?.map((item, index) => (
    //                     <td
    //                       className={classes.cell}
    //                       style={{
    //                         width: "100%",
    //                         border: "none",
    //                         borderBottom:
    //                           row[keyName]?.length - 1 === index
    //                             ? "none"
    //                             : "1px solid #EDEDED",
    //                       }}
    //                     >
    //                       {value === "Task Completed" && item?.activity?.map((act) => (act?.name))?.join(", ")}
    //                       {value === "Activities Performed" && item?.name}
    //                       {value === "Shift" && item?.shift}
    //                       {value === "Time" && item?.time}
    //                     </td>
    //                   ))}
    //                 </div>
    //               </td>
    //             ) : (
    //               <td className={classes.cell} key={i}>
    //                 {row[keyName]}
    //               </td>
    //             );
    //           }
    //         })}
    //       </tr>
    //     </>
    //   );
    // });

    const rows = headers?.data?.map((row, index) => {
      let headersCount = 0;
      return (
        <>
          <tr key={index}>
            {headers?.headers?.map((value, i) => {
              let keyName =
                value === "Task Name"
                  ? "taskType"
                  : value === "Client Name"
                  ? "client"
                  : value === "Date"
                  ? "date"
                  : value === "Facility Name"
                  ? "facility"
                  : value === "Shift"
                  ? "shift"
                  : value === "Task Completed" ||
                    // value === "Shift" ||
                    value === "Time" ||
                    value === "Activities Performed" ||
                    value === "Staff Name" ||
                    value === "Notes"
                  ? "expandedRow"
                  : "";
              console.log("key value", keyName, value);
              if (i <= headersCount) {
                headersCount = headersCount + 1;
                return keyName === "expandedRow" ? (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {row[keyName]?.map((item, index) => (
                        <td
                          className={classes.cell}
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom:
                              row[keyName]?.length - 1 === index
                                ? "none"
                                : "1px solid #EDEDED",
                          }}
                        >
                          {value === "Staff Name" && item?.staff?.name}
                          {value === "Task Completed" &&
                            item?.tasks?.map((item) => item)?.join(", ")}
                          {value === "Activities Performed" && item?.activities}
                          {/* {value === "Activities Performed" &&
                            item?.activities?.map((act) => act)?.join(", ")} */}
                          {value === "Shift" && item?.shift}
                          {value === "Time" && item?.time}
                          {value === "Notes" &&
                            item?.notes?.map((note) => note?.note)?.join(", ")}
                        </td>
                      ))}
                    </div>
                  </td>
                ) : (
                  <td className={classes.cell} key={i}>
                    {row[keyName]}
                  </td>
                );
              }
            })}
          </tr>
        </>
      );
    });

    // const hiddenRows = headers?.data?.map((row, index) => {
    //   console.log("🚀 ~ rows ~ row:", row);
    //   let headersCount = 0;
    //   return (
    //     <>
    //       <tr key={index}>
    //         {headers?.headers?.map((value, i) => {
    //           let keyName =
    //             value === "Task Name"
    //               ? "taskType"
    //               : value === "Client Name"
    //               ? "client"
    //               : value === "Date"
    //               ? "date"
    //               : value === "Facility Name"
    //               ? "facility"
    //               : value === "Task Completed" ||
    //                 value === "Shift" ||
    //                 value === "Time" || value === "Activities Performed"
    //               ? "fieldsDone"
    //               : "";
    //           console.log("key value", keyName, value);
    //           if (i <= headersCount) {
    //             headersCount = headersCount + 1;
    //             return keyName === "fieldsDone" &&
    //               value === "Activities Performed" ? (
    //               <td className={classes.cell}>
    //                 <table style={{ width: "100%", height: "100%" }}>
    //                   {row[keyName]?.map((item) => (
    //                     <tr>
    //                       <td className={classes.cell}>{item?.name}</td>
    //                     </tr>
    //                   ))}
    //                 </table>
    //               </td>
    //             ) : keyName === "fieldsDone" && value === "Task Completed" ? (
    //               <td
    //                 className={classes.cell}
    //                 style={{
    //                   width: "100%",
    //                   padding: "1rem 0",
    //                 }}
    //               >
    //                 <div
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <table style={{ width: "100%", height: "100%" }}>
    //                     {row[keyName]?.map((item) => (
    //                       <tr>
    //                         <td className={classes.cell}>{item?.activity?.map((act) => (act?.name))?.join(", ")}</td>
    //                       </tr>
    //                     ))}
    //                   </table>
    //                 </div>
    //               </td>
    //             ) : keyName === "fieldsDone" && value === "Shift" ? (
    //               <td
    //                 className={classes.cell}
    //                 style={{
    //                   width: "100%",
    //                   padding: "1rem 0",
    //                 }}
    //               >
    //                 <div
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <table style={{ width: "100%", height: "100%" }}>
    //                     {row[keyName]?.map((item) => (
    //                       <tr>
    //                         <td className={classes.cell}>{item?.shift}</td>
    //                       </tr>
    //                     ))}
    //                   </table>
    //                 </div>
    //               </td>
    //             ) : keyName === "fieldsDone" && value === "Time" ? (
    //               <td>
    //                 <table style={{ width: "100%", height: "100%" }}>
    //                   {row[keyName]?.map((item) => (
    //                     <tr>
    //                       <td className={classes.cell}>{item?.time}</td>
    //                     </tr>
    //                   ))}
    //                 </table>
    //               </td>
    //             ) : (
    //               <td
    //                 // rowSpan={
    //                 //   row?.fieldsDone?.length > 0 ? row?.fieldsDone?.length : 1
    //                 // }
    //                 // rowSpan={1}
    //                 className={classes.cell}
    //                 key={i}
    //               >
    //                 {row[keyName]}
    //               </td>
    //             );
    //           }
    //         })}
    //       </tr>
    //     </>
    //   );
    // });

    const hiddenRows = headers?.data?.map((row, index) => {
      let headersCount = 0;
      return (
        <>
          <tr key={index}>
            {headers?.headers?.map((value, i) => {
              let keyName =
                value === "Task Name"
                  ? "taskType"
                  : value === "Client Name"
                  ? "client"
                  : value === "Date"
                  ? "date"
                  : value === "Facility Name"
                  ? "facility"
                  : value === "Shift"
                  ? "shift"
                  : value === "Task Completed" ||
                    // value === "Shift" ||
                    value === "Time" ||
                    value === "Activities Performed" ||
                    value === "Staff Name" ||
                    value === "Notes"
                  ? "expandedRow"
                  : "";
              console.log("key value", keyName, value);
              if (i <= headersCount) {
                headersCount = headersCount + 1;
                return keyName === "expandedRow" &&
                  value === "Activities Performed" ? (
                  <td className={classes.cell}>
                    <table style={{ width: "100%", height: "100%" }}>
                      {row[keyName]?.map((item) => (
                        <tr>
                          {/* <td className={classes.cell}>{item?}</td> */}
                          <td className={classes.cell}>
                            {/* {item?.activities?.map((act) => act)?.join(", ")} */}
                            {item?.activities}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                ) : keyName === "expandedRow" && value === "Task Completed" ? (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <table style={{ width: "100%", height: "100%" }}>
                        {row[keyName]?.map((item) => (
                          <tr>
                            <td className={classes.cell}>
                              {item?.tasks?.map((item) => item)?.join(", ")}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </td>
                ) : keyName === "expandedRow" && value === "Staff Name" ? (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <table style={{ width: "100%", height: "100%" }}>
                        {row[keyName]?.map((item) => (
                          <tr>
                            <td className={classes.cell}>
                              {item?.staff?.name}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </td>
                ) : keyName === "expandedRow" && value === "Shift" ? (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <table style={{ width: "100%", height: "100%" }}>
                        {row[keyName]?.map((item) => (
                          <tr>
                            <td className={classes.cell}>{item?.shift}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </td>
                ) : keyName === "expandedRow" && value === "Notes" ? (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <table style={{ width: "100%", height: "100%" }}>
                        {row[keyName]?.map((item) => (
                          <tr>
                            <td className={classes.cell}>
                              {item?.notes
                                ?.map((item) => item?.note)
                                ?.join(", ")}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </td>
                ) : keyName === "expandedRow" && value === "Time" ? (
                  <td>
                    <table style={{ width: "100%", height: "100%" }}>
                      {row[keyName]?.map((item) => (
                        <tr>
                          <td className={classes.cell}>{item?.time}</td>
                        </tr>
                      ))}
                    </table>
                  </td>
                ) : (
                  <td
                    // rowSpan={
                    //   row?.fieldsDone?.length > 0 ? row?.fieldsDone?.length : 1
                    // }
                    // rowSpan={1}
                    className={classes.cell}
                    key={i}
                  >
                    {row[keyName]}
                  </td>
                );
              }
            })}
          </tr>
        </>
      );
    });

    const sizes = {
      Description: "300px",
      "Staff Name": "150px",
      Date: "120px",
      Time: "100px",
      "Facility Name": "150px",
      Shift: "100px",
      "Task Completed": "180px",
      Notes: "250px",
      [noteType_Client]: "150px",
    };

    return (
      <div className={classes.tableContainer}>
        {/* Visible table below */}
        <table
          id="sampleTable"
          style={{
            width: "100%",
            border: "1px solid #EDEDED",
            borderCollapse: "collapse",
          }}
        >
          <tr style={{ background: "#000", color: "#fff" }}>
            {headers?.headers?.map((header, index) => {
              return (
                <th key={index}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: sizes[header] || "150px",
                      gap: "1rem",
                      padding: "1rem",
                    }}
                  >
                    {header}
                  </span>
                </th>
              );
            })}
          </tr>

          {loadingNote_Types ? <Box
              style={{
                display: "flex",
                alienItem: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <TableLoader />
            </Box> : rows}
        </table>
        {/* Hidden table below */}
        <table
          id="hiddenTable"
          style={{
            width: "100%",
            border: "1px solid #EDEDED",
            position: "absolute",
            top: "-10000000px",
            left: "-10000000px",
          }}
        >
          <tr style={{ background: "#000", color: "#fff" }}>
            {headers?.headers?.map((header, index) => {
              return (
                <th key={index}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: sizes[header] || "150px",
                      gap: "1rem",
                      padding: "1rem",
                    }}
                  >
                    {header}
                  </span>
                </th>
              );
            })}
          </tr>

          {loadingNote_Types ? (
            <Box
              style={{
                display: "flex",
                alienItem: "center",
                justifyContent: "center",
                width: "100%"

              }}
            >
              <TableLoader />
            </Box>
          ) : (
            hiddenRows
          )}
        </table>
      </div>
    );
  }
);

export default TaskReportViewTable;
