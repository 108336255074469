import { Box, Grid, Text } from "@mantine/core";
import React from "react";
import useStyles from "./style";
import { useMediaQuery } from "@mantine/hooks";

function Feature() {
  const { classes } = useStyles();
  const isMobileScreen = useMediaQuery("(max-width: 1000px)");
  const isLabptopScreen = useMediaQuery("(max-width: 1024px)");
  return (
    <Box className={classes.InlinePadding}>
      <Grid gutter="lg" className={classes.MobileFlexDirection}>
        <Grid.Col
          span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
          m={"auto"}
        >
          <Text className={classes.featureHdng}>
            Tailored Solutions <br /> For Your Practice
          </Text>
          <Text className={classes.featureText}>
            Ogiso understands that every healthcare practice is unique. That’s
            why our platform is customizable to meet the specific needs of your
            facility, whether you're a small home or a large healthcare network.
            From appointment scheduling to report generation, Ogiso has you
            covered.
          </Text>
        </Grid.Col>
        <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
          <img
            src={"/assets/images/sheduleImg.png"}
            className={classes.img}
            alt="img"
          />
        </Grid.Col>
      </Grid>
      <Grid gutter="lg" className={classes.MobileFlexDirection}>
        <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
          <img
            src={"/assets/images/dataAndSecurityTableImg.png"}
            className={classes.img}
            alt="img"
          />
        </Grid.Col>
        <Grid.Col
          span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
          m={"auto"}
          
        >
          <Text
            className={classes.featureHdng}
            ml={isMobileScreen ? 0 : 50 || isLabptopScreen ? 0 : 50}
          >
            Data and Security
          </Text>
          <Text
            className={classes.featureText}
            ml={isMobileScreen ? 0 : 50 || isLabptopScreen ? 0 : 50}
          >
            Security is our top priority. Ogiso is fully compliant with HIPAA
            regulations, ensuring that your clients data is secure and
            protected. Our advanced encryption protocols and regular updates
            safeguard your information against any potential threats.
          </Text>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default Feature;
