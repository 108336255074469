import React from "react";
import {
  Font,
  StyleSheet,
  View,
  Text,
  Document,
  Page,
  Image,
} from "@react-pdf/renderer";

import phoneImg from "../../images/phone.png";
import { FaEnvelope } from "react-icons/fa6";
import moment from "moment";
import CourierRegular from "../../fonts/CourierPrime-Regular.ttf";
import { decrypt } from "../../utils";
import { styles } from "./style";

const ClientSummaryPDF = ({ clientData, activities }) => {
  console.log("🚀 ~ ClientSummaryPDF ~ activities:", activities);
  Font.register({
    family: "Courier Prime",
    fonts: [
      {
        src: CourierRegular,
      },
    ],
  });

  // console.log("Client data PDF =>", clientData);
  // const imageVal = decrypt(clientData?.imageurl);
  // console.log("imageVal =>", imageVal);

  const filteredMedications = clientData?.form3
    ? clientData?.form3?.filter(
        (medicine) =>
          medicine?.discontinued === false || !("discontinued" in medicine)
      )
    : "";

  const knownIllnessVal = clientData?.form1?.knownIllness;

  //ogiso-bucket.s3.us-east-2.amazonaws.com/1725887779284-image.jfif

  // console.log(
  //   "clientData?.form1?.previousAddress",
  //   clientData?.form1?.previousAddress?.length
  // );

  return (
    <Document>
      <Page>
        <View style={styles.detailsFlex}>
          <View style={styles.imageView}>
            <Image
              style={styles.clientImage}
              src={`${
                clientData?.imageurl
                  ? decrypt(clientData?.imageurl)
                  : "/assets/images/personal2.png"
              }?r=${Math.floor(Math.random() * 100000)}`}
            />
          </View>
          <View style={styles.detailsView}>
            <View style={styles.divFlex}>
              <View style={styles.viewOne}>
                {/* Client Name */}
                <View style={styles.details}>
                  <Text style={styles.clientName}>Client Name</Text>
                  <Text style={styles.clientValue}>{clientData?.name}</Text>
                </View>
                {/* DOB */}
                <View style={styles.details}>
                  <Text style={styles.dobName}>Date of Birth</Text>

                  <View style={styles.blueClasses}>
                    <Text style={styles.detailValue}>
                      {moment(clientData?.form1?.dob).format("MM/DD/YYYY")}
                    </Text>
                  </View>
                </View>
                {/* Gender */}
                <View style={styles.details}>
                  <View style={styles.redClasses}>
                    <Text style={styles.detailName}>Gender</Text>
                  </View>
                  <View style={styles.blueClasses}>
                    <Text style={styles.detailValue}>
                      {clientData?.form1?.gender}
                    </Text>
                  </View>
                </View>
                {/* Height */}
                <View style={styles.details}>
                  <View style={styles.redClasses}>
                    <Text style={styles.detailName}>Height</Text>
                  </View>
                  <View style={styles.blueClasses}>
                    <Text style={styles.detailValue}>
                      {clientData?.form1?.height
                        ? clientData?.form1?.height
                        : "-"}
                    </Text>
                  </View>
                </View>
                {/* Weight */}
                <View style={styles.details}>
                  <View style={styles.redClasses}>
                    <Text style={styles.detailName}>Weight</Text>
                  </View>
                  <View style={styles.blueClasses}>
                    <Text style={styles.detailValue}>{`${
                      clientData?.form1?.weight
                        ? clientData?.form1?.weight
                        : "-"
                    } lbs`}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.viewTwo}>
                {/* Address */}
                <View>
                  <Text style={styles.previousName}>Previous Address</Text>
                  <Text style={styles.addressVal}>
                    {clientData?.form1?.previousAddress
                      ? clientData?.form1?.previousAddress?.length < 70
                        ? clientData?.form1?.previousAddress
                        : clientData?.form1?.previousAddress?.slice(0, 70) +
                          " ..."
                      : "-"}
                  </Text>
                </View>
                {/* Admission Date */}
                <View style={styles.details}>
                  <Text style={styles.detailName}>Admission Date</Text>
                  <View style={styles.dateVal}>
                    <Text style={styles.detailValue}>
                      {moment(clientData?.form1?.admissionDate).format(
                        "MM/DD/YYYY"
                      )}
                    </Text>
                  </View>
                </View>
                {/* Level of Care */}
                <View style={styles.details}>
                  <Text style={styles.detailName}>Level of Care</Text>
                  <Text style={styles.levelValue}>
                    {clientData?.form1?.levelofcare
                      ? clientData?.form1?.levelofcare
                      : "-"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.careAndHealth}>
          {/* Care & Support */}
          <View style={styles.careWidth}>
            <View style={styles.careBg}>
              <Text>Care & Support</Text>
            </View>
            {/* Guardian */}
            {(clientData?.form2?.emergencyContactName ||
              clientData?.form2?.emergencyContactPhoneNumber ||
              clientData?.form2?.emergencyContactEmail) && (
              <View style={styles.box}>
                <Text style={styles.typeName}>Guardian</Text>
                <View style={styles.info}>
                  <View>
                    <Text style={styles.infoName}>
                      {clientData?.form2?.emergencyContactName}
                    </Text>
                  </View>
                  <View style={styles.numAndEmail}>
                    <View style={styles.numberParent}>
                      {/* <Text style={styles.number}> */}
                      <Text style={styles.number}>
                        <Image src={phoneImg} />{" "}
                        {clientData?.form2?.emergencyContactPhoneNumber}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.emailAlternate}>
                        <FaEnvelope />{" "}
                        {clientData?.form2?.emergencyContactEmail}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            {/* Case Manager */}
            {(clientData?.form2?.caseManagerName ||
              clientData?.form2?.caseManagerEmail ||
              clientData?.form2?.caseManagerPhoneNumber) && (
              <View style={styles.box}>
                <Text style={styles.typeName}>Case Manager</Text>
                <View style={styles.info}>
                  <View>
                    <Text style={styles.infoName}>
                      {clientData?.form2?.caseManagerName}
                    </Text>
                  </View>
                  <View style={styles.numAndEmail}>
                    <View style={styles.numberParent}>
                      <Text style={styles.number}>
                        <Image src={phoneImg} />{" "}
                        {clientData?.form2?.caseManagerPhoneNumber}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.email}>
                        <FaEnvelope /> {clientData?.form2?.caseManagerEmail}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            {/* Primary Care Physician */}

            {(clientData?.form2?.pcpName ||
              clientData?.form2?.pcpEmail ||
              clientData?.form2?.pcpPhoneNumber) && (
              <View style={styles.box}>
                <Text style={styles.typeName}>Primary Care Physician</Text>
                <View style={styles.info}>
                  <View>
                    <Text style={styles.infoName}>
                      {clientData?.form2?.pcpName}
                    </Text>
                  </View>
                  <View style={styles.numAndEmail}>
                    <View style={styles.numberParent}>
                      <Text style={styles.number}>
                        <Image src={phoneImg} />{" "}
                        {clientData?.form2?.pcpPhoneNumber}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.email}>
                        <FaEnvelope /> {clientData?.form2?.pcpEmail}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}

            {/* Pharmacy */}
            {(clientData?.form2?.pharmacyName ||
              clientData?.form2?.pharmacyEmail ||
              clientData?.form2?.pharmacyPhoneNumber) && (
              <View style={styles.box}>
                <Text style={styles.typeName}>Pharmacy</Text>
                <View style={styles.info}>
                  <View>
                    <Text style={styles.infoName}>
                      {clientData?.form2?.pharmacyName}
                    </Text>
                  </View>
                  <View style={styles.numAndEmail}>
                    <View style={styles.numberParent}>
                      <Text style={styles.number}>
                        <Image src={phoneImg} />{" "}
                        {clientData?.form2?.pharmacyPhoneNumber}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.email}>
                        <FaEnvelope /> {clientData?.form2?.pharmacyEmail}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}

            {clientData?.form2?.additionalProvider?.length > 0 &&
              [...clientData?.form2?.additionalProvider]?.map((item, index) => (
                <View key={index} style={styles.box} wrap={false}>
                  <Text style={styles.typeName}>{item?.providerType}</Text>
                  <View style={styles.info}>
                    <View>
                      <Text style={styles.infoName}>{item?.providerName}</Text>
                    </View>
                    <View style={styles.numAndEmail}>
                      <View style={styles.numberParent}>
                        <Text style={styles.number}>
                          <Image src={phoneImg} /> {item?.providerPhone}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.email}>
                          <FaEnvelope /> {item?.providerEmail}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ))}
          </View>
          {/* Health */}
          <View style={styles.healthWidth}>
            <View style={styles.healthBg}>
              <Text>Health</Text>
            </View>
            {/* Diagnosis Summary */}
            <View style={styles.fieldset}>
              <Text style={styles.legend}>Diagnosis Summary</Text>
              <Text style={styles.diagnosisText}>
                {clientData?.form1?.summary ? clientData?.form1?.summary : "-"}
              </Text>
            </View>
            {/* Diagnosis */}
            <View style={styles.diagnosisContainer}>
              <Text style={styles.diagnosis}>Diagnosis</Text>
              <View style={styles.diagnosisParent}>
                {Array.isArray(clientData?.form1?.knownIllness) &&
                clientData?.form1?.knownIllness?.length > 0
                  ? clientData?.form1?.knownIllness?.map((item, index) => (
                      <View key={index} style={styles.diagnosisELem}>
                        <Text style={styles.diagnosisVal}>
                          {typeof item === "object"
                            ? item?.illness?.length === 0
                              ? item?.contagious
                              : item?.illness
                            : item}
                        </Text>
                      </View>
                    ))
                  : knownIllnessVal?.length > 0
                  ? knownIllnessVal.map((item, index) => (
                      <View key={index} style={styles.diagnosisELem}>
                        <Text style={styles.diagnosisVal}>{item}</Text>
                      </View>
                    ))
                  : "No Diagnosis"}
              </View>
            </View>
            {/* Allergies */}
            <View style={styles.diagnosisContainer}>
              <Text style={styles.allergies}>Allergies</Text>
              <View style={styles.allergiesParent}>
                {clientData?.form1?.knownAllergies?.length != 0
                  ? clientData?.form1?.knownAllergies
                      ?.filter((item) => item.trim() !== "")
                      ?.map((item, index) => (
                        <View key={index} style={styles.allergiesELem}>
                          <Text style={styles.allergiesVal}>{item}</Text>
                        </View>
                      ))
                  : "No Allergy"}
              </View>
            </View>
            {/* Dietary Restrictions */}
            <View style={styles.diagnosisContainer}>
              <Text style={styles.restrictions}>Dietary Restrictions</Text>
              <View style={styles.restrictionsParent}>
                {clientData?.form1?.dietaries?.length != 0 ? (
                  clientData?.form1?.dietaries
                    ?.filter((item) => item != "")
                    ?.map((item, index) => (
                      <View key={index} style={styles.restrictionsELem}>
                        <Text style={styles.restrictionsVal}>{item}</Text>
                      </View>
                    ))
                ) : (
                  <small>No Restriction</small>
                )}
              </View>
            </View>
          </View>
        </View>

        <View style={styles.medicationActivities}>
          {/* Medications */}
          <View wrap={false}>
            <View style={styles.medicationsBg}>
              <Text style={styles.activeMedications}>Active Medications</Text>
            </View>
            <View style={styles.medicationParent}>
              {filteredMedications && filteredMedications?.length > 0
                ? filteredMedications?.map((medicine, index) => (
                    <View key={index} style={styles.medicationBg}>
                      <Text style={styles.medicationVal}>
                        {`${medicine?.medicationName} ${medicine?.dosage} ${medicine?.unit}`}
                      </Text>
                    </View>
                  ))
                : "No Medication"}
            </View>
          </View>
          <View wrap={false}>
            {/* Recent Activity */}
            <View style={styles.activityBg}>
              <Text style={styles.recentActivity}>Recent Activity</Text>
            </View>

            <View style={styles.medicationParent}>
              {activities?.map((item, index) => (
                <View key={index} style={styles.activityCell}>
                  <View style={styles.activityWrapper}>
                    <Text style={styles.infoNameAct}>
                      {item?.module === "note"
                        ? item?.moduleId?.fields?.noteType
                        : item?.module}
                    </Text>
                    <Text style={styles.infoSubHeadingAct}>
                      {moment(item?.createdAt).format("DD/MM/YYYY")}(
                      {moment(item?.createdAt)?.format("hh:mm a")})
                    </Text>
                  </View>
                  {/* <View>
                    {item?.module !== "mar" && (
                      <Text style={styles.activityVal}>
                        <span style={{ textTransform: "capitalize" }}>
                          {item?.module == "note" &&
                            (item?.moduleId?.fields?.noteType ||
                              item?.module)}
                        </span>
                        {item?.createdBy?.name}
                      </Text>
                    )}
                  </View> */}

                  <View>
                    {item?.module == "document" && (
                      <>
                        <Text style={styles.activityVal}>
                          {item?.module == "document"}
                          created by {item?.createdBy?.name}
                        </Text>
                      </>
                    )}
                  </View>

                  <View>
                    {item?.module == "note" && (
                      <>
                        <Text style={styles.activityVal}>
                          {item?.createdBy?.name}
                        </Text>
                        <Text style={styles.activityVal}>
                          {item?.moduleId?.fields?.Description}
                        </Text>
                      </>
                    )}
                  </View>

                  <View>
                    {item?.module === "task" && (
                      <>
                        <Text style={styles.activityVal}>
                          {item?.createdBy?.name}
                        </Text>
                        <Text style={styles.activityVal}>
                          {item?.moduleId?.fieldsDone
                            ?.map((_v) => _v?.field?.name)
                            ?.join(", ")}
                        </Text>
                      </>
                    )}
                  </View>

                  <View>
                    {item?.module == "mar" && (
                      <>
                        <Text style={styles.activityVal}>
                          {item?.createdBy?.name}
                        </Text>
                        <Text style={styles.activityVal}>
                          {item?.moduleId?.dosage}{" "}
                          {item?.moduleId?.form3?.dosageForm} of{" "}
                          {item?.moduleId?.form3?.medicationName}{" "}
                          {item?.moduleId?.form3?.dosage}
                          {item?.moduleId?.form3?.unit} (
                          {item?.moduleId?.form3?.frequency}) marked taken by{" "}
                          {item?.createdBy?.name}
                        </Text>
                      </>
                    )}
                  </View>
                </View>
              ))}
              {activities?.length == 0 && (
                <Text style={styles.addressVal}>No Recent Activity Found</Text>
              )}
            </View>
          </View>
        </View>

        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
      </Page>
    </Document>
  );
};

export default ClientSummaryPDF;
