import { Button, Group, Modal, Text, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { decrypt, firstLetterCap } from "../../utils";
import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import NarcoticBox from "../NarcoticBox";
import { DatePickerInput } from "@mantine/dates";
import { Dropzone } from "@mantine/dropzone";
import { IoIosCloseCircle } from "react-icons/io";
import { updateDiscontinue } from "../../services";

const DiscontinueModal = ({ opened, onclose, data }) => {
  const { classes } = useStyles();
  const [btnLoading, setBtnLoading] = useState(false);
  const { selectedClient } = useSelector((state) => state.mar);
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(new Date());

  const onSave = async () => {
    setBtnLoading(true);
    if (files?.length > 0) {
      // const obj = {
      //   clientId: selectedClient?.id,
      //   medicationId: data?.id,
      //   discontinuedOn: date?.toISOString(),
      //   file: files[0],
      // };
      const _formData = new FormData();
      _formData.append("file", files[0]);
      _formData.append("clientId", selectedClient?.id);
      _formData.append("medicationId", data?.id);
      _formData.append("discontinuedOn", date?.toISOString());
      const res = await updateDiscontinue(_formData, selectedClient?.id);
      if (res) {
        onclose();
        setFiles([]);
        setDate(new Date());
      }
    } else {
       const obj = {
        clientId: selectedClient?.id,
        medicationId: data?.id,
        discontinuedOn: date?.toISOString(),
      };
      const res = await updateDiscontinue(obj, selectedClient?.id);
      if (res) {
        onclose();
        setFiles([]);
        setDate(new Date());
      }
    }
    setBtnLoading(false);
  };

  const formatString = (str) => {
    const words = str?.split("");
    if (words?.length <= 15) {
      return str;
    }
    const startWords = words?.slice(0, 10)?.join("");
    const endWords = words?.slice(-10)?.join("");
    return `${startWords} ... ${endWords}`;
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          <Modal
            className={classes.medicationModal}
            opened={opened}
            size={1200}
            onClose={() => {
              onclose();
            }}
            shadow="md"
            overlayProps={{ blur: 3 }}
            radius={"lg"}
            zIndex={1000}
            centered
          >
            <CloseCircle
              onclose={() => {
                onclose();
              }}
            />
            <div className={classes.header}>
              <h2>Discontinue Medication</h2>
            </div>
            <div className={classes.mainContentWarpper}>
              <div className={classes.mainContent}>
                <div className={classes.details}>
                  <div className={classes.head}>
                    <div className={classes.headLeft}>
                      <div
                        className={classes.box}
                        style={{
                          background: "rgba(151, 151, 151, 0.25)",
                        }}
                      >
                        <h3>
                          {data?.medicineRemaining
                            ? data?.medicineRemaining
                            : data?.medicineRemaining}
                        </h3>
                        <p>
                          of {data?.totalMedicine} <br />
                          Remaining
                        </p>
                      </div>
                      <div>
                        <div className={`${classes.detailTitle}`}>
                          <h2>{firstLetterCap(data?.medicationName)}</h2>
                          {data?.isNarcotic === "Yes" && <NarcoticBox />}
                        </div>
                        <p style={{ color: "#979797" }}>
                          <span className={classes.span1}>
                            {data?.frequency}{" "}
                          </span>{" "}
                          <span className={classes.span2}>
                            {data?.dosage}
                            {data?.unit} {data?.ingestionMode}{" "}
                            {data?.dosageForm}
                          </span>{" "}
                          <span className={classes.span3}>
                            {data?.timeSlot}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={classes.personInfo}>
                      <img src={decrypt(selectedClient?.imageurl)} />
                      <h3>{selectedClient?.name}</h3>
                      <p>{selectedClient?.id?.slice(0, 5)?.toUpperCase()}</p>
                    </div>
                  </div>

                  {/* <div className={classes.orderInfo}>
                    <p>Please update order information.</p>
                  </div> */}
                  <div className={classes.orderInfoWarning}>
                    <p>Previously administered and missed medications will not be editable after discontinuing.</p>
                  </div>

                  <div className={classes.uploadAndDateBox}>
                    <div className={classes.dropzone}>
                      <Dropzone
                        onDrop={(newFiles) => setFiles([...files, ...newFiles])}
                        multiple={false}
                        onReject={(files) =>
                          console.log("rejected files", files)
                        }
                        // maxSize={3 * 1024 ** 2}
                      >
                        <Group
                          position="center"
                          spacing="xl"
                          style={{ minHeight: rem(220), pointerEvents: "none" }}
                        >
                          <Dropzone.Accept></Dropzone.Accept>
                          <Dropzone.Reject></Dropzone.Reject>
                          <Dropzone.Idle></Dropzone.Idle>

                          <div className={classes.column}>
                            <img
                              src="/assets/images/drag.png"
                              alt="Drag & Drop"
                              className={classes.dragImage}
                            />
                            <p className={classes.uploadText}>
                              <span>Upload</span> Doctor's Note
                            </p>
                          </div>
                        </Group>
                      </Dropzone>
                      {/* {files?.length < 1 && <p></p>} */}
                      {files?.length > 0 && (
                        <div className={classes.fileNameBox}>
                          <p>{formatString(files[0]?.name)}</p>
                          <IoIosCloseCircle
                            color="rgba(255, 72, 72, 0.18)"
                            size={25}
                            style={{ cursor: "pointer" }}
                            onClick={() => setFiles([])}
                          />
                        </div>
                      )}
                    </div>
                    <div className={classes.datePicker}>
                      <DatePickerInput
                        label="Discontinue Date"
                        size="xl"
                        value={date}
                        onChange={(date) => {
                          setDate(new Date(date));
                        }}
                        placeholder="mm/dd/yyyy"
                        rightSection={
                          <img
                            style={{ width: "30px" }}
                            src={"/assets/images/calendar-icon.png"}
                            alt="Icon"
                          />
                        }
                        withAsterisk
                        styles={{ rightSection: { pointerEvents: "none" } }}
                      />
                    </div>
                  </div>
                  {/* Save Button */}
                  <div className={classes.saveButton}>
                    <Button
                      // disabled={files?.length === 0}
                      loading={btnLoading}
                      onClick={onSave}
                    >
                      <img src="/assets/images/save-icon.png" alt="" />
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default DiscontinueModal;
