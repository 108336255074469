import React from "react";

export const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#FFBA45"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.146 9.963a1.668 1.668 0 00-.302 0A4.052 4.052 0 016.93 5.902 4.067 4.067 0 0111 1.832a4.066 4.066 0 01.147 8.13zM6.562 13.348c-2.218 1.485-2.218 3.905 0 5.38 2.521 1.688 6.655 1.688 9.176 0 2.218-1.484 2.218-3.904 0-5.38-2.512-1.678-6.646-1.678-9.176 0z"
      ></path>
    </svg>
  );
};
