import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addFacilityLoading: false,
  facilities: [],
  facilityTypes: [],
  allFacilities: [],
  allFacilityTypes: [],
};

export const facilityState = createSlice({
  name: "facility",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setLoadingAddFacility(state, action) {
      return {
        ...state,
        addFacilityLoading: action?.payload,
      };
    },
    setFacilities(state, action) {
      return {
        ...state,
        loading: false,
        facilities: action?.payload,
        addFacilityLoading: false
      };
    },
    setFacilityTypes(state, action) {
      return {
        ...state,
        loading: false,
        addFacilityLoading: false,
        facilityTypes: action?.payload
      };
    },

    setAllFacilities(state, action) {
      return {
        ...state,
        loading: false,
        allFacilities: action?.payload,
        addFacilityLoading: false
      };
    },
    setAllFacilityTypes(state, action) {
      return {
        ...state,
        loading: false,
        addFacilityLoading: false,
        allFacilityTypes: action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const {
  setLoading,
  setFacilities,
  setLoadingAddFacility,
  setFacilityTypes,
  setAllFacilityTypes,
  setAllFacilities
} = facilityState.actions;
export default facilityState.reducer;
