import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getNotificationLoading: false,
  total: 0,
  notiCount: 0,
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationLoading(state, action) {
      return {
        ...state,
        getNotificationLoading: action?.payload,
      };
    },
    setNotifications(state, action) {
      return {
        ...state,
        getNotificationLoading: false,
        notifications: action.payload.notifications,
        total: action.payload.total,
        notiCount: action.payload.notiCount,
      };
    },
    setNotificationsCount(state, action) {
      return {
        ...state,
        notiCount: action.payload.notiCount,
      };
    },
  },
});

export const {
  setNotificationLoading,
  setNotifications,
  setNotificationsCount,
} = notificationSlice.actions;
export default notificationSlice.reducer;
