import React from "react";

import {
  Button,
  Card,
  Checkbox,
  Menu,
  Modal,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import useStyles from "./style";
// import { addSubTask } from "../../services/task";
import { IconPlus } from "@tabler/icons-react";
import { IoIosClose, IoMdCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  addTaskType,
  getFields,
  getSubTask,
  getTaskTypes,
  updateTaskType
} from "../../services/task";
import { showError } from "../../utils";
import swal from "sweetalert";

const AddTaskTypeModal = ({ opened, close, edit, data }) => {
  const { classes } = useStyles();
  const ref = useRef();
  const { modalLoading, subTask, fields, taskTypes } = useSelector(
    (state) => state.task
  );

  const [allSubTask, setAllSubTask] = useState([]);
  const [allTaskFields, setAllTaskFields] = useState([]);

  const [SubTask, setSubTask] = useState("");
  const [taskField, setTaskField] = useState([]);
  const [singleTaskField, setSingleTaskField] = useState("");

  const [list, setList] = useState([]);
  const [checked, setChecked] = useState([]);

  const [showCustomList, setShowCustomList] = useState(false);

  const [editObjIndex, setEditObjIndex] = useState(-1);

  useEffect(() => {
    if (subTask?.length > 0) {
      setAllSubTask(
        subTask?.map((data) => {
          return {
            value: JSON.stringify({ name: data?.name, _id: data?._id }),
            label: data?.name,
          };
        })
      );
    }
  }, [subTask]);

  useEffect(() => {
    if (fields?.length > 0) {
      setAllTaskFields(
        fields?.map((data) => {
          return {
            value: JSON.stringify(data),
            label: data?.name,
          };
        })
      );
    }
  }, [fields]);

  useEffect(() => {
    if (opened && edit && data) {
      form.setFieldValue("name", data?.name);
      form.setFieldValue("description", data?.description);
      const _list = data?.subTasks?.map((v) => {
        return {
          subTask: JSON.stringify(v?.task),
          taskField: v?.fields?.map((x) => JSON.stringify(x)),
        };
      });
      setList(_list);

      setAllSubTask(
        subTask?.map((data) => {
          return {
            value: JSON.stringify(data),
            label: data?.name,
          };
        })
      );
      setAllTaskFields(
        fields?.map((data) => {
          return {
            value: JSON.stringify(data),
            label: data?.name,
          };
        })
      );
    }
    getTaskTypes("all", false);
  }, [opened]);

  const resetForm = () => {
    form?.reset();
    close();
    // setSaveValues([]);
    setTaskField([]);
  };

  const Submit = async (values) => {
    if (list?.length > 0) {
      const newList = list?.map((v) => {
        const task = JSON.parse(v?.subTask);
        return {
          task: {
            name: task?.name,
            _id: task?._id,
          },
          fields: v?.taskField?.map((x) => {
            const field = JSON.parse(x);
            return {
              name: field?.name,
              _id: field?._id,
            };
          }),
        };
      });
      const finalObj = {
        name: values?.name,
        description: values?.description ? values?.description : "",
        subTasks: newList,
      };

      if (edit) {
        let obj = {
          id: data?.id,
          ...finalObj,
        };
        await updateTaskType(obj, resetForm);
      } else {
        await addTaskType(finalObj, () => {});
        // await addTaskType(finalObj, resetForm);
      }
    } else {
      showError("Please Add Task List First", 400);
    }
    // if (SaveValues.length > 0) {
    //   if (edit) {
    //     let obj = {
    //       id: data?.id,
    //       name: form.getInputProps("name").value,
    //       description: form.getInputProps("description").value,
    //       subTasks: taskList?.map((v) => ({
    //         task: v?.SubTask?._id,
    //         fields: v?.taskField?.map((vv) => vv?._id),
    //       })),
    //     };

    //     await updateTaskType(obj, resetForm);
    //   } else {
    //     await addTaskType(
    //       {
    //         name: values?.name,
    //         description: values?.description,
    //         subTasks: SaveValues,
    //       },
    //       resetForm
    //     );
    //   }
    // } else {
    //   showError("Please Add Task List First", 400);
    // }
  };

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      general: false,
    },
    validate: {
      name: (value) => (value ? null : "Required"),
    },
  });

  useEffect(() => {
    if (opened) {
      getSubTask();
      getFields();
    }
  }, [opened]);

  const resetListItem = () => {
    setSubTask("");
    setTaskField([]);
    setSingleTaskField("");
    !showCustomList && setShowCustomList(false);
  };

  const deleteList = (index) => {
    const temp = [...list];
    temp.splice(index, 1);
    setList(temp);
  };

  const setExistingList = (subTask, taskField, id) => {
    if (checked?.includes(id)) {
      setList((pre) => pre?.filter((v) => v?._id !== id));
      setChecked((pre) => [...pre]?.filter((v) => v !== id));
    } else {
      setChecked((pre) => [...pre, id]);
      setList((pre) => [
        ...pre,
        {
          subTask: subTask,
          taskField: taskField,
          _id: id,
        },
      ]);
    }
  };

  const handleEditList = (index) => {
    setEditObjIndex(index);
    setShowCustomList(true);
    const obj = JSON.parse(list[index]?.subTask);
    setAllSubTask((pre) => [
      ...pre,
      {
        value: JSON.stringify({ name: obj?.name, _id: obj?._id }),
        label: obj?.name,
      },
    ]);
    setSubTask(JSON.stringify({ name: obj?.name, _id: obj?._id }));
    setTaskField(list[index]?.taskField);
  };

  const addToList = () => {
    if (SubTask?.trim() != "" && (singleTaskField || taskField?.length > 0)) {
      const _taskFields =
        singleTaskField !== "" ? [...taskField, singleTaskField] : taskField;
      if (editObjIndex !== -1) {
        const tempList = [...list];
        tempList[editObjIndex] = {
          subTask: SubTask,
          taskField: _taskFields,
        };
        setList(tempList);
        setEditObjIndex(-1);
        resetListItem();
        setShowCustomList(false);
      } else {
        setList((pre) => [
          ...pre,
          {
            subTask: SubTask,
            taskField: _taskFields,
          },
        ]);
        resetListItem();
      }
    }
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={"xl"}
      onClose={() => {
        close();
        form?.reset();
        setList([]);
        resetListItem();
      }}
      radius={15}
      shadow="md"
      overlayProps={{ blur: 3 }}
      title={
        <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
          {edit ? "Edit Task Type" : "Add Task Type"}
        </Text>
      }
      centered
      zIndex={1000}
    >
      <form ref={ref} onSubmit={form?.onSubmit((values) => Submit(values))}>
        <div
          style={{
            padding: "1rem",
            border: "1px solid #CBCBCB66",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderRadius: "0.25rem",
            borderBottom: "none !important",
          }}
        >
          <TextInput
            label={"Name"}
            size="md"
            className={classes.myfont}
            placeholder="Name"
            {...form.getInputProps("name")}
          />
          <Textarea
            label={"Description"}
            size="md"
            className={classes.myfont}
            placeholder="Description"
            {...form.getInputProps("description")}
          />
          <div className={classes.buttonBox}>
            <ExistingListMenu
              data={taskTypes}
              setExistingList={setExistingList}
              checked={checked}
              setChecked={setChecked}
            />
            {/* <MultiSelect
              checkIconPosition="right"
              data={taskTypes?.map(()=>{
                return {
                  value:
                }
              })}
              dropdownOpened
              pb={150}
              label="Control check icon"
              placeholder="Pick value"
              defaultValue={["React"]}
            /> */}
            <Button
              variant="filled"
              color="yellow"
              leftIcon={<IconPlus />}
              onClick={() => setShowCustomList(!showCustomList)}
            >
              Custom List
            </Button>
          </div>
        </div>

        <Card
          sx={{
            paddingTop: 20,
            borderTopWidth: 1,
            borderTopColor: "#CBCBCB66",
            borderTopStyle: "solid",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // backgroundColor: "#8492a7",
          }}
          shadow={"xs"}
        >
          <div className={classes.toDoHeadingBox}>
            <h2>TO DO LISTS</h2>
          </div>
          <ScrollArea w={715} type="always">
            <div className={classes.listCardBox}>
              {list?.map((v, i) => (
                <ListCard
                  key={i}
                  index={i}
                  data={v}
                  deleteList={deleteList}
                  handleEditList={handleEditList}
                />
              ))}
            </div>
          </ScrollArea>
        </Card>

        {showCustomList && (
          // <Card
          //   sx={{
          //     paddingTop: 20,
          //     borderTopWidth: 1,
          //     borderTopColor: "#CBCBCB66",
          //     borderTopStyle: "solid",
          //     display: "flex",
          //     flexDirection: "column",
          //     gap: "20px",
          //   }}
          //   shadow={"xs"}
          // >
          <div
            style={{
              padding: "1rem",
              border: "1px solid #CBCBCB66",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              borderRadius: "0.25rem",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <div className={classes.customListBox}>
              <Select
                className={classes.selectBox}
                label=""
                data={allSubTask}
                value={SubTask}
                placeholder="Untitled List"
                nothingFound="Nothing found"
                searchable
                creatable
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={(query) => {
                  const item = {
                    value: JSON.stringify({ name: query, _id: null }),
                    label: query,
                  };
                  setAllSubTask((current) => [...current, item]);
                  return item;
                }}
                onChange={(e) => {
                  setSubTask(e);
                }}
              />

              {taskField?.map((v, i) => {
                return (
                  <div className={classes.customListRow} key={i}>
                    <Checkbox checked={false} disabled />
                    <Select
                      className={classes.selectBox}
                      label=""
                      value={v}
                      data={[
                        {
                          label: JSON.parse(v)?.name,
                          value: v,
                        },
                      ]}
                      placeholder="Add Action"
                      nothingFound="Nothing found"
                      searchable
                      creatable
                      getCreateLabel={(query) => `+ Create ${query}`}
                      disabled
                    />
                    <Button
                      style={{
                        paddingRight: 0,
                        marginLeft: "200px",
                        backgroundColor: "transparent",
                      }}
                      variant="light"
                      color="yellow"
                      onClick={() => {
                        const temp = [...taskField];
                        temp.splice(i, 1);
                        setTaskField(temp);
                      }}
                      leftIcon={<IoIosClose size={30} />}
                    />
                  </div>
                );
              })}

              <div className={classes.customListRow}>
                <Checkbox checked={false} disabled />
                <Select
                  className={classes.selectBox}
                  label=""
                  value={singleTaskField}
                  data={allTaskFields}
                  placeholder="Add Action"
                  nothingFound="Nothing found"
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    const item = {
                      value: JSON.stringify({ name: query, _id: null }),
                      label: query,
                    };
                    setSingleTaskField("");
                    return item;
                  }}
                  onChange={(e) => {
                    setSingleTaskField(e);
                  }}
                />
                <Button
                  style={{ paddingRight: 0, marginLeft: "200px" }}
                  variant="filled"
                  color="yellow"
                  onClick={() => {
                    if(SubTask !== "" && singleTaskField !== ""){
                      setTaskField((pre) => [...pre, singleTaskField]);
                      setSingleTaskField("");
                    }else{
                      swal({
                        title: `Task item required`,
                        text: "Please select or click create to add item to the list.",
                        dangerMode: true,
                      });
                    }
                  }}
                  leftIcon={<IconPlus />}
                />
              </div>
            </div>

            <div>
              <Button color="yellow" onClick={addToList} variant="filled">
                {editObjIndex !== -1 ? "Update Task" : "Add Task"}
              </Button>
            </div>
          </div>
          //  </Card>
        )}

        <Card
          sx={{
            paddingTop: 20,
            borderTopWidth: 1,
            borderTopColor: "#CBCBCB66",
            borderTopStyle: "solid",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // zIndex: -1,
          }}
          shadow={"xs"}
        >
          <Button
            color="yellow"
            loading={modalLoading}
            type="submit"
            variant="filled"
          >
            Save
          </Button>
        </Card>
      </form>
    </Modal>
  );
};

const ExistingListMenu = ({ data, setExistingList, checked }) => {
  const { classes } = useStyles();
  return (
    <Menu shadow="md" width={250}>
      <Menu.Target>
        <Button
          className={classes.existingListButton}
          variant="filled"
          color="yellow"
          leftIcon={<IconPlus />}
        >
          Existing List
        </Button>
      </Menu.Target>

      <Menu.Dropdown style={{ overflow: "auto", height: "200px" }}>
        {data?.map((v, i) =>
          v?.subTasks?.map((x, index) => {
            return (
              <Menu.Item
                key={index}
                onClick={() => {
                  setExistingList(
                    JSON.stringify(x?.task),
                    x?.fields?.map((v) => JSON.stringify(v)),
                    x?.task?._id
                  );
                }}
              >
                <div className={classes.dropdownItem}>
                  <p style={{ margin: 0 }}>
                    {x?.task?.name} ({x?.fields?.length})
                  </p>
                  <Checkbox
                    key={i}
                    disabled
                    checked={checked?.includes(x?.task?._id)}
                  />
                </div>
              </Menu.Item>
            );
          })
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

const ListCard = ({ data, index, deleteList, handleEditList, setChecked }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.listCard} onClick={() => handleEditList(index)}>
      <div className={classes.closeBtn}>
        <IoMdCloseCircle
          color="orange"
          size={22}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            deleteList(index);
            setChecked((pre) => pre?.filter((x) => x !== data?._id));
          }}
        />
      </div>
      {/* <p className={classes.index}>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleEditList(index)}
        >
          {index + 1}
        </span>
      </p> */}
      <h3 className={classes.title}>{JSON.parse(data?.subTask)?.name}</h3>
      <div className={classes.tilteAndCheckbox}>
        {data?.taskField?.map((v, i) => {
          return (
            <Checkbox
              key={i}
              checked={false}
              disabled
              label={JSON.parse(v)?.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddTaskTypeModal;
