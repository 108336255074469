import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedNoteType: "",
  loading: false,
  addNoteLoading: false,
  loadingNote_Types: false,

  notes: [],
  noteTypes: [],
  allNotesTypes: [],
  fieldType: [],
  notesAgainstTypes: []
};

export const noteState = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setLoadingNote_Types(state, action) {
      return {
        ...state,
        loadingNote_Types: action?.payload
      };
    },
    setAddNoteLoading(state, action) {
      return {
        ...state,
        addNoteLoading: action?.payload
      };
    },
    setNotes(state, action) {
      return {
        ...state,
        loading: false,
        addNoteLoading: false,
        notes: action?.payload
      };
    },
    setNoteTypes(state, action) {
      return {
        ...state,
        loading: false,
        noteTypes: action?.payload
      };
    },
    setAllNotesTypes(state, action) {
      return {
        ...state,
        loading: false,
        addNoteLoading: false,
        allNotesTypes: action?.payload
      };
    },
    setFieldTypes(state, action) {
      return {
        ...state,
        loading: false,
        fieldType: action?.payload
      };
    },
    setNoteType(state, action) {
      return {
        ...state,
        selectedNoteType: action?.payload
      };
    },
    setNotesAgainstType(state, action) {
      return {
        ...state,
        notesAgainstTypes: action?.payload,
        loadingNote_Types: false
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const {
  setLoading,
  setAddNoteLoading,
  setNotes,
  setNoteTypes,
  setFieldTypes,
  setNoteType,
  setAllNotesTypes,
  setLoadingNote_Types,
  setNotesAgainstType
} = noteState.actions;
export default noteState.reducer;
