import { createStyles } from "@mantine/core";
import moment from "moment";
import { getDailyPriorityColor } from "../../utils";

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "190px",
    paddingBlock: "20px",
    background: "#fff",
    borderBottom: "6px solid transparent",
    gap: "10px",
    "& h3": {
      margin: "0",
      fontSize: "1vw",
      fontWeight: 400,
      "@media (max-width: 1440px)": {
        fontSize: "12px",
      },
    },
  },
  active: {
    borderBottom: "6px solid #FFBA45 !important",
  },
  box: {
    width: "80%",
    height: "3px !important",
  },
}));

const TodayDateCard = ({ slots, data }) => {
  const { classes } = useStyles();

  const getCurentTime = () => {
    const currentTime = moment();
    const startTime = moment(slots?.startTime, "HH");
    const endTime = moment(slots?.endTime, "HH:mm");
    const isBetween = currentTime.isBetween(startTime, endTime);
    return isBetween;
  };

  return (
    <div className={`${classes.card} ${getCurentTime() ? classes.active : ""}`}>
      <div
        className={classes.box}
        style={{ backgroundColor: getDailyPriorityColor(data) }}
      />
      <h3>{slots?.title}</h3>
    </div>
  );
};

export default TodayDateCard;
