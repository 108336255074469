import React, { useEffect } from "react";
import {
  ActionIcon,
  Avatar,
  Box,
  Group,
  Menu,
  Switch,
  Text,
  createStyles,
} from "@mantine/core";
import { IconDownload, IconEdit, IconTrash } from "@tabler/icons-react";
import { FiExternalLink } from "react-icons/fi";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import swal from "sweetalert";
import { editPatient, removePatient } from "../../services/patient";
import PatientModal from "../PatientModal";
import { useDispatch, useSelector } from "react-redux";
import { setAddPatientLoading } from "../../store/reducers/patient";
import { decrypt, formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { setSelectedClient } from "../../store/reducers/mar";
import moment from "moment";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import ClientSummaryPDF from "../ClientSummaryPDF/ClientSummaryPDF";
import { getActivites } from "../../services/activity";
import { saveAs } from "file-saver";

const useStyles = createStyles((theme) => ({
  border: {
    borderLeft: "1px solid #F2F2F2",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    gap: 30,
    height: "100%",
  },
  marginTop: {
    marginTop: "15px",
  },
}));

const PatientTableRow = ({ row, onEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { classes } = useStyles();
  const [active, setActive] = useState(row.active);
  const [opened, { open, close }] = useDisclosure(false);
  const [view, setView] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;

  const customId = row?.id;
  const clientId = customId?.slice(-6);

  // functions
  const remove = async () => {
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });
    if (willDelete) {
      try {
        await removePatient(row?.id);
        swal("Success", "Patient remove successfully", "success");
      } catch (error) {
        swal("Error", "Failed to remove the patient", "error");
      }
    }
  };

  const handleClientSelect = (obj) => {
    dispatch(setSelectedClient(obj));
    localStorage.setItem("selectedClient", JSON.stringify(obj));
  };

  const updateStatus = async (event) => {
    setActive(event.target.checked);
    editPatient({ id: row?.id, active: event?.target?.checked }, false);
  };

  function handleRowClick(event) {
    // setView(true);
    // open();
    handleClientSelect(row);
    navigate("/home/client", { state: row?.id });
  }

  const _getActivites = async () => {
    try {
      const data = await getActivites(customId);
      // setActivities(data);
      return data;
    } catch (error) {
      console.error("Failed to fetch activities:", error);
    }
  };

  const handlePdfDownload = async (fileName) => {
    setPdfLoading(true);
    const activitiesRes = await _getActivites();
    const blob = await pdf(
      <ClientSummaryPDF clientData={row} activities={activitiesRes} />
    ).toBlob();
    saveAs(blob, fileName);
    setPdfLoading(false);
  };

  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={handleRowClick}>
        <Box className={classes.dFlex}>
          <Avatar radius="xl" size="lg" src={decrypt(row?.imageurl)} />
          <Box>
            <Text fz={20}>{row?.name || "-"} </Text>
            <Text style={{ textTransform: "uppercase" }} color="dimmed">
              {clientId || "-"}
            </Text>
          </Box>
        </Box>
      </td>
      <td onClick={handleRowClick}>
        {row?.facilityId?.map((fac) => fac?.name)?.join(", ")}
      </td>
      <td id="dob" onClick={handleRowClick}>
        {moment(row.form1?.admissionDate).format("MM/DD/YYYY") || "-"}
      </td>
      <td onClick={handleRowClick}>
        {moment(row.form1?.dob).format("MM/DD/YYYY") || "-"}
      </td>
      <td onClick={handleRowClick}>{row.form2?.emergencyContactName || "-"}</td>
      <td onClick={handleRowClick}>
        {row.form2?.emergencyContactPhoneNumber || "-"}
      </td>
      <td className={classes.dFlex}>
        <Switch
          className={`${classes.StaffSwitchBtn} ${classes.marginTop}`}
          size="md"
          color="yellow"
          checked={active}
          onChange={updateStatus}
          disabled={
            profile?.user?.type === "staff" && profile?.user?.role !== "admin"
          }
        />
        <Group>
          <PatientModal
            opened={opened}
            close={close}
            title={"Edit Individual"}
            data={row}
            view={view}
            setView={setView}
          />
          <Menu shadow="md" position="left" zIndex={"1000"}>
            <Menu.Target>
              <ActionIcon className={classes.marginTop}>
                <BiDotsVerticalRounded
                  id="vertical-dots"
                  size={20}
                  color="#000"
                />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                id="open-link"
                icon={<FiExternalLink size={14} />}
                onClick={() => {
                  navigate("/home/client", { state: row?.id });
                  handleClientSelect(row);
                }}
              >
                Open
              </Menu.Item>
              {/* <Menu.Item icon={<IconDownload size={14} />}>Download</Menu.Item> */}

              {/* <PDFDownloadLink
                style={{ textDecoration: "none" }}
                document={
                  <ClientSummaryPDF clientData={row} activities={activities} />
                }
                fileName={`${row?.form1?.name ?? "Client Summary"}.pdf`}
              >

                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Menu.Item
                      disabled={true}
                      icon={<IconDownload size={14} />}
                    >
                      Download
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      disabled={false}
                      icon={<IconDownload size={14} />}
                    >
                      Download
                    </Menu.Item>
                  )
                }
              </PDFDownloadLink> */}

              <Menu.Item
                disabled={pdfLoading}
                icon={<IconDownload size={14} />}
                onClick={() =>
                  handlePdfDownload(
                    `${row?.form1?.name ?? "Client Summary"}.pdf`
                  )
                }
              >
                Download
              </Menu.Item>

              {/* <Menu.Item icon={<IconEdit size={14} />} onClick={open}> */}
              <Menu.Item
                icon={<IconEdit size={14} />}
                onClick={() => onEdit(row)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size={14} />}
                color="red"
                onClick={remove}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </td>
    </tr>
  );
};

export default PatientTableRow;
