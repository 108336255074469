import React from "react";

export const TwoIconSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.84 70V62.896C33.84 60.5707 34.192 58.5973 34.896 56.976C35.6 55.3547 36.6453 53.9893 38.032 52.88C39.44 51.7493 41.2 50.7787 43.312 49.968L51.824 46.704C53.68 45.9573 55.0453 45.04 55.92 43.952C56.816 42.864 57.264 41.52 57.264 39.92C57.264 38.32 56.6773 36.976 55.504 35.888C54.352 34.7787 52.6987 34.224 50.544 34.224C48.4747 34.224 46.736 34.7467 45.328 35.792C43.9413 36.816 43.1413 38.32 42.928 40.304H34.544C34.608 37.4667 35.344 35.1093 36.752 33.232C38.16 31.3547 40.048 29.9573 42.416 29.04C44.784 28.1013 47.4507 27.632 50.416 27.632C53.2107 27.632 55.7387 28.1653 58 29.232C60.2613 30.2773 62.0533 31.7493 63.376 33.648C64.72 35.5253 65.392 37.7227 65.392 40.24C65.392 42.3733 64.9973 44.2507 64.208 45.872C63.44 47.4933 62.2667 48.912 60.688 50.128C59.1093 51.344 57.1147 52.4213 54.704 53.36L46.128 56.688C44.464 57.328 43.2587 58.0427 42.512 58.832C41.7653 59.6 41.392 60.6133 41.392 61.872V63.088H65.136V70H33.84Z"
        fill="#FFBA45"
      />
      <rect x="0.5" y="0.5" width="99" height="99" rx="49.5" stroke="#FFBA45" />
    </svg>
  );
};
