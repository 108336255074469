import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import swal from "sweetalert";
import store from "../store";
import moment from "moment";
import mixpanel from "mixpanel-browser";
export const showError = (message, status, navigate = true) => {
  // console.log("error", message);
  if (status === 401) {
    if (navigate) {
      swal("Session Expired", "", "error");
      removeCookie("token");
      window.location.href = "/login";
    } else {
      swal("Oops! Something went wrong", message || "", "error");
    }
  } else swal("Oops! Something went wrong", message || "", "error");
};

export const showInfo = (message) => {
  swal("Info", message || "", "success");
};

export const setCookie = (value, key) => {
  Cookies.set(key, value);
};

export const getCookie = (key) => Cookies.get(key);

export const removeCookie = (key) => Cookies.remove(key);

// browser Inactive sessions expire after 30 minutes
// let inactivityTimeout;
// function resetTimer() {
//   clearTimeout(inactivityTimeout);
//   inactivityTimeout = setTimeout(logOutUser, 30 * 60 * 1000); // 30 minutes
// }
// function logOutUser() {
//   removeCookie("token");
//   mixpanel.track("Logout");
//   mixpanel.reset();
//   window.location.href = '/login';
// }
// ['mousemove', 'keydown', 'click'].forEach(event => {
//   window.addEventListener(event, resetTimer);
// });
// resetTimer();


export const formatDate = (date) => {
  // let months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  ///hamza changes

  let months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  if (
    `${date}`.split(":")?.length === 3 &&
    `${date}`.split("-")?.length === 3
  ) {
    const parsedDate = new Date(date);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();
    // return `${months[month]} ${day}, ${year}`;

    //hamza changes

    return `${months[month]}-${day}-${year}`;
    //  return `${day}-${months[month]}-${year}`;
  } else {
    return date;
  }
};

export const formatTime = (date) => {
  if (
    `${date}`.split(":")?.length === 3 &&
    `${date}`.split("-")?.length === 3
  ) {
    return moment(date)?.format("hh:mm a");
  } else {
    return date;
  }
};

export const dateFormat2 = (inputDate) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObject = new Date(inputDate);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();

  const formattedDate = `${months[monthIndex]} ${day
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
};

export const isSubscriptionExpired = () => {
  return store?.getState()?.profile?.profile?.subscriptionExpired;
};

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const getOptions = (optionType, custom, fieldName) => {
  console.log("🚀 ~ getOptions ~ fieldName:", fieldName)
  console.log("🚀 ~ getOptions ~ custom:", custom)
  console.log("🚀 ~ getOptions ~ optionType:", optionType)
  let othersArray = [];
  if (custom && Array.isArray(custom)) {
    var classField = custom?.find(
      (item) => item?.options === "Other" && item?.field === fieldName
    );
    if (classField) {
      othersArray = classField?.others
      ? classField?.others?.split(",")?.map((item) => item?.trim())
      : [];
    }
  }
  console.log("🚀 ~ getOptions ~ othersArray:", othersArray)

  let { allFacilities, allFacilityTypes } = store?.getState()?.facility;
  let { allPatients } = store?.getState()?.patient;
  let { staff } = store?.getState()?.staff;
  console.log("🚀 ~ getOptions ~ staff:", staff)

  // if (otherOptions) othersArray = otherOptions;

  let obj = {
    facilities: allFacilities?.facilities
      ? allFacilities?.facilities?.map((value) => ({
          value: value?.id,
          label: value?.name,
        }))
      : [],
    clients: allPatients
      ? allPatients?.map((value) => ({
          value: value?.id,
          label: value?.name,
          disabled: !value?.active,
        }))
      : [],
    staff: staff?.staffs
      ? staff?.staffs?.map((value) => ({
          value: value?.id,
          label: value?.type === "staff" ? value?.name : `${value?.name} (admin)`,
        }))
      : [],
    "facility types": allFacilityTypes?.ftypes
      ? allFacilityTypes?.ftypes?.map((value) => ({
          value: value?.id,
          label: value?.name,
        }))
      : [],

    other: othersArray
      ? othersArray?.map((value) => ({
          value: value,
          label: value,
        }))
      : [],
  };

  return obj[optionType?.toLowerCase()];
};

let secretKey = "ogiso-life-secret";
export let noteType_Client = "Client Name";
export let noteType_Client_Options = "Clients";

export const decrypt = (cipher, parse = true) => {
  try {
    var bytes = CryptoJS.AES.decrypt(cipher, secretKey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    // let secretKey = process.env.REACT_APP_ENCRYPT_SECRET;
    if (parse) return JSON.parse(originalText);
    else return originalText;
  } catch (e) {
    return originalText;
  }
};

export const encrypt = (data) => {
  // let secretKey = process.env.REACT_APP_ENCRYPT_SECRET;
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return ciphertext;
};

export const sortByDateAndTime = (arr) => {
  const newArr = arr?.length > 0 ? [...arr] : [];

  newArr?.sort((a, b) => {
    const dateA = moment(a?.date);
    const timeA = moment(a?.timeSlot ? a?.timeSlot : a?.date, "hh:mm A");
    const combinedDateTimeA = moment({
      year: dateA.year(),
      month: dateA.month(),
      date: dateA.date(),
      hour: timeA.hours(),
      minute: timeA.minutes(),
    });

    const dateB = moment(b?.date);
    const timeB = moment(b?.timeSlot ? b?.timeSlot : b?.date, "hh:mm A");
    const combinedDateTimeB = moment({
      year: dateB.year(),
      month: dateB.month(),
      date: dateB.date(),
      hour: timeB.hours(),
      minute: timeB.minutes(),
    });

    return combinedDateTimeB - combinedDateTimeA;
  });

  return newArr;
};

export const getPriorityColor = (date, data) => {
  // Missed #eb7a74
  // Unable to Take #feff80
  // Refused #c7daf5
  // PRN #a03399
  // Taken #4fa80d
  // Out of Medication #000
  // upcoming #979797
  const _dateFormat = "YYYY-MM-DD";
  const types = data
    ?.filter(
      (x) =>
        moment(date).format(_dateFormat) === moment(x?.date).format(_dateFormat)
    )
    ?.map((v) => v?.medicationType);

  return checkType(types);
};

export const getDailyPriorityColor = (data) => {
  const types = data?.map((v) => v?.medicationType);
  return checkType(types);
};

const checkType = (types) => {
  if (types?.includes("Missed")) {
    return "#eb7a74";
  } else if (types?.includes("Unable to Take")) {
    return "#feff80";
  } else if (types?.includes("Refused")) {
    return "#c7daf5";
  } else if (types?.includes("PRN")) {
    return "#a03399";
  } else if (types?.includes("Taken")) {
    return "#4fa80d";
  } else if (types?.includes("Out of Medication")) {
    return "#000";
  } else if (types?.includes("upcoming")) {
    return "#979797";
  } else {
    return "#fff";
  }
};

export const firstLetterCap = (str) => {
  return str ? str?.[0].toUpperCase() + str?.slice(1) : str;
};
