import { createStyles, getStylesRef, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: 17,
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    background: 'red'
  },
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black
    }
  },
  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    flexWrap: "nowrap",
    fontSize: rem(20),
    // border: "1px solid red",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: "5px 10px",
    borderRadius: 12,
    fontWeight: 500,
    marginBottom: theme.spacing.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black
      }
    }
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: "#232323",
      color: "white",
      [`& .${getStylesRef("icon")}`]: {
        color: "white"
        // background: "white",
      }
    }
  },
  chevron: {
    transition: "transform 200ms ease"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%',
    flex: 1
  }
}));

export default useStyles;
