import { createStyles, Container, Box } from "@mantine/core";

import AddButton from "../../../components/AddButton";
import ReportModal from "../../../components/ReportModal";
import ReportTable from "../../../components/ReportTable";
import Topbar from "../../../components/Topbar";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { getReports } from "../../../services/report";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import { getAllNoteTypes } from "../../../services/note";
import { getAllStaff } from "../../../services/staff";
import { getAllFacilitiesModal, getAllFacilityTypes } from "../../../services";
import { getAllPatientsModal } from "../../../services/patient";
import { getTaskTypes } from "../../../services/task";
import { TrackPageView } from "../../../config/mixpanelConfig";


const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important"
    }
  },
  mwd: {
    width: "100%"
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end"
  }
}));
const Reports = (props) => {
  const { reports } = useSelector((state) => state.report);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getReports(currentPage);
  }, [currentPage]);

  useEffect(() => {
    TrackPageView("Report")
  }, []);

  // get all data to populate all related fields in the reports
  useEffect(() => {
    getAllNoteTypes();
    getTaskTypes()
    getAllStaff("all");
    getAllFacilitiesModal();
    getAllFacilityTypes();
    getAllPatientsModal();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Container fluid className={classes.content} px={20}>
      <ReportModal opened={opened} onclose={close} title={"Add New Report"} />
      <Topbar title={"Reports"} />
      <Box className={classes.btn}>
        <AddButton title={"Add New Report"} onClick={open} />
      </Box>
      <ReportTable />
      <CustomPagination
        data={reports?.count}
        itemsPerPage={10}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Container>
  );
};

export default Reports;
