import { Box, createStyles, Text } from "@mantine/core";
import React from "react";
import infoFormImg from "./../../../images/infoFormImg.png";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  required: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "@media (max-width :500px)": {
      width: "70%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: "auto",
    },
  },
}));

const FormInfo = ({ activeTabValue }) => {
  const { classes } = useStyles();
  const isLabptopScreen = useMediaQuery("(max-width: 800px)");
  // const isMobileScreen = useMediaQuery("(max-width: 500px)");

  return (
    <Box p={isLabptopScreen ? 5 : 10}>
      <Box className={classes.required}>
        <img
          // width={isMobileScreen ? "100%" : "100%"}
          src={infoFormImg}
          alt="info"
          className={classes.required}
        />
      </Box>
      <Text ff={"Inter"} size="sm" className={classes.required}>
        <Text mr={10} color="red">
          *
        </Text>
        required field
      </Text>
      {activeTabValue === "personalInfo" ? (
        <Box mt={20}>
          <Text ff={"Inter"} fw={700} fz={isLabptopScreen ? 14 : 16}>
            1. Profile Picture
          </Text>
          <Text
            ff={"Inter"}
            color="#7D7D7D"
            ml={20}
            fz={isLabptopScreen ? 12 : 14}
          >
            Click on the profile image to add a client's image
          </Text>
          <Text ff={"Inter"} fw={700} fz={isLabptopScreen ? 14 : 16}>
            2. Personal Preference
          </Text>
          <Text
            ff={"Inter"}
            color="#7D7D7D"
            ml={20}
            fz={isLabptopScreen ? 12 : 14}
          >
            Add the clients preference to their profile. Ex: Nancy prefers
            pancakes to waffles.
          </Text>
        </Box>
      ) : null}
      {activeTabValue === "healthInfo" ? (
        <Box mt={20}>
          <Text ff={"Inter"} fw={700} fz={isLabptopScreen ? 14 : 16}>
            1. DNR Codes
          </Text>
          <Text ff={"Inter"} color="#7D7D7D" fz={isLabptopScreen ? 12 : 14}>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <span style={{ textDecoration: "underline" }}>Level 1:</span>{" "}
                Stay in the facility and be kept comfortable, but not given
                antibiotics or other medications to cure you.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>Level 2:</span>{" "}
                Stay in the facility and receive all medications and treatments
                possible within the facility.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>Level 3:</span> Be
                transferred to a hospital from a nursing facility but not given
                CPR or taken to intensive care.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>Level 4:</span> Be
                taken to a hospital and given all possible medical
                interventions. Do everything possible.
              </li>
            </ul>
          </Text>
        </Box>
      ) : null}

      {activeTabValue === "careSupportInfo" ? (
        <Box mt={20}>
          <Text ff={"Inter"} fw={700} fz={isLabptopScreen ? 14 : 16}>
            1. Don’t see your Contact Type?
          </Text>
          <Text
            ff={"Inter"}
            color="#7D7D7D"
            ml={20}
            mt={10}
            fz={isLabptopScreen ? 12 : 14}
          >
            <Text>
              Add a custom Care & Support Contact by clicking 'Add Additional
              Contact'
            </Text>
            <br />
            <Text>Ex:</Text>
            <br />
            <Text>Contact Type: Emergency Contact</Text>
          </Text>
        </Box>
      ) : null}
      {activeTabValue === "insuranceInfo" ? (
        <Box mt={20}>
          <Text ff={"Inter"} fw={700} fz={isLabptopScreen ? 14 : 16}>
            1. Don’t see your Insurance Type?
          </Text>
          <Text
            ff={"Inter"}
            color="#7D7D7D"
            ml={20}
            mt={10}
            fz={isLabptopScreen ? 12 : 14}
          >
            <Text>
              Add a custom Insurance Information by clicking ‘Add Additional
              Insurance’
            </Text>
            <br />
            <Text>Ex:</Text>
            <br />
            <Text>Insurance Type: Dental</Text>
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};

export default FormInfo;
