import { createStyles, getStylesRef } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    marginTop: 50,
  },
  rightBTN: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  btnIcon: {
    ref: getStylesRef("btnIcon"),
    background: "#FFBA45",
    borderRadius: 5,
    cursor: "pointer",
  },
  FilterIcon: {
    background: "#FFBA45",
    padding: "5px",
    borderRadius: 3,
    cursor: "pointer",
  },
  filterBtn: {
    margin: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1rem",
  },
}));

export default useStyles;
