import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  centered: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginTop: rem(50),
    // margin: "auto",
  },
  update:{
    color: "#fff",
    fontWeight: 600,
    textDecoration: "none",
    borderBottom: "1px solid #fff",
  },
  title: {
    fontSize: rem(34),
    fontWeight: 700,
    fontFamily: "Inter",
    marginBottom: rem(10)
  },
  paymentFailedBox:{
    background: "#ff00009e",
    color: "#FFFF",
    padding: 20,
    borderRadius: 10,
    // marginTop: 10,
    marginBottom: 10,
    fontFamily: "Inter",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  description: {
    fontSize: rem(16),
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#979797"
  },
  logo: {
    marginTop: rem(30),
    marginBottom: rem(40)
  },
  type: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#97979726",
    borderStyle: "solid",
    padding: 20
  },
  selected: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#FFBA45",
    borderStyle: "solid",
    padding: 20
  },
  myfont: {
    fontFamily: "Inter"
  },
  card: {
    width: "100%",
    marginTop: 0,
    overflow: "unset"
  },
  payBtn: {
    fontFamily: "Inter",
    marginTop: rem(50),
    marginBottom: rem(20)
  },
  m10: {
    marginTop: rem(10),
    marginBottom: rem(10)
  },
  font1: {
    fontSize: rem(20),
    fontWeight: 500,
    fontFamily: "Inter",
    cursor: "pointer"
  },
  font2: {
    fontSize: rem(16),
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#979797"
  },
  back: {
    position: "absolute",
    top: rem(50),
    left: rem(50),
    fontSize: rem(16),
    fontFamily: "Inter",
    fontWeight: 500,
    "@media (max-width: 1024px)": {
      top: rem(100),
    }
  },
  plaidComponent: {
    // cursor: "pointer",
    border: "1px solid #F2F2F2",
    borderRadius: "10px",
    width: "65%",
    "@media (max-width: 900px)": {
      width: "80%"
    },
    "@media (max-width: 768px)": {
      width: "90%"
    }
  },
  connectBankImage: {
    width: "30%",
    "@media (max-width: 500px)": {
      width: "100%"
    }
  },
  insideFlex: {
    width: "60%",
    "@media (max-width: 500px)": {
      width: "95%",
      padding: "10px"
    }
  }
}));
