import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  firsttext: {
    marginTop: "40px",
    marginBottom: "8px",
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 400,
    color: "#2F2F2F",
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  clientInfo: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& img": {
      width: "30px",
    },
  },
  clientlogo: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  firstpara: {
    color: "#070707",
    fontFamily: "Inter",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "58.09px",
    textAlign: "left",
  },

  editbtn: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
    bottom: "37px",
  },

  fq0g4p: {
    borderBottom: "None",
  },

  firstbox: {
    marginTop: "37px",
    //width: '100%',
    //height: '725px',
    border: "0.5px solid #070707",
    background: "#F4F4F2",
  },

  fourthbox: {
    marginTop: "37px",
    //width: '100%',
    //height: '725px',
    border: "0.5px solid #070707",
    background: "#D8D8D8",
  },

  fifthbox: {
    background: "#F4F4F2",
    marginTop: "37px",
    //width: '100%',
    //height: '725px',
    border: "0.5px solid #070707",
  },

  newfifthbox: {
    background: "#F4F4F2",
    //marginTop: "37px",
    //width: '100%',
    //height: '725px',
    // border: '0.5px solid #070707',
  },

  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },

  clientprofile: {
    color: "#070707",
    fontFamily: "Inter",
    fontSize: "20px",
    textAlign: "Center",
    paddingTop: "10px",
    paddingBottom: "10px",
    //marginTop:'12px',
    borderBottom: "0.5px solid #070707",
    fontWeight: "600",
  },

  secondbox: {
    width: "90%",
    paddingTop: "36px",
    //height: '213px',
    borderRadius: "5px",
    border: "0.5px solid #070707",
    background: "#FFF",
    marginTop: "36px",
    margin: "auto",
    paddingBottom: "38px",
  },

  id: {
    color: "#2F2F2F",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      textAlign: "left",
    },
  },

  text: {
    color: "#7C7C7C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontweight: "500",
    "@media (max-width: 1024px)": {
      fontSize: "12px",
    },
  },

  diaghead: {
    color: "#070707",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "center",
  },

  diagpara: {
    color: "#7C7C7C",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    marginTop: "5px",
    textAlign: "center",
    "@media (max-width: 1024px)": {
      fontSize: "12px",
    },
    //paddingBottom:'55px'
  },

  boxtext: {
    //border: '0.5px solid #070707',
    background: "#D8D8D8",

    color: "#070707",
    fontFamily: "Inter",
    fontSize: "16px",

    paddingTop: "14px",
    paddingBottom: "13px",
    paddingLeft: "20px",
    fontWeight: "500",
  },

  boxpara: {
    //border: '0.5px solid #070707',
    paddingTop: "14px",
    paddingBottom: "13px",
    paddingLeft: "20px",
    background: "#F8F8F8",
    color: "#070707",
    fontFamily: "Inter",
    fontSize: "14px",

    fontWeight: "500",
  },

  boxnewpara: {
    border: "0.5px solid #070707",
    //paddingTop: "14px",
    //paddingBottom: "13px",
    //paddingLeft:'20px',
    // width: "20%",
    //height: '33px',
    padding: "8px 10px",
    background: "#D8D8D8",
    color: "#070707",
    fontFamily: "Inter",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "500",
    "@media (max-width: 1024px)": {
      fontSize: "12px",
    },
  },

  thirdbox: {
    width: "100%",
    margin: "auto",
    // height: '247px',
    //border: '0.5px solid #070707',
  },

  newbox: {
    // width:'90%',
    // margin:'auto',
  },

  guard: {
    padding: "9px",
    color: "070707",
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: "500",
    paddingRight: "20px",
  },
  newname: {
    color: "070707",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "600",
  },
  newpara: {
    color: "070707",

    fontFamily: "Inter",
    fontSize: "10px",
    fontWeight: "600",
  },
  top_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    position: "sticky",
  },
  buttonParent: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "30px",
    "@media (max-width: 500px)": {
      display: "block",
      textAlign:"center",
      marginRight:"0"
    },
    insuranceCompParent:{
      marginLeft:"10px"
    }
  },
}));

export default useStyles;
