import {  Box, createStyles, rem } from "@mantine/core";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import TableLoader from "../TableLoader";
import moment from "moment";
import { decrypt, formatDate, formatTime } from "../../utils";

const useStyles = createStyles((theme) => ({
  header: {
    color: "white",
    fontFamily: "Inter",
    fontStyle: "medium",
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: "#232323",
    paddingTop: rem(15),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: rem(30),
    width: "100%",
    background: "purple",
  },
  cell: {
    border: "1px solid #EDEDED",
    fontFamily: "Inter",
    fontSize: 14,
    padding: "1rem",
    width: "max-content",
  },
  outcomeCell: {
    border: "1px solid #cbced7",
    fontFamily: "Inter",
    fontSize: 16,
    padding: "1rem",
    width: "max-content",
  },
  control: {
    color: "white",
    background: "green",
  },
  tableContainer: {
    // width: "1150px",
    overflowX: "auto",

    // "@media (min-width: 1441px)": {
    //   width: "1320px",
    // },
    // "@media (max-width :1024px)": {
    //   width: "900px"
    // },
    // "@media (max-width :800px)": {
    //   width: "520px"
    // }
  },
  thClass: {
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "1rem",
    padding: "1rem",
  },
}))

const MARReportViewTable = forwardRef(({ data, tables, onFiltered, filteredMar ,setFilteredMar, loading }, ref) => {
  const { classes } = useStyles();
  const [headers, setHeaders] = useState([]);

  useImperativeHandle(ref, () => ({
    applyFilters: applyFilters,
  }));

  useEffect(() => {
    if (data?.source?.length) {
      let sortedData = data?.source?.sort(function (a, b) {
        return new Date(b?.date) - new Date(a?.date);
      });

      let _mar = [];

      if (sortedData?.length > 0) {
        sortedData?.forEach((v, i) => {
          const _decrypt = decrypt(v?.client?.form3);
          const filtered = _decrypt?.find(
            (x) => Number(x?.id) === Number(v?.medicationId)
          );
          if (filtered) {
            _mar.push({
              ...v,
              form3: filtered ? filtered : null,
            });
          }
        });
      }

      let formattedSource = _mar?.map((item) => {
        return {
          date: item?.date,
          medicationName: item?.form3?.medicationName || "-",
          clientName: decrypt(item?.client?.name, false) || "-",
          dosage: item?.dosage || "-",
          route: item?.form3?.ingestionMode || "-",
          narcotic: item?.form3?.isNarcotic || "-",
          remainQty: item?.medicineRemaining || "-",
          administrationType: item?.form3?.frequency || "-",
          schedule: item?.timeSlot || "-",
          staffName: item?.staff?.name || "-",
          administeredTime: item?.staff
            ? formatTime(item?.createdAt)
            : "-" || "-",
          outcome:
            item?.medicationType === "upcoming"
              ? !moment(item.date).isBefore(moment().format("MM-DD-YYYY"))
                ? "Upcoming"
                : "Missed"
              : item?.medicationType,
        };
      });

      setHeaders({
        headers: data?.fields,
        source: formattedSource,
      });
    }
  }, [data]);

  const mergeArrays = (arrays) => {
    const uniqueFields = {};

    arrays.forEach((array) => {
      array.forEach((obj) => {
        const key = obj.field;
        if (!uniqueFields[key]) {
          uniqueFields[key] = obj;
        }
      });
    });
    return Object.values(uniqueFields);
  };

  const applyFilters = (filters) => {
    if (data) {
      let mar = data?.source;

      let _mar = [];
      mar?.forEach((v, i) => {
        const _decrypt = decrypt(v?.client?.form3);
        const filtered = _decrypt?.find(
          (x) => Number(x?.id) === Number(v?.medicationId)
        );
        if (filtered) {
          _mar.push({
            ...v,
            form3: filtered ? filtered : null,
          });
        }
      });

      let marFormattedSource = _mar?.map((item) => {
        return {
          date: item?.date,
          medicationName: item?.form3?.medicationName || "-",
          clientName: decrypt(item?.client?.name, false) || "-",
          dosage: item?.dosage || "-",
          route: item?.form3?.ingestionMode || "-",
          narcotic: item?.form3?.isNarcotic || "-",
          remainQty: item?.medicineRemaining || "-",
          administrationType: item?.form3?.frequency || "-",
          schedule: item?.timeSlot || "-",
          staffName: item?.staff?.name || "-",
          administeredTime: item?.staff
            ? formatTime(item?.createdAt)
            : "-" || "-",
          outcome:
            item?.medicationType === "upcoming"
              ? !moment(item.date).isBefore(moment().format("MM-DD-YYYY"))
                ? "Upcoming"
                : "Missed"
              : item?.medicationType,
        };
      });

      let filteredData = [];

      if (Array.isArray(filters) && filters?.length) {
        const toFilter = {};

        let sources = filters?.map((filter) => filter?.source);
        filters?.forEach((v) => {
          let array = filters?.filter(
            (element) => element?.source == v?.source
          );


          let _filters = {};
          array?.forEach((element) => {
            // _filters[element?.field] = element?.filterOn;
            filteredData = [
              ...(filteredData?.length ? filteredData : marFormattedSource),
            ]?.filter((mar) => {
              if (element?.fieldValue === "Client Name") {
                return element?.filterOn === mar?.clientName;
              } else if (element?.fieldValue === "Medication Name") {
                return element?.filterOn === mar?.medicationName;
              } else if (element?.fieldValue === "Date") {
                return element?.filterOn === formatDate(mar?.date);
              } else if (element?.fieldValue === "Dosage") {
                return element?.filterOn == mar?.dosage;
              } else if (element?.fieldValue === "Route") {
                return element?.filterOn === mar?.route;
              } else if (element?.fieldValue === "Narcotic") {
                return element?.filterOn === mar?.narcotic;
              } else if (element?.fieldValue === "Remaining Qty") {
                return element?.filterOn == mar?.remainQty;
              } else if (element?.fieldValue === "Administration Type") {
                return element?.filterOn === mar?.administrationType;
              } else if (element?.fieldValue === "Scheduled Time") {
                return element?.filterOn === mar?.schedule;
              } else if (element?.fieldValue === "Administered Time") {
                return element?.filterOn === mar?.administeredTime;
              } else if (element?.fieldValue === "Staff Name") {
                return element?.filterOn === mar?.staffName;
              } else if (element?.fieldValue === "Outcome") {
                return element?.filterOn === mar?.outcome;
              }

              return false; // Return false for cases that don't match the conditions
            });
          });

        });
      }

      if (filters !== "clear") {
        setFilteredMar(filteredData);

        setHeaders({
          headers: data?.fields,
          source: filteredData,
        });
      } else {
        setFilteredMar(null);
        setHeaders({
          headers: data?.fields,
          source: marFormattedSource,
        });
      }
    }
  };

  // const sendFiltersBack = (headers) => {
  //   const heads = headers?.headers?.map((value) => value?.field);

  //   let csvData = headers?.data?.map((row, index) =>
  //     headers?.headers?.map((value) => {
  //       if (value?.options) {
  //         let options = getOptions(
  //           value?.options,
  //           allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)?.custom
  //         );
  //         return options?.find((op) => op?.value === row?.fields[value?.field])
  //           ?.label;
  //       }
  //       return formatDate(row?.fields[value?.field]);
  //     })
  //   );
  //   if (csvData?.length && data?.source) {
  //     onFiltered(data?.source?.id, data?.source, [heads, ...csvData]);
  //   }
  // };

  const rows = headers?.source?.map((row, index) => {
    // if(data?.source?.length){
      return (
        <>
          <tr key={index}>
            {headers?.headers?.map((value, i) => {
                let keyName =
                  value === "Date"
                    ? "date"
                    : value === "Medication Name"
                    ? "medicationName"
                    : value === "Client Name"
                    ? "clientName"
                    : value === "Dosage"
                    ? "dosage"
                    : value === "Route"
                    ? "route"
                    : value === "Narcotic"
                    ? "narcotic"
                    : value === "Remaining Qty"
                    ? "remainQty"
                    : value === "Administration Type"
                    ? "administrationType"
                    : value === "Scheduled Time"
                    ? "schedule"
                    : value === "Administered Time"
                    ? "administeredTime"
                    : value === "Staff Name"
                    ? "staffName"
                    : value === "Outcome"
                    ? "outcome"
                    : value;
                // clientName
                return (
                  <td
                    className={classes.cell}
                    style={{
                      width: "100%",
                      padding: "1rem 0",
                    }}
                    key={i}
                  >
                    {keyName === "date" ? formatDate(row[keyName]) : row[keyName]}
                  </td>
                );
            })}
          </tr>
        </>
      );
    // }
  });

  const sizes = {
    Description: "300px",
    "Staff Name": "150px",
    Date: "100px",
    Time: "100px",
    "Facility Name": "150px",
    Shift: "100px",
    // [noteType_Client]: "150px",
  };

  return (
    <div className={classes.tableContainer}>
      <table
        id="sampleTable"
        style={{
          width: "100%",
          border: "1px solid #EDEDED",
          borderCollapse: "collapse",
        }}
      >
        <tr style={{ background: "#000", color: "#fff" }}>
          {headers?.headers?.map((header, index) => {
            return (
              <th key={index}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: sizes[header?.field] || "150px",
                    gap: "1rem",
                    padding: "1rem",
                  }}
                >
                  {header}
                </span>
              </th>
            );
          })}
        </tr>

        {loading ? <Box
              style={{
                display: "flex",
                alienItem: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <TableLoader />
            </Box> : rows}
      </table>
      <table
        id="hiddenTable"
        style={{
          width: "100%",
          border: "1px solid #EDEDED",
          position: "absolute",
          top: "-10000000px",
          left: "-10000000px",
        }}
      >
        <tr style={{ background: "#000", color: "#fff" }}>
          {headers?.headers?.map((header, index) => {
            return (
              <th key={index}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: sizes[header?.field] || "150px",
                    gap: "1rem",
                    padding: "1rem",
                  }}
                >
                  {header}
                </span>
              </th>
            );
          })}
        </tr>

        {loading ? <Box
              style={{
                display: "flex",
                alienItem: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <TableLoader />
            </Box> : rows}
      </table>
    </div>
  );
});

export default MARReportViewTable;
