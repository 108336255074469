import { createStyles } from "@mantine/core";
import moment from "moment";
import { getPriorityColor } from "../../utils";

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "9%",
    paddingBlock: "10px",
    background: "#fff",
    borderBottom: "6px solid transparent",
    "& h4": {
      margin: "0",
      fontSize: "1.2vw",
      fontWeight: 400,
      color: "#979797",
      textTransform: "uppercase",
    },
    "& h3": {
      margin: "0",
      fontSize: "1.8vw",
      fontWeight: 400,
    },
  },
  active: {
    borderBottom: "6px solid #FFBA45 !important",
  },
  box: {
    width: "80%",
    height: "3px",
  },
}));

const MonthWeekCard = ({ day }) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes.card} ${
        moment().format("ddd")?.toLowerCase() === day?.toLowerCase()
          ? classes.active
          : ""
      }`}
    >
      <h4>{day}</h4>
    </div>
  );
};

export default MonthWeekCard;
