import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    margin: "0"
  },
  left_pane: {
    width: "50%",
    height: "100vh",
    background: "#FFBA45"
  },
  login_btn: {
    padding: "10px"
  },
  right_pane: {
    padding: 0
  },
  welcome: {
    position: "relative",
    top: "5.5%",
    left: "5.5%",
    maxWidth: "80%"
  },
  logo: {
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important"
    },
    "@media (max-width:768px)": {
      marginLeft: 30
    },
    "@media (max-width:330px)": {
      marginLeft: 10
    }
  },
  // logo_img: {
  //   width: "21.57%",
  //   aspectRatio: 3.7,
  //   "@media (max-width :80em)": {
  //     width: "31.57%",
  //   },
  // },
  logo_img2: {
    width: "40%",
    aspectRatio: 3.7,
    "@media (max-width :80em)": {
      width: "30%",
      height: "30%"
    },
    "@media (max-width :40em)": {
      display: "inline"
    },
    display: "none"
  },
  glass_man: {
    marginTop: "9.4%",
    marginLeft: "8.95%",
    width: "59.2%",
    aspectRatio: 0.88
  },
  credential: {
    "@media (max-width :80em)": {
      position: "absolute",
      top: "50%"
    },
    position: "absolute",
    top: "24%",
    left: "26.09%",
    width: "19%",
    aspectRatio: "0.8693"
  },
  myform: {
    maxWidth: "80%",
    margin: "auto",
    padding: "3rem 0"
    // position: "relative",
    // top: "10%",
  },
  rememberbox: {
    marginTop: rem(15),
    marginBottom: rem(15)
  },
  forgot: {
    marginLeft: "auto",
    color: "#FFBA45",
    textDecoration: "underline"
  },
  signup: {
    color: "#FFBA45",
    textDecoration: "underline"
  },
  toptitle: {
    position: "relative",
    top: "5.5%",
    right: "5.5%",
    fontSize: rem(14),
    textAlign: "right"
  },
  title: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(24),
    fontWeight: 700,
    fontFamily: "Inter"
  },
  login_btn: {
    "@media (max-width :48em)": {
      width: "100%"
    },
    marginTop: "2rem",
    width: rem(296),
    height: rem(50),
    fontSize: rem(22)
  },
  login_btn_google: {
    "@media (max-width :48em)": {
      width: "100%"
    },
    "@media (max-width :80em)": {
      width: "100%",
      fontSize: "1rem"
    },
    width: rem(296),
    height: rem(50),
    fontSize: rem(22),
    marginTop: rem(20),
    color: "grey"
  }
}));

export default useStyles;
