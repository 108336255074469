import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Dashboard from "../pages/Home/Dashboard";
import Facility from "../pages/Home/Facility";
import FacilityType from "../pages/Home/FacilityType";
import NoteType from "../pages/Home/NoteType";
import Patient from "../pages/Home/Patient";
import Reports from "../pages/Home/Report";
import Setting from "../pages/Home/Setting";
import Notification from "../pages/Home/Notificaton";
import Staff from "../pages/Home/Staff";
import Login from "../pages/Login";
import PaymentInfo from "../pages/PaymentInformation";
import NewPaymentMethod from "../pages/NewPaymentMethod";
import SimplePricing from "../pages/SimplePricing";
import ForgetPassword from "../pages/ForgetPassword";
import VerifyEmail from "../pages/VerifyEmail";
import ResetPassword from "../pages/CodeVerification";
import TCandPP from "../pages/TermCondition&PP/TCandPP";
import ContactUs from "../pages/ContactUs";
import CookiesPolicy from "../pages/CookiesPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import SupportPlan from "../pages/Home/SupportPlan";
import HaveProblem from "../pages/HaveProblem";
import MyTicket from "../pages/Home/MyTicket";
import SingleTicket from "../pages/SingleTicket";
import Landing from "../pages/LandingPage";
import WhyOgiso from "../pages/WhyOgiso";
import PricingComp from "../pages/PricingCopm";
import AdminProfilePage from "../pages/AdminProfile";
import ChatBot from "../utils/chatbot";

function Router() {
  return (
    <>
    <ChatBot />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<AdminProfilePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/whyogiso" element={<WhyOgiso />} />
        <Route path="/pricing" element={<PricingComp />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/verify-email-update/:token" element={<VerifyEmail />} />
        <Route path="/selectprice" element={<SimplePricing />} />
        <Route path="/payInfo" element={<PaymentInfo />} />
        <Route path="/add-payment" element={<NewPaymentMethod />} />
        <Route path="/term-conditions" element={<TCandPP />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/home/*" element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="supportPlan" element={<SupportPlan />} />
          <Route path="facility" element={<Facility />} />
          <Route path="facilitytype" element={<FacilityType />} />
          <Route path="staff" element={<Staff />} />
          <Route path="report" element={<Reports />} />
          <Route path="patient" element={<Patient />} />
          <Route path="note" element={<Dashboard />} />
          <Route path="notetype" element={<NoteType />} />
          <Route path="settings" element={<Setting />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="tickets" element={<MyTicket />} />
          <Route path="ticket/:id" element={<SingleTicket />} />
          <Route path="create-ticket" element={<HaveProblem />} />
        </Route>
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </>
  );
}

export default Router;
