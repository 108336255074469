import { Box, Button, createStyles, Text } from "@mantine/core";
import mixpanel from "mixpanel-browser";
import React from "react";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  bannerContainer: {
    background: theme.other.yellowColor,
    color: theme.other.black,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: "60px 20px",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    width: "90%",
    margin: "auto",
    marginTop: "40px",
    "@media (max-width: 468px)": {
      marginTop: "0px",
      padding: "30px 20px",
    },
  },
  text: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontSize: "35px",
    fontWeight: "400",
    lineHeight: "66px",
    "@media (max-width: 768px)": {
      fontSize: "30px",
      lineHeight: "46px",
    },
    "@media (max-width: 468px)": {
      lineHeight: "30px",
      fontSize: "20px",
      fontWeight: "600",
      textAlign: "center",
    },
  },
  btns: {
    display: "flex",
    gap: "1rem",
    justifyContent: "start",
    alignItems: "center",
    "@media (max-width: 435px)": {
      flexDirection: "column-reverse",
    },
  },
  demoBtn: {
    paddingLeft: "0",
    paddingRight: "0",
    width: "200px",
    background: theme.other.white,
    color: theme.other.black,
    "&:hover": {
      background: theme.other.white,
      opacity: 0.8,
    },
  },
  createBtn: {
    width: "200px",
    background: theme.other.black,
    color: theme.other.white,
    "&:hover": {
      background: theme.other.black,
      opacity: 0.8,
    },
  },
  requestDemo: {
    width: "100%",
    border: "none",
    background: "none",
    height: "2.625rem",
    width: "200px",
    cursor: "pointer",
  },
}));

const StartedBanner = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.bannerContainer}>
      <Text className={classes.text}>
        It’s easy to get started, with $0 onboarding fees and $0 training or
        setup fees.
      </Text>
      <Box className={classes.btns}>
        <Button
          size="md"
          width="180px"
          className={classes.demoBtn}
          onClick={() => mixpanel.track("demo booked")}
        >
          <PopupButton
            className={classes.requestDemo}
            url="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
            rootElement={document.getElementById("root")}
            text="Request Demo"
          />
        </Button>
        <Button
          size="md"
          width="180px"
          className={classes.createBtn}
          onClick={() => navigate("/signup")}
        >
          Create free account
        </Button>
      </Box>
    </Box>
  );
};

export default StartedBanner;
