import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 20,
    border: "1px solid black",
  },
  parentView: {
    border: "1px solid #979797",
    width: "100%",
    height: "95%",
  },
  detailsFlex: {
    border: "1px solid #979797",
    margin: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingVertical: 5,
    // width: "100%",
    height: "15%",
  },
  imageView: {
    width: "90px",
  },
  detailsView: {
    flexGrow: 1,
    paddingLeft: 20,
  },
  clientImage: {
    width: "100px",
    height: "auto",
    // height: "180px",
    borderRadius: "8px",
  },
  divFlex: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "5px",
    height: "auto",
  },
  detailName: {
    fontSize: "10px",
    textAlign: "right",
    width: "auto",
  },
  dobName: {
    fontSize: "10px",
    textAlign: "right",
    width: "auto",
    marginLeft: "10px",
  },
  clientName: {
    fontSize: "10px",
    textAlign: "right",
    width: "auto",
    marginLeft: "10px",
  },
  previousName: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "left",
  },
  detailValue: {
    fontSize: "10px",
    fontWeight: 100,
    fontFamily: "Courier Prime",
  },
  clientValue: {
    fontSize: "10px",
    fontWeight: 100,
    fontFamily: "Courier Prime",
    marginLeft: "40px",
    width: "auto",
  },
  levelValue: {
    fontSize: "10px",
    fontWeight: 100,
    fontFamily: "Courier Prime",
    marginLeft: "30px",
    width: "auto",
  },
  addressVal: {
    fontSize: "10px",
    fontWeight: 400,
    marginTop: "5px",
    fontFamily: "Courier Prime",
  },
  activityVal: {
    fontSize: "8px",
    fontWeight: 400,
    marginTop: "5px",
    fontFamily: "Courier Prime",
    display: "block",
    padding: "0 10px",
  },

  taskVal: {
    fontSize: "8px",
    fontWeight: 400,
    marginTop: "5px",
    fontFamily: "Courier Prime",
    padding: "0 10px",
  },

  viewOne: {
    width: "140px",
  },
  viewTwo: {
    width: "150px",
  },
  additionalDetails: {
    borderTop: "1px solid #979797",
    width: "100%",
    height: "100%",
    paddingTop: "0",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  careAndHealth: {
    borderLeft: "1px solid #979797",
    borderRight: "1px solid #979797",
    // borderBottom: "1px solid #979797",
    margin: "0 20px 0",
    padding: "0 5px 20px 5px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  careBg: {
    backgroundColor: "#fff5e3",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    textAlign: "center",
    width: "100%",
    fontSize: "12px",
    fontWeight: " bold",
  },
  healthBg: {
    backgroundColor: "#fff5e3",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    textAlign: "center",
    width: "100%",
    fontSize: "12px",
    fontWeight: " bold",
  },
  careElements: {
    backgroundColor: "#ffffff",
  },
  careWidth: {
    width: "100%",
    marginTop: "10px",
    marginRight: "20px",
  },
  healthWidth: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box: {
    border: "1px solid #979797",
    width: "90%",
    borderRadius: "5px",
    padding: "7px 5px 5px 10px",
    // height: "auto",
    height: "auto",
    // minHeight: "80px",
    margin: "0 auto",
    marginTop: "10px",
  },
  boxActivity: {
    border: "1px solid #979797",
    width: "30%",
    borderRadius: "5px",
    padding: "5px",
    marginTop: "10px",
    display: "inline-block",
  },
  wrapperActivity: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "green",
  },
  typeName: {
    fontSize: "10px",
    color: "#979797",
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    rowGap: "10px",
  },
  infoActivity: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: "10px",
  },
  infoHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "1rem",
  },
  infoName: {
    fontSize: "10px",
    marginTop: "10px",
    // width: "70px",
  },
  infoNameAct: {
    fontSize: "11px",
    fontWeight: "700",
    textTransform: "capitalize",
    marginLeft: "8px",
    minWidth: "80px",
  },
  infoSubWrapper: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    flexDirection: "row",
    gap: "1rem",
  },
  infoSubHeadingAct: {
    fontSize: "8px",
    fontWeight: "500",
    margin: "3px 10px",
    color: "#979797",

    // color: "979797",
  },
  numAndEmail: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  numberParent: { marginRight: "10px" },
  number: {
    fontSize: "10px",
    marginTop: "10px",
    // width: "75px",
  },
  // newClass: {
  //   color: "red",
  //   width: "max-content"
  // },
  email: {
    fontSize: "10px",
    marginTop: "10px",
    // width: "90px",
  },
  emailAlternate: {
    fontSize: "10px",
    marginTop: "10px",
    // width: "90px",
    // width: "fit-content"
  },
  fieldset: {
    position: "relative",
    border: "1px solid #d3d3d3", // Light gray border
    padding: 10,
    borderRadius: 4,
    marginBottom: "10px",
    marginTop: "10px",
    width: "90%",
  },
  legend: {
    position: "absolute",
    top: "-8px",
    left: "5px",
    backgroundColor: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 6,
    padding: "0 3px",
  },
  diagnosisText: {
    fontSize: 10,
    color: "#333",
    lineHeight: 1.5,
    fontFamily: "Courier Prime",
  },
  diagnosisContainer: {
    width: "90%",
  },
  diagnosis: {
    fontSize: "10px",
    marginTop: "5px",
  },
  allergies: {
    fontSize: "10px",
    marginTop: "15px",
  },
  restrictions: { fontSize: "10px", marginTop: "15px" },
  diagnosisParent: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  allergiesParent: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  restrictionsParent: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  diagnosisELem: {
    width: "auto",
    backgroundColor: "#f0efff",
    marginRight: "10px",
    padding: "5px",
    borderRadius: "5px",
    marginTop: "5px",
  },
  allergiesELem: {
    width: "auto",
    backgroundColor: "#e8f6e6",
    marginRight: "10px",
    padding: "5px",
    borderRadius: "5px",
    marginTop: "5px",
  },
  restrictionsELem: {
    width: "auto",
    backgroundColor: "#ffefe6",
    marginRight: "10px",
    padding: "5px",
    borderRadius: "5px",
    marginTop: "5px",
  },
  diagnosisVal: {
    color: "#6C63FF",
    fontSize: "11px",
  },
  allergiesVal: {
    color: "#167207",
    fontSize: "11px",
  },
  restrictionsVal: {
    color: "#FF5E03",
    fontSize: "11px",
  },
  medicationsBg: {
    backgroundColor: "#fff5e3",
    marginTop: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "12px",
  },
  activeMedications: {
    textAlign: "center",
  },

  medicationParent: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  medicationBg: {
    backgroundColor: "#f0f5ff",
    width: "auto",
    display: "inline-block",
    marginRight: "10px",
    borderRadius: "5px",
    padding: "5px 5px",
    marginTop: "10px",
    marginLeft: "5px",
  },
  activityCell: {
    border: "1px solid #979797",
    width: "45%",
    display: "flex",
    flexDirection: "row",
    // gap: "1rem",
    flexWrap: "wrap",
    marginRight: "21px",
    borderRadius: "5px",
    padding: "5px 0",
    marginTop: "10px",
    marginLeft: "5px",
    height: "65px",
    position: "relative",
    left: "8px",
  },
  activityWrapper: {
    display: "flex",
    alignContent: "start",
    // justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1rem",
    borderBottom: "1px solid #979797",
  },
  medicationVal: {
    fontSize: "11px",
    textAlign: "center",
  },
  redClasses: {
    textAlign: "right",
    width: "65px",
  },
  blueClasses: {
    textAlign: "left",
    width: "55px",
  },
  dateVal: {
    width: "60px",
    textAlign: "left",
  },
  recentActivity: {
    textAlign: "center",
    fontSize: "12px",
  },
  activityBg: {
    backgroundColor: "#fff5e3",
    padding: "5px 0",
    marginTop: "20px",
  },
  comingSoon: {
    textAlign: "center",
    marginTop: "70px",
    fontSize: "12px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 13,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  medicationActivities: {
    borderLeft: "1px solid #979797",
    borderRight: "1px solid #979797",
    borderBottom: "1px solid #979797",
    margin: "0 20px 20px",
    padding: "0 5px 20px 5px",
  },
  textValue: {
    fontSize: "10px",
  },
  noData: {
    fontSize: "10px",
  },
});
