import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const CloseCircle = ({ onclose }) => {
  return (
    <IoCloseCircleOutline
      size={40}
      id="close-circle"
      style={{
        position: "absolute",
        right: "-18px",
        top: "-18px",
        cursor: "pointer",
        zIndex: 9999,
      }}
      onClick={onclose}
    />
  );
};

export default CloseCircle;
