import React from "react";
import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { editStaff, addStaff } from "../../services/staff";
import { useSelector } from "react-redux";
import { DropDownArrowSvg } from "../../components/Svgs/DropDownArrowSvg";
import { StaffIcon } from "../../components/Svgs/StaffIcon";
import { ProfileIcon } from "../../components/Svgs/ProfileIcon";
import { PositionIcon } from "../../components/Svgs/PositionIcon";
import { MailIcon } from "../../components/Svgs/MailIcon";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  staffName: {
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: "#CBCBCB",
    borderTopStyle: "solid",
    margin: "0 15px",
  },
}));
const StaffModal = ({ opened, onclose, title, view, setView, ...data }) => {
  const { classes } = useStyles();
  const edit = data.data ? true : false;
  const { addStaffLoading } = useSelector((state) => state.staff);
  const DropDownArrow = (
    <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />
  );

  const form = useForm({
    initialValues: {
      fullName: edit ? data?.data?.name : "",
      position: edit ? data?.data?.position : "",
      email: edit ? data?.data?.email : "",
      role: edit ? data?.data?.role : "",
    },

    validate: {
      fullName: (value) => (value ? null : "Required"),
      position: (value) => (value ? null : "Required"),
      role: (value) => (value ? null : "Required"),
      email: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value)
          ? null
          : "Please enter a valid email address";
      },
    },
  });

  const onSave = async (values) => {
    if (edit) {
      let response = await editStaff({
        ...values,
        id: data?.data?.id,
        name: values?.fullName,
      });
      if (response) clearForm();
    } else {
      let response = await addStaff({ ...values, active: false });
      if (response) clearForm();
    }
  };

  const clearForm = () => {
    form?.reset();
    onclose();
    view && setView(false);
  };

  return (
    <Modal
      centered
      size={"lg"}
      shadow="md"
      radius={"lg"}
      zIndex={1000}
      opened={opened}
      onClose={clearForm}
      overlayProps={{ blur: 3 }}
      className={classes.mymodal}
      title={
        <Group m={10}>
          <StaffIcon />
          <Text ff={"Inter"} fz={22} fw={600}>
            {view ? "View Staff" : title}
          </Text>
        </Group>
      }
    >
      <form onSubmit={form.onSubmit((values) => onSave(values))}>
        {/* full name */}
        <TextInput
          size="md"
          disabled={view}
          label="Full Name*"
          id="staff-full-name"
          icon={<ProfileIcon />}
          className={classes.staffName}
          placeholder="Ex: Marco Botton"
          {...form.getInputProps("fullName")}
        />
        {/* position */}
        <TextInput
          mr={15}
          ml={15}
          mt={20}
          size="md"
          disabled={view}
          label="Position"
          id="staff-full-position"
          placeholder="Ex: Head Chief"
          icon={<PositionIcon />}
          {...form.getInputProps("position")}
        />
        {/* role dropdown */}
        <Select
          mr={15}
          ml={15}
          mt={20}
          size="md"
          w={"150px"}
          label="Roles"
          id="staff-Role"
          disabled={view}
          placeholder="Select Role"
          rightSection={DropDownArrow}
          data={[
            { value: "admin", label: "Admin" },
            { value: "staff", label: "Staff" },
          ]}
          {...form.getInputProps("role")}
        />
        {/* email */}
        <TextInput
          mr={15}
          ml={15}
          mt={20}
          size="md"
          label="Email"
          disabled={view}
          icon={<MailIcon />}
          id="staff-full-email"
          placeholder="Ex: peldi@gmail.com"
          {...form.getInputProps("email")}
        />
        {view ? (
          <div style={{ height: "30px" }} />
        ) : (
          <SimpleGrid cols={2} m={"auto"} mt={25} mb={10}>
            <Button
              w={122}
              ml={"auto"}
              ff={"Inter"}
              radius={"md"}
              color="yellow"
              variant="light"
              onClick={clearForm}
            >
              Cancel
            </Button>
            <Button
              w={122}
              mr={"auto"}
              ff={"Inter"}
              type="submit"
              radius={"md"}
              color="yellow"
              id="save-staff"
              loading={addStaffLoading}
            >
              Save
            </Button>
          </SimpleGrid>
        )}
      </form>
    </Modal>
  );
};

export default StaffModal;
