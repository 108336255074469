import { Box, Container, createStyles } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddButton from "../../../components/AddButton";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import PlanTable from "../../../components/PlanTable";
import SupportModal from "../../../components/SupportModal";
import Topbar from "../../../components/Topbar";
import { getAllFacilitiesModal } from "../../../services";
import { getAllPatientsModal } from "../../../services/patient";
import { getAllPlans, getPlans } from "../../../services/supportplan";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));
const SupportPlan = (props) => {
  const { state } = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const { count } = useSelector((state) => state.supportPlan);
  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;

  useEffect(() => {
    getAllPlans();
    getAllPatientsModal();
    getAllFacilitiesModal();
  }, []);
  useEffect(() => {
    if (
      state &&
      state?.newPlan &&
      localStorage.getItem("performActionFlag") != "true"
    ) {
      open();
    }
  }, [state]);
  useEffect(() => {
    getPlans(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber);
    setCurrentPage(pageNumber);
  };

  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Container fluid className={classes.content} px={20}>
      <SupportModal
        close={close}
        opened={opened}
        title={"Add Support Plan"}
        facility={state ? state?.facility : null}
        patient={state ? state?.patient : null}
      />
      <Topbar title={"Support Plan"} />
      <Box className={classes.btn}>
        {userType !== "staff" ? (
          <AddButton title={"Add Support Plan"} onClick={open} />
        ) : null}
      </Box>
      <Box className={classes.table}>
        <PlanTable />
        <CustomPagination
          data={count}
          itemsPerPage={10}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default SupportPlan;
