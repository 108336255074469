import {
  ActionIcon,
  Box,
  Container,
  Group,
  SimpleGrid,
  Skeleton,
  Text,
} from "@mantine/core";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { IconChevronLeft } from "@tabler/icons-react";
import PriceCard from "../../components/PriceCard";
import useStyles from "./style";
import { useEffect, useState } from "react";
import { getPackages } from "../../services/package";
import { selectPlan, selectTrial } from "../../services/subscription";
import { getCookie, showError, removeCookie } from "../../utils";
import { getProfile } from "../../services/profile";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";
import mixpanel from "mixpanel-browser";

const SimplePricing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(); // State for window size
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const { profile } = useSelector((state) => state.profile);

  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth); // Update state with window width
    }
    window.addEventListener("resize", updateSize); // Add resize event listener
    updateSize(); // Initial call to set initial size
    return () => window.removeEventListener("resize", updateSize); // Remove event listener on cleanup
  }, []); // Empty dependency array for one-time setup

  const getAllPackages = async () => {
    const data = await getPackages(profile?.user?.id);
    if (data) {
      setLoadingPackages(false);
      if (profile?.user?.trialUsed) {
        let noTrial = data?.packages?.filter((v) => Number(v?.cost) != 0);
        setPackages({ packages: noTrial });
      } else {
        let tempPackages = data?.packages

        const sortedByCost = tempPackages.sort((a,b) => {
          return Number(a.cost) - Number(b.cost);
        });

        setPackages(sortedByCost)
      };
    }
  };

  useEffect(() => {
    getAllPackages();
    pageInfoAnalytics("Simple Pricing Page Viewed", "Simple Pricing Page");

  }, []);

  const onStart = async (id) => {
    if (profile?.business) {
      setIsLoading(true);
      const resp = await selectPlan({ id });
      if (resp) setIsLoading(false);
      if (resp?.data?.package) {
        navigate("/payInfo", {
          state: {
            stripePlan: resp?.data?.package?.stripeId,
            planId: resp?.data?.package?.id,
            plan: resp?.data?.package,
          },
        });
      }
    } else showError("Please create your business profile first.");
  };
  const onTrial = async (id) => {
    if (profile?.business) {
      setIsLoading(true);
      const resp = await selectTrial({ id });
      if (resp) {
        setIsLoading(true);
        let pr = await getProfile();
        if (pr) navigate("/home");
      }
    } else showError("Please create your business profile first.");
  };
  const onBack = () => {
    if (profile?.user) {
      navigate("/login");
    }
  };

  if (getCookie("token")) {
    if (profile?.user?.type !== "staff")
      if (profile?.subscription) return <Navigate to="/home" />;
  }
  return (
    <>
      <Group style={{ display: "flex", alignItems: "center" }}>
        <div className={classes.back}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              cursor: "pointer",
            }}
          >
            <ActionIcon
              variant="outline"
              size={"xl"}
              radius={"xl"}
              onClick={onBack}
            >
              <IconChevronLeft />
            </ActionIcon>
            <Text>Back</Text>
          </div>
          <div
            onClick={() => {
              removeCookie("token");
              mixpanel.track("Logout");
              // generate new cookie with new distinct_id
              mixpanel.reset();
              navigate("/");
            }}
            variant="default"
            className={classes.login_btn}
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/images/logout.png"
              alt="logout"
              className={classes.linkIcon}
              stroke={1}
            />
            <Text>Logout</Text>
          </div>
        </div>
      </Group>

      <Container className={classes.centered}>
        <div className={classes.logo}>
          <img width={"20%"} src="/assets/images/logo2.png" alt="" />
        </div>
        <Text className={classes.title}>Simple Pricing</Text>
        <Text className={classes.description}>
          Select a package that suits you
        </Text>
      </Container>

      <Box mx={"auto"} className={classes.price_card_box}>
        <SimpleGrid
          cols={size > 700 ? (profile?.user?.trialUsed ? 3 : 3) : 1}
          spacing={size > 800 ? 50 : 30}
        >
          {loadingPackages
            ? [1, 2, 3]?.map((value) => {
                return (
                  <Skeleton loading={false} style={{ marginTop: "100px" }}>
                    <div
                      style={{
                        height: "400px",
                        width: "300px",
                        marginTop: "100px",
                      }}
                    ></div>
                  </Skeleton>
                );
              })
            // : packages?.packages?.map((item) => (
            : packages?.map((item) => (
                <PriceCard
                  bothNormal={profile?.user?.trialUsed}
                  packageId={item?.id}
                  facility={item?.facility}
                  staff={item?.staff}
                  patient={item?.patient}
                  item={item}
                  promotion={item?.promotion}
                  daily={item?.dailyUpdates}
                  disabled={isLoading}
                  isPriceCard={item.name === "Personal"}
                  // isTrial={Number(item?.cost) == 0}
                  isTrial={item?.duration == "unlimited"}
                  src={
                    item?.name == "Trial"
                      ? "/assets/images/trial.png"
                      : item?.name == "Personal"
                      ? "/assets/images/personal.png"
                      : "/assets/images/agency.png"
                  }
                  title={item?.name}
                  theme={item?.name == "Personal" && "black"}
                  duration={item?.duration}
                  cost={item?.cost}
                  onClick={Number(item?.cost) == 0 ? onTrial : onStart}
                />
              ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default SimplePricing;
