import { createStyles } from "@mantine/core";

const bgColor = "rgba(242, 242, 242, 0.6)";

const useStyles = createStyles((theme) => ({
  confirmationModal: {
    "& .mantine-19pz3dh": {
      display: "none",
    },
    "& .mantine-Modal-body": {
      padding: 0,
    },
    "& .mantine-Paper-root": {
      overflow: "initial",
    },
  },
  wrapper: {
    width: "100%",
    padding: "30px 30px",
  },

  saveButton: {
    display: "flex",
    gap: "10px",
    "& button": {
      marginTop: "20px",
      borderRadius: "5px",
      background: "rgba(255, 186, 69, 0.85)",
      width: "100%",
      height: "40px",
      color: "#000",
      paddingInline: "10px",

      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",

      "&:hover": {
        background: "rgba(255, 186, 69, 0.85)",
      },
    },
  },
}));

export default useStyles;
