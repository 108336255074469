import { Box, useMantineTheme } from "@mantine/core";
import useStyles from "./style";
import HeroSection from "./HeroSection";
import FeatureHighlightCard from "./FeatureHighlightCard";
import Feature from "./Feature";
import StartedBanner from "../LandingPage/StartedBanner";
import Footer from "../LandingPage/Footer";
import HeaderMain from "../../components/Header/HeaderMain";
import Header from "../../components/Header/Header";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { TrackPageExit, TrackPageView } from "../../config/mixpanelConfig";

const WhyOgiso = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [showHeaderMain, setShowHeaderMain] = useState(true);
  let featuresRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToFeatures && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  useEffect(() => {
    TrackPageView("Why Ogiso")
    TrackPageExit("Why Ogiso")
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowHeaderMain(false);
      } else {
        setShowHeaderMain(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box>
        <Box
          style={{
            opacity: showHeaderMain ? 1 : 0,
            visibility: showHeaderMain ? "visible" : "hidden",
            transition: "opacity 0.5s ease, visibility 0.5s ease",
          }}
        >
          <HeaderMain />
        </Box>
        <Box
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 999,
            opacity: showHeaderMain ? 0 : 1,
            visibility: showHeaderMain ? "hidden" : "visible",
            transition: "opacity 0.5s ease, visibility 0.5s ease",
          }}
        >
          <Header />
        </Box>
      </Box>
      <Box className={classes.InlinePadding}>
        <HeroSection />
        <FeatureHighlightCard
          bgColor={"yellow"}
          heading={"Intuitive Design, Powerful Features"}
          text={
            "Our platform is built with both healthcare professionals and clients in mind. The user-friendly interface ensures that you can navigate seamlessly through patient records, appointments, and medical histories with ease. Experience a robust set of tools designed to make your workflow more efficient and effective."
          }
        />
        <Feature />
        <FeatureHighlightCard
          bgColor={"transparent"}
          heading={"Innovation at Your Fingertips"}
          hdngColor={theme.colorScheme === "dark" ? "inherit" : "black"}
          textColor={theme.colorScheme === "dark" ? "inherit" : "black"}
          text={
            "Stay ahead of the curve with Ogiso’s continuous innovation. We are committed to providing you with the latest features and updates to keep your facility running smoothly. Our platform evolves with the ever-changing landscape of state regulation and technology, ensuring that you always have the best tools at your disposal"
          }
        />
        <br />
        <br />
        <FeatureHighlightCard
          bgColor={"yellow"}
          heading={"Compliance and Reporting"}
          text={
            "Maintain compliance with industry standards and regulations with our built-in reporting tools that generate detailed reports and analytics."
          }
        />
        <br />
        <br />
      </Box>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default WhyOgiso;
