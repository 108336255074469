import {
  Box,
  Button,
  Checkbox,
  rem,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// import { PhoneInput } from "react-international-phone";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { FaChevronRight } from "react-icons/fa6";

const CareSupport = forwardRef(
  (
    {
      title,
      view,
      state,
      active,
      setState,
      setActive,
      onSubmit,
      handleFormSubmit,
      selectedTab,
      saveHandle,
      edit,
    },
    ref
  ) => {
    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const isLabptopScreen = useMediaQuery("(max-width: 1024px)");

    const [showGuadrian, setShowGuardian] = useState(false);
    const [showPCP, setShowPCP] = useState(false);
    const [showPharmacy, setShowPharmacy] = useState(false);
    const [showCaseManger, setShowCaseManager] = useState(false);
    const [showAdditional, setShowAdditional] = useState(false);

    const { classes } = useStyles();
    const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;

    const initialProvider = {
      providerEmail: "",
      providerName: "",
      providerType: "",
      providerPhone: "",
    };
    const providerTypes = [
      "Allergist/Immunologist",
      "Anesthesiologist",
      "Cardiologist",
      "Dermatologist",
      "Emergency Medicine Physician",
      "Endocrinologist",
      "Gastroenterologist",
      "Geriatrician",
      "General Practitioner (GP)",
      "Hematologist",
      "Infectious Disease Specialist",
      "Nephrologist",
      "Neonatologist",
      "Neurologist",
      "Obstetrician/Gynecologist (OB/GYN)",
      "Oncologist",
      "Ophthalmologist",
      "Orthopedic Surgeon",
      "Otolaryngologist (ENT Specialist)",
      "Pathologist",
      "Pediatrician",
      "Pharmacist",
      "Physical Medicine and Rehabilitation Specialist",
      "Plastic Surgeon",
      "Podiatrist",
      "Psychiatrist",
      "Pulmonologist",
      "Radiologist",
      "Rheumatologist",
      "Sports Medicine Specialist",
      "Urologist",
    ];

    const [additionalProvider, setAdditionalProvider] = useState([
      { id: 1, ...initialProvider },
    ]);
    const topRef = useRef();
    const inputRef = useRef();
    const noGuardianRef = useRef();
    const noPharmacyRef = useRef();
    const noCaseRef = useRef();
    const noAdditionalRef = useRef();
    const form = useForm({
      initialValues: {
        guardianFirstName: "",
        guardianLastName: "",
        emergencyContactEmail: "",
        emergencyContactPhoneNumber: "",

        pcpFirstName: "",
        pcpLastName: "",
        pcpPhoneNumber: "",
        pcpEmail: "",
        pcpFaxNumber: "",

        pharmacyName: "",
        pharmacyPhoneNumber: "",
        pharmacyEmail: "",
        pharmacyFaxNumber: "",

        caseManagerFirstName: "",
        caseManagerLastName: "",
        caseManagerPhoneNumber: "",
        caseManagerEmail: "",
        caseManagerFaxNumber: "",
      },
      validate: {
        // guardianFirstName: (val) => (val ? null : "Required"),
        // guardianLastName: (val) => (val ? null : "Required"),
        // emergencyContactEmail: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
        // emergencyContactPhoneNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // pcpFirstName: (val) => (val ? null : "Required"),
        // pcpLastName: (val) => (val ? null : "Required"),
        // pcpPhoneNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // pcpEmail: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
        // pcpFaxNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // pharmacyName: (val) => (val ? null : "Required"),
        // pharmacyPhoneNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // pharmacyEmail: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
        // pharmacyFaxNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // caseManagerFirstName: (val) => (val ? null : "Required"),
        // caseManagerLastName: (val) => (val ? null : "Required"),
        // caseManagerPhoneNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
        // caseManagerEmail: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
        // caseManagerFaxNumber: (val) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val) ? null : "Invalid phone number"),
      },
    });

    useImperativeHandle(ref, () => ({
      confirm: () => {
        let values = form.values;
        if (
          !values?.caseManagerEmail &&
          values?.caseManagerPhoneNumber == "+1" &&
          !values?.caseManagerName
        ) {
          values.noCaseManager = true;
        } else {
          values.noCaseManager = false;
        }
        if (
          !values?.emergencyContactName &&
          !values?.emergencyContactEmail &&
          values?.emergencyContactPhoneNumber == "+1"
        ) {
          values.noGuardian = true;
        } else {
          values.noGuardian = false;
        }

        if (additionalProvider?.length === 0)
          values.noAdditionalProvider = true;
        let obj = {
          ...values,
          additionalProvider,
        };
        setState((p) => ({ ...p, form2: obj }));
        setActive(2);
      },
    }));

    const onNext = (values, formName) => {
      let valid = form?.validate();
      handleFormSubmit("careSupportInfo", "insuranceInfo");
      if (additionalProvider?.length === 0) values.noAdditionalProvider = true;

      if (Object.keys(valid?.errors).length === 0) {
        let updatedForm2 = {
          ...state.form2,
          ...values,
          additionalProvider,
          guardianFirstName: values?.guardianFirstName,
          guardianLastName: values?.guardianLastName,
          emergencyContactName:
            `${values?.guardianFirstName} ${values?.guardianLastName}`.trim(),

          caseManagerFirstName: values?.caseManagerFirstName,
          caseManagerLastName: values?.caseManagerLastName,
          caseManagerName:
            `${values?.caseManagerFirstName} ${values?.caseManagerLastName}`.trim(),

          pcpFirstName: values?.pcpFirstName,
          pcpLastName: values?.pcpLastName,
          pcpName: `${values?.pcpFirstName} ${values?.pcpLastName}`.trim(),

          // providerFirstName: values?.providerFirstName,
          // providerLastName: values?.providerLastName,
          // providerName: `${additionalProvider?.map(
          //   (provider) => provider?.providerFirstName
          // )} ${additionalProvider?.map(
          //   (provider) => provider?.providerLastName
          // )}`.trim(),
        };

        // Update the state with the merged form2 values
        setState((prevState) => ({
          ...prevState,
          form2: updatedForm2,
        }));

        setActive(2);
        // onSubmit();
      }
    };

    const onSave = () => {
      const values = form.getTransformedValues();
      let valid = form?.validate();
      if (Object.keys(valid?.errors).length === 0) {
        let updatedForm2 = {
          ...state.form2,
          ...values,
          additionalProvider,
          guardianFirstName: values?.guardianFirstName,
          guardianLastName: values?.guardianLastName,
          emergencyContactName:
            `${values?.guardianFirstName} ${values?.guardianLastName}`.trim(),

          caseManagerFirstName: values?.caseManagerFirstName,
          caseManagerLastName: values?.caseManagerLastName,
          caseManagerName:
            `${values?.caseManagerFirstName} ${values?.caseManagerLastName}`.trim(),

          pcpFirstName: values?.pcpFirstName,
          pcpLastName: values?.pcpLastName,
          pcpName: `${values?.pcpFirstName} ${values?.pcpLastName}`.trim(),

          // providerFirstName: values?.providerFirstName,
          // providerLastName: values?.providerLastName,
          // providerName: `${additionalProvider?.map(
          //   (provider) => provider?.providerFirstName
          // )} ${additionalProvider?.map(
          //   (provider) => provider?.providerLastName
          // )}`.trim(),
        };

        // Update the state with the merged form2 values
        setState((prevState) => ({
          ...prevState,
          form2: updatedForm2,
        }));

        saveHandle(null, updatedForm2, null);
        setActive(2);
        // onSubmit();
      }
    };
    const handleChangeProvider = (index, key, value) => {
      let temp = additionalProvider?.map((v, i) => {
        return i === index
          ? {
              ...v,
              [key]: value,
              providerName: v.providerFirstName + " " + v.providerLastName,
            }
          : v;
      });
      setAdditionalProvider(temp);
    };

    const addProvider = () => {
      setAdditionalProvider([
        ...additionalProvider,
        { id: Date.now(), ...initialProvider },
      ]);
    };
    const removeProvider = (provId) => {
      setAdditionalProvider(additionalProvider.filter((p) => p.id !== provId));
    };

    useEffect(() => {
      if (active === 0) topRef?.current?.scrollIntoView(); // { behavior: "smooth" }
      if (active === 0 && state?.form2) {
        if (
          state?.form2?.additionalProvider &&
          Array.isArray(state?.form2?.additionalProvider)
        )
          setAdditionalProvider(state?.form2?.additionalProvider);
        else setAdditionalProvider([]);
        form.setValues({
          ...(state?.form2
            ? {
                ...state?.form2,
                effectiveDate: state?.form2?.effectiveDate
                  ? new Date(state?.form2?.effectiveDate)
                  : new Date(),
              }
            : {}),
        });
        setShowCaseManager(state?.form2?.noCaseManager);
        setShowGuardian(state?.form2?.noGuardian);
        setShowAdditional(state?.form2?.noAdditionalProvider);
        if (state?.form2?.noCaseManager) noCaseRef.current.checked = true;
        // if (state?.form2?.noAdditionalProvider)
        //   noAdditionalRef.current.checked = true;
        if (state?.form2?.noGuardian) noGuardianRef.current.checked = true;
      }
    }, [active]);

    const guardianDisableHandle = (e) => {
      setShowGuardian(e?.target?.checked);
      if (e.target.checked === true) {
        [
          "guardianFirstName",
          "guardianLastName",
          "emergencyContactEmail",
          "emergencyContactPhoneNumber",
        ].map((item) => form.setFieldValue(item, ""));
      }
    };
    const pcpDisableHandle = (e) => {
      setShowPCP(e?.target?.checked);
      if (e.target.checked === true) {
        [
          "pcpFirstName",
          "pcpLastName",
          "pcpPhoneNumber",
          "pcpEmail",
          "pcpFaxNumber",
        ].map((item) => form.setFieldValue(item, ""));
      }
    };
    const pharmacyDisableHandle = (e) => {
      setShowPharmacy(e?.target?.checked);
      if (e.target.checked === true) {
        [
          "pharmacyName",
          "pharmacyPhoneNumber",
          "pharmacyEmail",
          "pharmacyFaxNumber",
        ].map((item) => form.setFieldValue(item, ""));
      }
    };
    const caseManagerDisableHandle = (e) => {
      setShowCaseManager(e?.target?.checked);
      if (e.target.checked === true) {
        [
          "caseManagerFirstName",
          "caseManagerLastName",
          "caseManagerPhoneNumber",
          "caseManagerEmail",
        ].map((item) => form.setFieldValue(item, ""));
      }
    };
    return (
      <form
        onSubmit={form?.onSubmit((values) => onNext(values, "careSupportInfo"))}
        ref={topRef}
      >
        <Text fz={24} weight={600}>
          {title}
        </Text>
        <Box className={classes.labelCheck}>
          <Text variant="text" fw={500} fz={18} ref={inputRef}>
            Guardian Information
          </Text>
          <Checkbox
            size={"xs"}
            color="yellow"
            disabled={view}
            label="No Guardian"
            ref={noGuardianRef}
            data-testid="noGuardianCheckbox"
            {...form.getInputProps("noGuardian")}
            onClick={guardianDisableHandle}
          />
        </Box>
        {!showGuadrian && (
          <SimpleGrid
            cols={isMobileScreen ? 1 : 4 || isLabptopScreen ? 2 : 3}
            className={classes.mwd}
          >
            <TextInput
              id="guardian-first-name"
              size="sm"
              disabled={view}
              sx={{ marginTop: 10 }}
              className={classes.myfont}
              label="Guardian First Name"
              placeholder="Enter First Name"
              {...form.getInputProps("guardianFirstName")}
            />
            <TextInput
              id="guardian-last-name"
              size="sm"
              disabled={view}
              sx={{ marginTop: 10 }}
              className={classes.myfont}
              label="Guardian Last Name"
              placeholder="Enter Last Name"
              {...form.getInputProps("guardianLastName")}
            />

            <TextInput
              id="guardian-phone-number"
              size="sm"
              type="number"
              disabled={view}
              sx={{ marginTop: 10 }}
              label={"Phone Number"}
              className={classes.myfont}
              placeholder="Enter Phone Number"
              {...form.getInputProps("emergencyContactPhoneNumber")}
            />
            <TextInput
              id="guardian-email"
              size="sm"
              label="Email"
              disabled={view}
              sx={{ marginTop: 10 }}
              placeholder="Enter Email"
              className={classes.myfont}
              {...form.getInputProps("emergencyContactEmail")}
            />
          </SimpleGrid>
        )}
        <Box className={classes.labelCheck}>
          <Text variant="text" fw={500} fz={18}>
            Primary Care Physician Information
          </Text>
          <Checkbox
            size={"xs"}
            color="yellow"
            disabled={view}
            ref={noGuardianRef}
            data-testid="noPCPCheckbox"
            {...form.getInputProps("noPCP")}
            label="No Primary Care Physician"
            onChange={pcpDisableHandle}
          />
        </Box>
        {!showPCP && (
          <SimpleGrid cols={isMobileScreen ? 1 : 4 || isLabptopScreen ? 2 : 3}>
            <TextInput
              id="pcp-first-name"
              size="sm"
              disabled={view}
              label="PCP First Name"
              sx={{ marginTop: 10 }}
              placeholder="Enter Name"
              className={classes.myfont}
              {...form.getInputProps("pcpFirstName")}
            />
            <TextInput
              id="pcp-last-name"
              size="sm"
              disabled={view}
              label="PCP Last Name"
              sx={{ marginTop: 10 }}
              placeholder="Enter Name"
              className={classes.myfont}
              {...form.getInputProps("pcpLastName")}
            />
            <TextInput
              id="pcp-number"
              size="sm"
              type="number"
              disabled={view}
              label={"Phone Number"}
              sx={{ marginTop: 10 }}
              className={classes.myfont}
              placeholder="Enter Phone Number"
              {...form.getInputProps("pcpPhoneNumber")}
            />
            <TextInput
              id="pcp-email"
              size="sm"
              type="email"
              label="Email"
              disabled={view}
              sx={{ marginTop: 10 }}
              placeholder="Enter email"
              className={classes.myfont}
              {...form.getInputProps("pcpEmail")}
            />
            <TextInput
              id="pcp-fax-number"
              size="sm"
              type="number"
              disabled={view}
              label={"Fax Number"}
              className={classes.myfont}
              placeholder="Enter Fax Number"
              {...form.getInputProps("pcpFaxNumber")}
            />
          </SimpleGrid>
        )}

        <Box className={classes.labelCheck}>
          <Text variant="text" fw={500} fz={18}>
            Pharmacy Information
          </Text>
          <Checkbox
            size={"xs"}
            color="yellow"
            disabled={view}
            ref={noPharmacyRef}
            data-testid="noPharmacyCheckbox"
            {...form.getInputProps("noPharamacy")}
            label="No Pharmacy"
            onChange={pharmacyDisableHandle}
          />
        </Box>
        {!showPharmacy && (
          <SimpleGrid cols={isMobileScreen ? 1 : 4 || isLabptopScreen ? 2 : 3}>
            <TextInput
              size="sm"
              id="pharmacy-name"
              disabled={view}
              label="Pharmacy Name"
              sx={{ marginTop: 10 }}
              placeholder="Enter Name"
              className={classes.myfont}
              {...form.getInputProps("pharmacyName")}
            />
            <TextInput
              id="pharmacy-fax-number"
              size="sm"
              type="number"
              disabled={view}
              label={"Fax Number"}
              sx={{ marginTop: 10 }}
              className={classes.myfont}
              placeholder="Enter Fax Number"
              {...form.getInputProps("pharmacyFaxNumber")}
            />
            <TextInput
              id="pharmacy-number"
              size="sm"
              type="number"
              disabled={view}
              label={"Phone Number"}
              sx={{ marginTop: 10 }}
              className={classes.myfont}
              placeholder="Enter Phone Number"
              {...form.getInputProps("pharmacyPhoneNumber")}
            />
            <TextInput
              id="pharmacy-email"
              size="sm"
              type="email"
              label="Email"
              disabled={view}
              sx={{ marginTop: 10 }}
              placeholder="Enter email"
              className={classes.myfont}
              {...form.getInputProps("pharmacyEmail")}
            />
          </SimpleGrid>
        )}

        {/* <Divider my="sm" variant="dashed" /> */}
        <Box className={classes.labelCheck}>
          <Text variant="text" fw={500} fz={18}>
            Case Manager Infomation
          </Text>
          <Checkbox
            size={"xs"}
            color="yellow"
            disabled={view}
            ref={noCaseRef}
            label="No Case Manager"
            data-testid="noCaseManagerCheckbox"
            {...form.getInputProps("noCaseManager")}
            onChange={caseManagerDisableHandle}
          />
        </Box>
        <SimpleGrid
          cols={isMobileScreen ? 1 : 4 || isLabptopScreen ? 2 : 3}
          className={classes.mwd}
        >
          {!showCaseManger && (
            <>
              <TextInput
                id="case-manager-fn"
                size="sm"
                disabled={view}
                sx={{ marginTop: 10 }}
                className={classes.myfont}
                placeholder="Enter First Name"
                label="Case Manager First Name"
                // labelProps={{ style: { fontSize: "10px" } }}
                {...form.getInputProps("caseManagerFirstName")}
              />
              <TextInput
                id="case-manager-ln"
                size="sm"
                disabled={view}
                sx={{ marginTop: 10 }}
                className={classes.myfont}
                placeholder="Enter Last Name"
                label="Case Manager Last Name"
                {...form.getInputProps("caseManagerLastName")}
              />

              <TextInput
                id="case-manager-number"
                size="sm"
                type="number"
                disabled={view}
                label={"Phone Number"}
                sx={{ marginTop: 10 }}
                className={classes.myfont}
                placeholder="Enter Phone Number"
                {...form.getInputProps("caseManagerPhoneNumber")}
              />

              <TextInput
                id="case-manager-email"
                size="sm"
                type="email"
                label="Email"
                disabled={view}
                // sx={{ marginTop: 10 }}
                placeholder="Enter email"
                className={classes.myfont}
                {...form.getInputProps("caseManagerEmail")}
              />
            </>
          )}
        </SimpleGrid>

        {!showAdditional && (
          <Box mt={20}>
            {additionalProvider?.map((value, index) => (
              <Box>
                {/* <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!view && (
                    <span
                      onClick={() => removeProvider(value.id)}
                      style={{
                        color: "#fab005",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Delete
                    </span>
                  )}
                </Box> */}

                <SimpleGrid
                  cols={isMobileScreen ? 1 : 3}
                  className={classes.mwd}
                  sx={{
                    paddingBottom: 30,
                  }}
                >
                  <Select
                    id="provider-type"
                    label="Provider Type"
                    size="md"
                    data={providerTypes}
                    placeholder="Select Type"
                    value={value?.providerType}
                    rightSection={icon}
                    sx={{ marginTop: 10 }}
                    className={classes.myfont}
                    onChange={(value) => {
                      handleChangeProvider(index, "providerType", value);
                    }}
                    disabled={view}
                  />
                  <TextInput
                    id="provider-fn"
                    label="Provider First Name"
                    size="md"
                    sx={{ marginTop: 10 }}
                    placeholder="Enter Provider First Name"
                    className={classes.myfont}
                    value={value?.providerFirstName}
                    onChange={(e) =>
                      handleChangeProvider(
                        index,
                        "providerFirstName",
                        e?.target?.value
                      )
                    }
                    disabled={view}
                  />
                  <TextInput
                    id="provider-ln"
                    label="Provider Last Name"
                    size="md"
                    sx={{ marginTop: 10 }}
                    placeholder="Enter Provider Last Name"
                    className={classes.myfont}
                    value={value?.providerLastName}
                    onChange={(e) =>
                      handleChangeProvider(
                        index,
                        "providerLastName",
                        e?.target?.value
                      )
                    }
                    disabled={view}
                  />
                  <TextInput
                    id="provider-number"
                    size="sm"
                    type="number"
                    disabled={view}
                    label={"Phone Number"}
                    sx={{ marginTop: 10 }}
                    className={classes.myfont}
                    value={value?.providerPhone}
                    placeholder="Enter Phone Number"
                    onChange={(e) => {
                      handleChangeProvider(
                        index,
                        "providerPhone",
                        e?.target?.value
                      );
                    }}
                    // {...form.getInputProps("caseManagerPhoneNumber")}
                  />
                  <TextInput
                    id="provider-email"
                    className={classes.myfont}
                    label="Provider Email"
                    size="md"
                    placeholder="Enter Provider Email"
                    value={value?.providerEmail}
                    onChange={(e) =>
                      handleChangeProvider(
                        index,
                        "providerEmail",
                        e?.target?.value
                      )
                    }
                    disabled={view}
                  />
                </SimpleGrid>
              </Box>
            ))}
            {!view && (
              <Button
                size="sm"
                color="yellow"
                variant="white"
                onClick={addProvider}
                style={{ marginLeft: "auto", display: "block" }}
              >
                Add Additional Contact
              </Button>
            )}
          </Box>
        )}
        {view ? (
          <div style={{ height: "30px" }} />
        ) : (
          <SimpleGrid cols={3} className={classes.btns}>
            <Button
              variant="white"
              color="yellow"
              size="sm"
              onClick={() => {
                handleFormSubmit("", "healthInfo");
              }}
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
                fontFamily: "inter",
              })}
            >
              Go Back
            </Button>
            <Box className={classes.centerBTN}>
              <Button
                color="yellow"
                size="sm"
                variant="outline"
                radius="md"
                styles={(theme) => ({
                  root: {
                    color: "#000",
                    borderColor: "#FFBA45",
                  },
                })}
                onClick={() => {
                  handleFormSubmit("", "insuranceInfo");
                }}
              >
                Add Later
              </Button>
              {edit && (
                <Button
                  id="individual-next"
                  className="edit-save"
                  color="yellow"
                  radius="sm"
                  size="sm"
                  // disabled={selectedTab?.disable === true ? false : true}
                  onClick={() => {
                    onSave();
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
            <Button
              id="individual-next"
              className="care-support-next"
              radius="sm"
              size="sm"
              color="yellow"
              type="submit"
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
              })}
            >
              Next
              <FaChevronRight />
            </Button>
          </SimpleGrid>
        )}
      </form>
    );
  }
);

CareSupport.displayName = "CareSupport";

export default CareSupport;
