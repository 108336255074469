import { createStyles } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important"
    },
   
  },
  mwd: {
    width: "100%"
  },
  myfont: {
    fontFamily: "Inter"
  },
  bordered: {
    borderColor: "#00000014",
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: "solid",
    padding: 20,
    marginTop: 20
  },
  field: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600
  },
  error: {
    color: "red",
    fontSize: "14px"
  }
}));

export default useStyles
