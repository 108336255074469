import React from "react";
import {
  Button,
  Group,
  Modal,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useSelector } from "react-redux";
import { createFolder, updateFolder } from "../../services/Document";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  camera: {
    position: "absolute",
    // position:'relative',
    // top:'28%',
    // right:'5%',
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  drop: {
    display: "none",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },
  dropImage: {
    img: {
      borderRadius: "50%",
    },
  },
  myfont: {
    fontFamily: "Inter",
    // marginTop: 20,
    overflow: "visible",
  },
  mycommmentfont: {
    fontFamily: "Inter",
    fontSize: "none !important",
    // marginTop: 20,
    overflow: "visible",
    "& .mantine-Textarea-label": {
      fontSize: "1rem !important",
    },
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const CreateFolderModal = ({ opened, onclose, title, data,client }) => {
  const { classes } = useStyles();
  const { createFolderLoading } = useSelector((state) => state.document);

  const form = useForm({
    initialValues: {
      name: data?.name || "",
    },
    validate: {
      name: (value) => (value ? null : "Please specify Folder Name"),
    },
  });
  const resetForm = () => {
    form?.reset();
    onclose();
  };

  const onSave = async (values) => {
    try {
      if (data) {
        let payload = {
          ...values,
          folder: data?._id,
          client
        };
        await updateFolder(payload);
      } else {
        let payload = {
          ...values,
          client
        };
        await createFolder(payload);
      }
      onclose();
      resetForm();
    } catch (error) {
      console.error("Error creating folder:", error.message);
    }
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          <Modal
            className={classes.mymodal}
            opened={opened}
            size={"xl"}
            onClose={() => {
              onclose();
            }}
            shadow="md"
            overlayProps={{ blur: 3 }}
            radius={"lg"}
            zIndex={1000}
            title={
              <Group>
                <img src="/assets/images/addfac.png" alt="" />
                <Text
                  sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}
                >
                  {title}
                </Text>
              </Group>
            }
            centered
          >
            <form onSubmit={form?.onSubmit((values) => onSave(values))}>
              <SimpleGrid
                cols={1}
                className={classes.mwd}
                sx={{
                  marginTop: 10,
                  paddingBottom: 30,
                }}
              >
                <TextInput
                  className={classes.myfont}
                  value={form?.values?.name}
                  label="Name"
                  size="md"
                  //   icon={<img src="/assets/images/profile.png" alt="home" />}
                  placeholder="Folder Name"
                  //onChange={(event) => setForm({ ...form, values: { ...form?.values, name: event.target.value } })}
                  {...form.getInputProps("name")}
                  //disabled={view}
                />
              </SimpleGrid>

              <Button
                color="yellow"
                sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                type="submit"
                loading={createFolderLoading}
              >
                {data ? "Update" : "Create"}
              </Button>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CreateFolderModal;
