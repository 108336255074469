import React from "react";
import { ScrollArea, Table, createStyles, Text, Box } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import RecordNotFound from "../RecordNotFound";
import StaffTableRow from "../StaffTableRow";
import TableLoader from "../TableLoader";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100vh",
    gap: "8rem",
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const StaffTable = () => {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { loading, staff } = useSelector((state) => state.staff);

  const rows = staff?.staffs?.map((row, i) => (
    <StaffTableRow row={row} key={i} />
  ));

  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      mt={30}
    >
      <Table
        // withColumnBorders
        withBorder
        fontSize={"sm"}
        highlightOnHover
        enablestickyheader
        verticalSpacing={"md"}
        sx={{ fontFamily: "Inter" }}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th style={{ minWidth: "200px" }}>Name</th>
            <th style={{ minWidth: "140px" }}>Job Title</th>
            <th style={{ minWidth: "140px" }}>Email Address</th>
            <th style={{ minWidth: "140px" }}>Active</th>
            <th style={{ minWidth: "140px" }}>Role</th>
            <th style={{ minWidth: "140px" }}>Reset Password</th>
            <th style={{ minWidth: "140px" }}></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={1000}>
                <TableLoader />
              </td>
            </tr>
          ) : rows?.length ? (
            rows
          ) : (
            <tr>
              <td colSpan={1000}>
                <Box className={classes.notFoundContainer}>
                  <RecordNotFound />
                  <Text className={classes.notFoundText}>
                    You don&apos;t have any record, click on "Add New Employee"
                  </Text>
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default StaffTable;
