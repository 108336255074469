import React from "react";
import useStyles from "./style";
import awsLogo from "./../../images/awsLogo.png";
import cactusLogo from "./../../images/cactusLogo.png";
import chanLogo from "./../../images/chanLogo.png";
import huesLogo from "./../../images/huesLogo.png";
import levelupLogo from "./../../images/levelupLogo.png";
import sitemarkLogo from "./../../images/sitemarkLogo.png";
import uturnLogo from "./../../images/uturnLogo.png";
import hipaaLogo from "../../../src/images/hipaaCompliance.jpg";
import { Box } from "@mantine/core";

const LogoCarousel = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.InlinePadding}>
      <Box className={classes.logoCarouselContainer}>
        <img
          src={awsLogo}
          width={60}
          height={60}
          alt="awsLogo"
          className={classes.logo}
        />
        <img src={sitemarkLogo} alt="sitemarkLogo" className={classes.logo} />
        <img src={levelupLogo} alt="levelupLogo" className={classes.logo} />
        <img src={uturnLogo} alt="uturnLogo" className={classes.logo} />
        <img src={cactusLogo} alt="cactusLogo" className={classes.logo} />
        <img src={chanLogo} alt="chanLogo" className={classes.logo} />
        <img src={huesLogo} alt="huesLogo" className={classes.logo} />
        <img src={hipaaLogo} alt="huesLogo" className={classes.logo} />
      </Box>
    </Box>
  );
};

export default LogoCarousel;
