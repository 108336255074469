import React from "react";

import { Group, Modal, Stepper, Text, createStyles } from "@mantine/core";

import { useEffect, useRef, useState } from "react";
import "react-international-phone/style.css";
import { useSelector } from "react-redux";
import {
  addPatient,
  editPatient,
  getAllPatientsModal,
} from "../../services/patient";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: "100%",
    overflow: "visible",
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-content": {
      overflowY: "inherit",
      //added by hamza
      maxHeight: "none !important",
    },
    "& .mantine-Modal-header": {
      borderRadius: "10px",
    },
    "& .mantine-Stepper-steps": {
      display: "none",
      marginBottom: "10px",
      marginTop: "10px",
      "@media (max-width:768px)": {
        overflowX: "auto",
        flexDirection: "row !important",
      },
    },
    "& .mantine-Stepper-separator": {
      "@media (max-width:768px)": {
        minHeight: "inherit",
      },
    },
    "& .mantine-Stepper-content": {
      height: "480px",
      overflowY: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
  myfont: {
    fontFamily: "Inter",
    // marginTop: 20,
    overflow: "visible",
  },
  providerPhone: {
    "& button": {
      height: "2.6rem",
      borderColor: "#ced4da",
    },
    "& .react-international-phone-input": {
      height: "2.6rem !important",
      borderColor: "#ced4da",
      width: "100%",
    },
  },
  label: {
    display: "inline-block",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
    marginBottom: "4px",
    marginTop: "22px",
  },
  providerLabel: {
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
    marginBottom: "4px",
    marginTop: "2px",
  },
  phoneInput: {
    "& button": {
      height: "2.3rem",
      borderColor: "#ced4da",
    },
    "& .react-international-phone-input": {
      height: "2.3rem !important",
      borderColor: "#ced4da",
      width: "100%",
    },
  },
  mwd: {
    width: "100%",
    "& .mantine-Modal-header": {
      zIndex: 100,
    },
  },
  textArea: {
    fontFamily: "Inter",
    "& .mantine-Textarea-input": {
      height: "200px",
    },
  },
  descriptionIcon: {
    position: "absolute",
    top: 10,
    left: 10,
  },
}));
const PatientViewModal = ({
  opened,
  close,
  title,
  form3,
  setClientDetails,
  view,
  setView,
  activeTab,
  page,
  data,
}) => {
  const navigate = useNavigate();
  const { allFacilities } = useSelector((state) => state?.facility);
  const { addPatientLoading } = useSelector((state) => state?.patient);
  const { classes } = useStyles();
  const [active, setActive] = useState(0);

  const edit = data ? true : false;
  // console.log('edit', edit);
  // console.log('form3',form3);
  const [state, setState] = useState(
    data
      ? data
      : {
          form1: null,
          form2: null,
          form3: null,
        }
  );
  const [options, setOptions] = useState([]);

  const form1Ref = useRef();
  const form2Ref = useRef();
  const form3Ref = useRef();

  useEffect(() => {
    const resp = [];
    allFacilities?.facilities?.map((val) => {
      if (val?.active) {
        resp?.push({
          label: val?.name,
          value: val?.id,
        });
      } else {
        resp?.push({
          label: val?.name,
          value: val?.id,
          disabled: true,
        });
      }
    });
    setOptions(resp);
  }, [allFacilities?.facilities]);

  useEffect(() => {
    if (data && activeTab) {
      setActive(activeTab);
    }
  }, [data, activeTab]);

  const onSave = async (form3) => {
    if (edit) {
      let obj = {
        id: data?.id,
        ...state,
        name: state?.form1?.name,
        form3,
        facilityId: state?.form1?.facility,
      };
      delete obj.active;

      if (edit) {
        console.log(
          "obj?.form1?.socialSecurityNumber",
          obj?.form1?.socialSecurityNumber
        );
        console.log("data?.form1?.lastFourDigits", data?.form1?.lastFourDigits);
        if (obj.form1.socialSecurityNumber === data?.form1?.lastFourDigits) {
          obj.form1.socialSecurityNumber = data?.form1?.socialSecurityNumber;
        }
      }
      // console.log(first)
      editPatient(obj, false, page);

      close();
    } else {
      let obj = {
        ...state,
        form3,
        facilityId: state?.form1?.facility,
        name: state?.form1?.name,
      };
      delete obj.active;

      let response = await addPatient(obj);
      if (response.status === 200) {
        const create = await swal({
          title: `Support Plan`,
          text: "Do you want to create support plan?",
          icon: "info",
          buttons: ["No", "Yes"],
        });
        if (create) {
          getAllPatientsModal();
          localStorage.setItem("performActionFlag", "false");
          navigate("/home/supportPlan", {
            state: {
              newPlan: true,
              patient: response?.data?.patient?.id,
              facility: response?.data?.patient?.facilityId,
            },
          });

          close();
        } else {
          close();
        }
      } else {
        await addPatient(obj);
        close();
      }
    }
  };

  const confirmFields = (request) => {
    if (view) return setActive(request);
    if (request < active) setActive(request);
    else {
      if (request === 1) form1Ref?.current?.confirm();
      else if (active === 1 && request === 2) form2Ref?.current?.confirm();
      else if (active === 0 && request === 2) {
        form1Ref?.current?.confirm();
        setActive(request);
      }
    }
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={810}
      onClose={() => {
        close();
        view && setView(false);
      }}
      shadow="md"
      radius={15}
      overlayProps={{ blur: 3 }}
      zIndex={1000}
      lockScroll={true}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {view ? "View Individual" : title ? title : "Edit Individual"}
          </Text>
        </Group>
      }
      centered
    >
      <Stepper
        size="xs"
        color="yellow"
        active={active}
        onStepClick={confirmFields}
        breakpoint="sm"
      >
        <Stepper.Step>
          <Form3
            view={view}
            ref={form3Ref}
            edit={edit}
            setActive={setActive}
            setState={setState}
            data={data}
            form3={form3}
            setClientDetails={setClientDetails}
            active={active}
            state={state}
            classes={classes}
            close={close}
            onSubmit={onSave}
            addPatientLoading={addPatientLoading}
          />
        </Stepper.Step>
      </Stepper>
    </Modal>
  );
};

export default PatientViewModal;
