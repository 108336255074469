import React from "react";

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  column: {
    width: "50px",
    // height: "calc(100% - 30px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgb(230,230,230)",
    backgroundColor: "#F2F2F2",
    "& p": {
      transform: "rotate(-90deg)",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "25px",
      letterSpacing: "1px",
    },
  },
}));

const ClientColumn = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.column}>
      <p>Clients</p>
    </div>
  );
};

export default ClientColumn;
