import { Button, createStyles } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";

// import "react-multi-carousel/lib/styles.css";
import MarLogTable from "../MarLogTab/MarLogTable";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "50px",
    height: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "60vh",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "30px",
    "& button": {
      height: "50px",
      "& img": {
        width: "30px",
      },
    },
  },
  mainHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MarListTab = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.box}>
        <MarLogTable islistenabled={true} />
      </div>
      {/* <div className={classes.btnContainer}>
        <Button
          leftIcon={<img src={"/assets/images/downloadFolder.png"} />}
          color="yellow"
          style={{ color: "black" }}
        >
          Download Document
        </Button>
      </div> */}
    </div>
  );
};

export default MarListTab;
