import React from "react";

export const PositionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#FFBA45"
        d="M11 1.375a2.76 2.76 0 00-2.75 2.75A2.76 2.76 0 0011 6.875a2.76 2.76 0 002.75-2.75A2.76 2.76 0 0011 1.375zm0 1.375c.768 0 1.375.607 1.375 1.375S11.768 5.5 11 5.5a1.365 1.365 0 01-1.375-1.375c0-.768.607-1.375 1.375-1.375zM6.832 15.357a.637.637 0 00-.039.004c-1.515.182-2.758.45-3.678.799-.46.175-.841.366-1.152.62-.31.254-.588.624-.588 1.093 0 .344.158.65.354.872.197.22.434.385.707.534.544.297 1.243.527 2.09.723 1.691.39 3.963.621 6.474.621 2.51 0 4.783-.231 6.475-.622.846-.195 1.544-.425 2.09-.722.272-.149.51-.313.705-.534.197-.222.355-.528.355-.872 0-.469-.277-.84-.588-1.093-.31-.254-.692-.445-1.152-.62-.92-.35-2.163-.617-3.678-.8a.688.688 0 00-.164 1.366c1.442.174 2.606.436 3.353.72.388.148.694.317.81.412-.587.44-1.438.662-2.041.803-1.543.356-3.74.587-6.165.587s-4.622-.23-6.165-.587a6.065 6.065 0 01-2.04-.803c.143-.105.584-.334.809-.412.747-.284 1.911-.546 3.353-.72a.687.687 0 00-.125-1.37z"
      ></path>
      <path
        fill="#FFBA45"
        d="M9.969 7.563c-1.131 0-2.063.931-2.063 2.062v3.438c0 .718.434 1.273 1 1.643v3.134a.687.687 0 001.374 0v-3.455a.688.688 0 00-.5-.66.678.678 0 01-.499-.662V9.624c0-.393.295-.688.688-.688h2.062c.393 0 .688.295.688.688v3.438a.677.677 0 01-.489.659.687.687 0 00-.493.66v3.458a.687.687 0 101.375 0v-3.142c.556-.371.982-.924.982-1.636V9.626c0-1.13-.932-2.063-2.063-2.063H9.97z"
      ></path>
    </svg>
  );
};
