import { createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  MainContainer: {
    // width: "100%",
    marginLeft: "280px",
    width: "calc(100% - 280px)",

    "@media (max-width: 1408px)": {
      marginLeft: "260px",
      width: "calc(100% - 260px)",
    },
    "@media (max-width: 1200px)": {
      marginLeft: "250px",
      width: "calc(100% - 250px)",
    },
    "@media (max-width: 992px)": {
      marginLeft: "220px",
      width: "calc(100% - 220px)",
    },
    "@media (max-width: 768px)": {
      marginLeft: "0px",
      width: "100%",
    },
  },
}));

const MainContainer = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.MainContainer}>{children}</div>;
};

export default MainContainer;
