import { createStyles } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: "100%",
    overflow: "visible",
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  myfont: {
    fontFamily: "Inter",
  },
  bordered: {
    borderColor: "#00000014",
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: "solid",
    padding: 20,
    marginTop: 20,
  },
  field: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
  },
  error: {
    color: "red",
    fontSize: "14px",
  },

  buttonBox: {
    display: "flex",
    gap: "10px",
    // justifyContent: "center",
    // "& >button": {
    //   backgroundColor: "transparent",
    //   border: "1px solid #CBCBCB66",
    //   color: "#000",
    // },
  },
  existingListButton: {
    width: "300px",
  },
  listCardBox: {
    display: "flex",
    gap: "20px",
    minHeight: "200px",
    paddingInline: "20px",
  },
  listCard: {
    width: "200px",
    background: "#fff",
    height: "190px",
    overflowY: "auto",
    position: "relative",
    background: "#ffeed2",
    borderRadius: "4px",
    borderLeft: "1px solid #ffba45",
    borderTop: "1px solid #CBCBCB66",
    borderBottom: "1px solid #CBCBCB66",
    borderRight: "1px solid #CBCBCB66",
    cursor: "pointer",
  },
  index: {
    margin: 0,
    padding: "5px",
  },
  title: {
    padding: "0px 20px 0px 50px",
    marginTop: 20,
  },
  tilteAndCheckbox: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    paddingInline: "20px",
    paddingBottom: "20px",
  },
  closeBtn: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },

  customListRow: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "center",
    marginTop: "20px",
  },
  selectBox: {
    width: "100%",
    "& input": {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
    },
  },
  toDoHeadingBox: {
    borderBottom: "1px solid #CBCBCB66",
    "& h2": {
      margin: "0px",
      paddingInline: "20px",
    },
  },
  dropdownItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default useStyles;
