export const lightTheme = {
  colorScheme: "light",
  primaryColor: "blue",
  other: {
    yellowColor: "#FFB435",
    primaryBtn: "#000000",
    black: "#000000",
    white: "#FFFFFF",
    grayColor: "#EDEDED",
    grayDarkColor: "#ccc",
    lightGray: "#666666", // or #eeeeee
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 10px", // Light shadow for light theme
    navbarBoxColor: "#f9f8f7"
  },
};

export const darkTheme = {
  yellowColor: "#FFB435",
  colorScheme: "dark",
  primaryColor: "teal",
  other: {
    primaryBtn: "linear-gradient(219.15deg, #FFDA7A 19.95%, #FFBA45 80.3%)",
    yellowColor: "#FFB435",
    black: "#ffffff",
    white: "#000000",
    grayColor: "#EDEDED",
    grayDarkColor: "#ccc",
    lightGray: "#b0b0b0",
    boxShadow: "rgba(255, 255, 255, 0.2) 0px 1px 5px, rgba(255, 255, 255, 0.3) 0px 1px 10px", // Softer white shadows for dark theme
    navbarBoxColor: "#181818"
  },
};

