import {
  ActionIcon,
  Group,
  Text,
  createStyles
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import swal from "sweetalert";
import { remoteNoteType } from "../../services/note";
import NoteTypeModal from "../NoteTypeModal";

const useStyles = createStyles((theme) => ({
  field: {
    borderWidth: 1,
    boderColor: "#232323",
    borderStyle: "solid",
    borderRadius: 8
  }
}));

const NoteTypeTableRow = ({ row }) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [view, setView] = useState(false);
  const fields = row.custom?.map((field, i) => (
    <Text className={classes.field} p={5} key={i}>
      {field?.field}
    </Text>
  ));
  // functions
  const remove = async (canDelete) => {
    // console.log({ canDelete });
    if (canDelete) {
      const willDelete = await swal({
        title: `Are you sure?`,
        text: "This action cannot be undone. Note Types are further associated to Note, Deleting note type can cause problems.",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Delete"]
      });
      if (willDelete) remoteNoteType(row?.id);
    } else {
      await swal({
        title: `Delete`,
        text: "You cannot delete this note type. ",
        icon: "warning"
      });
    }
  };
  function handleRowClick(event) {
    setView(true);
    open();
  }

  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={handleRowClick}>{row.name || "-"}</td>
      <td onClick={handleRowClick}>
        <Group>{fields}</Group>
      </td>
      <td>
        <Group wrap="nowrap">
          <NoteTypeModal
            opened={opened}
            close={() => {
              close();
              setView(false);
            }}
            title={"Edit Note Type"}
            data={row}
            view={view}
            setView={setView}
          />
          {!row?.general && (
            <ActionIcon
              color="yellow"
              variant="light"
              onClick={() => {
                setView(true);
                open();
              }}
            >
              <IconEye />
            </ActionIcon>
          )}
          {!row?.general && (
            <ActionIcon color="green" variant="light" onClick={open}>
              <IconEdit />
            </ActionIcon>
          )}
          {!row?.general && (
            <ActionIcon
              color="red"
              variant="light"
              onClick={() => remove(row?.canDelete)}
            >
              <IconTrash />
            </ActionIcon>
          )}
        </Group>
      </td>
    </tr>
  );
};

export default NoteTypeTableRow;
