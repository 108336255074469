import swal from "sweetalert";
import { https } from "../http";
import store from "../store";
import {
  setStaff,
  setLoading,
  setAddStaffLoading
} from "../store/reducers/staff";
import { showError, } from "../utils/index";

// GET
const getAllStaff = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/staff/${page}`);

    if (response?.status === 200) {
      store.dispatch(setStaff(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

// POST
const addStaff = async (data) => {
  try {
    store.dispatch(setAddStaffLoading(true));
    let response = await https().post("/staff", data);

    if (response?.status === 200) {
      swal({
        title: `Verify Your Email`,
        text: "Email verification link sent successfully",
        icon: "success",
        dangerMode: false,
      });
      getAllStaff("staff");
      return "success";
    }
  } catch (error) {
    store.dispatch(setAddStaffLoading(false));

    let string = error?.response?.data?.message;
    if (string?.includes("duplicate") && string.includes("email"))
      return showError("Staff with this email already exists");
    if (string?.includes("duplicate") && string.includes("name"))
      return showError("Staff with this name already exists")
    else showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

// PUT

const editStaff = async (data, showLoading = true) => {
  try {
    store.dispatch(setAddStaffLoading(true));

    let response = await https().put(`/staff`, data);

    if (response?.status === 200) {
      getAllStaff("staff");
      return true;
    }
  } catch (error) {
    store.dispatch(setAddStaffLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

// DELETE

const removeStaff = async (id, callback) => {
  try {
    store.dispatch(setAddStaffLoading(true));
    // let id = { ...data }?.id;
    // delete data.id;
    let response = await https().delete(`/staff/${id}`);

    if (response?.status === 200) {
      getAllStaff("staff");
      callback?.();
    }
  } catch (error) {
    store.dispatch(setAddStaffLoading(false));
    showError(error?.response?.data?.message, error?.response?.status)
  }
};

export { getAllStaff, addStaff, removeStaff, editStaff };
