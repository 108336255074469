import { Container, createStyles } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "../../../components/AddButton";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import AddTaskTypeModal from "../../../components/TaskTable/AddTaskTypeModal";
import TaskTypeTable from "../../../components/TaskTable/TaskTypeTable";
import Topbar from "../../../components/Topbar";
import { getTaskTypes } from "../../../services/task";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important"
    }
  },
  mwd: {
    width: "100%"
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end"
  }
}));

const NoteType = (props) => {
  const { taskTypesCount } = useSelector((state) => state.task);
  const { profile } = useSelector((state) => state.profile);
  // let userType = profile?.user?.type;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getTaskTypes(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber);
    setCurrentPage(pageNumber);
  };
  const { classes } = useStyles();
  const [taskTypeopened, taskTypeHandler] = useDisclosure(false);

  if (profile?.user?.type === "staff" && profile?.user?.role !== "admin") return null;

  return (
    <Container fluid className={classes.content} px={20}>
      <AddTaskTypeModal
        opened={taskTypeopened}
        close={() => {
          taskTypeHandler.close();
        }}
      />
      <Topbar title={"Task Types"} back="/home/tasks" />
      <div className={classes.btn}>
        <AddButton
          title={"Add Task Type"}
          onClick={() => {
            taskTypeHandler.open();
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh"
        }}
      >
        <TaskTypeTable />

        <CustomPagination
          data={taskTypesCount}
          itemsPerPage={10}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default NoteType;
