import { Box, createStyles } from "@mantine/core";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  footer: {
    width: "100%",
    padding: "100px 160px",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    gap: "1rem",
    background: theme.other.black,
    "@media (max-width: 768px)": {
      display: "flex",
      flexWrap: "wrap",
      padding: "1rem 2rem",
      gap: "1rem",
    },
  },
  footerContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    width: "100%",
    gap: "3rem",

    "@media (max-width: 675px)": {
      gap: "1rem",
      justifyContent: "center",
      alignItems: "center !important",
      flexDirection: "column",
    },
  },
  logo: {
    width: "152px",
    height: "56px",
  },
  menuHeading: {
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "36px",
    color: theme.other.white,
    margin: "0",
    marginBottom: "15px",
  },
  menus: {
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "24px",
    cursor: "pointer",
    margin: "5px 0",
    color: theme.other.white,
    "@media (max-width:675px)": {
      listStyleType: "none",
    },
    ":hover": {
      color: theme.other.yellowColor,
    },
  },
  menuList: {
    padding: "0",
    paddingLeft: "12px",
  },
  linkContainer: {
    "@media (max-width:675px)": {
      textAlign: "center",
    },
  },
  links: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    gap: "100px",
    "@media (max-width: 1024px)": {
      display: "flex",
      gap: "100px",
    },
    "@media (max-width: 675px)": {
      gap: "2rem",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  activeLink: {
    color: theme.colors.yellow[6],
  },
}));
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, cx } = useStyles();
  const theme = useSelector((state) => state.theme);
  const isActiveLink = (path) => location.pathname === path;
  return (
    <Box className={classes.footer}>
      <Box className={classes.footerContainer}>
        <img
          src={
            theme?.colorScheme !== "dark"
              ? "/assets/images/logo2-white-version-2.png"
              : "/assets/images/logo2.png"
            }
            alt="logo"
        />
        <Box className={classes.links}>
          <div className={classes.linkContainer}>
            <h3 className={classes.menuHeading}>Legal</h3>
            <ul className={classes.menuList}>
              <li
                className={cx(classes.menus, {
                  [classes.activeLink]: isActiveLink("/term-conditions"),
                })}
                onClick={() => {
                  navigate("/term-conditions");
                }}
              >
                Terms and Conditions
              </li>
              <li
                className={cx(classes.menus, {
                  [classes.activeLink]: isActiveLink("/privacy-policy"),
                })}
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </li>
              <li
                className={cx(classes.menus, {
                  [classes.activeLink]: isActiveLink("/cookies-policy"),
                })}
                onClick={() => {
                  navigate("/cookies-policy");
                }}
              >
                Cookie Policy
              </li>
            </ul>
          </div>
          <div className={classes.linkContainer}>
            <h3 className={classes.menuHeading}>Company</h3>
            <ul className={classes.menuList}>
              <li
                className={cx(classes.menus, {
                  [classes.activeLink]: isActiveLink("/whyogiso"),
                })}
                onClick={() => {
                  navigate("/whyogiso");
                }}
              >
                Why Ogiso?
              </li>
              <li
                className={cx(classes.menus, {
                  [classes.activeLink]: isActiveLink("/contact-us"),
                })}
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact us
              </li>
            </ul>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
