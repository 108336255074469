import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createFolderLoading: false,
  getFolderLoading: false,
  getFileLoading: false,
  renameFileLoading: false,
  uploadFileLoading: false,
  folders: [],
  files: null,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setFolderLoading(state, action) {
      return {
        ...state,
        getFolderLoading: action?.payload,
      };
    },
    setCreateFolderLoading(state, action) {
      return {
        ...state,
        createFolderLoading: action?.payload,
      };
    },
    setRenameFileLoading(state, action) {
      return {
        ...state,
        renameFileLoading: action?.payload,
      };
    },
    setFileLoading(state, action) {
      return {
        ...state,
        getFileLoading: action?.payload,
      };
    },
    setUploadFileLoading(state, action) {
      return {
        ...state,
        uploadFileLoading: action?.payload,
      };
    },

    setFolders(state, action) {
      return {
        ...state,
        getFolderLoading: false,
        folders: action.payload,
      };
    },
    setFiles(state, action) {
      return {
        ...state,
        getFileLoading: false,
        files: action.payload,
      };
    },
  },
});

export const {
  setFolderLoading,
  setCreateFolderLoading,
  setFileLoading,
  setUploadFileLoading,
  setFolders,
  setFiles,
  setRenameFileLoading,
} = documentSlice.actions;
export default documentSlice.reducer;
