import { Box, Container, createStyles } from "@mantine/core";

import AddButton from "../../../components/AddButton";
import FacilityTypeTable from "../../../components/FacilityTypeTable";
import FacilityTypeModal from "../../../containers/FacilityTypeModal";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import Topbar from "../../../components/Topbar";
import { getFacilityTypes } from "../../../services/facility";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
}));

const FacilityType = (props) => {
  const { facilityTypes } = useSelector((state) => state.facility);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    TrackPageView("Facility Type");
  }, []);

  useEffect(() => {
    getFacilityTypes(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Container fluid className={classes.content} px={20}>
      <FacilityTypeModal
        opened={opened}
        close={close}
        title={"Facility Type"}
      />

      <Topbar title={"Facility Type"} back="/home/facility" />
      <Box className={classes.btn}>
        <AddButton title={"New Facility Type"} onClick={open} />
      </Box>
      <Box className={classes.table}>
        <FacilityTypeTable />
        <CustomPagination
          data={facilityTypes.count}
          itemsPerPage={10}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default FacilityType;
