import {
  Box,
  Button,
  Modal,
  Select,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { useEffect, useState } from "react";
import useStyles from "./modalStyle";
import CloseCircle from "../CloseCircle";
import { DatePickerInput } from "@mantine/dates";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { getPlans, updateSupportDiscontinue } from "../../services/supportplan";
import { getNotes } from "../../services/note";

const DiscontinueCarePlanModal = ({
  id,
  data,
  client,
  opened,
  segment,
  onclose,
  outcomes,
  outcomeId,
  setRowData,
  activityId,
  currentPage,
  discontinueType,
  setShowCarePlanDetails,
}) => {
  const { classes } = useStyles();
  const [btnLoading, setBtnLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [discontinueReason, setDiscontinueReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [isCustomReason, setIsCustomReason] = useState(false);
  const [errors, setErrors] = useState({
    discontinueReason: "",
    customReason: "",
  });

  const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;

  useEffect(() => {
    if (opened) {
      resetState();
    }
  }, [opened]);

  const discontinueOptions = {
    careplan: [
      "Care Plan no longer needed",
      "Care Plan no longer effective",
      "Care Plan completed",
      "Other",
    ],
    outcomes: [
      "Outcome no longer needed",
      "Outcome no longer effective",
      "Outcome completed",
      "Other",
    ],
    activities: [
      "Support Activity no longer needed",
      "Support Activity no longer effective",
      "Support Activity completed",
      "Other",
    ],
  };

  const resetState = () => {
    setDiscontinueReason("");
    setCustomReason("");
    setIsCustomReason(false);
    setDate(new Date());
  };

  const handleClose = () => {
    resetState();
    onclose();
  };

  const onSave = async () => {
    setBtnLoading(true);
    try {
      let hasError = false;
      const newErrors = { discontinueReason: "", customReason: "" };

      if (!discontinueReason && !isCustomReason) {
        newErrors.discontinueReason = "Discontinue reason is required.";
        hasError = true;
      }

      if (isCustomReason && !customReason) {
        newErrors.customReason = "Discontinue reason is required.";
        hasError = true;
      }

      if (hasError) {
        setErrors(newErrors);
        setBtnLoading(false);
        return;
      }

      const reason = discontinueReason ? discontinueReason : customReason;
      const patload = {
        id,
        outcomes,
        outcomeId,
        activityId,
        discontinueType,
        clientId: client,
        medicationId: data?.id,
        discontinueReason: reason,
        discontinueDate: date?.toISOString(),
      };

      const response = await updateSupportDiscontinue(patload, client);

      if (response) {
        resetState();
        getNotes(1, client, false)
        handleClose();
      }
      setRowData(null);
      handleClose();
      setShowCarePlanDetails(false);
      getPlans(currentPage, client, segment);
      setBtnLoading(false);
    } catch (error) {
      console.error("Failed to discontinue:", error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleReasonChange = (value) => {
    if (value === "Other") {
      setIsCustomReason(true);
      setDiscontinueReason(null);
    } else {
      setIsCustomReason(false);
      setDiscontinueReason(value);
    }
    setErrors((prevErrors) => ({ ...prevErrors, discontinueReason: "" }));
  };
  const handleCustomReasonChange = (e) => {
    setCustomReason(e.currentTarget.value);
    setErrors((prevErrors) => ({ ...prevErrors, customReason: "" }));
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "1000px" }}>
          <Modal
            centered
            size={600}
            shadow="md"
            zIndex={1000}
            radius={"lg"}
            opened={opened}
            onClose={handleClose}
            overlayProps={{ blur: 3 }}
            className={classes.medicationModal}
          >
            <CloseCircle onclose={handleClose} />
            <Box className={classes.header}>
              <Text variant="text" fw={600} size={24}>
                Are you sure?
              </Text>
            </Box>
            <Box className={classes.mainContentWarpper}>
              <Box className={classes.mainContent}>
                <Text variant="text" fw={400} size={16} m={10}>
                  Please note that discontinued {discontinueType} will no longer
                  be accessible for the client.
                </Text>
                <Box className={classes.selectBoxDate}>
                  <Box className={classes.reasonBox}>
                    <Select
                      id="discontinue-reason"
                      data={discontinueOptions[discontinueType]}
                      value={discontinueReason}
                      onChange={handleReasonChange}
                      label="Discontinue Reason"
                      placeholder={
                        isCustomReason === true ? "Other" : "Discontinue Reason"
                      }
                      rightSectionPointerEvents="none"
                      rightSection={icon}
                      required
                      className={classes.select}
                      variant="filled"
                      style={{ flexGrow: 1 }}
                      error={errors?.discontinueReason}
                    />
                  </Box>
                  <Box className={classes.datePicker}>
                    <DatePickerInput
                      label="Discontinue Date"
                      size="sm"
                      value={date}
                      onChange={(date) => {
                        setDate(new Date(date));
                      }}
                      placeholder="mm/dd/yyyy"
                      rightSection={
                        <img
                          style={{ width: "30px" }}
                          src={"/assets/images/calendar-icon.png"}
                          alt="Icon"
                        />
                      }
                      withAsterisk
                      styles={{ rightSection: { pointerEvents: "none" } }}
                    />
                  </Box>
                </Box>
                <Box mt={15} className={classes.otherReason}>
                  {isCustomReason && (
                    <TextInput
                      required
                      variant="filled"
                      label="Other Reason"
                      value={customReason}
                      style={{ flexGrow: 1 }}
                      placeholder="Enter Reason"
                      className={classes.select}
                      error={errors?.customReason}
                      onChange={handleCustomReasonChange}
                    />
                  )}
                </Box>
                <Box className={classes.buttons}>
                  <Button
                    onClick={() => onclose()}
                    variant="outline"
                    color="red"
                    shape="circle"
                    radius="xl"
                  >
                    Cancel
                  </Button>
                  <Button
                    id="discontinue"
                    onClick={() => onSave()}
                    variant="filled"
                    color="red"
                    shape="circle"
                    radius="xl"
                    loading={btnLoading}
                  >
                    Discontinue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default DiscontinueCarePlanModal;
