import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Image,
  PasswordInput,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
  rem,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { IconCamera } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  updateProfile,
  updateProfilePhoto,
} from "../../../../services/profile";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import VerifyPassword from "../../../../components/VerifyPassword";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 40,
    marginBottom: 40,
    borderWidth: 1,
    borderRadius: 15,
    borderStyle: "solid",
    borderColor: "#FFDEABEE",
    display: "flex",
    flexDirection: "row",
    "& .mantine-Container-root": {
      padding: "0px !important",
    },
  },
  camera: {
    position: "absolute",
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  box: {
    width: 280,
    paddingTop: 47,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "#F2F2F2",
    textAlign: "center",
    "@media (max-width :40em)": {
      maxWidth: "100%",
      width: "100%",
    },
  },
  btn: {
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  inputIcon: {
    borderRightWidth: 1,
    borderRigthColor: "#CBCBCB",
    borderRightStyle: "solid",
    paddingRight: 5,
  },
  changepwd: {
    position: "absolute",
    color: "#FFBA45",
    fontFamily: "Inter",
    fontSize: rem(14),
    marginLeft: "auto",
    textDecoration: "underline",
    paddingBottom: 10,
    marginTop: 9,
  },
  button: { width: 122, fontFamily: "Inter", fontSize: 16, fontWeight: 500 },
  drop: {
    display: "none",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },
  wrapperContianer: {
    "@media (max-width :40em)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  inputContainer: {
    margin: "0 1rem",
    "@media (max-width :40em)": {
      display: "flex",
      flexDirection: "column",
      padding: "0 1rem",
      paddingBottom: "4rem",
    },
  },
  dropImage: {
    img: {
      borderRadius: "50%",
    },
  },
}));

const MyProfile = (props) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);

  const { profile, saveProfileLoading } = useSelector(
    (state) => state?.profile
  );
  const [objForUpdate, setObjForUpdate] = useState(null);
  const openRef = useRef();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: profile?.user?.email,
      name: profile?.user?.name,
    },
    validate: {
      name: (value) => (value ? null : "Required"),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      // password: (value) => (value ? null : "Required")
    },
  });
  const onFinish = (values) => {
    let obj = {
      name: values?.name,
    };
    if (values?.email != profile?.user?.email) {
      open();
      obj.email = values?.email;
      setObjForUpdate({
        name: values?.name,
        oldEmail: profile?.user?.email,
        newEmail: values?.email,
      });
    } else {
      updateProfile(obj, navigate);
    }
  };

  const onChangePhoto = (files) => {
    if (files?.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0]);
      updateProfilePhoto(formData);
    }
  };

  return (
    <>
      <form onSubmit={form?.onSubmit((values) => onFinish(values))}>
        <Card className={classes.wrapper} p={0}>
          <Container fluid sx={{ width: "100%" }}>
            <Grid columns={100} className={classes.wrapperContianer}>
              <Grid.Col span={30} className={classes.box}>
                <Center>
                  <Dropzone
                    onDrop={(files) => onChangePhoto(files)}
                    className={classes.drop}
                    openRef={openRef}
                    accept={MIME_TYPES.jpeg | MIME_TYPES.png}
                  ></Dropzone>
                  <Image
                    src={
                      profile?.user?.photo
                        ? profile?.user?.photo
                        : "/assets/images/avatar.png"
                    }
                    alt=""
                    width={111}
                    height={111}
                    onClick={() => openRef.current?.()}
                    className={classes.dropImage}
                  />
                  <IconCamera
                    color="white"
                    className={classes.camera}
                    onClick={() => openRef.current?.()}
                  />
                </Center>
                <Box sx={{ margin: 20 }}>
                  <Text ff={"Inter"} fw={700} fz={24}>
                    {profile?.user?.name}
                  </Text>
                  <Text ff={"Inter"} fw={600} fz={18} c={"#979797"} mt={10}>
                    {profile?.user?.position}
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={"auto"} mr={5} mt={30}>
                <Container fluid sx={{ width: "100%" }}>
                  <SimpleGrid cols={1} className={classes.inputContainer}>
                    <TextInput
                      label={"Name*"}
                      size="md"
                      icon={
                        <img
                          src={"/assets/images/profile.png"}
                          alt=""
                          className={classes.inputIcon}
                        />
                      }
                      {...form.getInputProps("name")}
                    />
                    <TextInput
                      label={"Email"}
                      size="md"
                      icon={
                        <img
                          src={"/assets/images/sms.png"}
                          alt=""
                          className={classes.inputIcon}
                        />
                      }
                      {...form.getInputProps("email")}
                      // disabled
                    />
                  </SimpleGrid>
                </Container>
              </Grid.Col>
            </Grid>
          </Container>
        </Card>
        <SimpleGrid
          cols={2}
          sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
        >
          <Button
            ml={"auto"}
            color="yellow"
            variant="light"
            className={classes.button}
            onClick={() => navigate("/home/dashboard")}
          >
            Cancel
          </Button>
          <Button
            mr={"auto"}
            type="submit"
            color="yellow"
            className={classes.button}
            loading={saveProfileLoading}
          >
            Save
          </Button>
        </SimpleGrid>
      </form>
      <VerifyPassword
        onclose={close}
        opened={opened}
        data={profile?.user}
        objForUpdate={objForUpdate}
      />
    </>
  );
};

export default MyProfile;
