/* eslint-disable jsx-a11y/alt-text */

import {
  Box,
  Button,
  Card,
  Center,
  Group,
  Text,
  createStyles,
  rem
} from "@mantine/core";

const PriceCard = (props) => {
  const {
    item,
    src,
    onClick,
    disabled,
    isPriceCard,
    isTrial,
    bothNormal,
    ...properties
  } = props;
  let {
    patient,
    name,
    promotion,
    additionalStaff,
    additionalClient,
    additionalFacility,
    notes,
    reports,
    duration,
    dailyUpdates,
    cost,
    facility,
    staff,
    id: packageId
  } = item;

  const names = {
    Personal: "Silver",
    Agency: "Gold",
    Trial: "Trial"
  };

  const facilityString =
    Number(facility) > 1
      ? `Up to ${facility} facilities`
      : `${facility} Facility`;

  const useStyles = createStyles((theme) => ({
    priceBox: {
      
      marginBottom: "100px",
      "@media (max-width:750px)": {
        marginBottom: "10px !important",
        marginTop: "10px !important"
      }
    },
    container: {
      maxWidth: "100%",
      minWidth: 230,
      background: props.theme === "black" ? "#232323" : "white",
      fontFamily: "Inter",
      padding: 30,
      "@media (max-width:865px)": {
        padding: 20
      },
      "@media (max-width:750px)": {
        border: "1px solid blue"
      }
    },
    startBtn: {
      fontSize: rem(18),
      marginTop: isTrial ? 20 : isPriceCard ? 20 : 15,
      marginBottom: isTrial ? 20 : isPriceCard ? 20 : 15,
      fontFamily: "Inter",

      "@media (max-width: 1440px)": {
        fontSize: rem(15)
      }
    },
    typedesc: {
      fontSize: rem(20),
      fontFamily: "Inter",
      color: props.theme === "black" ? "white" : "black"
    },
    type: {
      marginBottom: 10
    },
    p10: {
      marginTop: isTrial ? 20 : isPriceCard ? 12 : bothNormal ? 12 : 10,
      marginBottom:10,
      opacity: "90%",
      color: props.theme === "black" ? "white" : "#979797",
      fontSize: rem(18),
      fontFamily: "Inter",
      "@media (max-width: 1440px)": {
        fontSize: "15px",
        "& img": {
          width: "20px"
        },
        "@media (max-width:865px)": {
          "& img": {
            width: "15px"
          }
        }
      }
    },
    price: {
      fontSize: isTrial
        ? rem(36)
        : isPriceCard
        ? rem(30)
        : bothNormal
        ? rem(30)
        : rem(24),

      fontFamily: "Inter",
      fontWeight: 600,
      color: props.theme === "black" ? "white" : "black",
    },
    checkgroup: {
      marginTop: isTrial ? 46 : isPriceCard ? 30 : bothNormal ? 86 : 15

    },
    myfont: {
      fontFamily: "Inter",
      "@media (max-width:865px)": {
        fontSize: "12px"
      }
    },
    moreText: {
      color: "#979797",
      fontSize: "13px",
      textAlign: "center"
    }
  }));

  const { classes } = useStyles();

  function calculateDiscountedPrice(cost) {
    const discountPercentage = promotion;
    const discountedPrice =
      Number(cost) + Number(cost) * (discountPercentage / 100);
    return Number(discountedPrice).toFixed(2);
  }

  return (
    <Box
      {...properties}
      className={classes?.priceBox}
      sx={{
        marginTop: isPriceCard
          ? "50px !important"
          : bothNormal
          ? "50px"
          : "100px",
      }}
    >
      <Card
        shadow="sm"
        radius={"lg"}
        withBorder
        className={classes.container}
        padding={30}
      >
        <Group className={classes.type}>
          <img src={src} width={40} />
          {/* <Text className={classes.typedesc}>{names[name]}</Text> */}
          <Text className={classes.typedesc}>{name}</Text>
        </Group>
        <Text className={[classes.p10]}>
          <span className={classes.price}>${cost}</span>
          <span>/{duration}</span>
        </Text>
        {promotion ? (
          <Button variant="outline" disabled style={{ color: "black" }}>
            Promotion: <del>${calculateDiscountedPrice(cost)}</del> ({promotion}
            % off)
          </Button>
        ) : null}

        <div className={classes.checkgroup}>
          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>{facilityString}</Text>
          </Group>
          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>{staff} Staff Members</Text>
          </Group>
          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" color="red" />
            <Text className={classes.myfont}>{patient} Residents/Clients</Text>
          </Group>
          {cost == 0 && (
            <>
              <Group className={classes.p10}>
                <img src="/assets/images/check.png" alt="" />
                <Text className={classes.myfont}>
                  {dailyUpdates} Daily Updates
                </Text>
              </Group>
            </>
          )}
          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Medication Management/eMAR</Text>
          </Group>

          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Care Plans</Text>
          </Group>

          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Client Intake</Text>
          </Group>

          {name != "Free" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Task Management</Text>
          </Group>}

          {name != "Free" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Document Management</Text>
          </Group>}

          {name != "Free" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Text to Speech</Text>
          </Group>}

          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Custom Roles (coming soon)</Text>
          </Group>

          <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}>Staff Management (coming soon)</Text>
          </Group>

          {name == "Gold" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}> Forms (coming soon)
            </Text>
          </Group>}

          {name == "Gold" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}> Scheduler (coming soon)
            </Text>
          </Group>}

          {name == "Gold" && <Group className={classes.p10}>
            <img src="/assets/images/check.png" alt="" />
            <Text className={classes.myfont}> Sky AI (coming soon)
            </Text>
          </Group>}

          {/* {name != "Free" && ( */}
            <>
              <Group className={classes.p10}>
                <img src="/assets/images/check.png" alt="" />
                <Text className={classes.myfont}>{notes} Notes</Text>
              </Group>
              <Group className={classes.p10}>
                <img src="/assets/images/check.png" alt="" />
                <Text className={classes.myfont}>{reports} Reports</Text>
              </Group>
            </>
          {/* )} */}
        </div>

        <Card.Section>
          <Center>
            <Button
              disabled={disabled}
              color={"yellow"}
              className={classes.startBtn}
              size="md"
              onClick={() => onClick(packageId)}
            >
              {/* {isTrial === true ? "Try Free" : "Get Started"} */}
              {"Get Started"}
            </Button>
          </Center>
        </Card.Section>
        {cost != 0 && (
          <>
            <Text className={classes.moreText}>
              {`+$${additionalFacility || "20"}/m for additional Facility`}
            </Text>
            <Text className={classes.moreText}>
              {`+$${additionalStaff || "20"}/m for additional Staff`}
            </Text>
            <Text className={classes.moreText}>
              {`+$${additionalClient || "15"}/m for additional Client`}
            </Text>
          </>
        )}
      </Card>
    </Box>
  );
};

export default PriceCard;
