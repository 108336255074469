import {
  Anchor,
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { toggleTheme } from "../../store/reducers/theme";
import { useMediaQuery } from "@mantine/hooks";
import mixpanel from "mixpanel-browser";
import { PopupButton } from "react-calendly";

const useStyles = createStyles((theme) => ({
  PaddingInline: {
    paddingInline: "6rem",
    "@media (max-width:1224px)": {
      paddingInline: "1rem",
    },
  },
  container: {
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderBottom:
      "border-bottom: 1px solid linear-gradient(90deg, rgba(0, 0, 0, 0) 1.67%, #000000 51.29%, rgba(0, 0, 0, 0) 98.07%)",
    height: rem(80),
    "@media (max-width:768px)": {
      justifyContent: "space-between",
    },
  },
  borderContainer: {
    border: "1px solid gray",
    padding: ".5rem 1rem",
    marginBlock: ".5rem",
    borderRadius: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.other.navbarBoxColor,
    backdropFilter: "blur(100px)",
    "@media (max-width:870px)": {
      border: "unset",
      background: "#fff",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      marginBlock: "0px",
      borderRadius: "0px",
      padding: "0"
    },
  },
  leftSide: {
    display: "flex !important",
    justifyContent: "start !important",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
  },
  rightSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  loginsignup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 870px)": {
      display: "none",
    },
  },
  link: {
    marginTop: rem(38),
  },
  linkgroup: {
    display: "flex",
    gap: "40px",
    padding: "10px 20px",
    borderRadius: "10px",
    FontFamily: "Inter",
    marginRight: "5rem",
    // gap: 75,
    "@media (max-width: 1440px)": {
      marginRight: "1rem",
      gap: 25,
    },
    "@media (max-width: 1100px)": {
      gap: "20px",
    },
    "@media (max-width: 950px)": {
      padding: "10px 10px",
      gap: "10px",
    },
    "@media (max-width: 870px)": {
      display: "none",
    },
  },
  navbar_link: {
    color: theme.other.black,
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    cursor: "pointer",
    width: "max-content",
    lineHeight: "normal",
    "@media (max-width: 1440px)": {
      fontSize: "16px",
    },
    "&:hover": {
      borderBottom: "2px solid #000",
    },
  },
  getStarted: {
    background: theme.other.grayColor,
    "&:hover": {
      background: theme.other.grayColor,
      opacity: 0.8,
    },
    "@media (max-width: 870px)": {
      display: "none",
    },
  },
  hoverHeaderBtn: {
    display: "block",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  bookCalendly: {
    fontFamily: "Inter",
    background: theme.other.yellowColor,
    height: "3.125rem",
    padding: "0 1.625rem",
    borderRadius: "0.0625rem",
    width: "max-content",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: theme.other.yellowColor,
      opacity: 0.8,
    },
  },
  login: {
    background: theme.other.yellowColor,
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    color: theme.other.footerText,
    textAlign: "center",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    "@media (max-width: 950px)": {
      fontSize: "14px",
    },
    "@media (max-width: 768px)": {
      marginRight: 30,
    },
    "@media (max-width: 870px)": {
      display: "none",
    },
    "&:hover": {
      background: theme.other.yellowColor,
      opacity: 0.8,
    },
  },
  logo: {
    cursor: "pointer",
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important",
    },
    "@media (max-width:768px)": {
      marginLeft: 30,
    },
    "@media (max-width:330px)": {
      marginLeft: 10,
    },
  },
  menu: {
    width: 35,
    height: 35,
    objectFit: "contain",

    marginRight: 30,
    display: "none",
    "@media (max-width:870px)": {
      display: "block",
    },
    "@media (max-width:330px)": {
      marginRight: 10,
    },
  },

  customDrawer: {
    width: 0,
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#f2f2f2",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 1,
  },

  customDrawerOpen: {
    height: "100%",
    width: "250px !important",
    position: "fixed",
    top: 0,
    left: 0,
    // backgroundColor: "#FFBA45",
    backgroundColor: "#FFF",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 999,
  },

  drawerContent: {
    padding: "20px",
    width: "100%",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
    margin: "20px 10px",
  },
  textColor: {
    color: theme.primaryColor,
  },
  secondaryColor: {
    color: theme.other.successColor,
  },
  drawerBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "10px",
  },

  activeLink: {
    cursor: "pointer",
    color: theme.colors.yellow[6],
    "&:hover": {
      borderBottomWidth: "2px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.colors.yellow[6],
    },
  },
}));

const Header = () => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isLabptopScreen = useMediaQuery("(max-width: 1000px)");

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  // Function to toggle the navbar visibility
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    navigate("/");
  };

  const handleNavigateToFeatures = async () => {
    await mixpanel.track("scroll to features section")
    navigate("/", { state: { scrollToFeatures: true } });
  };

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const classNames = `${classes.container} ${
    location.pathname !== "/term-conditions" ? classes.PaddingInline : ""
  }`;

  const isActiveLink = (path) => location.pathname === path;

  return (
    <Box className={`${classNames} ${classes.PaddingInline}`}>
      <Box className={classes.borderContainer}>
        <div className={classes.leftSide}>
          <Image
            src={
              theme.colorScheme === "dark"
                ? "/assets/images/logo2-white-version-2.png"
                : "/assets/images/logo2.png"
            }
            fit="fill"
            className={classes.logo}
            onClick={handleClick}
          />
          <SimpleGrid className={classes.linkgroup}>
            <Text
              className={cx(classes.navbar_link, {
                [classes.activeLink]: isActiveLink("/"),
              })}
              onClick={() => {
                navigate("/");
              }}
            >
              <Center>Home</Center>
            </Text>
            <Text
              className={cx(classes.navbar_link, {
                [classes.activeLink]: isActiveLink("/whyogiso"),
              })}
              onClick={async () => {
                await mixpanel.track("navigate to why ogiso page")
                navigate("/whyogiso");
              }}
            >
              <Center>Why Ogiso</Center>
            </Text>
            <Text
              className={classes.navbar_link}
              onClick={handleNavigateToFeatures}
            >
              <Center>Features</Center>
            </Text>
            <Text
              className={cx(classes.navbar_link, {
                [classes.activeLink]: isActiveLink("/pricing"),
              })}
              onClick={async () => {
                await mixpanel.track("navigate to pricing page")
                navigate("/pricing");
              }}
            >
              <Center>Pricing</Center>
            </Text>
            <Text
              className={cx(classes.navbar_link, {
                [classes.activeLink]: isActiveLink("/contact-us"),
              })}
              onClick={async () => {
                await mixpanel.track("navigate to contact page")
                navigate("/contact-us");
              }}
            >
              <Center>Contact Us</Center>
            </Text>
          </SimpleGrid>
        </div>
        <img
          src="/assets/images/menu.png"
          className={classes?.menu}
          onClick={toggleDrawer}
          alt="menu"
        />
        <div className={classes.rightSide}>
          {/* <Button onClick={() => dispatch(toggleTheme())}>
          {theme.colorScheme === "light" ? "Dark" : "Light"} Mode
        </Button> */}
          <div className={classes.loginsignup}>
            <Text
              className={classes.navbar_link}
              onClick={() => {
                navigate("/login");
              }}
            >
              <Center>Login</Center>
            </Text>

            {/* <Anchor
              className={classes.navbar_link}
              onClick={() => {
                if (contactFeature) {
                  localStorage.setItem("contactFeature", true);
                  navigate("/", { state: { scrollFeature: true } });
                } else {
                  onClicks?.onPressFeature();
                }
              }}
            >
  
              <Center>signup</Center>
            </Anchor> */}
          </div>
          <Button
            color={theme.other.grayColor}
            size="lg"
            radius={"lg"}
            c={"#000"}
            className={`${classes.login}, ${classes.getStarted}`}
            onClick={() => navigate("/signup")}
          >
            {isLabptopScreen ? "Started" : "Get Started"}
          </Button>

          {/* <Link
            target="_blank"
            style={{ textDecoration: "none", color: "#000" }}
            to="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
          >
            <Button
              color={theme.other.yellowColor}
              size="lg"
              c={"#000"}
              className={classes.login}
            >
              {isLabptopScreen ? "Demo" : "Book a Demo"}
            </Button>
          </Link> */}

          <div className={classes.hoverHeaderBtn} onClick={() => mixpanel.track("demo booked")}>
            <PopupButton
              // className={classes.login}
              className={`${classes.login}, ${classes.bookCalendly}`}
              url="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
              rootElement={document.getElementById("root")}
              text={isLabptopScreen ? "Demo" : "Book a Demo"}
            />
          </div>
        </div>
      </Box>

      {isOpen ? (
        <div className={classes?.customDrawerOpen}>
          <div className={classes.drawerContent}>
            <span onClick={toggleNavbar}>
              <img width={25} src="/assets/images/closeIcon.png" alt="close" />
            </span>
            <Image
              src="/assets/images/logo3.png"
              mr={"auto"}
              fit="fill"
              ml={99}
              className={classes.logo}
            />
            <div className={classes.links}>
              <Anchor
                className={cx(classes.navbar_link, {
                  [classes.activeLink]: isActiveLink("/"),
                })}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Center>Home</Center>
              </Anchor>
              <Anchor
                className={cx(classes.navbar_link, {
                  [classes.activeLink]: isActiveLink("/whyogiso"),
                })}
                onClick={async () => {
                  await mixpanel.track("navigate to why ogiso page")
                  navigate("/whyogiso");
                }}
              >
                <Center>Why Ogiso</Center>
              </Anchor>
              <Anchor
                className={classes.navbar_link}
                onClick={handleNavigateToFeatures}
              >
                <Center>Features</Center>
              </Anchor>
              <Anchor
                className={cx(classes.navbar_link, {
                  [classes.activeLink]: isActiveLink("/pricing"),
                })}
                onClick={async () => {
                  await mixpanel.track("navigate to pricing page")
                  navigate("/pricing");
                }}
              >
                <Center>Pricing</Center>
              </Anchor>
              <Anchor
                className={cx(classes.navbar_link, {
                  [classes.activeLink]: isActiveLink("/contact-us"),
                })}
                onClick={async () => {
                  await mixpanel.track("navigate to contact page")
                  navigate("/contact-us");
                }}
              >
                <Center>Contact Us</Center>
              </Anchor>
            </div>
            <Box className={classes.drawerBtn}>
              <Button
                size="xs"
                c={"#000"}
                color="yellow"
                variant="white"
                className={classes.loginMobile}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              <Button
                size="xs"
                c={"#000"}
                color="gray"
                className={classes.loginMobile}
                onClick={() => navigate("/signup")}
              >
                Get Started
              </Button>

              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
              >
                <Button
                  size="sm"
                  c={"#000"}
                  color="yellow"
                  className={classes.loginMobile}
                >
                  Book a Demo
                </Button>
              </Link>
            </Box>
          </div>
        </div>
      ) : (
        <div className={classes.customDrawer}>
          <div className={classes.drawerContent}></div>
        </div>
      )}
    </Box>
  );
};

export default Header;
