import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginBlock: "2rem",
    width: "100vw important",
  },
  InlinePadding: {
    paddingInline: "6rem",
    "@media (max-width: 768px)": {
      paddingInline: "2rem",
    },
    "@media (max-width: 430px)": {
      paddingInline: "1rem",
    },
  },
  cards:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  primaryHeading: {
    fontFamily: "Inter",
    fontSize: "50px",
    fontWeight: "400",
    lineHeight: "50px",
    textAlign: "left",
    "@media (max-width: 768px)": {
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  cardContainer: {
    display: "flex",
    flex: 1,
    background: "blue",
    marginTop: "30px",
    "@media (max-width: 1040px)": {
      justifyContent: "center",
    },
  },
  cardColor: {
    height: "8px",
    width: "100%",
  },
  Card: {
    flex: "1",
    borderRadius: "0px !important",
    padding: "0px !important",
    position: "relative",
    height: "600px",
    "@media (max-width: 1750px)": {
      height: "730px",
    },
    "@media (max-width: 1300px)": {
      height: "800px",
    },
    "@media (max-width: 1100px)": {
      height: "1150px",
    },
    "@media (max-width: 900px)": {
      height: "750px",
    },
    "@media (max-width: 600px)": {
      height: "auto",
      width: "100%"
    },
  },
  cardContent: {
    padding: "18px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "between",
    justifyContent: "space-between",
  },
  cast:{
    "@media (max-width: 1024px)": {
      fontSize: "20px"
    },
  },
  cardButton: {
    width: "100%",
    background: theme.other.black,
    color: theme.other.white,
    "&:hover": {
      background: theme.other.black,
      opacity: "0.8",
    },
  },
  cardAmount: {
    display: "flex",
    alignItems: "center",
    lineHeight: "18px",
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Inter",
    color: theme.other.black,
  },
  cardList: {
    fontFamily: "Inter",
    padding: "1px 18px !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "23px",
    color: theme.other.black,
  },
  cardStatus: {
    padding: "10px 18px !important",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "26px",
    color: theme.other.black,
  },
  btnContainer: {
    padding: " 18px  !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "between",
    justifyContent: "space-between",
    width: "100%",
  },

  AccordionHeading: {
    fontSize: "45px",
    lineHeight: "66px",
    marginTop: "60px",
    fontWeight: "400",
    textTransform: "capitalize",
    color: theme.other.black,
    "@media (max-width: 760px)": {
      fontSize: "35px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "25px",
      lineHeight: "23px",
    },
  },

  question: {
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "36px",
    marginTop: "30px",
    color: theme.other.black,
    "@media (max-width: 760px)": {
      fontSize: "22px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "20px",
      lineHeight: "23px",
    },
  },

  answer: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: theme.other.blackLight,
    width: "90%",
    "@media (max-width: 760px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "@media (max-width: 560px)": {
      fontSize: "16px",
      lineHeight: "23px",
      width: "95%",
    },
  },

  chevron: {
    transition: "transform 0.2s ease", // O
    background: "red",
    "&[data-rotate]": {
      transform: "rotate(230deg) !important",
    },
  },
  
  icon: {
    background: "green",
    width: "16px",
    height: "16px",
  },
}));
