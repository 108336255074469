import React from "react";
import useStyles from "./style";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useForm } from "@mantine/form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { IconPhoto } from "@tabler/icons-react";

import {
  ActionIcon,
  Box,
  Text,
  Container,
  TextInput,
  Group,
  Button,
  Textarea,
  Select,
  FileButton,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { createTicket } from "../../services";
import Topbar from "../../components/Topbar";

const HaveProblem = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      issueType: "",
      message: "",
    },
    // validate: {
    //     email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
    // }
  });
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formdata = new FormData();
      file?.forEach((v) => {
        formdata.append("file", v);
      });
      formdata.append("message", values?.message);
      formdata.append("issueType", values?.issueType);

      await createTicket(formdata);

      setLoading(false);

      // Display success notification
      // swal({
      //   title: `Ticket`,
      //   text: "Ticket Created successfully",
      //   icon: "success",
      //   dangerMode: false,
      // });
      form.reset();
      navigate("/home/settings");
    } catch (error) {
      console.error("Error submitting form:", error);

      setLoading(false);
      // Display error notification
      swal({
        title: `Ticket`,
        text: "Ticket not created",
        dangerMode: true,
      });
    }
  };

  const handleClick = () => {
    navigate("/");
  };

  const onChangePhoto = (files) => {
    if (files?.length > 0) {
      setFile(files);
    }
  };

  return (
    <Container fluid className={classes.content} style={{ width: "100%" }}>
      <Topbar title={"Create Ticket"} />
      <div style={{ padding: "50px 0 0 0" }}>
        <Box>
          <h2 className={classes.contactHeading}>Have Problem?</h2>
          <p className={classes.email}>Generate Ticket</p>
          <p className={classes.message}>tell us your problem</p>
        </Box>
        <Box>
          <form onSubmit={form.onSubmit((values) => onFinish(values))}>
            <Select
              label="Issue Type"
              placeholder="Issue Type"
              data={[
                { value: "error", label: "Error" },
                { value: "broken", label: "Something is broken" },
                { value: "help", label: "Help" },
                { value: "other", label: "other" },
              ]}
              {...form.getInputProps("issueType")}
            />
            {/* <TextInput
                        radius="xs"
                        my={"lg"}
                        placeholder="NAME"
                        {...form.getInputProps("name")}
                    /> */}
            {/* <TextInput
              radius="xs"
              my={"lg"}
              placeholder="EMAIL"
              {...form.getInputProps("email")}
            /> */}
            <Group style={{ marginTop: "20px" }} position="center">
              {/* <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => (
                <Button {...props} className={classes.submitBTN}>
                  Upload image
                </Button>
              )}
            </FileButton> */}

              <Dropzone
                onDrop={(files) => onChangePhoto(files)}
                className={classes.drop}
                //   openRef={openRef}
                accept={MIME_TYPES.jpeg | MIME_TYPES.png}
              >
                <div className={classes.drop_inner_container}>
                  <IconPhoto size="3.2rem" stroke={1.5} />

                  <div>
                    <Text size="xl" inline>
                      Drag images here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      Attach as many files as you like, each file should not
                      exceed 5mb
                    </Text>
                  </div>
                </div>
              </Dropzone>
            </Group>

            {file && (
              <Text size="sm" align="center" mt="sm">
                {/* Picked files: {file.name} */}
                Picked files: {file?.map((item) => `${item.name}, `)}
              </Text>
            )}
            <Textarea
              radius="xs"
              my={"lg"}
              className={classes.messageInput}
              placeholder="MESSAGE"
              {...form.getInputProps("message")}
            />

            <Group position="center" width="100%" mt="md" my={"lg"}>
              <Button
                color="yellow"
                radius="xs"
                className={classes.submitBTN}
                width="100%"
                type="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Group>
          </form>
        </Box>
      </div>
    </Container>
  );
};

export default HaveProblem;
