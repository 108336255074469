import { https } from "../http";
import store from "../store";
import { setLoading, setDashboardCounts, setDashboardNotes } from "../store/reducers/dashboard";
import { showError } from "../utils/index";

// GET
const getDashboardCounts = async (showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/me/dashboard`);
    if (response?.status === 200) {
      store.dispatch(setDashboardCounts(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getTodaysNotes = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/note?page=all&date=today`);

    if (response?.status === 200) {
      store.dispatch(setDashboardNotes(response?.data?.notes));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { getDashboardCounts,getTodaysNotes };
