import { createStyles } from "@mantine/core";
import moment from "moment";
import { getPriorityColor } from "../../utils";


const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "9%",
    paddingBlock: "10px",
    background: "#fff",
    borderBottom: "6px solid transparent",
    "& h4": {
      margin: "0",
      fontSize: "1.2vw",
      fontWeight: 400,
      color: "#979797",
      textTransform: "uppercase",
    },
    "& h3": {
      margin: "0",
      fontSize: "1.8vw",
      fontWeight: 400,
    },
  },
  active: {
    borderBottom: "6px solid #FFBA45 !important",
  },
  box: {
    width: "80%",
    height: "3px",
  },
}));

const DateCard = ({ date, data }) => {
  const { classes } = useStyles();
  const _dateFormat = "YYYY-MM-DD";

  return (
    <div
      className={`${classes.card} ${
        moment(date).format(_dateFormat) === moment().format(_dateFormat)
          ? classes.active
          : ""
      }`}
    >
      <div
        className={classes.box}
        style={{ backgroundColor: getPriorityColor(date, data) }}
      />
      <h4>{moment(date).format("ddd")}</h4>
      <h3>{moment(date).format("DD")}</h3>
    </div>
  );
};

export default DateCard;
