import { Text, Box } from "@mantine/core";
import React from "react";
import useStyles from "./style";
import heroSectionImg from "./../../images/heroSectionImg.png";
import { DemoBookButton } from "../../components/BookADemoButton/DemoBookButton";

const HeroSection = () => {
  const { classes } = useStyles();
  return (
    <Box className={`${classes.heroSection} ${classes.InlinePadding}`}>
      <Text className={classes.primaryHeading} ta={"center"}>
        Empowering Excellence in Home & <br /> Community-Based Healthcare
      </Text>
      <img
        className={classes.leftBgImg}
        src={"/assets/images/leftBgImg.png"}
        alt="heroSectionImg"
      />
      <Box className={classes.buttonsContainer}>
        <DemoBookButton />
        <img
          src={heroSectionImg}
          alt="heroSectionImg"
          className={classes.heroImg}
        />
      </Box>
      <img
        className={classes.rightBgImg}
        src={"/assets/images/rightBgImg.png"}
        alt="heroSectionImg"
      />
    </Box>
  );
};

export default HeroSection;
