import {
  ActionIcon,
  Button,
  Group,
  Popover,
  ScrollArea,
  Select,
  Table,
  Text,
  createStyles,
} from "@mantine/core";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import TableLoader from "../../../../../components/TableLoader";
import { decrypt, formatDate, formatTime } from "../../../../../utils";
import moment from "moment";
import NotFound from "../../NotFound";
import {
  IconChevronDown,
  IconEdit,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react";
import MedicationModal from "../../../../../components/MedicationModal";
import { useDisclosure } from "@mantine/hooks";
import PRNMedicationModal from "../../../../../components/PRNMedicationModal";
import { deleteMar } from "../../../../../services";
import { setLogType } from "../../../../../store/reducers/mar";
import store from "../../../../../store";

export const isLate = (date, timeSlot, createdAt, type) => {
  const mergedDateTime = moment(date).set({
    hour: moment(timeSlot, "hh:mm A").hours(),
    minute: moment(timeSlot, "hh:mm A").minutes(),
    second: moment(timeSlot, "hh:mm A").seconds(),
  });

  if (type === "Taken") {
    if (moment(createdAt).isAfter(mergedDateTime.add(1, "hour"))) {
      return "Yes";
    } else {
      return "No";
    }
  } else {
    return "-";
  }
};

const MarLogTable = ({ islistenabled }) => {
  const { medicationLogs, loading, selectedClient } = useSelector((state) => state.mar);
  console.log("medicationLogs", medicationLogs)
  const { profile } = useSelector((state) => state.profile);
  const [scrolled, setScrolled] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [prnShow, setPRNShow] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const prnRef = useRef();
  const useStyles = createStyles((theme) => ({
    bg1: {
      "& td": {
        background: "#fff",
      },
    },
    bg2: {
      "& td": {
        background: "#F2F2F2",
      },
    },
  }));
  const { classes } = useStyles();

  const getColor = (key) => {
    const color = {
      Taken: "#167207",
      Missed: "#F00",
      Refused: "#6B9CFF",
      "Unable to Take": "rgba(255, 186, 69, 0.70)",
      upcoming: "#cbcbcb",
    };
    return color[key];
  };

  const compareDate = (date) => {
    var time1 = moment(date).startOf("day"); // Set time to the start of the day
    var today = moment().startOf("day");

    if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  const openPRNView = (data) => {
    setIsNew(false);
    prnRef?.current?.openView(data);
    setPRNShow(true);
  };
  const prnClose = () => {
    setSelectedRow(null);
    setPRNShow(false);
  };

  const DeleteComponent = ({ data }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [dltLoading, setDltLoading] = useState(false);

    const removeMedication = async () => {
      // setDltLoading(true);
      const obj = {
        client: data?.client?.id,
        marId: data?._id,
        type: data?.medicationType === "PRN" ? "prn" : "scheduled",
      };
      await deleteMar(obj);
      setDltLoading(false);
      setDeleteModal(false);
    };
    return (
      <Popover
        opened={deleteModal}
        width={300}
        position="bottom"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            color="red"
            variant="light"
            onClick={() => setDeleteModal(true)}
          >
            {data?.medicationType === "PRN" ? <IconTrash /> : <IconRefresh />}
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">
            Are you sure you want to{" "}
            {data?.medicationType === "PRN" ? "Delete" : "Reset"}?
          </Text>

          <Group grow mt={10}>
            <Button
              onClick={() => setDeleteModal(false)}
              size={"xs"}
              color="yellow"
            >
              Cancel
            </Button>
            <Button
              loading={dltLoading}
              size={"xs"}
              color={"red"}
              onClick={removeMedication}
            >
              {data?.medicationType === "PRN" ? "Delete" : "Reset"}
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <>
      {/* <Select
        icon={
          <img
            src={"/assets/images/filter-icon.png"}
            style={{
              width: "30px",
              height: "auto",
            }}
          />
        }
        onChange={(val) => {
          console.log("🚀 ~ MarLogTable ~ val:", val);
          store.dispatch(setLogType(val));
        }}
        defaultValue={"all"}
        data={[
          { value: "all", label: "All" },
          { value: "ytd", label: "YTD" },
          { value: "last_year", label: "Last Year" },
        ]}
        rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
        style={{
          width: 200,
          display: "flex",
          marginLeft: "auto",
          marginBottom: "20px",
        }}
      /> */}

      <ScrollArea
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        // sx={{ marginTop: "10px" }}
      >
        <Table
          withColumnBorders
          withBorder
          highlightOnHover
          enableStickyHeader
          sx={{ fontFamily: "Inter", minWidth: "1500px" }}
          fontSize={"sm"}
          verticalSpacing={"md"}
          id="marlogTable"
        >
          <TableHeader scrolled={scrolled} islistenabled={islistenabled} />

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={1000}>
                  <TableLoader />
                </td>
              </tr>
            ) : medicationLogs?.length > 0 ? (
              <>
                {medicationLogs
                  ?.slice(0)
                  ?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
                  // ?.filter((v) => v?.medicationType !== "upcoming")
                  ?.map((row, i) => {
                    const bg = i % 2 === 0;
                    return (
                      <tr key={i} className={bg ? classes.bg2 : classes.bg1}>
                        <td>{formatDate(row?.createdAt) || "-"}</td>
                        <td>
                          {decrypt(row?.client?.name, false) ||
                            row?.client?.name ||
                            "-"}
                        </td>
                        <td>{row?.medication?.medicationName || "-"}</td>
                        <td>{row?.refillCount || "-"}</td>
                        <td>{row?.newMedicineCount || "-"}</td>
                       
                        {/* <td>
                          <div style={{ display: "flex", gap: "5px" }}>
                            {islistenabled && (
                              <ActionIcon
                                color="yellow"
                                variant="light"
                                onClick={(e) => {
                                  setSelectedRow(row);
                                  if (row?.form3?.medicationType === "PRN") {
                                    return openPRNView(row);
                                  }

                                  if (compareDate(row?.date) === -1) {
                                    setIsViewOpen(true);
                                    open();
                                  } else if (compareDate(row?.date) === 0) {
                                    row?.medicationType === "upcoming"
                                      ? setIsViewOpen(false)
                                      : setIsViewOpen(true);
                                    open();
                                    // past date view true
                                  } else if (compareDate(row?.date) === 1) {
                                    setIsViewOpen(false);
                                  }
                                }}
                              >
                                <IconEdit />
                              </ActionIcon>
                            )}
                            {profile?.user?.type !== "staff" &&
                              row?.medicationType !== "upcoming" && (
                                <DeleteComponent data={row} />
                              )}
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
              </>
            ) : (
              <NotFound />
            )}
          </tbody>
        </Table>
      </ScrollArea>
      {/* <PRNMedicationModal
        isNew={isNew}
        ref={prnRef}
        opened={prnShow}
        onclose={prnClose}
        data={selectedClient}
      />
      <MedicationModal
        opened={opened}
        onclose={() => {
          close();
          setIsViewOpen(false);
          setSelectedRow(null);
        }}
        isViewOpen={isViewOpen}
        setIsViewOpen={setIsViewOpen}
        data={selectedRow}
      /> */}
    </>
  );
};

const TableHeader = ({ scrolled, islistenabled }) => {
  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      borderRadius: "16px",
      transition: "box-shadow 150ms ease",
      "& th": {
        backgroundColor: "#fff",
        color: "#000 !important",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },

    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));
  const { classes, cx } = useStyles();
  return (
    <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
      <tr>
        <th style={{ minWidth: "140px" }}>Refill Date</th>
        <th style={{ minWidth: "140px" }}>Client Name</th>
        <th style={{ minWidth: "140px" }}>Medication Name</th>
        <th style={{ minWidth: "80px" }}>Refill Quanity</th>
        <th style={{ minWidth: "80px" }}>Count after Refill</th>
        {/* <th style={{ minWidth: "80px" }}>Actions</th> */}
      </tr>
    </thead>
  );
};

export default MarLogTable;
