import { JsonInput, createStyles, rem } from "@mantine/core";

const useStyles = createStyles(() => ({
  contactHeading: {
    fontSize: "50px",
    fontWeight: "300",
    paddingTop: "4rem",
    margin: "0",
  },
  email: {
    fontSize: "18px",
    fontWeight: "300",
    margin: "0",
    fontFamily: "Inter",
  },
  message: {
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "6rem",
    fontFamily: "Inter",
  },
  messageInput: {
    fontFamily: "Inter",
    // border: '1px solid #ccc',
    // padding: '40px',
    marginBottom: "10px",
    width: "100%",
    resize: "none" /* Optional: Disable textarea resizing by the user */,
    overflowY: "hidden" /* Optional: Hide vertical scrollbar */,
  },
  submitBTN: {
    width: "100%",
  },
  issue: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  response: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  chat: {
    width: "70%",
    // background: "yellow",
    // boxShadow:
    //   "-9px 13px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
    padding: "20px",
    borderRadius: "5px",
    border: '1px solid #fab005'
  },
  text3: {
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: rem(22),
    color: "#979797",
    marginTop: rem(26),
    marginBottom: rem(4),
    "@media (max-width: 1440px)": {
      fontSize: rem(18),
    },
  },
  messageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  messageContainer: {
    width: "100%",
  },
  footer_wrapper: {
    display: "flex",
    padding: "50px 100px",
    flexDirection: "row",
    gap: "100px",
    flexWrap: "wrap",
    "@media (max-width: 1440px)": {
      gap: "50px",
    },
    "@media (max-width: 1024px)": {
      gap: "50px",
      padding: "50px 40px",
    },
    "@media (max-width: 800px)": {
      padding: "30px",
    },
    "@media (max-width: 500px)": {
      padding: "50px",
    },
    "@media (max-width: 425px)": {
      padding: "30px",
    },
    "@media (max-width: 325px)": {
      padding: "20px",
    },
  },
  footer_left: {
    flex: 1,
  },
  footer_text: {
    fontFamily: "Inter",
    fontSize: rem(20),
    fontWeight: 400,
    color: "#292828",
    marginTop: 23,
    "@media (max-width: 1440px)": {
      fontSize: rem(16),
    },
  },
  footer_center: {
    width: "250px !important",
    marginTop: "20px",
    "& .mantine-Text-root": {
      margin: "10px",
    },
  },
  footer_right: {
    width: "400px !important",
    marginTop: "20px",
  },
}));
export default useStyles;
