import { Link } from "react-router-dom";
import useStyles from "./style";
import Header from "../../components/Header/Header";
import { Box } from "@mantine/core";
import StartedBanner from "../LandingPage/StartedBanner";
import Footer from "../LandingPage/Footer";
import { useEffect } from "react";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";

const TCandPP = () => {
  const { classes } = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    pageInfoAnalytics("Term and Conditions Page Viewed", "Term and Conditions Page");
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <Header />
      </Box>
      <div className={classes.TCandPP}>
        <h2 className={classes.mainHeading}>TERMS AND CONDITIONS</h2>
        <span>Last updated December 16, 2023</span>
        <h2 className={classes.mainHeading}>AGREEMENT TO OUR LEGAL TERMS</h2>
        <p className={classes.text}>
          We are Lumen Christi of Arizona LLC ("Company," "we," "us," "our"), a
          company registered in Arizona, United States at 2626 E Havard St,
          Phoenix, AZ 85008.
          <br />
          <br />
          We operate the website{" "}
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={"https://ogiso.io"}
          >
            {" "}
            https://www.ogiso.io{" "}
          </Link>{" "}
          (the "Site"), as well as any other related products and services that
          refer or link to these legal terms (the "Legal Terms") (collectively,
          the "Services").
          <br />
          <br />
          You can contact us by phone at 7573382712, email at support@ogiso.io,
          or by mail to 2626 E Havard St, Phoenix, AZ 85008, United States.
          <br />
          <br />
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("you"), and Lumen
          Christi of Arizona LLC, concerning your access to and use of the
          Services. You agree that by accessing the Services, you have read,
          understood, and agreed to be bound by all of these Legal Terms. IF YOU
          DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
          PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
          <br />
          <br />
          We will provide you with prior notice of any scheduled changes to the
          Services you are using. The modified Legal Terms will become effective
          upon posting or notifying you by support@ogiso.io, as stated in the
          email message. By continuing to use the Services after the effective
          date of any changes, you agree to be bound by the modified terms.
          <br />
          <br />
          The Services are intended for users who are at least 18 years old.
          Persons under the age of 18 are not permitted to use or register for
          the Services. We recommend that you print a copy of these Legal Terms
          for your records.
          <br />
          <br />
        </p>
        <h4 className={classes.mainHeading}>TABLE OF CONTENTS</h4>
        <p className={classes.text}>
          <a style={{ textDecoration: "none" }} href="#our-service">
            1. OUR SERVICES
          </a>
          <br />
          <br />
          <a
            style={{ textDecoration: "none" }}
            href="#INTELLECTUAL-PROPERTY-RIGHTS"
          >
            2. INTELLECTUAL PROPERTY RIGHTS
          </a>

          <br />
          <br />

          <a style={{ textDecoration: "none" }} href="#USER-REPRESENTATIONS">
            3. USER REPRESENTATIONS
          </a>
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#USER-REGISTRATION">
            4. USER REGISTRATION
          </a>
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#PURCHASES-AND-PAYMENT">
            5. PURCHASES AND PAYMENT
          </a>
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#FREE-TRIAL">
            6. FREE TRIAL
          </a>
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#CANCELLATION">
            7. CANCELLATION
          </a>

          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#PROHIBITED-ACTIVITIES">
            8. PROHIBITED ACTIVITIES
          </a>
          <br />
          <br />

          <a
            style={{ textDecoration: "none" }}
            href="#USER-GENERATED-CONTRIBUTIONS"
          >
            9. USER GENERATED CONTRIBUTIONS
          </a>
          <br />
          <br />
          {/* <Link
          className={classes.linkStyle}
          target="_blank"
          to={
            "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/terms-of-service#services"
          }
        >
          10. CONTRIBUTION LICENSE
        </Link> */}
          <a style={{ textDecoration: "none" }} href="#CONTRIBUTION-LICENSE">
            10. CONTRIBUTION LICENSE
          </a>
          <br />
          <br />
          <a style={{ textDecoration: "none" }} href="#SERVICES-MANAGEMENT">
            11. SERVICES MANAGEMENT
          </a>
          <br />
          <br />

          {/* <a style={{ textDecoration: "none" }} href="#PRIVACY-POLICY">
          12. PRIVACY POLICY
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#DMCA">
          13. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#TERM-AND-TERMINATION">
          14. TERM AND TERMINATION
        </a>
        <br />
        <br />
        <a
          style={{ textDecoration: "none" }}
          href="#MODIFICATIONS-AND-INTERRUPTIONS"
        >
          15. MODIFICATIONS AND INTERRUPTIONS{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#GOVERNING-LAW">
          16. GOVERNING LAW{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#DISPUTE-RESOLUTION">
          17. DISPUTE RESOLUTION{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#CORRECTIONS">
          18. CORRECTIONS{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#DISCLAIMER">
          19. DISCLAIMER{" "}
        </a>
        <br />
        <br />

        <a style={{ textDecoration: "none" }} href="#LIMITATIONS-OF-LIABILITY">
          20. LIMITATIONS OF LIABILITY{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#INDEMNIFICATION">
          21. INDEMNIFICATION{" "}
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#USER-DATA">
          22. USER DATA
        </a>
        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#ECTS">
          23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </a>
        <br />
        <br />
        <a
          style={{ textDecoration: "none" }}
          href="#CALIFORNIA-USERS-AND-RESIDENTS"
        >
          24. CALIFORNIA USERS AND RESIDENTS{" "}
        </a>

        <br />
        <br />
        <a style={{ textDecoration: "none" }} href="#MISCELLANEOUS">
          25. MISCELLANEOUS{" "}
        </a>
        <br />
        <br />

        <a style={{ textDecoration: "none" }} href="#CONTACT-US">
          26. CONTACT US{" "}
        </a> */}
        </p>
        <h2 id="our-service" className={classes.mainHeading}>
          1. OUR SERVICES
        </h2>
        <p className={classes.text}>
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
          <br />
          <br />
          The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use the Services. You may not use the Services in a way that would
          violate the Gramm-Leach-Bliley Act (GLBA).
        </p>
        <h2 id="INTELLECTUAL-PROPERTY-RIGHTS" className={classes.mainHeading}>
          2. INTELLECTUAL PROPERTY RIGHTS
        </h2>
        <span>Our intellectual property</span>
        <p className={classes.text}>
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the "Content"), as well as the
          trademarks, service marks, and logos contained therein (the "Marks").
          <br />
          <br />
          Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world.
          <br />
          <br />
          The Content and Marks are provided in or through the Services "AS IS"
          for your internal business purpose only.
        </p>
        <span>Your use of our Services</span>
        <p className={classes.text}>
          Subject to your compliance with these Legal Terms, including the "
          <a style={{ textDecoration: "none" }} href="#PROHIBITED-ACTIVITIES">
            8. PROHIBITED ACTIVITIES
          </a>
          " section below, we grant you a non-exclusive, non-transferable,
          revocable license to:
          <ul style={{ textAlign: "left", listStyleType: "square" }}>
            <li>access the Services; and</li>
            <li>
              download or print a copy of any portion of the Content to which
              you have properly gained access.
            </li>
          </ul>
        </p>
        <p className={classes.text}>
          solely for your internal business purpose.
          <br />
          <br />
          Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
          <br />
          <br />
          If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms,
          please address your request to: support@ogiso.io. If we ever grant you
          the permission to post, reproduce, or publicly display any part of our
          Services or Content, you must identify us as the owners or licensors
          of the Services, Content, or Marks and ensure that any copyright or
          proprietary notice appears or is visible on posting, reproducing, or
          displaying our Content.
          <br />
          <br />
          We reserve all rights not expressly granted to you in and to the
          Services, Content, and Marks.
          <br />
          <br />
          Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.
        </p>
        <span>Your submissions and contributions</span>
        <p className={classes.text}>
          Please review this section and the "
          <a style={{ textDecoration: "none" }} href="#PROHIBITED-ACTIVITIES">
            PROHIBITED ACTIVITIES
          </a>
          " section carefully prior to using our Services to understand the (a)
          rights you give us and (b) obligations you have when you post or
          upload any content through the Services.
          <br />
          <br />
          Submissions: By directly sending us any question, comment, suggestion,
          idea, feedback, or other information about the Services
          ("Submissions"), you agree to assign to us all intellectual property
          rights in such Submission. You agree that we shall own this Submission
          and be entitled to its unrestricted use and dissemination for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.
          <br />
          <br />
          Contributions: The Services may invite you to chat, contribute to, or
          participate in blogs, message boards, online forums, and other
          functionality during which you may create, submit, post, display,
          transmit, publish, distribute, or broadcast content and materials to
          us or through the Services, including but not limited to text,
          writings, video, audio, photographs, music, graphics, comments,
          reviews, rating suggestions, personal information, or other material
          ("Contributions"). Any Submission that is publicly posted shall also
          be treated as a Contribution.
          <br />
          <br />
          You understand that Contributions may be viewable by other users of
          the Services.
          <br />
          <br />
          When you post Contributions, you grant us a license (including use of
          your name, trademarks, and logos): By posting any Contributions, you
          grant us an unrestricted, unlimited, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free, fully-paid, worldwide
          right, and license to: use, copy, reproduce, distribute, sell, resell,
          publish, broadcast, retitle, store, publicly perform, publicly
          display, reformat, translate, excerpt (in whole or in part), and
          exploit your Contributions (including, without limitation, your image,
          name, and voice) for any purpose, commercial, advertising, or
          otherwise, to prepare derivative works of, or incorporate into other
          works, your Contributions, and to sublicense the licenses granted in
          this section. Our use and distribution may occur in any media formats
          and through any media channels.
          <br />
          <br />
          This license includes our use of your name, company name, and
          franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide.
          <br />
          <br />
          You are responsible for what you post or upload: By sending us
          Submissions and/or posting Contributions through any part of the
          Services or making Contributions accessible through the Services by
          linking your account through the Services to any of your social
          networking accounts, you:
          <ul style={{ textAlign: "left", listStyleType: "square" }}>
            <li>
              confirm that you have read and agree with our "
              <a
                style={{ textDecoration: "none" }}
                href="#PROHIBITED-ACTIVITIES"
              >
                PROHIBITED ACTIVITIES
              </a>
              " and will not post, send, publish, upload, or transmit through
              the Services any Submission nor post any Contribution that is
              illegal, harassing, hateful, harmful, defamatory, obscene,
              bullying, abusive, discriminatory, threatening to any person or
              group, sexually explicit, false, inaccurate, deceitful, or
              misleading;
            </li>
            <li>
              to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission and/or Contribution;
            </li>
            <li>
              warrant that any such Submission and/or Contributions are original
              to you or that you have the necessary rights and licenses to
              submit such Submissions and/or Contributions and that you have
              full authority to grant us the above-mentioned rights in relation
              to your Submissions and/or Contributions; and
            </li>
            <li>
              warrant and represent that your Submissions and/or Contributions
              do not constitute confidential information.
            </li>
          </ul>
          You are solely responsible for your Submissions and/or Contributions
          and you expressly agree to reimburse us for any and all losses that we
          may suffer because of your breach of (a) this section, (b) any third
          party’s intellectual property rights, or (c) applicable law.
          <br />
          <br />
          We may remove or edit your Content: Although we have no obligation to
          monitor any Contributions, we shall have the right to remove or edit
          any Contributions at any time without notice if in our reasonable
          opinion we consider such Contributions harmful or in breach of these
          Legal Terms. If we remove or edit any such Contributions, we may also
          suspend or disable your account and report you to the authorities.
        </p>
        <h4>Copyright infringement</h4>
        <p className={classes.text}>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately refer to the "
          {/* <Link
          className={classes.linkStyle}
          target="_blank"
          to={
            "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/terms-of-service#dmca"
          }
        >
          DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
        </Link> */}
          <a style={{ textDecoration: "none" }} href="#DMCA">
            DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
          </a>
          " section below.
        </p>
        <h2 id="USER-REPRESENTATIONS" className={classes.mainHeading}>
          3. USER REPRESENTATIONS
        </h2>
        <p className={classes.text}>
          By using the Services, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Legal
          Terms; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Services through automated or
          non-human means, whether through a bot, script or otherwise; (6) you
          will not use the Services for any illegal or unauthorized purpose; and
          (7) your use of the Services will not violate any applicable law or
          regulation.
          <br />
          <br />
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services
          (or any portion thereof).
        </p>
        <h2 id="USER-REGISTRATION" className={classes.mainHeading}>
          4. USER REGISTRATION
        </h2>
        <p className={classes.text}>
          You may be required to register to use the Services. You agree to keep
          your password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable
        </p>
        <h2 id="PURCHASES-AND-PAYMENT" className={classes.mainHeading}>
          5. PURCHASES AND PAYMENT
        </h2>
        <p className={classes.text}>
          We accept the following forms of payment:
          <br />
          <br />
          <ul style={{ listStyleType: "none" }}>
            <li>- Visa</li>
            <li>- Mastercard</li>
            <li>- Discover</li>
            <li>- American Express</li>
          </ul>
          <br />
          <br />
          You agree to provide current, complete, and accurate purchase and
          account information for all purchases made via the Services. You
          further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed. Sales tax will be added to the price of purchases as deemed
          required by us. We may change prices at any time. All payments shall
          be in US dollars.
          <br />
          <br />
          You agree to pay all charges at the prices then in effect for your
          purchases and any applicable shipping fees, and you authorize us to
          charge your chosen payment provider for any such amounts upon placing
          your order. If your order is subject to recurring charges, then you
          consent to our charging your payment method on a recurring basis
          without requiring your prior approval for each recurring charge, until
          such time as you cancel the applicable order. We reserve the right to
          correct any errors or mistakes in pricing, even if we have already
          requested or received payment.
          <br />
          <br />
          We reserve the right to refuse any order placed through the Services.
          We may, in our sole discretion, limit or cancel quantities purchased
          per person, per household, or per order. These restrictions may
          include orders placed by or under the same customer account, the same
          payment method, and/or orders that use the same billing or shipping
          address. We reserve the right to limit or prohibit orders that, in our
          sole judgment, appear to be placed by dealers, resellers, or
          distributors.
        </p>
        <h2 id="FREE-TRIAL" className={classes.mainHeading}>
          6. FREE TRIAL
        </h2>
        <p className={classes.text}>
          We offer a 14-day free trial to new users who register with the
          Services. The account will be charged according to the user's chosen
          subscription at the end of the free trial.
        </p>
        <h2 id="CANCELLATION" className={classes.mainHeading}>
          7. CANCELLATION
        </h2>
        <p className={classes.text}>
          All purchases are non-refundable. You can cancel your subscription at
          any time by logging into your account. Your cancellation will take
          effect at the end of the current paid term.
          <br />
          If you are unsatisfied with our Services, please email us at
          support@ogiso.io or call us at 7573382712.
        </p>
        <h2 id="PROHIBITED-ACTIVITIES" className={classes.mainHeading}>
          8. PROHIBITED ACTIVITIES
        </h2>
        <p className={classes.text}>
          You may not access or use the Services for any purpose other than that
          for which we make the Services available. The Services may not be used
          in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us. As a user of the Services,
          you agree not to:
        </p>
        <ul
          style={{ textAlign: "left", listStyleType: "square" }}
          className={classes.text}
        >
          <li>
            Systematically retrieve data or other content from the Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the Services and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Services.
          </li>
          <li>
            Use any information obtained from the Services in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use the Services in a manner inconsistent with any applicable laws
            or regulations.
          </li>
          <li>Engage in unauthorized framing of or linking to the Services.</li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party&aspt;s uninterrupted use and enjoyment of
            the Services or modifies, impairs, disrupts, alters, or interferes
            with the use, features, functions, operation, or maintenance of the
            Services.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as "spyware" or
            "passive collection mechanisms" or "pcms").
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Services
            or the networks or services connected to the Services.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.
          </li>
          <li>
            Attempt to bypass any measures of the Services designed to prevent
            or restrict access to the Services, or any portion of the Services.
          </li>
          <li>
            Copy or adapt the Services' software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Services.
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Services, or
            use or launch any unauthorized script or other software.
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchases on the
            Services.
          </li>
          <li>
            Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use the Services as part of any effort to compete with us or
            otherwise use the Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
        </ul>
        <h2 id="USER-GENERATED-CONTRIBUTIONS" className={classes.mainHeading}>
          9. USER GENERATED CONTRIBUTIONS
        </h2>
        <p className={classes.text}>
          The Services may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Services, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Services and through third-party websites. As such, any Contributions
          you transmit may be treated as non-confidential and non-proprietary.
          When you create or make available any Contributions, you thereby
          represent and warrant that:
        </p>
        <ul
          className={classes.text}
          style={{ textAlign: "left", listStyleType: "square" }}
        >
          <li>
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Services, and other users of the Services to use your
            Contributions in any manner contemplated by the Services and these
            Legal Terms
          </li>
          <li>
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Services and these Legal Terms.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Legal Terms, or any applicable
            law or regulation.
          </li>
        </ul>
        <p className={classes.text}>
          Any use of the Services in violation of the foregoing violates these
          Legal Terms and may result in, among other things, termination or
          suspension of your rights to use the Services.
        </p>
        <h2 id="CONTRIBUTION-LICENSE" className={classes.mainHeading}>
          10. CONTRIBUTION LICENSE
        </h2>
        <p className={classes.text}>
          By posting your Contributions to any part of the Services, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use, copy, reproduce, disclose,
          sell, resell, publish, broadcast, retitle, archive, store, cache,
          publicly perform, publicly display, reformat, translate, transmit,
          excerpt (in whole or in part), and distribute such Contributions
          (including, without limitation, your image and voice) for any purpose,
          commercial, advertising, or otherwise, and to prepare derivative works
          of, or incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.
          <br />
          <br />
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
          <br />
          <br />
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Services. You
          are solely responsible for your Contributions to the Services and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
          <br />
          <br />
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Services; and (3) to pre-screen or delete any Contributions at any
          time and for any reason, without notice. We have no obligation to
          monitor your Contributions.
        </p>
        <h2 id="SERVICES-MANAGEMENT" className={classes.mainHeading}>
          11. SERVICES MANAGEMENT
        </h2>
        <p className={classes.text}>
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the Services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the Services.
        </p>
      </div>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default TCandPP;
