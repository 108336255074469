import React from "react";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  Modal,
  PasswordInput,
  Radio,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";

import { useForm, setForm } from "@mantine/form";

import { forwardRef, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

import { PhoneInput } from "react-international-phone";
import { useLocation } from "react-router-dom";
import { EditclientProfile, getSinglePatients } from "../../services/patient";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    // "& .mantine-Paper-root": {
    //   overflow: "initial !important",
    // },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const EditGaurdianinfo = forwardRef(
  ({ opened, onclose, title, form2, setClientDetails, noGuardianRef }, ref) => {
    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const { classes } = useStyles();

    const initialProvider = {
      providerEmail: "",
      providerName: "",
      providerType: "",
      providerPhone: "",
    };

    const providerTypes = [
      "Allergist/Immunologist",
      "Anesthesiologist",
      "Cardiologist",
      "Dermatologist",
      "Emergency Medicine Physician",
      "Endocrinologist",
      "Gastroenterologist",
      "Geriatrician",
      "General Practitioner (GP)",
      "Hematologist",
      "Infectious Disease Specialist",
      "Nephrologist",
      "Neonatologist",
      "Neurologist",
      "Obstetrician/Gynecologist (OB/GYN)",
      "Oncologist",
      "Ophthalmologist",
      "Orthopedic Surgeon",
      "Otolaryngologist (ENT Specialist)",
      "Pathologist",
      "Pediatrician",
      "Pharmacist",
      "Physical Medicine and Rehabilitation Specialist",
      "Plastic Surgeon",
      "Podiatrist",
      "Psychiatrist",
      "Pulmonologist",
      "Radiologist",
      "Rheumatologist",
      "Sports Medicine Specialist",
      "Urologist",
    ];

    const [showAdditional, setShowAdditional] = useState(false);
    const [view, setView] = useState(false);

    const [additionalProvider, setAdditionalProvider] = useState([
      { id: 1, ...initialProvider },
    ]);
    const noAdditionalRef = useRef();
    const inputRef = useRef();

    const location = useLocation();
    const id = location.state;

    const noCaseRef = useRef();
    const [show, setShow] = useState(false);
    const [showGuadrian, setShowGuardian] = useState(false);

    const form = useForm({
      initialValues: {
        emergencyContactName: "",
        emergencyContactPhoneNumber: "",
        emergencyContactEmail: "",
        noCaseManager: false,
        noAdditionalProvider: false,
        caseManagerName: "",
        caseManagerPhoneNumber: "",
        caseManagerEmail: "",
        noGuardian: false,
        isForm1: "false",
        pcpName: "",
        pcpPhoneNumber: "",
        pcpFaxNumber: "",
        pcpEmail: "",
      },
      validate: {},
    });

    useEffect(() => {
      if (form2) {
        if (
          form2?.additionalProvider &&
          Array.isArray(form2?.additionalProvider)
        )
          setAdditionalProvider(form2?.additionalProvider);
        else setAdditionalProvider([]);

        form.setValues({
          //...form1,
          emergencyContactName: form2?.emergencyContactName,
          emergencyContactPhoneNumber: form2?.emergencyContactPhoneNumber,
          emergencyContactEmail: form2?.emergencyContactEmail,
          noCaseManager: setShow(form2?.noCaseManager),
          noAdditionalProvider: setShowAdditional(form2?.noAdditionalProvider),
          caseManagerName: form2?.caseManagerName,
          caseManagerPhoneNumber: form2?.caseManagerPhoneNumber,
          caseManagerEmail: form2?.caseManagerEmail,
          noGuardian: setShowGuardian(form2?.noGuardian),
          pcpEmail: form2?.pcpEmail,
          pcpFaxNumber: form2?.pcpFaxNumber,
          pcpName: form2?.pcpName,
          pcpPhoneNumber: form2?.pcpPhoneNumber,
          additionalProvider: form2?.additionalProvider,
        });

        //if (opened && form2?.noCaseManager && ref.current) { setTimeout(() => { ref.current.checked = true; }, 500); }
        //  if (opened && form2?.noCaseManager) ref.current.checked = true;
      }
    }, [form2]);

    const prevoiusdata = {
      effectiveDate: new Date(form2?.effectiveDate),
      groupNumber: form2?.groupNumber,
      prescriptionGroup: form2?.prescriptionGroup,
      subscriberNumber: form2?.subscriberNumber,
      subscriberName: form2?.subscriberName,
      insuranceCompanyName: form2?.insuranceCompanyName,
      // additionalProvider: form2?.additionalProvider,
      noAdditionalProvider: form2?.noAdditionalProvider,
      noGuardian: form2?.noGuardian,
      noCaseManager: form2?.noCaseManager,
    };

    const handleChangeProvider = (index, key, value) => {
      let temp = additionalProvider?.map((v, i) => {
        return i === index
          ? {
              ...v,
              [key]: value,
            }
          : v;
      });
      setAdditionalProvider(temp);
    };

    const addProvider = () => {
      setAdditionalProvider([
        ...additionalProvider,
        { id: Date.now(), ...initialProvider },
      ]);
    };
    const removeProvider = (provId) => {
      setAdditionalProvider(additionalProvider.filter((p) => p.id !== provId));
    };

    const onSave = async (values) => {
      if (additionalProvider?.length === 0) values.noAdditionalProvider = true;
      let obj = {
        ...values,
        additionalProvider,
      };

      // console.log('values',values);
      // console.log('obj',obj);
      //setLoading(true);
      try {
        //const payload = {  ...values,...prevoiusdata };
        //const payload = {  ...obj,...prevoiusdata };
        let payload = {
          ...obj,
          ...prevoiusdata,
          id,
          isForm1: false,
        };
        await EditclientProfile(payload);
        //console.log('payload',payload);
        //await EditclientProfile({form2:payload,clientId:id})
        await EditclientProfile(payload);
        console.log("Successfully updated profile");
        const data = await getSinglePatients(id);
        setClientDetails(data);
        resetForm();
        onclose();
      } catch (error) {
        console.error("Error updated profille:", error.message);
      } finally {
        //getMar()
        //setLoading(false);
        //window.location.reload();
      }
    };

    const resetForm = () => {
      form?.reset();
      onclose();
    };

    return (
      <>
        <div style={{ overflowX: "auto" }}>
          <div style={{ minWidth: "600px" }}>
            {false ? (
              // <TableLoader/>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "11%",
                  left: "7%",
                  //padding: "100px 0 0 0",
                }}
              >
                <Loader color="yellow" size={"md"} />
              </Box>
            ) : (
              <Modal
                className={classes.mymodal}
                opened={opened}
                size={"xl"}
                onClose={() => {
                  //form?.reset();
                  onclose();
                }}
                shadow="md"
                overlayProps={{ blur: 3 }}
                radius={"lg"}
                zIndex={1000}
                title={
                  <Group>
                    <img src="/assets/images/addfac.png" alt="" />
                    <Text
                      sx={{
                        fontFamily: "Inter",
                        fontSize: 22,
                        fontWeight: 600,
                      }}
                    >
                      {title}
                    </Text>
                  </Group>
                }
                centered
              >
                <form onSubmit={form?.onSubmit((values) => onSave(values))}>
                  <Text
                    variant="text"
                    style={{ fontWeight: "600" }}
                    ref={inputRef}
                  >
                    Guardian Info
                  </Text>
                  <Checkbox
                    label="No Guardian"
                    // ref={noGuardianRef}
                    defaultChecked={form2?.noGuardian}
                    {...form.getInputProps("noGuardian")}
                    sx={{ marginTop: 20 }}
                    onChange={(v) => setShowGuardian(v?.target?.checked)}
                    //disabled={view}
                    color="yellow"
                  />
                  {!showGuadrian && (
                    <SimpleGrid
                      cols={isMobileScreen ? 1 : 2}
                      className={classes.mwd}
                      sx={{
                        paddingBottom: 30,
                      }}
                    >
                      <TextInput
                        className={classes.myfont}
                        label="Guardian/Emergency Contact Name"
                        size="sm"
                        icon={
                          <img src="/assets/images/profile.png" alt="contact" />
                        }
                        placeholder="Ex: John Doe"
                        sx={{ marginTop: 20 }}
                        {...form.getInputProps("emergencyContactName")}
                        //disabled={view}
                      />

                      <div className={classes.phoneInputBox}>
                        <p className={classes.label}>
                          Guardian/Emergency contact Phone Number
                        </p>
                        <PhoneInput
                          defaultCountry="us"
                          {...form.getInputProps("emergencyContactPhoneNumber")}
                          className={classes.phoneInput}
                        />
                      </div>

                      <TextInput
                        className={classes.myfont}
                        label="Guardian/Emergency Contact Email"
                        size="sm"
                        icon={<img src="/assets/images/sms.png" alt="email" />}
                        placeholder="Enter email"
                        {...form.getInputProps("emergencyContactEmail")}
                        //   disabled={view}
                      />
                    </SimpleGrid>
                  )}

                  <Divider my="sm" variant="dashed" />
                  <Text variant="text" style={{ fontWeight: "600" }}>
                    Case Manager Info
                  </Text>
                  <Checkbox
                    label="No Case Manager"
                    {...form.getInputProps("noCaseManager")}
                    sx={{ marginTop: 20 }}
                    ref={noCaseRef}
                    defaultChecked={form2?.noCaseManager}
                    onChange={(v) => setShow(v?.target?.checked)}
                    //   disabled={view}
                    color="yellow"
                  />
                  <SimpleGrid
                    cols={isMobileScreen ? 1 : 2}
                    className={classes.mwd}
                    sx={{
                      paddingBottom: 30,
                    }}
                  >
                    {!show && (
                      <>
                        <TextInput
                          className={classes.myfont}
                          label="Case Manager Name"
                          size="sm"
                          icon={
                            <img src="/assets/images/profile.png" alt="user" />
                          }
                          placeholder="Enter name"
                          sx={{ marginTop: 20 }}
                          {...form.getInputProps("caseManagerName")}
                          // disabled={view}
                        />

                        <div className={classes.phoneInputBox}>
                          <p className={classes.label}>
                            Case Manager Phone Number
                          </p>
                          <PhoneInput
                            required
                            defaultCountry="us"
                            {...form.getInputProps("caseManagerPhoneNumber")}
                            className={classes.phoneInput}
                          />
                        </div>

                        <TextInput
                          className={classes.myfont}
                          label="Case Manager Email"
                          size="sm"
                          icon={
                            <img src="/assets/images/sms.png" alt="email" />
                          }
                          placeholder="Enter email"
                          sx={{ marginTop: 20 }}
                          type="email"
                          {...form.getInputProps("caseManagerEmail")}
                          // disabled={view}
                        />
                      </>
                    )}
                  </SimpleGrid>

                  <Divider my="sm" variant="dashed" />
                  <Text variant="text" style={{ fontWeight: "600" }}>
                    Primary Care Physician
                  </Text>
                  <SimpleGrid cols={isMobileScreen ? 1 : 2}>
                    <TextInput
                      className={classes.myfont}
                      label="PCP Name"
                      size="sm"
                      icon={
                        <img src="/assets/images/profile.png" alt="profile" />
                      }
                      placeholder="Enter Name"
                      sx={{ marginTop: 20 }}
                      {...form.getInputProps("pcpName")}
                      //disabled={view}
                    />
                    <TextInput
                      className={classes.myfont}
                      label="PCP Email"
                      size="sm"
                      icon={<img src="/assets/images/sms.png" alt="email" />}
                      placeholder="Enter email"
                      type="email"
                      sx={{ marginTop: 20 }}
                      {...form.getInputProps("pcpEmail")}
                      //disabled={view}
                    />
                    <div className={classes.phoneInputBox}>
                      <p className={classes.label}>PCP Phone Number</p>
                      <PhoneInput
                        required
                        defaultCountry="us"
                        {...form.getInputProps("pcpPhoneNumber")}
                        className={classes.phoneInput}
                      />
                    </div>

                    <div className={classes.phoneInputBox}>
                      <p className={classes.label}>PCP Fax Number</p>
                      <PhoneInput
                        required
                        defaultCountry="us"
                        {...form.getInputProps("pcpFaxNumber")}
                        className={classes.phoneInput}
                      />
                    </div>
                  </SimpleGrid>

                  <Divider my="sm" variant="dashed" size={"md"} />
                  <Text variant="text" style={{ fontWeight: "600" }}>
                    Additional Provider Info
                  </Text>
                  <Checkbox
                    label="No Additional Provider"
                    {...form.getInputProps("noAdditionalProvider")}
                    sx={{ marginTop: 20 }}
                    defaultChecked={form2?.noAdditionalProvider}
                    ref={noAdditionalRef}
                    onChange={(v) => {
                      setShowAdditional(v?.target?.checked);
                      form.setFieldValue(
                        "noAdditionalProvider",
                        v?.target?.checked
                      );
                    }}
                    //disabled={view}
                    color="yellow"
                  />

                  {!showAdditional && (
                    <div>
                      {additionalProvider?.map((value, index) => (
                        <div key={index}>
                          {/* <span style={{ marginTop: "30px" }}>{index + 1} .</span> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {!view && (
                              <span
                                onClick={() => removeProvider(value.id)}
                                style={{
                                  color: "#fab005",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Delete
                              </span>
                            )}
                          </div>

                          <SimpleGrid
                            cols={isMobileScreen ? 1 : 2}
                            className={classes.mwd}
                            sx={{
                              paddingBottom: 30,
                            }}
                          >
                            <TextInput
                              className={classes.myfont}
                              label="Provider Name"
                              size="md"
                              icon={
                                <img
                                  src="/assets/images/building.png"
                                  alt="address"
                                />
                              }
                              placeholder="Enter Provider Name"
                              value={value?.providerName}
                              onChange={(e) =>
                                handleChangeProvider(
                                  index,
                                  "providerName",
                                  e?.target?.value
                                )
                              }
                              //disabled={view}
                            />
                            <TextInput
                              className={classes.myfont}
                              label="Provider Email"
                              size="md"
                              icon={
                                <img
                                  src="/assets/images/sms.png"
                                  alt="address"
                                />
                              }
                              placeholder="Enter Provider Email"
                              value={value?.providerEmail}
                              onChange={(e) =>
                                handleChangeProvider(
                                  index,
                                  "providerEmail",
                                  e?.target?.value
                                )
                              }
                              //disabled={view}
                            />
                            <div>
                              <p className={classes.providerLabel}>
                                Provider Contact
                              </p>
                              <PhoneInput
                                required
                                defaultCountry="us"
                                onChange={(val) => {
                                  handleChangeProvider(
                                    index,
                                    "providerPhone",
                                    val
                                  );
                                }}
                                value={value?.providerPhone}
                                className={classes.providerPhone}
                              />
                            </div>

                            <Select
                              className={classes.myfont}
                              label="Provider Type"
                              size="md"
                              data={providerTypes}
                              placeholder="Select Type"
                              value={value?.providerType}
                              onChange={(value) => {
                                handleChangeProvider(
                                  index,
                                  "providerType",
                                  value
                                );
                              }}
                              //disabled={view}
                            />
                          </SimpleGrid>
                          <Divider my="sm" variant="dashed" />
                        </div>
                      ))}
                      {/* Button to Add Medication */}
                      {!view && (
                        <Button
                          size="sm"
                          color="yellow"
                          onClick={addProvider}
                          style={{ marginLeft: "auto", display: "block" }}
                        >
                          Add Provider
                        </Button>
                      )}
                    </div>
                  )}

                  <Button
                    color="yellow"
                    sx={{
                      marginRight: "auto",
                      width: 122,
                      fontFamily: "Inter",
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </Modal>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default EditGaurdianinfo;
