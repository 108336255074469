import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  backAllClients: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "1rem",
  },
  facilityBox:{
    border: "1px solid #ccc",
    padding : ".5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "4rem",

  },
  facilityContainer:{
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  facility:{
    backgroundColor: "#d9d9d9", 
    padding: "1px 5px",
    margin: "0 5px",
    borderRadius: "8px",
    fontFamily: "Inter",
    display: "inline-block",
  },
  leftSide:{
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rightIcons:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  myfont: {
    fontFamily: "Inter",
    fontSize: rem(26),
    fontWeight: 600,
    marginLeft: 10,
    float: "left",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 54,
    width: "100%",
    "& .mantine-puhs6b:hover": {
      backgroundColor: "none !important",
    },
  },
  float_right: {
    float: "right",
  },
  colorChangeActive: {
    backgroundColor: "#000",
    color: "#fff",
  },
  mobileScreen: {
    width: "50%",
    margin: "0", // Set the default margin to 0
    "@media (max-width :80em)": {
      width: "100%",
      fontFamily: "Inter",
      margin: "1rem 0", // Adjust the margin for mobile screens
    },
  },
  changeColor: {
    background: "rgba(35, 35, 35, 0.06)",
    padding: "1rem",
    width: "52px",
    height: "52px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  changeIconColor: {
    display: "flex",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    color: "#000",
  },
  settingBGcolor: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "12px",
    background: "rgba(35, 35, 35, 0.06)",
    width: "52px",
    height: "52px",
    cursor: "pointer",
    margin: "0 5px",
  },
  settingIcon: {
    color: "#232323",
    display: "flex",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationBGcolor: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "12px",
    background: "rgba(35, 35, 35, 0.06)",
    width: "52px",
    height: "52px",
    margin: "0 5px",
  },
  notificationIcon: {
    color: "#232323",
    display: "flex",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  badge: {
    position: "absolute",
    top: -10,
    right: -10,
    background: "#fab005",
    color: "white",
    borderRadius: "50%",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "14px",
  },

  // tabs: {
  //   "& .mantine-Tabs-tab[aria-selected='true']": {
  //     background: "#f4ead8 !important",
  //   },
  //   "& .mantine-Tabs-tabsList": {
  //     flexWrap: "nowrap !important",
  //     overflowX: "auto !important",
  //     paddingBottom: "1rem !important",
  //   },
  //   "& .mantine-cohod4": {
  //     gap: "0rem",
  //   },
  //   backgroundColor: "#fff",
  //   margin: "1rem",
  // },
  // tab: {
  //   // padding: "1rem 1rem",
  //   backgroundColor: "#fff",
  //   marginRight: "1rem",
  // },
  // tabList: {
  //   backgroundColor: "#F2F2F2",
  // },
  // tabTitle: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   gap: ".5rem",
  // },
  // formContent: {
  //   display: "flex",
  //   alignItems: "start",
  //   justifyContent: "start",
  //   gap: "1rem",
  //   width: "100%",
  //   backgroundColor: "red",
  //   margin: "0 !important",
  //   "@media (max-width :500px)": {
  //     flexDirection: "column",
  //   },
  // },
  // formInfo: {
  //   backgroundColor: "#fff",
  //   width: "100px",
  //   "@media (max-width :500px)": {
  //     width: "100% !important",
  //     maxWidth: "100% !important",
  //   },
  // },
}));
export default useStyles;
