import React, { useState } from "react";
import useStyles from "./stylesComponents";
import { Button } from "@mantine/core";
import { IconFile, IconFolder } from "@tabler/icons-react";

function RowGridRadio({ onChange }) {
  const [selected, setSelected] = useState("grid");
  const { classes } = useStyles();

  const change = (val) => {
    onChange?.(val);
    setSelected(val);
  };
  return (
    <div className={classes.row}>
      <div
        className={
          selected === "row" ? classes.radioItemSelected : classes.radioItem
        }
        onClick={() => change("row")}
      >
        <img src={"/assets/images/row.png"} className={classes.radioImage} />
      </div>
      <div
        onClick={() => change("grid")}
        className={
          selected === "grid" ? classes.radioItemSelected : classes.radioItem
        }
      >
        <img src={"/assets/images/grid.png"} className={classes.radioImage} />
      </div>
    </div>
  );
}

function FileFolderRadio({ onChange }) {
  const [selected, setSelected] = useState("folder");
  const { classes } = useStyles();

  const change = (val) => {
    onChange?.(val);
    setSelected(val);
  };
  return (
    <Button.Group>
      <Button
        aria-label="Files"
        leftIcon={<IconFile />}
        variant="default"
        className={
          selected === "file"
            ? classes.radioStartButtonSelected
            : classes.radioStartButton
        }
        onClick={() => change("file")}
      >
        Files
      </Button>
      <Button
        aria-label="Folders"
        leftIcon={<IconFolder />}
        variant="default"
        className={
          selected === "folder"
            ? classes.radioEndButtonSelected
            : classes.radioEndButton
        }
        onClick={() => change("folder")}
      >
        Folders
      </Button>
    </Button.Group>
  );
}

export { RowGridRadio, FileFolderRadio };
