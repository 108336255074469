import React from "react";

export const DropDownArrowSvg = () => {
  return (
    <svg
      width="21"
      height="8"
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00013 3L15.7344 15L28.4688 3"
        stroke="#FBAA0D"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
