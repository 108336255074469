import React from "react";
import { ScrollArea, Table, createStyles } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NoteTableRowDashboard } from "../NoteTableRow";
import TableLoader from "../TableLoader";
import NoteModal from "../NoteModal";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important"
    },

    transition: "box-shadow 150ms ease"
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  }
}));

export function DashTable() {
  const { notes, loading } = useSelector((state) => state?.dashboard);
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [view, setView] = useState(false);

  return (
    <ScrollArea
      h={476}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      <NoteModal
        opened={isopen}
        close={() => {
          setOpen(false);
          setData(null);
        }}
        noteType={noteType}
        title={"Add New Note"}
        view={view}
        setView={setView}
        data={data}
      />

      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          {loading ? (
            <TableLoader />
          ) : (
            <Table
              withColumnBorders
              withBorder
              sx={{ fontFamily: "Inter" }}
              fontSize={"lg"}
              verticalSpacing={"md"}
            >
              <thead
                className={cx(classes.header, { [classes.scrolled]: scrolled })}
              >
                <tr>
                  <th style={{ minWidth: "140px" }}>Note Type</th>
                  <th style={{ minWidth: "140px" }}>Client Name</th>
                  <th style={{ minWidth: "140px" }}>Facility Name</th>
                  <th style={{ minWidth: "140px" }}>Staff Name</th>
                  <th style={{ minWidth: "140px" }}>Date</th>
                  <th style={{ minWidth: "140px" }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {notes?.map((row, i) => (
                  <NoteTableRowDashboard
                    row={row}
                    key={i}
                    index={i}
                    open={(data) => {
                      setData(data);
                      setOpen(true);
                    }}
                    setView={() => {
                      setView(true);
                    }} 
                  />
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </ScrollArea>
  );
}
