import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  MultiSelect,
  Radio,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";

function Form4({
  classes,
  view,
  state,
  setState,
  edit,
  onSubmit,
  close,
  addPlanLoading,
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const buttonRef = useRef();

  const initialOutcome = {
    outcomeCategory: "",
    outcomeTitle: "",
    customOutcome: "",
    outcomeDescription: "",
    additionalInformation: "",

    activities: [
      {
        id: Date.now(),

        supportActivity: "",
        supportInstructions: "",
        skillBuilding: "no",

        activityNumber: "1a",
      },
    ],

    // supportInstructions: "",
    // supportActivity: "",
    howOften: "",
    iNoLongerNeedThisOutcome: "",

    // errors: {
    //   outcomeTitle: undefined,
    //   outcomeCategory: undefined
    // },
    // required: ["outcomeTitle", "outcomeCategory"]
  };

  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  // const initialactivity = useForm({
  //   initialValues: {
  //     supportActivity:"",
  //     supportInstructions:"",
  //     skillBuilding: "no",
  //   },
  //   validate: {

  //   },
  // });

  const [outcome, setOutcome] = useState([{ id: 1, ...initialOutcome }]);

  const [showCustomOutcome, setShowCustomOutcome] = useState({});

  // components
  const NoRecord = () => {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/images/building.png"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "contain",
          }}
        />
        <Text style={{ opacity: 0.3 }} mt={10}>
          No Outcomes Found
        </Text>
      </div>
    );
  };

  //functions
  const onSave = () => {
    setState((p) => ({
      ...p,
      outcomes: outcome,
    }));

    onSubmit(outcome);
    
    //setOutcome(initialOutcome);
  };

  // const onSave = () => {

  //   const filteredOutcomes = outcome.map(outcomeItem => ({
  //     ...outcomeItem,
  //     activities: outcomeItem.activities.filter(activity =>
  //       activity.supportActivity || activity.supportInstructions
  //     )
  //   }));

  //   setState(prevState => ({
  //     ...prevState,
  //     outcomes: filteredOutcomes
  //   }));

  //   console.log('myoutcome', filteredOutcomes);

  //   //onSubmit(filteredOutcomes);
  // };

  const handleOutcomeChange = (outcomeId, field, value) => {
    let current = outcome.find((v) => v?.id === outcomeId);
    // console.log("current?.required", current.required);
    setOutcome((prevOutcome) =>
      prevOutcome.map((outcome) =>
        outcome.id === outcomeId
          ? {
              ...outcome,
              [field]: value,
              // errors: {
              //   ...outcome.errors,
              //   [field]:
              //     current?.required?.includes(field) && value == ""
              //       ? "Required"
              //       : undefined
              // }
            }
          : outcome
      )
    );
  };

  const handleactivityChange = (outcomeId, field, value, activityId) => {
    let tempOutcome = outcome;

    const tempResult = tempOutcome.map((outcome) =>
      outcome.id === outcomeId
        ? {
            ...outcome,
            activities: outcome?.activities.map((activity) =>
              // activity.id === value
              activity.id === activityId
                ? {
                    ...activity,
                    // [activityId]: field,
                    [field]: value,
                  }
                : activity
            ),
          }
        : outcome
    );

    setOutcome(tempResult);

    // setOutcome((prevOutcome) =>
    //   prevOutcome.map((outcome) =>
    //     outcome.id === outcomeId
    //       ? {
    //         ...outcome,
    //         activities: outcome.activities.map((activity) =>

    //           activity.id === activityId
    //             ? {
    //               ...activity,
    //               [field]: value,
    //             }
    //             : activity
    //         ),
    //       }
    //       : outcome
    //   )
    // );
  };

  const removeItem = (id) => {
    //setFields((p) => p?.filter((value) => value?.id !== id));
  };

  // console.log("outcome", outcome);
  const addOutcome = () => {
    if (edit) {
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          outcomeNumber: outcome?.slice(-1)[0]?.outcomeNumber + 1,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              activityNumber: `${outcome?.length + 1}a`,
            },
          ],
        },
      ]);
    } else
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              activityNumber: `${outcome?.length + 1}a`,
            },
          ],
        },
      ]);
  };

  // out.activities
  const addactivity = (outcomeIndex, activityNumber, activityIndex) => {
    // console.log('outcomeIndex',outcomeIndex);

    const newActivityId = Date.now();

    if (edit) {
      // setOutcome(prevOutcome => {
      //   const newOutcome = [...prevOutcome];
      //   newOutcome[outcomeIndex].activities.push({ id: newActivityId, activityNumber:activityNumber });
      //   return newOutcome;

      // });
      setOutcome((prevOutcome) => {
        const newOutcome = JSON.parse(JSON.stringify(prevOutcome)); // Deep clone
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          activityNumber: `${outcomeIndex + 1}${alphabet[activityIndex + 1]}`,
        });
        return newOutcome;
      });
    } else {
      setOutcome((prevOutcome) => {
        const newOutcome = [...prevOutcome];
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          activityNumber: `${outcomeIndex + 1}${alphabet[activityIndex + 1]}`,
        });
        return newOutcome;
      });
    }
  };

  const removeOutcome = (id) => {
    if (outcome?.length > 1) setOutcome((p) => p?.filter((v) => v?.id != id));
  };

  const removeActivity = (id) => {
    setOutcome((prevOutcome) => {
      return prevOutcome.map((outcomeItem) => {
        return {
          ...outcomeItem,
          activities: outcomeItem.activities.filter(
            (activity) => activity.id !== id
          ),
        };
      });
    });
  };

  // useEffect(() => {
  //   let object = {};
  //   if (state?.outcomes) {
  //     console.log('state', state?.outcomes);
  //     console.log('new state',state?.outcomes[0].activities);

  //   let form = Array.isArray(state?.outcomes) ? state?.outcomes : [];
  //     setOutcome(form);
  //     console.log('outcome', outcome);
  //     form?.forEach((value) => {
  //       object[value?.id] = value?.outcomeCategory === "Other";
  //     });
  //   }
  // }, [state?.outcomes]);

  // useEffect(() => {
  //   if (view || edit) {
  //     // if (state?.outcomes[0]?.activities) {
  //       let form = state?.outcomes?.length > 0 ? state?.outcomes : [];
  //       //[{}]
  //       setOutcome(form);

  //   }
  // }, [state?.outcomes]);

  useEffect(() => {
    if (view || edit) {
      let form = state?.outcomes?.length > 0 ? state?.outcomes : [];

      form = form.map((outcome) => {
        if (!outcome?.activities || outcome?.activities.length === 0) {
          return {
            ...outcome,
            activities: [
              {
                id: Date.now(),
                supportActivity: outcome?.supportActivity || "",
                supportInstructions: outcome?.supportInstructions || "",
                skillBuilding: outcome?.skillBuilding || "no",
                activityNumber: `${outcome?.outcomeNumber}a`,
              },
            ],
          };
        }
        return outcome;
      });

      setOutcome(form);
    }
  }, [view, edit, state?.outcomes]);

  return (
    <form>
      <Text variant="text" style={{ fontWeight: "600" }}>
        Support Plan
      </Text>

      {outcome?.length > 0 ? (
        outcome?.map((out, index) => {
          return (
            <div key={out.id}>
              {!view && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <span
                    onClick={() => removeOutcome(out?.id)}
                    style={{
                      color: "#fab005",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Delete
                  </span>
                </div>
              )}
              <TextInput
                className={classes.myfont}
                style={{ marginTop: "15px" }}
                label="Outcome Number"
                size="sm"
                disabled
                value={edit ? out?.outcomeNumber : index + 1}
              />
              <SimpleGrid
                cols={isMobileScreen ? 1 : 2}
                className={classes.mwd}
                sx={{
                  paddingBottom: 30,
                }}
              >
                <TextInput
                  id="outcome-title"
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Outcome Title"
                  size="sm"
                  placeholder="Enter outcome title"
                  disabled={view}
                  value={out?.outcomeTitle}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "outcomeTitle",
                      e?.target?.value
                    );
                  }}
                />
                <Select
                  id="outcome-category"
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="Outcome Category"
                  size="sm"
                  data={[
                    "Community Living",
                    "Safety and Security",
                    "Healthy living",
                    "Citizenship & Advocacy",
                    "Daily Life & Employment",
                    "Social & Spirituality",
                    "Integrated Community Involvement",
                    "Other",
                  ]}
                  placeholder="Select outcome category"
                  value={out?.outcomeCategory}
                  onChange={(value) => {
                    handleOutcomeChange(out?.id, "outcomeCategory", value);
                    setShowCustomOutcome((p) => ({
                      ...p,
                      [out?.id]: value === "Other",
                    }));
                  }}
                  disabled={view}
                />

                {showCustomOutcome[out?.id] && (
                  <Textarea
                    autosize={view}
                    className={classes.textArea}
                    style={{ marginTop: "15px" }}
                    label="Add Custom Outcome"
                    size="sm"
                    placeholder="Enter outcome"
                    disabled={view}
                    value={out?.customOutcome}
                    onChange={(e) => {
                      handleOutcomeChange(
                        out?.id,
                        "customOutcome",
                        e?.target?.value
                      );
                    }}
                  />
                )}
              </SimpleGrid>

              {out.activities?.length > 0 &&
                out?.activities.map((act, actIndex) => (
                  <Group key={act.id}>
                    <div
                      style={{
                        border: "1px solid black",
                        width: "100%",
                        backgroundColor: "Moccasin",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding:'10px'
                        }}
                      >
                        <div>
                          <p
                            // style={{ marginTop: 20 }}
                            disabled={view}
                          >
                            {/* {edit ? `${act?.activityNumber}${alphabet[index]}` : `${index + 1}${alphabet[actIndex]}`} */}
                            {edit
                              ? `${act?.activityNumber}`
                              : act.activityNumber}
                            {/* {edit ? `${act?.activityNumber}` : `${index + 1}${alphabet[actIndex]}`}  */}
                            {/* Debugging */}
                          </p>
                        </div>

                        <div style={{ width: "34%" }}>
                          <Textarea
                            id="support-activity"
                            autosize={view}
                            minRows={4}
                            maxRows={10}
                            //className={classes.textArea}
                            // style={{ marginTop: "15px" }}
                            // label="Support Activities"
                            size="sm"
                            disabled={view}
                            placeholder="Support Activity"
                            value={act?.supportActivity}
                            onChange={(e) => {
                              handleactivityChange(
                                out?.id,
                                "supportActivity",
                                e?.target?.value,
                                act?.id
                              );
                            }}
                            //sx={{ marginTop: 10 }}
                          />
                        </div>
                        <div style={{ color: "black" ,position:'relative',top:'38px'}}>
                          :
                        </div>

                        <div style={{ width: "34%" }}>
                          <Textarea
                            id="support-instruction"
                            autosize={view}
                            minRows={4}
                            maxRows={10}
                            // className={classes.textArea}
                            // style={{ marginTop: "15px" }}
                            // label="Support Instructions"
                            size="sm"
                            placeholder="support instructions"
                            disabled={view}
                            value={act?.supportInstructions}
                            onChange={(e) => {
                              handleactivityChange(
                                out?.id,
                                "supportInstructions",
                                e?.target?.value,
                                act?.id
                              );
                            }}
                            //sx={{ marginTop: 10 }}
                          />
                        </div>

                        <div>
                          <Radio.Group
                            style={{ margin: "20px 0px" }}
                            label="Skill-Building"
                            value={act?.skillBuilding}
                            onChange={(value) => {
                              handleactivityChange(
                                out?.id,
                                "skillBuilding",
                                value,
                                act?.id
                              );
                            }}
                          >
                            <Group>
                              <Radio
                                color={"yellow"}
                                label="Yes"
                                value={"yes"}
                                disabled={view}
                              />
                              <Radio
                                color={"yellow"}
                                label="No"
                                value={"no"}
                                disabled={view}
                              />
                            </Group>
                          </Radio.Group>
                        </div>

                        <div style={{ background: "transparent" }}>
                          {out.activities?.length > 1 && (
                            <ActionIcon
                              onClick={() => removeActivity(act?.id)}
                              size={"xl"}
                              color="red"
                              variant="light"
                            >
                              <IconTrash />
                            </ActionIcon>
                          )}

                          <ActionIcon
                            //onClick={() => addactivity(index, `${index + 1}${alphabet[actIndex]}`)}
                            onClick={() =>
                              addactivity(
                                index,
                                act?.activityNumber ? act.activityNumber : "",
                                actIndex
                              )
                            }
                            //onClick={() => addactivity(index, outcome, setOutcome, generateActivityNumber(outcome[index].activities))}
                            size={"xl"}
                            ref={buttonRef}
                            color="yellow"
                            variant="light"
                          >
                            <IconPlus />
                          </ActionIcon>
                        </div>
                      </div>
                    </div>
                  </Group>
                ))}
              <SimpleGrid
                cols={isMobileScreen ? 1 : 2}
                className={classes.mwd}
                sx={{
                  paddingBottom: 30,
                }}
              >
                <Select
                  id="select-how-often"
                  className={classes.myfont}
                  style={{ marginTop: "15px" }}
                  label="How Often"
                  size="sm"
                  data={[
                    "Daily",
                    "Weekly",
                    "Twice a Week",
                    "Monthly",
                    "Twice a Month",
                  ]}
                  placeholder="Select how often you do the activity"
                  value={out?.howOften}
                  onChange={(value) => {
                    handleOutcomeChange(out?.id, "howOften", value);
                  }}
                  disabled={view}
                />
              </SimpleGrid>
              <SimpleGrid
                cols={isMobileScreen ? 1 : 2}
                className={classes.mwd}
                sx={{
                  paddingBottom: 30,
                }}
              >
                <Textarea
                  id="additional-note"
                  autosize={view}
                  className={classes.textArea}
                  style={{ marginTop: "15px" }}
                  label="Additional Note"
                  size="sm"
                  placeholder="Enter Additional Note"
                  disabled={view}
                  value={out?.additionalInformation}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "additionalInformation",
                      e?.target?.value
                    );
                  }}
                />

                <Textarea
                  id="response"
                  autosize={view}
                  className={classes.textArea}
                  style={{ marginTop: "15px" }}
                  label="I no longer need this outcome when ..."
                  size="sm"
                  placeholder="Enter response"
                  disabled={view}
                  value={out?.iNoLongerNeedThisOutcome}
                  onChange={(e) => {
                    handleOutcomeChange(
                      out?.id,
                      "iNoLongerNeedThisOutcome",
                      e?.target?.value
                    );
                  }}
                />
              </SimpleGrid>
              <Divider my="sm" variant="dashed" />
            </div>
          );
        })
      ) : (
        <NoRecord />
      )}
      {!view && (
        <Button
          size="sm"
          color="yellow"
          onClick={addOutcome}
          style={{ marginLeft: "auto", display: "block" }}
        >
          Add New Outcome
        </Button>
      )}

      {!view && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <Button
            size="sm"
            variant="outline"
            color="yellow"
            onClick={close}
            disabled={view}
            style={{ marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button
            id="form-submit-button"
            loading={addPlanLoading}
            size="sm"
            color="yellow"
            onClick={onSave}
            disabled={view}
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </div>
      )}
    </form>
  );
}

export default Form4;
