import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  insuranceCard: {
    width: "245px",
    height: "auto",
    backgroundColor: "#fffdf7",
    padding: "5px 10px 5px 10px",
    boxShadow: "2px 2px 0px 1px #cccccc",
    borderRadius: "10px",
    flex: "0 0 auto",
    marginRight: "10px",
    marginBottom: "5px",
  },

  cardTitle: {
    textAlign: "center",
    fontWeight: "500",
    display: "block",
    color: "#979797",
    textTransform:"uppercase",
    height:"10px"
  },

  cardHeading: {
    textAlign: "center",
    marginTop: "6px",
    marginBottom: "0px",
    height: "20px",
    width: "100%",
    fontWeight: "500",
  },

  cardInsideBox: {
    border: "1px solid #979797",
    width: "100%",
    padding: "5px",
    marginTop: "0",
    borderRadius: "6px",
    backgroundColor: "#fffbef",
    height: "85px",
    paddingLeft:"10px"
  },

  informationParent: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "7px",
  },

  effectiveDateParent: {
    flexGrow: "0.45",
  },

  fieldNames: {
    display: "block",
    fontSize: "10px",
    textTransform: "capitalize",
  },

  lowerFieldValues: {
    display: "block",
    fontSize: "11px",
    fontWeight: "bold",
  },

  upperFieldValues: {
    display: "block",
    fontSize: "11px",
    fontWeight: "bold",
    height: "14px",
  },
}));

export default useStyles;
