import React from "react";
import {
    Button,
    Modal,
    Text,
    createStyles,
  } from "@mantine/core";
  
  import { useSelector } from "react-redux";
  import { deleteFolder } from "../../services/Document";
  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      display: "flex",
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  
    camera: {
      position: "absolute",
      // position:'relative',
      // top:'28%',
      // right:'5%',
      marginTop: 75,
      marginLeft: 75,
      background: "#FFBA45",
      borderRadius: "50%",
      padding: 5,
      [`&:hover`]: {
        cursor: "pointer",
      },
    },
    drop: {
      display: "none",
      "& .mantine-Image-image": {
        border: "2px solid green",
      },
    },
    dropImage: {
      img: {
        borderRadius: "50%",
      },
    },
    myfont: {
      fontFamily: "Inter",
      // marginTop: 20,
      overflow: "visible",
    },
    mycommmentfont: {
      fontFamily: "Inter",
      fontSize: "none !important",
      // marginTop: 20,
      overflow: "visible",
      "& .mantine-Textarea-label": {
        fontSize: "1rem !important",
      },
    },
    btn: {
      marginLeft: "auto",
      marginTop: 50,
    },
    mymodal: {
      width: 818,
      overflow: "visible",
      // height: 500,
      // "& .mantine-Modal-content": {
      //   height: "300px !important",
      // },
      // "& .mantine-Modal-inner": {
      //   padding: "0px !important",
      // },
      "& .mantine-Paper-root": {
        overflow: "initial !important",
      },
      "& .mantine-Modal-inner": {
        padding: "0px !important",
      },
  
      "@media (max-width:400px)": {
        width: "auto",
      },
    },
    mwd: {
      width: "100%",
    },
    buttonBox: {
        display : "flex",
        justifyContent : "end",
        alignItems : "center"
      },
  }));
  
  const DeleteFolderModal = ({ opened, onclose, title, data,client }) => {
    const { classes } = useStyles();
    const { createFolderLoading } = useSelector((state) => state.document);
  
    const onDelete = async () => {
      try {
          await deleteFolder(data?.id || data?._id , client);
        onclose();
      } catch (error) {
        console.error("Error creating folder:", error.message);
      }
    };
  
    return (
      <>
        <div style={{ overflowX: "auto" }}>
          <div style={{ minWidth: "600px" }}>
            <Modal
              className={classes.mymodal}
              opened={opened}
              size={"xl"}
              onClose={() => {
                onclose();
              }}
              shadow="md"
              overlayProps={{ blur: 3 }}
              radius={"lg"}
              zIndex={1000}
              title={
                  <Text
                    sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}
                  >
                    {title}
                  </Text>
              }
              centered
            >
                <Text
                    sx={{ fontFamily: "Inter", fontSize: 18, fontWeight: 400,marginBottom:"20px" }}
                  >
                    Are you sure you want to delete the folder <strong>"{data?.name}"?</strong><br/>Deleting folder will also result in deleting all files and documents stored in the folder.
                  </Text>
                  <div
                  className={classes.buttonBox}
                  >
              <Button
                  sx={{ marginRight: "10px", width: 122, fontFamily: "Inter" }}
                  onClick={() => {
                    onclose()
                  }}
                  >
                  Cancel
                </Button>
                <Button
                  color="red"
                  sx={{ marginRight: "0", width: 122, fontFamily: "Inter" }}
                  loading={createFolderLoading}
                  onClick={onDelete}
                  >
                  Confirm
                </Button>
                    </div>
            </Modal>
          </div>
        </div>
      </>
    );
  };
  
  export default DeleteFolderModal;
  