import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addStaffLoading: false,
  staff: []
};

export const staffState = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setAddStaffLoading(state, action) {
      return {
        ...state,
        addStaffLoading: action?.payload
      };
    },
    setStaff(state, action) {
      return {
        ...state,
        loading: false, addStaffLoading: false,
        staff: action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const { setLoading, setAddStaffLoading, setStaff } = staffState.actions;
export default staffState.reducer;
