import {
  ActionIcon,
  Box,
  Group,
  Select,
  SimpleGrid,
  Text,
  TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { Icon360, IconPlus } from "@tabler/icons-react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { noteType_Client_Options } from "../../utils";

const CustomForm = forwardRef(
  ({ classes, view, errorField, setErrorField, onSubmit }, ref) => {
    useEffect(() => {
      if (errorField === "" || errorField === undefined)
        form.setValues(initialValues);
    }, [errorField]);
    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const { fieldType } = useSelector((state) => state.note);
    const [others, setOthers] = useState(false);
    // constants
    const initialValues = {
      field: "",
      fieldType: "",
      priority: "",
      viewOnly: "",
      others: ""
    };
    const validations = {
      field: (value) => (value ? null : "Please specify Field Name"),
      fieldType: (value) => (value ? null : "Select Field Type"),
      priority: (value) => (value ? null : "Select Priority"),
      viewOnly: (value) => (value ? null : "Select View Only")
    };

    const form = useForm({
      initialValues,
      validate: validations
    });

    useImperativeHandle(ref, () => ({
      forceSubmit: () => plusRef?.current?.click()
    }));

    // refs
    const plusRef = useRef();
    const inputRef = useRef();

    // functions
    const onFieldTypeChange = (fieldT) => {
      form.setFieldValue("fieldType", fieldT);
      let selectedFieldType = fieldType?.find(
        (value) => value?.id === fieldT
      )?.name;
      setOthers(selectedFieldType?.toUpperCase() === "OTHER");
    };
    const submit = (values) => {
      let selectedFieldType = fieldType?.find(
        (value) => value?.id === values?.fieldType
      )?.name;
      let obj = {
        ...values,
        priority: values?.priority == "Yes",
        viewOnly: values?.viewOnly == "Yes"
      };
      if (selectedFieldType?.toUpperCase() === "OTHER") {
        obj.options = "Other";
      }
      onSubmit(obj);
    };

    return (
      <form onSubmit={form?.onSubmit((values) => submit(values))}>
        <Group>
          {!view && (
            <>
              <Box sx={{ width: "90%" }} className={classes.bordered}>
                <Text>Custom Field</Text>
                <SimpleGrid
                  cols={1}
                  sx={{
                    paddingTop: 10
                  }}
                >
                  <TextInput
                    size="md"
                    ref={inputRef}
                    icon={<img src="/assets/images/building.png" alt="home" />}
                    placeholder="Field Name"
                    className={classes.myfont}
                    {...form.getInputProps("field")}
                    disabled={view}
                  />
                </SimpleGrid>
                <SimpleGrid
                  cols={isMobileScreen ? 1 : 3}
                  className={classes.mwd}
                  sx={{
                    paddingTop: 10
                  }}
                >
                  <Select
                    size="md"
                    data={fieldType
                      ?.filter(
                        (v) =>
                          v?.name != noteType_Client_Options &&
                          v?.name !== "Facilities" &&
                          v?.name !== "Staff" &&
                          v?.name !== "Facility Types"
                      )
                      ?.map((val) => ({
                        value: val?.id,
                        label: val?.name?.toUpperCase()
                      }))}
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="Field Type"
                    className={classes.myfont}
                    {...form.getInputProps("fieldType")}
                    disabled={view}
                    onChange={onFieldTypeChange}
                  />
                  {others ? (
                    <TextInput
                      size="md"
                      // label="List Options"
                      icon={
                        <img src="/assets/images/building.png" alt="home" />
                      }
                      placeholder="Eg. Option1,Option2,Option3"
                      className={classes.myfont}
                      {...form.getInputProps("others")}
                      disabled={view}
                    />
                  ) : null}
                  <Select
                    size="md"
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="Field Priority"
                    data={["Yes", "No"]}
                    className={classes.myfont}
                    {...form.getInputProps("priority")}
                    disabled={view}
                  />
                  <Select
                    size="md"
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="View Only"
                    data={["Yes", "No"]}
                    className={classes.myfont}
                    {...form.getInputProps("viewOnly")}
                    disabled={view}
                  />
                </SimpleGrid>
              </Box>
              <Box>
                <ActionIcon
                  color="yellow"
                  variant="light"
                  mt={5}
                  size={44}
                  ref={plusRef}
                  type="submit"
                >
                  <IconPlus />
                </ActionIcon>
                <ActionIcon
                  color="blue"
                  variant="light"
                  mt={5}
                  size={44}
                  onClick={() => {
                    setErrorField("");
                    form.setValues(initialValues);
                  }}
                >
                  <Icon360 />
                </ActionIcon>
              </Box>
            </>
          )}

          {errorField != "" ? (
            <p style={{ color: "red", margin: 0, padding: 0 }}>{errorField}</p>
          ) : (
            ""
          )}
        </Group>
      </form>
    );
  }
);

export default CustomForm;
