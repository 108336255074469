import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  clientInsuranceModal: {
    "& .mantine-19pz3dh": {
      backgroundColor: "#fff5e3",
      paddingBottom: "10px",
      display: "block",
    },
    "& .mantine-1fholi4": {
      display: "none",
    },
  },
  body:{
    height:"500px"
  },
  flexParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerFlex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0px",
  },
  headerFlexImage: {
    width: "40px",
    height: "40px",
  },
  headerCollapseImage: {
    width: "25px",
    height: "25px",
  },
  headerCollapseImageButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  headerFlexTitle: {
    fontWeight: "bold",
    marginTop: "10px",
    marginLeft: "10px",
  },
  headerFlexEndButton: {
    color: "#979797",
    fontWeight: "500",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  bodyFlex: {
    paddingTop: "40px",
    "@media (max-width:768px)": {
      textAlign: "-webkit-center",
    },
  },
  noDataFound: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "normal",
  },
  noDataIcon: {
    marginLeft: "10px",
  },
}));

export default useStyles;
