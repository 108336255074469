import { Button, Grid, Image, Text } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../services/auth";
import useStyles from "./style";
import TableLoader from "../../components/TableLoader";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";
const VerifyEmail = () => {
  const { emailVerifyLoading } = useSelector((state) => state.auth);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordToken, setPasswordToken] = useState("");

  useEffect(() => {
    getPasswordSetLink();
    pageInfoAnalytics("Verify Email Page Viewed", "Verify Email Page");
  }, []);

  const getPasswordSetLink = async () => {
    let response = await verifyEmail({
      token: params?.token,
    });
    if (response?.data) {
      setShowPassword(!response?.data?.hasPassword);
      setPasswordToken(response?.data?.setPasswordToken);
      setSuccess(true);
    }
  };

  const setPassword = () => {
    navigate(`/reset-password/${passwordToken}`, {
      state: {
        title: "Set Your Password",
      },
    });
  };

  const continueToLogin = () => {
    navigate("/signup", { state: { token: params?.token } });
  };

  return (
    <Grid className={classes.container}>
      <Grid.Col span={6} className={classes.left_pane}>
        <div className={classes.welcome}>
          <Link to={"/"}>
            <Image
              src="/assets/images/logo.png"
              mr={"auto"}
              fit="fill"
              className={classes.logo}
            />
          </Link>
        </div>
      </Grid.Col>
      <Grid.Col
        span={6}
        className={classes.right_pane}
        style={{ position: "relative" }}
      >
        <div
          style={{
            background: "white",
            height: "50%",
            borderRadius: "10px",
            width: "100%",
            position: "absolute",
            top: "30%",
            left: "-50%",
            border: "1px solid gray",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <center>
            {emailVerifyLoading ? (
              <>
                <Text className={classes.title}>Verifying Email</Text>
                <TableLoader />
              </>
            ) : success ? (
              <div>
                <div className="d-flex">
                  <Text className={classes.title}>Email Verified</Text>
                  <img
                    src="/assets/images/greenCheck.png"
                    width={50}
                    alt="green check mark"
                  />
                </div>
                {showPassword ? (
                  <Button
                    style={{
                      padding: "0 .5rem",
                      width: "max-content",
                      fontSize: "1rem",
                    }}
                    color="yellow"
                    className={classes.login_btn}
                    onClick={setPassword}
                  >
                    Set Your Password
                  </Button>
                ) : (
                  <Button
                    color="gray"
                    className={classes.login_btn}
                    onClick={continueToLogin}
                  >
                    Continue
                  </Button>
                )}
              </div>
            ) : (
              <Text className={classes.title}>Oops! Something Went Wrong</Text>
            )}
          </center>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default VerifyEmail;
