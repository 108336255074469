import { Container, createStyles, Card, Grid, Box, Text, Title } from "@mantine/core";
import { IconBell } from "@tabler/icons-react";
import Topbar from "../../../components/Topbar";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TableLoader from "../../../components/TableLoader";
import { getNotifications } from "../../../services/notification";
import moment from "moment";
import { decrypt } from "../../../utils";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    alignItems: "center",
    textAlign: "center",
  },
  "& .mantine-Container-root": {
    paddingLeft: "0!important",
    paddingRight: "0!important",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingLeft: ".5rem !important",
    paddingRight: ".5rem !important",
  },
  settingIcon: {
    color: "#232323",
    display: "flex",
    width: "40px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    marginTop: "50px",
    marginLeft: "10px",
    marginrIGHT: "10px",
  },
  date: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "20px",
    margin: "5px 0",
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
}));
const Notification = () => {
  const { classes } = useStyles();
  const { notifications, total, getNotificationLoading } = useSelector(
    (state) => state.notification
  );
  const [page, setpage] = useState(1);
  const [perPage] = useState(2);

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
  };

  const getData = async () => {
    await getNotifications({ page, perPage, client: "" });
  };
  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    TrackPageView("Notification");
  }, []);

  const NotificationCard = ({ item }) => {
    return (
      <Card radius={10} shadow="sm" style={{ marginBottom: "15px" }}>
        <Box className={classes.cardContainer}>
          <IconBell className={classes.settingIcon} stroke={1} />
          <Box className={classes.date}>
            <Text style={{ margin: "0" }}>
              {moment(item?.metadata?.file?.expiry).format("MMM DD YYYY")}
            </Text>
            <Text style={{ margin: "0" }}>
              {moment(item?.metadata?.file?.expiry).format("hh:mm A")}
            </Text>
          </Box>
          <Box className={classes.message}>
            <Title order={3} style={{ margin: "5px 0" }}>
              {item?.module === "document" ? "Document Expiry" : ""}
            </Title>
            <Text style={{ margin: "0" }}>
              {/* your document is going to be expire tonight. */}
              Client <strong>{decrypt(item?.client?.name, false)}</strong>,{" "}
              {item?.title} {item?.message}
            </Text>
          </Box>
        </Box>
      </Card>
    );
  };
  return (
    <Container fluid className={classes.content} px={20}>
      <Topbar title={"Notification"} />
      <Box className={classes.mainContainer}>
        {getNotificationLoading ? (
          <TableLoader />
        ) : (
          <>
            <Grid>
              {notifications?.map((item, index) => (
                <Grid.Col key={index} xs={12} md={6} lg={3} xl={3}>
                  <NotificationCard item={item} />
                </Grid.Col>
              ))}
              {/* {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                <Grid.Col key={item} xs={12} md={6} lg={3} xl={3}>
                  <NotificationCard />
                </Grid.Col>
              ))} */}
            </Grid>

            <CustomPagination
              data={total}
              currentPage={page}
              itemsPerPage={perPage}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </Box>
    </Container>
  );
};

export default Notification;
