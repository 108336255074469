import React from "react";

import {
  ActionIcon,
  Button,
  Group,
  Popover,
  ScrollArea,
  Table,
  Text,
  createStyles
} from "@mantine/core";

import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import TableLoader from "../TableLoader";
import AddTaskTypeModal from "./AddTaskTypeModal";
import { deleteTaskType } from "../../services/task";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0
    }
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  },
  field: {
    borderWidth: 1,
    boderColor: "#232323",
    borderStyle: "solid",
    borderRadius: 8
  }
}));

const TaskTypeTable = () => {
  const [opened, setOpened] = useState(false);
  const { loadingTypes, taskTypes } = useSelector((state) => state.task);
  const [scrolled, setScrolled] = useState(false);
  const [data, setData] = useState(null);
  const { classes, cx } = useStyles();

  // functions
  const open = (row) => {
    setOpened(true);
    setData(row);
  };
  const remove = async (id) => {
    await deleteTaskType(id);
  };

  const DeleteComponent = ({ id, onPressOk }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    return (
      <Popover
        opened={deleteModal}
        width={300}
        position="bottom"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            color="red"
            variant="light"
            onClick={() => setDeleteModal(true)}
          >
            <IconTrash />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">
            Deleting Task Type can create problem in the system
          </Text>

          <Group grow mt={10}>
            <Button
              onClick={() => setDeleteModal(false)}
              size={"xs"}
              color="yellow"
            >
              Cancel
            </Button>
            <Button size={"xs"} color={"red"} onClick={() => onPressOk(id)}>
              Delete
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    );
  };

  const rows = taskTypes?.map((row, i) => (
    <tr style={{ cursor: "pointer" }}>
      <td>{row.name || "-"}</td>
      <td>{row.description || "-"}</td>
      <td>
        <Group>
          {row.subTasks?.map((field, i) =>
            field.fields?.map((field2, i) => (
              <Text className={classes.field} p={5} key={i}>
                {field2?.name}
              </Text>
            ))
          )}
        </Group>
      </td>
      <td>
        {!row?.general && (
          <Group>
            <ActionIcon color="green" variant="light" onClick={() => open(row)}>
              <IconEdit />
            </ActionIcon>
            <DeleteComponent id={row?.id} onPressOk={remove} />
          </Group>
        )}
      </td>
    </tr>
  ));

  return (
    <>
      <ScrollArea
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        sx={{ marginTop: "30px" }}
      >
        <AddTaskTypeModal
          edit
          data={data}
          opened={opened}
          close={() => {
            setOpened(false);
          }}
        />
        {loadingTypes ? (
          <TableLoader />
        ) : (
          <Table
            withColumnBorders
            withBorder
            highlightOnHover
            enableStickyHeader
            sx={{ fontFamily: "Inter" }}
            fontSize={"sm"}
            verticalSpacing={"md"}
          >
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th style={{ minWidth: "100px" }}>Task Type Name</th>
                <th style={{ minWidth: "200px" }}>Description</th>
                <th style={{ minWidth: "200px" }}>Task List</th>
                <th style={{ minWidth: "140px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}
      </ScrollArea>
    </>
  );
};

export default TaskTypeTable;
