import { combineReducers } from "redux";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import staffReducer from "./staff";
import facilityReducer from "./facility";
import noteReducer from "./note";
import patientReducer from "./patient";
import profileReducer from "./profile";
import reportReducer from "./report";
import medicationReducer from "./medication";
import supportPlanReducer from "./supportplan";
import ticketReducer from "./ticket";
import taskReducer from "./task";
import marReducer from "./mar";
import documentReducer from "./document";
import notificationReducer from "./notification";
import themeReducer from './theme';

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  staff: staffReducer,
  facility: facilityReducer,
  note: noteReducer,
  patient: patientReducer,
  profile: profileReducer,
  report: reportReducer,
  medication: medicationReducer,
  supportPlan: supportPlanReducer,
  ticket: ticketReducer,
  task: taskReducer,
  mar: marReducer,
  document: documentReducer,
  notification: notificationReducer,
  theme: themeReducer,

});
export default rootReducer;
