import React, { useState } from "react";
import { Button, Input, Modal } from "@mantine/core";

const ReportEmailModal = ({
  viewEmail,
  setViewEmail,
  setEmailState,
  emailState,
  submitFn
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSandButtonClick = () => {
    // Simple email validation using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(emailState);

    if (isValid) {
      submitFn();
      setViewEmail(false);
      setEmailState("");
      setIsValidEmail(true);
    } else {
      // Display error message or handle invalid email case as needed
      setIsValidEmail(false);
    }
  };
  return (
    <>
      <Modal
        opened={viewEmail}
        onClose={() => setViewEmail(false)}
        centered
        // Other modal props
        zIndex={1000}
        overlayProps={{ blur: 3 }}
      >
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h2>Report send to</h2>
          <Input
            sx={{ width: 322 }}
            value={emailState}
            onChange={(e) => setEmailState(e.target.value)}
            onKeyDown={(e) =>
              e?.key === "Enter" ? handleSandButtonClick(e) : () => null
            }
            placeholder="Enter your email"
          />
          {!isValidEmail && (
            <p style={{ color: "red", marginTop: 5 }}>
              Please enter a valid email address
            </p>
          )}
          <Button
            color="yellow"
            sx={{
              width: 122,
              fontFamily: "Inter",
              fontSize: 16,
              marginTop: 5
            }}
            onClick={handleSandButtonClick}
          >
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ReportEmailModal;
