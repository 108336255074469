import React from "react";

import { Box, ScrollArea, Table, Text, createStyles } from "@mantine/core";

import { useState } from "react";
import { useSelector } from "react-redux";
import PatientTableRow from "../PatientTableRow";
import RecordNotFound from "../RecordNotFound";
import TableLoader from "../TableLoader";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#F2F2F2",
      color: "#000 !important",
    },
    "& th:first-of-type": {
      borderTopLeftRadius: "16px !important",
      borderBottomLeftRadius: "16px !important",
    },
    "& th:last-of-type": {
      borderTopRightRadius: "16px !important",
      borderBottomRightRadius: "16px !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
    borderRadius: "15px",
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tableHeader: {
    borderBottom: "none !important",
  },
}));

const PatientTable = ({ data, onEdit }) => {
  const { classes, cx } = useStyles();
  const { patients, loading } = useSelector((state) => state.patient);
  const [scrolled, setScrolled] = useState(false);

  console.log("patients in patient table", patients?.patients)

  const rows = patients?.patients?.map((row, i) => (
    <PatientTableRow row={row} key={i} onEdit={onEdit} />
  ));
  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      sx={{ marginTop: "30px", minHeight: "300px" }}
    >
      <Table
        // withBorder
        highlightOnHover
        enableStickyHeader
        sx={{ fontFamily: "Inter", borderRadius: "15px" }}
        fontSize={"sm"}
        verticalSpacing={"md"}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th style={{ minWidth: "200px" }} className={classes.tableHeader}>
              Client
            </th>
            <th style={{ minWidth: "200px" }} className={classes.tableHeader}>
              Facility
            </th>
            <th style={{ minWidth: "140px" }} className={classes.tableHeader}>
            Intake Date
            </th>
            <th style={{ minWidth: "80px" }} className={classes.tableHeader}>
              DOB
            </th>
            <th style={{ minWidth: "140px" }} className={classes.tableHeader}>
              Guardian Name
            </th>
            <th style={{ minWidth: "140px" }} className={classes.tableHeader}>
              Guardian Number
            </th>
            <th style={{ minWidth: "140px" }} className={classes.tableHeader}>
              Active
            </th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan={1000}>
                <TableLoader />
              </td>
            </tr>
          ) : rows?.length ? (
            <>{rows}</>
          ) : (
            <tr>
              <td colSpan={1000}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    height: "75vh",
                    gap: "8rem",
                  }}
                >
                  <RecordNotFound />
                  <Text className={classes.notFoundText}>
                    You don&apos;t have any record, click on “Add Client”
                  </Text>
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default PatientTable;
