import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "50px",
  },
  detailBar: {
    display: "flex",
    flexDirection: "column",
  },
  tabContainer: {
    display: "flex",
    height: "50px",
  },
  userDetail: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "40px",
    "& .detail_box": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& .image_box": {
        width: "80px",
        height: "80px",
        border: "1px solid rgb(230,230,230)",
        borderRadius: "10px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& h3": {
        margin: "0",
        fontSize: "30px",
        textAlign: "center",
      },
      "& p": {
        margin: "0",
        color: "#979797",
        textAlign: "center",
      },
    },
  },
  scheduleDetail: {
    width: "100%",
    display: "flex",
    gap: "20px",
    // marginTop: "30px",
  },
  scheduleDetailLeft: {
    width: "250px",
  },
  scheduleDetailRight: {
    width: "calc(100% - 270px)",
  },
  scheduleDetailRight100: {
    width: "calc(100% - 70px)",
  },
  wrapper: {
    width: "100%",
    overflowX: "auto",
  },
  mainContainer: {
    // background: "#fdf8ec",
    // border: "1px solid rgba(90,90,90,0.1)",
    // width: "2000px",
  },

  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#fff",
      color: "#000 !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },

  bg1: {
    "& td": {
      background: "#fff",
    },
  },
  bg2: {
    "& td": {
      background: "#F2F2F2",
    },
  },
  bgDis: {
    "& td": {
      // background: "rgba(168, 168, 168, 0.65)",
      background: "#F2F2F2",
    },
  },
  stickyColumn: {
    position: 'sticky',
    left: -1, 
    backgroundColor: theme.colors.gray[1], 
    zIndex: 2,
  },
}));

export default useStyles;
