import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#74D588", // rgba(128, 0, 128, 0.50)
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "118px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "30px",
    },
  },
  buttonPRN: {
    height: "50px",
    width: "100px",
    color: "rgba(128, 0, 128, 1)",
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "3px solid rgba(128, 0, 128, 1)",
    fontSize: "20px",
    borderRadius: 10,
    fontWeight: "bold",
    gap: "5px",
  },

  detailTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "36px",
    },
    "& button": {
      height: "50px",
      width: "150px",
      color: "#FF0000",
      background: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid #FF0000",
      borderRadius: "25px",
      fontSize: "20px",
      gap: "5px",
      "& img": {
        width: "30px",
        height: "30px",
      },
    },
  },

  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },

  medicationModal: {
    width: 1450,
    overflowY: "auto",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  mwd: {
    width: "100%",
  },
  mainContentWarpper: {
    width: "100%",
    height: "75vh",
    maxHeight: "900px",
    overflowY: "auto",
  },
  mainContent: {
    width: "100%",
    // height: "100%",
    padding: "3rem 4rem 5rem 8rem",
    display: "flex",
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  details: {
    width: "75%",
  },
  personInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "column",
    width: "25%",
    "& img": {
      width: "170px",
      height: "160px",
      objectFit: "cover",
      borderRadius: "10px !important",
    },
    "& h3": {
      margin: "0",
      marginTop: "10px",
      textAlign: "center",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "25px",
    },
    "& p": {
      margin: "0",
      marginTop: "5px",
      textAlign: "center",
      color: "#979797",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "25px",
    },
  },

  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    marginBottom: "30px",
    "& p": {
      margin: "0",
      fontSize: "32px",
    },
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100px",
    height: "100px",
    background: "rgba(128, 0, 128, 0.15)",
    "& h3": {
      margin: "0",
      fontSize: "36px",
    },
    "& p": {
      margin: "0",
      textAlign: "center",
      fontSize: "16px !important",
    },
  },
  span1: {
    fontWeight: "600",
    fontSize: "36px",
  },
  span3: {
    color: "#979797",
    margin: "0",
  },

  outcome: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  redText: {
    color: "#FF0000",
  },
  dispensed: {
    display: "flex",
    // justifyContent: 'center',
    alignItems: "center",
    flexDirection: "column",
    "& h3": {
      fontWeight: "400",
      fontSize: "36px",
      margin: "0",
      marginBottom: "10px",
    },
  },
  quantity: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h3": {
      margin: "0",
      fontSize: "85px",
    },
    "& p": {
      margin: "0",
      textAlign: "center",
      fontSize: "20px ",
    },
  },
  radioContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "& .mantine-1svz3tp": {
      color: "#232323",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: "800",
      lineHeight: "25px",
    },
    "& .mantine-1cu6yj8:checked": {
      background: "#FFBA45",
      borderColor: "#FFBA45",
    },
  },
  wasEffectiveRadioContainer: {
    width: "50%",
  },
  effectiveContainer: {
    display: "flex",
    alignItems: "center",
    padding: "3rem 4rem 5rem 8rem",
    "& button": {
      color: "#000000",
      background: "rgba(128, 0, 128, 0.25)",
      fontSize: 20,
    },
    "& button:hover": {
      background: "rgba(128, 0, 128, 0.25)",
      // background:""
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderBottom: "3px solid #979797",
    background: "#F2F2F2",
    marginTop: "20px",
    gap: "20px",
    paddingInline: "30px",
    paddingBlock: "10px",
    "& .column1": {
      flex: 1,
      "& p": {
        textAlign: "left",
      },
      "& h3": {
        textAlign: "left",
      },
    },
    "& .column2": {
      "& h3": {
        color: "#5C5C5C",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "25px",
        paddingLeft: "20px",
      },
    },
    "& p": {
      margin: "0",
      textAlign: "center",
      color: "#979797",
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "25px",
      marginBottom: "20px",
    },
    "& h3": {
      margin: "0",
      textAlign: "center",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "25px",
    },
  },
  comments: {
    "& .mantine-1ix1d88": {
      borderRadius: "15px",
    },
  },

  upcomingHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#bf80bf",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "118px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "30px",
    },
  },

  quantityInput: {
    "& input": {
      borderRadius: "5px",
      width: "160px",
      height: "100px",
      border: "1px solid #979797",
      fontSize: "60px",
      fontWeight: "bold",
      textAlign: "center",
    },
  },
  medicationBox: {
    marginBottom: "20px",
  },
  saveButton: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      borderRadius: "5px",
      background: "rgba(255, 186, 69, 0.85)",
      width: "145px",
      height: "60px",
      color: "#000",

      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",

      "&:hover": {
        background: "rgba(255, 186, 69, 0.85)",
      },
    },
  },
  prnSelectBox: {
    "& .mantine-Select-input": {
      background: "#F2F2F2",
      borderRadius: 15,
      minHeight: "1rem !important",
      height: "4rem !important",
    },
  },
}));

export default useStyles;
