import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./HeroSection";
import { Box } from "@mantine/core";
import FeaturesPage from "./FeaturesPage";
import LogoCarousel from "./LogoCarousel";
import UpcomingFeatures from "./UpcomingFeatures";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import StartedBanner from "./StartedBanner";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import HeaderMain from "../../components/Header/HeaderMain";
import { pageInfoAnalytics, TrackPageView } from "../../config/mixpanelConfig";
import { removeCookie } from "../../utils";
import mixpanel from "mixpanel-browser";

const Landing = () => {
  const [showHeaderMain, setShowHeaderMain] = useState(true);
  const featuresRef = useRef(null);
  const location = useLocation();

  useEffect(()=>{
    pageInfoAnalytics("Home", "Home")
    TrackPageView("Home")
    mixpanel.track("landing-page-viewed")
    removeCookie("token");
  }, [])

  useEffect(() => {
    if (location.state?.scrollToFeatures && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowHeaderMain(false);
      } else {
        setShowHeaderMain(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box>
      <Box
        style={{
          opacity: showHeaderMain ? 1 : 0,
          visibility: showHeaderMain ? "visible" : "hidden",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <HeaderMain />
      </Box>
      <Box
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999,
          opacity: showHeaderMain ? 0 : 1,
          visibility: showHeaderMain ? "hidden" : "visible",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <Header />
      </Box>
      <HeroSection />
      <Box ref={featuresRef}>
        <FeaturesPage />
      </Box>
      <UpcomingFeatures />
      <LogoCarousel />
      <Testimonials />
      <StartedBanner />
      <Footer />
    </Box>
  );
};

export default Landing;
