import { Box, Container, createStyles } from "@mantine/core";

import AddButton from "../../../components/AddButton";
import NoteModal from "../../../components/NoteModal";
import NoteTypeModal from "../../../components/NoteTypeModal";
import NoteTypeTable from "../../../components/NoteTypeTable";
import Topbar from "../../../components/Topbar";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  getFieldTypes,
  getNoteTypes,
  getAllNoteTypes,
} from "../../../services/note";
import { useSelector } from "react-redux";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));
const NoteType = (props) => {
  const { noteTypes } = useSelector((state) => state.note);
  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getFieldTypes();
    // getNoteTypes();
    TrackPageView("Note Type");
  }, []);
  useEffect(() => {
    getNoteTypes(currentPage);
    getAllNoteTypes();
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber);
    setCurrentPage(pageNumber);
  };
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [isopen, setOpen] = useState(false);

  if (userType === "staff" && profile?.user?.role !== "admin") return null;

  return (
    <Container fluid className={classes.content} px={20}>
      <NoteModal
        opened={isopen}
        close={() => setOpen(false)}
        title={"Add New Type"}
        setView={() => null}
      />
      <NoteTypeModal
        close={close}
        save={setOpen}
        opened={opened}
        title={"New Note Type"}
      />
      <Topbar title={"Note Type"} back="/home/note" />
      <Box className={classes.btn}>
        <AddButton title={"Add New Note Type"} onClick={open} />
      </Box>
      <Box className={classes.table}>
        <NoteTypeTable />
        <CustomPagination
          itemsPerPage={10}
          data={noteTypes?.count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default NoteType;
