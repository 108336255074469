import React from "react";
import { Accordion, Text } from "@mantine/core";
import { useStyles } from "./style"; 

const Faqcomp = () => {
  const { classes } = useStyles();

  const faqItems = [
    {
      value: "Are there any training and/or onboarding fees?",
      description:
        "Absolutely no training or onboarding fees. Ogiso is a subscription based platform, which means that you are only billed on a monthly basis depending on your subscription tier.",
    },
    {
      value: "What types of healthcare businesses can use Ogiso?",
      description:
        "Ogiso's platform is a comprehensive documentation and compliance solution designed specifically for HCBS (Home and Community-Based Services) providers. It streamlines documentation, improves compliance management, and enhances the overall efficiency of care services through a user-friendly digital platform.",
    },
    {
      value: "How does Ogiso improve documentation for HCBS providers?",
      description:
        "Ogiso replaces outdated methods like handwritten notes or Word documents with a cloud-based platform that automates record-keeping, ensures real-time updates, and enhances collaboration. This allows providers to focus more on client care and less on paperwork.",
    },
    {
      value: "Is Ogiso compliant with industry regulations?",
      description:
        "Yes, Ogiso’s platform is designed to meet all relevant industry standards and regulatory requirements, ensuring that your documentation remains compliant with state and federal guidelines for HCBS providers..",
    },
    {
      value: "Can I customize the platform to fit my organization’s needs?",
      description:
        "Absolutely. Ogiso offers customizable features that allow you to tailor the documentation process according to your organization’s specific workflow and compliance requirements.",
    },
    {
      value: "How secure is the data stored on Ogiso’s platform?",
      description:
        "Ogiso uses advanced encryption and security protocols to ensure that all data is stored securely. We are fully HIPAA-compliant, providing peace of mind that your sensitive information is protected.",
    },
    {
      value: "Is there any contract?",
      description:
        "There are NO contracts associated with your Ogiso subscription. You can cancel anytime directly from your dashboard.",
    },
  ];

  const items = faqItems.map((item) => (
    <Accordion.Item key={item.value} value={item.value}>
      <Accordion.Control className={classes.question}>
        <span>{item.value}</span>
      </Accordion.Control>
      <Accordion.Panel className={classes.answer}>
        {item.description}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Text className={classes.AccordionHeading}>
        Frequently asked questions
      </Text>
      <Accordion>{items}</Accordion>
    </>
  );
};

export default Faqcomp;
