import {
  Button,
  Group,
  Modal,
  Text,
  Tooltip,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { IconCalendar, IconUpload } from "@tabler/icons-react";
import React, { useState } from "react";
import useStyles from "./stylesComponents";
import { Dropzone } from "@mantine/dropzone";
import { uploadFiles } from "../../services/Document";
import { useSelector } from "react-redux";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";

function UploadDocument({ selectedFolder,client }) {
  const { classes } = useStyles();
  const [opened, setOpen] = useState(false);
  const theme = useMantineTheme();
  const { uploadFileLoading } = useSelector((state) => state.document);
  const [files, setFiles] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);
  // console.log("🚀 ~ UploadDocument ~ expiryDates:", expiryDates);
  // console.log("🚀 ~ UploadDocument ~ files:", files);

  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const remove = (id) => {
    setFiles((p) => {
      return p?.filter((v) => v?.name !== id);
    });
  };

  const Header = () => {
    return (
      <div className={classes.uploadHeader}>
        <Text size={"md"} weight={"bolder"} ta={"center"}>
          Upload Document
        </Text>
      </div>
    );
  };
  const Content = () => {
    return (
      <div className={classes.column}>
        <div
          className={classes.row}
          style={{ gap: 10, justifyContent: "center", margin: 20 }}
        >
          <img
            src="/assets/images/folder.png"
            alt="folder-icon"
            className={classes.modalHeaderImage}
          />

          <Text>
            Location Selected :{" "}
            {selectedFolder ? selectedFolder?.name : "All Documents"}
          </Text>
        </div>

        <div className={classes.dropzone}>
          <Dropzone
            onDrop={(newFiles) => setFiles([...files, ...newFiles])}
            onReject={(files) => console.log("rejected files", files)}
            maxSize={3 * 1024 ** 2}
            // accept={IMAGE_MIME_TYPE}
          >
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: rem(220), pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                {/* <IconUpload
                size="3.2rem"
                stroke={1.5}
                color={
                  theme.colors[theme.primaryColor][
                    theme.colorScheme === "dark" ? 4 : 6
                  ]
                }
              /> */}
              </Dropzone.Accept>
              <Dropzone.Reject>
                {/* <IconX
                size="3.2rem"
                stroke={1.5}
                color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
              /> */}
              </Dropzone.Reject>
              <Dropzone.Idle>
                {/* <IconPhoto size="3.2rem" stroke={1.5} /> */}
              </Dropzone.Idle>

              <div className={classes.column}>
                <img
                  src="/assets/images/drag.png"
                  alt="Drag & Drop"
                  className={classes.dragImage}
                />
                <Text size="md" inline>
                  Drag & drop files or{" "}
                  <span className={classes.browseText}>
                    <u>Browse</u>
                  </span>
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Supported Formats: JPEG, PNG, SVG, PDF, docx, xlsx, IMG, pptx
                </Text>
              </div>
            </Group>
          </Dropzone>
        </div>
        {files.length ? (
          <div
            className={classes.row}
            style={{
              justifyContent: "space-around",
              alignSelf: "flex-start",
              width: "100%",
              marginTop: 50,
            }}
          >
            <div className={classes.column}>
              <Text my={5} style={{ alignSelf: "flex-start" }}>
                Uploaded
              </Text>
              {files?.map((value, index) => (
                <div className={classes.fileItem} key={index}>
                  <Tooltip label={value?.name}>
                    <Text lineClamp={1} w={180}>
                      {value?.name}
                    </Text>
                  </Tooltip>
                  <img
                    src="/assets/images/deleteFolder.png"
                    alt="delete"
                    style={{ width: 30, cursor: "pointer" }}
                    onClick={() => {
                      remove(value?.name);
                      expiryDates?.splice(1, index);
                      setExpiryDates([...expiryDates]);
                    }}
                  />
                </div>
              ))}
            </div>

            <div className={classes.column}>
              <Text my={5} style={{ alignSelf: "flex-start" }}>
                Expiration Date
              </Text>
              {files?.map((value, index) => (
                <div
                  style={{
                    background: "rgba(242, 242, 242, 1)",
                    margin: 5,
                    borderRadius: 2,
                  }}
                >
                  <DatePickerInput
                    key={index}
                    label="Enter Date"
                    size="xs"
                    value={
                      expiryDates?.[index]
                        ? new Date(expiryDates?.[index])
                        : null
                    }
                    onChange={(date) => {
                      let temp = [...expiryDates];
                      temp[index] = moment(date).toISOString();
                      setExpiryDates([...temp]);
                    }}
                    placeholder="mm/dd/yyyy"
                    icon={<IconCalendar />}
                    mx="auto"
                    className={classes.datePicker}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <Button
          loading={uploadFileLoading}
          onClick={async () => {
            let formdata = new FormData();
            files?.map((file) => {
              formdata.append("file", file);
            });
            if (selectedFolder) {
              formdata.append("folderId", selectedFolder?._id);
            }
            formdata.append("expiryDates", JSON.stringify(expiryDates));
            formdata.append("client", client);
            await uploadFiles(formdata, selectedFolder?._id , client);
            close();
            setFiles([]);
            setExpiryDates([]);
          }}
          my={5}
          leftIcon={
            <img src="/assets/images/save.png" alt="save-icon" width={20} />
          }
          color="yellow"
          style={{ marginBottom: "15px" }}
        >
          Save
        </Button>
      </div>
    );
  };
  return (
    <div>
      <Button
        leftIcon={<IconUpload size={20} />}
        color="yellow"
        onClick={open}
        style={{ color: "black" }}
      >
        Upload Documents
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        className={classes.uploadModal}
        withCloseButton={false}
        size={"lg"}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0,
          blur: 3,
        }}
      >
        <Header />
        <Content />
      </Modal>
    </div>
  );
}

export default UploadDocument;
