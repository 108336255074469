import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  saveProfileLoading: false,
  profile: null,
  businessProfile:null,
  timeZone: "EST",
};

export const profileState = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setTimeZone(state, action) {
      return {
        ...state,
        timeZone: action?.payload
      };
    },
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setLoadingSaveProfile(state, action) {
      return {
        ...state,
        saveProfileLoading: action?.payload
      };
    },
    setProfile(state, action) {
      return {
        ...state,
        loading: false,
        saveProfileLoading: false,
        profile: action?.payload
      };
    },
    setBusinessProfile(state, action) {
      return {
        ...state,
        loading: false,
        businessProfile: action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const {setTimeZone ,setLoading, setLoadingSaveProfile, setProfile , setBusinessProfile} =
  profileState.actions;
export default profileState.reducer;
