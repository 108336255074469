import { createStyles } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  accordionControl: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  // mymodal: {
  //   width: "100%",
  //   overflow: "visible",
  //   "& .mantine-Modal-content": {
  //     // overflowY: "visible !important"

  //   },
  //   "& .mantine-Modal-inner": {
  //     padding: "px !important",
  //   },
  //   "& .mantine-Modal-header": {
  //     zIndex: 100,
  //   },
  mymodal: {
    width: "100%",
    margin: "10px",
    overflow: "auto",
    // height: 600,
    borderRadius: "1rem 1rem 1rem 1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",

    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },
    "& .mantine-Modal-body": {
      height: "90vh ",
      overflowY: "auto",
      padding: "0",
      background: "#F2F2F2",
      borderRadius: "1rem",
      // "@media (min-width: 2560px)": {
      //   height: "80vh !important",
      // },
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
    // "@media (max-width: 400px)": {
    //   width: "auto",
    // },
    // "@media (min-width: 1440px)": {
    //   height: "50vh",
    // },
    // "@media (min-width: 1920px)": {
    //   height: "45vh",
    // },
    // "@media (max-width: 2560px)": {
    //   minHeight: "100vh !important",
    // },
  },
  mwd: {
    width: "100%",
    "& .mantine-Modal-header": {
      zIndex: 100,
    },
    "@media (max-width: 600px)": {
      width: "100% !important",
      flexDirection: "column",
    },
  },
  myfont: {
    fontFamily: "Inter",
    overflow: "auto",
    color: "red",
  },
  // descriptionText: {
  //   "& .mantine-Textarea-input": {
  //     height: "150px",
  //   },
  // },
  disabledTextarea: {
    overflowY: "auto",
    resize: "none",
    cursor: "not-allowed",
    // backgroundColor: "red",
  },
  viewTextArea: {
    minHeight: "100px",
    height: "100px",
  },
  descriptionIcon: {
    position: "absolute",
    top: 10,
    left: 10,
  },
  labelTextarea: {
    fontWeight: "500",
    paddingBottom: "10px",
    margin: "0 !important",
  },
  infoLabel: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4ead8",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "80px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
    },
  },
  modalTopHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  staffContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },

  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    padding: "0 1rem",
  },
  textInputBox: {
    backgroundColor: "#fff",
    width: "100%",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "5px 15px",
    borderRadius: "15px",
    position: "relative",
    "& textarea[disabled]": {
      backgroundColor: "#fff !important",
    },
    "& .mantine-Textarea-input": {
      border: "none",
      paddingInline: "0",
    },
    "& .label": {
      position: "absolute",
      borderRadius: "5px",
      top: -10,
      left: 15,
      zIndex: 100,
      color: "#000",
      margin: 0,
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
  },
  modalContentBG: {
    // background: "#fff",
    borderRadius: "10px",
    margin: "1rem",
    // padding: "1rem",
  },
  accordionTitle: {
    background: "#F2F2F2",
    padding: ".5rem",
    borderRadius: "5px",
  },
  accordionItem: {
    background: "#fff",
    borderRadius: "10px",
    borderBottom: "none !important",

    "& .mantine-Accordion-control": {
      borderRadius: "10px !important",
    },
  },
  howOftenClass: {
    border: "1px solid #F2F2F2",
    padding: "0 .5rem",
    borderRadius: "10px",
    marginBottom: "1rem",
    marginTop: ".3rem",
    width: "350px",
  },
  accordionInput: {
    border: "1px solid #F2F2F2",
    padding: "0 .5rem",
    borderRadius: "10px",
    marginBottom: "1rem",
    marginTop: ".3rem",
    width: "100%",
    // overflowY: "auto",
    // height: "150px",
  },
  activityContainer: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    gap: "10px",
    width: "100%",
    height: "100%",
  },
  radioGroup: {
    border: "1px solid #F2F2F2",
    borderRadius: "10px",
  },
  select: {
    background: "#f1f3f5",
    padding: "2px 10px",
    width: "250px",
    marginTop: "1rem",
  },
  selectBG: {
    background: "#f1f3f5",
    padding: "5px",
    borderRadius: "5px",
  },
  accordionRowWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  accordionRowSingleLine: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  accordionRowSingleLineText: {
    flex: 1,
  },

  accordianSingleLineBox: {
    flex: 5,
  },
  infoAccordion: {
    height: "250px",
    overflowY: "auto",
  },
  outcomeText: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#212529",
  },
  outcomeContianer: {
    background: "#f1f3f5",
    padding: "5px",
    borderRadius: "5px",
    marginTop: "3px",
  },
}));

export default useStyles;
