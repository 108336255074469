import React from "react";
import { Group, Text, Accordion, Checkbox } from "@mantine/core";

import moment from "moment-timezone";
import { useSelector } from "react-redux";

function AccordionLabel({ label, description }) {
  return (
    <Group>
      <div>
        <Text>{label}</Text>
        <Text size="sm" c="dimmed" fw={400}>
          {description}
        </Text>
      </div>
    </Group>
  );
}

function AccordionCheck({
  Data,
  Heading,
  checksDone,
  setChecks,
  checks,
  userType,
  userId,
  viewOnly,
  shift,
}) {
  const { timeZone } = useSelector((state) => state.profile);

  const charactersList = [
    {
      id: "bender",
      label: Heading,
      content: (
        <div>
          {Data?.map((data) => {
            let index = checks?.findIndex(
              // (item) => item === data?._id
              (item) => item.field === data?._id
            );
            let indexForDisabled = checks?.find(
              // (item) => item === data?._id
              (item) =>
                item.field === data?._id &&
                item?.staff === userId &&
                item.shift === shift
            );
            return (
              <Checkbox
                // id="checkbox"
                color="yellow"
                style={{ marginBottom: "10px" }}
                checked={index !== -1 ? true : false}
                disabled={
                  viewOnly
                    ? true
                    : index !== -1 && !indexForDisabled && userType === "staff"
                    ? true
                    : false
                }
                key={data?._id}
                onChange={(e) => {
                  if (e.target.checked) {
                    // console.log({
                    //   field: data?._id,
                    //   staff: userId,
                    //   shift: shift || "",
                    // });
                    setChecks([
                      ...checks,
                      {
                        field: data?._id,
                        staff: userId,
                        shift: shift || "",
                      },
                    ]);
                  } else {
                    let index = checks?.findIndex(
                      (item) => item.field === data?._id
                    );
                    checks.splice(index, 1);
                    setChecks([...checks]);
                  }
                }}
                label={`${data?.name} ${
                  index !== -1 && checksDone?.[index]?.staff?.name
                    ? ` (${checksDone?.[index]?.staff?.name}) (${
                        checksDone?.[index]?.shift?.toLowerCase() == "night"
                          ? "N"
                          : checksDone?.[index]?.shift?.toLowerCase() ==
                            "morning"
                          ? "M"
                          : "E"
                      }) (${moment
                        .tz(checksDone?.[index]?.checkedAt, timeZone)
                        .format("MM-DD-YYYY hh:mm a")})`
                    : ""
                }`}
              />
            );
          })}
        </div>
      ),
    },
  ];

  const items = charactersList.map((item) => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{item.content}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));
  // console.log("checks", checks);
  return (
    <Accordion
      multiple
      defaultValue={["bender"]}
      chevronPosition="right"
      variant="contained"
    >
      {items}
    </Accordion>
  );
}

export default AccordionCheck;
