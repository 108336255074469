import React from "react";

import { ScrollArea, Table, createStyles } from "@mantine/core";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecordNotFound from "../RecordNotFound";
import TableLoader from "../TableLoader";
import { useDisclosure } from "@mantine/hooks";

import ClientMedicationTableRow from "../ClientMedicationTableRow";
import AddButton from "../AddButton";
import PatientModal from "../PatientModal";
import PatientViewModal from "../PatientViewModal";
import { setAddPatientLoading } from "../../store/reducers/patient";
import { getSinglePatients } from "../../services/patient";
import CustomPagination from "../Pagination/CustomPagination";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const ClientViewMedication = ({ form3, setClientDetails }) => {
  const { classes, cx } = useStyles();
  const { loading } = useSelector((state) => state.patient);

  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);

  const form3Ref = useRef();

  const [opened, { open, close }] = useDisclosure(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsCopy, setRowsCopy] = useState(form3);
  const [rows, setRows] = useState([]);
  // console.log("🚀 ~ ClientViewMedication ~ rows:", rows);

  useEffect(() => {
    setRows(form3);
  });
  const rowsData =
    rows &&
    rows?.map((row, i) => (
      <ClientMedicationTableRow
        row={row}
        index={i + 1 + (currentPage - 1) * 10}
        key={i}
        setClientDetails={setClientDetails}
      />
    ));

  useEffect(() => {
    let start = (currentPage - 1) * ((currentPage - 1) * 10);
    setRows(rowsCopy?.slice(start, start + 10));
  }, [currentPage]);

  return (
    <>
      <div
        style={{ marginBottom: "30px", display: "flex", justifyContent: "end" }}
        className={classes.btn}
      >
        <AddButton
          onClick={(e) => {
            dispatch(setAddPatientLoading(false));
            open(e);
          }}
          title={"Add Medication Plan"}
        />

        <PatientViewModal
          page={currentPage}
          opened={opened}
          close={close}
          form3={form3}
          setClientDetails={setClientDetails}
          ref={form3Ref}
          title={"Add Medication Plan"}
        />
      </div>
      <ScrollArea
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        sx={{ marginTop: "30px" }}
      >
        <Table
          withColumnBorders
          withBorder
          highlightOnHover
          enableStickyHeader
          sx={{ fontFamily: "Inter" }}
          fontSize={"sm"}
          verticalSpacing={"md"}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th style={{ minWidth: "80px" }}>Entry #</th>

              <th style={{ minWidth: "80px" }}>Name of Medication </th>
              <th style={{ minWidth: "140px" }}>Medication Frequency</th>
              <th style={{ minWidth: "80px" }}>Medication Start Date</th>
              <th style={{ minWidth: "80px" }}>Dosage </th>
              <th style={{ minWidth: "80px" }}>Medicines Count</th>
              <th style={{ minWidth: "80px" }}>Medicines Administered</th>
              <th style={{ minWidth: "80px" }}> Actions </th>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={1000}>
                  <TableLoader />
                </td>
              </tr>
            ) : rowsData?.length ? (
              <>{rowsData}</>
            ) : (
              <tr>
                <td colSpan={1000}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      height: "70vh",
                      gap: "8rem",
                    }}
                  >
                    <RecordNotFound />
                    <p className={classes.notFoundText}>
                      You don&apos;t have any record, Add Medications in
                      Individual to see here.
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CustomPagination
          data={rowsCopy?.length}
          itemsPerPage={10}
          currentPage={currentPage}
          handlePageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </ScrollArea>
    </>
  );
};

export default ClientViewMedication;
