import { Box, Text } from "@mantine/core";
import React from "react";
import useStyles from "./style";

function FeatureHighlightCard({ heading, text, bgColor,hdngColor, textColor}) {
  const { classes } = useStyles();

  return (
    <Box bg={bgColor} className={classes.featureHighlightCard}>
      <Text color={hdngColor || "black"} className={classes.featureHighlightCardHdng} >{heading}</Text>
      <Text color={textColor || "#292828"} className={classes.featureHighlightCardText}>{text}</Text>
    </Box>
  );
}

export default FeatureHighlightCard;
