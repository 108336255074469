import { Link } from "react-router-dom";
import useStyles from "./style";
import Header from "../../components/Header/Header";
import { Box } from "@mantine/core";
import StartedBanner from "../LandingPage/StartedBanner";
import Footer from "../LandingPage/Footer";
import { useEffect } from "react";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";

const CookiesPolicy = () => {
  const { classes } = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    pageInfoAnalytics("Cookies Policy Page Viewed", "Cookies Policy Page");
  }, []);
  return (
    <>
      <Box className={classes.header}>
        <Header />
      </Box>
      <div className={classes.TCandPP}>
        <h2 className={classes.mainHeading}>COOKIE POLICY</h2>
        <span className={classes.dateUpdate}>
          Last updated December 16, 2023
        </span>
        <p className={classes.text}>
          This Cookie Policy explains how Lumen Christi of Arizona LLC
          ("Company," "we," "us," and "our") uses cookies and similar
          technologies to recognize you when you visit our website at{" "}
          <Link
            target="_blank"
            className={classes.linkStyle}
            to={"https://ogiso.io"}
          >
            https://www.ogiso.io
          </Link>
          ("Website"). It explains what these technologies are and why we use
          them, as well as your rights to control our use of them. In some cases
          we may use cookies to collect personal information, or that becomes
          personal information if we combine it with other information.
        </p>
        <h2 className={classes.mainHeading}>What are cookies?</h2>

        <p className={classes.text}>
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information.
          <br />
          <br />
          Cookies set by the website owner (in this case, Lumen Christi of
          Arizona LLC) are called "first-party cookies." Cookies set by parties
          other than the website owner are called "third-party cookies."
          Third-party cookies enable third-party features or functionality to be
          provided on or through the website (e.g., advertising, interactive
          content, and analytics). The parties that set these third-party
          cookies can recognize your computer both when it visits the website in
          question and also when it visits certain other websites.
        </p>

        <h2 className={classes.mainHeading}>Why do we use cookies?</h2>

        <p className={classes.text}>
          We use first- and third-party cookies for several reasons. Some
          cookies are required for technical reasons in order for our Website to
          operate, and we refer to these as "essential" or "strictly necessary"
          cookies. Other cookies also enable us to track and target the
          interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Website for
          advertising, analytics, and other purposes. This is described in more
          detail below.
        </p>

        <h2 className={classes.mainHeading}>How can I control cookies?</h2>
        <p className={classes.text}>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          Cookie Consent Manager. The Cookie Consent Manager allows you to
          select which categories of cookies you accept or reject. Essential
          cookies cannot be rejected as they are strictly necessary to provide
          you with services.
          <br />
          <br />
          The Cookie Consent Manager can be found in the notification banner and
          on our website. If you choose to reject cookies, you may still use our
          website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend your web browser
          controls to accept or refuse cookies.
          <br />
          <br />
          The specific types of first- and third-party cookies served through
          our Website and the purposes they perform are described in the table
          below (please note that the specific cookies served may vary depending
          on the specific Online Properties you visit):
        </p>
        <h2 className={classes.mainHeading}>
          How can I control cookies on my browser?
        </h2>
        <p className={classes.text}>
          As the means by which you can refuse cookies through your web browser
          controls vary from browser to browser, you should visit your browser's
          help menu for more information. The following is information about how
          to manage cookies on the most popular browsers:
          <ul style={{ textAlign: "left", listStyleType: "square" }}>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={
                  "https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                }
              >
                Chrome
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={
                  "https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                }
              >
                Internet Explorer
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={
                  "https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                }
              >
                Firefox
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={
                  "https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                }
              >
                Safari
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={
                  "https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                }
              >
                Edge
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={classes.linkStyle}
                to={"https://help.opera.com/en/latest/web-preferences/"}
              >
                Opera
              </Link>
            </li>
          </ul>
          <p className={classes.text}>
            In addition, most advertising networks offer you a way to opt out of
            targeted advertising. If you would like to find out more
            information, please visit
            <ul style={{ textAlign: "left", listStyleType: "square" }}>
              <li>
                <Link
                  target="_blank"
                  className={classes.linkStyle}
                  to={"http://www.aboutads.info/choices/"}
                >
                  Digital Advertising Alliance
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className={classes.linkStyle}
                  to={"https://youradchoices.ca/"}
                >
                  Digital Advertising Alliance of Canada
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className={classes.linkStyle}
                  to={"http://www.youronlinechoices.com/"}
                >
                  European Interactive Digital Advertising Alliance
                </Link>
              </li>
            </ul>
          </p>
        </p>
        <h2 className={classes.mainHeading}>
          What about other tracking technologies, like web beacons?
        </h2>
        <p className={classes.text}>
          Cookies are not the only way to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called "tracking pixels" or "clear gifs").
          These are tiny graphics files that contain a unique identifier that
          enables us to recognize when someone has visited our Website or opened
          an email including them. This allows us, for example, to monitor the
          traffic patterns of users from one page within a website to another,
          to deliver or communicate with cookies, to understand whether you have
          come to the website from an online advertisement displayed on a
          third-party website, to improve site performance, and to measure the
          success of email marketing campaigns. In many instances, these
          technologies are reliant on cookies to function properly, and so
          declining cookies will impair their functioning.
        </p>
        <h2 className={classes.mainHeading}>
          Do you use Flash cookies or Local Shared Objects?
        </h2>
        <p className={classes.text}>
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention, and for
          other site operations.
          <br />
          <br />
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the
          <Link
            target="_blank"
            className={classes.linkStyle}
            to={
              "http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            }
          >
            {" "}
            Website Storage Settings Panel
          </Link>{" "}
          . You can also control Flash Cookies by going to the{" "}
          <Link
            target="_blank"
            className={classes.linkStyle}
            to={
              "http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
            }
          >
            Global Storage Settings Panel{" "}
          </Link>{" "}
          and following the instructions (which may include instructions that
          explain, for example, how to delete existing Flash Cookies (referred
          to "information" on the Macromedia site), how to prevent Flash LSOs
          from being placed on your computer without your being asked, and (for
          Flash Player 8 and later) how to block Flash Cookies that are not
          being delivered by the operator of the page you are on at the time).
          <br />
          <br />
          Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.
        </p>
        <h2 className={classes.mainHeading}>
          Do you serve targeted advertising?
        </h2>
        <p className={classes.text}>
          Third parties may serve cookies on your computer or mobile device to
          serve advertising through our Website. These companies may use
          information about your visits to this and other websites in order to
          provide relevant advertisements about goods and services that you may
          be interested in. They may also employ technology that is used to
          measure the effectiveness of advertisements. They can accomplish this
          by using cookies or web beacons to collect information about your
          visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you.
          The information collected through this process does not enable us or
          them to identify your name, contact details, or other details that
          directly identify you unless you choose to provide these.
        </p>
        <h2 className={classes.mainHeading}>
          How often will you update this Cookie Policy?
        </h2>
        <p className={classes.text}>
          We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal, or regulatory reasons. Please therefore revisit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies.
          <br />
          The date at the top of this Cookie Policy indicates when it was last
          updated.
        </p>
        <h2 className={classes.mainHeading}>
          Where can I get further information?
        </h2>
        <p className={classes.text}>
          If you have any questions about our use of cookies or other
          technologies, please email us at support@ogiso.io or by post to:
          <br />
          <br />
          <span
            style={{ textAlign: "left", display: "flex", alignItems: "start" }}
          >
            Lumen Christi of Arizona LLC <br /> 2626 E Havard St <br /> Phoenix,
            AZ 85008 <br /> United States <br /> Phone: 7573382712 <br />{" "}
            support@ogiso.io
          </span>
        </p>
        <h2 className={classes.mainHeading}>PRIVACY NOTICE</h2>
        <span className={classes.dateUpdate}>
          Last updated December 16, 2023
        </span>
        <p className={classes.text}>
          This privacy notice for Ogiso ("we," "us," or "our"), describes how
          and why we might collect, store, use, and/or share ("process") your
          information when you use our services ("Services"), such as when you:{" "}
          <br />
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services.
        </p>
        <h2 className={classes.mainHeading}>SUMMARY OF KEY POINTS</h2>
        <p className={classes.text}>
          <span style={{ fontStyle: "italic" }}>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our{" "}
            <Link
              className={classes.linkStyle}
              target="_blacnk"
              to={
                "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#toc"
              }
            >
              table of contents
            </Link>{" "}
            below to find the section you are looking for.
          </span>
          <br />
          <br />
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with us and the Services, the choices you make,
          and the products and features you use. Learn more about{" "}
          <Link
            className={classes.linkStyle}
            target="_blacnk"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#personalinfo"
            }
          >
            personal information you disclose to us.
          </Link>
          <br />
          <br />
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
          <br />
          <br />
          Do we receive any information from third parties? We may receive
          information from public databases, marketing partners, social media
          platforms, and other outside sources. Learn more about
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#othersources"
            }
          >
            {" "}
            information collected from other sources
          </Link>
          .
          <br />
          <br />
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Learn more about{" "}
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#infouse"
            }
          >
            {" "}
            how we process your information
          </Link>
          .
          <br />
          <br />
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties. Learn more about{" "}
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#whoshare"
            }
          >
            {" "}
            when and with whom we share your personal information
          </Link>
          . What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#privacyrights"
            }
          >
            {" "}
            your privacy rights
          </Link>
          .
          <br />
          <br />
          How do you exercise your rights? The easiest way to exercise your
          rights is by submitting a{" "}
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/notify/e5850051-e777-476b-ae80-71bd11766f1c"
            }
          >
            {" "}
            data subject access request
          </Link>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
          <br />
          <br />
          Want to learn more about what we do with any information we collect?
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#toc"
            }
          >
            {" "}
            Review the privacy notice in full
          </Link>
          .
        </p>
        <h2 className={classes.mainHeading}>TABLE OF CONTENTS</h2>
        <ul
          style={{
            listStyleType: "none",
            textAlign: "left",
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            lineHeight: "1.5",
          }}
        >
          <li>
            <a style={{ textDecoration: "none" }} href="#information-collect">
              1. WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#information-process">
              2. HOW DO WE PROCESS YOUR INFORMATION?{" "}
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#information-personal">
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#TRACKING-TECHNOLOGIES">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#SOCIAL-LOGINS">
              5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="#TRANSFERRED-INTERNATIONALLY"
            >
              6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="#TRANSFERRED-INTERNATIONALLY"
            >
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="#INFORMATION-FROM-MINORS"
            >
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#PRIVACY-RIGHTS">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#DO-NOT-TRACK">
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#THIS-NOTICE">
              11. DO WE MAKE UPDATES TO THIS NOTICE?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#YOU-CONTACT-US-ABOUT">
              12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="#REVIEW-UPDATE-DELET">
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ul>
        <h2 id="information-collect" className={classes.mainHeading}>
          1. WHAT INFORMATION DO WE COLLECT?
        </h2>
        <span>Personal information you disclose to us</span>
        <p className={classes.text}>
          <i>In Short:</i> We collect personal information that you provide to
          us.
          <br />
          <br />
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
          <br />
          <br />
          Sensitive Information. We do not process sensitive information.
          <br />
          <br />
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h2 id="information-process" className={classes.mainHeading}>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
          <br />
          <br />
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <h2 id="information-personal" className={classes.mainHeading}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> We may share information in specific situations
          described in this section and/or with the following third parties. We
          may need to share your personal information in the following
          situations:
          <ul
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              listStyleType: "square",
            }}
          >
            <li>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li>
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners, or other companies that we
              control or that are under common control with us.
            </li>
            <li>
              Business Partners. We may share your information with our business
              partners to offer you certain products, services, or promotions.
            </li>
          </ul>
        </p>
        <h2 id="TRACKING-TECHNOLOGIES" className={classes.mainHeading}>
          {" "}
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h2>

        <p className={classes.text}>
          <i>
            <i>In Short:</i>
          </i>{" "}
          We may use cookies and other tracking technologies to collect and
          store your information.
          <br />
          <br />
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>

        <h2 id="SOCIAL-LOGINS" className={classes.mainHeading}>
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h2>

        <p className={classes.text}>
          <i>
            <i>In Short:</i>
          </i>{" "}
          If you choose to register or log in to our Services using a social
          media account, we may have access to certain information about you.
          <br />
          <br />
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
          <br />
          <br />
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h2 id="TRANSFERRED-INTERNATIONALLY" className={classes.mainHeading}>
          6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> We may transfer, store, and process your information
          in countries other than your own.
          <br />
          <br />
          Our servers are located in. If you are accessing our Services from
          outside, please be aware that your information may be transferred to,
          stored, and processed by us in our facilities and by those third
          parties with whom we may share your personal information (see "
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#whoshare"
            }
          >
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </Link>
          " above), in and other countries.
          <br />
          <br />
          If you are a resident in the European Economic Area (EEA), United
          Kingdom (UK), or Switzerland, then these countries may not necessarily
          have data protection laws or other similar laws as comprehensive as
          those in your country. However, we will take all necessary measures to
          protect your personal information in accordance with this privacy
          notice and applicable law.
        </p>

        <h2 id="KEEP-YOUR-INFORMATION" className={classes.mainHeading}>
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> We keep your information for as long as necessary to
          fulfill the purposes outlined in this privacy notice unless otherwise
          required by law.
          <br />
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h2 id="INFORMATION-FROM-MINORS" className={classes.mainHeading}>
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </h2>

        <p className={classes.text}>
          <i>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </i>
          <br />
          <br />
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&aspt;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at support@ogiso.io.
        </p>

        <h2 id="PRIVACY-RIGHTS" className={classes.mainHeading}>
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> You may review, change, or terminate your account at
          any time.
          <br />
          <br />
          <u>Withdrawing your consent:</u> If we are relying on your consent to
          process your personal information, which may be express and/or implied
          consent depending on the applicable law, you have the right to
          withdraw your consent at any time. You can withdraw your consent at
          any time by contacting us by using the contact details provided in the
          section "
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/dashboard/website/00ba6ea1-9f89-4cd6-94da-0a92da759852/privacy-policy#contact"
            }
          >
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </Link>
          " below.
          <br />
          <br />
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
          <br />
          <br />
          <h4>Account Information</h4>
          <br />
          <br />
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <br />
          <br />
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <h2 id="DO-NOT-TRACK" className={classes.mainHeading}>
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h2>

        <p className={classes.text}>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h2 id="THIS-NOTICE" className={classes.mainHeading}>
          11. DO WE MAKE UPDATES TO THIS NOTICE?
        </h2>

        <p className={classes.text}>
          <i>In Short:</i> Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
          <br />
          <br />
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <h2 id="YOU-CONTACT-US-ABOUT" className={classes.mainHeading}>
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h2>

        <p className={classes.text}>
          If you have questions or comments about this notice, you may contact
          us by post at:
          <br />
          <br />
          Lumen Christi of Arizona LLC <br />
          2626 E Havard St
          <br />
          Phoenix, AZ 85008 <br />
          United States <br />
          Phone: 7573382712 <br />
        </p>

        <h2 id="REVIEW-UPDATE-DELET" className={classes.mainHeading}>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>

        <p className={classes.text}>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please fill out and submit a{" "}
          <Link
            className={classes.linkStyle}
            target="_blank"
            to={
              "https://app.termly.io/notify/e5850051-e777-476b-ae80-71bd11766f1c"
            }
          >
            data subject access request.
          </Link>
        </p>
      </div>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default CookiesPolicy;
