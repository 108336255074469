import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  caution: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    alignSelf: "flex-end",
    width: "auto",
    gap: "15px",
    padding: "20px",
    "& p": {
      "@media (max-width: 1600px)": {
        fontSize: "12px",
      },
      "@media (max-width: 1440px)": {
        fontSize: "10px",
      },
      margin: "0",
    },
  },
  dFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    fontWeight: "500",
    "& p": {
      color: "#5C5C5C",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      fontSize: "20px",
    },
  },
  greenBox: {
    width: "15px",
    height: "15px",
    background: "#4fa80d",
  },
  redBox: {
    width: "15px",
    height: "15px",
    background: "#eb7a74",
  },
  blueBox: {
    width: "15px",
    height: "15px",
    background: "#c7daf5",
  },
  grayBox: {
    width: "15px",
    height: "15px",
    background: "#979797",
  },
  yellowBox: {
    width: "15px",
    height: "15px",
    background: "#feff80",
  },
  blackBox: {
    width: "15px",
    height: "15px",
    background: "#000",
  },
  purpleBox: {
    width: "15px",
    height: "15px",
    background: "#a03399",
  },
  divider: {
    width: "1px",
    backgroundColor: "#A8A8A8",
    height: "20px",
  },
}));

export default useStyles;
