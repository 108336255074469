import React, { useState } from "react";
import AddNewMedicationModal from "../AddNewMedicationModal";
import { useDisclosure } from "@mantine/hooks";
import useStyles from "../../pages/Home/Medication/style";
import { Box, Button } from "@mantine/core";
import { useSelector } from "react-redux";

const AddMedicationButton = ({ userId, selectedClient, getClientData }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [medicine, setMedicine] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const { profile } = useSelector((state) => state?.profile);

  const { classes } = useStyles();
  return (
    <>
      <AddNewMedicationModal
        opened={opened}
        onclose={(nType) => {
          close();
          setMedicine(null);
          if (isPreview) setIsPreview(false);
        }}
        data={medicine}
        title={"Chloroquine"}
        userId={userId}
        isPreview={isPreview}
        getClientData={getClientData}
      />
      <Box className={classes.bar} style={{ marginBottom: "10px", justifyContent:"flex-end" }}>
        <Box className={classes.right}>
          <Button
            color="yellow"
            onClick={() => open()}
            id="add-new-medication"
            disabled={!selectedClient  ? true : false}
            style={{ color: "black", marginTop:"20px" }}
            leftIcon={<img src={"/assets/images/icons8-pills-64 1.png"} alt="add-new-medication" />}
          >
            Add new Medication
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddMedicationButton;
