import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  InlinePadding: {
    paddingInline: "6rem",
    "@media (max-width: 768px)": {
      paddingInline: "3rem",
    },
    "@media (max-width: 430px)": {
      paddingInline: "1rem",
    },
  },
  heroImg: {
    // width: "100%",
    height: "auto",
    maxWidth: "100%",
    minWidth: "50%",
    display: "block",
  },
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    minWidth: "50%",
    display: "block",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    "@media (max-width: 430px)": {
      flexDirection: "column-reverse",
      marginTop: ".5rem",
    },
  },
  heroSection: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "4rem 0px",
    "@media (max-width: 430px)": {
      padding: "1rem 0px",
    },
  },
  leftBgImg: {
    position: "absolute",
    left: "0",
    top: "35%",
    zIndex: "-1",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  rightBgImg: {
    position: "absolute",
    right: "0",
    top: "35%",
    zIndex: "-1",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  primaryHeading: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "76px",
    "@media (max-width: 768px)": {
      fontSize: "32px",
      lineHeight: "46px",
    },
    "@media (max-width: 430px)": {
      lineHeight: "36px",
      fontSize: "24px !important",
    },
  },
  primarySubHeading: {
    fontFamily: "Inter",
    fontSize: "28px",
    padding: ".5rem 0",
    textTransform: "capitalize",
    fontWeight: "600",
    lineHeight: "31px",
    "@media (max-width: 768px)": {
      fontSize: "24px",
      lineHeight: "46px",
    },
    "@media (max-width: 430px)": {
      lineHeight: "36px",
      fontSize: "20px !important",
    },
  },
  text: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "26px",
    padding: "1rem 0",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "22px",
    },
    "@media (max-width: 430px)": {
      lineHeight: "20px",
      fontSize: "14px !important",
    },
    // width: "700px",
  },
  primaryBtn: {
    background: theme.other.primaryBtn,
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    width: "auto",
    height: "auto",
    lineHeight: "24px",
    padding: "1rem 3rem",
    borderRadius: "8px",
    margin: "24px 0px",
    "&:hover": {
      background: theme.other.primaryBtn,
      color: "#FFFFFF",
      opacity: "0.8",
    },
    "@media (max-width: 768px)": {
      padding: ".5rem 2rem",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  styleFeatureBtn: {
    width: "480px",
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "14px 14px 14px 14px",
    padding: "5px 5px 0px 5px",
    background:
      "linear-gradient(to bottom, #000000 20%, rgba(255, 255, 255, 0.2))",
    "@media(max-width:500px)": {
      width: "300px",
    },
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 1px 5px, rgba(0, 0, 0, 0.05) 0px 1px 10px",
  },

  features: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  featuresBtn: {
    background: theme.other.white,
    color: theme.other.black,
    borderColor: theme.other.black,
    borderWidth: "2px",
    borderStyle: "solid",
    fontFamily: "Inter",
    fontSize: "53px",
    fontWeight: "600",
    width: "auto",
    height: "auto",
    lineHeight: "76px",
    // padding: "1rem 2rem",
    borderRadius: "8px",
    margin: "24px 0px",
    padding: "0 20px",
    "&:hover": {
      background: theme.other.white,
      color: theme.other.black,
      borderColor: theme.other.black,
    },
    "@media (max-width: 768px)": {
      fontSize: "30px",
      // padding: "1rem",
    },
    "@media (max-width: 425px)": {
      fontSize: "20px",
      // padding: "1rem",
    },
  },
  Testimonials: {
    background: theme.other.white,
    color: theme.other.black,
    borderWidth: "2px",
    borderStyle: "solid",
    fontFamily: "Inter",
    fontSize: "63px",
    fontWeight: "600",
    width: "auto",
    height: "auto",
    lineHeight: "76px",
    padding: "1rem 3rem",
    borderRadius: "8px",
    margin: "24px 0px",
    "&:hover": {
      background: theme.other.white,
      color: theme.other.black,
    },
    "@media (max-width: 768px)": {
      margin: "10px 0px",
      fontSize: "30px",
      // padding: "1rem ",
    },
  },
  logoCarouselContainer: {
    margin: "6rem 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "3rem",
    "@media (max-width: 768px)": {
      margin: "2rem 0px",
    },
    "@media (max-width: 500px)": {
      margin: "2rem 0px",
      gap: "1rem",
    },
  },
  TestimonialsContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2rem",
  },
  testimonialsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  testimonialsCard: {
    width: "90%",
    height: "219.21px",
    borderRadius: "10px",
    background: "#F3F3F3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "1rem 3rem",
    marginTop: "-3rem",
    zIndex: "-1",
    "@media (max-width: 2570px)": {
      height: "400px",
    },
    "@media (max-width: 1800px)": {
      height: "400px",
    },
    "@media (max-width: 1200px)": {
      height: "440px",
    },
    "@media (max-width: 900px)": {
      height: "470px",
    },
    "@media (max-width: 700px)": {
      height: "600px",
      marginTop: "-2rem",
      padding: "1rem",
    },
    "@media (max-width: 425px)": {
      height: "550px",
    },
    "@media (max-width: 320px)": {
      height: "600px",
    }
  },
  testimonialsCardBlack: {
    background: theme.other.black,
    color: theme.other.white,
  },
  testimonialsImg: {
    // marginLeft: "3rem",
    // border: "7px solid #fff",
    borderRadius: "50%",
    width: "200px",
    height: "200px",
  },
  companyName: {
    // color: theme.other.black,
    fontFamily: "Inter",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "36px",
    textAlign: "center",
    marginTop: "2rem",
  },
  companyType: {
    fontSize: "20px",
    margin: ".5rem 0px",
  },
  message: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    "@media (max-width: 430px)": {
      lineHeight: "20px",
      fontSize: "15px",
    },
  },
  customerInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "end",
    width: "100%",
    marginTop: "1rem",
    marginRight: "4rem",
    
  },
  customerName: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "26px",
    textAlign: "right",
    width: "100%",
    marginTop: "1rem",
    margin: ".5rem 2rem",
  },
  position: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "450",
    lineHeight: "26px",
    textAlign: "right",
    width: "100%",
    // margin: "1rem 2rem",
  },
  carouselImgFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  CarouselContainer: {
    "& .mantine-Carousel-indicator": {
      background: theme.other.yellowColor,
      width: "15px",
      height: "15px",
      borderRadius: "50%",
    },
    "& .mantine-Carousel-control": {
      border: "none",
      boxShadow: "none",
      background: "transparent",
      margin: "0 -4rem",
      "@media (max-width: 768px)": {
        margin: "0 -3rem",
      },
      "@media (max-width: 430px)": {
        margin: "0 -2rem",
      },
    },
  },
  MobileFlexDirection: {
    paddingBlock: "5rem",
    "@media (max-width: 834px)": {
      flexDirection: "column-reverse",
      paddingBlock: "2rem",
    },
  },
  logo: {
    width: "10%",
    aspectRatio: "3/2",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      width: "20%",
    },
  },
  carouselContainer: {
    position: "relative",
  },
  carouselArrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: 10,
    padding: 0,
    margin: "0 3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: 30,
    },
  },
  testimonialCarouselArrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: 10,
    padding: 0,
    margin: "3rem -1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: 30,
    },
  },
  leftArrow: {
    left: theme.spacing.md,
  },
  rightArrow: {
    right: theme.spacing.md,
  },
  customDotListStyle: {
    " .react-multi-carousel-dot button": {
      border: "none",
    },
    " .react-multi-carousel-dot--active button": {
      background: theme.other.yellowColor,
    },
  },
  testimonialDotListStyle: {
    " .react-multi-carousel-dot button": {
      // marginTop: "4rem",
      border: "none",
    },
  },
}));
export default useStyles;
