import { Loader, createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  loader: {
    height: "30vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
function TableLoader() {
  const { classes } = useStyles();
  return (
    <div className={classes?.loader}>
      <Loader color="#FFBA45" />
    </div>
  );
}

export default TableLoader;
