import {
  Accordion,
  Avatar,
  Badge,
  Box,
  Button,
  Modal,
  SimpleGrid,
  Switch,
  Text,
} from "@mantine/core";
import useStyles from "./../MAR/modalStyle";
import CloseCircle from "../CloseCircle";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSinglePatients } from "../../services/patient";
import { decrypt } from "../../utils";
import { IconChevronDown } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

const ViewCarePlanModal = ({
  opened,
  onclose,
  data,
  setShowCarePlanDetails,
  setRowData,
}) => {
  const { classes } = useStyles();
  const id = data?.client?._id;
  const clientId = id?.slice(-6);
  const [clientDetails, setClientDetails] = useState(null);
  const { state } = useLocation();

  const getData = async () => {
    const res = await getSinglePatients(state);
    setClientDetails(res);
  };

  useEffect(() => {
    getData();
  }, [state]);


  const isMobileScreen = useMediaQuery("(max-width: 800px)");

  const handleClose = () => {
    onclose();
    setShowCarePlanDetails(false);
    setRowData(null);
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "1000px" }}>
          <Modal
            className={classes.medicationModal}
            opened={opened}
            onClose={handleClose}
            shadow="md"
            overlayProps={{ blur: 3 }}
            radius={"lg"}
            zIndex={1000}
            centered
            size={1200}
          >
            <CloseCircle onclose={handleClose} />
            <Box className={classes.header}>
              <Text variant="text" fw={600} size={24}>
                Individual Support Plan
              </Text>
            </Box>
            <Box className={classes.viewModalContent}>
              <Box className={classes.headerContent}>
                <Box className={classes.userDetails}>
                  <Box className={classes.userImg}>
                    <Avatar
                      shape="square"
                      radius="xl"
                      size={"xl"}
                      src={
                        clientDetails?.imageurl
                          ? decrypt(clientDetails?.imageurl)
                          : "/assets/images/personal2.png"
                      }
                    />
                    <Box className={classes.userInfo}>
                      <Text variant="text" fw={600} size={24}>
                        {data?.client?.name}
                      </Text>
                      <Text variant="text" fw={300}>
                        {clientId}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <SimpleGrid cols={3}>
                      <Text mt={5}>
                        <strong>Start Date:</strong>{" "}
                        {moment(data?.startDate).format("DD/MM/YYYY")}
                      </Text>
                      <div className={classes.type}>
                        <Text variant="text" fw={600}>
                          {data?.planType?.name}
                        </Text>
                        <Text variant="text" className={classes.date}>
                          {moment(data?.startDate).format("DD/MM/YYYY")} -{" "}
                          {moment(data?.endDate).format("DD/MM/YYYY")}
                        </Text>
                      </div>
                    </SimpleGrid>
                  </Box>
                </Box>
                {data?.isActive !== false && (
                  <Box className={classes.editBTN}>
                    <Button
                      onClick={() => onclose() || setShowCarePlanDetails(true)}
                      color="gray"
                      variant="transparent"
                      type="link"
                    >
                      Edit
                    </Button>
                  </Box>
                )}
              </Box>
              <Box className={classes.body}>
                {data?.outcomes?.length > 0 ? (
                  data?.outcomes?.map((out, index) => (
                    <Accordion
                      key={out.id}
                      chevron={<IconChevronDown size={30} color="#FBAA0D" />}
                      chevronPosition="right"
                      chevronSize={30}
                      styles={{
                        chevron: {
                          transition: "transform 200ms ease",
                          "&[data-active]": {
                            transform: "rotate(180deg)",
                          },
                        },
                      }}
                    >
                      <Accordion.Item value={"planType"}>
                        <Accordion.Control>
                          <SimpleGrid cols={3}>
                            <Box className={classes.accordionHeader}>
                              <Badge color="yellow" size="xl" circle>
                                {out?.outcomeNumber}
                              </Badge>
                              <Text variant="text">Outcome</Text>
                            </Box>
                            <Box className={classes.accordionHeader}>
                              <Text variant="text" fw={600}>
                                Title:
                              </Text>{" "}
                              <Text className={classes.accordionTitle}>
                                {out?.outcomeTitle}
                              </Text>
                            </Box>
                            <Box className={classes.accordionHeader}>
                              <Text variant="text" fw={600}>
                                Category:
                              </Text>{" "}
                              <Text className={classes.accordionTitle}>
                                {out?.outcomeCategory}
                              </Text>
                            </Box>
                          </SimpleGrid>
                        </Accordion.Control>
                        <Accordion.Panel
                          colapsed
                          className={classes.accordionPanel}
                        >
                          <Text variant="text" fw={600} fz={20} mb={15}>
                            Support Details
                          </Text>
                          <Box className={classes.supportDetailsContent}>
                            {out.activities?.length > 0 &&
                              out?.activities.map((act, actIndex) => (
                                <Box className={classes.supportActivityDetails}>
                                  <Text
                                    variant="text"
                                    fw={600}
                                    className={classes.activityNumber}
                                  >
                                    {act?.activityNumber}
                                  </Text>
                                  <Box className={classes.textInputBox}>
                                    <Text className="label">
                                      Support Activity
                                    </Text>
                                    <Box className={classes.supportBorder}>
                                      <Text
                                        variant="text"
                                        className={classes.supportActivityText}
                                      >
                                        {act?.supportActivity}
                                      </Text>
                                    </Box>
                                  </Box>
                                  <Box className={classes.textInputBox}>
                                    <Text className="label">
                                      Support Instruction
                                    </Text>
                                    <Box className={classes.supportBorder}>
                                      <Text
                                        variant="text"
                                        className={classes.supportActivityText}
                                      >
                                        {act?.supportInstructions}
                                      </Text>
                                    </Box>
                                  </Box>
                                  <Box className={classes.supportDetailButtons}>
                                    <Box
                                      className={
                                        classes.supportDetailRightButtons
                                      }
                                    >
                                      <Text variant="text" fw={500}>
                                        Skill-Building
                                      </Text>
                                      <Switch
                                        checked={act?.skillBuilding === "yes"}
                                        color="yellow"
                                        size="md"
                                      />
                                    </Box>
                                    <Box
                                      className={
                                        classes.supportDetailRightButtons
                                      }
                                    >
                                      <Text variant="text" fw={500}>
                                        Intensive In-Home
                                      </Text>
                                      <Switch
                                        checked={act?.intensiveInHome === "yes"}
                                        color="yellow"
                                        size="md"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                          </Box>
                          <SimpleGrid
                            cols={isMobileScreen ? 1 : 3}
                            // className={classes.mwd}
                            sx={{
                              paddingBottom: 30,
                              display: "flex",
                              flexDirection: "column",
                              gap: 0,
                            }}
                          >
                            <Text variant="text">
                              How often you do this activity.
                            </Text>
                            <Box className={classes.howOften}>
                              <Text
                                variant="text"
                                className={classes.supportActivityText}
                              >
                                {out?.howOften}
                              </Text>
                            </Box>
                          </SimpleGrid>
                          <SimpleGrid
                            cols={isMobileScreen ? 2 : 2}
                            className={classes.mwd}
                            sx={{
                              paddingBottom: 30,
                            }}
                          >
                            <Box className={classes.textInputBox}>
                              <Text className="label">
                                I No Longer Need This Outcome
                              </Text>
                              <Box
                                className={classes.supportBorder}
                                style={{ width: "100%" }}
                              >
                                <Text
                                  variant="text"
                                  className={classes.supportActivityText}
                                >
                                  {out?.iNoLongerNeedThisOutcome}
                                </Text>
                              </Box>
                            </Box>
                            <Box className={classes.textInputBox}>
                              <Text className="label">
                                Additional Information
                              </Text>
                              <Box
                                className={classes.supportBorder}
                                style={{ width: "100%" }}
                              >
                                <Text
                                  variant="text"
                                  title="Additional Information"
                                  className={classes.supportActivityText}
                                >
                                  {out?.additionalInformation}
                                </Text>
                              </Box>
                            </Box>
                          </SimpleGrid>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  ))
                ) : (
                  <Text variant="text">No Outcome</Text>
                )}
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ViewCarePlanModal;
