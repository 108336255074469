import React from "react";
import { createStyles } from "@mantine/core";
import mixpanel from "mixpanel-browser";
import { PopupButton } from "react-calendly";

const useStyles = createStyles((theme) => ({
  reactCalendlyButtonWrapper: {
    padding: "24px 0px",
  },
  primaryBtn: {
    background: theme.other.primaryBtn,
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    width: "auto",
    height: "auto",
    lineHeight: "24px",
    padding: "1rem 3rem",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: theme.other.primaryBtn,
      color: "#FFFFFF",
      opacity: "0.8",
    },
    "@media (max-width: 768px)": {
      padding: ".5rem 2rem",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  buttonLink: {
    textDecoration: "none",
    color: "#fff",
  },
}));

export const DemoBookButton = () => {
  const { classes } = useStyles();

  return (
    <div className="inline-widget">
      <div
        className={classes.reactCalendlyButtonWrapper}
        onClick={() => mixpanel.track("demo booked")}
      >
        <PopupButton
          className={classes.primaryBtn}
          url="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
          rootElement={document.getElementById("root")}
          text="Book a Demo"
        />
      </div>
    </div>

    // <Link
    //   target="_blank"
    //   className={classes.buttonLink}
    //   to="https://calendly.com/ogiso-demo/30min?primary_color=ffba45"
    // >
    //   <Button className={classes.primaryBtn}>Book a Demo</Button>
    // </Link>
  );
};
