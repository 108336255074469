import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MantineProvider } from "@mantine/core";

const Main = () => {
  const theme = useSelector((state) => state.theme);
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <App />
    </MantineProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="939264682518-96edt1quguebnpem10tednjcq9be5s5f.apps.googleusercontent.com">
      <Main />
    </GoogleOAuthProvider>
  </Provider>
);

reportWebVitals();
