import React, { useRef, useState } from "react";
import { Modal } from "@mantine/core";

import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import { useDisclosure } from "@mantine/hooks";
import { getColor } from "../../pages/Home/Medication";
import moment from "moment";
import PRNMedicationModal from "../PRNMedicationModal";
import MedicationModal from "../MedicationModal";
import { useSelector } from "react-redux";

const MarDetailModal = ({ data, date, calendar }) => {
  const { classes } = useStyles();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <div className={classes.moreRow}>
        <p onClick={() => open()}>+{data?.length - 5} more</p>
      </div>
      <Modal
        className={classes.marDetailModal}
        opened={opened}
        size={700}
        onClose={close}
        shadow="md"
        overlayProps={{ blur: 3 }}
        radius={"lg"}
        zIndex={1000}
        centered
      >
        <CloseCircle onclose={close} />
        <div className={classes.header}>
          <h2>MAR</h2>
          <p>{date}</p>
        </div>
        <div className={classes.medBody}>
          {/* {console.log("Length", data?.length)} */}
          {data
            ?.sort(function (a, b) {
              return new Date(a?.date) - new Date(b?.date);
            })
            ?.map((v, i) => {
              return <Card key={i} data={v} calendar={calendar} />;
            })}
        </div>
      </Modal>
    </>
  );
};

const Card = ({ data, calendar }) => {
  const { classes } = useStyles();
  const _medicationType = data?.medicationType;
  const _outOfMedication = "Out of Medication";
  const _upcoming = "upcoming";

  const { selectedClient } = useSelector((state) => state.mar);

  const [opened, { open, close }] = useDisclosure(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [prnShow, setPRNShow] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const prnRef = useRef();

  const compareDate = (date, medicationType, upcoming) => {
    var time1 = moment(date).startOf("day"); // Set time to the start of the day
    var today = moment().startOf("day");
    if (
      calendar !== "weekly" &&
      calendar !== "monthly" &&
      medicationType === upcoming
    ) {
      return -1;
    } else if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  const openPRNView = (data) => {
    setIsNew(false);
    prnRef?.current?.openView(data);
    setPRNShow(true);
  };
  const prnClose = () => {
    setSelectedRow(null);
    setPRNShow(false);
  };

  return (
    <div>
      <div
        className={`${classes.cardContainer} ${
          _medicationType === _outOfMedication ? classes.outOfmedication : ""
        }`}
        style={{
          backgroundColor: getColor(_medicationType)?.dark,
          cursor: compareDate(data?.date) === 1 ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          setSelectedRow(data);
          if (data?.form3?.medicationType === "PRN") {
            return openPRNView(data);
          }

          if (compareDate(data?.date) === 1) {
            setIsViewOpen(false);
          } else {
            _medicationType === _upcoming || _medicationType === "Missed"
              ? setIsViewOpen(false)
              : setIsViewOpen(true);
            open();
          }
        }}
      >
        <div className={classes.box1}>
          <div>
            <h3>{data?.form3?.frequency}</h3>
            <p>{data?.timeSlot}</p>
          </div>
          {data?.form3?.isNarcotic == "Yes" && (
            <img src="/assets/images/narcotic.png" alt="" />
          )}
        </div>
        <div className={classes.box2}>
          <div>
            <h2>{data?.form3?.medicationName}</h2>
            <p>
              {data?.form3?.dosage} {data?.form3?.unit}
            </p>
          </div>
          <p>
            {data?.form3?.ingestionMode} {data?.form3?.dosageForm}
          </p>
        </div>
        <div className={classes.box3}>
          <p>Recorded by:</p>
          <p>{data?.staff?.name}</p>
          <div>
            <p>{moment(data?.createdAt).format("h:mm A")}</p>
            {_medicationType === "Taken" && (
              <img
                src={"/assets/images/done-icon.png"}
                style={{ width: "15px", height: "15px" }}
                alt="done"
              />
            )}
            {_medicationType === "Missed" && (
              <img
                src={"/assets/images/warning.png"}
                style={{ width: "15px", height: "15px" }}
                alt="warning"
              />
            )}
            {_medicationType === "Unable to Take" && (
              <img
                src={"/assets/images/do-not-disturb.png"}
                style={{ width: "15px", height: "15px" }}
                alt="do-not-disturb"
              />
            )}
            {_medicationType === "Refused" && (
              <img
                src={"/assets/images/unavailable.png"}
                style={{ width: "15px", height: "15px" }}
                alt="unavailable"
              />
            )}
            {_medicationType === "Out of Medication" && (
              <img
                src={"/assets/images/close-white.png"}
                style={{ width: "15px", height: "15px" }}
                alt="close"
              />
            )}
          </div>
        </div>
        <div
          className={classes.box4}
          style={{
            border:
              _medicationType === _outOfMedication
                ? "1px solid #fff"
                : "1px solid black",
          }}
        >
          <h3>
            {data?.medicineRemaining
              ? data?.medicineRemaining
              : data?.form3?.medicineRemaining}
          </h3>
          <p>
            of {data?.form3?.totalMedicine} <br />
            Remaining
          </p>
        </div>
      </div>
      <PRNMedicationModal
        isNew={isNew}
        ref={prnRef}
        opened={prnShow}
        onclose={prnClose}
        data={selectedClient}
      />
      <MedicationModal
        opened={opened}
        onclose={() => {
          close();
          setIsViewOpen(false);
          setSelectedRow(null);
        }}
        isViewOpen={isViewOpen}
        setIsViewOpen={setIsViewOpen}
        data={selectedRow}
      />
    </div>
  );
};

export default MarDetailModal;
