import { https } from "../http";
import store from "../store";
import { setSelectedClient } from "../store/reducers/mar";

import {
  setAddPatientLoading,
  setLoading,
  setPatients,
  setAllPatients,
} from "../store/reducers/patient";
import { setLoadingSaveProfile } from "../store/reducers/profile";
import { decrypt, showError, showInfo } from "../utils/index";
import { getMar } from "./mar";

// GET
const getAllPatients = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/client/${page}`);

    if (response?.status === 200) {
      let data = decrypt(response?.data?.data);
      let patients = data?.patients?.map((pa) => {
        if (typeof pa.name === "string" && typeof pa.form1 === "string")
          return {
            ...pa,
            name: `${decrypt(pa.name, false)}`,
            form1: decrypt(pa.form1),
            form2: decrypt(pa.form2),
            form3: decrypt(pa.form3),
          };
        else return pa;
      });

      store.dispatch(setPatients({ ...data, patients }));
    }
  } catch (error) {
    console.log({ error });
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getSinglePatients = async (id) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().get(`/client/single/${id}`);

    if (response?.status === 200) {
      let data = decrypt(response?.data?.data);
      store.dispatch(setLoading(false));
      // console.log("type of ", typeof data?.name);
      if (
        typeof data?.patient?.name === "string" &&
        typeof data?.patient?.form1 === "string"
      )
        return {
          ...data?.patient,
          name: `${decrypt(data?.patient?.name, false)}`,
          form1: decrypt(data?.patient?.form1),
          form2: decrypt(data?.patient?.form2),
          form3: decrypt(data?.patient?.form3),
        };
      else return data?.patient;
    }
  } catch (error) {
    console.log({ error });
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getAllPatientsModal = async (showLoading = true, clientId = null) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/client/all`);

    if (response?.status === 200) {
      let data = decrypt(response?.data?.data);
      let patients = data?.patients?.map((pa) => {
        if (typeof pa.name === "string" && typeof pa.form1 === "string")
          return {
            ...pa,
            name: decrypt(pa.name, false),
            form1: decrypt(pa.form1),
            form2: decrypt(pa.form2),
            form3: decrypt(pa.form3),
          };
        else return pa;
      });

      const _setClient = (patients) => {
        const _localClient = JSON.parse(localStorage.getItem("selectedClient"));
        store.dispatch(
          setSelectedClient(
            patients?.length > 0
              ? _localClient
                ? _localClient
                : patients?.[0]
              : null
          )
        );
      };

      if (clientId) {
        let patient = patients?.find((obj) => obj.id === clientId);
        if (patient) {
          store.dispatch(setSelectedClient(patient));
        } else {
          _setClient(patients);
        }
      } else {
        _setClient(patients);
      }
      store.dispatch(setAllPatients(patients));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// POST
const addPatient = async (data) => {
  try {
    console.log("inside at patient" , data);
    store.dispatch(setAddPatientLoading(true));
    let response = await https().post("/client", data);

    if (response?.status === 200) {
      store.dispatch(setAddPatientLoading(false));
      getAllPatients();
      return response;
    }
  } catch (error) {
    console.log("🚀 ~ addPatient ~ error:", error)
    store.dispatch(setAddPatientLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// PUT
const editPatient = async (data, showLoading = true, page = undefined) => {
  try {
    if (showLoading) store.dispatch(setAddPatientLoading(true));

    let response = await https().put(`/client/${data?.id}`, data);

    if (response?.status === 200) {
      //store.dispatch(setAddPatientLoading(false));
      getAllPatients(page, showLoading);
    }
    return response;
  } catch (error) {
    console.error("🚀 ~ editPatient ~ error:", error)
    store.dispatch(setAddPatientLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const editPatientFacilitiy = async (patientId, payload ,showLoading = true) => {
  try {
    // if (showLoading) store.dispatch(setAddPatientLoading(true));

    let response = await https().post(`/client/update-patient-facility/${patientId}`, payload);

    if (response?.status === 200) {
      //store.dispatch(setAddPatientLoading(false));
      // getAllPatients(page, showLoading);
    }
    return response;
  } catch (error) {
    store.dispatch(setAddPatientLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const AddMedication = async (
  data,
  id,
  showLoading = true,
  page = undefined,
  reset
) => {
  try {

    if (showLoading) store.dispatch(setAddPatientLoading(true));

    let response = await https().put(`/client/addmedication/${id}`, data);

    if (response?.status === 200) {
      showInfo(response?.data?.message);
      getAllPatientsModal(true, id);
      getMar(localStorage.getItem("calendar_date"), id);
      reset?.();
      //store.dispatch(setAddPatientLoading(false));
      // getAllPatients(page, showLoading);
    }
  } catch (error) {
    store.dispatch(setAddPatientLoading(false));
    console.log("error", error);
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const EditMedication = async (data, id, showLoading = true, reset) => {
  try {
    if (showLoading) store.dispatch(setAddPatientLoading(true));

    let response = await https().put(`/client/editmedication/${id}`, data);

    if (response?.status === 200) {
      showInfo(response?.data?.message);
      getAllPatientsModal(true, id);
      getMar(localStorage.getItem("calendar_date"), id);
      reset?.();
    }
  } catch (error) {
    store.dispatch(setAddPatientLoading(false));
    console.log("error", error);
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const deleteMedication = async (payload) => {
  try {
    store.dispatch(setAddPatientLoading(true));
    let string = `/client/deletemedication`;
    let response = await https().post(string, payload);
    if (response?.status === 200) {
      getAllPatientsModal(true, payload?.clientId);
      // getMar(localStorage.getItem("calendar_date"), payload?.clientId);
    }
    
    store.dispatch(setAddPatientLoading(false));
  } catch (error) {
    store.dispatch(setAddPatientLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// update cliet photo

const updateclientprofile = async (data, id) => {
  // console.log("data", data);
  // console.log("id", id);
  try {
    store.dispatch(setLoadingSaveProfile(true));
    let response = await https().put(`/client/profilephoto/${id}`, data);

    if (response?.status === 200) {
      //getProfile();
      return response?.data;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setLoadingSaveProfile(false));
    return false;
  }
};

const uploadClientProfile = async (data) => {
  console.log("🚀 ~ uploadClientProfile ~ data:", data)
  // console.log("data", data);
  // console.log("id", id);
  try {
    store.dispatch(setLoadingSaveProfile(true));
    let response = await https().post(`/client/profilephoto`, data);

    if (response?.status === 200) {
      //getProfile();
      return response?.data;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setLoadingSaveProfile(false));
    return false;
  }
};

const EditclientProfile = async (payload) => {
  // console.log('data',payload?.form1);
  // console.log('id',payload?.clientId);
  try {
    // let tempPayload = payload?.form1

    // if (showLoading) store.dispatch(setAddPatientLoading(true));

    await https().put(`/client/updateprofile`, payload);
    // await https().put(`/client/updateprofile/${payload?.clientId}`, {form1 : payload?.form1, form2 : payload?.form2});
    // await https().put(`/client/updateprofile/${payload?.clientId}`, {payload: tempPayload});

    // if (response?.status === 200) {
    //   //store.dispatch(setAddPatientLoading(false));
    //   // getAllPatients(page, showLoading);
    // }
  } catch (error) {
    // store.dispatch(setAddPatientLoading(false));
    // console.log("error", error);
    // showError(error?.response?.data?.message, error?.response?.status);
  }
};

// DELETE
const removePatient = async (id, callback) => {
  try {
    let response = await https().delete(`/client/${id}`);

    if (response?.status === 200) {
      getAllPatients(false);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setAddPatientLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export {
  addPatient,
  editPatient,
  getAllPatients,
  uploadClientProfile,
  updateclientprofile,
  AddMedication,
  removePatient,
  EditclientProfile,
  getAllPatientsModal,
  getSinglePatients,
  EditMedication,
  deleteMedication,
  editPatientFacilitiy
};
