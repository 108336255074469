import { Box, Button, Group, Text, createStyles } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscription,
  forcePayment,
  updatePlan
} from "../../services/subscription";
import {
  dateFormat2,
  formatDate,
  isSubscriptionExpired,
  removeCookie,
  showInfo
} from "../../utils";
import swal from "sweetalert";
import moment from "moment";
import { getProfile } from "../../services/profile";

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderColor: "#97979726",
    borderWidth: 1,
    borderRadius: 14,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column"
  },
  wrapper_select: {
    background: "#232323"
  },
  plantype: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 500,
    color: "#232323"
  },
  price: {
    fontFamily: "Inter",
    fontSize: 22,
    color: "#232323",
    fontWeight: 600
  },
  unit: {
    fontFamily: "Inter",
    fontSize: 25,
    color: "#979797"
  },
  period: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#979797"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    margin: 20
  },
  pricetag: {
    marginLeft: "auto",
    margin: "1px solid green",
    fontSize: "13 !important"
  },
  desc: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#979797"
  },
  descbox: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 15
  },
  btnbox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20
  },
  btn: {
    width: 150,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    marginLeft: "auto"
  },
  reNewbtn: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 500,
    margin: 10,
    width: 180
  },
  billing: {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#979797"
  },
  cycle: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "white"
  },
  selectedtype: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 500,
    color: "white"
  },
  selectedPrice: {
    fontFamily: "Inter",
    fontSize: 22,
    color: "white",
    fontWeight: 600
  }
}));
const PlanCard = ({
  activeId,
  allPackages,
  packageId,
  title,
  price,
  plan,
  level,
  billing,
  btn,
  stripePlanId,
  duration
}) => {
  let desc = "";
  if (price == 0)
    desc = `${plan?.staff} Staff Members, ${plan?.facility} Facility, ${plan?.patient} Patients & ${plan?.dailyUpdates} Daily Updates`;
  else
    desc = `${plan?.staff} Staff Members, ${plan?.facility} Facility, ${plan?.patient} Patients, ${plan?.notes} Notes & ${plan?.reports} Reports`;
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector((state) => state?.profile);
  const navigate = useNavigate();
  const icons = {
    personal: "/assets/images/personal.png",
    trial: "/assets/images/trial.png",
    agency: "/assets/images/agency.png"
  };
  const names = {
    Personal: "Silver",
    Agency: "Gold",
    Trial: "Trial"
  };

  let downgraded = false;
  let [action] = [...profile?.subscription?.actions]?.slice(-1);
  const conatinsDowngrade = action?.actionType?.includes("downgraded");
  let inPeriod = new Date() <= new Date(action?.updatedDetails?.endedAt);

  let split = action ? action?.actionType?.split(" ")?.slice(0) : [""];

  const [priceId] = split?.slice(-1);
  const downgradedTo = allPackages?.find((v) => v?.stripeId === priceId);

  if (action && conatinsDowngrade && inPeriod) downgraded = true;
  const confirmation = (type, cancelData) => {
    if (type === "upgrade") {
      swal({
        title: `Upgrade Plan to ${title}`,
        text: `Plan Duration: ${duration}\nAmount: $ ${price}\n\nNote: Upgrade charges will be applied immediately, and a new subscription plan will take effect instantly.`,
        icon: "info",
        buttons: ["Cancel", "Upgrade Now"]
      }).then(async function (isConfirm) {
        if (isConfirm) {
          setLoading(true);
          let response = await updatePlan({
            packageId,
            stripePlan: stripePlanId,
            upgrade: true
          });
          if (response) {
            setLoading(false);
            showInfo("Plan upgraded successfully.");
          } else setLoading(false);
        }
      });
    } else if (type === "downgrade") {
      swal({
        // \n(Payment due at the end of the active subscription period)
        // title: `Downgrade Plan to ${names[title]}`,
        title: `Downgrade Plan to ${title}`,
        text: `Plan Duration: ${duration}\nAmount: $ ${price}\n\nNote: This subscription is recurring downgrading a subscription will kick in the following subscription period.`,
        icon: "info",
        buttons: ["Cancel", "Downgrade Now"]
      }).then(async function (isConfirm) {
        if (isConfirm) {
          setLoading(true);
          let response = await updatePlan({
            packageId,
            stripePlan: stripePlanId,
            upgrade: false
          });
          if (response) {
            showInfo(
              "Plan downgraded successfully. \nPayment due at the end of the active subscription period."
            );
            setLoading(false);
          } else setLoading(false);
        }
      });
    } else {
      swal({
        title: `Cancel Subscription "${title}" Plan`,
        text: `Are you sure you want to cancel this subscription?`,
        icon: "info",
        dangerMode: true,
        buttons: ["Cancel", "Cancel Subscription"]
      }).then(async function (isConfirm) {
        if (isConfirm) {
          setLoading(true);
          let response = await cancelSubscription(cancelData);
          if (response.status === 200) {
            // console.log("Navigation");
            removeCookie("token");
            navigate("/");
            setLoading(false);
          } else setLoading(false);
        }
      });
    }
  };

  const onClick = async () => {
    if (Number(activeId?.cost) === 0) {
      navigate("/payInfo", {
        state: {
          planId: packageId,
          stripePlan: stripePlanId,
          plan
        }
      });
      // console.log("Trial is active");
    } else {
      if (Number(level) > Number(activeId?.level)) {
        // console.log("Upgrade");
        confirmation("upgrade");
      } else {
        confirmation("downgrade");
      }
    }
  };
  const cancelSub = async () => {
    confirmation("cancel-subscription", {
      _id: profile?.subscription?.id,
      stripeSubscriptionId: profile?.subscription?.stripeSubscriptionId
    });
  };
  const reNewSubscription = async () => {
    await forcePayment();
  };

  if (price == 0 && activeId?.cost != 0) return null;

  // if( plan?.general === false && plan?.users?.includes(profile?.user?.id) === false ) return null
  return (
    <Box
      className={
        activeId?.id == packageId
          ? cx(classes.wrapper, classes.wrapper_select)
          : classes.wrapper
      }
    >
      <Box className={classes.title}>
        <Group>
          <img
            src={icons[title?.toLowerCase()] || "/assets/images/personal.png"}
            alt=""
            width={40}
            height={40}
          />
          <Text
            className={
              activeId?.id == packageId
                ? classes.selectedtype
                : classes.plantype
            }
          >
            {/* {names[title]} */}
            {title}
          </Text>
        </Group>
        <Text className={classes.pricetag}>
          <span className={classes.unit}>$</span>{" "}
          <span
            className={
              activeId?.id == packageId ? classes.selectedPrice : classes.price
            }
          >
            {price}
          </span>
          <span className={classes.period}>/{duration}</span>
        </Text>
      </Box>
      <Box className={classes.descbox}>
        <Text className={classes.desc}>{desc}</Text>
      </Box>
      <Box className={classes.btnbox}>
        {activeId?.id == packageId ? (
          <Text>
            {price != 0 && <span className={classes.billing}>Billing Cycle: </span>}
            {price != 0 && <span className={classes.cycle}>{billing}</span>}
            <div className={classes.cycle} style={{ color: "#979797a0" }}>
              {downgraded
                ? `You have downgraded this Plan to ${
                    // names[downgradedTo?.name]
                    downgradedTo?.name
                  }. Changes will reflect by the end of this period.`
                : ""}
            </div>
          </Text>
        ) : (
          ""
        )}
        {activeId?.id != packageId && (
          <Button
            loading={loading}
            onClick={onClick}
            className={classes.btn}
            color="yellow"
            radius={8}
            disabled={downgraded && btn === "Select Plan"}
          >
            {btn}
          </Button>
        )}
      </Box>
      {activeId?.id == packageId && Number(activeId?.cost) !== 0 && (
        <Button
          loading={loading}
          onClick={cancelSub}
          className={classes.reNewbtn}
          color="red"
          radius={8}
        >
          Cancel Subscription
        </Button>
      )}
      {activeId?.id == packageId && isSubscriptionExpired() && price != 0 && (
        <Button
          loading={loading}
          onClick={reNewSubscription}
          className={classes.reNewbtn}
          color="yellow"
          radius={8}
        >
          Renew Subscription
        </Button>
      )}
    </Box>
  );
};

export default PlanCard;
