import { createStyles } from "@mantine/core";
const useStyles = createStyles((theme) => ({
  // myfont: {
  //   "& .mantine-1fzet7j": {
  //     fontFamily: "Inter !important",
  //     fontWeight: "400 !important",
  //     color: "#263238 !important",
  //   },
  // },
  healthFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: "6rem",
  },
  healthDropdowns: {
    margin: "1rem 0",
  },
  diagnosis: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
  },
  tags: {
    // width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "wrap",
    position: "relative",
  },
  diagnosisTags: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "1.5rem",
    flexWrap: "wrap",
    background: "#6C63FF1A",
    color: "#6C63FF",
  },
  allergiesTags: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "1.5rem",
    flexWrap: "wrap",
    background: "#1DA7061A",
    color: "#167207",
  },
  dietariesTag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "1.5rem",
    flexWrap: "wrap",
    background: "#FF5E031A",
    color: "#FF5E03",
  },
  close: {
    position: "absolute",
    top: "7px",
    right: "-12px",
    width: "30px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    // zIndex: "1000",
  },
  formField: {
    marginTop: 30,
  },
  imgUploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  imgUpload: {
    cursor: "pointer",
    width: "111px",
    height: "111px",
  },
  loaderBox: {
    width: "111px",
    height: "111px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    background: "#f1f3f5",
    padding: "0 5px",
  },
  heightWidthInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%",
  },
  textInputBox: {
    width: "100%",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "5px 15px",
    borderRadius: "15px",
    position: "relative",
    "& textarea[disabled]": {
      backgroundColor: "#fff !important",
    },
    "& .mantine-Textarea-input": {
      border: "none",
      paddingInline: "0",
    },
    "& .label": {
      position: "absolute",
      top: -10,
      left: 15,
      zIndex: 100,
      margin: 0,
      color: "#5C5C5C",
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  btns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%",
    margin: "auto",
    marginTop: 25,
    marginBottom: 10,
  },
  centerBTN: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  allergiesDietaryInput: {
    width: "270px",
  },
  labelCheck: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    marginTop: "10px",
  },

  drop: {
    display: "none",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },
  dropImage: {
    img: {
      borderRadius: "50%",
    },
  },

  camera: {
    position: "absolute",
    // position:'relative',
    // top:'28%',
    // right:'5%',
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
}));

export default useStyles;
