import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingCount: true,
  ordersCount: {
    lasyDayOrders: 0,
    monthOrders: 0,
    todayOrders: 0,
    weekOrders: 0
  },
  notes: []
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setOrdersCount(state, action) {
      return {
        ...state,
        loadingOrdersCount: false,
        ordersCount: action.payload
      };
    },
    setOrdersCountLoading(state, action) {
      return {
        ...state,
        loadingOrdersCount: action.payload
      };
    },
    setDashboardNotes(state, action) {
      return {
        ...state,
        loading: false,
        notes: action.payload
      };
    },
    // dashboard order counts
    setDashboardCounts(state, action) {
      return {
        ...state,
        loadingCount: false,
        dashboardCounts: action.payload
      };
    }
  }
});

export const {
  setOrdersCount,
  setOrdersCountLoading,
  setDashboardCounts,
  setDashboardCountLoading,
  setLoading,
  setDashboardNotes
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
