import {
  ActionIcon,
  Box,
  Group,
  Select,
  SimpleGrid,
  Text,
  TextInput
} from "@mantine/core";
import { IconPlus, Icon360 } from "@tabler/icons-react";
import React from "react";

function FieldForm({
  classes,
  view,
  onChanges,
  addFieldRef,
  fieldForm1,
  onSaveField,
  fieldRef,
  fieldTypeOptionsFieldForm,
  arrayOfFieldOptions,
  fieldmultiple,
  othersField,
  fieldError,
  setFieldError
}) {
  let {
    onChangeFieldsFieldForm,
    handleOtherInputfieldChange,
    handlePriorityFieldChange,
    handleChange,
    handleOptionfieldChange
  } = onChanges;
  return (
    <form onSubmit={fieldForm1?.onSubmit((values) => onSaveField(values))}>
      <Group>
        {!view && (
          <>
            <Box sx={{ width: "90%" }} className={classes.bordered}>
              <Text>Existing Field</Text>
              <SimpleGrid cols={3}>
                <Select
                  searchable
                  ref={fieldRef}
                  size="md"
                  data={arrayOfFieldOptions}
                  icon={<img src="/assets/images/home2.png" alt="address" />}
                  placeholder="Field Name"
                  {...fieldForm1.getInputProps("field")}
                  onChange={onChangeFieldsFieldForm}
                  disabled={view}
                />
                <Select
                  size="md"
                  data={fieldTypeOptionsFieldForm}
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="Field Type"
                  className={classes.myfont}
                  {...fieldForm1.getInputProps("fieldType")}
                  onChange={handleChange}
                  disabled={view}
                />
                <Select
                  size="md"
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="Field Priority"
                  data={["Yes", "No"]}
                  className={classes.myfont}
                  {...fieldForm1.getInputProps("priority")}
                  onChange={handlePriorityFieldChange}
                  disabled={view}
                />
                <Select
                  size="md"
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="View Only"
                  data={["Yes", "No"]}
                  className={classes.myfont}
                  {...fieldForm1.getInputProps("viewOnly")}
                  disabled={view}
                />
                {fieldmultiple ? (
                  <Select
                    label={"Options"}
                    size="md"
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="Field Priority"
                    data={["Facilities", "Clients", "Staff", "Other"]}
                    className={classes.myfont}
                    {...fieldForm1.getInputProps("options")}
                    onChange={handleOptionfieldChange}
                    disabled={view}
                  />
                ) : null}
                {othersField ? (
                  <TextInput
                    size="md"
                    label="Other Options"
                    icon={<img src="/assets/images/building.png" alt="home" />}
                    placeholder="Eg. Option1,Option2,Option3"
                    className={classes.myfont}
                    {...fieldForm1.getInputProps("others")}
                    onChange={handleOtherInputfieldChange}
                    disabled={view}
                  />
                ) : null}
              </SimpleGrid>
              <SimpleGrid
                cols={3}
                className={classes.mwd}
                sx={{
                  paddingTop: 10
                }}
              ></SimpleGrid>
            </Box>
            <Box>
              <ActionIcon
                color="yellow"
                variant="light"
                mt={5}
                size={44}
                ref={addFieldRef}
                type="submit"
              >
                <IconPlus />
              </ActionIcon>
              <ActionIcon
                color="blue"
                variant="light"
                mt={5}
                size={44}
                onClick={() => {
                  setFieldError("");
                  fieldForm1.setValues({
                    field: "",
                    priority: "",
                    fieldType: ""
                  });
                }}
              >
                <Icon360 />
              </ActionIcon>
            </Box>
          </>
        )}
        {fieldError != "" ? (
          <p style={{ color: "red", margin: 0, padding: 0 }}>{fieldError}</p>
        ) : (
          ""
        )}
      </Group>
    </form>
  );
}

export default FieldForm;
