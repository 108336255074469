import { https } from "../http";
import store from "../store";

import {
  setReports,
  setLoading,
  setLoadingAddReport,
} from "../store/reducers/report";
import { showError } from "../utils/index";

// GET
const getMARReport = async (reportId, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/report/mar-report/${reportId}`);

    if (response?.status === 200) {
      return response?.data?.report
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// GET
const getReports = async (page, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/report/${page}`);

    if (response?.status === 200) {
      store.dispatch(setReports(response?.data));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// POST
const createReport = async (data, callback) => {
  try {
    store.dispatch(setLoadingAddReport(true));
    let response = await https().post("/report", data);

    if (response?.status === 200) {
      getReports(false);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddReport(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const sendReportEmail = async (data) => {
  try {
    store.dispatch(setLoadingAddReport(true));
    let response = await https().post("/report/send-email", data);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    showError(error);
    return false;
  }
};

// PUT
const editReport = async (data, showLoading = true, callback = null) => {
  try {
    store.dispatch(setLoadingAddReport(true));
    let response = await https().put(`/report`, data);

    if (response?.status === 200) {
      getReports(showLoading);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddReport(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// DELETE
const removeReport = async (data, callback) => {
  try {
    store.dispatch(setLoadingAddReport(true));

    let response = await https().delete(`/report/${data?.id}`);

    if (response?.status === 200) {
      getReports(false);
      callback?.();
    }
  } catch (error) {
    store.dispatch(setLoadingAddReport(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { createReport, editReport, removeReport, getReports, sendReportEmail, getMARReport };
