import { Container, createStyles, getStylesRef } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../components/AddButton";
import { IconSettingsPlus } from "@tabler/icons-react";
import TaskTable from "../../../components/TaskTable";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import Topbar from "../../../components/Topbar";
import AddTaskModal from "../../../components/TaskTable/AddTaskModal";
import { getTasks } from "../../../services/task";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 10,
    // marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  btnIcon: {
    ref: getStylesRef("btnIcon"),
    background: "#FFBA45",
    borderRadius: 5,
    cursor: "pointer",
  },
}));
const AllTasks = () => {
  const { tasksCount } = useSelector((state) => state?.task);
  const [currentPage, setCurrentPage] = useState(1);
  const { profile } = useSelector((state) => state.profile);
  // let userType = profile?.user?.type;

  useEffect(() => {
    getTasks(currentPage, "all");
  }, [currentPage]);

  useEffect(() => {
    TrackPageView("Task")
  }, []);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber);
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Container fluid className={classes.content} px={20}>
      <AddTaskModal
        opened={opened}
        onclose={(nType) => {
          close();
        }}
        title={"Add a New Task"}
      />
      <Topbar title={"All Tasks"} />
      <div className={classes.btn}>
        <AddButton title={"Add New Task"} onClick={open} />

        {(profile?.user?.type !== "staff" ||
          (profile?.user?.type === "staff" &&
            profile?.user?.role === "admin")) && (
          <IconSettingsPlus
            color="black"
            stroke={1}
            className={classes.btnIcon}
            size={40}
            onClick={() => navigate("/home/taskType")}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <TaskTable />
        <CustomPagination
          data={tasksCount}
          itemsPerPage={10}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default AllTasks;
