import { Button, Grid, Image, PasswordInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../services/auth";
import useStyles from "./style";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";
import { useEffect } from "react";
const ResetPassword = () => {
  const location = useLocation();
  const { resetPasswordLoading } = useSelector((state) => state.auth);

  const params = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validate: {
      password: (value) => {
        if (!value) {
          return "Required";
        }
        // Regular expression to check if the password contains at least one letter and one number
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
      
        // Check if the password meets the length requirement
        const isLengthValid = value.length >= 8;
      
        return passwordRegex.test(value) && isLengthValid
          ? null
          : "Password must contain at least one letter, one number, and be at least 8 characters long";
      },
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null
    }
  });

  const reset = (values) => {
    resetPassword(
      {
        ...values,
        token: params?.token,
        setActive: location?.state?.title === "Set Your Password" ? true : false
      },
      navigate
    );
  };

  useEffect(() => {
    pageInfoAnalytics("Reset Password Page Viewed", "Reset Password Page");
}, []);
  return (
    <Grid className={classes.container}>
      <Grid.Col span={6} className={classes.left_pane}>
        <div className={classes.welcome}>
          <Link to={"/"}>
            <img
              className={classes.logo_img}
              src="/assets/images/logo.png"
              alt=""
            />
          </Link>
          <Text
            fs={"italic"}
            fw={700}
            sx={{ fontFamily: "Inter", marginTop: "1rem" }}
            fz={38}
          >
            Welcome Back
          </Text>
          <Text c="white">
          </Text>
        </div>

        <div>
          <img
            className={classes.glass_man}
            src="/assets/images/glass_man.png"
            alt=""
          />
        </div>
        <img
          className={classes.credential}
          src="/assets/images/credential.png"
          alt=""
        />
      </Grid.Col>
      <Grid.Col span={6} className={classes.right_pane}>
        <div className={classes.myform}>
          <Image
            src="/assets/images/logo2.png"
            mr={"auto"}
            fit="fill"
            width={"30%"}
            className={classes.logo_img2}
          />

          <Text className={classes.title}>
            {location?.state?.title || "Reset Password"}
          </Text>
          <form onSubmit={form.onSubmit((values) => reset(values))}>
            <Text className={classes.verifiyText}>
              Please enter the new Password
            </Text>
            <PasswordInput
              mt={"lg"}
              label="Password"
              placeholder="Eg.A*****"
              {...form.getInputProps("password")}
              size={"lg"}
            />
            <PasswordInput
              mt={"lg"}
              label="Re-enter Password"
              placeholder="Eg.A*****"
              {...form.getInputProps("confirmPassword")}
              size={"lg"}
            />
            <Button
              loading={resetPasswordLoading}
              color="yellow"
              className={classes.login_btn}
              type="submit"
            >
              Reset
            </Button>
          </form>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default ResetPassword;
