import { Box, Button, Grid, Image, Text, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "./style";
import { useForm } from "@mantine/form";
import { forgetPassword } from "../../services/auth";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";
const ForgetPassword = () => {
  const { forgotLoading } = useSelector((state) => state.auth);
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const isMobileScreen = useMediaQuery("(max-width: 640px)");

  const forgot = (values) => {
    forgetPassword(values);
  };

  useEffect(() => {
    pageInfoAnalytics("Forget Password Page Viewed", "Forget Password Page");
}, []);
  return (
    <Grid className={classes.container}>
      <Grid.Col span={5} className={classes.left_pane}>
        <Box className={classes.welcome}>
          <Link to={"/"}>
            <Image
              src="/assets/images/logo2.png"
              mr={"auto"}
              fit="fill"
              className={classes.logo}
            />
          </Link>
          <Box className={classes.leftSideTitle}>
            <p className={classes.titleText}>
              Simplifying <br /> Healthcare <br /> Documentation
            </p>
          </Box>
          <Text c="#000" ff={"Inter"} fz={20} fw={400}>
            Join us today to streamline your documentation process and enhance
            patient care.
          </Text>
        </Box>

        <img
          className={classes.authImg}
          src="assets/images/loginImg.png"
          alt=""
        />
      </Grid.Col>
      <Grid.Col span={7} className={classes.right_pane}>
        <div className={classes.myform}>
        {isMobileScreen && (
          <Image
            src="/assets/images/logo2.png"
            mr={"auto"}
            fit="fill"
            width={"30%"}
            className={classes.logo_img2}
          />)}
          <Text c={"#ccc"}>
            Don't have an account?{" "}
            <Link className={classes.signup} to={"/signup"}>
              Sign up
            </Link>
          </Text>
          <Text className={classes.title}>Forget Password</Text>
          <form onSubmit={form.onSubmit((values) => forgot(values))}>
            <TextInput
              mt="xl"
              label="Email"
              // width={100}
              placeholder="EG.abc@gmail.com"
              size={"lg"}
              {...form.getInputProps("email")}
            />
            <Button
              loading={forgotLoading}
              color="#000"
              type="submit"
              className={classes.login_btn}
            >
              Submit
            </Button>
          </form>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default ForgetPassword;
