import React from "react";

import { ActionIcon, Menu, Table, Text, Title } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import useStyles from "./stylesComponents";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";
import CreateFolderModal from "./CreateFolderModal";
import { IconEdit } from "@tabler/icons-react";
import { deleteFolder } from "../../services/Document";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import RecordNotFound from "../RecordNotFound";
import DeleteFolderModal from "./DeleteFolderModal";

function FolderItem({ data, setSelectedFolder, setType,client }) {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [Deleteopened, { open : deleteOpen, close : deleteClose }] = useDisclosure(false);

  return (
    <div key={data?._id} className={classes.folderItem}>
      <div className={classes.column}>
        <div className={classes.verticalIconContainer}>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <IconDotsVertical size={20} style={{ cursor: "pointer" }} />
            </Menu.Target>

            <Menu.Dropdown>
              {/* <Menu.Label>Application</Menu.Label> */}
              <Menu.Item
                onClick={() => {
                  setType("file");
                  setSelectedFolder(data);
                }}
                icon={
                  <img
                    src="/assets/images/open.png"
                    alt="open"
                    className={classes.folderMenuIcon}
                  />
                }
              >
                Open
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  const zip = new JSZip();
                  let func = (url) => {
                    return new Promise(function (resolve, reject) {
                      JSZipUtils.getBinaryContent(url, function (err, data) {
                        if (err) {
                          reject(err);
                        } else {
                          resolve(data);
                        }
                      });
                    });
                  };
                  data?.files?.map((item) => {
                    zip.file(
                      `${item?.name}.${
                        item?.fileUrl?.split(".")[
                          item?.fileUrl?.split(".")?.length - 1
                        ]
                      }`,
                      func(item?.fileUrl),
                      { binary: true }
                    );
                  });
                  zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, data?.name);
                  });
                }}
                icon={
                  <img
                    src="/assets/images/downloadFolder.png"
                    alt="download"
                    className={classes.folderMenuIcon}
                  />
                }
              >
                Download
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  open();
                }}
                icon={
                  <img
                    src="/assets/images/editFolder.png"
                    alt="rename"
                    className={classes.folderMenuIcon}
                  />
                }
              >
                Rename
              </Menu.Item>
              <Menu.Item
                icon={
                  <img
                    src="/assets/images/deleteFolder.png"
                    alt="delete"
                    className={classes.folderMenuIcon}
                    style={{ width: 25, margin: 0 }}
                  />
                }
                style={{ color: "red" }}
                onClick={async () => {
                  deleteOpen()
                  // await deleteFolder(data?._id);
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>

        <img
          src="/assets/images/folderBig.png"
          alt="folder-icon"
          className={classes.folderBig}
          onClick={() => {
            setType("file");
            setSelectedFolder(data);
          }}
        />

        <Title onClick={() => {
                  setType("file");
                  setSelectedFolder(data);
                }} order={5}>{data?.name}</Title>
        <Text onClick={() => {
                  setType("file");
                  setSelectedFolder(data);
                }} className={classes.folderDateText} fz="xs">
          {moment(data?.createdAt).format("MMMM DD, YYYY")}
        </Text>
      </div>
      <CreateFolderModal
        opened={opened}
        onclose={() => {
          close();
        }}
        title={"Rename Folder"}
        data={data}
        client={client}
      />
       <DeleteFolderModal
                  opened={Deleteopened}
                  onclose={() => {
                    deleteClose();
                  }}
                  title={"Delete Folder"}
                  data={data}
                  client={client}
                />
    </div>
  );
}

const FolderTable = ({ folders, setType, setSelectedFolder,client }) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [Deleteopened, { open : deleteOpen, close : deleteClose }] = useDisclosure(false);

  const ths = (
    <tr>
      <th style={{ minWidth: "500px" }}>Name</th>
      <th style={{ minWidth: "250px" }}>Upload Date</th>
      {/* <th style={{ minWidth: "250px" }}>Location</th> */}
      <th style={{ minWidth: "250px" }}>Actions</th>
    </tr>
  );
  return (
    <>
     {folders?.length > 0 ? <Table striped fontSize={"md"}>
        <thead>{ths}</thead>
        <tbody>
          {folders?.map((val, index) => (
              <>
                <tr key={val?._id}>
                  <td>
                    <div className={classes.row}>
                      <img
                        src="/assets/images/rowFolder.png"
                        alt="folder"
                        className={classes.fileImageRow}
                      />{" "}
                      {/* <img
                    src="/assets/images/folderBig.png"
                    alt="icon"
                    className={classes.folderBig}
                  /> */}
                      <p>{val?.name}</p>
                      <ActionIcon
                        // disabled={userType === "staff"}
                        color="yellow"
                        variant="light"
                        onClick={(e) => {
                          open(e);
                        }}
                      >
                        <IconEdit />
                      </ActionIcon>
                    </div>
                  </td>
                  <td>{`${moment(val?.createdAt).format("MM/DD/YYYY")}`}</td>
                  {/* <td>{val?.location}</td> */}
                  <td>
                    <div className={classes.row} style={{ gap: 5 }}>
                      <img
                        onClick={() => {
                          setType("file");
                          setSelectedFolder(val);
                        }}
                        src="/assets/images/eye.png"
                        alt="view"
                        style={{ width: 30, cursor: "pointer" }}
                      />
                      <img
                        onClick={() => {
                          const zip = new JSZip();
                          let func = (url) => {
                            return new Promise(function (resolve, reject) {
                              JSZipUtils.getBinaryContent(
                                url,
                                function (err, data) {
                                  if (err) {
                                    reject(err);
                                  } else {
                                    resolve(data);
                                  }
                                }
                              );
                            });
                          };
                          val?.files?.map((item) => {
                            zip.file(
                              `${item?.name}.${
                                item?.fileUrl?.split(".")[
                                  item?.fileUrl?.split(".")?.length - 1
                                ]
                              }`,
                              func(item?.fileUrl),
                              {
                                binary: true,
                              }
                            );
                          });
                          zip
                            .generateAsync({ type: "blob" })
                            .then(function (content) {
                              saveAs(content, val?.name);
                            });
                        }}
                        src="/assets/images/downloadFile.png"
                        alt="download"
                        className={classes.actionsImageRow}
                      />
                      <img
                        onClick={async () => {
                          deleteOpen()
                          // await deleteFolder(val?._id);
                        }}
                        src="/assets/images/deleteFolder.png"
                        alt="delete"
                        style={{ width: 30, cursor: "pointer" }}
                      />
                    </div>
                  </td>
                </tr>
                <CreateFolderModal
                  opened={opened}
                  onclose={() => {
                    close();
                  }}
                  title={"Rename Folder"}
                  data={val}
                  client={client}
                />
                  <DeleteFolderModal
                  opened={Deleteopened}
                  onclose={() => {
                    deleteClose();
                  }}
                  title={"Delete Folder"}
                  data={val}
                  client={client}
                />
              </>
            ))}
        </tbody>
      </Table> :   <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  height: "60vh",
                  gap: "8rem",
                }}
              >
                <RecordNotFound />
                <p className={classes.notFoundText}>
                  You don&apos;t have any files uploaded to this folder, click
                  on “upload documents” to add.
                </p>
              </div>}
    </>
  );
};

export { FolderItem, FolderTable };
