import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addPatientLoading: false,
  patients: [],
  allPatients: []
};

export const patientState = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action?.payload
      };
    },
    setAddPatientLoading(state, action) {
      return {
        ...state,
        addPatientLoading: action?.payload
      };
    },
    setPatients(state, action) {
      return {
        ...state,
        loading: false,
        patients: action?.payload
      };
    },
    setAllPatients(state, action) {
      return {
        ...state,
        loading: false,
        allPatients: action?.payload
      };
    },

    // Reset State
    reset() {
      return initialState;
    }
  }
});

export const { setLoading, setAddPatientLoading, setPatients, setAllPatients } =
  patientState.actions;
export default patientState.reducer;
