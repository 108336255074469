import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useStyles from "./style";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActionIcon, Box, Text, Container, Loader } from "@mantine/core";
import { getTickets } from "../../services";
import Topbar from "../../components/Topbar";

const SingleTicket = () => {
  const { loading } = useSelector((state) => state?.ticket);
  const { classes } = useStyles();
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);

  const getSingleTicket = async () => {
    const res = await getTickets("", true, id);
    if (res) {
      setTicket(res?.ticket);
    }
  };

  useEffect(() => {
    getSingleTicket(id);
  }, []);

  const onFinish = async (values) => {
    // try {
    //     await contactUs(values);
    //     // Display success notification
    //     swal({
    //         title: `Message sent`,
    //         text: "Message sent successfully",
    //         icon: "success",
    //         dangerMode: false
    //     });
    //     form.reset();
    // } catch (error) {
    //     console.error("Error submitting form:", error);
    //     // Display error notification
    //     swal({
    //         title: `Message sent`,
    //         text: "Message sent successfully",
    //         dangerMode: true
    //     });
    // }
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  //   if (!loading) return <Loader color="yellow" size={"md"} />;

  return (
    <Container fluid className={classes.content} style={{ width: "100%" }}>
      <Topbar title={"Ticket"} />

      {loading ? (
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 0 0 0",
          }}
        >
          <Loader color="yellow" size={"md"} />
        </Box>
      ) : (
        // <Box maw={"70%"} mx="auto" my={"lg"}>
        <Box style={{ padding: "100px 0 0 0" }}>
          <div className={classes.Context}>
            <p>
              <span style={{ fontSize: "30px", fontWeight: 600 }}>
                Ticket #:{" "}
              </span>
              {ticket?.ticketNumber}
            </p>
            <p>
              <span style={{ fontSize: "18px", fontWeight: 600 }}>
                Issue Type:{" "}
              </span>
              {ticket?.issueType}
            </p>
            <p>
              <span style={{ fontSize: "18px", fontWeight: 600 }}>
                Ticket Date:{" "}
              </span>
              {moment(ticket?.createdAt).format("DD-MM-YYYY")}
            </p>
          </div>

          <Box className={classes.messageWrapper}>
            <Text fz={30} fw={500}>
              Message
            </Text>
            <Box className={classes.messageContainer}>
              <Box maw={"100%"} mx="auto" my={"lg"} className={classes.issue}>
                <div className={classes.chat}>
                  <p>{ticket?.message}</p>
                </div>
              </Box>
              <Box
                maw={"100%"}
                mx="auto"
                my={"lg"}
                className={classes.response}
              >
                <div className={classes.chat}>
                  <Text fz={14} fw={500}>
                    Response
                  </Text>
                  <p></p>
                  {/* <p>Admin</p> */}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default SingleTicket;
