import {
  Container,
  Group,
  Loader,
  SimpleGrid,
  Text,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PaymentCard from "../../../../components/PaymentMethodCard";
import { getConnectedAccounts } from "../../../../services/payment";
const useStyles = createStyles((theme) => ({
  "& .mantine-usvkjp": {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  wrapperContainer: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "@media (max-width :80em)": {
      margin: "1rem 0!important",
    },
  },
  inputContainer: {
    "@media (max-width :80em)": {
      display: "flex !important",
      flexDirection: "column !important",
      paddingBottom: "4rem !important",
    },
  },
  noAccountText: {
    color: "#979797",
    padding: "10px",
  },
}));
const ChangeMethod = () => {
  const [connected, setConnected] = useState([]);

  const { classes } = useStyles();
  const { profile } = useSelector((state) => state.profile);
  const [loadingAccounts, setLoadingAccounts] = useState(false);

  useEffect(() => {
    getAllConnected();
  }, []);

  useEffect(() => {
    // console.log("subscription", profile);
  }, [profile]);

  const getAllConnected = async () => {
    setLoadingAccounts(true);
    let accounts = await getConnectedAccounts();
    setConnected(accounts);
    setLoadingAccounts(false);
  };
  return (
    <Container
      fluid
      mt={50}
      sx={{ width: "100%" }}
      className={classes.wrapperContainer}
    >
      {connected?.length === 0 && !loadingAccounts && (
        <Text className={classes.noAccountText}>No Accounts Found</Text>
      )}
      {loadingAccounts ? (
        <Group cols={2}>
          <Loader color="yellow" size={"md"} />
          <Text>Fetching details...</Text>
        </Group>
      ) : (
        <SimpleGrid cols={2} spacing={30} className={classes.inputContainer}>
          {connected?.map((v, i) => {
            return (
              <PaymentCard
                data={v}
                key={i}
                connected={connected}
                callback={getAllConnected}
              />
            );
          })}
          <PaymentCard data={{}} newAccount={true} callback={getAllConnected} />

          {/* {connected?.length === 1 && (
            <PaymentCard
              data={{}}
              connected={connected}
              newAccount={true}
              callback={getAllConnected}
            />
          )}
          {connected?.length === 0 && (
            <PaymentCard
              data={{}}
              newAccount={true}
              callback={getAllConnected}
            />
          )} */}
        </SimpleGrid>
      )}
    </Container>
  );
};

export default ChangeMethod;
