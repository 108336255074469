import { Text, createStyles } from "@mantine/core";
import moment from "moment";

const useStyles = createStyles(() => ({
  noteCard: {
    margin: "10px 0",
    alignItems: "center",
    border: "1px solid #dee2e6",
    borderRadius: "15px",
    padding: "10px 20px",
  },
}));

const PreviousNotesComp = ({ notes }) => {
  const { classes } = useStyles();
  return (
    <div>
      {notes
        ?.sort(function (a, b) {
          return new Date(b?.date) - new Date(a?.date);
        })
        ?.map((note) => (
          <div className={classes.noteCard}>
            <Text c="dimmed">
              {note?.staff?.name} (
              {note?.date
                ? moment(note?.date).format("MM-DD-YYYY, hh:mm a")
                : ""}
              )
            </Text>
            <Text size="lg">{note.note}</Text>
          </div>
        ))}
    </div>
  );
};

export default PreviousNotesComp;
