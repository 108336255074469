import {
  ActionIcon,
  Avatar,
  Box,
  Container,
  Loader,
  Tabs,
  Text,
  Button,
  Grid,
  Col,
  SimpleGrid,
  PasswordInput,
  Timeline,
  Textarea,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ClientSupportPlan from "../../../components/MAR/ClientSupportPlans";
import MedicationTable from "../../../components/MedicationTable";
import { getSinglePatients } from "../../../services/patient";
import { setMar, setSelectedClient } from "../../../store/reducers/mar";
import { decrypt } from "../../../utils";
import useStyles from "./style";
import { FaEnvelope } from "react-icons/fa";
import { IconEdit, IconEye, IconEyeClosed } from "@tabler/icons-react";
import EditGaurdianinfo from "../../../components/Edit_client_profile/EditGaurdianinfo";
import EditPersonalInfo from "../../../components/Edit_client_profile/EditPersonalInfo";
import Editinsurance from "../../../components/Edit_client_profile/Editinsurance";
import PersonalHealthEdit from "../../../components/Edit_client_profile/PersonalHealthEdit";
import ClientViewMedication from "../../../components/MedicationTable/ClientViewMedication";
import ClientNoteview from "../Note/ClientNoteview";
import Document from "../../../components/Document";
import Medication from "../Medication";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useMediaQuery } from "@mantine/hooks";
import homeLocation from "../../../images/homeLocation.png";
import lightBulb from "../../../images/lightBulb.png";
import insurance from "../../../images/insurance.png";
import contact from "../../../images/contact.png";
import expand from "../../../images/expand.png";
import heart from "../../../images/heart.png";
import phone from "../../../images/phone.png";
import printer from "../../../images/printer.png";
import AddNewMedicationModal from "../../../components/AddNewMedicationModal";
import ClientInsuranceModal from "../../../components/ClientInsuranceModal";
import CareSupportModal from "../../../components/CareSupportModal";
import MedicationListTab from "../Medication/MedicationListTab";
import {
  IconGitBranch,
  IconGitPullRequest,
  IconGitCommit,
  IconMessageDots,
} from "@tabler/icons-react";

import "./index.css";
import InsuranceCard from "../../../components/InsuranceCard/InsuranceCard";
import MarTab from "../Medication/MarTab";
import MarLogTab from "../Medication/MarLogTab";
import MedicationLogTab from "../Medication/MedicationLogTab";
import AddMedicationButton from "../../../components/AddMedicationButton/AddMedicationButton";
import FilterBar from "../Medication/FilterBar";
import { getAllPlans } from "../../../services/supportplan";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ClientSummaryPDF from "../../../components/ClientSummaryPDF/ClientSummaryPDF";
import { getActivites } from "../../../services/activity";
import ActivitySide from "./ActivitySide";
import { TrackPageView } from "../../../config/mixpanelConfig";

const Client = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState(null);

  const [showSSN, setShowSSN] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedModal, { open: openDetails, close: closeDetails }] =
    useDisclosure(false);
  const [insuranceModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [careSupportModal, { open: openCareModal, close: closeCareModal }] =
    useDisclosure(false);
  const [
    personalHealthOpened,
    { open: openPersonalHealth, close: closePersonalHealth },
  ] = useDisclosure(false);
  const [
    guardianInfoOpened,
    { open: openGuardianInfo, close: closeGuardianInfo },
  ] = useDisclosure(false);
  const [insuranceOpened, { open: openInsurance, close: closeInsurance }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("profile");
  const { selectedClient } = useSelector((state) => state.mar);
  console.log("🚀 ~ Client ~ selectedClient:", selectedClient)
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [medicine, setMedicine] = useState(null);
  console.log("🚀 ~ Client ~ medicine:", medicine)
  const [isPreview, setIsPreview] = useState(false);
  const [view, setView] = useState("calendar");
  const [activities, setActivities] = useState(null);
  const [activityLoading, setActivityLoading] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const id = location.state;

  const editFormRef = useRef();

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  // const noGuardianRef = useRef();

  const form1 = clientDetails?.form1;
  const form2 = clientDetails?.form2;

  // console.log("clientData =========>", clientDetails);

  const knownIllnessVal = clientDetails?.form1?.knownIllness;

  useEffect(() => {
    if (guardianInfoOpened && form2) {
      if (form2?.noCaseManager && editFormRef?.current) {
        editFormRef.current.checked = true;
      }
      // if (form2?.noGuardian && noGuardianRef?.current) {
      //   noGuardianRef.current.checked = true;
      // }
    }
  }, [guardianInfoOpened, form2]);

  useEffect(() => {
    _getActivites();
    TrackPageView("Client");
  }, []);

  useEffect(() => {
    console.log("activities", activities);
  }, [activities]);

  const _getActivites = async () => {
    setActivityLoading(true);
    const data = await getActivites(id);
    setActivities(data);
    setActivityLoading(false);
  };

  const getData = async () => {
    const data = await getSinglePatients(state);
    console.log("🚀 ~ getData ~ data:", data)
    setClientDetails(data);
    setLoading(false);
  };

  const handleTabChange = (val) => {
    setShouldRefresh(true);
    setActiveTab(val);
    // setTimeout(() => setActiveTab(val), 200);
    localStorage.setItem("tab", val);
  };

  useEffect(() => {
    // setLoading(true);
    getData();
    getAllPlans();
    // dispatch(setMar([]));
  }, [state]);

  const { classes } = useStyles();

  const customId = clientDetails?.id;
  const clientId = customId?.slice(-6);

  const filteredMedications = clientDetails?.form3
    ? clientDetails?.form3?.filter(
        (medicine) =>
          medicine?.discontinued === false || !("discontinued" in medicine)
      )
    : "";

  const tabChangeHandler = (tabValue) => {
    console.log("On change clicked");
    console.log("tabValue", tabValue);
  };
  return (
    <Container fluid className={classes.content} px={20}>
      <AddNewMedicationModal
        opened={opened}
        onclose={(nType) => {
          close();
          setMedicine(null);
          if (isPreview) setIsPreview(false);
        }}
        data={medicine}
        title={"Chloroquine"}
        userId={clientDetails?.id}
        isPreview={isPreview}
        getClientData={getData}
      />
      <EditPersonalInfo
        opened={openedModal}
        form1={form1}
        clientDetails={clientDetails}
        setClientDetails={setClientDetails}
        onclose={(nType) => {
          closeDetails();
        }}
        title={"Edit  Client Information"}
      />
      <PersonalHealthEdit
        opened={personalHealthOpened}
        form1={form1}
        onclose={closePersonalHealth}
        setClientDetails={setClientDetails}
        title={"Edit Health Information"}
      />

      <EditGaurdianinfo
        opened={guardianInfoOpened}
        form2={clientDetails?.form2}
        setClientDetails={setClientDetails}
        // ref={editFormRef}
        onclose={closeGuardianInfo}
        title={"Edit Guardian Information"}
      />

      <Editinsurance
        opened={insuranceOpened}
        setClientDetails={setClientDetails}
        form2={form2} // Assuming you need to pass some form data
        onclose={closeInsurance}
        title={"Edit Insurance Information"}
      />

      <ClientInsuranceModal
        opened={insuranceModal}
        onClose={closeModal}
        data={clientDetails}
      />

      <CareSupportModal
        opened={careSupportModal}
        onClose={closeCareModal}
        clientDetails={clientDetails}
      />

      {loading ? (
        <Loader
          color="yellow"
          style={{
            marginTop: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            left: "40%",
          }}
        />
      ) : (
        <>
          <Box
            className={classes.firsttext}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeftLong size={20} />
            All Clients
          </Box>
          <Box className={classes.top_header}>
            <Text className="client-text" size="xl" weight={600} bold>
              Client
            </Text>

            {/* <Box className={classes.clientInfo}>
              <Box>
                <Text weight={600} size="lg" fz={26} bold>
                  {clientDetails?.name}
                </Text>
                <Text size="sm"> {clientId}</Text>
              </Box>
              <img
                className={classes.clientlogo}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={
                  clientDetails?.imageurl
                    ? decrypt(clientDetails?.imageurl)
                    : "/assets/images/personal2.png"
                }
                alt="logo"
              />
            </Box> */}
          </Box>
          {activeTab !== "profile" && (
            <Grid className="top-client-grid" align="Center">
              <Col span={12} sm={2} md={1.3} lg={0.75} xl={0.8}>
                <img
                  src={
                    clientDetails?.imageurl
                      ? decrypt(clientDetails?.imageurl)
                      : "/assets/images/personal2.png"
                  }
                  alt="client_image"
                  className="top-image"
                />
              </Col>
              <Col span={12} sm={9} md={10} lg={10} xl={10}>
                <h2 className="top-client-name">{clientDetails?.name}</h2>
                <p className="top-client-id">
                  {clientDetails?.id?.slice(0, 6)}
                </p>
              </Col>
            </Grid>
          )}

          <div style={{ marginTop: "45px" }}>
            <Tabs defaultValue="profile">
              <Tabs.List style={{ gap: "20px" }}>
                <Tabs.Tab
                  value="profile"
                  onClick={() => {
                    _getActivites();
                    handleTabChange("profile")
                  }}
                >
                  Facesheet
                </Tabs.Tab>
                <Tabs.Tab
                  value="support"
                  id="care"
                  onClick={() => handleTabChange("support")}
                >
                  Care Plan
                </Tabs.Tab>
                {/* <Tabs.Tab value="medication" onClick={() => setISMAR("")}>
                  Medication
                </Tabs.Tab> */}
                <Tabs.Tab
                  value="medication-list"
                  onClick={() => handleTabChange("medicationList")}
                >
                  Medication List
                </Tabs.Tab>
                <Tabs.Tab
                  value="medication-log"
                  onClick={() => handleTabChange("medicationLog")}
                  className={
                    activeTab === "medicationLog" ? classes.active : ""
                  }
                >
                  Medication Log
                </Tabs.Tab>

                <Tabs.Tab value="mar" onClick={() => handleTabChange("mar")}>
                  MAR
                </Tabs.Tab>
                <Tabs.Tab
                  value="mar-log"
                  id="mar-log"
                  onClick={() => handleTabChange("marLog")}
                  className={activeTab === "marLog" ? classes.active : ""}
                >
                  MAR Log
                </Tabs.Tab>

                <Tabs.Tab value="notes" onClick={() => handleTabChange("notes")}>
                  Notes
                </Tabs.Tab>
                <Tabs.Tab value="documents" onClick={() => handleTabChange("documents")}>
                  Documents
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel
                value="profile"
                style={{
                  backgroundColor: "#F2F2F2",
                  height: "auto",
                  padding: "15px 30px",
                }}
                className="profile-tab"
              >
                {/* download client summary and client chart buttons */}
                <Box className={classes.buttonParent}>
                  <PDFDownloadLink
                    document={
                      <ClientSummaryPDF
                        clientData={clientDetails}
                        activities={activities}
                      />
                    }
                    fileName={`${
                      clientDetails?.form1?.name ?? "Client Summary"
                    }.pdf`}
                  >
                    <Button className="download-client">
                      Download Client Summary
                    </Button>
                  </PDFDownloadLink>
                </Box>
                {/* patient details */}
                <div className="patient-details-parent">
                  <Grid>
                    <Col sm={12} lg={2} xl={1.4}>
                      <img
                        src={
                          clientDetails?.imageurl
                            ? decrypt(clientDetails?.imageurl)
                            : "/assets/images/personal2.png"
                        }
                        alt="client_image"
                        className={
                          clientDetails?.imageurl
                            ? "client-image"
                            : "avatar-image"
                        }
                      />
                    </Col>
                    <Col span={12} sm={8} lg={6} xl={6.6}>
                      <div>
                        <h5 className="client-name">{clientDetails?.name}</h5>
                        <p className="client-id figma-grey-color">
                          {clientDetails?.id?.slice(18, 24)}
                        </p>
                      </div>
                      <div>
                        <Grid justify="Flex-start">
                          <Col span={12} sm={6} lg={1}>
                            <p className="gender font-size">
                              {clientDetails?.form1?.gender}
                            </p>
                          </Col>
                          <Col span={12} sm={6} lg={3}>
                            <p className="client-data font-size">
                              {moment(clientDetails?.form1?.dob).format(
                                "MM/DD/YYYY"
                              )}

                              <span className="relative-date">
                                (
                                {moment(clientDetails?.form1?.dob)
                                  ?.fromNow()
                                  ?.replace("ago", "")
                                  ?.trim()}
                                )
                              </span>
                            </p>
                          </Col>
                          <Col span={12} sm={6} lg={1.55}>
                            <p className="client-data font-size">
                              Height:{" "}
                              <span>{clientDetails?.form1?.height || "-"}</span>
                            </p>
                          </Col>
                          <Col span={12} sm={6} lg={2.5}>
                            <p className="client-data font-size">
                              Weight:{" "}
                              {clientDetails?.form1?.weight ? (
                                <span>{clientDetails.form1.weight} lbs</span>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Grid>
                      </div>
                      <div>
                        <Grid align="Center" justify="Flex-start">
                          <Col
                            span={8}
                            sm={6}
                            md={6}
                            lg={5}
                            className="ssn-parent"
                          >
                            <div
                              className={classes.text}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "70%",
                                fontSize: "small",
                              }}
                            >
                              SSN:{" "}
                              {`${
                                showSSN
                                  ? decrypt(
                                      clientDetails?.form1
                                        ?.socialSecurityNumber,
                                      false
                                    )
                                  : "*".repeat(4)
                              }`}
                              {showSSN ? (
                                <IconEye
                                  color="#FFBA45"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowSSN((p) => !p)}
                                />
                              ) : (
                                <IconEyeClosed
                                  color="#FFBA45"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowSSN((p) => !p)}
                                />
                              )}
                            </div>
                          </Col>
                          <Col
                            span={12}
                            md={6}
                            className="ssn-parent font-size"
                          >
                            <span className="font-weight-500">
                              Admission Date:
                              <span className="figma-grey-color date-margin-left">
                                {moment(
                                  clientDetails?.form1?.admissionDate
                                ).format("MM/DD/YYYY")}
                              </span>
                            </span>
                          </Col>
                        </Grid>
                      </div>
                      <div className="address font-size">
                        <img
                          className="home-location"
                          src={homeLocation}
                          alt="home location item"
                        />
                        <span className="figma-grey-color font-weight-500">
                          {clientDetails?.form1?.previousAddress ||
                            "No address"}
                        </span>
                      </div>
                      <div className="statement">
                        <small className="font-weight-500 about-client">
                          {clientDetails?.form1?.personalPreference ||
                            "No preferences"}
                        </small>
                      </div>
                    </Col>
                    <Col span={12} sm={12} lg={4} xl={4}>
                      <div className="diagnosis-parent">
                        <p className="font-weight-500 mb-2 level-of-care font-size level-of-care">
                          Level of Care
                        </p>
                        {clientDetails?.form1?.levelofcare ? (
                          <div className="label">
                            {clientDetails?.form1?.levelofcare}
                          </div>
                        ) : (
                          <button className="no-level-of-care">
                            No Level of Care
                          </button>
                        )}

                        <p className="font-weight-500 mb-0 font-size diagnosis">
                          Diagnosis
                        </p>
                        {/* <Grid justify="Flex-end" className="known-illness"> */}
                        {Array.isArray(clientDetails?.form1?.knownIllness) &&
                        clientDetails?.form1?.knownIllness?.length > 0 ? (
                          clientDetails?.form1?.knownIllness?.map(
                            (item, index) => (
                              <button key={index} className="illness-child">
                                {typeof item === "object"
                                  ? item?.illness?.length === 0
                                    ? item?.contagious
                                    : item?.illness
                                  : item}
                                {/* {item} */}
                              </button>
                            )
                          )
                        ) : knownIllnessVal?.length > 0 ? (
                          knownIllnessVal.map((item, index) => (
                            <button key={index} className="illness-child">
                              {item}
                            </button>
                          ))
                        ) : (
                          <button className="no-diagnosis">No Diagnosis</button>
                        )}
                        {/* </Grid> */}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "baseline",
                            height: "100%",
                          }}
                        >
                          <button
                            id="client-personal-details"
                            onClick={() => {
                              navigate("/home/patient", {
                                state: {
                                  clientDetails: clientDetails,
                                  formName: "personalInfo",
                                  edit: true,
                                  disable: true,
                                },
                              });
                            }}
                            className="edit-button figma-grey-color font-weight-500"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Grid>
                </div>
                <div className="multiple-sections">
                  <Grid>
                    <Col span={12} lg={7.5}>
                      {/* INSURANCE */}
                      <div className="insurance-parent">
                        <div className="insurance-header">
                          <div>
                            <img
                              src={insurance}
                              alt="InsuranceImage"
                              className="insurance-img"
                            />
                            <h2 className="insurance-text">Insurance</h2>
                          </div>
                          <div>
                            <button
                              className="insurance-edit"
                              onClick={() => {
                                navigate("/home/patient", {
                                  state: {
                                    clientDetails: clientDetails,
                                    formName: "insuranceInfo",
                                    edit: true,
                                    disable: true,
                                  },
                                });
                              }}
                            >
                              Edit
                            </button>
                            <button
                              id="insurance-expand"
                              className="expand-button"
                              onClick={() => openModal()}
                            >
                              <img
                                src={expand}
                                alt="ExpandImage"
                                className="expand-image"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="insurance-card-parent">
                          <div className="insurance-cards-flex">
                            {clientDetails?.form2?.insurance ? (
                              clientDetails?.form2?.insurance?.map(
                                (data, index) => (
                                  <div
                                    key={index}
                                    className={
                                      data?.type != "medical"
                                        ? "insuranceCompParent"
                                        : ""
                                    }
                                  >
                                    <InsuranceCard
                                      type={data?.type}
                                      effectiveDate={
                                        moment(data?.effectiveDate).format(
                                          "MM/DD/YYYY"
                                        ) ||
                                        moment(
                                          clientDetails?.form2?.effectiveDate
                                        ).format("MM/DD/YYYY")
                                      }
                                      groupNumber={
                                        data?.groupNumber ||
                                        clientDetails?.form2?.groupNumber
                                      }
                                      subscriberName={
                                        data?.subscriberName ||
                                        clientDetails?.form2?.subscriberName
                                      }
                                      identificationNumber={
                                        data?.identificationNumber
                                      }
                                      insuranceCompanyName={
                                        data?.providerName ||
                                        clientDetails?.form2
                                          ?.insuranceCompanyName
                                      }
                                      anotherType={data?.insuranceType}
                                    />
                                  </div>
                                )
                              )
                            ) : (
                              <InsuranceCard
                                type={"Medical"}
                                effectiveDate={moment(
                                  clientDetails?.form2?.effectiveDate
                                ).format("MM/DD/YYYY")}
                                groupNumber={clientDetails?.form2?.groupNumber}
                                subscriberName={
                                  clientDetails?.form2?.subscriberName
                                }
                                identificationNumber={""}
                                insuranceCompanyName={
                                  clientDetails?.form2?.insuranceCompanyName
                                }
                                anotherType={""}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* CARE & SUPPORT */}
                      <div className="care-parent">
                        <div className="care-header">
                          <div>
                            <img
                              src={contact}
                              alt="Care&SupportImage"
                              className="care-img"
                            />
                            <h2 className="care-text">Care & Support</h2>
                          </div>
                          <div>
                            <button
                              id="care-support-edit"
                              className="care-edit"
                              onClick={() => {
                                navigate("/home/patient", {
                                  state: {
                                    clientDetails: clientDetails,
                                    formName: "careSupportInfo",
                                    edit: true,
                                    disable: true,
                                  },
                                });
                              }}
                              // onClick={(e) => {
                              //   dispatch(setAddPatientLoading(false));
                              //   openGuardianInfo();

                              // }}
                            >
                              Edit
                            </button>
                            <button
                              className="expand-button"
                              onClick={() => openCareModal()}
                            >
                              <img
                                src={expand}
                                alt="ExpandImage"
                                className="expand-image"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="care-box-parent">
                          {/* Care and support field name */}
                          <div className="box-1">
                            <small className="display-block font-weight-500 figma-grey-color line-height">
                              Guardian
                            </small>

                            <small className="display-block font-weight-500 figma-grey-color line-height">
                              Case Manager
                            </small>

                            <small className="display-block font-weight-500 figma-grey-color line-height">
                              PRIMARY CARE PHYSICIAN
                            </small>

                            <small className="display-block font-weight-500 figma-grey-color line-height">
                              PHARMACY
                            </small>

                            {clientDetails?.form2?.additionalProvider ? (
                              clientDetails?.form2?.additionalProvider?.map(
                                (item, index) => (
                                  <small
                                    key={index}
                                    className="display-block font-weight-500 figma-grey-color line-height"
                                  >
                                    {item?.providerType?.length > 23
                                      ? `${item?.providerType.slice(0, 23)}...`
                                      : item?.providerType}
                                  </small>
                                )
                              )
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          {/* Care and support names */}
                          <div className="box-2">
                            <small className="display-block font-weight-500 line-height">
                              {clientDetails?.form2?.emergencyContactName ? (
                                clientDetails?.form2?.emergencyContactName
                              ) : (
                                <div>-</div>
                              )}
                            </small>
                            <small className="display-block font-weight-500 line-height">
                              {clientDetails?.form2?.caseManagerName ? (
                                clientDetails?.form2?.caseManagerName
                              ) : (
                                <div>-</div>
                              )}
                            </small>
                            <small className="display-block font-weight-500 line-height">
                              {clientDetails?.form2?.pcpName ? (
                                clientDetails?.form2?.pcpName
                              ) : (
                                <div>-</div>
                              )}
                            </small>
                            <small className="display-block font-weight-500 line-height">
                              {clientDetails?.form2?.pharmacyName ? (
                                clientDetails?.form2?.pharmacyName
                              ) : (
                                <div>-</div>
                              )}
                            </small>
                            {clientDetails?.form2?.additionalProvider ? (
                              clientDetails?.form2?.additionalProvider?.map(
                                (item, index) => (
                                  <small
                                    key={index}
                                    className="display-block font-weight-500 line-height"
                                  >
                                    {item?.providerName}
                                  </small>
                                )
                              )
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          {/* Care and support call */}
                          <div className="box-3">
                            {clientDetails?.form2
                              ?.emergencyContactPhoneNumber ? (
                              <small className="display-block font-weight-500 line-height">
                                <img
                                  src={phone}
                                  alt="Call"
                                  className="call-icon"
                                />
                                {
                                  clientDetails?.form2
                                    ?.emergencyContactPhoneNumber
                                }
                              </small>
                            ) : (
                              <div>-</div>
                            )}

                            {clientDetails?.form2?.caseManagerPhoneNumber ? (
                              <small className="display-block font-weight-500 line-height">
                                <img
                                  src={phone}
                                  alt="Call"
                                  className="call-icon"
                                />
                                {clientDetails?.form2?.caseManagerPhoneNumber}
                              </small>
                            ) : (
                              <div>-</div>
                            )}
                            {clientDetails?.form2?.pcpPhoneNumber ? (
                              <small className="display-block font-weight-500 line-height">
                                <img
                                  src={phone}
                                  alt="Call"
                                  className="call-icon"
                                />
                                {clientDetails?.form2?.pcpPhoneNumber}
                              </small>
                            ) : (
                              <div>-</div>
                            )}

                            {clientDetails?.form2?.pharmacyPhoneNumber ? (
                              <small className="display-block font-weight-500 line-height">
                                <img
                                  src={phone}
                                  alt="Call"
                                  className="call-icon"
                                />
                                {clientDetails?.form2?.pharmacyPhoneNumber}
                              </small>
                            ) : (
                              <div>-</div>
                            )}

                            {clientDetails?.form2?.additionalProvider &&
                            clientDetails?.form2?.additionalProvider?.length > 0
                              ? clientDetails?.form2?.additionalProvider?.map(
                                  (item, index) =>
                                    item?.providerPhone ? (
                                      <small
                                        key={index}
                                        className="display-block font-weight-500 line-height"
                                      >
                                        <img
                                          src={phone}
                                          alt="Call"
                                          className="call-icon"
                                        />
                                        {item?.providerPhone}
                                      </small>
                                    ) : (
                                      <div>-</div>
                                    )
                                )
                              : null}
                          </div>
                          {/* Care and support emails*/}
                          <div className="box-4">
                            {clientDetails?.form2?.emergencyContactEmail ? (
                              <small className="display-block font-weight-500 line-height">
                                <FaEnvelope style={{ marginRight: "7px" }} />
                                {clientDetails?.form2?.emergencyContactEmail}
                              </small>
                            ) : (
                              <div>-</div>
                            )}
                            {clientDetails?.form2?.caseManagerEmail ? (
                              <small className="display-block font-weight-500 line-height">
                                <FaEnvelope style={{ marginRight: "7px" }} />
                                {clientDetails?.form2?.caseManagerEmail}
                              </small>
                            ) : (
                              <div>-</div>
                            )}
                            {clientDetails?.form2?.pcpEmail ? (
                              <small className="display-block font-weight-500 line-height">
                                <FaEnvelope style={{ marginRight: "7px" }} />
                                {clientDetails?.form2?.pcpEmail}
                              </small>
                            ) : (
                              <div>-</div>
                            )}
                            {clientDetails?.form2?.pharmacyEmail ? (
                              <small className="display-block font-weight-500 line-height">
                                <FaEnvelope style={{ marginRight: "7px" }} />
                                {clientDetails?.form2?.pharmacyEmail}
                              </small>
                            ) : (
                              <div>-</div>
                            )}
                            {clientDetails?.form2?.additionalProvider &&
                            clientDetails?.form2?.additionalProvider.length > 0
                              ? clientDetails.form2.additionalProvider.map(
                                  (item, index) =>
                                    item.providerEmail ? (
                                      <small
                                        key={index}
                                        className="display-block font-weight-500 line-height"
                                      >
                                        <FaEnvelope
                                          style={{ marginRight: "7px" }}
                                        />
                                        {item.providerEmail}
                                      </small>
                                    ) : (
                                      <div>-</div>
                                    )
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                      {/* HEALTH */}
                      <div className="health-parent">
                        <div className="health-header">
                          <div>
                            <img
                              src={heart}
                              alt="Healthimage"
                              className="health-img"
                            />
                            <h2 className="health-text">Health</h2>
                          </div>
                          <div>
                            <button
                              className="health-edit"
                              id="health-edit-click"
                              onClick={() => {
                                navigate("/home/patient", {
                                  state: {
                                    clientDetails: clientDetails,
                                    formName: "healthInfo",
                                    edit: true,
                                    disable: true,
                                  },
                                });
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                        <div>
                          {/* Active Medication */}
                          <div className="medication-parent">
                            <p className="client-medication">
                              Active Medication
                            </p>
                            {filteredMedications &&
                            filteredMedications.length > 0 ? (
                              filteredMedications.map((medicine, index) => (
                                <button key={index} className="medicine">
                                  {`${medicine?.medicationName} ${medicine?.dosage} ${medicine?.unit}`}
                                </button>
                              ))
                            ) : (
                              <small>No Medication</small>
                            )}

                            {/* <button className="medicine">
                              Acetaminophen 500 mg
                            </button>
                            <button className="medicine">
                              Insulin 15 mg/mL
                            </button>
                            <button className="medicine">
                              Amoxicillin 5mg
                            </button>
                            <button className="medicine">
                              Insulin 15 mg/mL
                            </button> */}
                          </div>
                          {/* Allergies */}
                          <div className="medication-parent">
                            <p className="allergies">Allergies</p>

                            {clientDetails?.form1?.knownAllergies?.length >
                            0 ? (
                              clientDetails?.form1?.knownAllergies
                                ?.filter((item) => item.trim() !== "")
                                ?.map((item, index) => (
                                  <button key={index} className="allergy">
                                    {item}
                                  </button>
                                ))
                            ) : (
                              <small>No Allergy</small>
                            )}
                          </div>
                          {/* Dietary Restrictions */}
                          <div className="medication-parent">
                            <p className="dietary-restrictions">
                              Dietary Restrictions
                            </p>
                            {clientDetails?.form1?.dietaries?.length != 0 ? (
                              clientDetails?.form1?.dietaries
                                ?.filter((item) => item != "")
                                ?.map((item, index) => (
                                  <button key={index} className="restriction">
                                    {item}
                                  </button>
                                ))
                            ) : (
                              <small>No Restriction</small>
                            )}
                          </div>
                          {/* Diagnosis Summary */}
                          <div className="textarea-parent">
                            <fieldset className="contact-fieldset">
                              <legend>Diagnosis Summary</legend>
                              {/* <Textarea value={"Hello"} variant="unstyled" disabled /> */}
                              <p className="diagnosis-text">
                                {clientDetails?.form1?.summary}
                              </p>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* Activity */}
                    <Col span={12} lg={4.5}>
                      <div className="timeline-parent">
                        <div className="activity-parent">
                          <img
                            className="light-bulb"
                            src={lightBulb}
                            alt="Activity icon"
                          />
                          <h2 className="activity-text">Activity</h2>
                        </div>
                        <div className="timeline-div">
                          {activityLoading ? (
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                height: "700px",
                              }}
                            >
                              <Loader color="yellow" size={"md"} />
                            </Box>
                          ) : (
                            <ActivitySide activities={activities} />
                          )}
                          {/* <h2 className="coming-soon">Coming Soon!</h2> */}
                        </div>
                      </div>
                    </Col>
                  </Grid>
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="support">
                {/* <ClientSupportPlan client={state} /> */}
                <ClientSupportPlan client={state} />
                {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
                  <ClientSummaryPDF clientData={clientDetails} />
                </PDFViewer> */}
              </Tabs.Panel>
              <Tabs.Panel value="notes">
                {/* <NoteTable userId={id} />  */}
                <ClientNoteview userId={id} />
              </Tabs.Panel>
              {/* <Tabs.Panel value="medication">
                <ClientViewMedication
                  setClientDetails={setClientDetails}
                  form3={clientDetails?.form3}
                />
              </Tabs.Panel> */}
              <Tabs.Panel value="medication-list">
                {loading ? (
                  <Loader
                    color="yellow"
                    style={{
                      marginTop: "190px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      left: "40%",
                    }}
                  />
                ) : (
                  activeTab === "medicationList" && (
                    <>
                      <AddMedicationButton
                        userId={clientDetails?.id}
                        selectedClient={selectedClient}
                        getClientData={getData}
                      />
                      <FilterBar activeTab={activeTab} />
                      <MedicationListTab
                        userId={clientDetails?.id}
                        clientId={selectedClient?.id}
                        clientDetails={clientDetails}
                        onEditClick={(data, isPreview) => {
                          setMedicine(data);
                          open();
                          if (isPreview) setIsPreview(true);
                        }}
                      />
                    </>
                  )
                )}
              </Tabs.Panel>
              <Tabs.Panel value="mar-log">
                <>
                  <AddMedicationButton
                    userId={clientDetails?.id}
                    selectedClient={selectedClient}
                  />
                  <FilterBar activeTab={activeTab} />
                  {activeTab === "marLog" && (
                    <MarLogTab
                      userId={id}
                      activeTab={activeTab}
                      refresh={shouldRefresh}
                    />
                  )}
                </>
              </Tabs.Panel>
              <Tabs.Panel value="medication-log">
                <>
                  <AddMedicationButton
                    userId={clientDetails?.id}
                    selectedClient={selectedClient}
                  />
                  <FilterBar activeTab={activeTab} />
                  {activeTab === "medicationLog" && (
                    <MedicationLogTab
                      userId={id}
                      activeTab={activeTab}
                      refresh={shouldRefresh}
                    />
                  )}
                </>
              </Tabs.Panel>
              <Tabs.Panel value="mar">
                {/* <AddEntryModal mar={state} /> */}

                {/* <MedicationTable userId={id} /> */}

                <Medication userId={id} tabName={"mar"} />
              </Tabs.Panel>
              <Tabs.Panel value="documents">
                <Document client={id} />
              </Tabs.Panel>
            </Tabs>
          </div>
        </>
      )}
    </Container>
  );
};

export default Client;
