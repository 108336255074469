import { Container } from "@mantine/core";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import MainContainer from "../../components/MainContainer";
import Nav from "../../components/Navbar";
import Dashboard from "./Dashboard";
import Facility from "./Facility";
import FacilityType from "./FacilityType";
import Note from "./Note";
import NoteType from "./NoteType";
import Patient from "./Patient";
import Reports from "./Report";
import Setting from "./Setting";
import Notification from "./Notificaton";
import Staff from "./Staff";
import Task from "./Task";
import TaskType from "./Task/TaskType.jsx";
import AddTask from "./Task/AddTask.jsx";
import useStyles from "./style";
import { isSubscriptionExpired } from "../../utils";
import { useEffect } from "react";
import Medication from "./Medication";
import SupportPlan from "./SupportPlan";
import SingleTicket from "../SingleTicket/index.jsx";
import HaveProblem from "../HaveProblem/index.jsx";
// import ViewMedication from "./Medication/ViewMedication.jsx";
import ViewTask from "./Task/ViewTask.jsx";
import EditTask from "./Task/EditTask.jsx";
import Client from "./Client/index.jsx";

const Home = () => {
  const { classes } = useStyles();
  const { profile } = useSelector((state) => state?.profile);
  let token = Cookies.get("token");

  const navigate = useNavigate();

  useEffect(() => {
    const isExpired = isSubscriptionExpired();
    if (isExpired) {
      if (profile?.user?.type === "staff") return navigate("/home/settings");
      navigate("/home/settings?selectedTab=upgrade");
    }
  }, [profile?.subscription?.expiredAt]);

  if (token && !profile?.business && profile?.user?.type !== "staff")
    return <Navigate to="/signup" state={{token: token}} />;

  if (!token) return <Navigate to="/login" />;

  return (
    <MainContainer>
      <Container className={classes.wrapper} fluid p={0}>
        <Nav />
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="supportPlan" element={<SupportPlan />} />
          <Route path="facility" element={<Facility />} />
          {profile?.user?.type != "staff"
           ? (
            <Route path="staff" element={<Staff />} />
          ) : null}
          <Route path="facilitytype" element={<FacilityType />} />
          <Route path="report" element={<Reports />} />
          <Route path="client" element={<Client />} />
          <Route path="patient" element={<Patient />} />
          <Route path="medication" element={<Medication />} />
          <Route path="note" element={<Note />} />
          <Route path="notetype" element={<NoteType />} />
          <Route path="settings" element={<Setting />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="tasks" element={<Task />} />
          <Route path="taskType" element={<TaskType />} />
          <Route path="add-task" element={<AddTask />} />
          <Route path="view-task" element={<ViewTask />} />
          <Route path="edit-task" element={<EditTask />} />
          <Route path="ticket/:id" element={<SingleTicket />} />
          <Route path="create-ticket" element={<HaveProblem />} />
        </Routes>
      </Container>
    </MainContainer>
  );
};
export default Home;
