import {
  Box,
  Container,
  createStyles,
  SegmentedControl,
  Text,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomPagination from "../../components/Pagination/CustomPagination";
import PlanTable from "../../components/PlanTable";
import { getPlans } from "../../services/supportplan";
import AddButton from "../AddButton";
import SelectCarePlanTypeModal from "../SelectCarePlanTypeModal";
import SupportModal from "../SupportModal";
import CarePlaneDetails from "./CarePlaneDetails";
import { FaArrowLeftLong } from "react-icons/fa6";
import CarePlanUpdate from "./CarePlanUpdate";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
    // ".mantine-rhlimb":{
    //   padding: "unset !important",
    // },
    // "& .mantine-p2ocmu": {
    //   borderRadius: "1rem 0 0 1rem !important",
    // },
  },
  btn: {
    // marginLeft: "auto",
    // marginTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  firsttext: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: 10,
    marginTop: 50,
    cursor: "pointer",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  segmentBTN: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "10px",
  },
  tabContainer: {
    display: "flex",
    minWidth: "260px",
    height: "40px",
  },
  activeTabBg: {
    background: "#fae7be !important",
  },
  activeTab: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px 0 0 50px",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      width: "24px",
      marginRight: "5px",
    },
  },
  draftTab: {
    display: "flex",
    alignItems: "center",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      width: "24px",
      marginRight: "5px",
    },
  },
  discontinuedTab: {
    display: "flex",
    alignItems: "center",
    borderRadius: "0 50px 50px 0",
    background: "#F2F2F2",
    padding: "5px 10px",
    cursor: "pointer",
    "& p": {
      margin: "0",
      fontWeight: "600",
    },
    "& img": {
      width: "24px",
      marginRight: "5px",
    },
  },
}));
const ClientSupportPlan = ({ client }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
  const [carePlanType, setCarePlanType] = useState("");
  const [selectedTab, setSelectedTab] = useState("active");

  const [row, setRow] = useState(null);
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { count } = useSelector((state) => state.supportPlan);
  const [showCarePlanDetails, setShowCarePlanDetails] = useState(false);

  const [rowData, setRowData] = useState(null);

  const { plans, addPlanLoading } = useSelector((state) => state?.supportPlan);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const carePlanSelected = (type) => {
    setCarePlanType(type);
    setShowCarePlanDetails(true);
    close2();
    open();
  };

  const { classes } = useStyles();

  const handleAddCarePlanClick = () => {
    setShowCarePlanDetails(true);
    // open2();
  };

  const handleTableBoxClick = () => {
    setShowCarePlanDetails(false);
    setRowData(null);
  };

  const tabMap = {
    active: "active",
    draft: "draft",
    discontinued: "discontinued",
  };

  useEffect(() => {
    const segment = tabMap[selectedTab];
    getPlans(currentPage, client, segment);
  }, [currentPage, selectedTab]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Container fluid className={classes.content} px={20}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {showCarePlanDetails && (
          <Box className={classes.firsttext} onClick={handleTableBoxClick}>
            <FaArrowLeftLong size={20} />
            Back
          </Box>
        )}
        <div className={classes.btn} style={{ marginTop: "20px" }}>
          {!showCarePlanDetails && (
            <Box className={classes.segmentBTN}>
              <Box className={classes.tabContainer}>
                <Box
                  className={`${classes.activeTab} ${
                    selectedTab === "active" ? classes.activeTabBg : ""
                  }`}
                  onClick={() => handleTabChange("active")}
                >
                  <Text fw={500}>Active</Text>
                </Box>
                <Box
                  id="medication-week"
                  className={`${classes.draftTab} ${
                    selectedTab === "draft" ? classes.activeTabBg : ""
                  }`}
                  onClick={() => handleTabChange("draft")}
                >
                  <Text fw={500}>Draft</Text>
                </Box>
                <Box
                  id="medication-today"
                  className={`${classes.discontinuedTab} ${
                    selectedTab === "discontinued" ? classes.activeTabBg : ""
                  }`}
                  onClick={() => handleTabChange("discontinued")}
                >
                  <Text fw={500}>Discontinued</Text>
                </Box>
              </Box>
              <AddButton
                title={"Add Care Plan"}
                onClick={handleAddCarePlanClick}
              />
              {/* {selectedSegment === "Active" && plans?.length < 1 &&   <AddButton
                title={"Add Care Plan"}
                onClick={handleAddCarePlanClick}
              />} */}
            </Box>
          )}
        </div>
        {showCarePlanDetails && !rowData && (
          <CarePlaneDetails
            rowData={rowData}
            // onSelect={carePlanSelected}
            segment={selectedTab}
            client={client}
            carePlanType={carePlanType}
            setRowData={setRowData}
            addPlanLoading={addPlanLoading}
            setShowCarePlanDetails={setShowCarePlanDetails}
            handleTableBoxClick={handleTableBoxClick}
          />
        )}
        {/* <SupportModal
          opened={opened}
          close={close}
          carePlanType={carePlanType}
          title={"Add Care Plan"}
          data={row}
          view={view}
          client={client}
        /> */}
        {!showCarePlanDetails && (
          <>
            <PlanTable
              setRowData={setRowData}
              setShowCarePlanDetails={setShowCarePlanDetails}
              segment={selectedTab}
              currentPage={currentPage}
              client={client}
            />
            <CustomPagination
              data={count}
              itemsPerPage={10}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </div>
      {showCarePlanDetails && rowData && (
        <CarePlanUpdate
          edit={true}
          client={client}
          rowData={rowData}
          segment={selectedTab}
          setRowData={setRowData}
          currentPage={currentPage}
          setShowCarePlanDetails={setShowCarePlanDetails}
          handleTableBoxClick={handleTableBoxClick}
        />
      )}

      <SelectCarePlanTypeModal
        open={opened2}
        close={close2}
        onSelect={carePlanSelected}
      />
    </Container>
  );
};

export default ClientSupportPlan;
