import { https } from "../http";
import { showError } from "../utils";

const getPlaidLink = async () => {
  try {
    let response = await https().get(`/plaid/get-session-link`);

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const exchangeToken = async (data) => {
  try {
    let response = await https().post(`/plaid/exchange-public-token`, data);

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const getConnectedAccounts = async () => {
  try {
    let response = await https().get(`/payment/get-connected-accounts`);

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const deleteCustomer = async (id) => {
  try {
    let response = await https().delete(
      `/payment/delete-customer?stripeCustomerId=${id}`
    );

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const connectCard = async (data, callback) => {
  try {
    let response = await https().post(`/payment/connect-card`, data);

    if (response?.status === 200) {
      if (response?.data?.type === "StripeCardError") {
        showError(response?.data?.raw?.message, 500);
        callback?.(response?.data?.raw?.message);
      } else callback?.(null, response.data);
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
    callback(error);
  }
};
const addNewCard = async (data, callback) => {
  try {
    let response = await https().post(`/payment/add-new-card`, data);

    if (response?.status === 200) {
      if (response?.data?.type === "StripeCardError") {
        showError(response?.data?.raw?.message, 500);
        callback?.(response?.data?.raw?.message);
      } else callback?.(null, response.data);
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
    callback(error);
  }
};
export {
  getPlaidLink,
  exchangeToken,
  getConnectedAccounts,
  deleteCustomer,
  connectCard,addNewCard
};
