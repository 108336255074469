import "./index.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import NoteModal from "../../../components/NoteModal";
import OpenFileModal from "../../../components/Document/OpenFileModal";
import { Box, Button, Text, Timeline } from "@mantine/core";
import moment from "moment";
import MedicationModal from "../../../components/MedicationModal";

const ActivitySide = ({ activities }) => {
  const [view, setView] = useState(true);
  console.log("🚀 ~ ActivitySide ~ view:", view)
  const [data, setData] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  console.log("🚀 ~ ActivitySide ~ isViewOpen:", isViewOpen)
  const [selectedMar, setSelectedMar] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [Fileopened, { open: openFile, close: closeFile }] =
    useDisclosure(false);

  const navigate = useNavigate();
  const compareDate = (date, medicationType, upcoming) => {
    var time1 = moment(date).startOf("day");
    var today = moment().startOf("day");
    if (medicationType === upcoming) {
      return -1;
    } else if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  return (
    <>
      <NoteModal
        opened={isopen}
        close={() => {
          setOpen(false);
          setData(null);
        }}
        title={"Add New Note"}
        view={true}
        setView={setView}
        data={data}
      />

      <OpenFileModal
        opened={Fileopened}
        onclose={() => {
          closeFile();
        }}
        title={""}
        data={selectedFile}
      />

      {selectedMar && (
        <MedicationModal
          opened={opened}
          onclose={() => {
            close();
            setIsViewOpen(false);
          }}
          isViewOpen={true}
          setIsViewOpen={setIsViewOpen}
          data={selectedMar}
          futureDate={compareDate(selectedMar?.date)}
        />
      )}

      <Timeline
        lineWidth={4}
        color="yellow"
        active={activities?.length}
        bulletSize={20}
      >
        {activities &&
          activities?.map((item, index) => (
            <Timeline.Item
              key={index}
              title={
                <Box className="timeline-title-time">
                  <Box className="timeline-title-time-left">
                    <Box className="timeline-title timeline-title-color">
                      {item?.module == "note"
                        ? item?.moduleId?.fields?.noteType
                        : item?.module}
                    </Box>
                    <Box className="accurate-time">
                      <span className="figma-grey-color">
                        {moment(item?.createdAt).format("hh:mm a")}
                      </span>
                    </Box>
                  </Box>
                  <Box className="relative-time">
                    <span className="figma-grey-color">
                      {moment(item?.createdAt).fromNow()}
                    </span>
                  </Box>
                </Box>
              }
            >
              {item?.module !== "mar" && (
                <div>
                  <Text c="dimmed" size="sm" className="timeline-text">
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.module == "note" &&
                        (item?.moduleId?.fields?.noteType || item?.module)}{" "}
                    </span>{" "}
                    created by {item?.createdBy?.name}
                  </Text>

                  {item?.module == "note" && (
                    <Text c="dimmed" size="xs" className="timeline-text">
                      {item?.moduleId?.fields?.Description}
                    </Text>
                  )}

                  {item?.module == "task" && (
                    <Text c="dimmed" size="xs" className="timeline-text">
                      {item?.moduleId?.fieldsDone
                        ?.map((_v) => _v?.field?.name)
                        ?.join(", ")}
                    </Text>
                  )}
                </div>
              )}

              {item?.module == "mar" && (
                <div>
                  <Text c="dimmed" size="sm" className="timeline-text">
                    {item?.moduleId?.dosage} {item?.moduleId?.form3?.dosageForm}{" "}
                    of{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.moduleId?.form3?.medicationName}{" "}
                    </span>{" "}
                    {item?.moduleId?.form3?.dosage}
                    {item?.moduleId?.form3?.unit} (
                    {item?.moduleId?.form3?.frequency}) marked taken by{" "}
                    {item?.createdBy?.name}
                  </Text>
                  <Text c="dimmed" size="xs" className="timeline-text">
                    {item?.moduleId?.comments}
                  </Text>
                </div>
              )}
              <Button
                disabled={item?.moduleDeleted}
                className="timeline-view"
                style={{ color: item?.moduleDeleted ? "#ccc" : "#FFBA45" }}
                onClick={() => {
                  if (item?.module == "task") {
                    navigate("/home/view-task", {
                      state: {
                        ...item?.moduleId,
                        selectedShift: item?.moduleId?.shift[0],
                      },
                    });
                  } else if (item?.module == "note") {
                    setData(item?.moduleId);
                    setOpen(true);
                  } else if (item?.module == "document") {
                    setSelectedFile(item?.moduleId);
                    openFile();
                  } else if (item?.module == "mar") {
                    setSelectedMar(item?.moduleId);
                    open();
                  }
                }}
              >
                View
              </Button>
            </Timeline.Item>
          ))}
      </Timeline>
    </>
  );
};

export default ActivitySide;
