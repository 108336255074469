import React, { useEffect, useState } from "react";

import { Box, Container, createStyles } from "@mantine/core";
import AddButton from "../../../components/AddButton";
import AlertModal from "../../../components/AlertModal";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import StaffTable from "../../../components/StaffTable";
import Topbar from "../../../components/Topbar";
import StaffModal from "../../../containers/StaffModal";
import { getAllStaff } from "../../../services/staff";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important"
    }
  },
  mwd: {
    width: "100%"
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  }
}));
const Staff = () => {
  const { classes } = useStyles();
  const [isopen, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { staff } = useSelector((state) => state.staff);
  const [isStaffModalOpen, { open: openStaffModal, close: closeStaffModal }] = useDisclosure(false);


  useEffect(() => {
    getAllStaff(currentPage);
  }, [currentPage]);

  useEffect(()=>{
    TrackPageView("Staff")
  }, [])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  return (
    <Container fluid className={classes.content} px={20}>
      <AlertModal isopen={isopen} close={() => setOpen(false)} />
      <StaffModal
        save={setOpen}
        title={"Add New Staff"}
        opened={isStaffModalOpen}
        onclose={closeStaffModal}
      />
      <Topbar title={"Staff Management"} />
      <Box className={classes.btn}>
        <AddButton title={"Add New Employee"} onClick={openStaffModal} />
      </Box>
      <Box className={classes.table}
      >
        <StaffTable />
        <CustomPagination
          itemsPerPage={10}
          data={staff?.count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default Staff;
