import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";

import { IconChevronLeft } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./style";

import { DateInput, MonthPickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { addNewCard } from "../../services/payment";
import swal from "sweetalert";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";

const PaymentInfo = () => {
  const [value, setValue] = useState("manual");

  const navigate = useNavigate();
  const { state } = useLocation();
  const { profile } = useSelector((state) => state.profile);

  const { classes } = useStyles();

  const [connectingCard, setConnectingCard] = useState(false);

  const form = useForm({
    initialValues: {
      nameCard: "",
      cardNumber: "",
      cardExp: null,
      CVV: "",
    },
    validate: {
      nameCard: (value) => (value ? null : "Please add card name"),
      cardNumber: (value) =>
        value && value?.length <= 16 ? null : "Please add a valid card number",
      cardExp: (value) => (value ? null : "Select expiry date"),
      CVV: (value) => {
        const isValidLength =
          value && (value?.length === 3 || value?.length === 4);
        return isValidLength ? null : "Please add a valid number";
      },
    },
  });

  useEffect(() => {
    if (form.getInputProps("cardNumber")?.value?.length > 16) {
      form.setFieldValue(
        "cardNumber",
        form.getInputProps("cardNumber")?.value?.slice(0, 16)
      );
    }
  }, [form.getInputProps("cardNumber")?.value]);

  useEffect(() => {
    if (form.getInputProps("CVV")?.value?.length > 4) {
      form.setFieldValue("CVV", form.getInputProps("CVV")?.value?.slice(0, 4));
    }
  }, [form.getInputProps("CVV")?.value]);

  useEffect(() => {
    pageInfoAnalytics("Payment Info Page Viewed", "Payment Info Page");
  }, []);

  // const getAllConnected = async () => {
  //   let accounts = await getConnectedAccounts();
  //   setConnected(accounts);
  // };
  const onFinish = async (values) => {
    let obj = {
      ...values,
      userId: profile?.user.id,
      // email: profile?.user?.email,
      // businessId: profile?.business?.id
    };

    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone. Previous added credit card will be replaced with this new card and all upcoming payments will be processed on this new card.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Proceed"],
    });
    if (willDelete) {
      setConnectingCard(true);
      addNewCard(obj, (error, data) => {
        if (!error) {
          setConnectingCard(false);
          // if (newConnect === "true") return navigate("/home/settings");
          return navigate("/home/settings");
          // getAllConnected();
        } else {
          setConnectingCard(false);
        }
      });
    } else {
      return navigate("/home/settings");
    }
  };
  const onBack = () => {
    navigate("/home/settings");
  };

  return (
    <Box className="payment-page">
      <Group className={classes.back}>
        <ActionIcon
          size={"xl"}
          radius={"xl"}
          onClick={onBack}
          variant="outline"
        >
          <IconChevronLeft />
        </ActionIcon>
        <Text>Back</Text>
      </Group>

      <Container className={classes.centered} size={"md"}>
        {/* <Box className={classes.paymentFailedBox}>
          <Text ff="Inter" fz={16} fw={400} color="#FFFF">
            Your payment was unsuccessful. Please <Link className={classes.update} to="/home/settings" state={{ selectedTab: "method" }}>update</Link> your payment method to
            maintain your subscription.
          </Text>
        </Box> */}
        <Box className={classes.logo}>
          <img width={"30%"} src="assets/images/logo2.png" alt="" />
        </Box>
        <Text className={classes.title}>Add New Payment</Text>
      </Container>

      {value === "manual" && (
        <Container size={"md"}>
          <Card shadow="sm" className={classes.card} padding={40}>
            <form onSubmit={form.onSubmit((values) => onFinish(values))}>
              <Box className={classes.inputs}>
                <TextInput
                  type=""
                  size="lg"
                  variant="filled"
                  label="Name on Card"
                  placeholder="Eg.abc"
                  {...form.getInputProps("nameCard")}
                  className={[classes.myfont, classes.m10]}
                />
              </Box>

              <Box className={classes.inputs}>
                <TextInput
                  size="lg"
                  type="number"
                  variant="filled"
                  label="Card Number"
                  className={classes.myfont}
                  placeholder="Eg.5952 **** **** ****"
                  {...form.getInputProps("cardNumber")}
                />
                {form.errors.cardNumber && (
                  <Box style={{ color: "red" }}>
                    {form.errors.cardNumber.message}
                  </Box>
                )}
              </Box>

              <SimpleGrid cols={2} className={classes.m10}>
                {/* <DateInput
                  size="lg"
                  variant="filled"
                  label="Expiry Date"
                  valueFormat="MM/YY"
                  minDate={new Date()}
                  placeholder="Eg.08/23"
                  className={classes.myfont}
                  {...form.getInputProps("cardExp")}
                /> */}
                <MonthPickerInput
                  size="lg"
                  variant="filled"
                  label="Expiry Date"
                  valueFormat="MM/YY"
                  minDate={new Date()}
                  placeholder="Eg.08/23"
                  className={classes.myfont}
                  {...form.getInputProps("cardExp")}
                />
                <TextInput
                  size="lg"
                  label="CVV"
                  type="number"
                  variant="filled"
                  placeholder="Eg.***"
                  className={classes.myfont}
                  {...form.getInputProps("CVV")}
                />
              </SimpleGrid>

              <Center>
                <Button
                  size="lg"
                  type="submit"
                  color="yellow"
                  loading={connectingCard}
                  className={classes.payBtn}
                >
                  Connect
                </Button>
              </Center>
            </form>
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default PaymentInfo;
