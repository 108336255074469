import React from "react";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  PasswordInput,
  Radio,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  createStyles
} from "@mantine/core";

import { useForm, setForm } from "@mantine/form";

import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

import { useLocation } from "react-router-dom";
import { IconClock, IconPlus, IconTrash } from "@tabler/icons-react";
import { EditclientProfile, getSinglePatients } from "../../services/patient";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    // "& .mantine-Paper-root": {
    //     overflow: "initial !important",
    // },
    "& .mantine-Modal-inner": {
      padding: "0px !important"
    },

    "@media (max-width:400px)": {
      width: "auto"
    }
  },
  mwd: {
    width: "100%"
  }
}));

const PersonalHealthEdit = ({
  opened,
  onclose,
  title,
  form1,
  setClientDetails
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const { classes } = useStyles();

  // console.log('my form1', form1);

  const location = useLocation();
  const id = location.state;

  const [knownAllergies, setKnownAllergies] = useState([""]);
  const [levelofcare, setLevelofcare] = useState([""]);
  const [knownIllness, setKnownIllness] = useState([]);
  const [dietaries, setDietaries] = useState([""]);
  const [isknownIllness, setIsKnownIllness] = useState(false);

  const form = useForm({
    initialValues: {
      isKnownIllness: "no",
      dietaryRestrictions: [],
      knownAllergies: [],
      levelofcare: "",
      isForm1: "true"
    }
  });

  const level_of_care = [
    " Memory Care",
    "Supervisory Care",
    "Personal Care",
    "Directed Care"
  ];
  const addEmptyDietary = () => {
    let temp = [...dietaries];
    temp.push("");
    setDietaries(temp);
  };
  const removeDietary = (index) => {
    if (dietaries?.length > 1) {
      let temp = dietaries?.filter((v, i) => i !== index);
      setDietaries(temp);
    }
  };

  const handleChangeKnownIllness = (index, value, type) => {
    // let temp = [...knownIllness];
    // temp[index][type] = value;
    setKnownIllness((p) =>
      p?.map((v, i) => (index === i ? { ...v, [type]: value } : v))
    );
  };

  const addEmptyKnownIllness = () => {
    let temp = [...knownIllness];
    temp.push({
      illness: "",
      contagious: "no"
    });
    setKnownIllness(temp);
  };
  const removeKnownIllness = (index) => {
    if (knownIllness?.length > 1) {
      let temp = knownIllness?.filter((v, i) => i !== index);
      setKnownIllness(temp);
    }
  };

  const handleChangeKnownAllergies = (index, value) => {
    let temp = [...knownAllergies];
    temp[index] = value;
    setKnownAllergies(temp);
  };

  const addEmptyAllergy = () => {
    let temp = [...knownAllergies];
    temp.push("");
    setKnownAllergies(temp);
  };
  const removeAllergy = (index) => {
    if (knownAllergies?.length > 1) {
      let temp = knownAllergies?.filter((v, i) => i !== index);
      setKnownAllergies(temp);
    }
  };

  const handleChangeDietary = (index, value) => {
    let temp = [...dietaries];
    temp[index] = value;
    setDietaries(temp);
  };

  useEffect(() => {
    if (form1) {
      form.setValues({
        levelofcare: form1?.levelofcare
      });

      let knownAl =
        typeof form1?.knownAllergies === "string" ||
        form1?.knownAllergies?.length === 0
          ? [""]
          : form1?.knownAllergies || [""];

      let knownIll =
      form1?.knownIllness?.length > 0 && form1?.knownIllness?.[0]?.illness ? form1?.knownIllness?.map(item => item.illness) : form1?.knownIllness
      let diet =
        form1?.dietaries === "string" || form1?.dietaries?.length === 0
          ? [""]
          : form1?.dietaries || [""];

      setKnownAllergies(knownAl);
      setKnownIllness(knownIll);
      setDietaries(diet);
    }
  }, [form1]);

  const prevoiusdata = {
    name: form1?.name,
    dob: form1?.dob,
    previousAddress: form1?.previousAddress,
    admissionDate: form1?.admissionDate,
    gender: form1?.gender,
    socialSecurityNumber: form1?.socialSecurityNumber,
    summary: form1?.summary,
    facility: form1?.facility,
    contagious: form1?.contagious,
    lastFourDigits: form1?.lastFourDigits,
    patientWounds: form1?.patientWounds,
    state: form1?.state,
    knownIllness: form1?.knownIllness?.length > 0 && form1?.knownIllness?.[0]?.illness ? form1?.knownIllness?.map(item => item.illness) : form1?.knownIllness,
    zipCode: form1?.zipCode
  };

  const onSave = async (values) => {
    // console.log('values', values);
    //setLoading(true);

    try {
      let payload = {
        ...values,
        knownAllergies: knownAllergies,
        dietaries: dietaries,
        ...prevoiusdata,
        id,
        isForm1: true
      };
      await EditclientProfile(payload);
      //const payload = { ...values, id: id,knownAllergies: knownAllergies, dietaries: dietaries, ...prevoiusdata };
      // console.log('payload', payload);
      // await EditclientProfile(payload);
      //await EditclientProfile({ form1: payload, clientId: id })
      // console.log("Successfully updated profile");
      //resetForm();
      const data = await getSinglePatients(id);
      // console.log({ data });
      setClientDetails(data);
      onclose();
    } catch (error) {
      console.error("Error updated profille:", error.message);
    } finally {
      //getMar()
      //setLoading(false);
      //window.location.reload();
    }
  };

  const resetForm = () => {
    form?.reset();
    onclose();
  };
  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          {false ? (
            // <TableLoader/>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                bottom: "11%",
                left: "7%"
                //padding: "100px 0 0 0",
              }}
            >
              <Loader color="yellow" size={"md"} />
            </Box>
          ) : (
            <Modal
              className={classes.mymodal}
              opened={opened}
              size={"xl"}
              onClose={() => {
                //form?.reset();
                onclose();
              }}
              shadow="md"
              overlayProps={{ blur: 3 }}
              radius={"lg"}
              zIndex={1000}
              title={
                <Group>
                  <img src="/assets/images/addfac.png" alt="" />
                  <Text
                    sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}
                  >
                    {title}
                  </Text>
                </Group>
              }
              centered
            >
              <form onSubmit={form?.onSubmit((values) => onSave(values))}>
                <SimpleGrid cols={1}>
                  <Divider variant="dashed" />
                  <Select
                    style={{ width: "36%" }}
                    searchable
                    label="Level Of Care"
                    data={level_of_care}
                    size="md"
                    placeholder="Choose Level Of care"
                    {...form.getInputProps("levelofcare")}

                    //disabled={view}
                  />
                  <Divider variant="dashed" />
                  {knownAllergies?.map((value, index) => (
                    <Group key={index} align="center" grow>
                      <Group>
                        <span style={{ marginTop: "20px" }}>{index + 1} .</span>
                        <TextInput
                          className={classes.myfont}
                          label="Known Allergies"
                          size="md"
                          icon={
                            <img
                              src="/assets/images/building.png"
                              alt="address"
                            />
                          }
                          placeholder="Please enter known allergies"
                          value={value}
                          onChange={(e) =>
                            handleChangeKnownAllergies(index, e?.target?.value)
                          }
                          //disabled={view}
                        />
                      </Group>
                      <Group>
                        <ActionIcon
                          color="red"
                          variant="light"
                          mt={23}
                          size={25}
                          opacity={0.9}
                          onClick={() => removeAllergy(index)}
                        >
                          <IconTrash size={"md"} />
                        </ActionIcon>
                        {index === knownAllergies?.length - 1 && (
                          <ActionIcon
                            color="yellow"
                            variant="light"
                            mt={23}
                            size={30}
                            onClick={addEmptyAllergy}
                          >
                            <IconPlus />
                          </ActionIcon>
                        )}
                      </Group>
                    </Group>
                  ))}

                  <Divider variant="dashed" />

                  {dietaries?.map((value, index) => (
                    <Group key={index} align="center" grow>
                      <Group>
                        <span style={{ marginTop: "20px" }}>{index + 1} .</span>
                        <TextInput
                          className={classes.myfont}
                          label="Dietary Restrictions"
                          size="md"
                          icon={
                            <img
                              src="/assets/images/building.png"
                              alt="address"
                            />
                          }
                          placeholder="Dietary Restrictions"
                          value={value}
                          onChange={(e) =>
                            handleChangeDietary(index, e?.target?.value)
                          }
                          //disabled={view}
                        />
                      </Group>
                      <Group>
                        <ActionIcon
                          color="red"
                          variant="light"
                          mt={23}
                          size={25}
                          opacity={0.9}
                          onClick={() => removeDietary(index)}
                        >
                          <IconTrash size={"md"} />
                        </ActionIcon>
                        {index === dietaries?.length - 1 && (
                          <ActionIcon
                            color="yellow"
                            variant="light"
                            mt={23}
                            size={30}
                            // ref={addFieldRef}
                            onClick={addEmptyDietary}
                          >
                            <IconPlus />
                          </ActionIcon>
                        )}
                      </Group>
                    </Group>
                  ))}

                  <Divider variant="dashed" />

                  <Radio.Group
                    style={{ marginBottom: "30px" }}
                    name="isKnownIllness"
                    label="Are there any known Illness?"
                    {...form.getInputProps("isKnownIllness")}
                    description="If 'Yes' Please tell Known Illness"
                    onChange={(value) => {
                      form?.setFieldValue("isKnownIllness", value);
                      setIsKnownIllness(value === "yes");
                    }}
                  >
                    <Group mt="xs">
                      <Radio color="yellow" value="yes" label="Yes" />
                      <Radio color="yellow" value="no" label="No" />
                    </Group>
                  </Radio.Group>
                </SimpleGrid>

                <Button
                  color="yellow"
                  sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalHealthEdit;
