import React from "react";
import { Button, Card, Text, createStyles } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  name: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 24,
    color: "white"
  },
  pos: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 16,
    color: "#232323"
  },
  mycard: {
    background: "#FFBA45",
    textAlign: "center"
  },
  profileBG1: {
    position: "absolute",
    bottom: 0,
    right: 0,
    objectFit: "cover"
  },
  profileBG2: {
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover"
  }
}));

const UserCard = (props) => {
  const { profile } = useSelector((state) => state?.profile);
  // console.log("User profile =>", profile)
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <Card className={classes.mycard} radius={10} shadow="sm">
      <div className={classes.profileBG2}>
        <img
          width="70px"
          src="/assets/images/profileCardBG2.png"
          alt="profileCardBG1"
        />
      </div>
      <div className={classes.profileBG1}>
        <img
          width="100px"
          src="/assets/images/profileCardBG1.png"
          alt="profileCardBG2"
        />
      </div>
      <img
        style={{
          zIndex: 9,
          width: 100,
          height: 100,
          borderRadius: "50%",
          marginTop: 40
        }}
        src={
          profile?.user?.photo
            ? profile?.user?.photo
            : "/assets/images/avatar.png"
        }
        alt=""
      />
      <Text className={classes.name}> {profile?.user?.name}</Text>
      <Text className={classes.pos} mt={8}>
        {profile?.user?.position}
      </Text>
      <Button
        onClick={() => navigate("/home/settings")}
        color="black"
        variant="default"
        mt={26}
        sx={{
          fontFamily: "Inter"
        }}
      >
        View Profile
      </Button>
    </Card>
  );
};

export default UserCard;
