import React, { useEffect, useRef, useState } from "react";
import useStyles from "./style";
import { useForm } from "@mantine/form";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Text, TextInput, Group, Button, Textarea } from "@mantine/core";
import Footer from "../LandingPage/Footer";
import Header from "../../components/Header/Header";
import StartedBanner from "../LandingPage/StartedBanner";
import { contactUs } from "../../services";
import Faqcomp from "../PricingCopm/Faq";
import HeaderMain from "../../components/Header/HeaderMain";
import { pageInfoAnalytics, TrackPageExit } from "../../config/mixpanelConfig";

const ContactUs = () => {
  const { classes } = useStyles();
  const [showHeaderMain, setShowHeaderMain] = useState(true);
  const featuresRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.scrollToFeatures && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowHeaderMain(false);
      } else {
        setShowHeaderMain(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      message: "",
      phone: "",
      type: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phone: (value) => (value ? null : "Required"),
      name: (value) => (value ? null : "Required"),
      message: (value) => (value ? null : "Required"),
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    pageInfoAnalytics("Contact Us Page Viewed", "Contact Us Page");
    TrackPageExit("Contact Us")
  }, []);

  const onFinish = async (values) => {
    try {
      await contactUs(values);
      swal({
        title: `Message sent`,
        text: "Message sent successfully",
        icon: "success",
        dangerMode: false,
      });

      form.reset();
    } catch (error) {
      swal({
        title: `Error`,
        text: "Message not sent",
        icon: "error",
        dangerMode: true,
      });
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <Box
        style={{
          opacity: showHeaderMain ? 1 : 0,
          visibility: showHeaderMain ? "visible" : "hidden",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <HeaderMain />
      </Box>
      <Box
        style={{
          top: 0,
          zIndex: 999,
          width: "100%",
          position: "fixed",
          opacity: showHeaderMain ? 0 : 1,
          visibility: showHeaderMain ? "hidden" : "visible",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
        }}
      >
        <Header />
      </Box>

      <Box className={classes.ContactUs}>
        <Box className={classes.contactContent}>
          <Box my={"lg"} >
            <Text className={classes.contactHeading}>Contact Us</Text>
            <Text className={classes.contectText}>
              Whether you have questions about our platform, need support,{" "}
              <br /> or want to learn more about how Ogiso can benefit your{" "}
              <br /> agency, our team is here to assist you.
            </Text>
            <Text className={classes.message}>
              Email : <strong> <a href="mailto:support@ogiso.io" className={classes.link}>support@ogiso.io</a></strong>
            </Text>
            <Text className={classes.message}>
              Phone : <strong><a href="tel:+17576000297" className={classes.link}>+1 757-600-0297</a></strong>
            </Text>

            <Box className={classes.contentSection}>
              <Box className={classes.sectionBox}>
                <Text className={classes.sectionHeading}>Customer Support</Text>
                <Text className={classes.sectionText}>
                  Our support team is available to help <br /> address any
                  concerns or queries you <br /> may have
                </Text>
              </Box>
              <Box className={classes.sectionBox}>
                <Text className={classes.sectionHeading}>
                  Feedback and Suggestions
                </Text>
                <Text className={classes.sectionText}>
                  We value your feedback and are continuously <br /> working to
                  improve Snappy. <br />
                  Your input is crucial in shaping the future of Ogiso.
                </Text>
              </Box>
              <Box className={classes.sectionBox}>
                <Text className={classes.sectionHeading}>Media Inquiries</Text>
                <Text className={classes.sectionText}>
                  For media-related questions or press inquiries, please contact
                  us at <br /> <strong>support@ogiso.io</strong>{" "}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box className={classes.formContent}>
            <Text className={classes.formtHeading}>Get In Touch</Text>
            <Text className={classes.formTitle}>You can reach us anytime</Text>
            <form onSubmit={form.onSubmit((values) => onFinish(values))}>
              <TextInput
                radius="sm"
                my={"lg"}
                placeholder="Full Name"
                {...form.getInputProps("name")}
              />
              <TextInput
                radius="sm"
                my={"lg"}
                placeholder="Email Address"
                {...form.getInputProps("email")}
              />
              <TextInput
                radius="sm"
                type="number"
                my={"lg"}
                placeholder="Phone Number"
                {...form.getInputProps("phone")}
              />
              <TextInput
                radius="sm"
                my={"lg"}
                placeholder={`What type of healthcare business?             (optional)`}
                {...form.getInputProps("type")}
              />
              <Textarea
                radius="sm"
                my={"lg"}
                className={classes.messageInput}
                placeholder="How can i help you"
                {...form.getInputProps("message")}
                minRows={4}
              />

              <Group position="center" width="100%" mt="md" my={"lg"}>
                <Button
                  color="yellow"
                  radius="sm"
                  className={classes.submitBTN}
                  width="100%"
                  type="submit"
                  c={"#000"}
                >
                  Submit
                </Button>
              </Group>
              <Text className={classes.termsCondition}>
                By contacting us, you agree to our{" "}
                <span
                  className={classes.hilightedtext}
                  onClick={() => {
                    navigate("/term-conditions");
                  }}
                >
                  Terms of service
                </span>{" "}
                and{" "}
                <span
                  className={classes.hilightedtext}
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </span>{" "}
              </Text>
            </form>
          </Box>
        </Box>
        <Faqcomp />
      </Box>
      <StartedBanner />
      <Footer />
    </>
  );
};

export default ContactUs;
