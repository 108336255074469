import { Pagination, createStyles } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));

const CustomPagination = ({
  data,
  itemsPerPage,
  currentPage,
  handlePageChange,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.pagination}>
      <Pagination
        total={Math.ceil(data / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        value={currentPage}
        onChange={handlePageChange}
        color="yellow"
      />
    </div>
  );
};

export default CustomPagination;
