import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  clientInsuranceModal: {
    "& .mantine-19pz3dh": {
      backgroundColor: "#fff5e3",
      paddingBottom: "10px",
      display: "block",
    },
    "& .mantine-1fholi4": {
      display: "none",
    },
  },
  flexParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  body:{
    height:"500px"
  },
  headerFlex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0px",
  },
  headerFlexImage: {
    width: "40px",
    height: "40px",
  },
  headerCollapseImage: {
    width: "25px",
    height: "25px",
  },
  headerCollapseImageButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  headerFlexTitle: {
    fontWeight: "bold",
    marginTop: "10px",
    marginLeft: "10px",
  },
  headerFlexEndButton: {
    color: "#979797",
    fontWeight: "500",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  gridParent: {
    paddingTop: "10px",
    paddingLeft: "55px",
    paddingRight: "55px",
  },
  box: {
    border: "1px solid #000000",
    width: "100%",
    borderRadius: "5px",
    paddingBottom: "12px",
    marginTop:"20px"
  },
  title: {
    marginTop: "0",
    marginBottom: "0",
    color: "#979797",
    fontWeight: "bold",
  },
  dataFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:768px)": {
      display: "block",
      textAlign: "center",
    },
  },
  dataName: {
    marginTop: "10px",
  },
  phoneImg: {
    width: "15px",
    height: "15px",
    marginRight: "5px",
    position: "relative",
    top: "3px",
  },
  number: {
    fontWeight: "bold",
  },
  contact: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:768px)": {
      display: "block",
      textAlign: "center",
    },
  },
  phoneNo: {
    marginRight: "15px",
    marginTop:"9px",
    "@media (max-width:768px)": {
      marginTop: "5px", 
      marginBottom:"5px"
    },
  },
  envelope: {
    marginRight: "7px",
    position: "relative",
    top: "2px",
  },
  emailParent:{
    width:"185px",
    textAlign:"right",
    marginTop:"9px"
  },
  noDataFound:{
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
    fontWeight:"normal",
  },
  noDataIcon:{
    marginLeft:"10px"
  }
}));

export default useStyles;
