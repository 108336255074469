import { Container } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import AddNewMedicationModal from "../../../components/AddNewMedicationModal";
import Topbar from "../../../components/Topbar";
import { getAllPatientsModal } from "../../../services/patient";
import MarLogTab from "./MarLogTab";
import MedicationLogTab from "./MedicationLogTab";
import MarTab from "./MarTab";
import MedicationListTab from "./MedicationListTab";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { getAllStaff } from "../../../services/staff";
import FilterBar from "./FilterBar";
import AddMedicationButton from "../../../components/AddMedicationButton/AddMedicationButton";
import { TrackPageView } from "../../../config/mixpanelConfig";

export const getColor = (key) => {
  const color = {
    Taken: { dark: "#8ed383", light: "rgba(29, 167, 6, 0.15)" },
    Missed: { dark: "#ff9999", light: "rgba(255, 0, 0, 0.15)" },
    Refused: { dark: "#c7daf5", light: "rgba(199, 218, 245, 0.25)" },
    upcoming: { dark: "#cbcbcb", light: "#cbcbcb" },
    "Unable to Take": { dark: "#ffff80", light: "rgba(255, 255, 0, 0.15)" },
    "Out of Medication": { dark: "#000", light: "#000" },
    PRN: { dark: "#bf80bf", light: "rgba(128, 0, 128, 0.15)" },
  };
  return color[key];
};

const Medication = ({ userId, tabName }) => {
  const { classes } = useStyles();
  // const [activeTab, setActiveTab] = useState(
  //   localStorage.getItem("tab") || "mar"
  // );
  const [activeTab, setActiveTab] = useState("medicationList");
  const [view, setView] = useState("calendar");
  const [opened, { open, close }] = useDisclosure(false);
  const { selectedClient } = useSelector((state) => state.mar);
  const [medicine, setMedicine] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (tabName) {
      setActiveTab(tabName);
    }
  }, [tabName]);
  useEffect(() => {
    TrackPageView("Medication")

  }, []);

  useEffect(() => {
    getAllPatientsModal();
    getAllStaff("withAdmin");
    return () => {
      localStorage.setItem("tab", "medicationList");
      localStorage.removeItem("selectedClient");
    };
  }, []);

  const handleTabChange = (val) => {
    setShouldRefresh(true);
    setTimeout(() => setActiveTab(val), 200);
    localStorage.setItem("tab", val);
  };

  // console.log("userId in Medication =>", userId);

  return (
    <Container fluid className={classes.content} px={20}>
      <AddNewMedicationModal
        opened={opened}
        onclose={(nType) => {
          close();
          setMedicine(null);
          if (isPreview) setIsPreview(false);
        }}
        data={medicine}
        title={"Chloroquine"}
        userId={userId}
        isPreview={isPreview}
      />

      {!userId ? (
        <>
          <Topbar title={""} />
          <div className={classes.mainHeading}>
            <h2>Medication</h2>
          </div>
        </>
      ) : (
        ""
      )}

      {!userId ? (
        <div className={classes.tabs}>
          <p
            id="medication-list-click"
            onClick={() => handleTabChange("medicationList")}
            className={activeTab === "medicationList" ? classes.active : ""}
          >
            Medication List
          </p>
          <p
            onClick={() => handleTabChange("medicationLog")}
            className={activeTab === "medicationLog" ? classes.active : ""}
          >
            Medication Log
          </p>
          <p
            id="mar-click"
            onClick={() => handleTabChange("mar")}
            className={activeTab === "mar" ? classes.active : ""}
          >
            MAR
          </p>
          <p
            id="mar-log"
            onClick={() => handleTabChange("marLog")}
            className={activeTab === "marLog" ? classes.active : ""}
          >
            MAR Log
          </p>
        </div>
      ) : (
        ""
      )}

      <div className={classes.bar} style={{ marginBottom: "10px" }}>
        <div className={classes.left}>
          {activeTab === "mar" && (
            <>
              <div
                className={`${classes.iconBar} ${
                  view === "list" ? classes.iconBarActive : ""
                }`}
                onClick={() => {
                  setShouldRefresh(false);
                  setTimeout(() => {
                    setView("list");
                  }, 200);
                }}
              >
                <img src={"/assets/images/icons8-menu-24 33.png"} />
              </div>
              <div
                className={`${classes.iconBar} ${
                  view === "calendar" ? classes.iconBarActive : ""
                }`}
                onClick={() => {
                  setActiveTab("mar");
                  setView("calendar");
                }}
              >
                <img src={"/assets/images/icons8-calendar-96 3.png"} />
              </div>
            </>
          )}
        </div>
        {/* <div className={classes.right}>
          <Button
            id="add-new-medication"
            leftIcon={<img src={"/assets/images/icons8-pills-64 1.png"} />}
            color="yellow"
            style={{ color: "black" }}
            onClick={() => open()}
            disabled={!selectedClient}
          >
            Add new Medication
          </Button>
        </div> */}

        <AddMedicationButton userId={userId} selectedClient={selectedClient} />
      </div>

      <FilterBar activeTab={activeTab} />

      {activeTab === "medicationList" && (
        <MedicationListTab
          userId={userId}
          clientId={selectedClient?.id}
          onEditClick={(data, isPreview) => {
            setMedicine(data);
            open();
            if (isPreview) setIsPreview(true);
          }}
        />
      )}
      {activeTab === "mar" && (
        <MarTab userId={userId} view={view} activeTab={activeTab} />
      )}
      {activeTab === "marLog" && (
        <MarLogTab
          userId={userId}
          activeTab={activeTab}
          refresh={shouldRefresh}
        />
      )}
      {activeTab === "medicationLog" && (
        <MedicationLogTab activeTab={activeTab} refresh={shouldRefresh} />
      )}
    </Container>
  );
};

export default Medication;
