import { createStyles } from "@mantine/core";

const bgColor = "rgba(242, 242, 242, 0.6)";

const useStyles = createStyles((theme) => ({
  fillMedicationModal: {
    "& .mantine-19pz3dh": {
      display: "none",
    },
    "& .mantine-Modal-body": {
      padding: 0,
    },
    "& .mantine-Paper-root": {
      overflow: "initial",
    },
  },
  wrapper: {
    width: "100%",
    padding: "30px 30px",
  },
  textInputBox: {
    width: "100%",
    // borderTop: "1px solid rgba(0,0,0,0.1)",
    // borderRight: "1px solid rgba(0,0,0,0.1)",
    // borderLeft: "1px solid rgba(0,0,0,0.1)",
    // padding: "5px 15px",
    borderRadius: "5px",
    // borderBottom: "5px solid rgba(255, 186, 69, 0.85)",
    // backgroundColor: bgColor,
    "& .mantine-InputWrapper-label": {
      fontSize: "16px",
      fontWeight: 700,
    },
    "& .mantine-Input-input": {
      backgroundColor: "transparent !important",
      fontSize: "18px",
    },
    "& input": {
      // backgroundColor: "transparent !important",
      border: "none",
      // paddingInline: 0,
    },
  },
  formRow: {
    width: "100%",
    display: "flex",
    gap: "30px",
    marginBottom: "20px",
    "@media (max-width:700px)": {
      flexDirection: "column",
    },
  },
  saveButton: {
    marginTop: "20px",
    borderRadius: "5px",
    background: "rgba(255, 186, 69, 0.85)",
    width: "100%",
    height: "40px",
    color: "#000",
    paddingInline: "10px",

    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "25px",

    "& img": {
      width: "25px",
      marginRight: "10px",
    },
    "&:hover": {
      background: "rgba(255, 186, 69, 0.85)",
    },
  },
  passwordInput: {
    "& input": {
      paddingLeft: "20px",
    },
    "& .mantine-Input-wrapper": {
      border: "1px solid rgb(230,230,230)",
      borderRadius: "12px",
    },
    "& label": {
      marginBottom: "5px",
    },
  },
}));

export default useStyles;
