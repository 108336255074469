import React from "react";

import { ScrollArea, Table, createStyles } from "@mantine/core";

import { useState } from "react";
import { useSelector } from "react-redux";
import FacilityTableRow from "../FacilityTableRow";
import RecordNotFound from "../RecordNotFound";
import TableLoader from "../TableLoader";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0
    }
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  }
}));

const FacilityTable = ({ facilities }) => {
  const { classes, cx } = useStyles();
  const { loading } = useSelector((state) => state.facility);
  const [scrolled, setScrolled] = useState(false);
  const rows = facilities?.map((row, i) => (
    <FacilityTableRow row={row} key={i} />
  ));

  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      sx={{ marginTop: "30px" }}
    >
      <Table
        withColumnBorders
        withBorder
        highlightOnHover
        enablestickyheader
        sx={{ fontFamily: "Inter" }}
        fontSize={"sm"}
        verticalSpacing={"md"}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th style={{ minWidth: "200px" }}>Facility Name</th>
            <th style={{ minWidth: "140px" }}>Facility Type</th>
            <th style={{ minWidth: "80px" }}>Location</th>
            <th style={{ minWidth: "140px" }}>Active</th>
            <th style={{ minWidth: "140px" }}></th>
          </tr>
        </thead>
        {loading ? (
          <tr>
            <td colSpan={1000}>
              <TableLoader />
            </td>
          </tr>
        ) : rows?.length ? (
          <tbody>{rows}</tbody>
        ) : (
          <tr>
            <td colSpan={1000}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  height: "100vh",
                  gap: "8rem"
                }}
              >
                <RecordNotFound />
                <p className={classes.notFoundText}>
                  You don&apos;t have any record, click on “Add New Facility”
                </p>
              </div>
            </td>
          </tr>
        )}
      </Table>
    </ScrollArea>
  );
};

export default FacilityTable;
