import {
  ActionIcon,
  Group,
  Switch,
  createStyles,
  rem,
  Modal,
  Text,
  Button,
  Flex
} from "@mantine/core";
import { IconEdit, IconEye, IconKey, IconTrash } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import swal from "sweetalert";
import StaffModal from "../../containers/StaffModal";
import { forgetPassword } from "../../services/auth";
import { editStaff, removeStaff } from "../../services/staff";

const useStyles = createStyles((theme) => ({
  StaffSwitchCell: {
    paddingTop: rem(12),
    paddingBottom: rem(12),
    paddingLeft: 20,
    fontFamily: "Inter",
    height: "100%"
  },
  StaffSwitchBtn: {
    height: "32px",
    width: "61px"
  },
  border: {
    borderLeft: "1px solid #F2F2F2"
  }
}));

const StaffTableRow = ({ row }) => {
  const { classes } = useStyles();
  const [view, setView] = useState(false);
  const [active, setActive] = useState(row.active);
  const [opened, { open, close }] = useDisclosure(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // functions
  const remove = async () => {
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"]
    });
    if (willDelete) removeStaff(row?.id);
  };

  const resetPassword = async () => {
    const email = await swal({
      title: `Reset Password`,
      text: "Do you want to email reset password link?",
      dangerMode: false,
      buttons: ["Cancel", "Send"]
    });
    if (email) {
      forgetPassword({ email: row?.email }, "reset");
    }
  };
  
  function handleRowClick(event) {
    setView(true);
    open();
  }

  const updateStatus = async (isChecked) => {
    setActive(isChecked);
    editStaff({ id: row?.id, active: isChecked }, false);
  };

  // Function to handle the switch toggle
  const handleSwitchToggle = () => {
    if (!active) {
      if (row?.isEmailVerified) updateStatus(true);
      else setConfirmationOpen(true);
    } else {
      updateStatus(false);
    }
  };

  const handleConfirmationSubmit = (forceActivate) => {
    setConfirmationOpen(false);
    updateStatus(forceActivate);
  };

  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={handleRowClick}>{row?.name || "-"}</td>
      <td onClick={handleRowClick}>{row.position || "-"}</td>
      <td onClick={handleRowClick}>{row?.email || "-"}</td>
      <td>
        <Switch
          size="md"
          color="yellow"
          checked={active}
          onChange={handleSwitchToggle}
          className={classes.StaffSwitchBtn}
        />
      </td>
      <td onClick={handleRowClick}>{row.role || "-"}</td>
      <td>
        <ActionIcon variant="light" onClick={resetPassword}>
          <IconKey />
        </ActionIcon>
      </td>
      <td>
        <Group>
          <StaffModal
            opened={opened}
            onclose={close}
            title={"Edit Staff"}
            data={row}
            view={view}
            setView={setView}
          />
          <ActionIcon
            color="yellow"
            variant="light"
            onClick={() => {
              setView(true);
              open();
            }}
          >
            <IconEye />
          </ActionIcon>
          <ActionIcon color="green" variant="light" onClick={open}>
            <IconEdit />
          </ActionIcon>
          <ActionIcon color="red" variant="light" onClick={remove}>
            <IconTrash />
          </ActionIcon>
        </Group>
      </td>
      <Modal
        size="sm"
        opened={confirmationOpen}
        title="Email not verified"
        overlayProps={{ blur: 3 }}
        zIndex={1000}
        onClose={() => setConfirmationOpen(false)}
      >
        <Text >Are you sure you want to activate staff?</Text>
        <Flex gap={10} mt={20} justify={"flex-end"}>
          <Button
            color="red"
            variant="light"
            onClick={() => setConfirmationOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="yellow"
            variant="filled"
            onClick={() => handleConfirmationSubmit(true)}
          >
            Force Activate
          </Button>
        </Flex>
      </Modal>
    </tr>
  );
};

export default StaffTableRow;
