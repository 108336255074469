import React from "react";
import { useState } from "react";
import { Center, Navbar } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LinksGroup } from "../NavbarLinksGroup";
import useStyles from "./style";
import { dateFormat2, isSubscriptionExpired, removeCookie } from "../../utils";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

const data = [
  {
    to: "dashboard",
    label: "Dashboard",
    imgsrc: "/assets/images/home.png",
  },
  {
    to: "facility",
    label: "Facility",
    imgsrc: "/assets/images/building2.png",
  },
  { to: "staff", label: "Staff", imgsrc: "/assets/images/people.png" },
  { to: "patient", label: "Client", imgsrc: "/assets/images/patient.png" },
  // {
  //   to: "supportPlan",
  //   label: "Support Plan",
  //   imgsrc: "/assets/images/patient.png",
  // },
  { to: "medication", label: "Medication", imgsrc: "/assets/images/note.png" },
  {
    to: "tasks",
    label: "Task",
    imgsrc: "/assets/images/icon-note-type.png",
  },
  {
    to: "note",
    label: "Note",
    imgsrc: "/assets/images/icon-note-type.png",
  },
  { to: "report", label: "Reports", imgsrc: "/assets/images/report.png" },
  // { to: "tickets", label: "Tickets", imgsrc: "/assets/images/report.png" },
];

export default function Nav() {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { profile } = useSelector((state) => state?.profile);

  const staffAdminNotSee = ["Staff"];
  const staffNotSee = ["Facility", "Staff"];

  const links =
    profile?.user?.type !== "staff"
      ? data?.map((item, i) => (
          <LinksGroup {...item} setIsOpen={setIsOpen} key={i} />
        ))
      : profile?.user?.type === "staff" && profile?.user?.role === "admin"
      ? data
          ?.filter((item) => !staffAdminNotSee?.includes(item?.label))
          ?.map((item, i) => (
            <LinksGroup {...item} setIsOpen={setIsOpen} key={i} />
          ))
      : data
          ?.filter((item) => !staffNotSee?.includes(item?.label))
          ?.map((item, i) => (
            <LinksGroup {...item} setIsOpen={setIsOpen} key={i} />
          ));
  const navigate = useNavigate();

  const location = useLocation();
  const isDashboardPage =
    location.pathname === "/home/dashboard" || location.pathname === "/home"
      ? true
      : false;

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Function to toggle the navbar visibility
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Navbar
        height={"100vh"}
        width={{ sm: 220, md: 250, lg: 260, xl: 280 }}
        p={20}
        className={classes.adminSidebar}
      >
        <Center className={classes.header}>
          <Link
            to={
              isSubscriptionExpired()
                ? "/home/settings?selectedTab=upgrade"
                : "/home/dashboard"
            }
            aria-disabled
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/images/logo2.png"
                alt="Logo"
                style={{ width: "50%" }}
              />
            </div>
          </Link>
        </Center>

        <Navbar.Section
          grow
          className="sidebaritem"
          // style={{ overflowY: "auto" }}
        >
          {links}
        </Navbar.Section>
        <div style={{ overflowY: "auto" }}>
          {isDashboardPage === true && profile?.user?.type !== "staff" && (
            <div className={classes.cartoonBox}>
              <div className={classes.leftText}>
                <div>
                  <p className={classes.titleAgency}>
                    {profile?.subscription?.packageId?.name}
                  </p>
                  <p className={classes.subscription}>Subscription</p>
                  <p className={classes.billingTitle}>Billing Cycle : </p>
                  <p className={classes.billingDate}>
                    {`${dateFormat2(profile?.subscription?.startedAt)} -
                    ${dateFormat2(profile?.subscription?.expiredAt)}`}
                  </p>
                </div>
                <button
                  onClick={() => navigate("/home/settings?selectedTab=upgrade")}
                >
                  Upgrade Plan
                </button>
              </div>
              <div className={classes.rightImg}>
                <img
                  width="100%"
                  src="/assets/images/cartoonSidebar.png"
                  alt="cartoon box"
                />
              </div>
              <div className={classes.bgIMG}>
                <img
                  width="290px"
                  src="/assets/images/cartoonBoxBgLayer.png"
                  alt="background layer"
                />
              </div>
            </div>
          )}

          <Navbar.Section className={classes.footer}>
            <Link
              onClick={() => {
                removeCookie("token");
                mixpanel.track("Logout");
                // generate new cookie with new distinct_id
                mixpanel.reset();
              }}
              className={classes.link}
              to={"/"}
            >
              <img
                src="/assets/images/logout.png"
                alt="logout"
                className={classes.linkIcon}
                stroke={1}
              />
              <span>Logout</span>
            </Link>
          </Navbar.Section>
        </div>
      </Navbar>

      {isOpen ? (
        <div className={classes?.customDrawerOpen}>
          <span onClick={toggleNavbar}>
            <img width={25} src="/assets/images/closeIcon.png" alt="close" />
          </span>
          <div className={classes.drawerContent}>
            <Navbar.Section grow className="sidebaritem">
              <Center className={classes.header}>
                <Link to={"/home"}>
                  <img
                    width={"50%"}
                    src="/assets/images/logo2.png"
                    alt="Logo"
                  />
                </Link>
              </Center>
              {links}
            </Navbar.Section>
            <Navbar.Section className={classes.footer}>
              <Link
                aria-label="logout button"
                className={classes.link}
                to={"/"}
                onClick={() => {
                  removeCookie("token");
                  mixpanel.track("Logout");
                  // generate new cookie with new distinct_id
                  mixpanel.reset();
                  navigate("/");
                }}
              >
                <img
                  src="/assets/images/logout.png"
                  alt="logout"
                  className={classes.linkIcon}
                  stroke={1}
                />
                <span role="button" aria-label="Logout">
                  Logout
                </span>
              </Link>
            </Navbar.Section>
          </div>
        </div>
      ) : (
        <div className={classes.customDrawer}>
          <div className={classes.drawerContent}></div>
        </div>
      )}
      <div className={classes.menuIconContainer}>
        <img
          src="/assets/images/menu.png"
          className={classes?.menu}
          onClick={toggleDrawer}
          alt="menu toggle"
        />
      </div>
    </div>
  );
}
