import { createStyles, getStylesRef, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: 17,
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    overflowY: "auto",
    minHeight: "90px"
  },
  adminSidebar: {
    height: "100%",
    position: "fixed",
    overflowY: "hidden",
    left: 0,
    "@media(max-width: 768px)": {
      display: "none"
    }
  },
  logo: {
    width: "150px !important",
    objectFit: "cover"
  },
  customDrawer: {
    width: 0,
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#f2f2f2",
    overflowX: "hidden",
    transition: "0.3s",
    paddingTop: "60px",
    zIndex: 1
  },

  customDrawerOpen: {
    boxSizing: "border-box",
    height: "100%",
    width: "320px !important",
    position: "fixed",
    top: 0,
    left: 0,
    // backgroundColor: "#FFBA45",
    backgroundColor: "#FFF",
    overflowX: "hidden",
    transition: "0.3s",
    padding: 30,
    paddingTop: "60px",
    zIndex: 999,
    boxShadow: "-1px 0 10px -2px #888"
  },
  menuIconContainer: {
    background: "#fff",
    zIndex: 1,
    padding: ".8rem",
    position: "fixed",
    // border: "1px solid #000",
    top: 0,
    left: 0,
    right: 0,
    width: "99vw",
    height: "40px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    display : "none",
    "@media(max-width: 768px)": {
      display: "block"
    }
  },
  menu: {
    display: "none",
    width: 35,
    height: 35,
    objectFit: "contain",
    "@media (max-width:768px)": {
      display: "block"
    }
  },

  drawerContent: {
    padding: 0
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: rem(20),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    paddingTop: rem(14),
    paddingBottom: rem(14),
    paddingLeft: rem(22),
    borderRadius: 12,
    fontWeight: 500,
    marginBottom: theme.spacing.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black
      }
    }
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: "#232323",
      color: "white",
      [`& .${getStylesRef("icon")}`]: {
        color: "white"
      }
    }
  },
  cartoonBox: {
    padding: "1.3rem .8rem",
    display: "flex",
    alignItems: "start",
    borderRadius: "20px",
    justifyContent: "space-between",
    background: "linear-gradient(180deg, #232322 0%, #1F1E1D 100%)",
    boxShadow: "0px 4px 16px 0px rgba(115, 115, 115, 0.06)",
    position: "relative",
    overflow: "hidden"
  },
  bgIMG: {
    position: "absolute",
    bottom: -3,
    left: 0,
    zIndex: 1,
    objectFit: "cover"
  },
  titleAgency: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  subscription: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  billingTitle: {
    color: "rgba(255, 255, 255, 0.50)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "138.3%"
  },
  billingDate: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "138.3%"
  },
  leftText: {
    "& button": {
      borderRadius: "8px",
      background: "#FFBA45",
      width: "126px",
      height: "42px",
      flexShrink: 0,
      color: "#FFF",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      cursor: "pointer",
      border: "none"
    },
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    zIndex: 3,
    "@media (max-width:1024px)": {
      display: "flex",
      flexDirection: "column",
      gap: "0"
    }
  },
  rightImg: {
    zIndex: 3
  }
}));

export default useStyles;
