import { createStyles } from "@mantine/core";

const bgColor = "rgba(242, 242, 242, 0.6)";

const useStyles = createStyles((theme) => ({
  addMedicationModal: {
    "& .mantine-19pz3dh": {
      display: "none",
    },
    "& .mantine-Modal-body": {
      padding: 0,
    },
    "& .mantine-Paper-root": {
      overflow: "initial",
    },
  },
  header: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "16px 16px 0px 0px",
    backgroundColor: "rgba(255, 186, 69, 0.85)",
    width: "100%",
    "& img": {
      width: "30px",
    },
  },
  formBox: {
    width: "100%",
    overflowY: "auto",
  },
  wrapper: {
    width: "100%",
    padding: "30px 100px",
    height: "69vh",
    // height: "calc(100vh - 300px)",
    "@media (max-width:900px)": {
      padding: "30px",
    },
  },
  headerImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginTop: "-20px",
    marginBottom: "30px",
    "& img": {
      width: "300px",
    },
  },

  topFieldsBox: {
    width: "100%",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    marginBottom: "20px",
    gap: "30px",
    "& .isPrescribed": {
      flex: 1,
    },
    "& .isNarcotic": {
      flex: 1,
      display: "flex",
      // alignItems: "center",
      gap: "20px",
      "& button": {
        height: "50px",
        width: "150px",
        color: "#FF0000",
        border: "2px solid #FF0000",
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "25px",
        fontSize: "20px",
        gap: "5px",
        "& img": {
          width: "30px",
          height: "30px",
        },
      },
      "@media (max-width:420px)": {
        flexDirection: "column",
      },
    },
    "& .mantine-1ejqehl": {
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
    },
    "& .mantine-1cu6yj8:checked": {
      background: "#FFBA45",
      borderColor: "#FFBA45",
    },
    "@media (max-width:800px)": {
      flexDirection: "column",
    },
  },

  formRow: {
    width: "100%",
    display: "flex",
    gap: "30px",
    marginBottom: "20px",
    "@media (max-width:700px)": {
      flexDirection: "column",
    },
  },

  medicationDosageBox: {
    position: "relative",
    // backgroundColor: bgColor,
    width: "100%",
    marginBottom: "20px",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "15px",
    "& input": {
      // backgroundColor: bgColor,
    },
    "& .mantine-Input-input": {
      // backgroundColor: bgColor,
    },
    "& > p": {
      position: "absolute",
      top: -10,
      left: 30,
      zIndex: 100,
      color: "#000",
      margin: 0,
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
    "& .dosageHeading": {
      position: "absolute",
      top: -12,
      left: 30,
      zIndex: 100,
      color: "#000",
      margin: 0,
      display: "inline-block",
    },
  },
  medicationDosageInner: {
    width: "100%",
    display: "flex",
    gap: "15px",
    width: "100%",
    padding: "5px 15px 10px 15px",
    borderRadius: "5px",
    // borderBottom: "5px solid rgba(255, 186, 69, 0.85)",
    "& > div": {
      width: "calc((100% - 30px) / 3)",
    },
    "@media (max-width:700px)": {
      flexDirection: "column",
    },
  },
  bottomFields: {
    position: "relative",
    backgroundColor: bgColor,
    width: "100%",
    marginBottom: "20px",
    "& input": {
      backgroundColor: bgColor,
    },
    "& .mantine-Input-input": {
      backgroundColor: bgColor,
    },
    "& > p": {
      margin: "0",
      color: "#000",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "25px",
      marginLeft: "30px",
    },
    "& .dosageHeading": {
      position: "absolute",
      top: -12,
      left: 30,
      zIndex: 100,
      color: "#000",
      margin: 0,
      display: "inline-block",
    },
  },
  bottomFieldsInner: {
    width: "100%",
    display: "flex",
    alignItems : "center",
    gap: "15px",
    width: "100%",
    padding: "5px 15px 10px 15px",
    borderRadius: "5px",
    // borderBottom: "5px solid rgba(255, 186, 69, 0.85)",
    "& > div": {
      width: "calc((100% - 30px) / 3)",
    },
    "@media (max-width:700px)": {
      flexDirection: "column",
    },
  },

  forLabel: {
    "& .mantine-InputWrapper-label": {
      marginTop: "10px",
    },
  },

  saveButton: {
    width: "100%",
    // position: "fixed",
    // bottom: 0,
    // left: 0,
    // marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
    backgroundColor: "#fff",
    borderTop: "1px solid rgba(255, 186, 69, 0.85)",
    zIndex: 1000,
    borderRadius: "0px 0px 15px 15px",
    "& button": {
      borderRadius: "5px",
      background: "rgba(255, 186, 69, 0.85)",
      // width: "110px",
      height: "40px",
      color: "#000",
      paddingInline: "10px",

      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",

      "& img": {
        width: "25px",
        marginRight: "10px",
      },
      "&:hover": {
        background: "rgba(255, 186, 69, 0.85)",
      },
    },
  },

  uploadAndDateBox: {
    width: "100%",
    display: "flex",
    gap: "80px",
    alignItems: "center",
    // justifyContent: "center",
  },

  dropzone: {
    "& .mantine-Dropzone-root": {
      background: "#F2F2F2",
      border: "none",
    },
    "& .mantine-Group-root": {
      gap: "0px",
      // height: "60px",
      minHeight: "initial !important",
    },
    width: "350px",
    height: "90px",
  },
  column: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    paddingLeft: "20px",
  },
  dragImage: {
    alignSelf: "center",
    width: "55px",
    height: "55px",
  },
  uploadText: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "25px",
    "& span": {
      color: "#FFBA45",
    },
  },

  datePicker: {
    width: "250px",
    "& .mantine-DatePickerInput-input": {
      border: "none",
      borderRadius: "0px !important",
      borderBottom: "2px solid #A8A8A8 !important",
    },
    "& label": {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  },

  fileNameBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    "& p": {
      fontSize: "14px",
      color: "#5C5C5C",
      fontWeight: 600,
    },
  },

  adminScheduleBox: {
    "& .label": {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#212529",
      wordBreak: "break-word",
      marginTop: "4px",
      textAlign: "center",
    },
    "& .mantine-Menu-dropdown": {
      padding: "1rem",
    },
    "& .heading": {
      display : "flex",
      justifyContent : "space-between",
      alignItems : "center"
    },
  },
  scheduleMenuBtnText: {
    marginTop: "10px",
    color: "#FFBA45",
    cursor: "pointer",
    textAlign: "center",
    margin: 0,
  },
  monthRepeatBox: {
    color: "black",
    fontSize : "14px",
    borderRadius : "15px",
    margin : "5px 0",
    background : "white",
    height : "30px",
    display : "flex",
    justifyContent : "center",
    alignItems : "center"
  },

  scheduleMenuBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "& h2": {
      fontSize: "14px",
      textAlign: "center",
      margin: 0,
    },
    "& .footer": {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        backgroundColor: "transparent",
      },
      "& button:nth-of-type(1)": {
        color: "black",
      },
      "& button:nth-of-type(2)": {
        color: "#FFBA45",
      },
    },
  },
  repeatEveryBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& > p": {
      color: "#979797",
      fontWeight: 400,
      margin: 0,
    },
    "& .mantine-NumberInput-control": {
      border: "transparent",
      color: "#FFBA45",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  },
  repeatOn: {
    "& > p": {
      color: "#979797",
      fontWeight: 400,
      margin: 0,
    },
    "& .dayCircleBox": {
      display: "flex",
      gap: "5px",
      marginTop: "10px",
      "& .dayCircle": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        cursor: "pointer",
        "& p": {
          fontSize: "14px",
          fontWeight: 400,
          margin: 0,
          lineHeight: 1,
        },
      },
      "& .dayCircleActive": {
        backgroundColor: "#FFBA45",
        "& p": {
          color: "#fff",
        },
      },
      "& .dayCircleInactive": {
        backgroundColor: "#D9D9D9",
        "& p": {
          color: "#000",
        },
      },
    },
  },
}));

export default useStyles;
