import React from "react";

import { Center, Grid, ScrollArea, Table, Text, createStyles, rem } from "@mantine/core";

import NoteTypeTableRow from "../NoteTypeTableRow";
import { useSelector } from "react-redux";
import TableLoader from "../TableLoader";
import RecordNotFound from "../RecordNotFound";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0
    }
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  }
}));

const NoteTypeTable = ({ data }) => {
  const { noteTypes, loading } = useSelector((state) => state.note);
  const [scrolled, setScrolled] = useState(false);
  const { classes, cx } = useStyles();
  const rows = noteTypes?.ntypes?.map((row, i) => (
    <NoteTypeTableRow row={row} key={i} />
  ));
  return (
    <>
      <ScrollArea
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        sx={{ marginTop: "30px" }}
      >
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            withColumnBorders
            withBorder
            highlightOnHover
            enableStickyHeader
            sx={{ fontFamily: "Inter" }}
            fontSize={"sm"}
            verticalSpacing={"md"}
          >
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th style={{ minWidth: "200px" }}>Note Type</th>
                <th style={{ minWidth: "140px" }}>Fields</th>
                <th style={{ minWidth: "140px" }}></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}
      </ScrollArea>
    </>
  );
};

export default NoteTypeTable;
