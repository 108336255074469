import React from "react";
import {
  Button,
  Group,
  Modal,
  SimpleGrid,
  Text,
  TextInput,
  createStyles
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { addFacilityType, editFacilityType } from "../../services/facility";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  typeInput: {
    borderTopColor: "#CBCBCB66",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    fontFamily: "Inter",
    paddingTop: 20
  }
}));
const FacilityModal = ({ opened, close, title, ...data }) => {
  const { addFacilityLoading } = useSelector((state) => state.facility);
  const { classes } = useStyles();
  const edit = data.data ? true : false;

  const form = useForm({
    initialValues: { type: edit ? data?.data?.type : "" },

    validate: {
      type: (value) => (value ? null : "Please enter a type")
    }
  });

  const onSave = (values) => {
    if (edit) {
      editFacilityType({
        name: values?.type,
        id: data?.data?.id
      });
      form?.reset();
    } else {
      addFacilityType({ name: values?.type });
    }
    form?.reset();
    close();
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={"md"}
      onClose={close}
      shadow="md"
      zIndex={1000}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {title}
          </Text>
        </Group>
      }
      centered
    >
      <form onSubmit={form.onSubmit((values) => onSave(values))}>
        <TextInput
          className={classes.typeInput}
          label="Facility Type Name"
          size="lg"
          icon={<img src="/assets/images/building.png" alt="home" />}
          placeholder="Enter Facility type name"
          {...form.getInputProps("type")}
        />
        <p></p>
        <SimpleGrid
          cols={2}
          sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
        >
          <Button
            color="yellow"
            sx={{ marginLeft: "auto", width: 110, fontFamily: "Inter" }}
            onClick={close}
            radius={8}
          >
            Cancel
          </Button>
          <Button
            color="yellow"
            radius={8}
            sx={{ marginRight: "auto", width: 110, fontFamily: "Inter" }}
            type="submit"
            loading={addFacilityLoading}
          >
            Save
          </Button>
        </SimpleGrid>
      </form>
    </Modal>
  );
};

export default FacilityModal;
