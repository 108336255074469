import React, { useState } from "react";
import { createStyles } from "@mantine/core";
import { getColor } from "..";
import moment from "moment";
import "../../../../components/Calendar/calendarSchedule.css";
import MedicationModal from "../../../../components/MedicationModal";
import { useDisclosure } from "@mantine/hooks";
import { firstLetterCap } from "../../../../utils";

const useStyles = createStyles((theme) => ({
  scheduleCard: {
    width: "100%",
    // backgroundColor: "#C7DAF5",
    cursor: "pointer",
    padding: "5px 5px",
    position: "relative",
    "& .remainingBox": {
      border: "1px solid #000",
      position: "absolute",
      right: 10,
      top: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: "5px",
      "& p": {
        textAlign: "center",
        margin: "0px",
      },
      "& p:nth-of-type(1)": {
        fontSize: "8px",
        fontWeight: 500,
        lineHeight: 1,
      },
      "& p:nth-of-type(2)": {
        fontSize: "6px",
        fontWeight: 400,
        lineHeight: 1,
        color: "#5C5C5C",
      },
    },
    "& p": {
      margin: "0px",
    },
    "& .top": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "5px",
      "& .div1 p:nth-of-type(1)": {
        fontSize: "8px",
        fontWeight: 600,
      },
      "& .div1": {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
      },
      "& .div1 p:nth-of-type(2)": {
        fontSize: "6px",
        fontWeight: 300,
      },
    },
    "& .center": {
      marginTop: "5px",
      "& p:nth-of-type(1)": {
        fontSize: "14px",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      "& p:nth-of-type(2)": {
        fontSize: "10px",
        fontWeight: 400,
      },
    },
    "& .bottom": {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& .bottomLeft": {
        display: "flex",
        gap: "5px",
        "& p": {
          fontSize: "10px",
          fontWeight: 600,
        },
      },
      "& .bottomRight": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& p:nth-of-type(1)": {
          fontSize: "8px",
        },
        "& p:nth-of-type(2)": {
          fontSize: "10px",
        },
      },
    },
    "& .upcoming": {
      width: "100%",
      display: "flex",
      gap: "5px",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#5C5C5C",
      },
    },
  },

  outOfMedication: {
    width: "100%",
    // backgroundColor: "#C7DAF5",
    cursor: "pointer",
    padding: "5px 5px",
    position: "relative",
    "& .remainingBox": {
      border: "1px solid #fff",
      position: "absolute",
      right: 10,
      top: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: "5px",
      "& p": {
        textAlign: "center",
        margin: "0px",
        color: "#fff",
      },
      "& p:nth-of-type(1)": {
        fontSize: "8px",
        fontWeight: 500,
        lineHeight: 1,
        color: "#fff",
      },
      "& p:nth-of-type(2)": {
        fontSize: "6px",
        fontWeight: 400,
        lineHeight: 1,
        // color: "#5C5C5C",
        color: "#fff",
        opacity: 0.5,
      },
    },
    "& p": {
      margin: "0px",
      color: "#fff",
    },
    "& .top": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "5px",
      "& .div1 p:nth-of-type(1)": {
        fontSize: "8px",
        fontWeight: 600,
        color: "#fff",
      },
      "& .div1": {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
      },
      "& .div1 p:nth-of-type(2)": {
        fontSize: "6px",
        fontWeight: 300,
        color: "#fff",
      },
    },
    "& .center": {
      marginTop: "5px",
      // "& p::first-letter": {
      //   textTransform: "capitalize",
      // },
      "& p:nth-of-type(1)": {
        fontSize: "14px",
        fontWeight: 500,
        wordBreak: "break-word",
        color: "#fff",
      },
      "& p:nth-of-type(2)": {
        fontSize: "10px",
        fontWeight: 400,
        color: "#fff",
      },
    },
    "& .bottom": {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& .bottomLeft": {
        display: "flex",
        gap: "5px",
        "& p": {
          fontSize: "10px",
          fontWeight: 600,
          color: "#fff",
        },
      },
      "& .bottomRight": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& p:nth-of-type(1)": {
          fontSize: "8px",
          color: "#fff",
        },
        "& p:nth-of-type(2)": {
          fontSize: "10px",
          color: "#fff",
        },
      },
    },
    "& .upcoming": {
      width: "100%",
      display: "flex",
      gap: "5px",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        fontSize: "12px",
        fontWeight: 600,
        // color: "#5C5C5C",
        color: "#fff",
      },
    },
  },
}));

const ScheduleCard = ({ date, prn, data, onOpen, calendar = "weekly" }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const _medicationType = data?.medicationType;
  const _outOfMedication = "Out of Medication";
  const _upcoming = "upcoming";

  const { classes } = useStyles();
  const compareDate = (date) => {
    var time1 = moment(date).startOf("day"); // Set time to the start of the day
    var today = moment().startOf("day");
    if (calendar !== "weekly" && _medicationType === _upcoming) {
      return -1;
    } else if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  return (
    <>
      <div
        id="mar-scheduled"
        onClick={() => {
          if (prn) return onOpen(data);

          // if (compareDate(data?.date) === 1) {
          //   setIsViewOpen(false);
          // } else {
          //   _medicationType === _upcoming || _medicationType === "Missed"
          //     ? setIsViewOpen(false)
          //     : setIsViewOpen(true);
          //   open();
          // }

          _medicationType === _upcoming || _medicationType === "Missed"
            ? setIsViewOpen(false)
            : setIsViewOpen(true);
          open();
        }}
        style={{
          backgroundColor: getColor(prn ? "PRN" : _medicationType)?.dark,
          cursor: "pointer",
        }}
        // className={`${
        //   _medicationType === _outOfMedication
        //     ? classes.outOfMedication
        //     : classes.scheduleCard
        // } ${compareDate(data?.date) === 1 ? "calendar-card" : ""}`}
        className={`${
          _medicationType === _outOfMedication
            ? classes.outOfMedication
            : classes.scheduleCard
        }`}
      >
        <div className="remainingBox">
          <p>
            {data?.medicineRemaining > 0
              ? data?.medicineRemaining || data?.form3?.medicineRemaining || "0"
              : "0"}
          </p>
          {/* <p>{data?.medicineRemaining || "-"}</p> */}
          <p>
            Of {data?.totalMedicine} <br /> Remaining
            {/* Of {data?.form3?.totalMedicine} <br /> Remaining */}
          </p>
        </div>
        <div className={"top"}>
          <div className="div1">
            <p
              style={{
                color: prn
                  ? "#fff"
                  : _medicationType === _outOfMedication
                  ? "#fff"
                  : "#000",
              }}
            >
              {data?.form3?.frequency || "-"}
            </p>
            {/* <p>{moment(data?.date).tz(timezone).format("hh:mm A")?.toUpperCase() || "-"}</p> */}
            <p>{data?.timeSlot || "-"}</p>
          </div>
          {data?.form3?.isNarcotic === "Yes" && (
            <div>
              <img
                src={"/assets/images/lock-pill.png"}
                style={{ width: "14px", height: "auto" }}
              />
            </div>
          )}
        </div>
        <div className="center">
          <p>{firstLetterCap(data?.form3?.medicationName) || "-"}</p>
          <p>
            {data?.form3?.dosage} {data?.form3?.unit}{" "}
            {data?.form3?.ingestionMode} {data?.form3?.dosageForm}
          </p>
        </div>
        <div className="bottom">
          {prn ? (
            <>
              <div className="bottomLeft">
                <p>{moment(data?.date).format("h:mm A")}</p>
              </div>
              {data?.staff && (
                <div className="bottomRight">
                  <p>Administered By</p>
                  <p>{data?.staff?.name}</p>
                </div>
              )}
            </>
          ) : (
            <>
              {_medicationType === _upcoming ? (
                <div className="upcoming">
                  <p>Upcoming</p>
                  <img
                    src={"/assets/images/alert.png"}
                    style={{ width: "15px", height: "15px" }}
                  />
                </div>
              ) : _medicationType === "Missed" ? (
                <div className="upcoming">
                  <p>Missed</p>
                  <img
                    src={"/assets/images/warning.png"}
                    style={{ width: "15px", height: "15px" }}
                  />
                </div>
              ) : (
                <>
                  <div className="bottomLeft">
                    <p>{moment(data?.createdAt).format("h:mm A")}</p>
                    {_medicationType === "Taken" && (
                      <img
                        src={"/assets/images/done-icon.png"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                    {_medicationType === "Missed" && (
                      <img
                        src={"/assets/images/warning.png"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                    {_medicationType === "Unable to Take" && (
                      <img
                        src={"/assets/images/do-not-disturb.png"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                    {_medicationType === "Refused" && (
                      <img
                        src={"/assets/images/unavailable.png"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                    {_medicationType === "Out of Medication" && (
                      <img
                        src={"/assets/images/close-white.png"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    )}
                  </div>
                  <div className="bottomRight">
                    <p>Administered By</p>
                    <p>{data?.staff?.name}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <MedicationModal
        opened={opened}
        onclose={() => {
          close();
          setIsViewOpen(false);
        }}
        isViewOpen={isViewOpen}
        setIsViewOpen={setIsViewOpen}
        data={data}
        date={date}
        futureDate={compareDate(data?.date)}
      />
    </>
  );
};

export default ScheduleCard;
