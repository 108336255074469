import React from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { createNoteType, editNoteType } from "../../services/note";
import CustomForm from "./CustomForm";
import FieldForm from "./FieldForm";
import useStyles from "./styles";
import CustomRow from "./CustomRow";
import { noteType_Client } from "../../utils";

const NoteTypeModal = ({ opened, close, title, view, ...data }) => {
  const { classes } = useStyles();
  const edit = data.data ? true : false;
  // refs
  const ref = useRef();
  const plusRef = useRef();
  const fieldRef = useRef();
  const addFieldRef = useRef();

  // states
  const [errorField, setError] = useState("");
  const [fieldError, setFieldError] = useState("");
  const [multiple, setMultiple] = useState(false);
  const [others, setOthers] = useState(false);
  const [fieldmultiple, setFiledMultiple] = useState(false);
  const [othersField, setOthersField] = useState(false);
  const [fields, setFields] = useState([]);
  const [customFields, setcustomFields] = useState(
    data?.data?.custom?.map((value, index) => ({
      ...value,
      fieldType: value?.fieldType?.id,
      order: index,
    })) || []
  );

  // selectors
  const { fieldType } = useSelector((state) => state.note);

  // forms

  const initialValues = {
    field: "",
    fieldType: "",
    priority: "",
    viewOnly: "no",
  };

  const validations = {
    field: (value) => (value ? null : "Please specify Field Name"),
    fieldType: (value) => (value ? null : "Select Field Type"),
    priority: (value) => (value ? null : "Select Priority"),
  };

  const initialValues1 = {
    field: "",
    fieldType: "",
    priority: "",
  };

  const validations1 = {
    field: (value) => (value ? null : "Please specify Field Name"),
    fieldType: (value) => (value ? null : "Select Field Type"),
    priority: (value) => (value ? null : "Select Priority"),
  };

  // changing initial values of form according to the need
  if (multiple) initialValues["options"] = [];
  if (multiple)
    validations["options"] = (value) =>
      value?.length ? null : "Select Options for this Field";
  if (others) initialValues["others"] = "";
  if (others)
    validations["others"] = (value) => {
      if (!value || value?.split(",")?.length === 0) {
        return "Please enter options: Eg: Option1,Option2,Option3";
      } else return null;
    };

  if (fieldmultiple) initialValues1["options"] = [];
  if (fieldmultiple)
    validations1["options"] = (value) =>
      value?.length ? null : "Select Options for this Field";
  if (othersField) initialValues1["others"] = "";
  if (othersField)
    validations1["others"] = (value) => {
      if (!value || value?.split(",")?.length === 0) {
        return "Please enter options: Eg: Option1,Option2,Option3";
      } else return null;
    };

  const fieldForm = useForm({
    initialValues: {},
    validate: validations,
  });
  const fieldForm1 = useForm({
    initialValues: {},
    validate: validations,
  });
  const form = useForm({
    initialValues: {
      name: edit ? data?.data?.name : "",
    },
    validate: {
      name: (value) => (value ? null : "Required"),
    },
  });

  // constants

  const arrayOfFieldOptions = [
    "Date",
    "Time",
    "Description",
    "Facility Type",
    "Staff Name",
    "Facility Name",
    noteType_Client,
    "Known Allergies",
    "Dietary Restrictions",
    "Known Illnes",
    "Known Wounds",
    "All PCP Info",
    "Insurance Details",
    "Medication Records",
    "Support Plan",
  ];

  const toInclude = [
    "Clients",
    "Facilities",
    "Staff",
    "date",
    "text area",
    "time",
    "Facility Types",
    "Medication Records",
    "Support Plan",
  ];

  const fieldTypeOptionsFieldForm = fieldType
    ?.filter((v) => toInclude.includes(v?.name))
    ?.map((val) => ({
      value: val?.id,
      label: val?.name?.toUpperCase(),
    }));

  useEffect(() => {
    if (opened && !edit) {
      // setFields([
      //   {
      //     fieldType: "657abec4eb8581608246bec2",
      //     field: "Staff Name",
      //     priority: true,
      //     viewOnly: false,
      //     id: 1
      //   }
      // ]);
    }
  }, [opened]);

  // functions

  const onSave = (values) => {
    let valid = form.validate();
    let isValid = Object.keys(valid?.errors)?.length === 0;

    if (!isValid)
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    plusRef?.current?.forceSubmit();
    addFieldRef?.current?.click();

    const mappedFields = customFields?.map(({ _id, id, ...value }) => {
      return {
        ...value,
        field_type: "Custom Field",
      };
    });
    const mappedFields1 = fields?.map(({ _id, id, ...value }) => ({
      ...value,
      field_type: "Field",
    }));
    let allCustoms = [...mappedFields, ...mappedFields1]?.map((field) => {
      let fieldTypeName = fieldType?.find(
        (val) => val?.id === field?.fieldType
      )?.name;
      let multipleFields = [
        "Staff",
        "Clients",
        "Facilities",
        "Facility Types",
      ];
      if (multipleFields?.includes(fieldTypeName)) {
        return {
          ...field,
          options: fieldTypeName,
        };
      } else {
        return { ...field };
      }
    });

    if (!errorField && !fieldError && isValid && allCustoms?.length > 0) {
      if (edit) {
        let multipleFields = [
          "Staff",
          "Clients",
          "Facilities",
          "Facility Types",
        ];
        let allCustoms = [...mappedFields, ...mappedFields1]?.map((field) => {
          let fieldTypeName = fieldType?.find(
            (val) => val?.id === field?.fieldType
          )?.name;

          if (multipleFields?.includes(fieldTypeName)) {
            return {
              ...field,
              options: fieldTypeName,
            };
          } else {
            return { ...field };
          }
        });
        let obj = {
          name: form.getInputProps("name")?.value,
          id: data.data?.id,
          custom: allCustoms,
        };

        // console.log({ obj });
        editNoteType(obj);
        close();
      } else {
        let obj = {
          name: form.getInputProps("name").value,
          custom: allCustoms,
        };
        createNoteType(obj);
        // console.log("obj", obj);
        close();

        fieldForm?.reset();
        fieldForm1?.reset();
        setcustomFields([]);
        setFields([]);
        fieldForm?.reset();
        fieldForm1?.reset();
      }
    }
  };

  // customFields
  const onSaveCustomField = (values) => {
    let allFields = [...customFields, ...(edit ? [] : fields)];
    let filtered = allFields?.find(
      (value) => value?.field?.toLowerCase() === values?.field?.toLowerCase()
    );

    if (!filtered) {
      customFields?.push({ ...values, id: Date.now() });
      setcustomFields(customFields);
      setError(undefined);
    } else {
      setError(`Field Name Already Exist`);
    }
  };

  const onSaveField = (values) => {
    setMultiple(false);
    setFiledMultiple(false);
    setOthers(false);
    setOthersField(false);
    let allFields = [...customFields, ...fields];
    let filtered = allFields?.find(
      (value) => value?.field?.toLowerCase() === values?.field?.toLowerCase()
    );

    let fieldTypeName = fieldType?.find(
      (val) => val?.id === values.fieldType
    )?.name;

    if (!filtered) {
      let tempValues = {
        ...values,
        priority: values?.priority == "Yes" ? true : false,
        viewOnly: values?.viewOnly == "Yes" ? true : false,
      };

      if (fieldTypeName?.toLowerCase() !== "multiple option")
        delete tempValues.options;
      fields?.push({ ...tempValues, id: Date.now() });
      setFields(fields);

      fieldForm1?.reset();

      fieldForm1?.setValues(initialValues1);
      setFieldError("");
    } else {
      setFieldError(`Field Name Already Exist`);
    }
  };

  // fields

  // onChange of field <form> First Item
  const onChangeFieldsFieldForm = (value) => {
    let obj = {
      "Staff Name": "Staff",
      "Facility Name": "Facilities",
      "Facility Type": "Facility Types",
      Description: "text area",
      Date: "date",
      Time: "time",
      "Known Allergies": "text area",
      "Dietary Restrictions": "text area",
      "Known Illnes": "text area",
      "Known Wounds": "text area",
      "All PCP Info": "text area",
      "Insurance Details": "text area",
      "Medication Records": "Medication Records",
      "Support Plan": "Support Plan",
    };
    obj[noteType_Client] = "Clients"
    let view = [
      "Known Allergies",
      "Dietary Restrictions",
      "Known Illnes",
      "Known Wounds",
      "All PCP Info",
      "Insurance Details",
    ];

    fieldForm1.setFieldValue(
      "fieldType",
      fieldType?.find((v) => v?.name === obj[value])?.id
    );
    fieldForm1.setFieldValue("viewOnly", view?.includes(value) ? "Yes" : "No");

    fieldForm1?.setFieldValue("field", value);
  };
  const handleChange = (value) => {
    if (
      value ===
      fieldType?.find(
        (val) => val?.id === value && val?.name === "Multiple Option"
      )?.id
    )
      setFiledMultiple(true);
    else {
      setFiledMultiple(false);
      setOthersField(false);
    }
    fieldForm1?.setFieldValue("fieldType", value);
  };
  const handleOptionfieldChange = (value) => {
    if (value?.toLowerCase() === "list") setOthers(true);
    else setOthers(false);
    fieldForm1?.setFieldValue("options", value);
  };
  const handleOtherInputfieldChange = (e) => {
    fieldForm1?.setFieldValue("list", e?.target?.value);
  };
  const handlePriorityFieldChange = (value) => {
    fieldForm1?.setFieldValue("priority", value);
  };
  const handleField1Edit1 = (value, key, element) => {
    let compareObject = {
      noteType_Client: "Clients",
      "Staff Name": "Staff",
      "Facility Name": "Facilities",
      "Facility Type": "Facility Types",
      Description: "text area",
      Date: "date",
      Time: "time",
      "Known Allergies": "text area",
      "Dietary Restrictions": "text area",
      "Known Illnes": "text area",
      "Known Wounds": "text area",
      "All PCP Info": "text area",
      "Insurance Details": "text area",
      "Medication Records": "Medication Records",
      "Support Plan": "Support Plan",
    };
    let view = [
      "Known Allergies",
      "Dietary Restrictions",
      "Known Illnes",
      "Known Wounds",
      "All PCP Info",
      "Insurance Details",
    ];

    let obj = { ...element };

    if (key === "fieldType") {
      let fieldTypeName = fieldType?.find((val) => val?.id === value)?.name;
      if (fieldTypeName?.toLowerCase() !== "multiple option")
        delete obj.options;
    }

    let updatedData = fields?.map((item) => {
      if (item?.id && item?.id === element?.id) {
        return {
          ...obj,
          [key]: value,
          fieldType: fieldType?.find((v) => v?.name === compareObject[value])
            ?.id,
          viewOnly: view?.includes(value) ? "Yes" : obj?.viewOnly,
        };
      } else if (item?._id && item?._id === element?._id) {
        return { ...obj, [key]: value };
      } else return item;
    });

    setFields(updatedData);
  };
  const removefieldItem = async (item) => {
    let id = item?.id || item?._id;

    if (!edit) {
      setFields((p) => p?.filter((value) => (value?._id || value?.id) !== id));
    } else {
      const willDelete = await swal({
        title: `Are you sure?`,
        text: "This field will be deleted form  all associated Notes",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Delete"],
      });
      if (willDelete) {
        setFields((p) =>
          p?.filter((value) => (value?._id || value?.id) !== id)
        );
      }
    }
  };

  // custom fields

  const isMultiple = (value) => {
    let fieldTypeName = fieldType?.find((val) => val?.id === value)?.name;
    return fieldTypeName?.toLowerCase() === "other";
  };

  const handleFieldsEdit = (value, key, element) => {
    let obj = { ...element };

    if (key === "fieldType") {
      let fieldTypeName = fieldType?.find((val) => val?.id === value)?.name;
      if (fieldTypeName?.toLowerCase() !== "multiple option")
        delete obj.options;
    }

    let updatedData = customFields?.map((item) => {
      if (item?.id && item?.id === element?.id) {
        return { ...obj, [key]: value };
      } else if (item?._id && item?._id === element?._id) {
        return { ...obj, [key]: value };
      } else return item;
    });

    setcustomFields(updatedData);
  };
  const removeItem = async (item) => {
    let id = item?.id || item?._id;

    if (!edit) {
      setcustomFields((p) =>
        p?.filter((value) => (value?._id || value?.id) !== id)
      );
    } else {
      const willDelete = await swal({
        title: `Are you sure?`,
        text: "This field will be deleted form  all associated Notes",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Delete"],
      });
      if (willDelete) {
        setcustomFields((p) =>
          p?.filter((value) => (value?._id || value?.id) !== id)
        );
      }
    }
  };
  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={"xl"}
      onClose={() => {
        close();
        form?.reset();
        edit == false && setcustomFields([]) && setFields([]);
        fieldForm?.reset();
      }}
      radius={15}
      shadow="md"
      zIndex={1000}
      overlayProps={{ blur: 3 }}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {view ? "View Note Type" : title}
          </Text>
        </Group>
      }
      centered
    >
      <form ref={ref} onSubmit={() => null}>
        <Card
          sx={{
            paddingTop: 20,
            borderTopWidth: 1,
            borderTopColor: "#CBCBCB66",
            borderTopStyle: "solid",
          }}
          shadow={"xs"}
        >
          <TextInput
            label={"Note Type Name"}
            size="md"
            icon={<img src="/assets/images/home2.png" alt="home" />}
            className={classes.myfont}
            placeholder="Ex: Medication Note"
            {...form.getInputProps("name")}
            disabled={view}
          />
        </Card>
      </form>

      {fields?.map((element, index) => {
        return (
          <Group key={index}>
            <Box sx={{ width: "90%" }} className={classes.bordered}>
              <Text style={{ fontWeight: 600 }}>Field</Text>
              <SimpleGrid
                cols={3}
                className={classes.mwd}
                sx={{
                  paddingTop: 20,
                }}
              >
                <Select
                  data={arrayOfFieldOptions}
                  size="md"
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="Field Name"
                  className={classes.myfont}
                  value={element?.field}
                  onChange={(value) => {
                    handleField1Edit1(value, "field", element);
                  }}
                  disabled={view || edit}
                />

                <Select
                  data={fieldTypeOptionsFieldForm}
                  size="md"
                  icon={<img src="/assets/images/building.png" alt="home" />}
                  placeholder="Field Type"
                  className={classes.myfont}
                  value={element?.fieldType}
                  onChange={(value) => {
                    handleField1Edit1(value, "fieldType", element);
                  }}
                  disabled={view || edit}
                />

                {isMultiple(element?.fieldType) && (
                  <Select
                    data={["Facilities", "Clients", "Staff", "Other"]}
                    size="md"
                    icon={<img src="/assets/images/building.png" alt="home" />}
                    placeholder="Field Type"
                    className={classes.myfont}
                    value={
                      !element?.options && element?.others
                        ? "Other"
                        : element?.options
                    }
                    onChange={(value) => {
                      let key =
                        !element?.options && element?.others
                          ? "Other"
                          : "options";
                      handleField1Edit1(value, key, element);
                    }}
                    disabled={view || edit}
                  />
                )}

                {"options" in element &&
                  element?.options != "Clients" &&
                  element?.options != "Facilities" &&
                  element?.options != "Staff" &&
                  element?.options != "Facility Types" && (
                    <TextInput
                      size="md"
                      icon={<img src="/assets/images/home2.png" alt="home" />}
                      placeholder="Other Options"
                      className={classes.myfont}
                      value={element?.others}
                      onChange={(e) => {
                        handleField1Edit1(e?.target?.value, "others", element);
                      }}
                      disabled={view || edit}
                    />
                  )}

                <Select
                  data={["Yes", "No"]}
                  size="md"
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="Field Priority"
                  className={classes.myfont}
                  value={element?.priority ? "Yes" : "No"}
                  onChange={(value) => {
                    handleField1Edit1(
                      value === "Yes" ? true : false,
                      "priority",
                      element
                    );
                  }}
                  disabled={view || edit}
                />
                <Select
                  data={["Yes", "No"]}
                  size="md"
                  icon={<img src="/assets/images/home2.png" alt="home" />}
                  placeholder="View Only"
                  className={classes.myfont}
                  value={element?.viewOnly ? "Yes" : "No"}
                  onChange={(value) => {
                    handleField1Edit1(
                      value === "Yes" ? true : false,
                      "viewOnly",
                      element
                    );
                  }}
                  disabled={view || edit}
                />
              </SimpleGrid>
            </Box>
            {!view && (
              <Box>
                <ActionIcon
                  color="red"
                  variant="light"
                  size={44}
                  mt={5}
                  sx={{ padding: 10 }}
                  onClick={() => removefieldItem(element)}
                >
                  <IconTrash size={"md"} />
                </ActionIcon>
              </Box>
            )}
          </Group>
        );
      })}

      <CustomRow
        view={view}
        edit={edit}
        classes={classes}
        data={customFields}
        functions={{
          removeItem,
          handleFieldsEdit,
        }}
      />

      <Divider my="sm" variant="dashed" mt={"20px"} />
      {/* fields form */}
      <FieldForm
        arrayOfFieldOptions={arrayOfFieldOptions}
        fieldError={fieldError}
        fieldForm1={fieldForm1}
        fieldRef={fieldRef}
        fieldmultiple={fieldmultiple}
        othersField={othersField}
        onChanges={{
          handleChange,
          handleOptionfieldChange,
          handleOtherInputfieldChange,
          handlePriorityFieldChange,
          onChangeFieldsFieldForm,
        }}
        onSaveField={onSaveField}
        view={view}
        addFieldRef={addFieldRef}
        classes={classes}
        setFieldError={setFieldError}
        fieldTypeOptionsFieldForm={fieldTypeOptionsFieldForm}
      />

      {/* custom field Form */}
      <CustomForm
        classes={classes}
        errorField={errorField}
        othersField={others}
        setErrorField={setError}
        view={view}
        ref={plusRef}
        onSubmit={onSaveCustomField}
      />

      {view ? (
        <div style={{ height: "30px" }} />
      ) : (
        <SimpleGrid
          cols={2}
          sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
        >
          <Button
            color="yellow"
            sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            color="yellow"
            sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
            onClick={onSave}
          >
            Save
          </Button>
        </SimpleGrid>
      )}
    </Modal>
  );
};

export default NoteTypeModal;
