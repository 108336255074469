import React from "react";
import {
  Modal,
  createStyles,
} from "@mantine/core";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  camera: {
    position: "absolute",
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  drop: {
    display: "none",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },
  dropImage: {
    img: {
      borderRadius: "50%",
    },
  },
  myfont: {
    fontFamily: "Inter",
    // marginTop: 20,
    overflow: "visible",
  },
  mycommmentfont: {
    fontFamily: "Inter",
    fontSize: "none !important",
    // marginTop: 20,
    overflow: "visible",
    "& .mantine-Textarea-label": {
      fontSize: "1rem !important",
    },
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    "& .mantine-Modal-content": {
      height: "1000px !important",
    },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-body" : {
      overflow:  "auto",
      maxHeight : "90% !important",
    },
    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const OpenFileModal = ({ opened, onclose, title, data }) => {
  console.log("🚀 ~ OpenFileModal ~ opened, onclose:", opened, onclose)
  const { classes } = useStyles();

  return (
    <>
      <div key={data?._id} style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          <Modal
            className={classes.mymodal}
            opened={opened}
            size={"xl"}
            onClose={() => {
              onclose();
            }}
            shadow="sm"
            overlayProps={{ blur: 3 }}
            radius={"xs"}
            zIndex={1000}
            centered
          >
            <DocViewer
              style={{ width: "100%", minHeight: 700 }}
              //   config={{
              //     header: { disableHeader: true },
              //   }}
              key={data?.id}
              documents={[
                {
                  uri: data?.fileUrl,
                  fileType:
                    data?.fileUrl?.split(".")[
                      data?.fileUrl?.split(".")?.length - 1
                    ],
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default OpenFileModal;
