import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    minHeight: '100vh',
    margin: '0',
    "@media (max-width :40em)": {
      maxWidth: '100%',
      display: 'block',
    },
  },
  left_pane: {
    width: "50%",
    background: "#FFBA45",
    "@media (max-width :40em)": {
      display: 'none'
    },
  },
  right_pane: {
    "@media (max-width :40em)": {
      maxWidth: '100%',
    },
    padding: 0
  },
  welcome: {
    position: "relative",
    top: "5.5%",
    left: "5.5%",
    maxWidth: "80%",
  },

  logo_img: {
    width: "21.57%",
    aspectRatio: 3.7,
    "@media (max-width :80em)": {
      width: "31.57%",
    },
  },
  logo_img2: {
    width: "40%",
    aspectRatio: 3.7,
    "@media (max-width :80em)": {
      width: "30%",
      height: '30%',
    },
    "@media (max-width :40em)": {
      display: 'inline',
    },
    display: "none",
  },
  glass_man: {
    marginTop: "9.4%",
    marginLeft: "8.95%",
    width: "59.2%",
    aspectRatio: 0.88,
  },
  credential: {
    "@media (max-width :80em)": {
      position: "absolute",
      top: "50%",
    },
    position: "absolute",
    top: "30%",
    left: "26.09%",
    width: "19%",
    aspectRatio: "0.8693",
  },
  myform: {
    maxWidth: "70%",
    margin: "auto",
    padding: '3rem 0'
    // position: "relative",
    // top: "10%",
  },
  rememberbox: {
    marginTop: rem(15),
    marginBottom: rem(15),
  },
  forgot: {
    marginLeft: "auto",
    color: "#FFBA45",
    textDecoration: "underline",
  },
  signup: {
    color: "#FFBA45",
    textDecoration: "underline",
  },
  toptitle: {
    position: "relative",
    top: "5.5%",
    right: "5.5%",
    textAlign: "right",
    color: '#A8A8A8',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  title: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(24),
    fontWeight: 700,
    fontFamily: "Inter",
  },
  login_btn: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    marginTop: "2rem",
    width: rem(296),
    height: rem(50),
    fontSize: rem(22),
  },
  login_btn_google: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    "@media (max-width :80em)": {
      width: "100%",
      fontSize: '1rem'
    },
    width: rem(296),
    height: rem(50),
    fontSize: rem(22),
    marginTop: rem(20),
    color: "grey",
  },
}));

export default useStyles;
