import { Box, Button, Text } from "@mantine/core";
import React from "react";
import useStyles from "./style";
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonials = () => {
  const { classes } = useStyles();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const { classes } = useStyles();
    return (
      <button
        onClick={() => onClick()}
        className={`${classes.testimonialCarouselArrow} ${classes.leftArrow}`}
      >
        <PiCaretLeft size={35} />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const { classes } = useStyles();
    return (
      <button
        onClick={() => onClick()}
        className={`${classes.testimonialCarouselArrow} ${classes.rightArrow}`}
      >
        <PiCaretRight size={35} />
      </button>
    );
  };

  return (
    <Box className={classes.InlinePadding}>
      <Box className={classes.features}>
        <Button className={classes.Testimonials}>Testimonials</Button>
      </Box>
      <Carousel
        ssr={true}
        infinite={true}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        // arrows={false}
        // showDots={true}
        slidesToSlide={1}
        autoPlaySpeed={12000}
        pauseOnHover={false}
        keyBoardControl={true}
        responsive={responsive}
        transitionDuration={1000}
        containerClass="carousel-container"
        customTransition="transform 500ms ease-in-out 0s"
        className={classes.CarouselContainer}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        dotListClass={classes.testimonialDotListStyle}
        itemClass="carousel-item-padding-40-px"
        // showDots={isLabptopScreen ? true : false}
        // removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        <Box className={classes.TestimonialsContainer}>
          <Box className={classes.testimonialsBox}>
            <img
              src={"assets/images/OurLovingAbode.jfif"}
              alt="testimonialsImg"
              className={classes.testimonialsImg}
            />
            <Box className={classes.testimonialsCard}>
              <Text className={classes.companyName}>Our Loving Abode</Text>
              <Text className={classes.companyType}>(IDD Group home)</Text>
              <Text className={classes.message}>
                Ogiso has helped me to manage medications and notes virtually
                without having to be on site and without having to shuffle
                through papers. It’s so easy to learn and very user friendly. My
                staff and I absolutely love it. I had my first annual inspection
                recently and my licensing specialist was so impressed with how
                organized everything was. It made the review process go so much
                smoother.
              </Text>
              <Box className={classes.customerInfo}>
                <Text className={classes.customerName}>Shaniqua</Text>
                <Text className={classes.position}>Director</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.TestimonialsContainer}>
          <Box className={classes.testimonialsBox}>
            <img
              src={"assets/images/LumenChristiHomeofVirginia.jfif"}
              alt="testimonialsImg"
              className={classes.testimonialsImg}
            />
            <Box
              className={`${classes.testimonialsCard} ${classes.testimonialsCardBlack}`}
            >
              <Text className={classes.companyName}>
                Lumen Christi Home of Virginia{" "}
              </Text>
              <Text className={classes.companyType}>(IDD Group home)</Text>
              <Text className={classes.message}>
                This platform has been God sent in many ways for my business. As
                someone who works on documentation daily and requires accuracy,
                both me and my staff enjoy how user friendly this application
                is. It saves us so much time, and is very affordable.
              </Text>
              <Box className={classes.customerInfo}>
                <Text className={classes.customerName}>Christiana</Text>
                <Text className={classes.position}>Owner</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.TestimonialsContainer}>
          <Box className={classes.testimonialsBox}>
            <img
              src={"assets/images/FountainviewAssistedLiving.jpg"}
              alt="testimonialsImg"
              className={classes.testimonialsImg}
            />
            <Box className={classes.testimonialsCard}>
              <Text className={classes.companyName}>
                Fountainview Assisted Living{" "}
              </Text>
              <Text className={classes.companyType}>(Assisted Living)</Text>
              <Text className={classes.message}>
                Ogiso has completely changed the way I manage our care home. The
                ease of use has helped us not only streamline our operation, but
                also helps us stay compliant. I truly beleive that this is one
                of the monst user friendly platforms on the market, and their
                customer support is top notch.
              </Text>
              <Box className={classes.customerInfo}>
                <Text className={classes.customerName}>Kizito</Text>
                <Text className={classes.position}>Manager</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Carousel>
    </Box>
  );
};

export default Testimonials;
