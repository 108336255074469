import React from "react";
import {
  Box,
  Button,
  Center,
  Group,
  Image,
  Loader,
  Modal,
  PasswordInput,
  Radio,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  createStyles,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCamera } from "@tabler/icons-react";
import { updateProfilePhoto } from "../../services/profile";
import {
  EditclientProfile,
  getSinglePatients,
  updateclientprofile,
} from "../../services/patient";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { decrypt, encrypt } from "../../utils";
//import moment from "moment";
import * as moment from "moment-timezone";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  camera: {
    position: "absolute",
    // position:'relative',
    // top:'28%',
    // right:'5%',
    marginTop: 75,
    marginLeft: 75,
    background: "#FFBA45",
    borderRadius: "50%",
    padding: 5,
    [`&:hover`]: {
      cursor: "pointer",
    },
  },
  drop: {
    display: "none",
    "& .mantine-Image-image": {
      border: "2px solid green",
    },
  },
  dropImage: {
    img: {
      borderRadius: "50%",
    },
  },
  myfont: {
    fontFamily: "Inter",
    // marginTop: 20,
    overflow: "visible",
  },
  mycommmentfont: {
    fontFamily: "Inter",
    fontSize: "none !important",
    // marginTop: 20,
    overflow: "visible",
    "& .mantine-Textarea-label": {
      fontSize: "1rem !important",
    },
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      // overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const EditPersonalInfo = ({
  opened,
  onclose,
  title,
  form1,
  setClientDetails,
  clientDetails,
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const { classes } = useStyles();
  const openRef = useRef();
  // console.log('openref',openRef.current);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { profile, saveProfileLoading } = useSelector(
    (state) => state?.profile
  );

  //   console.log('profile',profile);
  const location = useLocation();
  const id = location.state;
  // console.log("form1", form1);

  const genders = ["Male", "Female", "Other"];
  const form = useForm({
    initialValues: {
      name: form1?.name || "",

      gender: "",
      dob: null,
      dob: new Date(),
      //dob: moment().format('MM/DD/YYYY'),

      previousAddress: "",
      admissionDate: new Date(),
      // admissionDate:moment().tz("America/Los_Angeles").format('MM/DD/YYYY'),
      socialSecurityNumber: "",
      summary: "",
    },

    // "MM/DD/YYYY"
  });

  const onChangePhoto = async (files) => {
    setLoading(true);
    // console.log('files',files);
    if (files?.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0]);
      await updateclientprofile(formData, id);
      const data = await getSinglePatients(id);
      setClientDetails(data);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //     console.log('image url', image)
  // },[image])

  const prevoiusdata = {
    facility: form1?.facility || "",

    contagious: form1?.contagious,
    lastFourDigits: form1?.lastFourDigits || "",
    levelofcare: form1?.levelofcare || "",
    patientWounds: form1?.patientWounds || "",
    profileimage: form1?.profileimage || "",
    isKnownIllness: form1?.isKnownIllness || [],
    //isKnownIllness: [],
    state: form1?.state || "",
    knownAllergies: form1?.knownAllergies || [],

    knownIllness: form1?.knownIllness == "undefined" ? [] : form1?.knownIllness?.length > 0 && form1?.knownIllness?.[0]?.illness ? form1?.knownIllness?.map(item => item.illness) : form1?.knownIllness,
    dietaries: form1?.dietaries || [],

    // knownAllergies:  [],
    // knownIllness: [],
    // dietaries:  [],
    zipCode: form1?.zipCode || "",
  };

  useEffect(() => {
    // console.log("out")

    if (form1?.dob || form1?.name) {
      // console.log("in")

      form.setValues({
        //...form1,
        name: form1?.name,
        dob: new Date(form1?.dob),
        previousAddress: form1?.previousAddress,
        admissionDate: new Date(form1?.admissionDate),
        gender: form1?.gender,

        socialSecurityNumber: decrypt(form1?.socialSecurityNumber),
        summary: form1?.summary,
      });
    }
  }, [form1]);

  const onSave = async (values) => {
    try {
      let payload = {
        ...values,
        ...prevoiusdata,
        id,
        isForm1: true,
      };
      await EditclientProfile(payload);
      //await EditclientProfile({ form1: formData, clientId: id });

      const data = await getSinglePatients(id);
      setClientDetails(data);

      onclose();
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  const resetForm = () => {
    form?.reset();
    onclose();
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          {false ? (
            // <TableLoader/>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                bottom: "11%",
                left: "7%",
                //padding: "100px 0 0 0",
              }}
            >
              <Loader color="yellow" size={"md"} />
            </Box>
          ) : (
            <Modal
              className={classes.mymodal}
              opened={opened}
              size={"xl"}
              onClose={() => {
                //form?.reset();
                onclose();
              }}
              shadow="md"
              overlayProps={{ blur: 3 }}
              radius={"lg"}
              zIndex={1000}
              title={
                <Group>
                  <img src="/assets/images/addfac.png" alt="" />
                  <Text
                    sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}
                  >
                    {title}
                  </Text>
                </Group>
              }
              centered
            >
              <form onSubmit={form?.onSubmit((values) => onSave(values))}>
                <SimpleGrid
                  cols={isMobileScreen ? 1 : 2}
                  className={classes.mwd}
                  sx={{
                    marginTop: 10,
                    paddingBottom: 30,
                  }}
                >
                  <Center>
                    <Dropzone
                      onDrop={(files) => onChangePhoto(files)}
                      className={classes.drop}
                      openRef={openRef}
                      // accept={MIME_TYPES.jpeg || MIME_TYPES.png}
                      accept={{
                        "image/*": [],
                      }}
                    ></Dropzone>

                    {loading ? (
                      <Loader
                        color="yellow"
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      />
                    ) : (
                      <>
                        <Image
                          width={111}
                          height={111}
                          src={
                            clientDetails?.imageurl
                              ? decrypt(clientDetails?.imageurl)
                              : "/assets/images/personal2.png"
                          }
                          alt=""
                          //width={20%}
                          //height={111}
                          onClick={() => openRef.current?.()}
                          className={classes.dropImage}
                          //{...form.getInputProps("imageurl")}
                        />
                      </>
                    )}
                    <IconCamera
                      color="white"
                      className={classes.camera}
                      onClick={() => openRef.current?.()}
                    />
                  </Center>

                  <TextInput
                    className={classes.myfont}
                    //value={name}
                    //value={form1?.name}
                    //value={form?.values?.name}
                    label="Full Name *"
                    size="md"
                    icon={<img src="/assets/images/profile.png" alt="home" />}
                    placeholder="Ex: Valerie Liberty"
                    //onChange={(event) => setForm({ ...form, values: { ...form?.values, name: event.target.value } })}
                    {...form.getInputProps("name")}
                    //disabled={view}
                  />

                  <DateInput
                    maxDate={new Date()}
                    // value={new Date()}
                    className={classes.myfont}
                    label="Admission Date*"
                    valueFormat="MM/DD/YYYY"
                    size="md"
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="Ex: 04/02/1993"
                    {...form.getInputProps("admissionDate")}
                    //disabled={view}
                  />
                  <DateInput
                    // maxDate={new Date()}
                    // value={new Date()}
                    className={classes.myfont}
                    label="Date of birth *"
                    valueFormat="MM/DD/YYYY"
                    size="md"
                    icon={<img src="/assets/images/home2.png" alt="home" />}
                    placeholder="Ex: 04/02/1993"
                    {...form.getInputProps("dob")}
                    //disabled={view}
                  />

                  <TextInput
                    className={classes.myfont}
                    label="Previous Address"
                    size="md"
                    icon={
                      <img src="/assets/images/location.png" alt="address" />
                    }
                    placeholder="Ex: A-34 Carry St."
                    {...form.getInputProps("previousAddress")}
                    //disabled={view}
                  />

                  <PasswordInput
                    className={classes.myfont}
                    label="Social Security Number"
                    size="md"
                    autoComplete="new-password"
                    icon={<img src="/assets/images/lock.png" alt="address" />}
                    placeholder="Ex: AAA-GG-SSSS"
                    {...form.getInputProps("socialSecurityNumber")}
                  />
                  <Select
                    searchable
                    label="Gender"
                    data={genders}
                    size="md"
                    placeholder="Choose Gender"
                    {...form.getInputProps("gender")}
                    //disabled={view}
                  />
                </SimpleGrid>

                <Textarea
                  label="Diagnosis Summary"
                  //value={state?.comments}
                  //key={index}
                  radius="md"
                  placeholder={`Enter Summary here`}
                  className={`${classes.mycommmentfont} ${classes.descriptionText}`}
                  style={{
                    //height: "200px !important",
                    marginTop: "0px",
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "1rem",
                  }}
                  //{...form.getInputProps()}
                  {...form.getInputProps("summary")}
                  //disabled={view || (viewOnly ? true : false)}
                />
                <Button
                  color="yellow"
                  sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPersonalInfo;
