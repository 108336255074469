import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    // minHeight: "100vh",
    margin: "0",
    "@media (max-width :40em)": {
      maxWidth: "100%",
      display: "block",
    },
    // background: "#",
  },
  left_pane: {
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: "0px",
    justifyContent: 'space-between',
    // height: "100%",
    // background: "#000",
    "@media (max-width :40em)": {
      display: "none",
    },
  },
  leftSideTitle: {
    " p": {
      fontFamily: "Inter",
      fontSize: "40px",
      fontWeight: "500",
      lineHeight: "66px",
      textAlign: "left",
      color: "#000",
      "@media (max-width : 800px)": {
        fontSize: "30px",
      },
    },
  },
  right_pane: {
    // background: 'red',
    borderTopLeftRadius: "40px",
    borderBottomLeftRadius: "40px",
    // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 1.25)",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width :40em)": {
      maxWidth: "100%",
      borderTopLeftRadius: "unset",
      borderBottomLeftRadius: "unset",
      padding: "3rem 1rem",
    },
    padding: 0,
  },
  textInputBox: {
    width: "100%",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.1)",
    // padding: "5px 0",
    borderRadius: "5px",
    position: "relative",
    "& textarea[disabled]": {
      backgroundColor: "#fff !important",
    },
    "& .mantine-Textarea-input": {
      border: "none",
      paddingInline: "0",
    },
    "& .mantine-TextInput-input":{
      border: "none"
    },
    "& .label": {
      position: "absolute",
      top: -10,
      left: 15,
      zIndex: 100,
      margin: 0,
      color: "#000",
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
  },
  titleText: {
    fontFamily: "Inter",
    fontSize: "55px",
    fontWeight: "500",
    lineHeight: "66px",
    textAlign: "left",
    color: "#000",
  },
  welcome: {
    maxWidth: "80%",
    marginLeft: "2rem",
    marginTop: "2rem",
  },
  authImg: {
    maxWidth: "100%",
    height: "auto",
  },
  logo: {
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important",
    },
    "@media (max-width:768px)": {
      marginLeft: 30,
    },
    "@media (max-width:330px)": {
      marginLeft: 10,
    },
  },

  logo_img2: {
    width: "40%",
    aspectRatio: 3.7,
    "@media (max-width :80em)": {
      width: "30%",
      height: "30%",
    },
    "@media (max-width :40em)": {
      display: "inline",
    },
    display: "none",
  },
  glass_man: {
    marginTop: "9.4%",
    marginLeft: "8.95%",
    width: "59.2%",
    aspectRatio: 0.88,
  },
  credential: {
    "@media (max-width :65em)": {
      position: "absolute",
      top: "250px",
    },
    "@media (max-width :50em)": {
      position: "absolute",
      top: "350px",
    },
    position: "absolute",
    top: "230px",
    left: "26.09%",
    width: "19%",
    aspectRatio: "0.8693",
  },
  myform: {
    maxWidth: "80%",
    margin: "auto",
    // padding: "3rem 0",
    // position: "relative",
    // top: "10%",
  },
  rememberbox: {
    marginTop: rem(15),
    // marginBottom: rem(15)
  },
  rememberme: {
    "& .mantine-Checkbox-label": {
      fontFamily: "Inter",
      fontSize: "16px !important",
      fontWeight: "700 !important",
    },
    "& .mantine-Checkbox-input": {
      border: "3px solid #000000 !important",
    },
  },
  forgot: {
    marginLeft: "auto",
    color: "#000000",
    textDecoration: "none",
  },
  signup: {
    color: "#00000",
    textDecoration: "none",
  },
  toptitle: {
    // position: "relative",
    // top: "5.5%",
    // right: "5.5%",
    textAlign: "center",
    color: "#00000",
    fontFamily: "Inter",
    margin: "1rem auto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  
  title: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(34),
    fontWeight: 700,
    fontFamily: "Inter",
    "@media (max-width :600px)": {
      fontSize: rem(24),
    },
  },
  login_btn: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    "@media (max-width :800px)": {
      width: "100%",
    },
    background: "#000 ",
    marginTop: "2rem",
    width: "100%",
    height: rem(50),
    fontFamily: "Inter",
    fontSize: "22px",
    borderRadius: "4px",
    color: "#FFF",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      background: "#000",
      opacity: 0.8,
    },
  },
  login_btn_google: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    "@media (max-width :80em)": {
      width: "100%",
      fontSize: "1rem",
    },
    width: rem(296),
    height: rem(60),
    marginTop: rem(20),
    color: "#979797",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    border: "1px solid rgba(0, 0, 0, 0.09)",
    borderRadius: "8px",
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: rem(20),
    gap: "1rem",
    marginLeft: rem(20),
  },
  iconBorder: {
    border: "1px solid #232323",
    borderRadius: "3px",
    padding: ".5rem 2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
    cursor: "pointer",
    ":hover": {
      background: "#cccccc78",
    },
  },
  step1Container:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    background: "#fff",
    "@media (max-width : 800px)": {
      padding: "0 1rem",
    },
  }
}));

export default useStyles;
