import { Button, Group, Popover, Text } from "@mantine/core";
import { useState } from "react";
import { deleteMar } from "../../services";
import { IconTrash, IconRefresh } from "@tabler/icons-react";

const DeleteComponent = ({ data, onClose, type }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [dltLoading, setDltLoading] = useState(false);

  const text = type === "prn" ? "Delete" : "Reset";

  const removeMedication = async () => {
    setDltLoading(true);
    const obj = {
      client: data?.client?.id,
      marId: data?._id,
      type,
    };
    await deleteMar(obj);
    setDltLoading(false);
    setDeleteModal(false);
    onClose();
  };
  return (
    <Popover
      opened={deleteModal}
      width={300}
      position="left-start"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        {type === "scheduled" ? (
          <IconRefresh
            size={30}
            color="red"
            onClick={() => setDeleteModal(true)}
          />
        ) : (
          <IconTrash
            size={30}
            color="red"
            onClick={() => setDeleteModal(true)}
          />
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm">Are you sure you want to {text}?</Text>

        <Group grow mt={10}>
          <Button
            onClick={() => setDeleteModal(false)}
            size={"xs"}
            color="yellow"
          >
            Cancel
          </Button>
          <Button
            loading={dltLoading}
            size={"xs"}
            color={"yellow"}
            onClick={removeMedication}
          >
            {text}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DeleteComponent;
