import React from "react";

import { ScrollArea, Table, createStyles } from "@mantine/core";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecordNotFound from "../RecordNotFound";
import TableLoader from "../TableLoader";
import MedicationTableRow from "../MedicationTableRow";
import { getMar } from "../../services";
import { setMar, setLoading } from "../../store/reducers/mar";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const MedicationTable = ({ data, userId }) => {
  const { classes, cx } = useStyles();
  //const { medications, loading } = useSelector((state) => state.medication);

  const dispatch = useDispatch();
  const { mar, loading } = useSelector((state) => state.mar);
  const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   setMar()
  // }, [])

  // useEffect(() => {

  //   dispatch(setLoading(true));
  //   console.log('loading state 1',loading);
  //   getMar()
  //     .then((data) => {

  //       dispatch(setMar(data));
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     })
  //     .finally(() => {
  //       console.log('loading state 2',loading);
  //       dispatch(setLoading(false));
  //       console.log('loading state 3',loading);
  //     });
  // }, [dispatch]);

  //console.log('hamza mar',mar);
  //console.log("medications", medications);
  //console.log("new hamza mar", mar);

  const rows = mar?.map((row, i) => (
    <MedicationTableRow row={row} key={i} index={i} />
  ));
  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      sx={{ marginTop: "30px" }}
    >
      <Table
        withColumnBorders
        withBorder
        highlightOnHover
        enableStickyHeader
        sx={{ fontFamily: "Inter" }}
        fontSize={"sm"}
        verticalSpacing={"md"}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            {/* <th style={{ minWidth: "80px" }}>Entry #</th>
            <th style={{ minWidth: "140px" }}>Client Name</th>
            <th style={{ minWidth: "80px" }}>Facility Name</th>
            <th style={{ minWidth: "140px" }}>Medication Name</th>
            <th style={{ minWidth: "80px" }}>Frequency</th>
            <th style={{ minWidth: "80px" }}>Dosage</th> */}

            {/* addded by hamza */}

            <th style={{ minWidth: "80px" }}>Date</th>
            <th style={{ minWidth: "140px" }}>Client Name</th>
            <th style={{ minWidth: "140px" }}>Medication Name</th>
            <th style={{ minWidth: "80px" }}>Dosage</th>
            <th style={{ minWidth: "80px" }}>Route</th>
            <th style={{ minWidth: "80px" }}>Narcotic</th>
            <th style={{ minWidth: "80px" }}>Remaining Qty</th>
            <th style={{ minWidth: "80px" }}>Administration Type</th>
            <th style={{ minWidth: "80px" }}>Scheduled Time</th>
            <th style={{ minWidth: "80px" }}>Administered Time</th>
            <th style={{ minWidth: "80px" }}>Administered by</th>
            <th style={{ minWidth: "80px" }}>Outcome</th>
            <th style={{ minWidth: "80px" }}>Late</th>

            {/* <th style={{ minWidth: "140px" }}>Ingestion Method</th>
            <th style={{ minWidth: "140px" }}>Responsible for Purchasing</th>
            <th style={{ minWidth: "140px" }}>Responsible for administering</th> */}

            {/* <th style={{ minWidth: "200px" }}>Medication Dates</th>
            <th style={{ minWidth: "140px" }}>Interval</th>
            <th style={{ minWidth: "140px" }}>Medicine Inventory</th> */}
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan={1000}>
                <TableLoader />
              </td>
            </tr>
          ) : rows?.length ? (
            <>{rows}</>
          ) : (
            <tr>
              <td colSpan={1000}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    height: "75vh",
                    gap: "8rem",
                  }}
                >
                  <RecordNotFound />
                  <p className={classes.notFoundText}>
                    You don&apos;t have any record, Add Medications in
                    Individual to see here.
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default MedicationTable;
