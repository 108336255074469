import { https } from "../http";
import store from "../store";

import {
  setTypesLoading,
  setTaskTypes,
  setModalLoading,
  setTasks,
  setSubTask,
  setFields,
  setLoadingTasks,
} from "../store/reducers/task";
import { showError, showInfo } from "../utils/index";

// GET
const getTaskTypes = async (page, loading = true) => {
  try {
    if (loading) {
      store.dispatch(setTypesLoading(true));
    }
    let string = `/task/types/get?page=${page}&perPage=10`;

    let response = await https().get(string);

    if (response?.status === 200) {
      console.log("🚀 ~ getTaskTypes ~ response:", response)
      store.dispatch(setTaskTypes(response?.data));
    }
    store.dispatch(setTypesLoading(false));
  } catch (error) {
    store.dispatch(setTypesLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getTasks = async (page, status) => {
  try {
    store.dispatch(setLoadingTasks(true));
    let string = `/task/get-tasks?page=${page}&perPage=10&status=${status}`;

    let response = await https().get(string);

    if (response?.status === 200) {
      store.dispatch(setTasks(response?.data));
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  } finally {
    store.dispatch(setLoadingTasks(false));
  }
};

const addTask = async (payload, reset, navigate, setLoading) => {
  try {
    let string = `/task/create-task`;
    let response = await https().post(string, payload);
    if (response) {
      showInfo(response.data.message);
      reset();
      // getTasks(1, "all");
      setLoading(false);
      navigate("/home/tasks");
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const editTask = async (payload, navigate, setLoading) => {
  try {
    let string = `/task/edit-task`;
    let response = await https().post(string, payload);
    if (response) {
      showInfo(response.data.message);
      setLoading(false);
      navigate("/home/tasks");
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const deleteTask = async (payload, navigate, setLoading) => {
  try {
    let string = `/task/delete-task`;
    let response = await https().post(string, payload);
    if (response) {
      showInfo(response.data.message);
      getTasks(1, "all");
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const changeTaskStatus = async (payload) => {
  try {
    let string = `/task/disabled-task`;
    let response = await https().post(string, {id: payload});
    if (response) {
      showInfo(response.data.message);
      getTasks(1, "all");
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getClientTask = async (payload) => {
  try {
    let string = `/task/get-client-task?client=${payload.client}&taskType=${payload.type}`;
    let response = await https().get(string, payload);
    if (response) {
      return response?.data;
      // reset();
      // getTasks(1, "all");
      // setLoading(false);
      // navigate("/home/add-task", { state: response?.data?.data });
    }

    // showInfo(response.data.message);
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getSubTask = async () => {
  try {
    // store.dispatch(setModalLoading(true));
    let string = `/task/subtask/get`;

    let response = await https().get(string);

    if (response?.status === 200) {
      store.dispatch(setSubTask(response?.data));
      // store.dispatch(setModalLoading(false));
    }
  } catch (error) {
    // store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getFields = async () => {
  try {
    // store.dispatch(setModalLoading(true));
    let string = `/task/fields/get`;

    let response = await https().get(string);

    if (response?.status === 200) {
      store.dispatch(setFields(response?.data));
      // store.dispatch(setModalLoading(false));
    }
  } catch (error) {
    // store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const addSubTask = async (payload) => {
  try {
    // store.dispatch(setModalLoading(true));
    let string = `/task/subtask/create`;
    let response = await https().post(string, payload);
    getSubTask();
    // store.dispatch(setModalLoading(false));

    // showInfo(response.data.message);
  } catch (error) {
    // store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const addTaskField = async (payload) => {
  try {
    // store.dispatch(setModalLoading(true));
    let string = `/task/fields/create`;
    let response = await https().post(string, payload);
    getFields();
    // store.dispatch(setModalLoading(false));

    // showInfo(response.data.message);
  } catch (error) {
    // store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const updateTask = async (data) => {
  try {
    store.dispatch(setTypesLoading(true));
    let string = `/task/update-task`;

    let response = await https().post(string, data);

    if (response?.status === 200) {
      showInfo(response?.data?.message);
      await getTasks(1, "draft");
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  } finally {
    store.dispatch(setTypesLoading(false));
  }
};

const addTaskType = async (payload, reset) => {
  try {
    store.dispatch(setModalLoading(true));
    let string = `/task/types/create`;
    let response = await https().post(string, payload);
    store.dispatch(setModalLoading(false));
    getTaskTypes(1);
    showInfo(response.data.message);
    reset();
  } catch (error) {
    store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const updateTaskType = async (payload, reset) => {
  try {
    store.dispatch(setModalLoading(true));
    let string = `/task/types/udpate`;
    let response = await https().put(string, payload);
    store.dispatch(setModalLoading(false));
    getTaskTypes(1);
    showInfo(response.data.message);
    reset();
  } catch (error) {
    store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const deleteTaskType = async (id, reset) => {
  try {
    store.dispatch(setModalLoading(true));
    let string = `/task/types?id=${id}`;
    await https().delete(string);
    store.dispatch(setModalLoading(false));
    getTaskTypes(1);
  } catch (error) {
    store.dispatch(setModalLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export {
  deleteTaskType,
  updateTaskType,
  getTaskTypes,
  addSubTask,
  addTaskField,
  getTasks,
  addTask,
  getSubTask,
  getFields,
  addTaskType,
  updateTask,
  getClientTask,
  editTask,
  deleteTask,
  changeTaskStatus,
};
