import {
  Accordion,
  Box,
  Button,
  Group,
  Loader,
  Modal,
  Radio,
  TextInput,
  Textarea,
  ActionIcon,
  rem,
  Select,
} from "@mantine/core";
import { Form, useForm } from "@mantine/form";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getColor } from "../../pages/Home/Medication";
import {
  createMar,
  getMar,
  updateMedicationAdministration,
} from "../../services";
import { decrypt, firstLetterCap, showInfo } from "../../utils";
import useStyles from "./style";
import DeleteComponent from "./DeleteComponent";
import { IconAlertTriangle, IconClock } from "@tabler/icons-react";
import CloseCircle from "../CloseCircle";
import NarcoticBox from "../NarcoticBox";
import { DateInput } from "@mantine/dates";
import { useRef } from "react";
import { TimeInput } from "@mantine/dates";
import { IoIosArrowDown } from "react-icons/io";

const MedicationModal = ({
  date,
  opened,
  onclose,
  data,
  isViewOpen,
  futureDate,
}) => {
  console.log("data for medication modal", data);

  const { classes } = useStyles();
  const ref = useRef(null);
  const _medicationType = data?.medicationType;
  const _outOfMedication = "Out of Medication";
  const _upcoming = "upcoming";
  const { staff } = useSelector((state) => state?.staff);

  const [validation, setValidation] = useState({
    outcome: (value) => (value ? null : "Required"),
    comments: (value) => (value ? null : "Required"),
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { selectedClient } = useSelector((state) => state.mar);
  const { profile } = useSelector((state) => state.profile);
  let userid = profile?.user?.id;
  let userType = profile?.user?.type;

  const form = useForm({
    initialValues: {
      quantity: "0",
      reason: "",
      comments: "",
      outcome: "",
    },
    validate: validation,
  });

  useEffect(() => {
    setValidation((prevValidation) => ({
      ...prevValidation,
      // reason: (value) =>
      //   form?.values?.outcome === "Unable to Take"
      //     ? value
      //       ? null
      //       : "Required"
      //     : null,
    }));
  }, [form?.values?.outcome]);

  useEffect(() => {
    if (isViewOpen) {
      form.setFieldValue("quantity", String(data?.dosage));
      form.setFieldValue("outcome", _medicationType);
      form.setFieldValue("comments", data?.comments);
      // form.setFieldValue("reason", data?.reason);

      // Administration Form
      adminForm.setFieldValue("staff", data?.staff?.id);
      adminForm.setFieldValue("date", new Date(data?.createdAt));
      adminForm.setFieldValue("time", moment(data?.createdAt).format("HH:mm"));
    } else {
      form.setFieldValue(
        "quantity",
        String(data?.form3?.medicationadministered)
      );
    }
  }, [isViewOpen, data]);

  const isValidDate = (d) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const onSave = async (values) => {
    if (
      (Number(values?.quantity) > 0 && values?.outcome === "Taken") ||
      values?.outcome !== "Taken"
    ) {
      let obj = {
        client: selectedClient?.id,
        timeSlot: data?.timeSlot,
        date: moment(data?.date).toISOString(),
        medicationId: data?.medicationId,
        medicationType:
          data?.form3?.medicationType === "PRN" ? "PRN" : values?.outcome,
        staff: userid,
        comments: values?.comments,
        // reason: values?.reason,
        marId: data?._id,
        medicationName: data?.form3?.medicationName,
      };
      if (!isValidDate(new Date(data?.date))) {
        return showInfo("Please refresh page");
      }
      const dosage = values?.outcome === "Taken" ? Number(values?.quantity) : 0;
      const payload = {
        ...obj,
        dosage,
      };
      console.log("🚀 ~ onSave ~ payload:", payload);
      setBtnLoading(true);
      try {
        const res = await createMar(payload);
        if (res) {
          form.reset();
          onclose();
          let calendar_view = localStorage.getItem("calendar_date");
          getMar(calendar_view, selectedClient?.id);
        }
      } catch (error) {
        console.error("Error creating MAR:", error.message);
      }
      setBtnLoading(false);
    } else {
      alert("Quantity is Required!");
    }
  };

  const adminForm = useForm({
    initialValues: {
      staff: data?.staff?.name,
      date: new Date(data?.createdAt),
      time: new Date(data?.createdAt),
    },
    validate: {
      staff: (value) => (value ? null : "Required"),
      date: (value) => (value ? null : "Required"),
      time: (value) => (value ? null : "Required"),
    },
  });

  const handleAdminBy = async (values) => {
    const date = moment(values?.date);
    const time = moment(values?.time, "hh:mm A");
    const mergedDateTime = moment(date).set({
      hour: time.hours(),
      minute: time.minutes(),
      second: 0,
    });

    const obj = {
      marId: data?._id,
      staff: values?.staff,
      createdAt: mergedDateTime?.toISOString(),
    };
    setBtnLoading(true);
    await updateMedicationAdministration(obj);
    setBtnLoading(false);
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div style={{ minWidth: "600px" }}>
          <Modal
            className={classes.medicationModal}
            opened={opened}
            size={1200}
            onClose={() => {
              onclose();
              setIsEditOpen(false);
            }}
            shadow="md"
            overlayProps={{ blur: 3 }}
            radius={"lg"}
            zIndex={1000}
            centered
          >
            <CloseCircle
              onclose={() => {
                onclose();
                setIsEditOpen(false);
              }}
            />

            {isViewOpen ||
            _medicationType === "Missed" ||
            _medicationType === "upcoming" ? (
              <div
                style={{
                  position: "relative",
                  background: getColor(_medicationType)?.dark,
                }}
                className={`${classes.title}`}
              >
                <h2
                  style={{
                    color:
                      _medicationType === _outOfMedication ? "#fff" : "initial",
                  }}
                >
                  {firstLetterCap(data?.form3?.medicationName)}
                </h2>
                {data?.form3?.isNarcotic == "Yes" && <NarcoticBox />}
                <span
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: 25,
                  }}
                >
                  {profile?.user?.type !== "staff" &&
                    _medicationType !== _upcoming && (
                      // !data?.form3?.discontinued &&
                      <DeleteComponent
                        data={data}
                        onclose={() => onclose()}
                        type={"scheduled"}
                      />
                    )}
                </span>
              </div>
            ) : data?.form3?.medicationType !== "PRN" ? (
              <div className={classes.upcomingHeader}>
                <img src="/assets/images/prescription-icon.png" />
                <h2>Administer Scheduled Medication</h2>
              </div>
            ) : (
              <div className={classes.upcomingHeader}>
                <img src="/assets/images/prescription-icon.png" />
                <h2>Administer PRN Medication</h2>
              </div>
            )}
            <div className={classes.mainContentWarpper}>
              <div className={classes.mainContent}>
                <div className={classes.details}>
                  {(_medicationType === "Missed" ||
                    _medicationType === "upcoming") && (
                    <p
                      style={{
                        textAlign: "left",
                        marginTop: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                        color: "#5C5C5C",
                      }}
                    >
                      {moment(date).format("MM/DD/YYYY")}
                    </p>
                  )}

                  {_medicationType === "Missed" && (
                    <div className={classes.missedBoxWrapper}>
                      <div className={"missedBox"}>
                        <IconAlertTriangle size={40} />
                        <p>MISSED</p>
                      </div>
                    </div>
                  )}

                  <div className={classes.head}>
                    <div
                      className={classes.box}
                      style={{
                        background:
                          getColor(_medicationType)?.light || "#fdf5e3",
                      }}
                    >
                      <h3
                        style={{
                          color:
                            _medicationType === _outOfMedication
                              ? "#fff"
                              : "initial",
                        }}
                      >
                        {/* {data?.medicineRemaining > 0
                          ? data?.medicineRemaining
                            ? data?.medicineRemaining
                            : data?.form3?.medicineRemaining
                          : "0"} */}
                        {data?.form3?.medicineRemaining > 0
                          ? data?.form3?.medicineRemaining
                            ? data?.form3?.medicineRemaining
                            : data?.medicineRemaining
                          : "0"}
                      </h3>
                      {/* <h3>{data?.form3?.medicineRemaining}</h3> */}
                      <p
                        style={{
                          color:
                            _medicationType === _outOfMedication
                              ? "#fff"
                              : "initial",
                        }}
                      >
                        of {data?.form3?.totalMedicine} <br />
                        {/* of {data?.form3?.totalMedicine} <br /> */}
                        Remaining
                      </p>
                    </div>
                    <div>
                      {
                        <div className={`${classes.detailTitle}`}>
                          {!isViewOpen &&
                            _medicationType !== "Missed" &&
                            _medicationType !== "upcoming" && (
                              <h2>{data?.form3?.medicationName}</h2>
                            )}
                          {data?.form3?.isNarcotic === "Yes" &&
                            !isViewOpen &&
                            _medicationType !== "Missed" &&
                            _medicationType !== "upcoming" && <NarcoticBox />}
                        </div>
                      }
                      <p>
                        <span className={classes.span1}>
                          {data?.form3?.frequency}
                        </span>
                        <span className={classes.span2}>
                          {data?.form3?.dosage}
                          {data?.form3?.unit} {data?.form3?.ingestionMode}
                          {data?.form3?.dosageForm}
                        </span>
                        <span className={classes.span3}>{data?.timeSlot}</span>
                      </p>
                    </div>
                  </div>
                  <div className={classes.medicationBox}>
                    <Accordion variant="contained">
                      <Accordion.Item
                        value="use"
                        style={{
                          borderRadius: "15px",
                          background: "#F2F2F2",
                          marginBottom: "10px",
                        }}
                      >
                        <Accordion.Control>Use of Medication</Accordion.Control>
                        <Accordion.Panel>
                          {data?.form3?.medicationUse}
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion variant="contained">
                      <Accordion.Item
                        value="instruction"
                        style={{
                          borderRadius: "15px",
                          background: "#F2F2F2",
                          marginBottom: "10px",
                        }}
                      >
                        <Accordion.Control>
                          Medication Instruction
                        </Accordion.Control>
                        <Accordion.Panel>
                          {data?.form3?.medicationInstruction}
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <form
                    className={classes.modalBody}
                    onSubmit={form?.onSubmit((values) => onSave(values))}
                  >
                    <div className={classes.outcome}>
                      <div className={classes.quantity}>
                        <TextInput
                          size="xl"
                          radius="xl"
                          type="number"
                          id="change-quantity"
                          className={classes.quantityInput}
                          {...form.getInputProps("quantity")}
                          defaultValue={form.values.quantity}
                          disabled={isViewOpen || futureDate == 1}
                          // min={0}
                          // disabled={isViewOpen || data?.form3?.discontinued}
                        />
                        <p>Quantity Dispensed</p>
                      </div>
                      {data?.form3?.medicationType !== "PRN" && (
                        <div className={classes.dispensed}>
                          <h3>
                            Outcomes <span className={classes.redText}>*</span>
                          </h3>
                          <div className={classes.radioContainer}>
                            <Radio.Group
                              label=""
                              defaultValue={form?.values.outcome}
                              onChange={(value) => {
                                form.setFieldValue("outcome", value);
                                // if (value !== "Unable to Take") {
                                //   form.setFieldValue("reason", null);
                                // }
                                if (value === "Taken") {
                                  setValidation({
                                    outcome: (value) =>
                                      value ? null : "Required",
                                  });
                                } else {
                                  setValidation({
                                    outcome: (value) =>
                                      value ? null : "Required",
                                    comments: (value) =>
                                      value ? null : "Required",
                                  });
                                }
                              }}
                              required
                              withAsterisk
                            >
                              <Group mt="xs">
                                {isViewOpen &&
                                // !data?.form3?.discontinued &&
                                form?.values.outcome === "Taken" ? (
                                  <div className={classes.statusBox}>
                                    <img src={"/assets/images/done-icon.png"} />
                                    <p style={{ color: "#167207" }}>Taken</p>
                                  </div>
                                ) : (
                                  <Radio
                                    id="taken-medicine"
                                    disabled={
                                      isViewOpen || futureDate == 1
                                      // || data?.form3?.discontinued
                                    }
                                    value="Taken"
                                    label="Taken"
                                  />
                                )}
                                {isViewOpen &&
                                // !data?.form3?.discontinued &&
                                form?.values.outcome === "Refused" ? (
                                  <div className={classes.statusBox}>
                                    <img
                                      src={"/assets/images/unavailable.png"}
                                    />
                                    <p style={{ color: "#6B9CFF" }}>Refused</p>
                                  </div>
                                ) : (
                                  <Radio
                                    disabled={
                                      isViewOpen || futureDate == 1
                                      // || data?.form3?.discontinued
                                    }
                                    value="Refused"
                                    label="Refused"
                                  />
                                )}
                                {isViewOpen &&
                                // !data?.form3?.discontinued &&
                                form?.values.outcome === "Unable to Take" ? (
                                  <div className={classes.statusBox}>
                                    <img
                                      src={"/assets/images/do-not-disturb.png"}
                                    />
                                    <p style={{ color: "#FDD65B" }}>
                                      Unable to Take
                                    </p>
                                  </div>
                                ) : (
                                  <Radio
                                    disabled={
                                      isViewOpen || futureDate == 1
                                      // || data?.form3?.discontinued
                                    }
                                    value="Unable to Take"
                                    label="Unable to Take"
                                  />
                                )}
                                {isViewOpen &&
                                // !data?.form3?.discontinued &&
                                form?.values.outcome === "Out of Medication" ? (
                                  <div className={classes.statusBox}>
                                    <img
                                      src={"/assets/images/close-black.png"}
                                    />
                                    <p style={{ color: "#black" }}>
                                      Out of Medication
                                    </p>
                                  </div>
                                ) : (
                                  <Radio
                                    disabled={
                                      isViewOpen || futureDate == 1
                                      // || data?.form3?.discontinued
                                    }
                                    value="Out of Medication"
                                    label="Out of Medication"
                                  />
                                )}
                              </Group>
                            </Radio.Group>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* {form?.values?.outcome === "Unable to Take" && (
                      <div style={{ marginBottom: "20px" }}>
                        <Select
                          label="Reason"
                          data={[
                            {
                              value: "Therapeutic leave of absence (TLOA)",
                              label: "Therapeutic leave of absence (TLOA)",
                            },
                            {
                              value: "Hospitalization or Acute care",
                              label: "Hospitalization or Acute care",
                            },
                            {
                              value: "Surgery or Medical Procedures",
                              label: "Surgery or Medical Procedures",
                            },
                            {
                              value: "Travel or Relocation",
                              label: "Travel or Relocation",
                            },
                          ]}
                          size="sm"
                          disabled={isViewOpen}
                          rightSection={
                            <IoIosArrowDown color={"#FBAA0D"} size={25} />
                          }
                          style={{ marginBottom: "15px" }}
                          placeholder="Select Reason"
                          {...form.getInputProps("reason")}
                        />
                      </div>
                    )} */}

                    <div
                      className={classes.comments}
                      style={{ marginBottom: "20px" }}
                    >
                      <Textarea
                        id="dosage-completed"
                        minRows={6}
                        label="Comments"
                        disabled={
                          isViewOpen || futureDate == 1
                          // || data?.form3?.discontinued
                        }
                        placeholder="Enter comments here"
                        defaultValue={form.values.comments}
                        {...form.getInputProps("comments")}
                      />
                    </div>

                    {!isViewOpen && (
                      // !data?.form3?.discontinued &&
                      <div className={classes.saveButton}>
                        <Button
                          id="click-to-submit-dosage"
                          loading={btnLoading}
                          type="submit"
                          style={btnLoading ? { width: "170px" } : {}}
                          disabled={futureDate == 1 ? true : false}
                        >
                          <img src="/assets/images/save-icon.png" alt="" />
                          Save
                        </Button>
                      </div>
                    )}
                  </form>
                  {isViewOpen && !isEditOpen && (
                    <>
                      <div className={classes.info}>
                        <div className="column1">
                          <div>
                            <p>Administered by</p>
                            <h3>{data?.staff?.name}</h3>
                          </div>
                        </div>
                        <div className="column2">
                          <p>Date</p>
                          <h3>
                            {moment(data?.createdAt).format("MM/DD/YYYY")}
                          </h3>
                        </div>
                        <div className="column2">
                          <p>Time</p>
                          <>
                            {_medicationType !== _upcoming &&
                              moment(data.createdAt).isSameOrBefore(
                                moment()
                              ) && (
                                <h3>
                                  {moment(data?.createdAt).format("hh:mm a")}
                                </h3>
                              )}
                          </>
                        </div>
                      </div>
                      {userType !== "staff" && (
                        // !data?.form3?.discontinued &&
                        <div className={classes.editButton}>
                          <Button
                            onClick={() => {
                              setIsEditOpen(true);
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  {isEditOpen && (
                    <form
                      onSubmit={adminForm?.onSubmit((values) =>
                        handleAdminBy(values)
                      )}
                      // style={{ border: "1px solid rgb(240,240,240)" }}
                    >
                      <div className={classes.info}>
                        <div className="column1">
                          <div>
                            <p>Administered by</p>
                            {userType !== "staff" ? (
                              <Select
                                data={staff?.staffs?.map((data) => {
                                  return {
                                    value: data?.id,
                                    label: data?.name,
                                  };
                                })}
                                placeholder="Select Staff"
                                {...adminForm.getInputProps("staff")}
                              />
                            ) : (
                              <h3>{data?.staff?.name}</h3>
                            )}
                          </div>
                        </div>
                        <div className="column2">
                          <p>Date</p>

                          {userType !== "staff" ? (
                            <DateInput
                              placeholder={"mm/dd/yyyy"}
                              rightSection={
                                <img
                                  style={{ width: "30px" }}
                                  src={"/assets/images/calendar-icon.png"}
                                  alt="Icon"
                                />
                              }
                              {...adminForm.getInputProps("date")}
                            />
                          ) : (
                            <h3>
                              {moment(data?.createdAt).format("MM/DD/YYYY")}
                            </h3>
                          )}
                        </div>
                        <div className="column2">
                          <p>Time</p>
                          {userType !== "staff" ? (
                            <TimeInput
                              ref={ref}
                              rightSection={
                                <ActionIcon
                                  variant="subtle"
                                  color="gray"
                                  onClick={() => ref?.current?.showPicker()}
                                >
                                  <IconClock
                                    style={{
                                      width: rem(16),
                                      height: rem(16),
                                    }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                              }
                              {...adminForm.getInputProps("time")}
                            />
                          ) : (
                            <>
                              {_medicationType !== _upcoming &&
                                moment(data.createdAt).isSameOrBefore(
                                  moment()
                                ) && (
                                  <h3>
                                    {/* {moment(data?.createdAt).format("hh:mm a")} */}

                                    {moment
                                      .tz(
                                        moment(data?.createdAt),
                                        profile?.user?.timeZone
                                      )
                                      .format("hh:mm a")}
                                  </h3>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                      {userType !== "staff" && (
                        <div className={classes.updateButton}>
                          <div className={classes.editButton}>
                            <Button
                              onClick={() => {
                                setIsEditOpen(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                          <Button
                            loading={btnLoading}
                            type="submit"
                            style={btnLoading ? { width: "180px" } : {}}
                          >
                            <img src="/assets/images/save-icon.png" alt="" />
                            Save
                          </Button>
                        </div>
                      )}
                    </form>
                  )}
                </div>
                <div className={classes.personInfo}>
                  <img src={decrypt(selectedClient?.imageurl)} />
                  <h3>{selectedClient?.name}</h3>
                  <p>{selectedClient?.id?.slice(0, 5)?.toUpperCase()}</p>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MedicationModal;
