import React from "react";
import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  createStyles,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import { setNoteType } from "../../store/reducers/note";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    // height: 800,
    "& .mantine-Modal-content": {
      height: "500px !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
  typeSelector: {
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: "#CBCBCB66",
    borderTopStyle: "solid",
    paddingBottom: 100,
  },
}));
const NoteTypeSelectModal = ({ opened, onclose, title, save }) => {
  const { classes } = useStyles();
  const { allNotesTypes } = useSelector((state) => state?.note);
  const dispatch = useDispatch();

  const onSave = (values) => {
    dispatch(setNoteType(values?.selected));
    setTimeout(() => {
      save(true);
      onclose(values?.selected);
      form?.reset();
    }, 400);
  };
  const form = useForm({
    initialValues: {
      selected: "",
    },
    validate: {
      selected: (value) => (value ? null : "Please select type"),
    },
  });

  return (
    <Modal
      // size={""}
      onClose={() => {
        form?.reset();
        onclose();
      }}
      shadow="md"
      radius={"lg"}
      zIndex={1000}
      opened={opened}
      overlayProps={{ blur: 3 }}
      className={classes.mymodal}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text ff={"Inter"} fz={22} fw={600}>
            {title}
          </Text>
        </Group>
      }
      centered
    >
      <form onSubmit={form?.onSubmit((values) => onSave(values))}>
        <Select
          size="md"
          searchable
          id="note-type"
          label="Note Type*"
          placeholder="Ex: Marco Botton"
          className={classes.typeSelector}
          icon={<img src="/assets/images/home2.png" alt="home" />}
          data={
            allNotesTypes?.ntypes
              ? allNotesTypes?.ntypes?.map((value) => ({
                  label: value?.name,
                  value: value?.id,
                }))
              : []
          }
          {...form.getInputProps("selected")}
        />

        <SimpleGrid cols={2} m={"auto"} mt={25} mb={10}>
          <Button
            w={122}
            ml={"auto"}
            ff={"Inter"}
            color="yellow"
            onClick={onclose}
          >
            Cancel
          </Button>
          <Button
            w={122}
            mr={"auto"}
            ff={"Inter"}
            type="submit"
            color="yellow"
            id="note-add-button"
          >
            Add
          </Button>
        </SimpleGrid>
      </form>
    </Modal>
  );
};

export default NoteTypeSelectModal;
