import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  ClientForm: {
    backgroundColor: "#F2F2F2",
  },
  formContent: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    gap: "1rem",
    backgroundColor: "#F2F2F2",
    margin: "0 !important",
    "@media (max-width :500px)": {
      flexDirection: "column",
    },
  },
  tabs: {
    "& .mantine-Tabs-tab[aria-selected='true']": {
      background: "#f4ead8 !important",
    },
    "& .mantine-Tabs-tabsList": {
      flexWrap: "nowrap !important",
      overflowX: "auto !important",
      paddingBottom: "1rem !important",
    },
    "& .mantine-cohod4": {
      gap: "0rem",
    },
    backgroundColor: "#fff",
    margin: "1rem",
  },
  tab: {
    padding: "1rem 1rem",
    backgroundColor: "#fff",
    marginRight: "1rem",
  },
  tabList: {
    backgroundColor: "#F2F2F2",
  },
  tabTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  form: {
    backgroundColor: "#fff",
    padding: "1rem",
    height: "100vh",
    overflowY: "auto",
    "@media (max-width :500px)": {
      height: "100% !important",
      overflow: "visible !important",
    },
  },
  formInfo: {
    backgroundColor: "#fff",
    width: "100px",
    height: "100vh",
    "@media (max-width :500px)": {
      width: "100% !important",
      maxWidth: "100% !important",
    },
  },
}));

export default useStyles;
