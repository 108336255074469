import { Box, Button, createStyles } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomPagination from "../../../../components/Pagination/CustomPagination";

import "react-multi-carousel/lib/styles.css";
import { getMedicationLogs } from "../../../../services/mar";
import MedicationLogTable from "./MedicationLogTable";
import UserDetail from "../MarTab/UserDetail";
import HideClient from "../ClientHide";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ClientColumn from "../ClientColumn";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // overflowX: "auto",
    marginBottom: "50px",
    height: "100%",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "& button": {
      border: "none",
      height: "50px",
      color: "black",
    },
    "& img": {
      width: "30px",
    },
  },
  mainHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBody: {
    width: "100%",
    display: "flex",
    gap: "20px",
    // marginTop: "30px",
  },
  scheduleDetailRight: {
    width: "calc(100% - 270px)",
  },
  scheduleDetailRight100: {
    width: "calc(100% - 70px)",
  },
  userDetail: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "40px",
    "& .detail_box": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& .image_box": {
        width: "80px",
        height: "80px",
        border: "1px solid rgb(230,230,230)",
        borderRadius: "10px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      "& h3": {
        margin: "0",
        fontSize: "30px",
        textAlign: "center",
      },
      "& p": {
        margin: "0",
        color: "#979797",
        textAlign: "center",
      },
    },
  },
}));

const MedicationLogTab = ({ userId }) => {
  const { classes } = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(true);
  const {
    medicationLogCount: count,
    selectedClient,
    logSearch,
  } = useSelector((state) => state.mar);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getMedLog();
  }, [currentPage, selectedClient]);

  const getMedLog = async () => {
    await getMedicationLogs(currentPage, null, true, logSearch);
  };

  return (
    <Box className={classes.content}>
      {!userId ? (
        <HideClient show={show} setShow={setShow} />
      ) : (
        <Box m={20}></Box>
      )}
      <Box className={classes.contentBody}>
        {show ? (
          <>
            {!userId && <UserDetail isClient={false} />}
            {/* {userId && <UserDetail isClient={true} />} */}
          </>
        ) : (
          <ClientColumn />
        )}
        <Box
          style={{ width: userId ? "100%" : "calc(100% - 270px)" }}

          // className={
          //   (!userId && userId) || !show
          //     ? classes.scheduleDetailRight100
          //     : classes.scheduleDetailRight
          // }
        >
          <MedicationLogTable />
          <CustomPagination
            data={count}
            itemsPerPage={10}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
      <Box className={classes.btnContainer}>
        <ReactHTMLTableToExcel
          table="marlogTable"
          sheet={selectedClient?.name + " Medication Log Report"}
          id="test-table-xls-button"
          filename={`${selectedClient?.name} Medication Log Report`}
          className="download-table-xls-button"
          buttonText={
            <Button
              leftIcon={
                <img
                  src={"/assets/images/downloadFolder.png"}
                  alt="downloadFolder"
                />
              }
              color="yellow"
            >
              Download Medication Log
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default MedicationLogTab;
