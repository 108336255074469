import React from "react";

import { ScrollArea, Table, createStyles } from "@mantine/core";

import { useState } from "react";
import { useSelector } from "react-redux";
import NoteTableRow from "../NoteTableRow";
import TableLoader from "../TableLoader";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

const NoteTable = ({ openModal, setView, userId, loading }) => {
  const { notes } = useSelector((state) => state?.note);
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  let sorted = notes?.notes
    ?.slice()
    ?.sort((b, a) => new Date(a?.fields?.Date) - new Date(b?.fields?.Date));

  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      mt={30}
    >
      {loading ? (
        <TableLoader />
      ) : (
        <Table
          withBorder
          fontSize={"sm"}
          highlightOnHover
          withColumnBorders
          enableStickyHeader
          verticalSpacing={"md"}
          sx={{ fontFamily: "Inter" }}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th style={{ minWidth: "200px" }}>Note Type</th>
              <th style={{ minWidth: "200px" }}>Staff Name</th>
              <th style={{ minWidth: "140px" }}>Date</th>
              <th style={{ minWidth: "120px" }}>Time</th>
              <th style={{ minWidth: "140px" }}>Facility Name</th>
              <th style={{ minWidth: "140px" }}>Client Name</th>
              <th style={{ minWidth: "140px" }}>Description</th>
              <th style={{ minWidth: "140px" }}>Medication</th>
              <th style={{ minWidth: "140px" }}>Reason</th>
              <th style={{ minWidth: "170px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sorted?.map((row, i) => (
              <NoteTableRow
                key={i}
                index={i}
                row={row}
                userId={userId}
                open={openModal}
                setView={setView}
              />
            ))}
          </tbody>
        </Table>
      )}
    </ScrollArea>
  );
};

export default NoteTable;
