import { https } from "../http";
import store from "../store";
import swal from "sweetalert";

import {
  setLoading,
  setLoadingSaveProfile,
  setProfile,
} from "../store/reducers/profile";
import { getCookie, removeCookie, showError } from "../utils/index";

// GET
const getProfile = async (showLoading = true, token, currentTimezone) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https(token).get("/me", {
      params: {
        timeZone: currentTimezone,
      },
    });

    if (response?.status === 200) {
      let temp = response?.data?.user;
      if (temp?.user?.active === false) {
        removeCookie("token");
        showError("Your session has expired");
        window.location.href = "/login";
      }
      store.dispatch(
        setProfile({
          ...temp,
          subscriptionExpired: response.data?.subscriptionExpired,
        })
      );
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    if (getCookie("token")) showError("Your session has expired");
    removeCookie("token");
    // window.location.href = "/";
    return false;
  }
};

// PUT
const updateProfile = async (data, navigate) => {
  try {
    store.dispatch(setLoadingSaveProfile(true));
    let response = await https().put("/me/", data);
    if (response?.status === 200) {
      // if (response?.data?.isNewEmail) {
      //   removeCookie("token");
      //   navigate("/");
      // } else {
      //   getProfile();
      //   }
      getProfile();
      return response?.data;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("This name is already taken");
    else showError(_error, error?.response?.status);
    store.dispatch(setLoadingSaveProfile(false));
    return false;
  }
};

const verifyPasswordAndUpdateEmail = async (payload, navigate) => {
  try {
    let response = await https().post("/auth/verify-password", payload);
    if (response?.status === 200) {
      const res = await updateProfile({
        name: payload?.name,
        email: payload?.newEmail,
      });
      // if (res) {
      //   removeCookie("token");
      //   navigate("/");
      // }
    }

    return true;
  } catch (error) {
    let _error = error?.response?.data?.message;
    swal(_error || "", "", "error");
    // showError(_error, error?.response?.status);
    return false;
  }
};

const updateProfilePhoto = async (data) => {
  try {
    store.dispatch(setLoadingSaveProfile(true));
    let response = await https().post("/me/updateProfile", data);
    if (response?.status === 200) {
      getProfile();
      return response?.data;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setLoadingSaveProfile(false));
    return false;
  }
};

// PUT
const updateBusinessProfile = async (data) => {
  try {
    store.dispatch(setLoadingSaveProfile(true));
    let response = await https().put("/me/business", data);
    if (response?.status === 200) {
      getProfile();
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setLoadingSaveProfile(false));
    showError(error?.response?.data?.message, error?.response?.status);
    return false;
  }
};

export {
  getProfile,
  updateBusinessProfile,
  updateProfile,
  updateProfilePhoto,
  verifyPasswordAndUpdateEmail,
};
