import {
  Alert,
  Container,
  Tabs,
  createStyles,
  rem,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Topbar from "../../../components/Topbar";
import BusinessProfile from "./BusinessProfile";
import MyProfile from "./MyProfie";
import Upgrade from "./Upgrade";

import { IconInfoCircle } from "@tabler/icons-react";
import { isSubscriptionExpired } from "../../../utils";
import ChangeMethod from "./ChangeMethod";
import MyTicket from "../MyTicket";
import { TrackPageView } from "../../../config/mixpanelConfig";

const useStyles = createStyles((theme) => ({
  "& .mantine-Container-root": {
    paddingLeft: "0!important",
    paddingRight: "0!important",
  },
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingLeft: ".5rem !important",
    paddingRight: ".5rem !important",
  },
  tabs: {
    marginTop: 50,
    // background: 'blue',
    fontFamily: "Inter",
    marginLeft: 10,
    marginRight: 10,
  },
  tab: {
    // background: 'red',
    fontFamily: "Inter",
    fontSize: rem(20),
    paddingLeft: 30,
    paddingRight: 30,
    "@media (max-width :80em)": {
      fontSize: rem(18),
      margin: "0 10px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    "@media (max-width :60em)": {
      fontSize: rem(16),
      margin: "0 5px",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },

  alertBox: {
    width: "100%",
    marginTop: "50px",
  },
}));
const Setting = () => {
  const { search, state } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [currentTab, setCurrentTab] = useState("myprofile");
  const [defaultTab, setDefaultTab] = useState("myprofile");
  const selectedTab = queryParams.get("selectedTab");

  useEffect(() => {
    if (state?.selectedTab) {
      setCurrentTab(state?.selectedTab);
    }
  }, [state?.selectedTab]);

  useEffect(() => {
    if (selectedTab === "upgrade") {
      setDefaultTab("upgrade");
    }
  }, [selectedTab]);
  useEffect(() => {
    TrackPageView("Setting");
  }, []);

  const { classes } = useStyles();
  const { profile } = useSelector((state) => state?.profile);

  return (
    <Container fluid className={classes.content} px={20}>
      <Topbar title={"Settings"} />
      <Tabs
        className={classes.tabs}
        defaultValue={selectedTab === "upgrade" ? "upgrade" : defaultTab}
        value={currentTab}
        onTabChange={(value) => setCurrentTab(value)}
      >
        <Tabs.List>
          <Tabs.Tab
            // onClick={() => {
            //   setDefaultTab("myprofile");
            // }}
            className={classes.tab}
            value="myprofile"
            color="yellow"
          >
            My Profile
          </Tabs.Tab>
          {profile?.user?.type == "staff" ? null : (
            <Tabs.Tab
              // onClick={() => {
              //   setDefaultTab("business");
              // }}
              className={classes.tab}
              value="business"
              color="yellow"
            >
              Business Profile
            </Tabs.Tab>
          )}
          {profile?.user?.type == "staff" ? null : (
            <Tabs.Tab
              // onClick={() => {
              //   setDefaultTab("upgrade");
              // }}
              className={classes.tab}
              value="upgrade"
              color="yellow"
            >
              Upgrade Plan
            </Tabs.Tab>
          )}
          {profile?.user?.type == "staff" ? null : (
            <Tabs.Tab
              // onClick={() => {
              //   setDefaultTab("method");
              // }}
              className={classes.tab}
              value="method"
              color="yellow"
            >
              Connected Accounts
            </Tabs.Tab>
          )}
          {/* {profile?.user?.type == "staff" ? null : (
            <Tabs.Tab
              onClick={() => {
                setDefaultTab("ticket");
              }}
              className={classes.tab}
              value="ticket"
              color="yellow"
            >
              <div>
                <span style={{ marginRight: "5px" }}>Tickets</span>
                {unreadedCount !== 0 ? (
                  <Badge size="xl" circle color="yellow">
                    {unreadedCount}
                  </Badge>
                ) : null}
              </div>
            </Tabs.Tab>
          )} */}
        </Tabs.List>

        {isSubscriptionExpired() && (
          <div className={classes.alertBox}>
            <Alert
              color="red"
              title="Expired"
              variant="light"
              icon={<IconInfoCircle />}
            >
              Your subscription is expired.
            </Alert>
          </div>
        )}

        <Tabs.Panel value="myprofile">
          <MyProfile />
        </Tabs.Panel>
        {profile?.user?.type !== "staff" && (
          <>
            <Tabs.Panel value="business">
              <BusinessProfile />
            </Tabs.Panel>
            <Tabs.Panel value="upgrade">
              <Upgrade />
            </Tabs.Panel>
            <Tabs.Panel value="method">
              <ChangeMethod />
            </Tabs.Panel>
            {/* <Tabs.Panel value="ticket">
              <MyTicket activeTab={defaultTab} />
            </Tabs.Panel> */}
          </>
        )}
      </Tabs>
    </Container>
  );
};

export default Setting;
