import React from "react";

export const PPUploadSvg = ({onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="111"
      height="111"
      fill="none"
      onClick={onClick}
      cursor={"pointer"}
      viewBox="0 0 150 150"
    >
      <rect
        width="145"
        height="145"
        x="2.5"
        y="2.5"
        stroke="#FFBA45"
        strokeWidth="5"
        rx="72.5"
      ></rect>
      <path
        fill="url(#pattern0_2201_4712)"
        d="M25 25.001H125V125.001H25z"
      ></path>
      <defs>
        <pattern
          id="pattern0_2201_4712"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01563)" xlinkHref="#image0_2201_4712"></use>
        </pattern>
        <image
          id="image0_2201_4712"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEmklEQVR4nO1bTYhcRRDuTTQIRkTC7lRNol4iQkQh/uBFj8Yo5OJBjBESPAieZN2YePAqCCZrghcjeDBqohFvXjzI6FTNLrrrqmQRweQg+VsRPBhzmKmOLfXmZbZn5s3Mm52fnR7fBw+W9/rVdFdXffXTb43JkCFDhgwZMmTIkGEocM5MVAgfsoz7LMN09cJ95W/xQX1mxhWuMAnCcEwILllGl3TpM2F4x81P5cy4wDkzYRkPCeM/rRbepAjGq5bgYPAW4Qp33yIEpxMWeEXvC+FRIZyN/ma80jwOTqkMEyKcMxMJi18Uxl3OmQ0J4zdICZ+0BEtNSgjREizjoXr/xll3xmzs9J4rmJuUK+oURzBjQoIr5aaE8G9v8Ue7lSEEx31OUBI1oUDqd3Axzc4nWYLvDmpBJiDfv+Tt3q61ypIi7PZC5MUguKDC8LC3+MtJhJcWETESrtyQp8mSGXVYwue9XTvdqzxh/KzmTqX8c2bUYQkOehbwdq/yhPFIUNHAEsx4CjjSqzyNIKuECq+aUYct4t7/tQtUqpVeLeXtAwn+USPBUn6nCS4MFmH3WmUJw9OeNV0IIgwqtKT1iGtJkxqzlkSI4adessnRSYUZjnUrQxjerUuFQ+sRWC8cxiZ8PI0lxMVQbfEx+0+b0OCUCxhONVR1S8oJLcvhyOdXzT5W3MfB+H5iQ6RRCVV/XhGCT6vNEJzVUOezvec6nwTbEKlriRHMRG2utC2xiD9gOtidT4KSWNz+utimKXpB2V5J1IwrnDMTmtBUM8a4LV7EvXpvrHY8Q4YMTXDLOzZVSvCIpdyzlvAlS3g4vl5RHqgUc4863nKbGSeU56buF4I3LeGCEJRTnApZy/Cz9hIqjI8HSYxu0dxsKf+iZVxOG/vbKOQ9ExIswwvCeL5FgvOvEPxqGb8QxhPC8FZ0Ec5aws81DRZGaXjnFxMCHN+Zt4RfJuzgVc3nhfJ73OIdt3eU81XuViF4Shg+sIzzQvCMGXUI4RPC8FdDHv+nEpwrTG424wxLsF8IK57JXo/O+Ae8cPfN1nsswRuWoBR9V0BQjjpRBCW9r8/NoGEZXla/9nb9d2XuQf6m+y6/RQjeb+SKBNcTHafjBzIRofyeasiq1fo/Kg+YAaJMuftaEWwbRZzX9/o7keLWBxq++JgfdALjCO/yj8m6UgLhir7fxxgP3qkt/OaKMNkX4a1+84zZqKfMPeUUhAu9tOhriIhndfHXHOfvNQOG5dyB9gvMvabhs/GjjIRxB3qaiIsOKuDasJuVlvBsu4VpjRHNb3nHpg5WcLb3yTD8EAv7ui8mlSbcdTDv+vm1H+vmtm3vbUKFyc0VwseG1az0zxrrcw08eSOV9sfX0mt9Tni9SQkhnC+2Om32QtuHJgVUCc1WEMAJcwoFnDQpIIQfha+AYgsX0MXFDZW68fE9fT4WLuDmtm3vKwkOo0YY6zC4HuiYCGnZrYkQ4evtx8F+EyKcpsKEC53Mu4OSvh9G3jIwaKXZ7n8NhlYMrSeicpjwXJeLP9f3cng90VVDhPHEwBoi641aS4yB61piDDy0lpgZA/wHDz5r2CX/DVMAAAAASUVORK5CYII="
        ></image>
      </defs>
    </svg>
  );
};
