import React from "react";

export const StaffIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect
        width="48"
        height="48"
        fill="#FFBA45"
        fillOpacity="0.15"
        rx="4"
      ></rect>
      <path
        fill="#FFBA45"
        d="M30.913 17.712a.923.923 0 00-.262 0 3.592 3.592 0 01-3.475-3.6 3.614 3.614 0 013.612-3.612 3.614 3.614 0 013.613 3.613 3.612 3.612 0 01-3.488 3.6zM34.987 26.374c-1.4.937-3.362 1.287-5.175 1.05a8.164 8.164 0 00.738-3.363c0-1.25-.275-2.437-.8-3.475 1.85-.25 3.813.1 5.225 1.038 1.975 1.3 1.975 3.437.012 4.75zM17.05 17.712a.923.923 0 01.262 0 3.592 3.592 0 003.475-3.6 3.606 3.606 0 10-7.213 0 3.592 3.592 0 003.475 3.6zM17.186 24.061a8.14 8.14 0 00.737 3.4c-1.762.188-3.6-.187-4.95-1.075-1.975-1.312-1.975-3.45 0-4.762 1.338-.9 3.225-1.263 5-1.063a7.947 7.947 0 00-.787 3.5zM23.999 19.832a4.358 4.358 0 00-4.354 4.354c0 2.356 1.842 4.263 4.244 4.345a.737.737 0 01.201 0h.064a4.343 4.343 0 004.199-4.345 4.358 4.358 0 00-4.354-4.354zM28.657 30.97c-2.558-1.705-6.729-1.705-9.304 0-1.165.78-1.806 1.834-1.806 2.961 0 1.128.642 2.172 1.796 2.943 1.284.861 2.97 1.292 4.657 1.292 1.687 0 3.373-.43 4.657-1.292 1.155-.78 1.796-1.825 1.796-2.961-.009-1.128-.641-2.173-1.796-2.943zm-2.823 3.648h-1.146v1.146a.692.692 0 01-.688.688.692.692 0 01-.687-.688v-1.146h-1.146a.692.692 0 01-.688-.687c0-.376.312-.688.688-.688h1.146v-1.145c0-.376.311-.688.687-.688.376 0 .688.312.688.688v1.145h1.146c.375 0 .687.312.687.688a.692.692 0 01-.688.688z"
      ></path>
    </svg>
  );
};
