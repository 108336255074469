import React from "react";
import useStyles from "./style";

const Cuations = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.caution}>
      <div className={classes.dFlex}>
        <div className={classes.greenBox} />
        <p className={classes.take}>Taken</p>
        <img
          src={"/assets/images/done-icon.png"}
          alt={" "}
          width={15}
          height={15}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.redBox} />
        <p className={classes.missed}>Missed</p>
        <img
          src={"/assets/images/warning.png"}
          alt={" "}
          width={18}
          height={18}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.blueBox} />
        <p className={classes.refused}>Refused</p>
        <img
          src={"/assets/images/unavailable.png"}
          alt={" "}
          width={18}
          height={18}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.grayBox} />
        <p className={classes.upcoming}>Upcoming</p>
        <img
          src={"/assets/images/alert.png"}
          alt={" "}
          width={18}
          height={18}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.yellowBox} />
        <p className={classes.unable}>Unable to Take</p>
        <img
          src={"/assets/images/do-not-disturb.png"}
          alt={" "}
          width={18}
          height={18}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.blackBox} />
        <p className={classes.unable}>Out of Medication</p>
        <img
          src={"/assets/images/close-black.png"}
          alt={" "}
          width={18}
          height={18}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.dFlex}>
        <div className={classes.purpleBox} />
        <p className={classes.prn}>PRN</p>
      </div>
    </div>
  );
};

export default Cuations;
