import { https } from "../http";
import store from "../store";
import { setLoading } from "../store/reducers/note";
import { showError } from "../utils/index";

const getPackages = async (userId, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().post("/package/get-packages" , {userId});
    if (response?.status === 200) {
      // console.log(response?.data);
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { getPackages };
