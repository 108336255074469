import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ChatBot = () => {
    const location = useLocation();

    useEffect(() => {
      const scriptId = "hs-script-loader";
      const allowedPaths = ["/", "/whyogiso", "/pricing", "/contact-us"];
    
      const checkAndRemoveScript = () => {
        const existingScript = document.getElementById(scriptId);
        const id = document.getElementById('hubspot-messages-iframe-container');
        if (id) {
          id.style.opacity = '0%';
        }
        if (existingScript && !allowedPaths.includes(location.pathname)) {
          document.body.removeChild(existingScript);
        }
      };
    
      if (allowedPaths.includes(location.pathname)) {
        const id = document.getElementById('hubspot-messages-iframe-container');
        if (id) {
          id.style.opacity = '100%';
          id.style.cursor = 'none';
        }
        if (!document.getElementById(scriptId)) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.id = scriptId;
          script.src = "//js-na1.hs-scripts.com/47128825.js";
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
        }
      } else {
        checkAndRemoveScript();
      }
        return () => {
        checkAndRemoveScript();
      };
    }, [location]);
};

export default ChatBot;
