import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Modal,
  Select,
  Text,
} from "@mantine/core";
import swal from "sweetalert";
import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import { useDisclosure } from "@mantine/hooks";
import { getColor } from "../../pages/Home/Medication";
import moment from "moment";
import PRNMedicationModal from "../PRNMedicationModal";
import MedicationModal from "../MedicationModal";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { createMar, getMar } from "../../services";

const MarMedicationModal = ({
  data,
  date,
  close,
  opened,
  calendar,
  futureDate,
  currentDate,
}) => {
  const { classes } = useStyles();
  const [btnLoading, setBtnLoading] = useState(false);
  const { selectedClient } = useSelector((state) => state.mar);
  const { profile } = useSelector((state) => state.profile);
  const [selectedMedicine, setSelectedMedicine] = useState([]);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [filteredWeekData, setFilteredData] = useState();

  let userid = profile?.user?.id;
  const form = useForm({
    initialValues: {
      quantity: "0",
      reason: "",
      comments: "",
      outcome: "",
    },
  });

  const selectMedicineHandler = (_v) => {
    if (selectedMedicine?.filter((val) => val?._id == _v?._id)?.length > 0) {
      let filteredMed = selectedMedicine?.filter((val) => val?._id != _v?._id);
      setSelectedMedicine(filteredMed);
    } else {
      setSelectedMedicine([...selectedMedicine, _v]);
    }
  };

  useEffect(() => {
    if (opened) {
      form.setFieldValue("quantity", String(data?.dosage));
    }
  }, []);

  const onSave = async (values, type) => {
    // if (Number(values?.quantity) > 0 && selectedMedicine?.length > 0) {
    const uniqueArray = selectedMedicine?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?.medicationId === value?.medicationId)
    );
    let medicationName = null;
    let redFlag = false;
    let i = 0;
    do {
      let filterMedication = selectedMedicine?.filter(
        (item) => item?.medicationId == uniqueArray[i]?.medicationId
      );

      let count = 0;
      filterMedication?.map((item) => {
        count += Number(item?.form3?.medicationadministered);
      });

      if (count > uniqueArray[i]?.medicineRemaining) {
        redFlag = true;
        medicationName = uniqueArray[i]?.form3?.medicationName;
      }
      i++;
    } while (i < uniqueArray?.length && !redFlag);

    if (redFlag && type === "Taken") {
      swal({
        title: `${medicationName} is overfilled`,
        text: "Medication Overfilled",
        icon: "info",
        dangerMode: true,
        buttons: ["Cancel", "Ok"],
      });
    } else if (!redFlag || type != "Taken") {
      let payload = [];
      selectedMedicine?.map((data) => {
        let obj = {
          client: selectedClient?.id,
          timeSlot: data?.timeSlot,
          date: moment(data?.date).toISOString(),
          medicationId: data?.medicationId,
          medicationType:
            // data?.form3?.medicationType === "PRN" ? "PRN" : values?.outcome,
            data?.form3?.medicationType === "PRN" ? "PRN" : type,
          staff: userid,
          comments: values?.comments,
          // reason: values?.reason,
          marId: data?._id,
          medicationName: data?.form3?.medicationName,
          dosage: data?.form3?.medicationadministered
            ? Number(data?.form3?.medicationadministered)
            : 0,
        };

        //   if (!isValidDate(new Date(data?.date))) {
        //     return showInfo("Please refresh page");
        //   }
        // const dosage = type === "Taken" ? Number(values?.quantity) : 0;
        // values?.outcome === "Taken" ? Number(values?.quantity) : 0;
        payload.push({
          ...obj,
        });
      });
      setBtnLoading(true);
      try {
        const res = await createMar(payload);
        if (res) {
          let calendar_view = localStorage.getItem("calendar_date");
          getMar(calendar_view, selectedClient?.id);
          form.reset();
          close();
        }
      } catch (error) {
        console.error("Error creating MAR:", error.message);
      }
      setBtnLoading(false);
      // } else {
      //   alert("Quantity is Required!");
      // }
    }
  };
  useEffect(() => {
    handleDateChange(currentDate);
  }, [data]);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    const filteredWeekData = data?.filter(
      (item) =>
        moment(item?.date).format("YYYY-MM-DD") ==
        moment(value).format("YYYY-MM-DD")
    );
    setFilteredData(filteredWeekData);
  };

  return (
    <>
      <Modal
        size={700}
        centered
        shadow="md"
        radius={"lg"}
        zIndex={1000}
        opened={opened}
        onClose={close}
        overlayProps={{ blur: 3 }}
        className={classes.marDetailModal}
      >
        <CloseCircle onclose={close} />
        <Box className={classes.header}>
          <Text size="xl" weight={500} ff={"inter"}>
            Select Medication
          </Text>

          {calendar === "weekly" ? (
            <Select
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              nothingFound="No medication found"
              data={date?.map((item, index) => ({
                value: item,
                label: moment(item).format("dddd (MMM DD, YYYY)"),
              }))}
              placeholder={
                currentDate
                  ? moment(currentDate).format("dddd (MMM DD, YYYY)")
                  : "Select a date"
              }
              defaultValue={
                currentDate
                  ? moment(currentDate).format("dddd (MMM DD, YYYY)")
                  : "Select a date"
              }
            />
          ) : (
            <Text c={"dimmed"} ff={"inter"}>
              {date}
            </Text>
          )}
        </Box>
        <Box className={classes.modalWrapper}>
          <Box className={classes.medBody}>
            {(calendar === "weekly" ? filteredWeekData : data)?.length > 0 ? (
              (calendar === "weekly" ? filteredWeekData : data)
                ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
                ?.map((v, i) => (
                  <div
                    className={classes.cardWrapper}
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      checked={selectedMedicine.some(
                        (val) => val?._id === v?._id
                      )}
                      disabled={
                        futureDate === 1 ||
                        v?.medicationType === "Taken" ||
                        v?.medicationType === "Refused" ||
                        v?.medicationType === "Unable to Take" ||
                        v?.medicationType === "Out of Medication" ||
                        v?.medicationType === "PRN"
                      }
                      color="yellow"
                      onClick={() => selectMedicineHandler(v)}
                    />
                    <Card data={v} calendar={calendar} />
                  </div>
                ))
            ) : (
              <Center style={{ height: "350px" }}>No Medication Found</Center>
            )}
          </Box>
        </Box>
        <Box className={classes.buttons}>
          <Box className={classes.topButtons}>
            <Button
              radius="md"
              variant="light"
              onClick={() => onSave(form.values, "Refused")}
              disabled={selectedMedicine?.length === 0 || btnLoading}
            >
              Refused
            </Button>
            <Button
              radius="md"
              color="yellow"
              variant="outline"
              onClick={() => onSave(form.values, "Unable to Take")}
              disabled={selectedMedicine?.length === 0 || btnLoading}
            >
              Unable to Take
            </Button>
            <Button
              c="black"
              radius="md"
              color="gray"
              variant="outline"
              disabled={selectedMedicine?.length === 0 || btnLoading}
              onClick={() => onSave(form.values, "Out of Medication")}
            >
              Out of Medication
            </Button>
          </Box>
          <Button
            fullWidth
            radius="md"
            color="green"
            variant="filled"
            loading={btnLoading}
            onClick={() => onSave(form.values, "Taken")}
            disabled={selectedMedicine?.length === 0 || btnLoading}
          >
            Save as Taken
          </Button>
          <Box></Box>
        </Box>
      </Modal>
    </>
  );
};

const Card = ({ data, calendar }) => {
  console.log("🚀 ~ Card ~ data:", data?.form3);
  console.log("===", data?.medicineRemaining);
  console.log("===", data?.form3?.medicineRemaining);
  const { classes } = useStyles();
  const _medicationType = data?.medicationType;
  const _outOfMedication = "Out of Medication";
  const _upcoming = "upcoming";

  const { selectedClient } = useSelector((state) => state.mar);

  const [opened, { open, close }] = useDisclosure(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  console.log("🚀 ~ Card ~ selectedRow:", selectedRow);
  const [prnShow, setPRNShow] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const prnRef = useRef();

  const compareDate = (date, medicationType, upcoming) => {
    var time1 = moment(date).startOf("day"); // Set time to the start of the day
    var today = moment().startOf("day");
    if (
      calendar !== "weekly" &&
      calendar !== "monthly" &&
      medicationType === upcoming
    ) {
      return -1;
    } else if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  const openPRNView = (data) => {
    setIsNew(false);
    prnRef?.current?.openView(data);
    setPRNShow(true);
  };
  const prnClose = () => {
    setSelectedRow(null);
    setPRNShow(false);
  };

  return (
    <div>
      <div
        className={`${classes.cardContainer} ${
          _medicationType === _outOfMedication ? classes.outOfmedication : ""
        }`}
        style={{
          backgroundColor: getColor(_medicationType)?.dark,
          cursor: compareDate(data?.date) === 1 ? "not-allowed" : "pointer",
          width: "630px",
        }}
        onClick={() => {
          setSelectedRow(data);
          if (data?.form3?.medicationType === "PRN") {
            return openPRNView(data);
          }

          if (compareDate(data?.date) === 1) {
            setIsViewOpen(false);
          } else {
            _medicationType === _upcoming || _medicationType === "Missed"
              ? setIsViewOpen(false)
              : setIsViewOpen(true);
            open();
          }
        }}
      >
        <div className={classes.box1}>
          <div>
            <h3>{data?.form3?.frequency}</h3>
            <p>{data?.timeSlot}</p>
          </div>
          {data?.form3?.isNarcotic == "Yes" && (
            <img src="/assets/images/narcotic.png" alt="" />
          )}
        </div>
        <div className={classes.box2}>
          <div>
            <h2>{data?.form3?.medicationName}</h2>
            <p>
              {data?.form3?.dosage} {data?.form3?.unit}
            </p>
          </div>
          <p>
            {data?.form3?.ingestionMode} {data?.form3?.dosageForm}
          </p>
        </div>
        <div className={classes.box3}>
          <p>Recorded by:</p>
          <p>{data?.staff?.name}</p>
          <div>
            <p>{moment(data?.createdAt).format("h:mm A")}</p>
            {_medicationType === "Taken" && (
              <img
                src={"/assets/images/done-icon.png"}
                style={{ width: "15px", height: "15px" }}
                alt="done"
              />
            )}
            {_medicationType === "Missed" && (
              <img
                src={"/assets/images/warning.png"}
                style={{ width: "15px", height: "15px" }}
                alt="warning"
              />
            )}
            {_medicationType === "Unable to Take" && (
              <img
                src={"/assets/images/do-not-disturb.png"}
                style={{ width: "15px", height: "15px" }}
                alt="do-not-disturb"
              />
            )}
            {_medicationType === "Refused" && (
              <img
                src={"/assets/images/unavailable.png"}
                style={{ width: "15px", height: "15px" }}
                alt="unavailable"
              />
            )}
            {_medicationType === "Out of Medication" && (
              <img
                src={"/assets/images/close-white.png"}
                style={{ width: "15px", height: "15px" }}
                alt="close"
              />
            )}
          </div>
        </div>

        <div
          className={classes.box4}
          style={{
            marginRight: "10px",
            border:
              _medicationType === _outOfMedication
                ? "1px solid #fff"
                : "1px solid black",
          }}
        >
          <h3>{data?.form3?.medicationadministered}</h3>
          <p>Quantity Dispensed</p>
        </div>
        <div
          className={classes.box4}
          style={{
            border:
              _medicationType === _outOfMedication
                ? "1px solid #fff"
                : "1px solid black",
          }}
        >
          <h3>
            {/* {data?.medicineRemaining
              ? data?.form3?.medicineRemaining
              : data?.medicineRemaining} */}
            {data?.form3?.medicineRemaining > 0
              ? data?.form3?.medicineRemaining
                ? data?.form3?.medicineRemaining
                : data?.medicineRemaining
              : "0"}
          </h3>
          <p>
            of {data?.form3?.totalMedicine} <br />
            Remaining
          </p>
        </div>
      </div>
      <PRNMedicationModal
        isNew={isNew}
        ref={prnRef}
        opened={prnShow}
        onclose={prnClose}
        data={selectedClient}
      />
      <MedicationModal
        opened={opened}
        onclose={() => {
          close();
          setIsViewOpen(false);
          setSelectedRow(null);
        }}
        isViewOpen={isViewOpen}
        setIsViewOpen={setIsViewOpen}
        data={selectedRow}
      />
    </div>
  );
};

export default MarMedicationModal;
