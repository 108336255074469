import React from "react";

import {
  Modal,
  ScrollArea,
  Table,
  createStyles,
  Text,
  Button,
  Flex,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RecordNotFound from "../RecordNotFound";
import TicketTableRow from "../TicketTableRow";
import TableLoader from "../TableLoader";
import { getTickets, getUnreadCount, readTickets } from "../../services/ticket";
import CustomPagination from "../Pagination/CustomPagination";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const TicketTable = ({ activeTab }) => {
  const { tickets, loading, count } = useSelector((state) => state.ticket);
  const [Page, setPage] = useState(1);

  useEffect(() => {
    if (activeTab === "ticket") {
      getTickets(Page);
      readTickets();
    } else {
      getUnreadCount();
    }
  }, [Page, activeTab]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const rows = tickets?.map((row, i) => <TicketTableRow row={row} key={i} />);
  const [open, setOpen] = useState(true);

  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      sx={{ marginTop: "30px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <Table
          withColumnBorders
          withBorder
          highlightOnHover
          enableStickyHeader
          sx={{ fontFamily: "Inter" }}
          fontSize={"sm"}
          verticalSpacing={"md"}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th style={{ minWidth: "200px" }}>Ticket #</th>
              <th style={{ minWidth: "140px" }}>Issue Type</th>
              <th style={{ minWidth: "140px" }}>TIcket Date</th>
              <th style={{ minWidth: "140px" }}>Status</th>
              <th style={{ minWidth: "140px" }}>Response</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={1000}>
                  <TableLoader />
                </td>
              </tr>
            ) : rows?.length ? (
              rows
            ) : (
              <tr>
                <td colSpan={1000}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      height: "100vh",
                      gap: "8rem",
                    }}
                  >
                    <RecordNotFound />
                    <p className={classes.notFoundText}>
                      You don&apos;t have any previous ticket!"
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CustomPagination
          data={count}
          itemsPerPage={10}
          currentPage={Page}
          handlePageChange={handlePageChange}
        />
      </div>
    </ScrollArea>
  );
};

export default TicketTable;
