import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  price_card_box: {
    width: '100%',
    display: "flex",
    gap: 50,
    // marginBottom: '2rem',
    justifyContent: "center",
    "@media (max-width: 1024px)": {
      gap: 30
    },
    "@media (max-width:865px)": {
      gap: 20
    },
    "@media (max-width:750px)": {
      flexDirection: "column",
      padding: 30
    }
  },
 
  cardcontainer: {
    width: "57%",
    margin: "auto",
  },
  centered: {
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    fontSize: rem(34),
    fontWeight: 700,
    fontFamily: "Inter",
    marginBottom: rem(10),
  },
  description: {
    fontSize: rem(20),
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#979797",
    // marginBottom: rem(50),
  },
  logo: {
    marginTop: rem(100),
    marginBottom: rem(40),
  },
    back: {
    position: "absolute",
    top: rem(50),
    // left: rem(50),
    fontSize: rem(16),
    fontFamily: "Inter",
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 3rem'
  },
}));

export default useStyles;
