import { Modal, Button, Box, Text, createStyles } from "@mantine/core";
import CloseCircle from "../../../components/CloseCircle";

const useStyles = createStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4ead8",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "80px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
    },
  },
  modal: {
    width: "100%",
    overflow: "auto",
    borderRadius: "1rem 1rem 1rem 1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  text: {
    fontFmily: "Inter",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    fontWeight: "400",
    lineHeight: "25px",
  },
  viewModalContent: {
    margin: "1rem",
    padding: "1rem",
  },
  facilitiesSelect: {
    border: "1px solid #D9D9D9",
    padding: "0 .5rem",
    cursor: "pointer",
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    margin: "0 auto",
    marginTop: 20,
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "1rem",
    textAlign: "center",
  },
}));

const MedicationAskModal = ({
  open,
  close,
  currentPage,
  getAllPatients,
  openMedicalModal,
  setShowClientForm,
}) => {
  const { classes } = useStyles();
  return (
    <>
      <Box style={{ overflowX: "auto" }}>
        <Box style={{ minWidth: "1000px" }}>
          <Modal
            centered
            size="lg"
            shadow="md"
            radius="lg"
            opened={open}
            zIndex={1000}
            onClose={close}
            className={classes.modal}
            overlayProps={{ blur: 3 }}
          >
            <CloseCircle
              onclose={() => {
                setShowClientForm(false);
                getAllPatients(currentPage)
                close();
              }}
            />
            <Box className={classes.header}>
              <Text
                fw={600}
                size={24}
                ff={"Inter"}
                c={"#263238"}
                variant="text"
              >
                Do you want to add a new medication?
              </Text>
            </Box>
            <Box className={classes.viewModalContent}>
              <Box className={classes.buttonBox}>
                <Button
                  id="add-later-medication"
                  radius="md"
                  onClick={() => {
                    setShowClientForm(false);
                    getAllPatients(currentPage)
                    close();
                  }}
                  variant="outline"
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                      borderColor: "#FFBA45",
                    },
                  })}
                >
                  No, Add Later
                </Button>
                <Button
                  radius="md"
                  color="yellow"
                  onClick={() => {
                    openMedicalModal()
                  }}
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                    },
                  })}
                >
                  Yes, Add Medication
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default MedicationAskModal;
