import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  // InlinePadding: {
  //   paddingInline: "3rem",
  //   // maxWidth:"1700px",
  //   // minWidth:"auto",
  //   "@media (max-width: 1250px)": {
  //     paddingInline: "20px",
  //   },
  //   "@media (max-width: 1700px)": {
  //     margin: "0px auto",
  //   },
  // },
  InlinePadding: {
    paddingInline: "6rem",
    "@media (max-width: 768px)": {
      paddingInline: "2rem",
    },
    "@media (max-width: 430px)": {
      paddingInline: "1rem",
    },
  },
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    minWidth: "50%",
    display: "bloc",
  },
  heroSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "4rem 0px",
  },
  primaryHeading: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontSize: "55px",
    fontWeight: "600",
    lineHeight: "76px",
    "@media (max-width: 768px)": {
      fontSize: "28px",
      lineHeight: "46px",
    },
    "@media (max-width: 425px)": {
      lineHeight: "36px",
      margin: "1rem 0",
      fontSize: "28px !important",
    },
  },
  badge: {
    backgroundColor: "transparent",
    border:
      theme.colorScheme === "dark" ? "1px solid gray" : "1px solid #2222221A",
    color: theme.colorScheme === "dark" ? "gray" : theme.black,
    fontSize: "16px",
    fontWeight: "400",
    padding: "20px 30px",
    borderRadius: "15px",
    marginBottom: "10px",
  },
  text: {
    fontFamily: "Inter",
    fontSize: "20px",
    width: "80%",
    fontWeight: 400,
    color: theme.colorScheme === "dark" ? "gray" : "#292828",
    marginTop: "20px",
    textAlign: "center",
    "@media (max-width: 1100px)": {
      fontSize: "17px",
    },
    "@media (max-width: 680px)": {
      width: "90%",
    }
  },
  featureHdng: {
    fontFamily: "Inter",
    fontSize: "50px",
    fontWeight: "600",
    lineHeight: "60px",
    "@media (max-width: 620px)": {
      fontSize:"25px",
      lineHeight:"30px"
    },
  },
  featureText: {
    fontFamily: "Inter",
    fontSize: "20px",
    width: "85%",
    fontWeight: 400,
    color: theme.colorScheme === "dark" ? "gray" : "#292828",
    marginTop: "20px",
    "@media (max-width: 620px)": {
      fontSize:"15px",
    },
  },
  primaryBtn: {
    background: theme.other.primaryBtn,
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "600",
    width: "auto",
    height: "auto",
    lineHeight: "24px",
    padding: "1rem 3rem",
    borderRadius: "8px",
    margin: "24px 0px",
    "&:hover": {
      background: theme.other.primaryBtn,
      color: "#FFFFFF",
    },
    "@media (max-width: 768px)": {
      padding: ".5rem 2rem",
      fontSize: "14px",
      fontWeight: "500",
    },
  },

  features: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  featuresBtn: {
    background: theme.other.white,
    color: theme.other.black,
    borderColor: theme.other.black,
    borderWidth: "2px",
    borderStyle: "solid",
    fontFamily: "Inter",
    fontSize: "63px",
    fontWeight: "600",
    width: "auto",
    height: "auto",
    lineHeight: "76px",
    padding: "1rem 3rem",
    borderRadius: "8px",
    margin: "24px 0px",
    "&:hover": {
      background: theme.other.white,
      color: theme.other.black,
      borderColor: theme.other.black,
    },
    "@media (max-width: 768px)": {
      fontSize: "30px",
      padding: "1rem ",
    },
  },

  featureHighlightCard: {
    width: "90%",
    height: "auto",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "45px 0px",
    borderRadius: "20px",
    "@media (max-width: 1030px)": {
      width: "100%",
    },
  },
  featureHighlightCardHdng: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontSize: "45px",
    fontWeight: "600",
    lineHeight: "50px",
    textAlign:"center",
    "@media (max-width: 1030px)": {
      fontSize: "35px",
      lineHeight: "40px",
    },
    "@media (max-width: 620px)": {
      fontSize: "25px",
      lineHeight: "40px",
    },
  },
  featureHighlightCardText: {
    fontFamily: "Inter",
    fontSize: "20px",
    width: "70%",
    fontWeight: 400,
    marginTop: "20px",
    textAlign: "center",
    "@media (max-width: 620px)": {
      // width: "100%",
      fontSize:"15px"
    },
  },
  MobileFlexDirection: {
    paddingBlock: "5rem",
    "@media (max-width: 800px)": {
      flexDirection: "column-reverse",
      paddingBlock: "2rem",
    },
  },
  logo: {
    width: "80px",
  },
}));
export default useStyles;
