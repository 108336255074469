import React from "react";
import useStyles from "./style";
import { Box, Grid, Text } from "@mantine/core";
import emarImg from "./../../images/eMARImage.png";
import taskImg from "./../../images/taskAndNotes.png";
import reportingImg from "./../../images/reporting.png";
import document from "./../../images/documentsManage.png";
import clientImg from "./../../images/clientIntake.png";
import staffImg from "./../../images/staffManagement.png";
import { useMediaQuery } from "@mantine/hooks";
import { DemoBookButton } from "../../components/BookADemoButton/DemoBookButton";

const FeaturesPage = () => {
  const { classes } = useStyles();
  const isSmallScreen = useMediaQuery("(max-width: 400px)");
  const isMobileScreen = useMediaQuery("(max-width: 834px)");
  const isLabptopScreen = useMediaQuery("(max-width: 1024px)");

  return (
    <Box className={classes.InlinePadding}>
      {isSmallScreen ? (
        <Box className={classes.features}>
          <img
            src="/assets/images/featuresHeadingImg.png"
            alt="featuresHeadingImg"
          />
        </Box>
      ) : (
        <Box className={classes.features}>
          <Box className={classes.featuresBtn}>
          Features
          </Box>
        </Box>
      )}
      <Box>
        {/* emar */}
        <Grid gutter="lg" className={classes.MobileFlexDirection}>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
          >
            <Text className={classes.primaryHeading}>eMAR</Text>
            <Text className={classes.primarySubHeading}>
              Streamline Medication Administration Process
            </Text>
            <Text className={classes.text}>
              Ogiso enhances medication management with a user-friendly <br />{" "}
              eMAR system designed for accuracy and compliance. <br /> Record
              and track medication administration seamlessly, <br /> reduce
              errors, and ensure adherence to protocols.
            </Text>
            <DemoBookButton />
          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img src={emarImg} alt="emarImg" className={classes.img} />
          </Grid.Col>
        </Grid>
        {/* Task Management & Notes */}
        <Grid gutter="xl" >
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img src={taskImg} alt="taskImg" className={classes.img} />
          </Grid.Col>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
            pl={isLabptopScreen ? 20 : 60}
          >
            <Text className={classes.primaryHeading}>
              Task Management <br /> & Notes
            </Text>
            <Text className={classes.primarySubHeading}>
              Stay Organized with Our Task Manager
            </Text>
            <Text className={classes.text}>
              Efficiently track Activities of Daily Living (ADLs) and other
              tasks with our <br /> intuitive task manager. Stay organized and
              ensure timely completion of <br /> essential tasks.
            </Text>
            <Text className={classes.primarySubHeading}>
              Tailored Notes & Outcomes for Effective Care
            </Text>
            <Text className={classes.text}>
              Customize your progress notes to fit your specific needs. Our
              solution <br /> ensures clear and effective communication,
              supporting comprehensive <br /> and personalized patient care.
            </Text>
            <DemoBookButton />

          </Grid.Col>
        </Grid>
        {/* Reporting */}
        <Grid gutter="lg" className={classes.MobileFlexDirection}>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
          >
            <Text className={classes.primaryHeading}>Reporting</Text>
            <Text className={classes.primarySubHeading}>
              Comprehensive Reporting at Your Fingertips
            </Text>
            <Text className={classes.text}>
              Generate detailed and insightful reports for all saved records.{" "}
              <br />
              Our reporting tools provide a comprehensive overview, helping you
              make <br /> informed decisions and maintain accurate records.
            </Text>
            <DemoBookButton />

          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img
              src={reportingImg}
              alt="reportingImg"
              className={classes.img}
            />
          </Grid.Col>
        </Grid>
        {/* Client Intake */}
        <Grid gutter="lg">
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img src={clientImg} alt="clientImg" className={classes.img} />
          </Grid.Col>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
            pl={isLabptopScreen ? 20 : 60}
          >
            <Text className={classes.primaryHeading}>Client Intake</Text>
            <Text className={classes.primarySubHeading}>
              Simplify Client Intake
            </Text>
            <Text className={classes.text}>
              Streamline your client intake process with Ogiso. Capture and{" "}
              <br />
              organize personal, health, care/support, and insurance <br />{" "}
              information in one place, making client management more <br />{" "}
              efficient and accessible.
            </Text>
            <DemoBookButton />

          </Grid.Col>
        </Grid>
        {/* Document Management */}
        <Grid gutter="lg" className={classes.MobileFlexDirection}>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
          >
            <Text className={classes.primaryHeading}>Document Management</Text>
            <Text className={classes.primarySubHeading}>
              Staff & Client Documents in One Place{" "}
            </Text>
            <Text className={classes.text}>
              Efficiently manage all staff and client documents. Stay on top of{" "}
              <br />
              document validity with expiration notifications, ensuring all
              necessary <br /> documentation is up-to-date.
            </Text>
            <DemoBookButton />

          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img src={document} alt="document" className={classes.img} />
          </Grid.Col>
        </Grid>
        {/* Staff Management */}
        <Grid gutter="lg">
          <Grid.Col span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}>
            <img src={staffImg} alt="staffImg" className={classes.img} />
          </Grid.Col>
          <Grid.Col
            span={isMobileScreen ? 12 : 6 || isLabptopScreen ? 6 : 6}
            m={"auto"}
            pl={isLabptopScreen ? 20 : 60}
          >
            <Text className={classes.primaryHeading}>Staff Management</Text>
            <Text className={classes.primarySubHeading}>
              Optimize Team Management
            </Text>
            <Text className={classes.text}>
              Oversee your team with ease. Add or deactivate staff, manage{" "}
              <br />
              documents, assign roles, and generate reports to optimize <br />{" "}
              team management and performance.
            </Text>
            <DemoBookButton />
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};

export default FeaturesPage;
