import { https } from "../http";
import store from "../store";
import {
  setNotificationLoading,
  setNotifications,
  setNotificationsCount,
} from "../store/reducers/notification";
import { showError } from "../utils/index";

const getNotifications = async (payload) => {
  try {
    store.dispatch(setNotificationLoading(true));
    let response = await https().get(
      `/notification/get-all?page=${payload?.page}&perPage=${payload?.perPage}`
    );
    if (response) {
      store.dispatch(setNotifications(response.data));
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    store.dispatch(setNotificationLoading(false));
    return false;
  }
};

const getNotificationsCount = async (payload) => {
  try {
    // store.dispatch(setNotificationLoading(true));
    let response = await https().get(`/notification/get-count`);
    if (response) {
      store.dispatch(setNotificationsCount(response.data));

      // store.dispatch(setNotifications(response.data));
    }
  } catch (error) {
    // let _error = error?.response?.data?.message;
    // showError(_error, error?.response?.status);
    // store.dispatch(setNotificationLoading(false));
    return false;
  }
};

export { getNotifications, getNotificationsCount };
