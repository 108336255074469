import { Box, Button, Group, Text, createStyles } from "@mantine/core";
import { useSelector } from "react-redux";
import { updateSubscriptionCustomer } from "../../services/subscription";
import swal from "sweetalert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderColor: "#97979726",
    borderWidth: 1,
    borderRadius: 14,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
  },
  wrapper_select: {
    background: "#232323",
  },
  plantype: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 500,
    color: "#232323",
  },
  price: {
    fontFamily: "Inter",
    fontSize: 22,
    color: "#232323",
    fontWeight: 600,
  },
  unit: {
    fontFamily: "Inter",
    fontSize: 25,
    color: "#979797",
  },
  period: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#979797",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    margin: 20,
  },
  pricetag: {
    marginLeft: "auto",
    margin: "1px solid green",
    fontSize: "13 !important",
  },
  desc: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#979797",
  },
  descbox: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 15,
  },
  btnbox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  btn: {
    // width: 200,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    marginLeft: "auto",
  },
  reNewbtn: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 500,
    margin: 10,
    width: 180,
  },
  billing: {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#979797",
  },
  cycle: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "white",
  },
  selectedtype: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 500,
    color: "white",
  },
  selectedPrice: {
    fontFamily: "Inter",
    fontSize: 22,
    color: "white",
    fontWeight: 600,
  },
}));

const PaymentCard = ({ data, connected, newAccount, callback }) => {
  console.log("🚀 ~ PaymentCard ~ data:", data);
  const noCard = connected === null || connected === undefined ? true : false;
  let newCard = connected?.find((v) => v?.creditCard);
  const { profile } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let {
    creditCard,
    isActive,
    cardName,
    cardNumber,
    accountNumber,
    routingNumber,
  } = data;
  const { classes, cx } = useStyles();

  const useThisAccount = async () => {
    let startDate = moment();
    let endDate = moment(profile?.subscription?.expiredAt);
    let daysRemaining = endDate.diff(startDate, "days");
    const isTrial = Number(profile?.subscription?.packageId?.cost) === 0;
    let text =
      Number(daysRemaining) > 0
        ? `You have ${daysRemaining} days remaining, Clicking confirm would cancel your current subscription and renew.\nPlease note, downgrading a subscription will kick in the following subscription period.`
        : `Clicking confirm would cancel your current subscription and renew.\nPlease note, downgrading a subscription will kick in the following subscription period.`;
    const trialText = `Do you want to Upgrade? Please select Plan from the "Upgrade Plan" tab.`;

    let buttons = ["Cancel"];
    if (!isTrial) buttons?.push("Change Account");

    swal({
      title: `Change Account for "${profile?.subscription?.packageId?.name}" Plan`,
      text: isTrial ? trialText : text,
      icon: "warning",
      dangerMode: isTrial ? false : true,
      buttons: buttons,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        if (!isTrial) {
          setLoading(true);
          let obj = {
            stripeSubscriptionId: profile.subscription?.stripeSubscriptionId,
            customer: data?.stripeCustomerId,
          };
          let response = await updateSubscriptionCustomer(obj);
          if (response.status === 200) {
            callback?.();
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    });

    // console.log("response?.data", response?.data);
  };

  const addNewAccount = async () => {
    navigate("/add-payment");
    // navigate("/payInfo?connect=true");
  };

  if (newAccount)
    return (
      <Box
        className={
          isActive
            ? cx(classes.wrapper, classes.wrapper_select)
            : classes.wrapper
        }
      >
        <Box className={classes.title}>
          <Group>
            <img
              src={"/assets/images/personal.png"}
              alt=""
              width={40}
              height={40}
            />
            <Text
              className={isActive ? classes.selectedtype : classes.plantype}
            >
              Add New Card Information
              {/* {noCard
                ? "Add Payment Details"
                : connected?.length === 1 && newCard
                ? "Add Bank Account"
                : "Add New Card"} */}
            </Text>
          </Group>
        </Box>
        <Box className={classes.descbox}>
          <Text className={classes.desc}>
            Follow the steps to add a new card.
            {/* {noCard
              ? "Follow the steps to add new payment method"
              : !creditCard
              ? `Follow the steps to add a new card.`
              : `Follow the steps to add New Bank Account.`} */}
          </Text>
        </Box>
        <Box className={classes.btnbox}>
          {!isActive && connected?.length > 1 && (
            <Button
              onClick={useThisAccount}
              className={classes.btn}
              color="yellow"
              loading={loading}
              radius={8}
            >
              Use This Account
            </Button>
          )}
          <Button
            onClick={addNewAccount}
            className={classes.btn}
            color="yellow"
            radius={8}
          >
            Proceed
          </Button>
        </Box>
        {/* {activeId?.id == packageId && isSubscriptionExpired() && (
    <Button
      loading={loading}
      onClick={reNewSubscription}
      className={classes.reNewbtn}
      color="yellow"
      radius={8}
    >
      Renew Subscription
    </Button>
  )} */}
      </Box>
    );

  return (
    <Box
      className={
        isActive ? cx(classes.wrapper, classes.wrapper_select) : classes.wrapper
      }
    >
      <Box className={classes.title}>
        <Group>
          <img
            src={"/assets/images/personal.png"}
            alt=""
            width={40}
            height={40}
          />
          <Text className={isActive ? classes.selectedtype : classes.plantype}>
            {creditCard ? "Card" : "Bank Account"}
          </Text>
        </Group>
      </Box>
      <Box className={classes.descbox}>
        <Text className={classes.desc}>
          {creditCard
            ? `Card Holder Name: ${cardName || ""}`
            : `Account Number: ${accountNumber || ""}`}
        </Text>
        <Text className={classes.desc}>
          {creditCard
            ? `Card Number: ${cardNumber || ""}`
            : `Routing Number: ${routingNumber || ""}`}
        </Text>
      </Box>
      <Box className={classes.btnbox}>
        {!isActive && connected?.length > 1 && (
          <Button
            onClick={useThisAccount}
            className={classes.btn}
            color="yellow"
            loading={loading}
            radius={8}
          >
            Use This Account
          </Button>
        )}
      </Box>
      {/* {activeId?.id == packageId && isSubscriptionExpired() && (
        <Button
          loading={loading}
          onClick={reNewSubscription}
          className={classes.reNewbtn}
          color="yellow"
          radius={8}
        >
          Renew Subscription
        </Button>
      )} */}
    </Box>
  );
};

export default PaymentCard;
