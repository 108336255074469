import React from "react";

export const FourIconSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.656 28.016H59.952V54.32H67.184V60.784H59.952V70H52.464V60.784H31.408V55.088L52.656 28.016ZM52.848 39.216L40.368 55.088H52.848V39.216Z"
        fill="#FFBA45"
      />
      <rect x="0.5" y="0.5" width="99" height="99" rx="49.5" stroke="#FFBA45" />
    </svg>
  );
};
