import {
  Modal,
  Button,
  Box,
  Text,
  createStyles,
  Checkbox,
} from "@mantine/core";
import { useEffect, useState } from "react";
import CloseCircle from "../../../components/CloseCircle";
import { editPatientFacilitiy } from "../../../services/patient";

const useStyles = createStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4ead8",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    height: "80px",
    gap: "20px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
    },
  },
  modal: {
    width: "100%",
    overflow: "auto",
    borderRadius: "1rem 1rem 1rem 1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
    "& .mantine-Modal-header": {
      display: "none",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
    "& .mantine-1q36a81": {
      padding: "0",
    },
  },
  viewModalContent: {
    margin: "1rem",
    padding: "1rem",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  facilitiesCheckboxList: {
    width: "fit-content",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    gap: "1rem",
    border: "1px solid #ccc",
    padding: "2rem 4rem",
    margin: "0 auto",
    borderRadius: "3px",
  },
  facilitiesSelect: {
    border: "1px solid #D9D9D9",
    padding: "0 .5rem",
    cursor: "pointer",
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    margin: "0 auto",
    marginTop: 20,
  },
  error: {
    color: "red",
    marginTop: "10px",
    textAlign: "center",
  },
}));

const FacilityModal = ({
  close,
  title,
  onNext,
  opened,
  options,
  editPatient,
  editFacility,
  setEditingPatient,
  setUpdateFacility,
  setPopulateFacility,
  initialSelectedFacilities,
}) => {
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { classes } = useStyles();

  const handleContinue = () => {
    if (selectedFacilities.length === 0) {
      setError("Please select at least one facility before continuing.");
    } else {
      setPopulateFacility(selectedFacilities);
      onNext(selectedFacilities);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    const res = await editPatientFacilitiy(editPatient?.id, selectedFacilities);
    setLoading(false);
    if (res) {
      setEditingPatient({...editPatient, facilityId: res?.data?.facilities})
      setPopulateFacility(selectedFacilities);
      setUpdateFacility(selectedFacilities)
      console.log("")
      handleClose();
    }
  };



  useEffect(() => {
    if (opened) {
      if (editFacility) {
        setSelectedFacilities(editFacility || []);
      } else {
        setSelectedFacilities(initialSelectedFacilities || []);
      }
    }
  }, [opened, initialSelectedFacilities]);

  const handleFacilityChange = (value) => {
    if (!editFacility) {
      setSelectedFacilities((prev) => {
        if (prev?.includes(value)) {
          return prev?.filter((facility) => facility !== value);
        } else {
          return [...prev, value];
        }
      });
    } else {
      setSelectedFacilities((prev) => {
        if (prev?.includes(value?.id)) {
          return prev?.filter((facility) => facility !== value?.id);
        } else {
          return [...prev, value?.id];
        }
      });
    }
    setError("");
  };

  const handleClose = () => {
    setSelectedFacilities(null);
    setError("");
    close();
  };

  return (
    <>
      <Box style={{ overflowX: "auto" }}>
        <Box style={{ minWidth: "1000px" }}>
          <Modal
            centered
            size="xl"
            shadow="md"
            radius={"lg"}
            zIndex={1000}
            opened={opened}
            onClose={handleClose}
            className={classes.modal}
            overlayProps={{ blur: 5 }}
          >
            <CloseCircle onclose={handleClose} />
            <Box className={classes.header}>
              <Text variant="text" fw={600} size={24}>
                {title}
              </Text>
            </Box>
            <Box className={classes.viewModalContent}>
              <Box className={classes.checkboxWrapper}>
                <Box className={classes.facilitiesCheckboxList}>
                  {options?.map((option, index) => (
                    <Box key={index} mb="sm">
                      <Checkbox
                        size="md"
                        color="yellow"
                        value={option?.id}
                        label={option?.name}
                        checked={
                          editFacility
                          ? selectedFacilities?.includes(option?.id)
                          : selectedFacilities?.includes(option)
                        }
                        onChange={() => handleFacilityChange(option)}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>

              {error && <Text className={classes.error}>{error}</Text>}
              <Box className={classes.buttonBox}>
                <Button
                  radius="md"
                  variant="outline"
                  onClick={handleClose}
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                      borderColor: "#FFBA45",
                    },
                  })}
                >
                  Cancel
                </Button>
                <Button
                  radius="md"
                  color="yellow"
                  loading={loading}
                  id="continue-modal"
                  styles={{ root: { color: "#000" } }}
                  onClick={editFacility ? handleUpdate : handleContinue}
                >
                  {editFacility ? "Update" : "Continue"}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default FacilityModal;
