import {
  Button,
  Group,
  Modal,
  Radio,
  Select,
  TextInput,
  Textarea,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";

import swal from "sweetalert";

import { DateInput, DatePickerInput } from "@mantine/dates";

import moment from "moment";
import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IconChevronDown } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { AddMedication, EditMedication } from "../../services/patient";
import { decrypt, showError } from "../../utils";
import useStyles from "./style";
import CloseCircle from "../CloseCircle";
import NarcoticBox from "../NarcoticBox";
import { Dropzone } from "@mantine/dropzone";
import ScheduleMenu from "./ScheduleMenu";
import { useDisclosure } from "@mantine/hooks";
import ConfirmationModal from "../ConfirmationModal";

const bgColor = "rgba(242, 242, 242, 0.6)";

const AddNewMedicationModal = ({
  opened,
  onclose,
  userId,
  selectedClient,
  data,
  isPreview,
  currentPage,
  getAllPatients,
  setShowClientForm,
  getClientData
}) => {
  const { classes } = useStyles();
  const client = useSelector((state) => state.mar.selectedClient);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("scheduled");
  const [files, setFiles] = useState([]);
  const [frequencyData, setFrequencyData] = useState([
    "QAM",
    "QPM",
    "Q4HR",
    "Q2HR",
    "Q6HR",
    "Q8HR",
    "Q12HR",
    "AC",
    "PC",
    "BID",
    "TID",
    "QID",
    "STAT",
    "QOD",
    "Noon",
    "QD",
    "5x/day", "6x/day", "AC", "AC HS", "AC breakfast", "AC breakfast/dinner", "AC breakfast/lunch", "AC dinner", "AC lunch", "AC lunch/dinner",
    "BID", "BID AC", "BID HS", "BID PC", "BID w/meals", "Daily", "Daily AC", "Every other day", "Every other evening", "On call", "Once", "PC", "PC HS", "q2h", "q2min", "q30min", "q36hr", "q3day", "q3h", "q3min", "q48h", "q4h", "q5min", "q6h", "q6min", "q72h", "q4day", "q5day", "q7day", "q10day", "q14day", "q21day", "q28day", "q7min", "q8h", "q8min", "q96hrs", "q2week", "PC breakfast", "PC breakfast/dinner", "PC breakfast/lunch", "PC dinner", "PC lunch", "PC lunch/dinner", "QID", "TID", "TID AC", "TID HS", "TID PC", "TID w/meals", "Today", "UD", "Before discharge", "p each loose BM", "q10min", "q12h", "q15min", "q1h", "q1min", "q20min", "q24h", "q3week", "q4week", "q6week", "q2month", "q3month", "q4month", "q6month", "qAM", "qPM", "qAM HS", "qAM PM", "qHS", "qmonth", "qweek", "qyear", "qShift", "w/breakfast", "w/breakfast/dinner", "w/breakfast/lunch", "w/dinner", "w/lunch", "w/lunch/dinner", "w/meals", "w/meals HS", "x1",
    "Daily",
    "Once Weekly",
    "Once Monthly",
  ])
  const [selectedDays, setSelectedDays] = useState(
    data?.repeatOn || [moment().format("dddd")]
  );

  console.table({
    "AdNewMedicationModal Keys": `AdNewMedicationModal Values`,
    opened: opened,
    onclose: onclose,
    userID: userId,
    data: data,
    isPreview: isPreview,
  });

  const [confirmModal, { open: confirmOpen, close: confirmClose }] =
    useDisclosure(false);

  const validation = () => {
    const _validation = {
      isPrescribed: (value) => (value ? null : "Required"),
      isNarcotic: (value) => (value ? null : "Required"),
      medicationName: (value) => (value ? null : "Required"),
      medicationType: (value) => (value ? null : "Required"),
      // dosage: (value) => (value ? null : "Required"),
      // unit: (value) => (value ? null : "Required"),
      // frequency: (value) => (value ? null : "Required"),
      ingestionMode: (value) => (value ? null : "Required"),
      totalMedicine: (value) => (value ? null : "Required"),
      medicationadministered: (value) => (value ? null : "Required"),
      medicationInstruction: (value) => (value ? null : "Required"),
      medicationDate: (value) => (value ? null : "Required"),
      medicineRemaining: (value) => (value ? null : "Required"),
      timeSlots: (value) => (value ? null : "Required"),
    };
    if (data) {
      delete _validation?.medicineRemaining;
    } else if (type === "PRN") {
      delete _validation?.timeSlots;
    }
    return _validation;
  };
  // const _initialValues = {
  //   isPrescribed: "Yes",
  //   isNarcotic: "Yes",
  //   medicationName: "Aleoya",
  //   medicationType: "scheduled",
  //   dosage: 200,
  //   unit: "mg",
  //   frequency: "Q4HR",
  //   ingestionMode: "Buccal",
  //   dosageForm: "Tablet",
  //   totalMedicine: 200,
  //   medicationUse: "qwertyu",
  //   medicationInstruction: "qwerty",
  //   responsibleParty: "Family",
  //   administering: "Family",
  //   medicationDate: new Date("2024/07/28"),
  //   expirationDate: new Date("2024/09/30"),
  //   timeSlots: ["02:00 AM", "08:00 PM"],
  //   medicineRemaining: 180,
  //   medicationadministered: 3,
  //   orderDate: null,
  //   repeatEvery: 1,
  //   repeatType: "week",
  //   repeatOn: [
  //     moment("2024/07/29").format("dddd"),
  //     moment("2024/07/30").format("dddd"),
  //     moment("2024/07/31").format("dddd"),
  //   ],
  //   scheduleType: "custom",
  // };
  const _initialValues = {
    isPrescribed: "",
    isNarcotic: "",
    medicationName: "",
    medicationType: "",
    dosage: "",
    unit: "",
    frequency: "",
    ingestionMode: "",
    dosageForm: "",
    totalMedicine: "",
    medicationUse: "",
    medicationInstruction: "",
    responsibleParty: "",
    administering: "",
    medicationDate: new Date(),
    expirationDate: null,
    timeSlots: [],
    medicineRemaining: "",
    medicationadministered: "",
    orderDate: null,
    repeatEvery: 1,
    repeatType: "day",
    repeatOn: [moment().format("dddd")],
  };
  const form = useForm({
    initialValues: _initialValues,
    validate: validation(),
  });

  useEffect(() => {
    if (data) {
      form.setValues({
        ...data,
        medicineRemaining: data?.medicineRemaining,
        ingestionMode: data?.ingestionMethod || data?.ingestionMode,
        medicationDate: data?.medicationDate
          ? new Date(data?.medicationDate)
          : null,
        expirationDate:
          data?.medicationEndDate && data?.medicationEndDate != "null"
            ? new Date(data?.medicationEndDate)
            : null,
        repeatEvery: Number(data?.repeatEvery || 1),
        orderDate:
          data?.orderDate &&
          data?.orderDate != "null" &&
          data?.orderDate != "undefined"
            ? new Date(data?.orderDate)
            : null,
      });
    }
  }, [data]);

  const reset = () => {
    getClientData?.()
    setLoading(false);
    form.reset();
    onclose();
    setFiles([]);
  };

  const handleDateConverter = (date, timeslot = null) => {
    const currentDate = new Date(date);
    const timeString = timeslot;

    if (timeslot) {
      const [time, modifier] = timeString.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      let newdate = currentDate.setHours(hours, minutes, 0, 0);
      // console.log("newdate : ", newdate);
      return moment(newdate);
    } else {
      return moment(date);
    }
  };

  const setScheduleDates = (values) => {
    const _format = "YYYY-MM-DD";
    // let _startDate = moment.tz(moment(values?.medicationDate), moment.tz.guess()).tz("UTC");
    let _startDate = moment(values?.medicationDate);
    let _endDate = values?.expirationDate
      ? moment(values?.expirationDate)
      : moment().endOf("year");

    let _dateRange = [];
    let _currentDate = _startDate.clone();
    let _finalDateWithTimeSlots = [];

    if (values?.scheduleType === "custom") {
      if (values?.repeatType === "day") {
        // For Day
        while (_currentDate.isSameOrBefore(_endDate, "day")) {
          _dateRange.push(_currentDate.format(_format));
          _currentDate.add(values?.repeatEvery, "day");
        }

        _dateRange?.forEach((date) => {
          values?.timeSlots.forEach((time) => {
            _finalDateWithTimeSlots.push({
              date: moment(date).toISOString(),
              timeslot: time,
            });
            // _finalDateWithTimeSlots.push(
            //     moment.tz(moment(date + " " + time), user?.timeZone)?.toISOString()
            //     // moment(date + " " + time, "YYYY-MM-DD hh:mm A")?.toISOString()
            // );
          });
        });
      } else if (values?.repeatType === "week") {
        // For Week
        while (_currentDate.isSameOrBefore(_endDate, "day")) {
          let days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          let days_dates = Array.from({ length: 7 }, (_, i) =>
            _currentDate.clone().startOf("week").add(i, "days")
          );
          values?.repeatOn?.map((day) => {
            let index = days?.indexOf(day);
            if (
              index !== -1 &&
              moment(days_dates[index]).isSameOrAfter(_startDate) &&
              moment(days_dates[index]).isSameOrBefore(_endDate)
            ) {
              _dateRange.push(moment(days_dates[index]).toISOString());
              // _dateRange.push(moment(days_dates[index]).tz(user?.timeZone).format(_format));
            }
          });
          _currentDate.add(values?.repeatEvery, "week");
        }

        _dateRange?.forEach((date) => {
          values?.timeSlots?.forEach((time) => {
            // _finalDateWithTimeSlots.push(
            //     moment(date + " " + time, "YYYY-MM-DD hh:mm A")?.toISOString()
            // );
            // if (serverFeTimeDiff > 20000) {
            //     console.log("🚀 ~ values?.timeSlots?.split ~ diff if:", diff)
            // } else {
            //     console.log("🚀 ~ values?.timeSlots?.split ~ diff else:", diff)

            // _finalDateWithTimeSlots.push(
            //     moment(date + " " + "00:00 AM" , "YYYY-MM-DD hh:mm A").tz("UTC").subtract(Math.abs(diff) , "hours").add(2 , "hours")?.toISOString()
            //   );

            // theory how it will work
            //  we need to cater three timeZones
            //  BE = KHI, FE = Brazil, UTC TimeZone
            //  first of all calculate the difference between BE and FE timezone here = 8 hours
            //  after
            //

            // let currentDate = moment(date, "YYYY-MM-DD").tz(user?.timeZone) // first convert this value to FE Timezone then you have to add/subtract 8 hours BE FE Timezone difference
            // let timeslot;

            // if (serverFeTimeDiff >= 0) {
            //     timeslot = currentDate.add(serverFeTimeDiff , 'hours').format("hh:mm A")
            //     currentDate = currentDate.add(serverFeTimeDiff , 'hours').tz("UTC")
            // } else {
            //     timeslot = currentDate.subtract(Math.abs(serverFeTimeDiff) , 'hours').format("hh:mm A")
            //     currentDate = currentDate.subtract(Math.abs(serverFeTimeDiff) , 'hours').tz("UTC")
            // }
            // then get start of day
            // then add the fixed hours like 02:00 AM == 2 hours
            // then convert it to UTC and save in server

            // currentDate = currentDate.startOf('day')

            // console.log('frontend time start day', currentDate)
            // currentDate = currentDate.add(2, "hours")
            // console.log(' added', currentDate)
            // console.log(' added', moment(currentDate).tz(user.timeZone))

            // currentDate = currentDate.tz("UTC")
            // console.log('timeslot ',timeslot)

            let final = moment
              .tz(handleDateConverter(date, time), moment.tz.guess())
              .tz("UTC")
              .toISOString();

            _finalDateWithTimeSlots.push({
              // date :  handleDateConverter(date,time)?.toISOString(),
              date: final,
              timeslot: time,
            });

            // }
          });
        });
      } else {
        // For Month
        while (_currentDate.isSameOrBefore(_endDate, "day")) {
          _dateRange.push(_currentDate.format(_format));
          _currentDate.add(values?.repeatEvery, "month");
        }

        _dateRange?.forEach((date) => {
          values?.timeSlots?.split(",").forEach((time) => {
            _finalDateWithTimeSlots.push({
              date: moment(date).toISOString(),
              timeslot: time,
            });
            // _finalDateWithTimeSlots.push(
            //     moment.tz(moment(date + " " + time), user?.timeZone)?.toISOString()
            //     // moment(date + " " + time, "YYYY-MM-DD hh:mm A")?.toISOString()
            // );
          });
        });
      }
    }

    // console.log("_dateRange", _dateRange);
    // console.log("_finalDateWithTimeSlots", _finalDateWithTimeSlots);
    return {
      scheduleDates: _finalDateWithTimeSlots,
      startDate: _startDate,
      endDate: _endDate,
    };
  };

  const onSave = async (values) => {
    if (
      (Number(values?.medicineRemaining) || 0) > Number(values?.totalMedicine)
    ) {
      return swal(
        "Error",
        "Current Medication Count cannot exceed Medication Total Quantity.",
        "error"
      );
    }

    if (
      Number(values?.medicationadministered) >
        Number(values?.medicineRemaining) &&
      !data
    ) {
      return swal(
        "Error",
        "Administration Count cannot exceed Current Medication Count.",
        "error"
      );
    }

    let clientId = selectedClient ? selectedClient : client?.id;

    if (data) {
      const checkArrays = (arr1, arr2) => {
        if (
          !Array.isArray(arr1) ||
          !Array.isArray(arr2) ||
          arr1.length !== arr2.length
        ) {
          return true;
        }

        for (let i = 0; i < arr1.length; i++) {
          if (arr2.indexOf(arr1[i]) == -1) {
            return true;
          }
          // if (arr1[i] !== arr2[i]) {
          //   return true;
          // }
        }

        return false;
      };
      const _format = "YYYY-MM-DD";
      const _medicationType = data?.medicationType !== values?.medicationType;
      const _startDate =
        moment(data?.medicationDate).format(_format) !==
        moment(values?.medicationDate).format(_format);
      const _endDate =
        data?.expirationDate &&
        data?.expirationDate != "null" &&
        moment(data?.expirationDate).format(_format) !==
          moment(values?.expirationDate).format(_format);
      const _timeSlots = checkArrays(data?.timeSlots, values?.timeSlots);
      const _repeatOn =
        data?.scheduleType === "custom"
          ? checkArrays(data?.repeatOn, values?.repeatOn)
          : false;

      const _scheduleType =
        data?.scheduleType && values?.scheduleType
          ? data?.scheduleType !== values?.scheduleType
          : false;
      const _repeatEvery =
        data?.scheduleType === "custom"
          ? Number(data?.repeatEvery) &&
            Number(data?.repeatEvery) != Number(values?.repeatEvery)
          : false;
      const _repeatType =
        data?.scheduleType === "custom"
          ? data?.repeatType && data?.repeatType !== values?.repeatType
          : false;

      // console.log(
      //   {  _medicationType,
      //     _startDate,
      //     _endDate,
      //     _timeSlots,
      //     _scheduleType,
      //     _repeatEvery,
      //     _repeatType,
      //     _repeatOn});

      if (
        _medicationType ||
        _startDate ||
        _endDate ||
        _timeSlots ||
        _scheduleType ||
        _repeatEvery ||
        _repeatType ||
        _repeatOn
      ) {
        confirmOpen();
      } else {
        // console.log("fine");
        handleEdit(false);
      }
    } else {
      if (!values?.timeSlots?.length && values?.medicationType != "PRN")
        return showError("Select time slots for medicine.");

      const { scheduleDates } = setScheduleDates(values);

      const _formData = new FormData();
      files?.length > 0 && _formData.append("file", files[0]);
      _formData.append("administering", values?.administering);
      _formData.append("dosage", values?.dosage);
      _formData.append("dosageForm", values?.dosageForm);
      _formData.append(
        "expirationDate",
        // values?.expirationDate ? values?.expirationDate?.toISOString() : null
        values?.expirationDate ? moment(values?.expirationDate)?.endOf("D")?.toISOString() : null
        // values?.expirationDate ? moment(values?.expirationDate)?.endOf("D") : null
      );
      _formData.append("frequency", values?.frequency);
      _formData.append("ingestionMode", values?.ingestionMode);
      _formData.append("isNarcotic", values?.isNarcotic);
      _formData.append("isPrescribed", values?.isPrescribed);
      _formData.append(
        "medicationDate",
        // values?.medicationDate ? values?.medicationDate?.toISOString() : null
        values?.medicationDate ? moment(values?.medicationDate)?.startOf("D")?.toISOString() : null
        // values?.medicationDate ? moment(values?.medicationDate)?.startOf("D") : null
      );

      // Start of Day on Frontend Date with 00:00:00 1 Aug 2024 Karachi Time.

      _formData.append("medicationInstruction", values?.medicationInstruction);
      _formData.append("medicationName", values?.medicationName);
      _formData.append("medicationType", values?.medicationType);
      _formData.append("medicationUse", values?.medicationUse);
      _formData.append(
        "medicationadministered",
        values?.medicationadministered
      );
      _formData.append(
        "medicineRemaining",
        Number(values?.medicineRemaining) || 0
      );
      _formData.append("orderDate", values?.orderDate?.toISOString());
      _formData.append("responsibleParty", values?.responsibleParty);
      _formData.append("timeSlots", values?.timeSlots);
      _formData.append("totalMedicine", values?.totalMedicine);
      _formData.append("unit", values?.unit);
      _formData.append(
        "medicineProvided",
        Number(values?.medicineRemaining) || 0
      );
      _formData.append("id", Date.now());
      if (values?.scheduleType === "custom") {
        _formData.append("scheduleType", values?.scheduleType);
        _formData.append("repeatEvery", values?.repeatEvery);
        _formData.append("repeatType", values?.repeatType);
        _formData.append("repeatOn", values?.repeatOn);
      } else {
        _formData.append("scheduleType", "daily");
      }
      _formData.append(
        "medicationEndDate",
        values?.expirationDate
          // ? moment(values?.expirationDate)?.toISOString()
          ? moment(values?.expirationDate)?.endOf("D")?.toISOString()
          // ? moment(values?.expirationDate)?.endOf("D")
          : moment().endOf("year").toISOString()
      );

      _formData.append("scheduleDates", JSON.stringify(scheduleDates));

      console.log("values medicationDate", values?.medicationDate)
      console.log("values medicationDate", moment(values?.medicationDate)?.startOf("day").toISOString())
      console.log("values medicationDate", moment(values?.medicationDate)?.startOf("day").format())
      console.log("values medicationDate", moment(values?.medicationDate)?.startOf("day").toDate().toISOString())

      // console.log("med end date", moment(values?.expirationDate)?.toISOString())
      // console.log("med end date", moment(values?.expirationDate)?.endOf("D")?.toISOString())

      setLoading(true);
      await AddMedication(_formData, clientId, false, null, reset);
      setLoading(false);

      if (selectedClient) {
        setShowClientForm(false);
        getAllPatients(currentPage);
        onclose();
      }

      // console.log(
      //   "formData onClick =>",
      //   moment(values?.expirationDate)?.toISOString()
      // );

      // console.log("formData startDate =>", moment(values?.medicationDate)?.toISOString())
    }
  };

  const handleEdit = async (confirm) => {
    if (confirmModal) {
      confirmClose();
    }
    const values = form.values;
    let current = moment().startOf("day");
    const { scheduleDates } = setScheduleDates({
      ...values,
      medicationDate: current,
    });
    console.log("🚀 ~ handleEdit ~ scheduleDates:", scheduleDates);
    // const { scheduleDates } = setScheduleDates(values)

    const _formData = new FormData();
    files?.length > 0 && _formData.append("file", files[0]);
    _formData.append("administering", values?.administering);
    _formData.append("dosage", values?.dosage);
    _formData.append("dosageForm", values?.dosageForm);
    _formData.append(
      "expirationDate",
      values?.expirationDate ? values?.expirationDate?.toISOString() : null
    );
    _formData.append("frequency", values?.frequency);
    _formData.append("ingestionMode", values?.ingestionMode);
    _formData.append("isNarcotic", values?.isNarcotic);
    _formData.append("isPrescribed", values?.isPrescribed);
    _formData.append(
      "medicationDate",
      values?.medicationDate ? values?.medicationDate?.toISOString() : null
    );
    _formData.append("medicationInstruction", values?.medicationInstruction);
    _formData.append("medicationName", values?.medicationName);
    _formData.append("medicationType", values?.medicationType);
    _formData.append("medicationUse", values?.medicationUse);
    _formData.append("medicationadministered", values?.medicationadministered);
    _formData.append("medicineRemaining", Number(data?.medicineRemaining) || 0);
    _formData.append("orderDate", values?.orderDate?.toISOString());
    _formData.append("responsibleParty", values?.responsibleParty);
    _formData.append("timeSlots", values?.timeSlots);
    _formData.append("totalMedicine", values?.totalMedicine);
    _formData.append("unit", values?.unit);
    _formData.append("medicineProvided", Number(data?.medicineRemaining) || 0);
    _formData.append("id", data?.id);
    if (values?.scheduleType === "custom") {
      _formData.append("scheduleType", values?.scheduleType);
      _formData.append("repeatEvery", values?.repeatEvery);
      _formData.append("repeatType", values?.repeatType);
      _formData.append("repeatOn", values?.repeatOn);
    } else {
      _formData.append("scheduleType", "daily");
    }
    _formData.append("confirm", confirm);
    _formData.append(
      "medicationEndDate",
      values?.expirationDate
        ? moment(values?.expirationDate)?.toISOString()
        : moment().endOf("year").toISOString()
    );
    _formData.append("scheduleDates", JSON.stringify(scheduleDates));

    setLoading(true);
    await EditMedication(_formData, client?.id, false, reset);
    setLoading(false);
  };

  const formatString = (str) => {
    const words = str?.split("");
    if (words?.length <= 15) {
      return str;
    }
    const startWords = words?.slice(0, 10)?.join("");
    const endWords = words?.slice(-10)?.join("");
    return `${startWords} ... ${endWords}`;
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleDownloadFile = async (url) => {
    try {
      if (url) {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url?.split("/")?.pop();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      } else {
        console.error("No url found");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Modal
      className={classes.addMedicationModal}
      opened={opened}
      size={1000}
      onClose={() => {
        onclose();
        form?.reset();
      }}
      shadow="md"
      overlayProps={{ blur: 3 }}
      radius={"lg"}
      zIndex={1000}
      centered
    >
      <CloseCircle onclose={onclose} />

      <form
        className={classes.modalBody}
        onSubmit={form?.onSubmit((values) => onSave(values))}
      >
        <div className={classes.header}>
          <img src={"/assets/images/pills-icon.png"} />
          <h3>
            {data
              ? isPreview
                ? "Preview Medication"
                : "Change Medication"
              : "Add New Medication"}
          </h3>
        </div>
        <div className={classes.formBox}>
          <div className={classes.wrapper}>
            <div className={classes.headerImage}>
              <img src={"/assets/images/medication-modal-icon.png"} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <img
                  width={"150px"}
                  style={{ objectFit: "contain", borderRadius: "30px" }}
                  height={"150px"}
                  src={decrypt(client?.imageurl)}
                />
                <h4 style={{ marginBottom: 0 }}>{client?.name}</h4>
                <span style={{ color: "#979797" }}>
                  {client?.id?.slice(0, 6)?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className={classes.topFieldsBox}>
              {/* Is this a prescription medication? */}
              <div className="isPrescribed">
                <Radio.Group
                  name="prescribedMedication"
                  label="Is this a prescription medication?"
                  {...form.getInputProps("isPrescribed")}
                  required
                  withAsterisk
                >
                  <Group mt="xs">
                    <Radio disabled={isPreview} value="Yes" label="Yes" />
                    <Radio disabled={isPreview} value="No" label="No" />
                  </Group>
                </Radio.Group>
              </div>
              <div className="isNarcotic">
                <Radio.Group
                  name="isNarcotic"
                  label="Is this a narcotic?"
                  {...form.getInputProps("isNarcotic")}
                  required
                  withAsterisk
                  disabled={isPreview}
                >
                  <Group mt="xs">
                    <Radio disabled={isPreview} value="Yes" label="Yes" />
                    <Radio disabled={isPreview} value="No" label="No" />
                  </Group>
                </Radio.Group>
                <NarcoticBox />
              </div>
            </div>

            <div className={classes.formRow}>
              {/* medication name */}
              <CustomTextInput
                id="medication-name"
                label={"Medication Name"}
                size="sm"
                placeholder={"Enter Medication Name"}
                {...form.getInputProps("medicationName")}
                withAsterisk
                disabled={isPreview}
              />
              {/* medication type */}
              <CustomSelectBox
                id="medication-type"
                label={"Medication Type"}
                size="sm"
                {...form.getInputProps("medicationType")}
                onChange={(value) => {
                  form.setFieldValue("medicationType", value);
                  setType(value);
                }}
                placeholder={"Select Medication Type"}
                withAsterisk
                data={[
                  { value: "PRN", label: "PRN" },
                  { value: "scheduled", label: "SCHEDULED" },
                ]}
                disabled={isPreview}
              />
            </div>

            <div className={classes.medicationDosageBox}>
              <p className="dosageHeading">
                Dosage Information <span style={{ color: "red" }}>*</span>
              </p>
              <div className={classes.medicationDosageInner}>
                {/* medication amount */}
                <TextInput
                  id="medication-amount"
                  type="number"
                  size="sm"
                  label="Amount"
                  className={classes.forLabel}
                  placeholder="Enter Amount"
                  {...form.getInputProps("dosage")}
                  disabled={isPreview}
                />
                {/* medication unit */}
                <Select
                  id="medication-unit"
                  label="Unit"
                  data={[
                    { value: "mg", label: "mg" },
                    { value: "mg/ml", label: "mg/ml" },
                    { value: "cc", label: "cc" },
                    { value: "iu", label: "iu" },
                    { value: "ml", label: "ml" },
                    { value: "puffs", label: "puffs" },
                    { value: "mcg", label: "mcg" },
                    { value: "g", label: "g" },
                    { value: "oz", label: "oz" },
                    { value: "gtts", label: "gtts" },
                    { value: "spray", label: "spray" },
                    { value: "drops", label: "drops" },
                  ]}
                  size="sm"
                  className={classes.forLabel}
                  placeholder="Select Unit"
                  {...form.getInputProps("unit")}
                  rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  disabled={isPreview}
                />

                <Select
                  label="Frequency"
                  id="frequency"
                  className={classes.forLabel}
                  data={[...new Set(frequencyData)]}
                  // data={[
                  //   "QAM",
                  //   "QPM",
                  //   "Q4HR",
                  //   "Q2HR",
                  //   "Q6HR",
                  //   "Q8HR",
                  //   "Q12HR",
                  //   "AC",
                  //   "PC",
                  //   "BID",
                  //   "TID",
                  //   "QID",
                  //   "STAT",
                  //   "QOD",
                  //   "Noon",
                  //   "QD",
                  //   "Daily",
                  //   "Once Weekly",
                  //   "Once Monthly",
                  // ]}
                  placeholder="Select Frequency"
                  size="sm"
                  {...form.getInputProps("frequency")}
                  rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  disabled={isPreview}
                />
              </div>
            </div>
            {/* medication route */}
            <div className={classes.formRow}>
              <CustomSelectBox
                id="medication-route"
                label={"Medication Route"}
                size="sm"
                placeholder={"Select medication route"}
                data={[
                  { value: "Buccal", label: "Buccal" },
                  { value: "Orally", label: "Orally" },
                  { value: "Intramuscular", label: "Intramuscular" },
                  { value: "Subcutaneous", label: "Subcutaneous" },
                  { value: "Cutaneous", label: "Cutaneous" },
                  { value: "Irrigation", label: "Irrigation" },
                  { value: "Laryngeal", label: "Laryngeal" },
                  { value: "Nasal", label: "Nasal" },
                  { value: "Not Applicable", label: "Not Applicable" },
                  { value: "Parenteral", label: "Parenteral" },
                  { value: "Rectal", label: "Rectal" },
                  { value: "Vaginal", label: "Vaginal" },
                  { value: "Sublingual", label: "Sublingual" },
                  { value: "Suppository", label: "Suppository" },
                  { value: "Injection", label: "Injection" },
                  { value: "Inhalation", label: "Inhalation" },
                  { value: "Topical", label: "Topical" },
                  { value: "Other", label: "Other" },
                ]}
                {...form.getInputProps("ingestionMode")}
                withAsterisk
                disabled={isPreview}
              />
              <CustomSelectBox
                id="dose-form"
                label={"Dose Form"}
                size="sm"
                placeholder={"Select dose form"}
                data={[
                  "Tablet",
                  "Capsule",
                  "Syrup",
                  "Pill",
                  "Liquid",
                  "Cream",
                  "Oil",
                  "Ointment",
                  "Lotion",
                  "Gel",
                  "Gas",
                  "Solution",
                  "Inhalant",
                  "Injection",
                  "Insert",
                  "Suppository",
                  "Jelly",
                  "Lozenge",
                  "Paste",
                  "Patch",
                  "Rinse",
                  "Shampoo",
                  "Drops",
                  "Spray",
                ]}
                {...form.getInputProps("dosageForm")}
                disabled={isPreview}
              />
            </div>
            {/* medicineProvided */}
            <div className={classes.formRow}>
              {/* medication quantity */}
              <CustomTextInput
                id="medication-quantity"
                type="number"
                label={"Medication Total Quantity"}
                size="sm"
                placeholder={"Enter Medication Total Quantity"}
                {...form.getInputProps("totalMedicine")}
                withAsterisk
                disabled={isPreview}
              />
              {/* medication count */}
              {(!data || isPreview) && (
                <CustomTextInput
                  id="current-medication-count"
                  type="number"
                  label={"Current Medication Count"}
                  size="sm"
                  placeholder={"Enter Current Count"}
                  {...form.getInputProps("medicineRemaining")}
                  disabled={isPreview}
                />
              )}
              {/* administration count */}
              <CustomTextInput
                id="administration-count"
                type="number"
                label={"Administration Count"}
                size="sm"
                placeholder={"Enter Administered Count"}
                {...form.getInputProps("medicationadministered")}
                disabled={isPreview}
              />
            </div>
            {/* use of medication */}
            <div className={classes.formRow}>
              <CustomTextArea
                id="use-of-medication"
                size="sm"
                label={"Use of Medication"}
                placeholder={"Enter medication use"}
                minRows={3}
                {...form.getInputProps("medicationUse")}
                disabled={isPreview}
              />
            </div>
            {/* medication instruction */}
            <div className={classes.formRow}>
              <CustomTextArea
                id="medication-instruction"
                size="sm"
                label={"Medication Instruction"}
                placeholder={"Enter medication Instruction"}
                minRows={3}
                {...form.getInputProps("medicationInstruction")}
                disabled={isPreview}
              />
            </div>

            <div className={classes.formRow}>
              {/* responsible for purchasing */}
              <CustomSelectBox
                id="responsible-for-purchasing"
                label={"Responsible for purchasing"}
                size="sm"
                placeholder={"Select purchasing party"}
                data={[
                  { value: "Family", label: "Family" },
                  { value: "Care Facility", label: "Care Facility" },
                  { value: "Doctor/Nurse", label: "Doctor/Nurse" },
                  { value: "Other", label: "Other" },
                ]}
                {...form.getInputProps("responsibleParty")}
                disabled={isPreview}
              />
              <CustomSelectBox
                id="responsible-for-administering"
                label={"Responsible for administering"}
                size="sm"
                placeholder={"Select administering party"}
                data={[
                  { value: "Family", label: "Family" },
                  { value: "Care Facility", label: "Care Facility" },
                  { value: "Doctor/Nurse", label: "Doctor/Nurse" },
                  { value: "Other", label: "Other" },
                ]}
                {...form.getInputProps("administering")}
                disabled={isPreview}
              />
            </div>

            <div className={classes.bottomFields}>
              <div className={classes.bottomFieldsInner}>
                <DateInput
                  id="medication-start-date"
                  size="sm"
                  placeholder={"mm/dd/yyyy"}
                  label="Medication Start Date"
                  rightSection={
                    <img
                      style={{ width: "30px" }}
                      src={"/assets/images/calendar-icon.png"}
                      alt="Icon"
                    />
                  }
                  {...form.getInputProps("medicationDate")}
                  withAsterisk
                  style={{ cursor: "pointer" }}
                  // disabled={isPreview || data}
                  disabled={isPreview}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                />
                <DateInput
                  id="expriration-date"
                  minDate={new Date()}
                  size="sm"
                  placeholder={"mm/dd/yyyy"}
                  label="Expiration Date"
                  rightSection={
                    !form.getInputProps("expirationDate")?.value ? (
                      <img
                        style={{ width: "30px" }}
                        src={"/assets/images/calendar-icon.png"}
                        alt="Icon"
                      />
                    ) : (
                      false
                    )
                  }
                  disabled={isPreview}
                  // onChange={(value) =>{
                  //   console.log("expirationDate =>", moment(value).toDate())
                  //   // form.setFieldValue("expirationDate" , mom(value))
                  // }
                  // }
                  {...form.getInputProps("expirationDate")}
                  styles={
                    !form.getInputProps("expirationDate")?.value
                      ? { rightSection: { pointerEvents: "none" } }
                      : {}
                  }
                  clearable
                />
                {type !== "PRN" && (
                  <div className={classes.adminScheduleBox}>
                    <div className="heading">
                      <p className="label">
                        Administration Schedule
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      {/* {(isPreview || data) && ( */}
                      <ScheduleMenu
                        form={form}
                        isPreview={isPreview}
                        data={data}
                      />
                      {/* )} */}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {form.values?.scheduleType === "custom" ? (
                        <p
                          className={classes.scheduleMenuBtnText}
                          style={{ cursor: "default" }}
                        >
                          Repeats every{" "}
                          <span
                            style={{
                              background: "#D9D9D9",
                              color: "black",
                              paddingInline: "5px",
                            }}
                          >
                            {form.values?.repeatEvery}
                          </span>{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {form.values?.repeatType}
                          </span>
                          (s)
                        </p>
                      ) : (
                        <p
                          className={classes.scheduleMenuBtnText}
                          style={{ cursor: "default" }}
                        >
                          Daily
                        </p>
                      )}
                      {form.values?.scheduleType === "custom" &&
                        form.values?.repeatType === "month" && (
                          <p
                            className={classes.monthRepeatBox}
                            style={{ cursor: "default" }}
                          >
                            On the{" "}
                            {moment(form.values?.medicationDate).get("date")}
                          </p>
                        )}

                      {form.values?.scheduleType === "custom" &&
                        form.values?.repeatType === "week" && (
                          <div
                            className={classes.repeatOn}
                            style={{
                              background: "white",
                              borderRadius: "15px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              className="dayCircleBox"
                              style={{ alignItems: "center", marginTop: "0" }}
                            >
                              {daysOfWeek.map((day) => (
                                <div
                                  key={day}
                                  className={`dayCircle ${
                                    form.values?.repeatOn?.includes(day)
                                      ? "dayCircleActive"
                                      : "dayCircleInactive"
                                  }`}
                                  // onClick={() => (!isPreview ? toggleDay(day) : () => {})}
                                >
                                  <p>{day.charAt(0).toUpperCase()}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                    </div>
                    {/* {!isPreview && !data ? (
                      <ScheduleMenu
                        form={form}
                        isPreview={isPreview}
                        data={data}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {data?.scheduleType === "custom" ? (
                          <p
                            className={classes.scheduleMenuBtnText}
                            style={{ cursor: "default" }}
                          >
                            Repeats every{" "}
                            <span
                              style={{
                                background: "#D9D9D9",
                                color: "black",
                                paddingInline: "5px",
                              }}
                            >
                              {data?.repeatEvery}
                            </span>{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {data?.repeatType}
                            </span>
                            (s)
                          </p>
                        ) : (
                          <p
                            className={classes.scheduleMenuBtnText}
                            style={{ cursor: "default" }}
                          >
                            Daily
                          </p>
                        )}
                        {data?.scheduleType === "custom" &&
                          data?.repeatType === "month" && (
                            <p
                              className={classes.monthRepeatBox}
                              style={{ cursor: "default" }}
                            >
                              On the {moment(data?.medicationDate).get("date")}
                            </p>
                          )}

                        {data?.scheduleType === "custom" &&
                          data?.repeatType === "week" && (
                            <div
                              className={classes.repeatOn}
                              style={{
                                background: "white",
                                borderRadius: "15px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "5px",
                              }}
                            >
                              <div
                                className="dayCircleBox"
                                style={{ alignItems: "center", marginTop: "0" }}
                              >
                                {daysOfWeek.map((day) => (
                                  <div
                                    key={day}
                                    className={`dayCircle ${
                                      data?.repeatOn?.includes(day)
                                        ? "dayCircleActive"
                                        : "dayCircleInactive"
                                    }`}
                                    // onClick={() => (!isPreview ? toggleDay(day) : () => {})}
                                  >
                                    <p>{day.charAt(0).toUpperCase()}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </div>

            <div className={classes.uploadAndDateBox}>
              <div className={classes.dropzone}>
                <Dropzone
                  onDrop={(newFiles) => setFiles([...files, ...newFiles])}
                  multiple={false}
                  onReject={(files) => console.log("rejected files", files)}
                  disabled={isPreview}
                >
                  <Group
                    position="center"
                    spacing="xl"
                    style={{ minHeight: rem(220), pointerEvents: "none" }}
                  >
                    <Dropzone.Accept></Dropzone.Accept>
                    <Dropzone.Reject></Dropzone.Reject>
                    <Dropzone.Idle></Dropzone.Idle>

                    <div className={classes.column}>
                      <img
                        src="/assets/images/drag.png"
                        alt="Drag & Drop"
                        className={classes.dragImage}
                      />
                      <p className={classes.uploadText}>
                        <span>Upload</span> Doctor's Note
                      </p>
                    </div>
                  </Group>
                </Dropzone>
                {files?.length > 0 && (
                  <div className={classes.fileNameBox}>
                    <p>{formatString(files[0]?.name)}</p>
                    <IoIosCloseCircle
                      color="rgba(255, 72, 72, 0.18)"
                      size={25}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFiles([])}
                    />
                  </div>
                )}
              </div>
              <div className={classes.datePicker}>
                <DatePickerInput
                  id="order-date"
                  label="Order Date"
                  size="xl"
                  placeholder="mm/dd/yyyy"
                  rightSection={
                    <img
                      style={{ width: "30px" }}
                      src={"/assets/images/calendar-icon.png"}
                      alt="Icon"
                    />
                  }
                  // withAsterisk
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  {...form.getInputProps("orderDate")}
                  disabled={isPreview}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <span style={{ color: "transparent" }}>.</span>
            </div>
            {data?.doctorNote && isPreview && (
              <>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <b>Download</b>
                  <img
                    src="/assets/images/downloadFolder.png"
                    alt="download"
                    width={30}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDownloadFile(data?.doctorNote)}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <span style={{ color: "transparent" }}>.</span>
                </div>
              </>
            )}
          </div>
        </div>
        {!isPreview && (
          <div className={classes.saveButton}>
            <Button
              id="save-medication"
              loading={loading}
              type="submit"
              style={{ width: loading ? "150px" : "110px" }}
            >
              <img src="/assets/images/save-icon.png" alt="" />
              Save
            </Button>
          </div>
        )}
      </form>
      <ConfirmationModal
        opened={confirmModal}
        close={confirmClose}
        handleEdit={() => {
          handleEdit(true);
        }}
      />
    </Modal>
  );
};

const CustomTextInput = ({ ...rest }) => {
  const useStyles = createStyles((theme) => ({
    textInputBox: {
      width: "100%",
      padding: "5px 15px",
      borderRadius: "5px",
      backgroundColor: bgColor,
      "& input": {
        backgroundColor: "transparent",
        border: "none",
        paddingInline: 0,
      },
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.textInputBox}>
      <TextInput {...rest} />
    </div>
  );
};

const CustomSelectBox = ({ ...rest }) => {
  const useStyles = createStyles((theme) => ({
    textInputBox: {
      width: "100%",
      padding: "5px 15px",
      borderRadius: "5px",
      backgroundColor: bgColor,
      "& input": {
        border: "none",
        paddingInline: 0,
        backgroundColor: "transparent",
      },
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.textInputBox}>
      <Select
        {...rest}
        rightSection={<IconChevronDown color={"#FBAA0D"} size={25} />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
      />
    </div>
  );
};

const CustomTextArea = ({ label, withAsterisk, ...rest }) => {
  const useStyles = createStyles((theme) => ({
    textInputBox: {
      width: "100%",
      border: "1px solid rgba(0,0,0,0.1)",
      padding: "5px 15px",
      borderRadius: "15px",
      position: "relative",
      "& .mantine-Textarea-input": {
        border: "none",
        paddingInline: "0",
      },
      "& .label": {
        position: "absolute",
        top: -10,
        left: 30,
        zIndex: 100,
        color: "#000",
        margin: 0,
        backgroundColor: "#fff",
        display: "inline-block",
        fontSize: "14px",
        fontWeight: 500,
        paddingInline: "10px",
      },
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.textInputBox}>
      <p className="label">
        {label} {withAsterisk && <span style={{ color: "red" }}>*</span>}
      </p>
      <Textarea {...rest} />
    </div>
  );
};

export default AddNewMedicationModal;
